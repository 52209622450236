import {action, computed, makeObservable, observable} from 'mobx';
import {AppStore} from '../AppStore';

export class DomainsView {
  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable isSearchDomainStart: boolean = false;
  @observable isSearchingDomains: boolean = false;

  @computed get domainsStore() {
    return this.app.domainsStore;
  }

  @action searchDomain = async (searchText: string) => {
    this.isSearchingDomains = true;
    this.isSearchDomainStart = true;

    this.domainsStore.search.clearDomainSuggestions();

    const {res, error} = await this.app.domainsStore.search.searchDomain(searchText);

    if (error) {
      this.app.notification.error(error.message);
    }

    this.isSearchingDomains = false;

    return {res, error};
  };

  @action reset = () => {
    this.isSearchDomainStart = false;

    this.app.domainsStore.search.resetFilter();
  };
}

export default DomainsView;
