/*eslint-disable*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";
$protobuf.util.Long = require('long');
$protobuf.configure();

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.ca2auth = (function() {
    
        /**
         * Namespace ca2auth.
         * @exports ca2auth
         * @namespace
         */
        var ca2auth = {};
    
        ca2auth.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2auth
             * @interface IRequest
             * @property {ca2auth.IStartRequest|null} [start] Request start
             * @property {ca2auth.IEnterCodeRequest|null} [enterCode] Request enterCode
             * @property {ca2auth.IEnterPasswordRequest|null} [enterPassword] Request enterPassword
             * @property {ca2auth.ILogoutRequest|null} [logout] Request logout
             * @property {ca2auth.IOAuthRequest|null} [oauth] Request oauth
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2auth
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2auth.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request start.
             * @member {ca2auth.IStartRequest|null|undefined} start
             * @memberof ca2auth.Request
             * @instance
             */
            Request.prototype.start = null;
    
            /**
             * Request enterCode.
             * @member {ca2auth.IEnterCodeRequest|null|undefined} enterCode
             * @memberof ca2auth.Request
             * @instance
             */
            Request.prototype.enterCode = null;
    
            /**
             * Request enterPassword.
             * @member {ca2auth.IEnterPasswordRequest|null|undefined} enterPassword
             * @memberof ca2auth.Request
             * @instance
             */
            Request.prototype.enterPassword = null;
    
            /**
             * Request logout.
             * @member {ca2auth.ILogoutRequest|null|undefined} logout
             * @memberof ca2auth.Request
             * @instance
             */
            Request.prototype.logout = null;
    
            /**
             * Request oauth.
             * @member {ca2auth.IOAuthRequest|null|undefined} oauth
             * @memberof ca2auth.Request
             * @instance
             */
            Request.prototype.oauth = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2auth.Request
             * @static
             * @param {ca2auth.IRequest=} [properties] Properties to set
             * @returns {ca2auth.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2auth.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.Request
             * @static
             * @param {ca2auth.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                    $root.ca2auth.StartRequest.encode(message.start, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.enterCode != null && Object.hasOwnProperty.call(message, "enterCode"))
                    $root.ca2auth.EnterCodeRequest.encode(message.enterCode, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.enterPassword != null && Object.hasOwnProperty.call(message, "enterPassword"))
                    $root.ca2auth.EnterPasswordRequest.encode(message.enterPassword, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.logout != null && Object.hasOwnProperty.call(message, "logout"))
                    $root.ca2auth.LogoutRequest.encode(message.logout, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.oauth != null && Object.hasOwnProperty.call(message, "oauth"))
                    $root.ca2auth.OAuthRequest.encode(message.oauth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2auth.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.Request
             * @static
             * @param {ca2auth.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.start = $root.ca2auth.StartRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.enterCode = $root.ca2auth.EnterCodeRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.enterPassword = $root.ca2auth.EnterPasswordRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.logout = $root.ca2auth.LogoutRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.oauth = $root.ca2auth.OAuthRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2auth.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.start != null && message.hasOwnProperty("start")) {
                    var error = $root.ca2auth.StartRequest.verify(message.start);
                    if (error)
                        return "start." + error;
                }
                if (message.enterCode != null && message.hasOwnProperty("enterCode")) {
                    var error = $root.ca2auth.EnterCodeRequest.verify(message.enterCode);
                    if (error)
                        return "enterCode." + error;
                }
                if (message.enterPassword != null && message.hasOwnProperty("enterPassword")) {
                    var error = $root.ca2auth.EnterPasswordRequest.verify(message.enterPassword);
                    if (error)
                        return "enterPassword." + error;
                }
                if (message.logout != null && message.hasOwnProperty("logout")) {
                    var error = $root.ca2auth.LogoutRequest.verify(message.logout);
                    if (error)
                        return "logout." + error;
                }
                if (message.oauth != null && message.hasOwnProperty("oauth")) {
                    var error = $root.ca2auth.OAuthRequest.verify(message.oauth);
                    if (error)
                        return "oauth." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.Request)
                    return object;
                var message = new $root.ca2auth.Request();
                if (object.start != null) {
                    if (typeof object.start !== "object")
                        throw TypeError(".ca2auth.Request.start: object expected");
                    message.start = $root.ca2auth.StartRequest.fromObject(object.start);
                }
                if (object.enterCode != null) {
                    if (typeof object.enterCode !== "object")
                        throw TypeError(".ca2auth.Request.enterCode: object expected");
                    message.enterCode = $root.ca2auth.EnterCodeRequest.fromObject(object.enterCode);
                }
                if (object.enterPassword != null) {
                    if (typeof object.enterPassword !== "object")
                        throw TypeError(".ca2auth.Request.enterPassword: object expected");
                    message.enterPassword = $root.ca2auth.EnterPasswordRequest.fromObject(object.enterPassword);
                }
                if (object.logout != null) {
                    if (typeof object.logout !== "object")
                        throw TypeError(".ca2auth.Request.logout: object expected");
                    message.logout = $root.ca2auth.LogoutRequest.fromObject(object.logout);
                }
                if (object.oauth != null) {
                    if (typeof object.oauth !== "object")
                        throw TypeError(".ca2auth.Request.oauth: object expected");
                    message.oauth = $root.ca2auth.OAuthRequest.fromObject(object.oauth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.Request
             * @static
             * @param {ca2auth.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.start = null;
                    object.enterCode = null;
                    object.enterPassword = null;
                    object.logout = null;
                    object.oauth = null;
                }
                if (message.start != null && message.hasOwnProperty("start"))
                    object.start = $root.ca2auth.StartRequest.toObject(message.start, options);
                if (message.enterCode != null && message.hasOwnProperty("enterCode"))
                    object.enterCode = $root.ca2auth.EnterCodeRequest.toObject(message.enterCode, options);
                if (message.enterPassword != null && message.hasOwnProperty("enterPassword"))
                    object.enterPassword = $root.ca2auth.EnterPasswordRequest.toObject(message.enterPassword, options);
                if (message.logout != null && message.hasOwnProperty("logout"))
                    object.logout = $root.ca2auth.LogoutRequest.toObject(message.logout, options);
                if (message.oauth != null && message.hasOwnProperty("oauth"))
                    object.oauth = $root.ca2auth.OAuthRequest.toObject(message.oauth, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2auth.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2auth.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.Request";
            };
    
            return Request;
        })();
    
        ca2auth.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2auth
             * @interface IResponse
             * @property {ca2auth.IStartResponse|null} [start] Response start
             * @property {ca2auth.IEnterCodeResponse|null} [enterCode] Response enterCode
             * @property {ca2auth.IEnterPasswordResponse|null} [enterPassword] Response enterPassword
             * @property {ca2auth.ILogoutResponse|null} [logout] Response logout
             * @property {ca2auth.IOAuthResponse|null} [oauth] Response oauth
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2auth
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2auth.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response start.
             * @member {ca2auth.IStartResponse|null|undefined} start
             * @memberof ca2auth.Response
             * @instance
             */
            Response.prototype.start = null;
    
            /**
             * Response enterCode.
             * @member {ca2auth.IEnterCodeResponse|null|undefined} enterCode
             * @memberof ca2auth.Response
             * @instance
             */
            Response.prototype.enterCode = null;
    
            /**
             * Response enterPassword.
             * @member {ca2auth.IEnterPasswordResponse|null|undefined} enterPassword
             * @memberof ca2auth.Response
             * @instance
             */
            Response.prototype.enterPassword = null;
    
            /**
             * Response logout.
             * @member {ca2auth.ILogoutResponse|null|undefined} logout
             * @memberof ca2auth.Response
             * @instance
             */
            Response.prototype.logout = null;
    
            /**
             * Response oauth.
             * @member {ca2auth.IOAuthResponse|null|undefined} oauth
             * @memberof ca2auth.Response
             * @instance
             */
            Response.prototype.oauth = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2auth.Response
             * @static
             * @param {ca2auth.IResponse=} [properties] Properties to set
             * @returns {ca2auth.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2auth.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.Response
             * @static
             * @param {ca2auth.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                    $root.ca2auth.StartResponse.encode(message.start, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.enterCode != null && Object.hasOwnProperty.call(message, "enterCode"))
                    $root.ca2auth.EnterCodeResponse.encode(message.enterCode, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.enterPassword != null && Object.hasOwnProperty.call(message, "enterPassword"))
                    $root.ca2auth.EnterPasswordResponse.encode(message.enterPassword, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.logout != null && Object.hasOwnProperty.call(message, "logout"))
                    $root.ca2auth.LogoutResponse.encode(message.logout, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.oauth != null && Object.hasOwnProperty.call(message, "oauth"))
                    $root.ca2auth.OAuthResponse.encode(message.oauth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2auth.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.Response
             * @static
             * @param {ca2auth.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.start = $root.ca2auth.StartResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.enterCode = $root.ca2auth.EnterCodeResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.enterPassword = $root.ca2auth.EnterPasswordResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.logout = $root.ca2auth.LogoutResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.oauth = $root.ca2auth.OAuthResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2auth.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.start != null && message.hasOwnProperty("start")) {
                    var error = $root.ca2auth.StartResponse.verify(message.start);
                    if (error)
                        return "start." + error;
                }
                if (message.enterCode != null && message.hasOwnProperty("enterCode")) {
                    var error = $root.ca2auth.EnterCodeResponse.verify(message.enterCode);
                    if (error)
                        return "enterCode." + error;
                }
                if (message.enterPassword != null && message.hasOwnProperty("enterPassword")) {
                    var error = $root.ca2auth.EnterPasswordResponse.verify(message.enterPassword);
                    if (error)
                        return "enterPassword." + error;
                }
                if (message.logout != null && message.hasOwnProperty("logout")) {
                    var error = $root.ca2auth.LogoutResponse.verify(message.logout);
                    if (error)
                        return "logout." + error;
                }
                if (message.oauth != null && message.hasOwnProperty("oauth")) {
                    var error = $root.ca2auth.OAuthResponse.verify(message.oauth);
                    if (error)
                        return "oauth." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.Response)
                    return object;
                var message = new $root.ca2auth.Response();
                if (object.start != null) {
                    if (typeof object.start !== "object")
                        throw TypeError(".ca2auth.Response.start: object expected");
                    message.start = $root.ca2auth.StartResponse.fromObject(object.start);
                }
                if (object.enterCode != null) {
                    if (typeof object.enterCode !== "object")
                        throw TypeError(".ca2auth.Response.enterCode: object expected");
                    message.enterCode = $root.ca2auth.EnterCodeResponse.fromObject(object.enterCode);
                }
                if (object.enterPassword != null) {
                    if (typeof object.enterPassword !== "object")
                        throw TypeError(".ca2auth.Response.enterPassword: object expected");
                    message.enterPassword = $root.ca2auth.EnterPasswordResponse.fromObject(object.enterPassword);
                }
                if (object.logout != null) {
                    if (typeof object.logout !== "object")
                        throw TypeError(".ca2auth.Response.logout: object expected");
                    message.logout = $root.ca2auth.LogoutResponse.fromObject(object.logout);
                }
                if (object.oauth != null) {
                    if (typeof object.oauth !== "object")
                        throw TypeError(".ca2auth.Response.oauth: object expected");
                    message.oauth = $root.ca2auth.OAuthResponse.fromObject(object.oauth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.Response
             * @static
             * @param {ca2auth.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.start = null;
                    object.enterCode = null;
                    object.enterPassword = null;
                    object.logout = null;
                    object.oauth = null;
                }
                if (message.start != null && message.hasOwnProperty("start"))
                    object.start = $root.ca2auth.StartResponse.toObject(message.start, options);
                if (message.enterCode != null && message.hasOwnProperty("enterCode"))
                    object.enterCode = $root.ca2auth.EnterCodeResponse.toObject(message.enterCode, options);
                if (message.enterPassword != null && message.hasOwnProperty("enterPassword"))
                    object.enterPassword = $root.ca2auth.EnterPasswordResponse.toObject(message.enterPassword, options);
                if (message.logout != null && message.hasOwnProperty("logout"))
                    object.logout = $root.ca2auth.LogoutResponse.toObject(message.logout, options);
                if (message.oauth != null && message.hasOwnProperty("oauth"))
                    object.oauth = $root.ca2auth.OAuthResponse.toObject(message.oauth, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2auth.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2auth.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.Response";
            };
    
            return Response;
        })();
    
        ca2auth.StartStep = (function() {
    
            /**
             * Properties of a StartStep.
             * @memberof ca2auth
             * @interface IStartStep
             */
    
            /**
             * Constructs a new StartStep.
             * @memberof ca2auth
             * @classdesc Represents a StartStep.
             * @implements IStartStep
             * @constructor
             * @param {ca2auth.IStartStep=} [properties] Properties to set
             */
            function StartStep(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new StartStep instance using the specified properties.
             * @function create
             * @memberof ca2auth.StartStep
             * @static
             * @param {ca2auth.IStartStep=} [properties] Properties to set
             * @returns {ca2auth.StartStep} StartStep instance
             */
            StartStep.create = function create(properties) {
                return new StartStep(properties);
            };
    
            /**
             * Encodes the specified StartStep message. Does not implicitly {@link ca2auth.StartStep.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.StartStep
             * @static
             * @param {ca2auth.IStartStep} message StartStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartStep.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified StartStep message, length delimited. Does not implicitly {@link ca2auth.StartStep.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.StartStep
             * @static
             * @param {ca2auth.IStartStep} message StartStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartStep.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StartStep message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.StartStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.StartStep} StartStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartStep.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.StartStep();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StartStep message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.StartStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.StartStep} StartStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartStep.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StartStep message.
             * @function verify
             * @memberof ca2auth.StartStep
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartStep.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a StartStep message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.StartStep
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.StartStep} StartStep
             */
            StartStep.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.StartStep)
                    return object;
                return new $root.ca2auth.StartStep();
            };
    
            /**
             * Creates a plain object from a StartStep message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.StartStep
             * @static
             * @param {ca2auth.StartStep} message StartStep
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartStep.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this StartStep to JSON.
             * @function toJSON
             * @memberof ca2auth.StartStep
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartStep.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StartStep
             * @function getTypeUrl
             * @memberof ca2auth.StartStep
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartStep.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.StartStep";
            };
    
            return StartStep;
        })();
    
        ca2auth.EnterCodeStep = (function() {
    
            /**
             * Properties of an EnterCodeStep.
             * @memberof ca2auth
             * @interface IEnterCodeStep
             * @property {string|null} [email] EnterCodeStep email
             * @property {ca2types.ICode|null} [code] EnterCodeStep code
             */
    
            /**
             * Constructs a new EnterCodeStep.
             * @memberof ca2auth
             * @classdesc Represents an EnterCodeStep.
             * @implements IEnterCodeStep
             * @constructor
             * @param {ca2auth.IEnterCodeStep=} [properties] Properties to set
             */
            function EnterCodeStep(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnterCodeStep email.
             * @member {string} email
             * @memberof ca2auth.EnterCodeStep
             * @instance
             */
            EnterCodeStep.prototype.email = "";
    
            /**
             * EnterCodeStep code.
             * @member {ca2types.ICode|null|undefined} code
             * @memberof ca2auth.EnterCodeStep
             * @instance
             */
            EnterCodeStep.prototype.code = null;
    
            /**
             * Creates a new EnterCodeStep instance using the specified properties.
             * @function create
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {ca2auth.IEnterCodeStep=} [properties] Properties to set
             * @returns {ca2auth.EnterCodeStep} EnterCodeStep instance
             */
            EnterCodeStep.create = function create(properties) {
                return new EnterCodeStep(properties);
            };
    
            /**
             * Encodes the specified EnterCodeStep message. Does not implicitly {@link ca2auth.EnterCodeStep.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {ca2auth.IEnterCodeStep} message EnterCodeStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterCodeStep.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    $root.ca2types.Code.encode(message.code, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                return writer;
            };
    
            /**
             * Encodes the specified EnterCodeStep message, length delimited. Does not implicitly {@link ca2auth.EnterCodeStep.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {ca2auth.IEnterCodeStep} message EnterCodeStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterCodeStep.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnterCodeStep message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.EnterCodeStep} EnterCodeStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterCodeStep.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.EnterCodeStep();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.email = reader.string();
                            break;
                        }
                    case 1: {
                            message.code = $root.ca2types.Code.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnterCodeStep message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.EnterCodeStep} EnterCodeStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterCodeStep.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnterCodeStep message.
             * @function verify
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnterCodeStep.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.code != null && message.hasOwnProperty("code")) {
                    var error = $root.ca2types.Code.verify(message.code);
                    if (error)
                        return "code." + error;
                }
                return null;
            };
    
            /**
             * Creates an EnterCodeStep message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.EnterCodeStep} EnterCodeStep
             */
            EnterCodeStep.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.EnterCodeStep)
                    return object;
                var message = new $root.ca2auth.EnterCodeStep();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.code != null) {
                    if (typeof object.code !== "object")
                        throw TypeError(".ca2auth.EnterCodeStep.code: object expected");
                    message.code = $root.ca2types.Code.fromObject(object.code);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EnterCodeStep message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {ca2auth.EnterCodeStep} message EnterCodeStep
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnterCodeStep.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = null;
                    object.email = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = $root.ca2types.Code.toObject(message.code, options);
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };
    
            /**
             * Converts this EnterCodeStep to JSON.
             * @function toJSON
             * @memberof ca2auth.EnterCodeStep
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnterCodeStep.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EnterCodeStep
             * @function getTypeUrl
             * @memberof ca2auth.EnterCodeStep
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnterCodeStep.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.EnterCodeStep";
            };
    
            return EnterCodeStep;
        })();
    
        ca2auth.EnterPasswordStep = (function() {
    
            /**
             * Properties of an EnterPasswordStep.
             * @memberof ca2auth
             * @interface IEnterPasswordStep
             * @property {string|null} [email] EnterPasswordStep email
             */
    
            /**
             * Constructs a new EnterPasswordStep.
             * @memberof ca2auth
             * @classdesc Represents an EnterPasswordStep.
             * @implements IEnterPasswordStep
             * @constructor
             * @param {ca2auth.IEnterPasswordStep=} [properties] Properties to set
             */
            function EnterPasswordStep(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnterPasswordStep email.
             * @member {string} email
             * @memberof ca2auth.EnterPasswordStep
             * @instance
             */
            EnterPasswordStep.prototype.email = "";
    
            /**
             * Creates a new EnterPasswordStep instance using the specified properties.
             * @function create
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {ca2auth.IEnterPasswordStep=} [properties] Properties to set
             * @returns {ca2auth.EnterPasswordStep} EnterPasswordStep instance
             */
            EnterPasswordStep.create = function create(properties) {
                return new EnterPasswordStep(properties);
            };
    
            /**
             * Encodes the specified EnterPasswordStep message. Does not implicitly {@link ca2auth.EnterPasswordStep.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {ca2auth.IEnterPasswordStep} message EnterPasswordStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterPasswordStep.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                return writer;
            };
    
            /**
             * Encodes the specified EnterPasswordStep message, length delimited. Does not implicitly {@link ca2auth.EnterPasswordStep.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {ca2auth.IEnterPasswordStep} message EnterPasswordStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterPasswordStep.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnterPasswordStep message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.EnterPasswordStep} EnterPasswordStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterPasswordStep.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.EnterPasswordStep();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnterPasswordStep message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.EnterPasswordStep} EnterPasswordStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterPasswordStep.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnterPasswordStep message.
             * @function verify
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnterPasswordStep.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                return null;
            };
    
            /**
             * Creates an EnterPasswordStep message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.EnterPasswordStep} EnterPasswordStep
             */
            EnterPasswordStep.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.EnterPasswordStep)
                    return object;
                var message = new $root.ca2auth.EnterPasswordStep();
                if (object.email != null)
                    message.email = String(object.email);
                return message;
            };
    
            /**
             * Creates a plain object from an EnterPasswordStep message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {ca2auth.EnterPasswordStep} message EnterPasswordStep
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnterPasswordStep.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.email = "";
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };
    
            /**
             * Converts this EnterPasswordStep to JSON.
             * @function toJSON
             * @memberof ca2auth.EnterPasswordStep
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnterPasswordStep.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EnterPasswordStep
             * @function getTypeUrl
             * @memberof ca2auth.EnterPasswordStep
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnterPasswordStep.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.EnterPasswordStep";
            };
    
            return EnterPasswordStep;
        })();
    
        ca2auth.SuccessStep = (function() {
    
            /**
             * Properties of a SuccessStep.
             * @memberof ca2auth
             * @interface ISuccessStep
             * @property {ca2types.IUser|null} [user] SuccessStep user
             * @property {string|null} [token] SuccessStep token
             */
    
            /**
             * Constructs a new SuccessStep.
             * @memberof ca2auth
             * @classdesc Represents a SuccessStep.
             * @implements ISuccessStep
             * @constructor
             * @param {ca2auth.ISuccessStep=} [properties] Properties to set
             */
            function SuccessStep(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SuccessStep user.
             * @member {ca2types.IUser|null|undefined} user
             * @memberof ca2auth.SuccessStep
             * @instance
             */
            SuccessStep.prototype.user = null;
    
            /**
             * SuccessStep token.
             * @member {string} token
             * @memberof ca2auth.SuccessStep
             * @instance
             */
            SuccessStep.prototype.token = "";
    
            /**
             * Creates a new SuccessStep instance using the specified properties.
             * @function create
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {ca2auth.ISuccessStep=} [properties] Properties to set
             * @returns {ca2auth.SuccessStep} SuccessStep instance
             */
            SuccessStep.create = function create(properties) {
                return new SuccessStep(properties);
            };
    
            /**
             * Encodes the specified SuccessStep message. Does not implicitly {@link ca2auth.SuccessStep.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {ca2auth.ISuccessStep} message SuccessStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SuccessStep.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.ca2types.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
                return writer;
            };
    
            /**
             * Encodes the specified SuccessStep message, length delimited. Does not implicitly {@link ca2auth.SuccessStep.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {ca2auth.ISuccessStep} message SuccessStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SuccessStep.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SuccessStep message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.SuccessStep} SuccessStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SuccessStep.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.SuccessStep();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.user = $root.ca2types.User.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.token = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SuccessStep message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.SuccessStep} SuccessStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SuccessStep.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SuccessStep message.
             * @function verify
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SuccessStep.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.ca2types.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };
    
            /**
             * Creates a SuccessStep message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.SuccessStep} SuccessStep
             */
            SuccessStep.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.SuccessStep)
                    return object;
                var message = new $root.ca2auth.SuccessStep();
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".ca2auth.SuccessStep.user: object expected");
                    message.user = $root.ca2types.User.fromObject(object.user);
                }
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };
    
            /**
             * Creates a plain object from a SuccessStep message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {ca2auth.SuccessStep} message SuccessStep
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SuccessStep.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.user = null;
                    object.token = "";
                }
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.ca2types.User.toObject(message.user, options);
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };
    
            /**
             * Converts this SuccessStep to JSON.
             * @function toJSON
             * @memberof ca2auth.SuccessStep
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SuccessStep.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SuccessStep
             * @function getTypeUrl
             * @memberof ca2auth.SuccessStep
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SuccessStep.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.SuccessStep";
            };
    
            return SuccessStep;
        })();
    
        ca2auth.StartRequest = (function() {
    
            /**
             * Properties of a StartRequest.
             * @memberof ca2auth
             * @interface IStartRequest
             * @property {string|null} [email] StartRequest email
             * @property {string|null} [recaptchaToken] StartRequest recaptchaToken
             * @property {string|null} [referralCode] StartRequest referralCode
             * @property {boolean|null} [forceSendCode] StartRequest forceSendCode
             */
    
            /**
             * Constructs a new StartRequest.
             * @memberof ca2auth
             * @classdesc Represents a StartRequest.
             * @implements IStartRequest
             * @constructor
             * @param {ca2auth.IStartRequest=} [properties] Properties to set
             */
            function StartRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StartRequest email.
             * @member {string} email
             * @memberof ca2auth.StartRequest
             * @instance
             */
            StartRequest.prototype.email = "";
    
            /**
             * StartRequest recaptchaToken.
             * @member {string} recaptchaToken
             * @memberof ca2auth.StartRequest
             * @instance
             */
            StartRequest.prototype.recaptchaToken = "";
    
            /**
             * StartRequest referralCode.
             * @member {string} referralCode
             * @memberof ca2auth.StartRequest
             * @instance
             */
            StartRequest.prototype.referralCode = "";
    
            /**
             * StartRequest forceSendCode.
             * @member {boolean} forceSendCode
             * @memberof ca2auth.StartRequest
             * @instance
             */
            StartRequest.prototype.forceSendCode = false;
    
            /**
             * Creates a new StartRequest instance using the specified properties.
             * @function create
             * @memberof ca2auth.StartRequest
             * @static
             * @param {ca2auth.IStartRequest=} [properties] Properties to set
             * @returns {ca2auth.StartRequest} StartRequest instance
             */
            StartRequest.create = function create(properties) {
                return new StartRequest(properties);
            };
    
            /**
             * Encodes the specified StartRequest message. Does not implicitly {@link ca2auth.StartRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.StartRequest
             * @static
             * @param {ca2auth.IStartRequest} message StartRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.recaptchaToken != null && Object.hasOwnProperty.call(message, "recaptchaToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.recaptchaToken);
                if (message.referralCode != null && Object.hasOwnProperty.call(message, "referralCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.referralCode);
                if (message.forceSendCode != null && Object.hasOwnProperty.call(message, "forceSendCode"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.forceSendCode);
                return writer;
            };
    
            /**
             * Encodes the specified StartRequest message, length delimited. Does not implicitly {@link ca2auth.StartRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.StartRequest
             * @static
             * @param {ca2auth.IStartRequest} message StartRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StartRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.StartRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.StartRequest} StartRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.StartRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.recaptchaToken = reader.string();
                            break;
                        }
                    case 3: {
                            message.referralCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.forceSendCode = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StartRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.StartRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.StartRequest} StartRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StartRequest message.
             * @function verify
             * @memberof ca2auth.StartRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.recaptchaToken != null && message.hasOwnProperty("recaptchaToken"))
                    if (!$util.isString(message.recaptchaToken))
                        return "recaptchaToken: string expected";
                if (message.referralCode != null && message.hasOwnProperty("referralCode"))
                    if (!$util.isString(message.referralCode))
                        return "referralCode: string expected";
                if (message.forceSendCode != null && message.hasOwnProperty("forceSendCode"))
                    if (typeof message.forceSendCode !== "boolean")
                        return "forceSendCode: boolean expected";
                return null;
            };
    
            /**
             * Creates a StartRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.StartRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.StartRequest} StartRequest
             */
            StartRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.StartRequest)
                    return object;
                var message = new $root.ca2auth.StartRequest();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.recaptchaToken != null)
                    message.recaptchaToken = String(object.recaptchaToken);
                if (object.referralCode != null)
                    message.referralCode = String(object.referralCode);
                if (object.forceSendCode != null)
                    message.forceSendCode = Boolean(object.forceSendCode);
                return message;
            };
    
            /**
             * Creates a plain object from a StartRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.StartRequest
             * @static
             * @param {ca2auth.StartRequest} message StartRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.recaptchaToken = "";
                    object.referralCode = "";
                    object.forceSendCode = false;
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.recaptchaToken != null && message.hasOwnProperty("recaptchaToken"))
                    object.recaptchaToken = message.recaptchaToken;
                if (message.referralCode != null && message.hasOwnProperty("referralCode"))
                    object.referralCode = message.referralCode;
                if (message.forceSendCode != null && message.hasOwnProperty("forceSendCode"))
                    object.forceSendCode = message.forceSendCode;
                return object;
            };
    
            /**
             * Converts this StartRequest to JSON.
             * @function toJSON
             * @memberof ca2auth.StartRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StartRequest
             * @function getTypeUrl
             * @memberof ca2auth.StartRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.StartRequest";
            };
    
            return StartRequest;
        })();
    
        /**
         * StartError enum.
         * @name ca2auth.StartError
         * @enum {number}
         * @property {number} SE_UNKNOWN_ERROR=0 SE_UNKNOWN_ERROR value
         * @property {number} SE_INVALID_EMAIL_ERROR=1 SE_INVALID_EMAIL_ERROR value
         * @property {number} SE_INVALID_RECAPTCHA_ERROR=2 SE_INVALID_RECAPTCHA_ERROR value
         * @property {number} SE_INVALID_REFERRAL_CODE_ERROR=3 SE_INVALID_REFERRAL_CODE_ERROR value
         * @property {number} SE_INVALID_BEARER_JWT_TOKEN_ERROR=4 SE_INVALID_BEARER_JWT_TOKEN_ERROR value
         * @property {number} SE_USER_IS_BLOCKED_ERROR=5 SE_USER_IS_BLOCKED_ERROR value
         */
        ca2auth.StartError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "SE_INVALID_EMAIL_ERROR"] = 1;
            values[valuesById[2] = "SE_INVALID_RECAPTCHA_ERROR"] = 2;
            values[valuesById[3] = "SE_INVALID_REFERRAL_CODE_ERROR"] = 3;
            values[valuesById[4] = "SE_INVALID_BEARER_JWT_TOKEN_ERROR"] = 4;
            values[valuesById[5] = "SE_USER_IS_BLOCKED_ERROR"] = 5;
            return values;
        })();
    
        ca2auth.StartResponse = (function() {
    
            /**
             * Properties of a StartResponse.
             * @memberof ca2auth
             * @interface IStartResponse
             * @property {Array.<ca2auth.StartError>|null} [errors] StartResponse errors
             * @property {ca2auth.IStartStep|null} [startStep] StartResponse startStep
             * @property {ca2auth.IEnterCodeStep|null} [enterCodeStep] StartResponse enterCodeStep
             * @property {ca2auth.IEnterPasswordStep|null} [enterPasswordStep] StartResponse enterPasswordStep
             * @property {boolean|null} [isNewUser] StartResponse isNewUser
             * @property {string|null} [blockedReason] StartResponse blockedReason
             */
    
            /**
             * Constructs a new StartResponse.
             * @memberof ca2auth
             * @classdesc Represents a StartResponse.
             * @implements IStartResponse
             * @constructor
             * @param {ca2auth.IStartResponse=} [properties] Properties to set
             */
            function StartResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StartResponse errors.
             * @member {Array.<ca2auth.StartError>} errors
             * @memberof ca2auth.StartResponse
             * @instance
             */
            StartResponse.prototype.errors = $util.emptyArray;
    
            /**
             * StartResponse startStep.
             * @member {ca2auth.IStartStep|null|undefined} startStep
             * @memberof ca2auth.StartResponse
             * @instance
             */
            StartResponse.prototype.startStep = null;
    
            /**
             * StartResponse enterCodeStep.
             * @member {ca2auth.IEnterCodeStep|null|undefined} enterCodeStep
             * @memberof ca2auth.StartResponse
             * @instance
             */
            StartResponse.prototype.enterCodeStep = null;
    
            /**
             * StartResponse enterPasswordStep.
             * @member {ca2auth.IEnterPasswordStep|null|undefined} enterPasswordStep
             * @memberof ca2auth.StartResponse
             * @instance
             */
            StartResponse.prototype.enterPasswordStep = null;
    
            /**
             * StartResponse isNewUser.
             * @member {boolean} isNewUser
             * @memberof ca2auth.StartResponse
             * @instance
             */
            StartResponse.prototype.isNewUser = false;
    
            /**
             * StartResponse blockedReason.
             * @member {string} blockedReason
             * @memberof ca2auth.StartResponse
             * @instance
             */
            StartResponse.prototype.blockedReason = "";
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * StartResponse next.
             * @member {"startStep"|"enterCodeStep"|"enterPasswordStep"|undefined} next
             * @memberof ca2auth.StartResponse
             * @instance
             */
            Object.defineProperty(StartResponse.prototype, "next", {
                get: $util.oneOfGetter($oneOfFields = ["startStep", "enterCodeStep", "enterPasswordStep"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new StartResponse instance using the specified properties.
             * @function create
             * @memberof ca2auth.StartResponse
             * @static
             * @param {ca2auth.IStartResponse=} [properties] Properties to set
             * @returns {ca2auth.StartResponse} StartResponse instance
             */
            StartResponse.create = function create(properties) {
                return new StartResponse(properties);
            };
    
            /**
             * Encodes the specified StartResponse message. Does not implicitly {@link ca2auth.StartResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.StartResponse
             * @static
             * @param {ca2auth.IStartResponse} message StartResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.enterCodeStep != null && Object.hasOwnProperty.call(message, "enterCodeStep"))
                    $root.ca2auth.EnterCodeStep.encode(message.enterCodeStep, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.enterPasswordStep != null && Object.hasOwnProperty.call(message, "enterPasswordStep"))
                    $root.ca2auth.EnterPasswordStep.encode(message.enterPasswordStep, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.startStep != null && Object.hasOwnProperty.call(message, "startStep"))
                    $root.ca2auth.StartStep.encode(message.startStep, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isNewUser != null && Object.hasOwnProperty.call(message, "isNewUser"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isNewUser);
                if (message.blockedReason != null && Object.hasOwnProperty.call(message, "blockedReason"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.blockedReason);
                return writer;
            };
    
            /**
             * Encodes the specified StartResponse message, length delimited. Does not implicitly {@link ca2auth.StartResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.StartResponse
             * @static
             * @param {ca2auth.IStartResponse} message StartResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StartResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.StartResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.StartResponse} StartResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.StartResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 4: {
                            message.startStep = $root.ca2auth.StartStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.enterCodeStep = $root.ca2auth.EnterCodeStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.enterPasswordStep = $root.ca2auth.EnterPasswordStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.isNewUser = reader.bool();
                            break;
                        }
                    case 6: {
                            message.blockedReason = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StartResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.StartResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.StartResponse} StartResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StartResponse message.
             * @function verify
             * @memberof ca2auth.StartResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                }
                if (message.startStep != null && message.hasOwnProperty("startStep")) {
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.StartStep.verify(message.startStep);
                        if (error)
                            return "startStep." + error;
                    }
                }
                if (message.enterCodeStep != null && message.hasOwnProperty("enterCodeStep")) {
                    if (properties.next === 1)
                        return "next: multiple values";
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.EnterCodeStep.verify(message.enterCodeStep);
                        if (error)
                            return "enterCodeStep." + error;
                    }
                }
                if (message.enterPasswordStep != null && message.hasOwnProperty("enterPasswordStep")) {
                    if (properties.next === 1)
                        return "next: multiple values";
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.EnterPasswordStep.verify(message.enterPasswordStep);
                        if (error)
                            return "enterPasswordStep." + error;
                    }
                }
                if (message.isNewUser != null && message.hasOwnProperty("isNewUser"))
                    if (typeof message.isNewUser !== "boolean")
                        return "isNewUser: boolean expected";
                if (message.blockedReason != null && message.hasOwnProperty("blockedReason"))
                    if (!$util.isString(message.blockedReason))
                        return "blockedReason: string expected";
                return null;
            };
    
            /**
             * Creates a StartResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.StartResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.StartResponse} StartResponse
             */
            StartResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.StartResponse)
                    return object;
                var message = new $root.ca2auth.StartResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2auth.StartResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "SE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "SE_INVALID_EMAIL_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "SE_INVALID_RECAPTCHA_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "SE_INVALID_REFERRAL_CODE_ERROR":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "SE_INVALID_BEARER_JWT_TOKEN_ERROR":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        case "SE_USER_IS_BLOCKED_ERROR":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        }
                }
                if (object.startStep != null) {
                    if (typeof object.startStep !== "object")
                        throw TypeError(".ca2auth.StartResponse.startStep: object expected");
                    message.startStep = $root.ca2auth.StartStep.fromObject(object.startStep);
                }
                if (object.enterCodeStep != null) {
                    if (typeof object.enterCodeStep !== "object")
                        throw TypeError(".ca2auth.StartResponse.enterCodeStep: object expected");
                    message.enterCodeStep = $root.ca2auth.EnterCodeStep.fromObject(object.enterCodeStep);
                }
                if (object.enterPasswordStep != null) {
                    if (typeof object.enterPasswordStep !== "object")
                        throw TypeError(".ca2auth.StartResponse.enterPasswordStep: object expected");
                    message.enterPasswordStep = $root.ca2auth.EnterPasswordStep.fromObject(object.enterPasswordStep);
                }
                if (object.isNewUser != null)
                    message.isNewUser = Boolean(object.isNewUser);
                if (object.blockedReason != null)
                    message.blockedReason = String(object.blockedReason);
                return message;
            };
    
            /**
             * Creates a plain object from a StartResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.StartResponse
             * @static
             * @param {ca2auth.StartResponse} message StartResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults) {
                    object.isNewUser = false;
                    object.blockedReason = "";
                }
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2auth.StartError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2auth.StartError[message.errors[j]] : message.errors[j];
                }
                if (message.enterCodeStep != null && message.hasOwnProperty("enterCodeStep")) {
                    object.enterCodeStep = $root.ca2auth.EnterCodeStep.toObject(message.enterCodeStep, options);
                    if (options.oneofs)
                        object.next = "enterCodeStep";
                }
                if (message.enterPasswordStep != null && message.hasOwnProperty("enterPasswordStep")) {
                    object.enterPasswordStep = $root.ca2auth.EnterPasswordStep.toObject(message.enterPasswordStep, options);
                    if (options.oneofs)
                        object.next = "enterPasswordStep";
                }
                if (message.startStep != null && message.hasOwnProperty("startStep")) {
                    object.startStep = $root.ca2auth.StartStep.toObject(message.startStep, options);
                    if (options.oneofs)
                        object.next = "startStep";
                }
                if (message.isNewUser != null && message.hasOwnProperty("isNewUser"))
                    object.isNewUser = message.isNewUser;
                if (message.blockedReason != null && message.hasOwnProperty("blockedReason"))
                    object.blockedReason = message.blockedReason;
                return object;
            };
    
            /**
             * Converts this StartResponse to JSON.
             * @function toJSON
             * @memberof ca2auth.StartResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StartResponse
             * @function getTypeUrl
             * @memberof ca2auth.StartResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.StartResponse";
            };
    
            return StartResponse;
        })();
    
        ca2auth.EnterPasswordRequest = (function() {
    
            /**
             * Properties of an EnterPasswordRequest.
             * @memberof ca2auth
             * @interface IEnterPasswordRequest
             * @property {string|null} [email] EnterPasswordRequest email
             * @property {string|null} [password] EnterPasswordRequest password
             */
    
            /**
             * Constructs a new EnterPasswordRequest.
             * @memberof ca2auth
             * @classdesc Represents an EnterPasswordRequest.
             * @implements IEnterPasswordRequest
             * @constructor
             * @param {ca2auth.IEnterPasswordRequest=} [properties] Properties to set
             */
            function EnterPasswordRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnterPasswordRequest email.
             * @member {string} email
             * @memberof ca2auth.EnterPasswordRequest
             * @instance
             */
            EnterPasswordRequest.prototype.email = "";
    
            /**
             * EnterPasswordRequest password.
             * @member {string} password
             * @memberof ca2auth.EnterPasswordRequest
             * @instance
             */
            EnterPasswordRequest.prototype.password = "";
    
            /**
             * Creates a new EnterPasswordRequest instance using the specified properties.
             * @function create
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {ca2auth.IEnterPasswordRequest=} [properties] Properties to set
             * @returns {ca2auth.EnterPasswordRequest} EnterPasswordRequest instance
             */
            EnterPasswordRequest.create = function create(properties) {
                return new EnterPasswordRequest(properties);
            };
    
            /**
             * Encodes the specified EnterPasswordRequest message. Does not implicitly {@link ca2auth.EnterPasswordRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {ca2auth.IEnterPasswordRequest} message EnterPasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterPasswordRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                return writer;
            };
    
            /**
             * Encodes the specified EnterPasswordRequest message, length delimited. Does not implicitly {@link ca2auth.EnterPasswordRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {ca2auth.IEnterPasswordRequest} message EnterPasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnterPasswordRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.EnterPasswordRequest} EnterPasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterPasswordRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.EnterPasswordRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.password = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnterPasswordRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.EnterPasswordRequest} EnterPasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnterPasswordRequest message.
             * @function verify
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnterPasswordRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                return null;
            };
    
            /**
             * Creates an EnterPasswordRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.EnterPasswordRequest} EnterPasswordRequest
             */
            EnterPasswordRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.EnterPasswordRequest)
                    return object;
                var message = new $root.ca2auth.EnterPasswordRequest();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.password != null)
                    message.password = String(object.password);
                return message;
            };
    
            /**
             * Creates a plain object from an EnterPasswordRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {ca2auth.EnterPasswordRequest} message EnterPasswordRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnterPasswordRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.password = "";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                return object;
            };
    
            /**
             * Converts this EnterPasswordRequest to JSON.
             * @function toJSON
             * @memberof ca2auth.EnterPasswordRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnterPasswordRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EnterPasswordRequest
             * @function getTypeUrl
             * @memberof ca2auth.EnterPasswordRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnterPasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.EnterPasswordRequest";
            };
    
            return EnterPasswordRequest;
        })();
    
        /**
         * EnterPasswordError enum.
         * @name ca2auth.EnterPasswordError
         * @enum {number}
         * @property {number} EPE_UNKNOWN_ERROR=0 EPE_UNKNOWN_ERROR value
         * @property {number} EPE_INVALID_EMAIL_ERROR=1 EPE_INVALID_EMAIL_ERROR value
         * @property {number} EPE_INVALID_PASSWORD_ERROR=2 EPE_INVALID_PASSWORD_ERROR value
         */
        ca2auth.EnterPasswordError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "EPE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "EPE_INVALID_EMAIL_ERROR"] = 1;
            values[valuesById[2] = "EPE_INVALID_PASSWORD_ERROR"] = 2;
            return values;
        })();
    
        ca2auth.EnterPasswordResponse = (function() {
    
            /**
             * Properties of an EnterPasswordResponse.
             * @memberof ca2auth
             * @interface IEnterPasswordResponse
             * @property {Array.<ca2auth.EnterPasswordError>|null} [errors] EnterPasswordResponse errors
             * @property {ca2auth.IEnterPasswordStep|null} [enterPasswordStep] EnterPasswordResponse enterPasswordStep
             * @property {ca2auth.IStartStep|null} [startStep] EnterPasswordResponse startStep
             * @property {ca2auth.ISuccessStep|null} [successStep] EnterPasswordResponse successStep
             */
    
            /**
             * Constructs a new EnterPasswordResponse.
             * @memberof ca2auth
             * @classdesc Represents an EnterPasswordResponse.
             * @implements IEnterPasswordResponse
             * @constructor
             * @param {ca2auth.IEnterPasswordResponse=} [properties] Properties to set
             */
            function EnterPasswordResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnterPasswordResponse errors.
             * @member {Array.<ca2auth.EnterPasswordError>} errors
             * @memberof ca2auth.EnterPasswordResponse
             * @instance
             */
            EnterPasswordResponse.prototype.errors = $util.emptyArray;
    
            /**
             * EnterPasswordResponse enterPasswordStep.
             * @member {ca2auth.IEnterPasswordStep|null|undefined} enterPasswordStep
             * @memberof ca2auth.EnterPasswordResponse
             * @instance
             */
            EnterPasswordResponse.prototype.enterPasswordStep = null;
    
            /**
             * EnterPasswordResponse startStep.
             * @member {ca2auth.IStartStep|null|undefined} startStep
             * @memberof ca2auth.EnterPasswordResponse
             * @instance
             */
            EnterPasswordResponse.prototype.startStep = null;
    
            /**
             * EnterPasswordResponse successStep.
             * @member {ca2auth.ISuccessStep|null|undefined} successStep
             * @memberof ca2auth.EnterPasswordResponse
             * @instance
             */
            EnterPasswordResponse.prototype.successStep = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * EnterPasswordResponse next.
             * @member {"enterPasswordStep"|"startStep"|"successStep"|undefined} next
             * @memberof ca2auth.EnterPasswordResponse
             * @instance
             */
            Object.defineProperty(EnterPasswordResponse.prototype, "next", {
                get: $util.oneOfGetter($oneOfFields = ["enterPasswordStep", "startStep", "successStep"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new EnterPasswordResponse instance using the specified properties.
             * @function create
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {ca2auth.IEnterPasswordResponse=} [properties] Properties to set
             * @returns {ca2auth.EnterPasswordResponse} EnterPasswordResponse instance
             */
            EnterPasswordResponse.create = function create(properties) {
                return new EnterPasswordResponse(properties);
            };
    
            /**
             * Encodes the specified EnterPasswordResponse message. Does not implicitly {@link ca2auth.EnterPasswordResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {ca2auth.IEnterPasswordResponse} message EnterPasswordResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterPasswordResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.enterPasswordStep != null && Object.hasOwnProperty.call(message, "enterPasswordStep"))
                    $root.ca2auth.EnterPasswordStep.encode(message.enterPasswordStep, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.successStep != null && Object.hasOwnProperty.call(message, "successStep"))
                    $root.ca2auth.SuccessStep.encode(message.successStep, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.startStep != null && Object.hasOwnProperty.call(message, "startStep"))
                    $root.ca2auth.StartStep.encode(message.startStep, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified EnterPasswordResponse message, length delimited. Does not implicitly {@link ca2auth.EnterPasswordResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {ca2auth.IEnterPasswordResponse} message EnterPasswordResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterPasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnterPasswordResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.EnterPasswordResponse} EnterPasswordResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterPasswordResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.EnterPasswordResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.enterPasswordStep = $root.ca2auth.EnterPasswordStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.startStep = $root.ca2auth.StartStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.successStep = $root.ca2auth.SuccessStep.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnterPasswordResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.EnterPasswordResponse} EnterPasswordResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterPasswordResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnterPasswordResponse message.
             * @function verify
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnterPasswordResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                }
                if (message.enterPasswordStep != null && message.hasOwnProperty("enterPasswordStep")) {
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.EnterPasswordStep.verify(message.enterPasswordStep);
                        if (error)
                            return "enterPasswordStep." + error;
                    }
                }
                if (message.startStep != null && message.hasOwnProperty("startStep")) {
                    if (properties.next === 1)
                        return "next: multiple values";
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.StartStep.verify(message.startStep);
                        if (error)
                            return "startStep." + error;
                    }
                }
                if (message.successStep != null && message.hasOwnProperty("successStep")) {
                    if (properties.next === 1)
                        return "next: multiple values";
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.SuccessStep.verify(message.successStep);
                        if (error)
                            return "successStep." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an EnterPasswordResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.EnterPasswordResponse} EnterPasswordResponse
             */
            EnterPasswordResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.EnterPasswordResponse)
                    return object;
                var message = new $root.ca2auth.EnterPasswordResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2auth.EnterPasswordResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "EPE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "EPE_INVALID_EMAIL_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "EPE_INVALID_PASSWORD_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        }
                }
                if (object.enterPasswordStep != null) {
                    if (typeof object.enterPasswordStep !== "object")
                        throw TypeError(".ca2auth.EnterPasswordResponse.enterPasswordStep: object expected");
                    message.enterPasswordStep = $root.ca2auth.EnterPasswordStep.fromObject(object.enterPasswordStep);
                }
                if (object.startStep != null) {
                    if (typeof object.startStep !== "object")
                        throw TypeError(".ca2auth.EnterPasswordResponse.startStep: object expected");
                    message.startStep = $root.ca2auth.StartStep.fromObject(object.startStep);
                }
                if (object.successStep != null) {
                    if (typeof object.successStep !== "object")
                        throw TypeError(".ca2auth.EnterPasswordResponse.successStep: object expected");
                    message.successStep = $root.ca2auth.SuccessStep.fromObject(object.successStep);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EnterPasswordResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {ca2auth.EnterPasswordResponse} message EnterPasswordResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnterPasswordResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2auth.EnterPasswordError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2auth.EnterPasswordError[message.errors[j]] : message.errors[j];
                }
                if (message.enterPasswordStep != null && message.hasOwnProperty("enterPasswordStep")) {
                    object.enterPasswordStep = $root.ca2auth.EnterPasswordStep.toObject(message.enterPasswordStep, options);
                    if (options.oneofs)
                        object.next = "enterPasswordStep";
                }
                if (message.successStep != null && message.hasOwnProperty("successStep")) {
                    object.successStep = $root.ca2auth.SuccessStep.toObject(message.successStep, options);
                    if (options.oneofs)
                        object.next = "successStep";
                }
                if (message.startStep != null && message.hasOwnProperty("startStep")) {
                    object.startStep = $root.ca2auth.StartStep.toObject(message.startStep, options);
                    if (options.oneofs)
                        object.next = "startStep";
                }
                return object;
            };
    
            /**
             * Converts this EnterPasswordResponse to JSON.
             * @function toJSON
             * @memberof ca2auth.EnterPasswordResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnterPasswordResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EnterPasswordResponse
             * @function getTypeUrl
             * @memberof ca2auth.EnterPasswordResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnterPasswordResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.EnterPasswordResponse";
            };
    
            return EnterPasswordResponse;
        })();
    
        ca2auth.EnterCodeRequest = (function() {
    
            /**
             * Properties of an EnterCodeRequest.
             * @memberof ca2auth
             * @interface IEnterCodeRequest
             * @property {string|null} [email] EnterCodeRequest email
             * @property {string|null} [code] EnterCodeRequest code
             */
    
            /**
             * Constructs a new EnterCodeRequest.
             * @memberof ca2auth
             * @classdesc Represents an EnterCodeRequest.
             * @implements IEnterCodeRequest
             * @constructor
             * @param {ca2auth.IEnterCodeRequest=} [properties] Properties to set
             */
            function EnterCodeRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnterCodeRequest email.
             * @member {string} email
             * @memberof ca2auth.EnterCodeRequest
             * @instance
             */
            EnterCodeRequest.prototype.email = "";
    
            /**
             * EnterCodeRequest code.
             * @member {string} code
             * @memberof ca2auth.EnterCodeRequest
             * @instance
             */
            EnterCodeRequest.prototype.code = "";
    
            /**
             * Creates a new EnterCodeRequest instance using the specified properties.
             * @function create
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {ca2auth.IEnterCodeRequest=} [properties] Properties to set
             * @returns {ca2auth.EnterCodeRequest} EnterCodeRequest instance
             */
            EnterCodeRequest.create = function create(properties) {
                return new EnterCodeRequest(properties);
            };
    
            /**
             * Encodes the specified EnterCodeRequest message. Does not implicitly {@link ca2auth.EnterCodeRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {ca2auth.IEnterCodeRequest} message EnterCodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterCodeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                return writer;
            };
    
            /**
             * Encodes the specified EnterCodeRequest message, length delimited. Does not implicitly {@link ca2auth.EnterCodeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {ca2auth.IEnterCodeRequest} message EnterCodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnterCodeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.EnterCodeRequest} EnterCodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterCodeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.EnterCodeRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.code = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnterCodeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.EnterCodeRequest} EnterCodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterCodeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnterCodeRequest message.
             * @function verify
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnterCodeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                return null;
            };
    
            /**
             * Creates an EnterCodeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.EnterCodeRequest} EnterCodeRequest
             */
            EnterCodeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.EnterCodeRequest)
                    return object;
                var message = new $root.ca2auth.EnterCodeRequest();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.code != null)
                    message.code = String(object.code);
                return message;
            };
    
            /**
             * Creates a plain object from an EnterCodeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {ca2auth.EnterCodeRequest} message EnterCodeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnterCodeRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.code = "";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };
    
            /**
             * Converts this EnterCodeRequest to JSON.
             * @function toJSON
             * @memberof ca2auth.EnterCodeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnterCodeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EnterCodeRequest
             * @function getTypeUrl
             * @memberof ca2auth.EnterCodeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnterCodeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.EnterCodeRequest";
            };
    
            return EnterCodeRequest;
        })();
    
        /**
         * EnterCodeError enum.
         * @name ca2auth.EnterCodeError
         * @enum {number}
         * @property {number} ECE_UNKNOWN_ERROR=0 ECE_UNKNOWN_ERROR value
         * @property {number} ECE_INVALID_EMAIL_ERROR=1 ECE_INVALID_EMAIL_ERROR value
         * @property {number} ECE_INVALID_CODE_ERROR=2 ECE_INVALID_CODE_ERROR value
         * @property {number} ECE_CODE_EXPIRED_ERROR=3 ECE_CODE_EXPIRED_ERROR value
         * @property {number} ECE_USER_NOT_FOUND_ERROR=5 ECE_USER_NOT_FOUND_ERROR value
         * @property {number} ECE_TOO_MANY_ATTEMPTS_ERROR=4 ECE_TOO_MANY_ATTEMPTS_ERROR value
         */
        ca2auth.EnterCodeError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ECE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "ECE_INVALID_EMAIL_ERROR"] = 1;
            values[valuesById[2] = "ECE_INVALID_CODE_ERROR"] = 2;
            values[valuesById[3] = "ECE_CODE_EXPIRED_ERROR"] = 3;
            values[valuesById[5] = "ECE_USER_NOT_FOUND_ERROR"] = 5;
            values[valuesById[4] = "ECE_TOO_MANY_ATTEMPTS_ERROR"] = 4;
            return values;
        })();
    
        ca2auth.EnterCodeResponse = (function() {
    
            /**
             * Properties of an EnterCodeResponse.
             * @memberof ca2auth
             * @interface IEnterCodeResponse
             * @property {Array.<ca2auth.EnterCodeError>|null} [errors] EnterCodeResponse errors
             * @property {ca2auth.IStartStep|null} [startStep] EnterCodeResponse startStep
             * @property {ca2auth.IEnterCodeStep|null} [enterCodeStep] EnterCodeResponse enterCodeStep
             * @property {ca2auth.IEnterPasswordStep|null} [enterPasswordStep] EnterCodeResponse enterPasswordStep
             * @property {ca2auth.ISuccessStep|null} [successStep] EnterCodeResponse successStep
             */
    
            /**
             * Constructs a new EnterCodeResponse.
             * @memberof ca2auth
             * @classdesc Represents an EnterCodeResponse.
             * @implements IEnterCodeResponse
             * @constructor
             * @param {ca2auth.IEnterCodeResponse=} [properties] Properties to set
             */
            function EnterCodeResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnterCodeResponse errors.
             * @member {Array.<ca2auth.EnterCodeError>} errors
             * @memberof ca2auth.EnterCodeResponse
             * @instance
             */
            EnterCodeResponse.prototype.errors = $util.emptyArray;
    
            /**
             * EnterCodeResponse startStep.
             * @member {ca2auth.IStartStep|null|undefined} startStep
             * @memberof ca2auth.EnterCodeResponse
             * @instance
             */
            EnterCodeResponse.prototype.startStep = null;
    
            /**
             * EnterCodeResponse enterCodeStep.
             * @member {ca2auth.IEnterCodeStep|null|undefined} enterCodeStep
             * @memberof ca2auth.EnterCodeResponse
             * @instance
             */
            EnterCodeResponse.prototype.enterCodeStep = null;
    
            /**
             * EnterCodeResponse enterPasswordStep.
             * @member {ca2auth.IEnterPasswordStep|null|undefined} enterPasswordStep
             * @memberof ca2auth.EnterCodeResponse
             * @instance
             */
            EnterCodeResponse.prototype.enterPasswordStep = null;
    
            /**
             * EnterCodeResponse successStep.
             * @member {ca2auth.ISuccessStep|null|undefined} successStep
             * @memberof ca2auth.EnterCodeResponse
             * @instance
             */
            EnterCodeResponse.prototype.successStep = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * EnterCodeResponse next.
             * @member {"startStep"|"enterCodeStep"|"enterPasswordStep"|"successStep"|undefined} next
             * @memberof ca2auth.EnterCodeResponse
             * @instance
             */
            Object.defineProperty(EnterCodeResponse.prototype, "next", {
                get: $util.oneOfGetter($oneOfFields = ["startStep", "enterCodeStep", "enterPasswordStep", "successStep"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new EnterCodeResponse instance using the specified properties.
             * @function create
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {ca2auth.IEnterCodeResponse=} [properties] Properties to set
             * @returns {ca2auth.EnterCodeResponse} EnterCodeResponse instance
             */
            EnterCodeResponse.create = function create(properties) {
                return new EnterCodeResponse(properties);
            };
    
            /**
             * Encodes the specified EnterCodeResponse message. Does not implicitly {@link ca2auth.EnterCodeResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {ca2auth.IEnterCodeResponse} message EnterCodeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterCodeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.enterCodeStep != null && Object.hasOwnProperty.call(message, "enterCodeStep"))
                    $root.ca2auth.EnterCodeStep.encode(message.enterCodeStep, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.successStep != null && Object.hasOwnProperty.call(message, "successStep"))
                    $root.ca2auth.SuccessStep.encode(message.successStep, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.startStep != null && Object.hasOwnProperty.call(message, "startStep"))
                    $root.ca2auth.StartStep.encode(message.startStep, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enterPasswordStep != null && Object.hasOwnProperty.call(message, "enterPasswordStep"))
                    $root.ca2auth.EnterPasswordStep.encode(message.enterPasswordStep, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified EnterCodeResponse message, length delimited. Does not implicitly {@link ca2auth.EnterCodeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {ca2auth.IEnterCodeResponse} message EnterCodeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnterCodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnterCodeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.EnterCodeResponse} EnterCodeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterCodeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.EnterCodeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 4: {
                            message.startStep = $root.ca2auth.StartStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.enterCodeStep = $root.ca2auth.EnterCodeStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.enterPasswordStep = $root.ca2auth.EnterPasswordStep.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.successStep = $root.ca2auth.SuccessStep.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnterCodeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.EnterCodeResponse} EnterCodeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnterCodeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnterCodeResponse message.
             * @function verify
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnterCodeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 5:
                        case 4:
                            break;
                        }
                }
                if (message.startStep != null && message.hasOwnProperty("startStep")) {
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.StartStep.verify(message.startStep);
                        if (error)
                            return "startStep." + error;
                    }
                }
                if (message.enterCodeStep != null && message.hasOwnProperty("enterCodeStep")) {
                    if (properties.next === 1)
                        return "next: multiple values";
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.EnterCodeStep.verify(message.enterCodeStep);
                        if (error)
                            return "enterCodeStep." + error;
                    }
                }
                if (message.enterPasswordStep != null && message.hasOwnProperty("enterPasswordStep")) {
                    if (properties.next === 1)
                        return "next: multiple values";
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.EnterPasswordStep.verify(message.enterPasswordStep);
                        if (error)
                            return "enterPasswordStep." + error;
                    }
                }
                if (message.successStep != null && message.hasOwnProperty("successStep")) {
                    if (properties.next === 1)
                        return "next: multiple values";
                    properties.next = 1;
                    {
                        var error = $root.ca2auth.SuccessStep.verify(message.successStep);
                        if (error)
                            return "successStep." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an EnterCodeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.EnterCodeResponse} EnterCodeResponse
             */
            EnterCodeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.EnterCodeResponse)
                    return object;
                var message = new $root.ca2auth.EnterCodeResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2auth.EnterCodeResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "ECE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "ECE_INVALID_EMAIL_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "ECE_INVALID_CODE_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "ECE_CODE_EXPIRED_ERROR":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "ECE_USER_NOT_FOUND_ERROR":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        case "ECE_TOO_MANY_ATTEMPTS_ERROR":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        }
                }
                if (object.startStep != null) {
                    if (typeof object.startStep !== "object")
                        throw TypeError(".ca2auth.EnterCodeResponse.startStep: object expected");
                    message.startStep = $root.ca2auth.StartStep.fromObject(object.startStep);
                }
                if (object.enterCodeStep != null) {
                    if (typeof object.enterCodeStep !== "object")
                        throw TypeError(".ca2auth.EnterCodeResponse.enterCodeStep: object expected");
                    message.enterCodeStep = $root.ca2auth.EnterCodeStep.fromObject(object.enterCodeStep);
                }
                if (object.enterPasswordStep != null) {
                    if (typeof object.enterPasswordStep !== "object")
                        throw TypeError(".ca2auth.EnterCodeResponse.enterPasswordStep: object expected");
                    message.enterPasswordStep = $root.ca2auth.EnterPasswordStep.fromObject(object.enterPasswordStep);
                }
                if (object.successStep != null) {
                    if (typeof object.successStep !== "object")
                        throw TypeError(".ca2auth.EnterCodeResponse.successStep: object expected");
                    message.successStep = $root.ca2auth.SuccessStep.fromObject(object.successStep);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EnterCodeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {ca2auth.EnterCodeResponse} message EnterCodeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnterCodeResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2auth.EnterCodeError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2auth.EnterCodeError[message.errors[j]] : message.errors[j];
                }
                if (message.enterCodeStep != null && message.hasOwnProperty("enterCodeStep")) {
                    object.enterCodeStep = $root.ca2auth.EnterCodeStep.toObject(message.enterCodeStep, options);
                    if (options.oneofs)
                        object.next = "enterCodeStep";
                }
                if (message.successStep != null && message.hasOwnProperty("successStep")) {
                    object.successStep = $root.ca2auth.SuccessStep.toObject(message.successStep, options);
                    if (options.oneofs)
                        object.next = "successStep";
                }
                if (message.startStep != null && message.hasOwnProperty("startStep")) {
                    object.startStep = $root.ca2auth.StartStep.toObject(message.startStep, options);
                    if (options.oneofs)
                        object.next = "startStep";
                }
                if (message.enterPasswordStep != null && message.hasOwnProperty("enterPasswordStep")) {
                    object.enterPasswordStep = $root.ca2auth.EnterPasswordStep.toObject(message.enterPasswordStep, options);
                    if (options.oneofs)
                        object.next = "enterPasswordStep";
                }
                return object;
            };
    
            /**
             * Converts this EnterCodeResponse to JSON.
             * @function toJSON
             * @memberof ca2auth.EnterCodeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnterCodeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EnterCodeResponse
             * @function getTypeUrl
             * @memberof ca2auth.EnterCodeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnterCodeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.EnterCodeResponse";
            };
    
            return EnterCodeResponse;
        })();
    
        ca2auth.LogoutRequest = (function() {
    
            /**
             * Properties of a LogoutRequest.
             * @memberof ca2auth
             * @interface ILogoutRequest
             */
    
            /**
             * Constructs a new LogoutRequest.
             * @memberof ca2auth
             * @classdesc Represents a LogoutRequest.
             * @implements ILogoutRequest
             * @constructor
             * @param {ca2auth.ILogoutRequest=} [properties] Properties to set
             */
            function LogoutRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new LogoutRequest instance using the specified properties.
             * @function create
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {ca2auth.ILogoutRequest=} [properties] Properties to set
             * @returns {ca2auth.LogoutRequest} LogoutRequest instance
             */
            LogoutRequest.create = function create(properties) {
                return new LogoutRequest(properties);
            };
    
            /**
             * Encodes the specified LogoutRequest message. Does not implicitly {@link ca2auth.LogoutRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {ca2auth.ILogoutRequest} message LogoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified LogoutRequest message, length delimited. Does not implicitly {@link ca2auth.LogoutRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {ca2auth.ILogoutRequest} message LogoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a LogoutRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.LogoutRequest} LogoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.LogoutRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a LogoutRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.LogoutRequest} LogoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a LogoutRequest message.
             * @function verify
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogoutRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a LogoutRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.LogoutRequest} LogoutRequest
             */
            LogoutRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.LogoutRequest)
                    return object;
                return new $root.ca2auth.LogoutRequest();
            };
    
            /**
             * Creates a plain object from a LogoutRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {ca2auth.LogoutRequest} message LogoutRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogoutRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this LogoutRequest to JSON.
             * @function toJSON
             * @memberof ca2auth.LogoutRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogoutRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for LogoutRequest
             * @function getTypeUrl
             * @memberof ca2auth.LogoutRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LogoutRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.LogoutRequest";
            };
    
            return LogoutRequest;
        })();
    
        ca2auth.LogoutResponse = (function() {
    
            /**
             * Properties of a LogoutResponse.
             * @memberof ca2auth
             * @interface ILogoutResponse
             */
    
            /**
             * Constructs a new LogoutResponse.
             * @memberof ca2auth
             * @classdesc Represents a LogoutResponse.
             * @implements ILogoutResponse
             * @constructor
             * @param {ca2auth.ILogoutResponse=} [properties] Properties to set
             */
            function LogoutResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new LogoutResponse instance using the specified properties.
             * @function create
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {ca2auth.ILogoutResponse=} [properties] Properties to set
             * @returns {ca2auth.LogoutResponse} LogoutResponse instance
             */
            LogoutResponse.create = function create(properties) {
                return new LogoutResponse(properties);
            };
    
            /**
             * Encodes the specified LogoutResponse message. Does not implicitly {@link ca2auth.LogoutResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {ca2auth.ILogoutResponse} message LogoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified LogoutResponse message, length delimited. Does not implicitly {@link ca2auth.LogoutResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {ca2auth.ILogoutResponse} message LogoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a LogoutResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.LogoutResponse} LogoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.LogoutResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a LogoutResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.LogoutResponse} LogoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a LogoutResponse message.
             * @function verify
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogoutResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a LogoutResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.LogoutResponse} LogoutResponse
             */
            LogoutResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.LogoutResponse)
                    return object;
                return new $root.ca2auth.LogoutResponse();
            };
    
            /**
             * Creates a plain object from a LogoutResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {ca2auth.LogoutResponse} message LogoutResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogoutResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this LogoutResponse to JSON.
             * @function toJSON
             * @memberof ca2auth.LogoutResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogoutResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for LogoutResponse
             * @function getTypeUrl
             * @memberof ca2auth.LogoutResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LogoutResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.LogoutResponse";
            };
    
            return LogoutResponse;
        })();
    
        ca2auth.OAuthRequest = (function() {
    
            /**
             * Properties of a OAuthRequest.
             * @memberof ca2auth
             * @interface IOAuthRequest
             * @property {string|null} [bearerJwtToken] OAuthRequest bearerJwtToken
             * @property {ca2types.OAuthProviderType|null} [oauthProviderType] OAuthRequest oauthProviderType
             */
    
            /**
             * Constructs a new OAuthRequest.
             * @memberof ca2auth
             * @classdesc Represents a OAuthRequest.
             * @implements IOAuthRequest
             * @constructor
             * @param {ca2auth.IOAuthRequest=} [properties] Properties to set
             */
            function OAuthRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * OAuthRequest bearerJwtToken.
             * @member {string} bearerJwtToken
             * @memberof ca2auth.OAuthRequest
             * @instance
             */
            OAuthRequest.prototype.bearerJwtToken = "";
    
            /**
             * OAuthRequest oauthProviderType.
             * @member {ca2types.OAuthProviderType} oauthProviderType
             * @memberof ca2auth.OAuthRequest
             * @instance
             */
            OAuthRequest.prototype.oauthProviderType = 0;
    
            /**
             * Creates a new OAuthRequest instance using the specified properties.
             * @function create
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {ca2auth.IOAuthRequest=} [properties] Properties to set
             * @returns {ca2auth.OAuthRequest} OAuthRequest instance
             */
            OAuthRequest.create = function create(properties) {
                return new OAuthRequest(properties);
            };
    
            /**
             * Encodes the specified OAuthRequest message. Does not implicitly {@link ca2auth.OAuthRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {ca2auth.IOAuthRequest} message OAuthRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OAuthRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bearerJwtToken != null && Object.hasOwnProperty.call(message, "bearerJwtToken"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.bearerJwtToken);
                if (message.oauthProviderType != null && Object.hasOwnProperty.call(message, "oauthProviderType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.oauthProviderType);
                return writer;
            };
    
            /**
             * Encodes the specified OAuthRequest message, length delimited. Does not implicitly {@link ca2auth.OAuthRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {ca2auth.IOAuthRequest} message OAuthRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OAuthRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a OAuthRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.OAuthRequest} OAuthRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OAuthRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.OAuthRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.bearerJwtToken = reader.string();
                            break;
                        }
                    case 2: {
                            message.oauthProviderType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a OAuthRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.OAuthRequest} OAuthRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OAuthRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a OAuthRequest message.
             * @function verify
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OAuthRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bearerJwtToken != null && message.hasOwnProperty("bearerJwtToken"))
                    if (!$util.isString(message.bearerJwtToken))
                        return "bearerJwtToken: string expected";
                if (message.oauthProviderType != null && message.hasOwnProperty("oauthProviderType"))
                    switch (message.oauthProviderType) {
                    default:
                        return "oauthProviderType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a OAuthRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.OAuthRequest} OAuthRequest
             */
            OAuthRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.OAuthRequest)
                    return object;
                var message = new $root.ca2auth.OAuthRequest();
                if (object.bearerJwtToken != null)
                    message.bearerJwtToken = String(object.bearerJwtToken);
                switch (object.oauthProviderType) {
                default:
                    if (typeof object.oauthProviderType === "number") {
                        message.oauthProviderType = object.oauthProviderType;
                        break;
                    }
                    break;
                case "OP_UNKNOWN":
                case 0:
                    message.oauthProviderType = 0;
                    break;
                case "OP_GOOGLE":
                case 1:
                    message.oauthProviderType = 1;
                    break;
                case "OP_GITHUB":
                case 2:
                    message.oauthProviderType = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a OAuthRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {ca2auth.OAuthRequest} message OAuthRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OAuthRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.bearerJwtToken = "";
                    object.oauthProviderType = options.enums === String ? "OP_UNKNOWN" : 0;
                }
                if (message.bearerJwtToken != null && message.hasOwnProperty("bearerJwtToken"))
                    object.bearerJwtToken = message.bearerJwtToken;
                if (message.oauthProviderType != null && message.hasOwnProperty("oauthProviderType"))
                    object.oauthProviderType = options.enums === String ? $root.ca2types.OAuthProviderType[message.oauthProviderType] === undefined ? message.oauthProviderType : $root.ca2types.OAuthProviderType[message.oauthProviderType] : message.oauthProviderType;
                return object;
            };
    
            /**
             * Converts this OAuthRequest to JSON.
             * @function toJSON
             * @memberof ca2auth.OAuthRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OAuthRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for OAuthRequest
             * @function getTypeUrl
             * @memberof ca2auth.OAuthRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OAuthRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.OAuthRequest";
            };
    
            return OAuthRequest;
        })();
    
        /**
         * OAuthError enum.
         * @name ca2auth.OAuthError
         * @enum {number}
         * @property {number} OA_UNKNOWN_ERROR=0 OA_UNKNOWN_ERROR value
         * @property {number} OA_EMAIL_IS_NOT_ASSOCIATED=1 OA_EMAIL_IS_NOT_ASSOCIATED value
         * @property {number} OA_ERROR=2 OA_ERROR value
         * @property {number} OA_INVALID_REF_ID_PROVIDED=3 OA_INVALID_REF_ID_PROVIDED value
         * @property {number} OA_INVALID_EMAIL_ERROR=4 OA_INVALID_EMAIL_ERROR value
         */
        ca2auth.OAuthError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OA_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "OA_EMAIL_IS_NOT_ASSOCIATED"] = 1;
            values[valuesById[2] = "OA_ERROR"] = 2;
            values[valuesById[3] = "OA_INVALID_REF_ID_PROVIDED"] = 3;
            values[valuesById[4] = "OA_INVALID_EMAIL_ERROR"] = 4;
            return values;
        })();
    
        /**
         * OAuthLoginStatus enum.
         * @name ca2auth.OAuthLoginStatus
         * @enum {number}
         * @property {number} OALS_OK=0 OALS_OK value
         * @property {number} OALS_EMAIL_IS_NOT_ASSOCIATED=1 OALS_EMAIL_IS_NOT_ASSOCIATED value
         * @property {number} OALS_ERROR=2 OALS_ERROR value
         * @property {number} OALS_NEW_USER_OK=3 OALS_NEW_USER_OK value
         * @property {number} OALS_INVALID_REF_ID_PROVIDED=4 OALS_INVALID_REF_ID_PROVIDED value
         */
        ca2auth.OAuthLoginStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OALS_OK"] = 0;
            values[valuesById[1] = "OALS_EMAIL_IS_NOT_ASSOCIATED"] = 1;
            values[valuesById[2] = "OALS_ERROR"] = 2;
            values[valuesById[3] = "OALS_NEW_USER_OK"] = 3;
            values[valuesById[4] = "OALS_INVALID_REF_ID_PROVIDED"] = 4;
            return values;
        })();
    
        ca2auth.OAuthResponse = (function() {
    
            /**
             * Properties of a OAuthResponse.
             * @memberof ca2auth
             * @interface IOAuthResponse
             * @property {Array.<ca2auth.OAuthError>|null} [errors] OAuthResponse errors
             * @property {ca2auth.ISuccessStep|null} [successStep] OAuthResponse successStep
             */
    
            /**
             * Constructs a new OAuthResponse.
             * @memberof ca2auth
             * @classdesc Represents a OAuthResponse.
             * @implements IOAuthResponse
             * @constructor
             * @param {ca2auth.IOAuthResponse=} [properties] Properties to set
             */
            function OAuthResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * OAuthResponse errors.
             * @member {Array.<ca2auth.OAuthError>} errors
             * @memberof ca2auth.OAuthResponse
             * @instance
             */
            OAuthResponse.prototype.errors = $util.emptyArray;
    
            /**
             * OAuthResponse successStep.
             * @member {ca2auth.ISuccessStep|null|undefined} successStep
             * @memberof ca2auth.OAuthResponse
             * @instance
             */
            OAuthResponse.prototype.successStep = null;
    
            /**
             * Creates a new OAuthResponse instance using the specified properties.
             * @function create
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {ca2auth.IOAuthResponse=} [properties] Properties to set
             * @returns {ca2auth.OAuthResponse} OAuthResponse instance
             */
            OAuthResponse.create = function create(properties) {
                return new OAuthResponse(properties);
            };
    
            /**
             * Encodes the specified OAuthResponse message. Does not implicitly {@link ca2auth.OAuthResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {ca2auth.IOAuthResponse} message OAuthResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OAuthResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.successStep != null && Object.hasOwnProperty.call(message, "successStep"))
                    $root.ca2auth.SuccessStep.encode(message.successStep, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified OAuthResponse message, length delimited. Does not implicitly {@link ca2auth.OAuthResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {ca2auth.IOAuthResponse} message OAuthResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OAuthResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a OAuthResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2auth.OAuthResponse} OAuthResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OAuthResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2auth.OAuthResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.successStep = $root.ca2auth.SuccessStep.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a OAuthResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2auth.OAuthResponse} OAuthResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OAuthResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a OAuthResponse message.
             * @function verify
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OAuthResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                if (message.successStep != null && message.hasOwnProperty("successStep")) {
                    var error = $root.ca2auth.SuccessStep.verify(message.successStep);
                    if (error)
                        return "successStep." + error;
                }
                return null;
            };
    
            /**
             * Creates a OAuthResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2auth.OAuthResponse} OAuthResponse
             */
            OAuthResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2auth.OAuthResponse)
                    return object;
                var message = new $root.ca2auth.OAuthResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2auth.OAuthResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "OA_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "OA_EMAIL_IS_NOT_ASSOCIATED":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "OA_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "OA_INVALID_REF_ID_PROVIDED":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "OA_INVALID_EMAIL_ERROR":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        }
                }
                if (object.successStep != null) {
                    if (typeof object.successStep !== "object")
                        throw TypeError(".ca2auth.OAuthResponse.successStep: object expected");
                    message.successStep = $root.ca2auth.SuccessStep.fromObject(object.successStep);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a OAuthResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {ca2auth.OAuthResponse} message OAuthResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OAuthResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.successStep = null;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2auth.OAuthError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2auth.OAuthError[message.errors[j]] : message.errors[j];
                }
                if (message.successStep != null && message.hasOwnProperty("successStep"))
                    object.successStep = $root.ca2auth.SuccessStep.toObject(message.successStep, options);
                return object;
            };
    
            /**
             * Converts this OAuthResponse to JSON.
             * @function toJSON
             * @memberof ca2auth.OAuthResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OAuthResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for OAuthResponse
             * @function getTypeUrl
             * @memberof ca2auth.OAuthResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OAuthResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2auth.OAuthResponse";
            };
    
            return OAuthResponse;
        })();
    
        return ca2auth;
    })();
    
    $root.ca2types = (function() {
    
        /**
         * Namespace ca2types.
         * @exports ca2types
         * @namespace
         */
        var ca2types = {};
    
        ca2types.Amount = (function() {
    
            /**
             * Properties of an Amount.
             * @memberof ca2types
             * @interface IAmount
             * @property {Long|null} [points] Amount points
             * @property {string|null} [formatted] Amount formatted
             */
    
            /**
             * Constructs a new Amount.
             * @memberof ca2types
             * @classdesc Represents an Amount.
             * @implements IAmount
             * @constructor
             * @param {ca2types.IAmount=} [properties] Properties to set
             */
            function Amount(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Amount points.
             * @member {Long} points
             * @memberof ca2types.Amount
             * @instance
             */
            Amount.prototype.points = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Amount formatted.
             * @member {string} formatted
             * @memberof ca2types.Amount
             * @instance
             */
            Amount.prototype.formatted = "";
    
            /**
             * Creates a new Amount instance using the specified properties.
             * @function create
             * @memberof ca2types.Amount
             * @static
             * @param {ca2types.IAmount=} [properties] Properties to set
             * @returns {ca2types.Amount} Amount instance
             */
            Amount.create = function create(properties) {
                return new Amount(properties);
            };
    
            /**
             * Encodes the specified Amount message. Does not implicitly {@link ca2types.Amount.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Amount
             * @static
             * @param {ca2types.IAmount} message Amount message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Amount.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.points != null && Object.hasOwnProperty.call(message, "points"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.points);
                if (message.formatted != null && Object.hasOwnProperty.call(message, "formatted"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.formatted);
                return writer;
            };
    
            /**
             * Encodes the specified Amount message, length delimited. Does not implicitly {@link ca2types.Amount.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Amount
             * @static
             * @param {ca2types.IAmount} message Amount message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Amount.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Amount message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Amount
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Amount} Amount
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Amount.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Amount();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.points = reader.int64();
                            break;
                        }
                    case 2: {
                            message.formatted = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Amount message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Amount
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Amount} Amount
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Amount.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Amount message.
             * @function verify
             * @memberof ca2types.Amount
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Amount.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.points != null && message.hasOwnProperty("points"))
                    if (!$util.isInteger(message.points) && !(message.points && $util.isInteger(message.points.low) && $util.isInteger(message.points.high)))
                        return "points: integer|Long expected";
                if (message.formatted != null && message.hasOwnProperty("formatted"))
                    if (!$util.isString(message.formatted))
                        return "formatted: string expected";
                return null;
            };
    
            /**
             * Creates an Amount message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Amount
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Amount} Amount
             */
            Amount.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Amount)
                    return object;
                var message = new $root.ca2types.Amount();
                if (object.points != null)
                    if ($util.Long)
                        (message.points = $util.Long.fromValue(object.points)).unsigned = false;
                    else if (typeof object.points === "string")
                        message.points = parseInt(object.points, 10);
                    else if (typeof object.points === "number")
                        message.points = object.points;
                    else if (typeof object.points === "object")
                        message.points = new $util.LongBits(object.points.low >>> 0, object.points.high >>> 0).toNumber();
                if (object.formatted != null)
                    message.formatted = String(object.formatted);
                return message;
            };
    
            /**
             * Creates a plain object from an Amount message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Amount
             * @static
             * @param {ca2types.Amount} message Amount
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Amount.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.points = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.points = options.longs === String ? "0" : 0;
                    object.formatted = "";
                }
                if (message.points != null && message.hasOwnProperty("points"))
                    if (typeof message.points === "number")
                        object.points = options.longs === String ? String(message.points) : message.points;
                    else
                        object.points = options.longs === String ? $util.Long.prototype.toString.call(message.points) : options.longs === Number ? new $util.LongBits(message.points.low >>> 0, message.points.high >>> 0).toNumber() : message.points;
                if (message.formatted != null && message.hasOwnProperty("formatted"))
                    object.formatted = message.formatted;
                return object;
            };
    
            /**
             * Converts this Amount to JSON.
             * @function toJSON
             * @memberof ca2types.Amount
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Amount.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Amount
             * @function getTypeUrl
             * @memberof ca2types.Amount
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Amount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Amount";
            };
    
            return Amount;
        })();
    
        ca2types.FloatRange = (function() {
    
            /**
             * Properties of a FloatRange.
             * @memberof ca2types
             * @interface IFloatRange
             * @property {number|null} [min] FloatRange min
             * @property {number|null} [max] FloatRange max
             */
    
            /**
             * Constructs a new FloatRange.
             * @memberof ca2types
             * @classdesc Represents a FloatRange.
             * @implements IFloatRange
             * @constructor
             * @param {ca2types.IFloatRange=} [properties] Properties to set
             */
            function FloatRange(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * FloatRange min.
             * @member {number} min
             * @memberof ca2types.FloatRange
             * @instance
             */
            FloatRange.prototype.min = 0;
    
            /**
             * FloatRange max.
             * @member {number} max
             * @memberof ca2types.FloatRange
             * @instance
             */
            FloatRange.prototype.max = 0;
    
            /**
             * Creates a new FloatRange instance using the specified properties.
             * @function create
             * @memberof ca2types.FloatRange
             * @static
             * @param {ca2types.IFloatRange=} [properties] Properties to set
             * @returns {ca2types.FloatRange} FloatRange instance
             */
            FloatRange.create = function create(properties) {
                return new FloatRange(properties);
            };
    
            /**
             * Encodes the specified FloatRange message. Does not implicitly {@link ca2types.FloatRange.verify|verify} messages.
             * @function encode
             * @memberof ca2types.FloatRange
             * @static
             * @param {ca2types.IFloatRange} message FloatRange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatRange.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.min);
                if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.max);
                return writer;
            };
    
            /**
             * Encodes the specified FloatRange message, length delimited. Does not implicitly {@link ca2types.FloatRange.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.FloatRange
             * @static
             * @param {ca2types.IFloatRange} message FloatRange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatRange.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a FloatRange message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.FloatRange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.FloatRange} FloatRange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatRange.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.FloatRange();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.min = reader.float();
                            break;
                        }
                    case 2: {
                            message.max = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a FloatRange message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.FloatRange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.FloatRange} FloatRange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatRange.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a FloatRange message.
             * @function verify
             * @memberof ca2types.FloatRange
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FloatRange.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.min != null && message.hasOwnProperty("min"))
                    if (typeof message.min !== "number")
                        return "min: number expected";
                if (message.max != null && message.hasOwnProperty("max"))
                    if (typeof message.max !== "number")
                        return "max: number expected";
                return null;
            };
    
            /**
             * Creates a FloatRange message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.FloatRange
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.FloatRange} FloatRange
             */
            FloatRange.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.FloatRange)
                    return object;
                var message = new $root.ca2types.FloatRange();
                if (object.min != null)
                    message.min = Number(object.min);
                if (object.max != null)
                    message.max = Number(object.max);
                return message;
            };
    
            /**
             * Creates a plain object from a FloatRange message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.FloatRange
             * @static
             * @param {ca2types.FloatRange} message FloatRange
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FloatRange.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.min = 0;
                    object.max = 0;
                }
                if (message.min != null && message.hasOwnProperty("min"))
                    object.min = options.json && !isFinite(message.min) ? String(message.min) : message.min;
                if (message.max != null && message.hasOwnProperty("max"))
                    object.max = options.json && !isFinite(message.max) ? String(message.max) : message.max;
                return object;
            };
    
            /**
             * Converts this FloatRange to JSON.
             * @function toJSON
             * @memberof ca2types.FloatRange
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FloatRange.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for FloatRange
             * @function getTypeUrl
             * @memberof ca2types.FloatRange
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FloatRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.FloatRange";
            };
    
            return FloatRange;
        })();
    
        ca2types.Uint64Range = (function() {
    
            /**
             * Properties of an Uint64Range.
             * @memberof ca2types
             * @interface IUint64Range
             * @property {Long|null} [min] Uint64Range min
             * @property {Long|null} [max] Uint64Range max
             */
    
            /**
             * Constructs a new Uint64Range.
             * @memberof ca2types
             * @classdesc Represents an Uint64Range.
             * @implements IUint64Range
             * @constructor
             * @param {ca2types.IUint64Range=} [properties] Properties to set
             */
            function Uint64Range(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Uint64Range min.
             * @member {Long} min
             * @memberof ca2types.Uint64Range
             * @instance
             */
            Uint64Range.prototype.min = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Uint64Range max.
             * @member {Long} max
             * @memberof ca2types.Uint64Range
             * @instance
             */
            Uint64Range.prototype.max = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Creates a new Uint64Range instance using the specified properties.
             * @function create
             * @memberof ca2types.Uint64Range
             * @static
             * @param {ca2types.IUint64Range=} [properties] Properties to set
             * @returns {ca2types.Uint64Range} Uint64Range instance
             */
            Uint64Range.create = function create(properties) {
                return new Uint64Range(properties);
            };
    
            /**
             * Encodes the specified Uint64Range message. Does not implicitly {@link ca2types.Uint64Range.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Uint64Range
             * @static
             * @param {ca2types.IUint64Range} message Uint64Range message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Uint64Range.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.min);
                if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.max);
                return writer;
            };
    
            /**
             * Encodes the specified Uint64Range message, length delimited. Does not implicitly {@link ca2types.Uint64Range.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Uint64Range
             * @static
             * @param {ca2types.IUint64Range} message Uint64Range message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Uint64Range.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Uint64Range message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Uint64Range
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Uint64Range} Uint64Range
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Uint64Range.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Uint64Range();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.min = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.max = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Uint64Range message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Uint64Range
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Uint64Range} Uint64Range
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Uint64Range.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Uint64Range message.
             * @function verify
             * @memberof ca2types.Uint64Range
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Uint64Range.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.min != null && message.hasOwnProperty("min"))
                    if (!$util.isInteger(message.min) && !(message.min && $util.isInteger(message.min.low) && $util.isInteger(message.min.high)))
                        return "min: integer|Long expected";
                if (message.max != null && message.hasOwnProperty("max"))
                    if (!$util.isInteger(message.max) && !(message.max && $util.isInteger(message.max.low) && $util.isInteger(message.max.high)))
                        return "max: integer|Long expected";
                return null;
            };
    
            /**
             * Creates an Uint64Range message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Uint64Range
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Uint64Range} Uint64Range
             */
            Uint64Range.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Uint64Range)
                    return object;
                var message = new $root.ca2types.Uint64Range();
                if (object.min != null)
                    if ($util.Long)
                        (message.min = $util.Long.fromValue(object.min)).unsigned = true;
                    else if (typeof object.min === "string")
                        message.min = parseInt(object.min, 10);
                    else if (typeof object.min === "number")
                        message.min = object.min;
                    else if (typeof object.min === "object")
                        message.min = new $util.LongBits(object.min.low >>> 0, object.min.high >>> 0).toNumber(true);
                if (object.max != null)
                    if ($util.Long)
                        (message.max = $util.Long.fromValue(object.max)).unsigned = true;
                    else if (typeof object.max === "string")
                        message.max = parseInt(object.max, 10);
                    else if (typeof object.max === "number")
                        message.max = object.max;
                    else if (typeof object.max === "object")
                        message.max = new $util.LongBits(object.max.low >>> 0, object.max.high >>> 0).toNumber(true);
                return message;
            };
    
            /**
             * Creates a plain object from an Uint64Range message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Uint64Range
             * @static
             * @param {ca2types.Uint64Range} message Uint64Range
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Uint64Range.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.min = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.min = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.max = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.max = options.longs === String ? "0" : 0;
                }
                if (message.min != null && message.hasOwnProperty("min"))
                    if (typeof message.min === "number")
                        object.min = options.longs === String ? String(message.min) : message.min;
                    else
                        object.min = options.longs === String ? $util.Long.prototype.toString.call(message.min) : options.longs === Number ? new $util.LongBits(message.min.low >>> 0, message.min.high >>> 0).toNumber(true) : message.min;
                if (message.max != null && message.hasOwnProperty("max"))
                    if (typeof message.max === "number")
                        object.max = options.longs === String ? String(message.max) : message.max;
                    else
                        object.max = options.longs === String ? $util.Long.prototype.toString.call(message.max) : options.longs === Number ? new $util.LongBits(message.max.low >>> 0, message.max.high >>> 0).toNumber(true) : message.max;
                return object;
            };
    
            /**
             * Converts this Uint64Range to JSON.
             * @function toJSON
             * @memberof ca2types.Uint64Range
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Uint64Range.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Uint64Range
             * @function getTypeUrl
             * @memberof ca2types.Uint64Range
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Uint64Range.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Uint64Range";
            };
    
            return Uint64Range;
        })();
    
        ca2types.Uint32Range = (function() {
    
            /**
             * Properties of an Uint32Range.
             * @memberof ca2types
             * @interface IUint32Range
             * @property {number|null} [min] Uint32Range min
             * @property {number|null} [max] Uint32Range max
             */
    
            /**
             * Constructs a new Uint32Range.
             * @memberof ca2types
             * @classdesc Represents an Uint32Range.
             * @implements IUint32Range
             * @constructor
             * @param {ca2types.IUint32Range=} [properties] Properties to set
             */
            function Uint32Range(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Uint32Range min.
             * @member {number} min
             * @memberof ca2types.Uint32Range
             * @instance
             */
            Uint32Range.prototype.min = 0;
    
            /**
             * Uint32Range max.
             * @member {number} max
             * @memberof ca2types.Uint32Range
             * @instance
             */
            Uint32Range.prototype.max = 0;
    
            /**
             * Creates a new Uint32Range instance using the specified properties.
             * @function create
             * @memberof ca2types.Uint32Range
             * @static
             * @param {ca2types.IUint32Range=} [properties] Properties to set
             * @returns {ca2types.Uint32Range} Uint32Range instance
             */
            Uint32Range.create = function create(properties) {
                return new Uint32Range(properties);
            };
    
            /**
             * Encodes the specified Uint32Range message. Does not implicitly {@link ca2types.Uint32Range.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Uint32Range
             * @static
             * @param {ca2types.IUint32Range} message Uint32Range message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Uint32Range.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.min);
                if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.max);
                return writer;
            };
    
            /**
             * Encodes the specified Uint32Range message, length delimited. Does not implicitly {@link ca2types.Uint32Range.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Uint32Range
             * @static
             * @param {ca2types.IUint32Range} message Uint32Range message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Uint32Range.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Uint32Range message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Uint32Range
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Uint32Range} Uint32Range
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Uint32Range.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Uint32Range();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.min = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.max = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Uint32Range message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Uint32Range
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Uint32Range} Uint32Range
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Uint32Range.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Uint32Range message.
             * @function verify
             * @memberof ca2types.Uint32Range
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Uint32Range.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.min != null && message.hasOwnProperty("min"))
                    if (!$util.isInteger(message.min))
                        return "min: integer expected";
                if (message.max != null && message.hasOwnProperty("max"))
                    if (!$util.isInteger(message.max))
                        return "max: integer expected";
                return null;
            };
    
            /**
             * Creates an Uint32Range message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Uint32Range
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Uint32Range} Uint32Range
             */
            Uint32Range.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Uint32Range)
                    return object;
                var message = new $root.ca2types.Uint32Range();
                if (object.min != null)
                    message.min = object.min >>> 0;
                if (object.max != null)
                    message.max = object.max >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an Uint32Range message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Uint32Range
             * @static
             * @param {ca2types.Uint32Range} message Uint32Range
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Uint32Range.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.min = 0;
                    object.max = 0;
                }
                if (message.min != null && message.hasOwnProperty("min"))
                    object.min = message.min;
                if (message.max != null && message.hasOwnProperty("max"))
                    object.max = message.max;
                return object;
            };
    
            /**
             * Converts this Uint32Range to JSON.
             * @function toJSON
             * @memberof ca2types.Uint32Range
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Uint32Range.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Uint32Range
             * @function getTypeUrl
             * @memberof ca2types.Uint32Range
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Uint32Range.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Uint32Range";
            };
    
            return Uint32Range;
        })();
    
        ca2types.Code = (function() {
    
            /**
             * Properties of a Code.
             * @memberof ca2types
             * @interface ICode
             * @property {number|null} [length] Code length
             * @property {Long|null} [validUntil] Code validUntil
             * @property {number|null} [numAttemptsLeft] Code numAttemptsLeft
             */
    
            /**
             * Constructs a new Code.
             * @memberof ca2types
             * @classdesc Represents a Code.
             * @implements ICode
             * @constructor
             * @param {ca2types.ICode=} [properties] Properties to set
             */
            function Code(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Code length.
             * @member {number} length
             * @memberof ca2types.Code
             * @instance
             */
            Code.prototype.length = 0;
    
            /**
             * Code validUntil.
             * @member {Long} validUntil
             * @memberof ca2types.Code
             * @instance
             */
            Code.prototype.validUntil = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Code numAttemptsLeft.
             * @member {number} numAttemptsLeft
             * @memberof ca2types.Code
             * @instance
             */
            Code.prototype.numAttemptsLeft = 0;
    
            /**
             * Creates a new Code instance using the specified properties.
             * @function create
             * @memberof ca2types.Code
             * @static
             * @param {ca2types.ICode=} [properties] Properties to set
             * @returns {ca2types.Code} Code instance
             */
            Code.create = function create(properties) {
                return new Code(properties);
            };
    
            /**
             * Encodes the specified Code message. Does not implicitly {@link ca2types.Code.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Code
             * @static
             * @param {ca2types.ICode} message Code message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Code.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.length);
                if (message.validUntil != null && Object.hasOwnProperty.call(message, "validUntil"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.validUntil);
                if (message.numAttemptsLeft != null && Object.hasOwnProperty.call(message, "numAttemptsLeft"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numAttemptsLeft);
                return writer;
            };
    
            /**
             * Encodes the specified Code message, length delimited. Does not implicitly {@link ca2types.Code.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Code
             * @static
             * @param {ca2types.ICode} message Code message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Code.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Code message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Code
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Code} Code
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Code.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Code();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.length = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.validUntil = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.numAttemptsLeft = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Code message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Code
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Code} Code
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Code.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Code message.
             * @function verify
             * @memberof ca2types.Code
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Code.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.length != null && message.hasOwnProperty("length"))
                    if (!$util.isInteger(message.length))
                        return "length: integer expected";
                if (message.validUntil != null && message.hasOwnProperty("validUntil"))
                    if (!$util.isInteger(message.validUntil) && !(message.validUntil && $util.isInteger(message.validUntil.low) && $util.isInteger(message.validUntil.high)))
                        return "validUntil: integer|Long expected";
                if (message.numAttemptsLeft != null && message.hasOwnProperty("numAttemptsLeft"))
                    if (!$util.isInteger(message.numAttemptsLeft))
                        return "numAttemptsLeft: integer expected";
                return null;
            };
    
            /**
             * Creates a Code message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Code
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Code} Code
             */
            Code.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Code)
                    return object;
                var message = new $root.ca2types.Code();
                if (object.length != null)
                    message.length = object.length >>> 0;
                if (object.validUntil != null)
                    if ($util.Long)
                        (message.validUntil = $util.Long.fromValue(object.validUntil)).unsigned = true;
                    else if (typeof object.validUntil === "string")
                        message.validUntil = parseInt(object.validUntil, 10);
                    else if (typeof object.validUntil === "number")
                        message.validUntil = object.validUntil;
                    else if (typeof object.validUntil === "object")
                        message.validUntil = new $util.LongBits(object.validUntil.low >>> 0, object.validUntil.high >>> 0).toNumber(true);
                if (object.numAttemptsLeft != null)
                    message.numAttemptsLeft = object.numAttemptsLeft >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a Code message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Code
             * @static
             * @param {ca2types.Code} message Code
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Code.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.length = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.validUntil = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.validUntil = options.longs === String ? "0" : 0;
                    object.numAttemptsLeft = 0;
                }
                if (message.length != null && message.hasOwnProperty("length"))
                    object.length = message.length;
                if (message.validUntil != null && message.hasOwnProperty("validUntil"))
                    if (typeof message.validUntil === "number")
                        object.validUntil = options.longs === String ? String(message.validUntil) : message.validUntil;
                    else
                        object.validUntil = options.longs === String ? $util.Long.prototype.toString.call(message.validUntil) : options.longs === Number ? new $util.LongBits(message.validUntil.low >>> 0, message.validUntil.high >>> 0).toNumber(true) : message.validUntil;
                if (message.numAttemptsLeft != null && message.hasOwnProperty("numAttemptsLeft"))
                    object.numAttemptsLeft = message.numAttemptsLeft;
                return object;
            };
    
            /**
             * Converts this Code to JSON.
             * @function toJSON
             * @memberof ca2types.Code
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Code.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Code
             * @function getTypeUrl
             * @memberof ca2types.Code
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Code.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Code";
            };
    
            return Code;
        })();
    
        ca2types.User = (function() {
    
            /**
             * Properties of a User.
             * @memberof ca2types
             * @interface IUser
             * @property {Long|null} [id] User id
             * @property {Long|null} [createdAt] User createdAt
             * @property {string|null} [email] User email
             * @property {boolean|null} [hasPassword] User hasPassword
             * @property {Long|null} [lastPasswordChangeAt] User lastPasswordChangeAt
             * @property {string|null} [fullName] User fullName
             * @property {string|null} [phone] User phone
             * @property {string|null} [billingAddress] User billingAddress
             * @property {string|null} [additionalBillingAddress] User additionalBillingAddress
             * @property {string|null} [taxId] User taxId
             * @property {string|null} [blockedReason] User blockedReason
             */
    
            /**
             * Constructs a new User.
             * @memberof ca2types
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {ca2types.IUser=} [properties] Properties to set
             */
            function User(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * User id.
             * @member {Long} id
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * User createdAt.
             * @member {Long} createdAt
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * User email.
             * @member {string} email
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.email = "";
    
            /**
             * User hasPassword.
             * @member {boolean} hasPassword
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.hasPassword = false;
    
            /**
             * User lastPasswordChangeAt.
             * @member {Long} lastPasswordChangeAt
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.lastPasswordChangeAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * User fullName.
             * @member {string} fullName
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.fullName = "";
    
            /**
             * User phone.
             * @member {string} phone
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.phone = "";
    
            /**
             * User billingAddress.
             * @member {string} billingAddress
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.billingAddress = "";
    
            /**
             * User additionalBillingAddress.
             * @member {string} additionalBillingAddress
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.additionalBillingAddress = "";
    
            /**
             * User taxId.
             * @member {string} taxId
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.taxId = "";
    
            /**
             * User blockedReason.
             * @member {string} blockedReason
             * @memberof ca2types.User
             * @instance
             */
            User.prototype.blockedReason = "";
    
            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof ca2types.User
             * @static
             * @param {ca2types.IUser=} [properties] Properties to set
             * @returns {ca2types.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties);
            };
    
            /**
             * Encodes the specified User message. Does not implicitly {@link ca2types.User.verify|verify} messages.
             * @function encode
             * @memberof ca2types.User
             * @static
             * @param {ca2types.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.createdAt);
                if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.fullName);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
                if (message.billingAddress != null && Object.hasOwnProperty.call(message, "billingAddress"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.billingAddress);
                if (message.additionalBillingAddress != null && Object.hasOwnProperty.call(message, "additionalBillingAddress"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.additionalBillingAddress);
                if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.taxId);
                if (message.hasPassword != null && Object.hasOwnProperty.call(message, "hasPassword"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.hasPassword);
                if (message.lastPasswordChangeAt != null && Object.hasOwnProperty.call(message, "lastPasswordChangeAt"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.lastPasswordChangeAt);
                if (message.blockedReason != null && Object.hasOwnProperty.call(message, "blockedReason"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.blockedReason);
                return writer;
            };
    
            /**
             * Encodes the specified User message, length delimited. Does not implicitly {@link ca2types.User.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.User
             * @static
             * @param {ca2types.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.User();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.createdAt = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.email = reader.string();
                            break;
                        }
                    case 9: {
                            message.hasPassword = reader.bool();
                            break;
                        }
                    case 10: {
                            message.lastPasswordChangeAt = reader.uint64();
                            break;
                        }
                    case 4: {
                            message.fullName = reader.string();
                            break;
                        }
                    case 5: {
                            message.phone = reader.string();
                            break;
                        }
                    case 6: {
                            message.billingAddress = reader.string();
                            break;
                        }
                    case 7: {
                            message.additionalBillingAddress = reader.string();
                            break;
                        }
                    case 8: {
                            message.taxId = reader.string();
                            break;
                        }
                    case 11: {
                            message.blockedReason = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a User message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a User message.
             * @function verify
             * @memberof ca2types.User
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            User.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.hasPassword != null && message.hasOwnProperty("hasPassword"))
                    if (typeof message.hasPassword !== "boolean")
                        return "hasPassword: boolean expected";
                if (message.lastPasswordChangeAt != null && message.hasOwnProperty("lastPasswordChangeAt"))
                    if (!$util.isInteger(message.lastPasswordChangeAt) && !(message.lastPasswordChangeAt && $util.isInteger(message.lastPasswordChangeAt.low) && $util.isInteger(message.lastPasswordChangeAt.high)))
                        return "lastPasswordChangeAt: integer|Long expected";
                if (message.fullName != null && message.hasOwnProperty("fullName"))
                    if (!$util.isString(message.fullName))
                        return "fullName: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                    if (!$util.isString(message.billingAddress))
                        return "billingAddress: string expected";
                if (message.additionalBillingAddress != null && message.hasOwnProperty("additionalBillingAddress"))
                    if (!$util.isString(message.additionalBillingAddress))
                        return "additionalBillingAddress: string expected";
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    if (!$util.isString(message.taxId))
                        return "taxId: string expected";
                if (message.blockedReason != null && message.hasOwnProperty("blockedReason"))
                    if (!$util.isString(message.blockedReason))
                        return "blockedReason: string expected";
                return null;
            };
    
            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.User
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.User} User
             */
            User.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.User)
                    return object;
                var message = new $root.ca2types.User();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.hasPassword != null)
                    message.hasPassword = Boolean(object.hasPassword);
                if (object.lastPasswordChangeAt != null)
                    if ($util.Long)
                        (message.lastPasswordChangeAt = $util.Long.fromValue(object.lastPasswordChangeAt)).unsigned = true;
                    else if (typeof object.lastPasswordChangeAt === "string")
                        message.lastPasswordChangeAt = parseInt(object.lastPasswordChangeAt, 10);
                    else if (typeof object.lastPasswordChangeAt === "number")
                        message.lastPasswordChangeAt = object.lastPasswordChangeAt;
                    else if (typeof object.lastPasswordChangeAt === "object")
                        message.lastPasswordChangeAt = new $util.LongBits(object.lastPasswordChangeAt.low >>> 0, object.lastPasswordChangeAt.high >>> 0).toNumber(true);
                if (object.fullName != null)
                    message.fullName = String(object.fullName);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.billingAddress != null)
                    message.billingAddress = String(object.billingAddress);
                if (object.additionalBillingAddress != null)
                    message.additionalBillingAddress = String(object.additionalBillingAddress);
                if (object.taxId != null)
                    message.taxId = String(object.taxId);
                if (object.blockedReason != null)
                    message.blockedReason = String(object.blockedReason);
                return message;
            };
    
            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.User
             * @static
             * @param {ca2types.User} message User
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.email = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    object.fullName = "";
                    object.phone = "";
                    object.billingAddress = "";
                    object.additionalBillingAddress = "";
                    object.taxId = "";
                    object.hasPassword = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.lastPasswordChangeAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastPasswordChangeAt = options.longs === String ? "0" : 0;
                    object.blockedReason = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                if (message.fullName != null && message.hasOwnProperty("fullName"))
                    object.fullName = message.fullName;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                    object.billingAddress = message.billingAddress;
                if (message.additionalBillingAddress != null && message.hasOwnProperty("additionalBillingAddress"))
                    object.additionalBillingAddress = message.additionalBillingAddress;
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    object.taxId = message.taxId;
                if (message.hasPassword != null && message.hasOwnProperty("hasPassword"))
                    object.hasPassword = message.hasPassword;
                if (message.lastPasswordChangeAt != null && message.hasOwnProperty("lastPasswordChangeAt"))
                    if (typeof message.lastPasswordChangeAt === "number")
                        object.lastPasswordChangeAt = options.longs === String ? String(message.lastPasswordChangeAt) : message.lastPasswordChangeAt;
                    else
                        object.lastPasswordChangeAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastPasswordChangeAt) : options.longs === Number ? new $util.LongBits(message.lastPasswordChangeAt.low >>> 0, message.lastPasswordChangeAt.high >>> 0).toNumber(true) : message.lastPasswordChangeAt;
                if (message.blockedReason != null && message.hasOwnProperty("blockedReason"))
                    object.blockedReason = message.blockedReason;
                return object;
            };
    
            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof ca2types.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for User
             * @function getTypeUrl
             * @memberof ca2types.User
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.User";
            };
    
            return User;
        })();
    
        ca2types.SSHKey = (function() {
    
            /**
             * Properties of a SSHKey.
             * @memberof ca2types
             * @interface ISSHKey
             * @property {number|null} [id] SSHKey id
             * @property {string|null} [name] SSHKey name
             * @property {string|null} [publicKey] SSHKey publicKey
             * @property {Long|null} [createdAt] SSHKey createdAt
             */
    
            /**
             * Constructs a new SSHKey.
             * @memberof ca2types
             * @classdesc Represents a SSHKey.
             * @implements ISSHKey
             * @constructor
             * @param {ca2types.ISSHKey=} [properties] Properties to set
             */
            function SSHKey(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SSHKey id.
             * @member {number} id
             * @memberof ca2types.SSHKey
             * @instance
             */
            SSHKey.prototype.id = 0;
    
            /**
             * SSHKey name.
             * @member {string} name
             * @memberof ca2types.SSHKey
             * @instance
             */
            SSHKey.prototype.name = "";
    
            /**
             * SSHKey publicKey.
             * @member {string} publicKey
             * @memberof ca2types.SSHKey
             * @instance
             */
            SSHKey.prototype.publicKey = "";
    
            /**
             * SSHKey createdAt.
             * @member {Long} createdAt
             * @memberof ca2types.SSHKey
             * @instance
             */
            SSHKey.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Creates a new SSHKey instance using the specified properties.
             * @function create
             * @memberof ca2types.SSHKey
             * @static
             * @param {ca2types.ISSHKey=} [properties] Properties to set
             * @returns {ca2types.SSHKey} SSHKey instance
             */
            SSHKey.create = function create(properties) {
                return new SSHKey(properties);
            };
    
            /**
             * Encodes the specified SSHKey message. Does not implicitly {@link ca2types.SSHKey.verify|verify} messages.
             * @function encode
             * @memberof ca2types.SSHKey
             * @static
             * @param {ca2types.ISSHKey} message SSHKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SSHKey.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.publicKey != null && Object.hasOwnProperty.call(message, "publicKey"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.publicKey);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.createdAt);
                return writer;
            };
    
            /**
             * Encodes the specified SSHKey message, length delimited. Does not implicitly {@link ca2types.SSHKey.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.SSHKey
             * @static
             * @param {ca2types.ISSHKey} message SSHKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SSHKey.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SSHKey message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.SSHKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.SSHKey} SSHKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SSHKey.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.SSHKey();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.publicKey = reader.string();
                            break;
                        }
                    case 4: {
                            message.createdAt = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SSHKey message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.SSHKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.SSHKey} SSHKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SSHKey.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SSHKey message.
             * @function verify
             * @memberof ca2types.SSHKey
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SSHKey.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                    if (!$util.isString(message.publicKey))
                        return "publicKey: string expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a SSHKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.SSHKey
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.SSHKey} SSHKey
             */
            SSHKey.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.SSHKey)
                    return object;
                var message = new $root.ca2types.SSHKey();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.publicKey != null)
                    message.publicKey = String(object.publicKey);
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                return message;
            };
    
            /**
             * Creates a plain object from a SSHKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.SSHKey
             * @static
             * @param {ca2types.SSHKey} message SSHKey
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SSHKey.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                    object.publicKey = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                    object.publicKey = message.publicKey;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                return object;
            };
    
            /**
             * Converts this SSHKey to JSON.
             * @function toJSON
             * @memberof ca2types.SSHKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SSHKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SSHKey
             * @function getTypeUrl
             * @memberof ca2types.SSHKey
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SSHKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.SSHKey";
            };
    
            return SSHKey;
        })();
    
        ca2types.Referral = (function() {
    
            /**
             * Properties of a Referral.
             * @memberof ca2types
             * @interface IReferral
             * @property {Long|null} [registeredAt] Referral registeredAt
             * @property {string|null} [email] Referral email
             */
    
            /**
             * Constructs a new Referral.
             * @memberof ca2types
             * @classdesc Represents a Referral.
             * @implements IReferral
             * @constructor
             * @param {ca2types.IReferral=} [properties] Properties to set
             */
            function Referral(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Referral registeredAt.
             * @member {Long} registeredAt
             * @memberof ca2types.Referral
             * @instance
             */
            Referral.prototype.registeredAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Referral email.
             * @member {string} email
             * @memberof ca2types.Referral
             * @instance
             */
            Referral.prototype.email = "";
    
            /**
             * Creates a new Referral instance using the specified properties.
             * @function create
             * @memberof ca2types.Referral
             * @static
             * @param {ca2types.IReferral=} [properties] Properties to set
             * @returns {ca2types.Referral} Referral instance
             */
            Referral.create = function create(properties) {
                return new Referral(properties);
            };
    
            /**
             * Encodes the specified Referral message. Does not implicitly {@link ca2types.Referral.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Referral
             * @static
             * @param {ca2types.IReferral} message Referral message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Referral.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.registeredAt != null && Object.hasOwnProperty.call(message, "registeredAt"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.registeredAt);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                return writer;
            };
    
            /**
             * Encodes the specified Referral message, length delimited. Does not implicitly {@link ca2types.Referral.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Referral
             * @static
             * @param {ca2types.IReferral} message Referral message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Referral.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Referral message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Referral
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Referral} Referral
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Referral.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Referral();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.registeredAt = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.email = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Referral message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Referral
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Referral} Referral
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Referral.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Referral message.
             * @function verify
             * @memberof ca2types.Referral
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Referral.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.registeredAt != null && message.hasOwnProperty("registeredAt"))
                    if (!$util.isInteger(message.registeredAt) && !(message.registeredAt && $util.isInteger(message.registeredAt.low) && $util.isInteger(message.registeredAt.high)))
                        return "registeredAt: integer|Long expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                return null;
            };
    
            /**
             * Creates a Referral message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Referral
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Referral} Referral
             */
            Referral.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Referral)
                    return object;
                var message = new $root.ca2types.Referral();
                if (object.registeredAt != null)
                    if ($util.Long)
                        (message.registeredAt = $util.Long.fromValue(object.registeredAt)).unsigned = true;
                    else if (typeof object.registeredAt === "string")
                        message.registeredAt = parseInt(object.registeredAt, 10);
                    else if (typeof object.registeredAt === "number")
                        message.registeredAt = object.registeredAt;
                    else if (typeof object.registeredAt === "object")
                        message.registeredAt = new $util.LongBits(object.registeredAt.low >>> 0, object.registeredAt.high >>> 0).toNumber(true);
                if (object.email != null)
                    message.email = String(object.email);
                return message;
            };
    
            /**
             * Creates a plain object from a Referral message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Referral
             * @static
             * @param {ca2types.Referral} message Referral
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Referral.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.registeredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.registeredAt = options.longs === String ? "0" : 0;
                    object.email = "";
                }
                if (message.registeredAt != null && message.hasOwnProperty("registeredAt"))
                    if (typeof message.registeredAt === "number")
                        object.registeredAt = options.longs === String ? String(message.registeredAt) : message.registeredAt;
                    else
                        object.registeredAt = options.longs === String ? $util.Long.prototype.toString.call(message.registeredAt) : options.longs === Number ? new $util.LongBits(message.registeredAt.low >>> 0, message.registeredAt.high >>> 0).toNumber(true) : message.registeredAt;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };
    
            /**
             * Converts this Referral to JSON.
             * @function toJSON
             * @memberof ca2types.Referral
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Referral.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Referral
             * @function getTypeUrl
             * @memberof ca2types.Referral
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Referral.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Referral";
            };
    
            return Referral;
        })();
    
        /**
         * OAuthProviderType enum.
         * @name ca2types.OAuthProviderType
         * @enum {number}
         * @property {number} OP_UNKNOWN=0 OP_UNKNOWN value
         * @property {number} OP_GOOGLE=1 OP_GOOGLE value
         * @property {number} OP_GITHUB=2 OP_GITHUB value
         */
        ca2types.OAuthProviderType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OP_UNKNOWN"] = 0;
            values[valuesById[1] = "OP_GOOGLE"] = 1;
            values[valuesById[2] = "OP_GITHUB"] = 2;
            return values;
        })();
    
        ca2types.OAuthProvider = (function() {
    
            /**
             * Properties of a OAuthProvider.
             * @memberof ca2types
             * @interface IOAuthProvider
             * @property {string|null} [name] OAuthProvider name
             * @property {ca2types.OAuthProviderType|null} [type] OAuthProvider type
             * @property {string|null} [title] OAuthProvider title
             * @property {string|null} [clientId] OAuthProvider clientId
             */
    
            /**
             * Constructs a new OAuthProvider.
             * @memberof ca2types
             * @classdesc Represents a OAuthProvider.
             * @implements IOAuthProvider
             * @constructor
             * @param {ca2types.IOAuthProvider=} [properties] Properties to set
             */
            function OAuthProvider(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * OAuthProvider name.
             * @member {string} name
             * @memberof ca2types.OAuthProvider
             * @instance
             */
            OAuthProvider.prototype.name = "";
    
            /**
             * OAuthProvider type.
             * @member {ca2types.OAuthProviderType} type
             * @memberof ca2types.OAuthProvider
             * @instance
             */
            OAuthProvider.prototype.type = 0;
    
            /**
             * OAuthProvider title.
             * @member {string} title
             * @memberof ca2types.OAuthProvider
             * @instance
             */
            OAuthProvider.prototype.title = "";
    
            /**
             * OAuthProvider clientId.
             * @member {string} clientId
             * @memberof ca2types.OAuthProvider
             * @instance
             */
            OAuthProvider.prototype.clientId = "";
    
            /**
             * Creates a new OAuthProvider instance using the specified properties.
             * @function create
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {ca2types.IOAuthProvider=} [properties] Properties to set
             * @returns {ca2types.OAuthProvider} OAuthProvider instance
             */
            OAuthProvider.create = function create(properties) {
                return new OAuthProvider(properties);
            };
    
            /**
             * Encodes the specified OAuthProvider message. Does not implicitly {@link ca2types.OAuthProvider.verify|verify} messages.
             * @function encode
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {ca2types.IOAuthProvider} message OAuthProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OAuthProvider.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.clientId);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                return writer;
            };
    
            /**
             * Encodes the specified OAuthProvider message, length delimited. Does not implicitly {@link ca2types.OAuthProvider.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {ca2types.IOAuthProvider} message OAuthProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OAuthProvider.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a OAuthProvider message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.OAuthProvider} OAuthProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OAuthProvider.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.OAuthProvider();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.title = reader.string();
                            break;
                        }
                    case 3: {
                            message.clientId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a OAuthProvider message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.OAuthProvider} OAuthProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OAuthProvider.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a OAuthProvider message.
             * @function verify
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OAuthProvider.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                return null;
            };
    
            /**
             * Creates a OAuthProvider message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.OAuthProvider} OAuthProvider
             */
            OAuthProvider.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.OAuthProvider)
                    return object;
                var message = new $root.ca2types.OAuthProvider();
                if (object.name != null)
                    message.name = String(object.name);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "OP_UNKNOWN":
                case 0:
                    message.type = 0;
                    break;
                case "OP_GOOGLE":
                case 1:
                    message.type = 1;
                    break;
                case "OP_GITHUB":
                case 2:
                    message.type = 2;
                    break;
                }
                if (object.title != null)
                    message.title = String(object.title);
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                return message;
            };
    
            /**
             * Creates a plain object from a OAuthProvider message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {ca2types.OAuthProvider} message OAuthProvider
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OAuthProvider.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.title = "";
                    object.clientId = "";
                    object.type = options.enums === String ? "OP_UNKNOWN" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    object.clientId = message.clientId;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.ca2types.OAuthProviderType[message.type] === undefined ? message.type : $root.ca2types.OAuthProviderType[message.type] : message.type;
                return object;
            };
    
            /**
             * Converts this OAuthProvider to JSON.
             * @function toJSON
             * @memberof ca2types.OAuthProvider
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OAuthProvider.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for OAuthProvider
             * @function getTypeUrl
             * @memberof ca2types.OAuthProvider
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OAuthProvider.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.OAuthProvider";
            };
    
            return OAuthProvider;
        })();
    
        ca2types.Region = (function() {
    
            /**
             * Properties of a Region.
             * @memberof ca2types
             * @interface IRegion
             * @property {string|null} [id] Region id
             * @property {string|null} [city] Region city
             * @property {string|null} [countryCode] Region countryCode
             * @property {Array.<string>|null} [providerIds] Region providerIds
             * @property {Array.<string>|null} [datacenterIds] Region datacenterIds
             */
    
            /**
             * Constructs a new Region.
             * @memberof ca2types
             * @classdesc Represents a Region.
             * @implements IRegion
             * @constructor
             * @param {ca2types.IRegion=} [properties] Properties to set
             */
            function Region(properties) {
                this.providerIds = [];
                this.datacenterIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Region id.
             * @member {string} id
             * @memberof ca2types.Region
             * @instance
             */
            Region.prototype.id = "";
    
            /**
             * Region city.
             * @member {string} city
             * @memberof ca2types.Region
             * @instance
             */
            Region.prototype.city = "";
    
            /**
             * Region countryCode.
             * @member {string} countryCode
             * @memberof ca2types.Region
             * @instance
             */
            Region.prototype.countryCode = "";
    
            /**
             * Region providerIds.
             * @member {Array.<string>} providerIds
             * @memberof ca2types.Region
             * @instance
             */
            Region.prototype.providerIds = $util.emptyArray;
    
            /**
             * Region datacenterIds.
             * @member {Array.<string>} datacenterIds
             * @memberof ca2types.Region
             * @instance
             */
            Region.prototype.datacenterIds = $util.emptyArray;
    
            /**
             * Creates a new Region instance using the specified properties.
             * @function create
             * @memberof ca2types.Region
             * @static
             * @param {ca2types.IRegion=} [properties] Properties to set
             * @returns {ca2types.Region} Region instance
             */
            Region.create = function create(properties) {
                return new Region(properties);
            };
    
            /**
             * Encodes the specified Region message. Does not implicitly {@link ca2types.Region.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Region
             * @static
             * @param {ca2types.IRegion} message Region message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Region.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
                if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryCode);
                if (message.providerIds != null && message.providerIds.length)
                    for (var i = 0; i < message.providerIds.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.providerIds[i]);
                if (message.datacenterIds != null && message.datacenterIds.length)
                    for (var i = 0; i < message.datacenterIds.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.datacenterIds[i]);
                return writer;
            };
    
            /**
             * Encodes the specified Region message, length delimited. Does not implicitly {@link ca2types.Region.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Region
             * @static
             * @param {ca2types.IRegion} message Region message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Region.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Region message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Region
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Region} Region
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Region.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Region();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.city = reader.string();
                            break;
                        }
                    case 3: {
                            message.countryCode = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.providerIds && message.providerIds.length))
                                message.providerIds = [];
                            message.providerIds.push(reader.string());
                            break;
                        }
                    case 5: {
                            if (!(message.datacenterIds && message.datacenterIds.length))
                                message.datacenterIds = [];
                            message.datacenterIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Region message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Region
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Region} Region
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Region.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Region message.
             * @function verify
             * @memberof ca2types.Region
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Region.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.city != null && message.hasOwnProperty("city"))
                    if (!$util.isString(message.city))
                        return "city: string expected";
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    if (!$util.isString(message.countryCode))
                        return "countryCode: string expected";
                if (message.providerIds != null && message.hasOwnProperty("providerIds")) {
                    if (!Array.isArray(message.providerIds))
                        return "providerIds: array expected";
                    for (var i = 0; i < message.providerIds.length; ++i)
                        if (!$util.isString(message.providerIds[i]))
                            return "providerIds: string[] expected";
                }
                if (message.datacenterIds != null && message.hasOwnProperty("datacenterIds")) {
                    if (!Array.isArray(message.datacenterIds))
                        return "datacenterIds: array expected";
                    for (var i = 0; i < message.datacenterIds.length; ++i)
                        if (!$util.isString(message.datacenterIds[i]))
                            return "datacenterIds: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a Region message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Region
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Region} Region
             */
            Region.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Region)
                    return object;
                var message = new $root.ca2types.Region();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.city != null)
                    message.city = String(object.city);
                if (object.countryCode != null)
                    message.countryCode = String(object.countryCode);
                if (object.providerIds) {
                    if (!Array.isArray(object.providerIds))
                        throw TypeError(".ca2types.Region.providerIds: array expected");
                    message.providerIds = [];
                    for (var i = 0; i < object.providerIds.length; ++i)
                        message.providerIds[i] = String(object.providerIds[i]);
                }
                if (object.datacenterIds) {
                    if (!Array.isArray(object.datacenterIds))
                        throw TypeError(".ca2types.Region.datacenterIds: array expected");
                    message.datacenterIds = [];
                    for (var i = 0; i < object.datacenterIds.length; ++i)
                        message.datacenterIds[i] = String(object.datacenterIds[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Region message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Region
             * @static
             * @param {ca2types.Region} message Region
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Region.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.providerIds = [];
                    object.datacenterIds = [];
                }
                if (options.defaults) {
                    object.id = "";
                    object.city = "";
                    object.countryCode = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.city != null && message.hasOwnProperty("city"))
                    object.city = message.city;
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    object.countryCode = message.countryCode;
                if (message.providerIds && message.providerIds.length) {
                    object.providerIds = [];
                    for (var j = 0; j < message.providerIds.length; ++j)
                        object.providerIds[j] = message.providerIds[j];
                }
                if (message.datacenterIds && message.datacenterIds.length) {
                    object.datacenterIds = [];
                    for (var j = 0; j < message.datacenterIds.length; ++j)
                        object.datacenterIds[j] = message.datacenterIds[j];
                }
                return object;
            };
    
            /**
             * Converts this Region to JSON.
             * @function toJSON
             * @memberof ca2types.Region
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Region.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Region
             * @function getTypeUrl
             * @memberof ca2types.Region
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Region.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Region";
            };
    
            return Region;
        })();
    
        ca2types.Datacenter = (function() {
    
            /**
             * Properties of a Datacenter.
             * @memberof ca2types
             * @interface IDatacenter
             * @property {string|null} [id] Datacenter id
             * @property {string|null} [city] Datacenter city
             * @property {string|null} [countryCode] Datacenter countryCode
             * @property {string|null} [extra] Datacenter extra
             */
    
            /**
             * Constructs a new Datacenter.
             * @memberof ca2types
             * @classdesc Represents a Datacenter.
             * @implements IDatacenter
             * @constructor
             * @param {ca2types.IDatacenter=} [properties] Properties to set
             */
            function Datacenter(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Datacenter id.
             * @member {string} id
             * @memberof ca2types.Datacenter
             * @instance
             */
            Datacenter.prototype.id = "";
    
            /**
             * Datacenter city.
             * @member {string} city
             * @memberof ca2types.Datacenter
             * @instance
             */
            Datacenter.prototype.city = "";
    
            /**
             * Datacenter countryCode.
             * @member {string} countryCode
             * @memberof ca2types.Datacenter
             * @instance
             */
            Datacenter.prototype.countryCode = "";
    
            /**
             * Datacenter extra.
             * @member {string} extra
             * @memberof ca2types.Datacenter
             * @instance
             */
            Datacenter.prototype.extra = "";
    
            /**
             * Creates a new Datacenter instance using the specified properties.
             * @function create
             * @memberof ca2types.Datacenter
             * @static
             * @param {ca2types.IDatacenter=} [properties] Properties to set
             * @returns {ca2types.Datacenter} Datacenter instance
             */
            Datacenter.create = function create(properties) {
                return new Datacenter(properties);
            };
    
            /**
             * Encodes the specified Datacenter message. Does not implicitly {@link ca2types.Datacenter.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Datacenter
             * @static
             * @param {ca2types.IDatacenter} message Datacenter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Datacenter.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
                if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryCode);
                if (message.extra != null && Object.hasOwnProperty.call(message, "extra"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.extra);
                return writer;
            };
    
            /**
             * Encodes the specified Datacenter message, length delimited. Does not implicitly {@link ca2types.Datacenter.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Datacenter
             * @static
             * @param {ca2types.IDatacenter} message Datacenter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Datacenter.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Datacenter message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Datacenter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Datacenter} Datacenter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Datacenter.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Datacenter();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.city = reader.string();
                            break;
                        }
                    case 3: {
                            message.countryCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.extra = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Datacenter message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Datacenter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Datacenter} Datacenter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Datacenter.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Datacenter message.
             * @function verify
             * @memberof ca2types.Datacenter
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Datacenter.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.city != null && message.hasOwnProperty("city"))
                    if (!$util.isString(message.city))
                        return "city: string expected";
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    if (!$util.isString(message.countryCode))
                        return "countryCode: string expected";
                if (message.extra != null && message.hasOwnProperty("extra"))
                    if (!$util.isString(message.extra))
                        return "extra: string expected";
                return null;
            };
    
            /**
             * Creates a Datacenter message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Datacenter
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Datacenter} Datacenter
             */
            Datacenter.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Datacenter)
                    return object;
                var message = new $root.ca2types.Datacenter();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.city != null)
                    message.city = String(object.city);
                if (object.countryCode != null)
                    message.countryCode = String(object.countryCode);
                if (object.extra != null)
                    message.extra = String(object.extra);
                return message;
            };
    
            /**
             * Creates a plain object from a Datacenter message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Datacenter
             * @static
             * @param {ca2types.Datacenter} message Datacenter
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Datacenter.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.city = "";
                    object.countryCode = "";
                    object.extra = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.city != null && message.hasOwnProperty("city"))
                    object.city = message.city;
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    object.countryCode = message.countryCode;
                if (message.extra != null && message.hasOwnProperty("extra"))
                    object.extra = message.extra;
                return object;
            };
    
            /**
             * Converts this Datacenter to JSON.
             * @function toJSON
             * @memberof ca2types.Datacenter
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Datacenter.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Datacenter
             * @function getTypeUrl
             * @memberof ca2types.Datacenter
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Datacenter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Datacenter";
            };
    
            return Datacenter;
        })();
    
        ca2types.Provider = (function() {
    
            /**
             * Properties of a Provider.
             * @memberof ca2types
             * @interface IProvider
             * @property {string|null} [id] Provider id
             * @property {number|null} [sortOrder] Provider sortOrder
             * @property {string|null} [title] Provider title
             */
    
            /**
             * Constructs a new Provider.
             * @memberof ca2types
             * @classdesc Represents a Provider.
             * @implements IProvider
             * @constructor
             * @param {ca2types.IProvider=} [properties] Properties to set
             */
            function Provider(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Provider id.
             * @member {string} id
             * @memberof ca2types.Provider
             * @instance
             */
            Provider.prototype.id = "";
    
            /**
             * Provider sortOrder.
             * @member {number} sortOrder
             * @memberof ca2types.Provider
             * @instance
             */
            Provider.prototype.sortOrder = 0;
    
            /**
             * Provider title.
             * @member {string} title
             * @memberof ca2types.Provider
             * @instance
             */
            Provider.prototype.title = "";
    
            /**
             * Creates a new Provider instance using the specified properties.
             * @function create
             * @memberof ca2types.Provider
             * @static
             * @param {ca2types.IProvider=} [properties] Properties to set
             * @returns {ca2types.Provider} Provider instance
             */
            Provider.create = function create(properties) {
                return new Provider(properties);
            };
    
            /**
             * Encodes the specified Provider message. Does not implicitly {@link ca2types.Provider.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Provider
             * @static
             * @param {ca2types.IProvider} message Provider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Provider.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.sortOrder);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
                return writer;
            };
    
            /**
             * Encodes the specified Provider message, length delimited. Does not implicitly {@link ca2types.Provider.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Provider
             * @static
             * @param {ca2types.IProvider} message Provider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Provider.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Provider message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Provider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Provider} Provider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Provider.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Provider();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.sortOrder = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.title = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Provider message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Provider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Provider} Provider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Provider.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Provider message.
             * @function verify
             * @memberof ca2types.Provider
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Provider.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    if (!$util.isInteger(message.sortOrder))
                        return "sortOrder: integer expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                return null;
            };
    
            /**
             * Creates a Provider message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Provider
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Provider} Provider
             */
            Provider.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Provider)
                    return object;
                var message = new $root.ca2types.Provider();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.sortOrder != null)
                    message.sortOrder = object.sortOrder >>> 0;
                if (object.title != null)
                    message.title = String(object.title);
                return message;
            };
    
            /**
             * Creates a plain object from a Provider message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Provider
             * @static
             * @param {ca2types.Provider} message Provider
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Provider.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.sortOrder = 0;
                    object.title = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = message.sortOrder;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                return object;
            };
    
            /**
             * Converts this Provider to JSON.
             * @function toJSON
             * @memberof ca2types.Provider
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Provider.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Provider
             * @function getTypeUrl
             * @memberof ca2types.Provider
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Provider.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Provider";
            };
    
            return Provider;
        })();
    
        ca2types.CPU = (function() {
    
            /**
             * Properties of a CPU.
             * @memberof ca2types
             * @interface ICPU
             * @property {number|null} [count] CPU count
             * @property {string|null} [model] CPU model
             */
    
            /**
             * Constructs a new CPU.
             * @memberof ca2types
             * @classdesc Represents a CPU.
             * @implements ICPU
             * @constructor
             * @param {ca2types.ICPU=} [properties] Properties to set
             */
            function CPU(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CPU count.
             * @member {number} count
             * @memberof ca2types.CPU
             * @instance
             */
            CPU.prototype.count = 0;
    
            /**
             * CPU model.
             * @member {string} model
             * @memberof ca2types.CPU
             * @instance
             */
            CPU.prototype.model = "";
    
            /**
             * Creates a new CPU instance using the specified properties.
             * @function create
             * @memberof ca2types.CPU
             * @static
             * @param {ca2types.ICPU=} [properties] Properties to set
             * @returns {ca2types.CPU} CPU instance
             */
            CPU.create = function create(properties) {
                return new CPU(properties);
            };
    
            /**
             * Encodes the specified CPU message. Does not implicitly {@link ca2types.CPU.verify|verify} messages.
             * @function encode
             * @memberof ca2types.CPU
             * @static
             * @param {ca2types.ICPU} message CPU message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPU.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.count);
                if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.model);
                return writer;
            };
    
            /**
             * Encodes the specified CPU message, length delimited. Does not implicitly {@link ca2types.CPU.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.CPU
             * @static
             * @param {ca2types.ICPU} message CPU message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPU.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CPU message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.CPU
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.CPU} CPU
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPU.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.CPU();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.model = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CPU message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.CPU
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.CPU} CPU
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPU.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CPU message.
             * @function verify
             * @memberof ca2types.CPU
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CPU.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.model != null && message.hasOwnProperty("model"))
                    if (!$util.isString(message.model))
                        return "model: string expected";
                return null;
            };
    
            /**
             * Creates a CPU message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.CPU
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.CPU} CPU
             */
            CPU.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.CPU)
                    return object;
                var message = new $root.ca2types.CPU();
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.model != null)
                    message.model = String(object.model);
                return message;
            };
    
            /**
             * Creates a plain object from a CPU message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.CPU
             * @static
             * @param {ca2types.CPU} message CPU
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CPU.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.count = 0;
                    object.model = "";
                }
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.model != null && message.hasOwnProperty("model"))
                    object.model = message.model;
                return object;
            };
    
            /**
             * Converts this CPU to JSON.
             * @function toJSON
             * @memberof ca2types.CPU
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CPU.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CPU
             * @function getTypeUrl
             * @memberof ca2types.CPU
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CPU.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.CPU";
            };
    
            return CPU;
        })();
    
        ca2types.GPU = (function() {
    
            /**
             * Properties of a GPU.
             * @memberof ca2types
             * @interface IGPU
             * @property {number|null} [count] GPU count
             * @property {Long|null} [memorySize] GPU memorySize
             * @property {string|null} [model] GPU model
             */
    
            /**
             * Constructs a new GPU.
             * @memberof ca2types
             * @classdesc Represents a GPU.
             * @implements IGPU
             * @constructor
             * @param {ca2types.IGPU=} [properties] Properties to set
             */
            function GPU(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GPU count.
             * @member {number} count
             * @memberof ca2types.GPU
             * @instance
             */
            GPU.prototype.count = 0;
    
            /**
             * GPU memorySize.
             * @member {Long} memorySize
             * @memberof ca2types.GPU
             * @instance
             */
            GPU.prototype.memorySize = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * GPU model.
             * @member {string} model
             * @memberof ca2types.GPU
             * @instance
             */
            GPU.prototype.model = "";
    
            /**
             * Creates a new GPU instance using the specified properties.
             * @function create
             * @memberof ca2types.GPU
             * @static
             * @param {ca2types.IGPU=} [properties] Properties to set
             * @returns {ca2types.GPU} GPU instance
             */
            GPU.create = function create(properties) {
                return new GPU(properties);
            };
    
            /**
             * Encodes the specified GPU message. Does not implicitly {@link ca2types.GPU.verify|verify} messages.
             * @function encode
             * @memberof ca2types.GPU
             * @static
             * @param {ca2types.IGPU} message GPU message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GPU.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.count);
                if (message.memorySize != null && Object.hasOwnProperty.call(message, "memorySize"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.memorySize);
                if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.model);
                return writer;
            };
    
            /**
             * Encodes the specified GPU message, length delimited. Does not implicitly {@link ca2types.GPU.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.GPU
             * @static
             * @param {ca2types.IGPU} message GPU message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GPU.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GPU message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.GPU
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.GPU} GPU
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GPU.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.GPU();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.memorySize = reader.uint64();
                            break;
                        }
                    case 4: {
                            message.model = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GPU message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.GPU
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.GPU} GPU
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GPU.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GPU message.
             * @function verify
             * @memberof ca2types.GPU
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GPU.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.memorySize != null && message.hasOwnProperty("memorySize"))
                    if (!$util.isInteger(message.memorySize) && !(message.memorySize && $util.isInteger(message.memorySize.low) && $util.isInteger(message.memorySize.high)))
                        return "memorySize: integer|Long expected";
                if (message.model != null && message.hasOwnProperty("model"))
                    if (!$util.isString(message.model))
                        return "model: string expected";
                return null;
            };
    
            /**
             * Creates a GPU message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.GPU
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.GPU} GPU
             */
            GPU.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.GPU)
                    return object;
                var message = new $root.ca2types.GPU();
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.memorySize != null)
                    if ($util.Long)
                        (message.memorySize = $util.Long.fromValue(object.memorySize)).unsigned = true;
                    else if (typeof object.memorySize === "string")
                        message.memorySize = parseInt(object.memorySize, 10);
                    else if (typeof object.memorySize === "number")
                        message.memorySize = object.memorySize;
                    else if (typeof object.memorySize === "object")
                        message.memorySize = new $util.LongBits(object.memorySize.low >>> 0, object.memorySize.high >>> 0).toNumber(true);
                if (object.model != null)
                    message.model = String(object.model);
                return message;
            };
    
            /**
             * Creates a plain object from a GPU message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.GPU
             * @static
             * @param {ca2types.GPU} message GPU
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GPU.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.count = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.memorySize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.memorySize = options.longs === String ? "0" : 0;
                    object.model = "";
                }
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.memorySize != null && message.hasOwnProperty("memorySize"))
                    if (typeof message.memorySize === "number")
                        object.memorySize = options.longs === String ? String(message.memorySize) : message.memorySize;
                    else
                        object.memorySize = options.longs === String ? $util.Long.prototype.toString.call(message.memorySize) : options.longs === Number ? new $util.LongBits(message.memorySize.low >>> 0, message.memorySize.high >>> 0).toNumber(true) : message.memorySize;
                if (message.model != null && message.hasOwnProperty("model"))
                    object.model = message.model;
                return object;
            };
    
            /**
             * Converts this GPU to JSON.
             * @function toJSON
             * @memberof ca2types.GPU
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GPU.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GPU
             * @function getTypeUrl
             * @memberof ca2types.GPU
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GPU.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.GPU";
            };
    
            return GPU;
        })();
    
        ca2types.ServerClass = (function() {
    
            /**
             * Properties of a ServerClass.
             * @memberof ca2types
             * @interface IServerClass
             * @property {string|null} [id] ServerClass id
             * @property {string|null} [title] ServerClass title
             * @property {boolean|null} [isGpu] ServerClass isGpu
             * @property {string|null} [providerId] ServerClass providerId
             */
    
            /**
             * Constructs a new ServerClass.
             * @memberof ca2types
             * @classdesc Represents a ServerClass.
             * @implements IServerClass
             * @constructor
             * @param {ca2types.IServerClass=} [properties] Properties to set
             */
            function ServerClass(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServerClass id.
             * @member {string} id
             * @memberof ca2types.ServerClass
             * @instance
             */
            ServerClass.prototype.id = "";
    
            /**
             * ServerClass title.
             * @member {string} title
             * @memberof ca2types.ServerClass
             * @instance
             */
            ServerClass.prototype.title = "";
    
            /**
             * ServerClass isGpu.
             * @member {boolean} isGpu
             * @memberof ca2types.ServerClass
             * @instance
             */
            ServerClass.prototype.isGpu = false;
    
            /**
             * ServerClass providerId.
             * @member {string} providerId
             * @memberof ca2types.ServerClass
             * @instance
             */
            ServerClass.prototype.providerId = "";
    
            /**
             * Creates a new ServerClass instance using the specified properties.
             * @function create
             * @memberof ca2types.ServerClass
             * @static
             * @param {ca2types.IServerClass=} [properties] Properties to set
             * @returns {ca2types.ServerClass} ServerClass instance
             */
            ServerClass.create = function create(properties) {
                return new ServerClass(properties);
            };
    
            /**
             * Encodes the specified ServerClass message. Does not implicitly {@link ca2types.ServerClass.verify|verify} messages.
             * @function encode
             * @memberof ca2types.ServerClass
             * @static
             * @param {ca2types.IServerClass} message ServerClass message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerClass.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                if (message.isGpu != null && Object.hasOwnProperty.call(message, "isGpu"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isGpu);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.providerId);
                return writer;
            };
    
            /**
             * Encodes the specified ServerClass message, length delimited. Does not implicitly {@link ca2types.ServerClass.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.ServerClass
             * @static
             * @param {ca2types.IServerClass} message ServerClass message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerClass.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServerClass message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.ServerClass
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.ServerClass} ServerClass
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerClass.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.ServerClass();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.title = reader.string();
                            break;
                        }
                    case 3: {
                            message.isGpu = reader.bool();
                            break;
                        }
                    case 4: {
                            message.providerId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServerClass message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.ServerClass
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.ServerClass} ServerClass
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerClass.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServerClass message.
             * @function verify
             * @memberof ca2types.ServerClass
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerClass.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                if (message.isGpu != null && message.hasOwnProperty("isGpu"))
                    if (typeof message.isGpu !== "boolean")
                        return "isGpu: boolean expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                return null;
            };
    
            /**
             * Creates a ServerClass message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.ServerClass
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.ServerClass} ServerClass
             */
            ServerClass.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.ServerClass)
                    return object;
                var message = new $root.ca2types.ServerClass();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.title != null)
                    message.title = String(object.title);
                if (object.isGpu != null)
                    message.isGpu = Boolean(object.isGpu);
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                return message;
            };
    
            /**
             * Creates a plain object from a ServerClass message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.ServerClass
             * @static
             * @param {ca2types.ServerClass} message ServerClass
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerClass.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.title = "";
                    object.isGpu = false;
                    object.providerId = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                if (message.isGpu != null && message.hasOwnProperty("isGpu"))
                    object.isGpu = message.isGpu;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                return object;
            };
    
            /**
             * Converts this ServerClass to JSON.
             * @function toJSON
             * @memberof ca2types.ServerClass
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerClass.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServerClass
             * @function getTypeUrl
             * @memberof ca2types.ServerClass
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServerClass.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.ServerClass";
            };
    
            return ServerClass;
        })();
    
        ca2types.Benefit = (function() {
    
            /**
             * Properties of a Benefit.
             * @memberof ca2types
             * @interface IBenefit
             * @property {string|null} [id] Benefit id
             * @property {string|null} [title] Benefit title
             */
    
            /**
             * Constructs a new Benefit.
             * @memberof ca2types
             * @classdesc Represents a Benefit.
             * @implements IBenefit
             * @constructor
             * @param {ca2types.IBenefit=} [properties] Properties to set
             */
            function Benefit(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Benefit id.
             * @member {string} id
             * @memberof ca2types.Benefit
             * @instance
             */
            Benefit.prototype.id = "";
    
            /**
             * Benefit title.
             * @member {string} title
             * @memberof ca2types.Benefit
             * @instance
             */
            Benefit.prototype.title = "";
    
            /**
             * Creates a new Benefit instance using the specified properties.
             * @function create
             * @memberof ca2types.Benefit
             * @static
             * @param {ca2types.IBenefit=} [properties] Properties to set
             * @returns {ca2types.Benefit} Benefit instance
             */
            Benefit.create = function create(properties) {
                return new Benefit(properties);
            };
    
            /**
             * Encodes the specified Benefit message. Does not implicitly {@link ca2types.Benefit.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Benefit
             * @static
             * @param {ca2types.IBenefit} message Benefit message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Benefit.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                return writer;
            };
    
            /**
             * Encodes the specified Benefit message, length delimited. Does not implicitly {@link ca2types.Benefit.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Benefit
             * @static
             * @param {ca2types.IBenefit} message Benefit message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Benefit.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Benefit message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Benefit
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Benefit} Benefit
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Benefit.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Benefit();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.title = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Benefit message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Benefit
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Benefit} Benefit
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Benefit.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Benefit message.
             * @function verify
             * @memberof ca2types.Benefit
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Benefit.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                return null;
            };
    
            /**
             * Creates a Benefit message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Benefit
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Benefit} Benefit
             */
            Benefit.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Benefit)
                    return object;
                var message = new $root.ca2types.Benefit();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.title != null)
                    message.title = String(object.title);
                return message;
            };
    
            /**
             * Creates a plain object from a Benefit message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Benefit
             * @static
             * @param {ca2types.Benefit} message Benefit
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Benefit.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.title = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                return object;
            };
    
            /**
             * Converts this Benefit to JSON.
             * @function toJSON
             * @memberof ca2types.Benefit
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Benefit.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Benefit
             * @function getTypeUrl
             * @memberof ca2types.Benefit
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Benefit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Benefit";
            };
    
            return Benefit;
        })();
    
        ca2types.ServerPlan = (function() {
    
            /**
             * Properties of a ServerPlan.
             * @memberof ca2types
             * @interface IServerPlan
             * @property {string|null} [id] ServerPlan id
             * @property {string|null} [classId] ServerPlan classId
             * @property {string|null} [providerId] ServerPlan providerId
             * @property {Array.<string>|null} [regionIds] ServerPlan regionIds
             * @property {Array.<string>|null} [datacenterIds] ServerPlan datacenterIds
             * @property {ca2types.ICPU|null} [cpu] ServerPlan cpu
             * @property {ca2types.IGPU|null} [gpu] ServerPlan gpu
             * @property {Long|null} [ramSize] ServerPlan ramSize
             * @property {Long|null} [storageSize] ServerPlan storageSize
             * @property {ca2types.IAmount|null} [monthlyRate] ServerPlan monthlyRate
             * @property {ca2types.IAmount|null} [hourlyRate] ServerPlan hourlyRate
             * @property {ca2types.IAmount|null} [windowsLicenseMonthlyRate] ServerPlan windowsLicenseMonthlyRate
             * @property {ca2types.IAmount|null} [windowsLicenseHourlyRate] ServerPlan windowsLicenseHourlyRate
             * @property {Long|null} [transfer] ServerPlan transfer
             * @property {Array.<string>|null} [benefitIds] ServerPlan benefitIds
             * @property {Array.<string>|null} [availableAddonIds] ServerPlan availableAddonIds
             * @property {number|null} [firstPaymentHours] ServerPlan firstPaymentHours
             */
    
            /**
             * Constructs a new ServerPlan.
             * @memberof ca2types
             * @classdesc Represents a ServerPlan.
             * @implements IServerPlan
             * @constructor
             * @param {ca2types.IServerPlan=} [properties] Properties to set
             */
            function ServerPlan(properties) {
                this.regionIds = [];
                this.datacenterIds = [];
                this.benefitIds = [];
                this.availableAddonIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServerPlan id.
             * @member {string} id
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.id = "";
    
            /**
             * ServerPlan classId.
             * @member {string} classId
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.classId = "";
    
            /**
             * ServerPlan providerId.
             * @member {string} providerId
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.providerId = "";
    
            /**
             * ServerPlan regionIds.
             * @member {Array.<string>} regionIds
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.regionIds = $util.emptyArray;
    
            /**
             * ServerPlan datacenterIds.
             * @member {Array.<string>} datacenterIds
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.datacenterIds = $util.emptyArray;
    
            /**
             * ServerPlan cpu.
             * @member {ca2types.ICPU|null|undefined} cpu
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.cpu = null;
    
            /**
             * ServerPlan gpu.
             * @member {ca2types.IGPU|null|undefined} gpu
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.gpu = null;
    
            /**
             * ServerPlan ramSize.
             * @member {Long} ramSize
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.ramSize = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * ServerPlan storageSize.
             * @member {Long} storageSize
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.storageSize = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * ServerPlan monthlyRate.
             * @member {ca2types.IAmount|null|undefined} monthlyRate
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.monthlyRate = null;
    
            /**
             * ServerPlan hourlyRate.
             * @member {ca2types.IAmount|null|undefined} hourlyRate
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.hourlyRate = null;
    
            /**
             * ServerPlan windowsLicenseMonthlyRate.
             * @member {ca2types.IAmount|null|undefined} windowsLicenseMonthlyRate
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.windowsLicenseMonthlyRate = null;
    
            /**
             * ServerPlan windowsLicenseHourlyRate.
             * @member {ca2types.IAmount|null|undefined} windowsLicenseHourlyRate
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.windowsLicenseHourlyRate = null;
    
            /**
             * ServerPlan transfer.
             * @member {Long} transfer
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.transfer = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * ServerPlan benefitIds.
             * @member {Array.<string>} benefitIds
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.benefitIds = $util.emptyArray;
    
            /**
             * ServerPlan availableAddonIds.
             * @member {Array.<string>} availableAddonIds
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.availableAddonIds = $util.emptyArray;
    
            /**
             * ServerPlan firstPaymentHours.
             * @member {number} firstPaymentHours
             * @memberof ca2types.ServerPlan
             * @instance
             */
            ServerPlan.prototype.firstPaymentHours = 0;
    
            /**
             * Creates a new ServerPlan instance using the specified properties.
             * @function create
             * @memberof ca2types.ServerPlan
             * @static
             * @param {ca2types.IServerPlan=} [properties] Properties to set
             * @returns {ca2types.ServerPlan} ServerPlan instance
             */
            ServerPlan.create = function create(properties) {
                return new ServerPlan(properties);
            };
    
            /**
             * Encodes the specified ServerPlan message. Does not implicitly {@link ca2types.ServerPlan.verify|verify} messages.
             * @function encode
             * @memberof ca2types.ServerPlan
             * @static
             * @param {ca2types.IServerPlan} message ServerPlan message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerPlan.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.classId != null && Object.hasOwnProperty.call(message, "classId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.classId);
                if (message.regionIds != null && message.regionIds.length)
                    for (var i = 0; i < message.regionIds.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.regionIds[i]);
                if (message.cpu != null && Object.hasOwnProperty.call(message, "cpu"))
                    $root.ca2types.CPU.encode(message.cpu, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.gpu != null && Object.hasOwnProperty.call(message, "gpu"))
                    $root.ca2types.GPU.encode(message.gpu, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.ramSize != null && Object.hasOwnProperty.call(message, "ramSize"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.ramSize);
                if (message.monthlyRate != null && Object.hasOwnProperty.call(message, "monthlyRate"))
                    $root.ca2types.Amount.encode(message.monthlyRate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.hourlyRate != null && Object.hasOwnProperty.call(message, "hourlyRate"))
                    $root.ca2types.Amount.encode(message.hourlyRate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.windowsLicenseMonthlyRate != null && Object.hasOwnProperty.call(message, "windowsLicenseMonthlyRate"))
                    $root.ca2types.Amount.encode(message.windowsLicenseMonthlyRate, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.transfer != null && Object.hasOwnProperty.call(message, "transfer"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.transfer);
                if (message.benefitIds != null && message.benefitIds.length)
                    for (var i = 0; i < message.benefitIds.length; ++i)
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.benefitIds[i]);
                if (message.storageSize != null && Object.hasOwnProperty.call(message, "storageSize"))
                    writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.storageSize);
                if (message.datacenterIds != null && message.datacenterIds.length)
                    for (var i = 0; i < message.datacenterIds.length; ++i)
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.datacenterIds[i]);
                if (message.availableAddonIds != null && message.availableAddonIds.length)
                    for (var i = 0; i < message.availableAddonIds.length; ++i)
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.availableAddonIds[i]);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.providerId);
                if (message.firstPaymentHours != null && Object.hasOwnProperty.call(message, "firstPaymentHours"))
                    writer.uint32(/* id 18, wireType 0 =*/144).uint32(message.firstPaymentHours);
                if (message.windowsLicenseHourlyRate != null && Object.hasOwnProperty.call(message, "windowsLicenseHourlyRate"))
                    $root.ca2types.Amount.encode(message.windowsLicenseHourlyRate, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ServerPlan message, length delimited. Does not implicitly {@link ca2types.ServerPlan.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.ServerPlan
             * @static
             * @param {ca2types.IServerPlan} message ServerPlan message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerPlan.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServerPlan message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.ServerPlan
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.ServerPlan} ServerPlan
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerPlan.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.ServerPlan();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.classId = reader.string();
                            break;
                        }
                    case 17: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.regionIds && message.regionIds.length))
                                message.regionIds = [];
                            message.regionIds.push(reader.string());
                            break;
                        }
                    case 15: {
                            if (!(message.datacenterIds && message.datacenterIds.length))
                                message.datacenterIds = [];
                            message.datacenterIds.push(reader.string());
                            break;
                        }
                    case 5: {
                            message.cpu = $root.ca2types.CPU.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.gpu = $root.ca2types.GPU.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.ramSize = reader.uint64();
                            break;
                        }
                    case 14: {
                            message.storageSize = reader.uint64();
                            break;
                        }
                    case 8: {
                            message.monthlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.hourlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.windowsLicenseMonthlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            message.windowsLicenseHourlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.transfer = reader.uint64();
                            break;
                        }
                    case 13: {
                            if (!(message.benefitIds && message.benefitIds.length))
                                message.benefitIds = [];
                            message.benefitIds.push(reader.string());
                            break;
                        }
                    case 16: {
                            if (!(message.availableAddonIds && message.availableAddonIds.length))
                                message.availableAddonIds = [];
                            message.availableAddonIds.push(reader.string());
                            break;
                        }
                    case 18: {
                            message.firstPaymentHours = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServerPlan message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.ServerPlan
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.ServerPlan} ServerPlan
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerPlan.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServerPlan message.
             * @function verify
             * @memberof ca2types.ServerPlan
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerPlan.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.classId != null && message.hasOwnProperty("classId"))
                    if (!$util.isString(message.classId))
                        return "classId: string expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.regionIds != null && message.hasOwnProperty("regionIds")) {
                    if (!Array.isArray(message.regionIds))
                        return "regionIds: array expected";
                    for (var i = 0; i < message.regionIds.length; ++i)
                        if (!$util.isString(message.regionIds[i]))
                            return "regionIds: string[] expected";
                }
                if (message.datacenterIds != null && message.hasOwnProperty("datacenterIds")) {
                    if (!Array.isArray(message.datacenterIds))
                        return "datacenterIds: array expected";
                    for (var i = 0; i < message.datacenterIds.length; ++i)
                        if (!$util.isString(message.datacenterIds[i]))
                            return "datacenterIds: string[] expected";
                }
                if (message.cpu != null && message.hasOwnProperty("cpu")) {
                    var error = $root.ca2types.CPU.verify(message.cpu);
                    if (error)
                        return "cpu." + error;
                }
                if (message.gpu != null && message.hasOwnProperty("gpu")) {
                    var error = $root.ca2types.GPU.verify(message.gpu);
                    if (error)
                        return "gpu." + error;
                }
                if (message.ramSize != null && message.hasOwnProperty("ramSize"))
                    if (!$util.isInteger(message.ramSize) && !(message.ramSize && $util.isInteger(message.ramSize.low) && $util.isInteger(message.ramSize.high)))
                        return "ramSize: integer|Long expected";
                if (message.storageSize != null && message.hasOwnProperty("storageSize"))
                    if (!$util.isInteger(message.storageSize) && !(message.storageSize && $util.isInteger(message.storageSize.low) && $util.isInteger(message.storageSize.high)))
                        return "storageSize: integer|Long expected";
                if (message.monthlyRate != null && message.hasOwnProperty("monthlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.monthlyRate);
                    if (error)
                        return "monthlyRate." + error;
                }
                if (message.hourlyRate != null && message.hasOwnProperty("hourlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.hourlyRate);
                    if (error)
                        return "hourlyRate." + error;
                }
                if (message.windowsLicenseMonthlyRate != null && message.hasOwnProperty("windowsLicenseMonthlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.windowsLicenseMonthlyRate);
                    if (error)
                        return "windowsLicenseMonthlyRate." + error;
                }
                if (message.windowsLicenseHourlyRate != null && message.hasOwnProperty("windowsLicenseHourlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.windowsLicenseHourlyRate);
                    if (error)
                        return "windowsLicenseHourlyRate." + error;
                }
                if (message.transfer != null && message.hasOwnProperty("transfer"))
                    if (!$util.isInteger(message.transfer) && !(message.transfer && $util.isInteger(message.transfer.low) && $util.isInteger(message.transfer.high)))
                        return "transfer: integer|Long expected";
                if (message.benefitIds != null && message.hasOwnProperty("benefitIds")) {
                    if (!Array.isArray(message.benefitIds))
                        return "benefitIds: array expected";
                    for (var i = 0; i < message.benefitIds.length; ++i)
                        if (!$util.isString(message.benefitIds[i]))
                            return "benefitIds: string[] expected";
                }
                if (message.availableAddonIds != null && message.hasOwnProperty("availableAddonIds")) {
                    if (!Array.isArray(message.availableAddonIds))
                        return "availableAddonIds: array expected";
                    for (var i = 0; i < message.availableAddonIds.length; ++i)
                        if (!$util.isString(message.availableAddonIds[i]))
                            return "availableAddonIds: string[] expected";
                }
                if (message.firstPaymentHours != null && message.hasOwnProperty("firstPaymentHours"))
                    if (!$util.isInteger(message.firstPaymentHours))
                        return "firstPaymentHours: integer expected";
                return null;
            };
    
            /**
             * Creates a ServerPlan message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.ServerPlan
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.ServerPlan} ServerPlan
             */
            ServerPlan.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.ServerPlan)
                    return object;
                var message = new $root.ca2types.ServerPlan();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.classId != null)
                    message.classId = String(object.classId);
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.regionIds) {
                    if (!Array.isArray(object.regionIds))
                        throw TypeError(".ca2types.ServerPlan.regionIds: array expected");
                    message.regionIds = [];
                    for (var i = 0; i < object.regionIds.length; ++i)
                        message.regionIds[i] = String(object.regionIds[i]);
                }
                if (object.datacenterIds) {
                    if (!Array.isArray(object.datacenterIds))
                        throw TypeError(".ca2types.ServerPlan.datacenterIds: array expected");
                    message.datacenterIds = [];
                    for (var i = 0; i < object.datacenterIds.length; ++i)
                        message.datacenterIds[i] = String(object.datacenterIds[i]);
                }
                if (object.cpu != null) {
                    if (typeof object.cpu !== "object")
                        throw TypeError(".ca2types.ServerPlan.cpu: object expected");
                    message.cpu = $root.ca2types.CPU.fromObject(object.cpu);
                }
                if (object.gpu != null) {
                    if (typeof object.gpu !== "object")
                        throw TypeError(".ca2types.ServerPlan.gpu: object expected");
                    message.gpu = $root.ca2types.GPU.fromObject(object.gpu);
                }
                if (object.ramSize != null)
                    if ($util.Long)
                        (message.ramSize = $util.Long.fromValue(object.ramSize)).unsigned = true;
                    else if (typeof object.ramSize === "string")
                        message.ramSize = parseInt(object.ramSize, 10);
                    else if (typeof object.ramSize === "number")
                        message.ramSize = object.ramSize;
                    else if (typeof object.ramSize === "object")
                        message.ramSize = new $util.LongBits(object.ramSize.low >>> 0, object.ramSize.high >>> 0).toNumber(true);
                if (object.storageSize != null)
                    if ($util.Long)
                        (message.storageSize = $util.Long.fromValue(object.storageSize)).unsigned = true;
                    else if (typeof object.storageSize === "string")
                        message.storageSize = parseInt(object.storageSize, 10);
                    else if (typeof object.storageSize === "number")
                        message.storageSize = object.storageSize;
                    else if (typeof object.storageSize === "object")
                        message.storageSize = new $util.LongBits(object.storageSize.low >>> 0, object.storageSize.high >>> 0).toNumber(true);
                if (object.monthlyRate != null) {
                    if (typeof object.monthlyRate !== "object")
                        throw TypeError(".ca2types.ServerPlan.monthlyRate: object expected");
                    message.monthlyRate = $root.ca2types.Amount.fromObject(object.monthlyRate);
                }
                if (object.hourlyRate != null) {
                    if (typeof object.hourlyRate !== "object")
                        throw TypeError(".ca2types.ServerPlan.hourlyRate: object expected");
                    message.hourlyRate = $root.ca2types.Amount.fromObject(object.hourlyRate);
                }
                if (object.windowsLicenseMonthlyRate != null) {
                    if (typeof object.windowsLicenseMonthlyRate !== "object")
                        throw TypeError(".ca2types.ServerPlan.windowsLicenseMonthlyRate: object expected");
                    message.windowsLicenseMonthlyRate = $root.ca2types.Amount.fromObject(object.windowsLicenseMonthlyRate);
                }
                if (object.windowsLicenseHourlyRate != null) {
                    if (typeof object.windowsLicenseHourlyRate !== "object")
                        throw TypeError(".ca2types.ServerPlan.windowsLicenseHourlyRate: object expected");
                    message.windowsLicenseHourlyRate = $root.ca2types.Amount.fromObject(object.windowsLicenseHourlyRate);
                }
                if (object.transfer != null)
                    if ($util.Long)
                        (message.transfer = $util.Long.fromValue(object.transfer)).unsigned = true;
                    else if (typeof object.transfer === "string")
                        message.transfer = parseInt(object.transfer, 10);
                    else if (typeof object.transfer === "number")
                        message.transfer = object.transfer;
                    else if (typeof object.transfer === "object")
                        message.transfer = new $util.LongBits(object.transfer.low >>> 0, object.transfer.high >>> 0).toNumber(true);
                if (object.benefitIds) {
                    if (!Array.isArray(object.benefitIds))
                        throw TypeError(".ca2types.ServerPlan.benefitIds: array expected");
                    message.benefitIds = [];
                    for (var i = 0; i < object.benefitIds.length; ++i)
                        message.benefitIds[i] = String(object.benefitIds[i]);
                }
                if (object.availableAddonIds) {
                    if (!Array.isArray(object.availableAddonIds))
                        throw TypeError(".ca2types.ServerPlan.availableAddonIds: array expected");
                    message.availableAddonIds = [];
                    for (var i = 0; i < object.availableAddonIds.length; ++i)
                        message.availableAddonIds[i] = String(object.availableAddonIds[i]);
                }
                if (object.firstPaymentHours != null)
                    message.firstPaymentHours = object.firstPaymentHours >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ServerPlan message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.ServerPlan
             * @static
             * @param {ca2types.ServerPlan} message ServerPlan
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerPlan.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.regionIds = [];
                    object.benefitIds = [];
                    object.datacenterIds = [];
                    object.availableAddonIds = [];
                }
                if (options.defaults) {
                    object.id = "";
                    object.classId = "";
                    object.cpu = null;
                    object.gpu = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.ramSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.ramSize = options.longs === String ? "0" : 0;
                    object.monthlyRate = null;
                    object.hourlyRate = null;
                    object.windowsLicenseMonthlyRate = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.transfer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.transfer = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.storageSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.storageSize = options.longs === String ? "0" : 0;
                    object.providerId = "";
                    object.firstPaymentHours = 0;
                    object.windowsLicenseHourlyRate = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.classId != null && message.hasOwnProperty("classId"))
                    object.classId = message.classId;
                if (message.regionIds && message.regionIds.length) {
                    object.regionIds = [];
                    for (var j = 0; j < message.regionIds.length; ++j)
                        object.regionIds[j] = message.regionIds[j];
                }
                if (message.cpu != null && message.hasOwnProperty("cpu"))
                    object.cpu = $root.ca2types.CPU.toObject(message.cpu, options);
                if (message.gpu != null && message.hasOwnProperty("gpu"))
                    object.gpu = $root.ca2types.GPU.toObject(message.gpu, options);
                if (message.ramSize != null && message.hasOwnProperty("ramSize"))
                    if (typeof message.ramSize === "number")
                        object.ramSize = options.longs === String ? String(message.ramSize) : message.ramSize;
                    else
                        object.ramSize = options.longs === String ? $util.Long.prototype.toString.call(message.ramSize) : options.longs === Number ? new $util.LongBits(message.ramSize.low >>> 0, message.ramSize.high >>> 0).toNumber(true) : message.ramSize;
                if (message.monthlyRate != null && message.hasOwnProperty("monthlyRate"))
                    object.monthlyRate = $root.ca2types.Amount.toObject(message.monthlyRate, options);
                if (message.hourlyRate != null && message.hasOwnProperty("hourlyRate"))
                    object.hourlyRate = $root.ca2types.Amount.toObject(message.hourlyRate, options);
                if (message.windowsLicenseMonthlyRate != null && message.hasOwnProperty("windowsLicenseMonthlyRate"))
                    object.windowsLicenseMonthlyRate = $root.ca2types.Amount.toObject(message.windowsLicenseMonthlyRate, options);
                if (message.transfer != null && message.hasOwnProperty("transfer"))
                    if (typeof message.transfer === "number")
                        object.transfer = options.longs === String ? String(message.transfer) : message.transfer;
                    else
                        object.transfer = options.longs === String ? $util.Long.prototype.toString.call(message.transfer) : options.longs === Number ? new $util.LongBits(message.transfer.low >>> 0, message.transfer.high >>> 0).toNumber(true) : message.transfer;
                if (message.benefitIds && message.benefitIds.length) {
                    object.benefitIds = [];
                    for (var j = 0; j < message.benefitIds.length; ++j)
                        object.benefitIds[j] = message.benefitIds[j];
                }
                if (message.storageSize != null && message.hasOwnProperty("storageSize"))
                    if (typeof message.storageSize === "number")
                        object.storageSize = options.longs === String ? String(message.storageSize) : message.storageSize;
                    else
                        object.storageSize = options.longs === String ? $util.Long.prototype.toString.call(message.storageSize) : options.longs === Number ? new $util.LongBits(message.storageSize.low >>> 0, message.storageSize.high >>> 0).toNumber(true) : message.storageSize;
                if (message.datacenterIds && message.datacenterIds.length) {
                    object.datacenterIds = [];
                    for (var j = 0; j < message.datacenterIds.length; ++j)
                        object.datacenterIds[j] = message.datacenterIds[j];
                }
                if (message.availableAddonIds && message.availableAddonIds.length) {
                    object.availableAddonIds = [];
                    for (var j = 0; j < message.availableAddonIds.length; ++j)
                        object.availableAddonIds[j] = message.availableAddonIds[j];
                }
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.firstPaymentHours != null && message.hasOwnProperty("firstPaymentHours"))
                    object.firstPaymentHours = message.firstPaymentHours;
                if (message.windowsLicenseHourlyRate != null && message.hasOwnProperty("windowsLicenseHourlyRate"))
                    object.windowsLicenseHourlyRate = $root.ca2types.Amount.toObject(message.windowsLicenseHourlyRate, options);
                return object;
            };
    
            /**
             * Converts this ServerPlan to JSON.
             * @function toJSON
             * @memberof ca2types.ServerPlan
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerPlan.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServerPlan
             * @function getTypeUrl
             * @memberof ca2types.ServerPlan
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServerPlan.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.ServerPlan";
            };
    
            return ServerPlan;
        })();
    
        ca2types.Addon = (function() {
    
            /**
             * Properties of an Addon.
             * @memberof ca2types
             * @interface IAddon
             * @property {string|null} [id] Addon id
             * @property {string|null} [providerId] Addon providerId
             * @property {string|null} [title] Addon title
             * @property {string|null} [description] Addon description
             * @property {ca2types.IAmount|null} [monthlyRate] Addon monthlyRate
             * @property {ca2types.IAmount|null} [hourlyRate] Addon hourlyRate
             */
    
            /**
             * Constructs a new Addon.
             * @memberof ca2types
             * @classdesc Represents an Addon.
             * @implements IAddon
             * @constructor
             * @param {ca2types.IAddon=} [properties] Properties to set
             */
            function Addon(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Addon id.
             * @member {string} id
             * @memberof ca2types.Addon
             * @instance
             */
            Addon.prototype.id = "";
    
            /**
             * Addon providerId.
             * @member {string} providerId
             * @memberof ca2types.Addon
             * @instance
             */
            Addon.prototype.providerId = "";
    
            /**
             * Addon title.
             * @member {string} title
             * @memberof ca2types.Addon
             * @instance
             */
            Addon.prototype.title = "";
    
            /**
             * Addon description.
             * @member {string} description
             * @memberof ca2types.Addon
             * @instance
             */
            Addon.prototype.description = "";
    
            /**
             * Addon monthlyRate.
             * @member {ca2types.IAmount|null|undefined} monthlyRate
             * @memberof ca2types.Addon
             * @instance
             */
            Addon.prototype.monthlyRate = null;
    
            /**
             * Addon hourlyRate.
             * @member {ca2types.IAmount|null|undefined} hourlyRate
             * @memberof ca2types.Addon
             * @instance
             */
            Addon.prototype.hourlyRate = null;
    
            /**
             * Creates a new Addon instance using the specified properties.
             * @function create
             * @memberof ca2types.Addon
             * @static
             * @param {ca2types.IAddon=} [properties] Properties to set
             * @returns {ca2types.Addon} Addon instance
             */
            Addon.create = function create(properties) {
                return new Addon(properties);
            };
    
            /**
             * Encodes the specified Addon message. Does not implicitly {@link ca2types.Addon.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Addon
             * @static
             * @param {ca2types.IAddon} message Addon message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Addon.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.monthlyRate != null && Object.hasOwnProperty.call(message, "monthlyRate"))
                    $root.ca2types.Amount.encode(message.monthlyRate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.providerId);
                if (message.hourlyRate != null && Object.hasOwnProperty.call(message, "hourlyRate"))
                    $root.ca2types.Amount.encode(message.hourlyRate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Addon message, length delimited. Does not implicitly {@link ca2types.Addon.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Addon
             * @static
             * @param {ca2types.IAddon} message Addon message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Addon.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Addon message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Addon
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Addon} Addon
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Addon.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Addon();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 8: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 3: {
                            message.title = reader.string();
                            break;
                        }
                    case 4: {
                            message.description = reader.string();
                            break;
                        }
                    case 2: {
                            message.monthlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.hourlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Addon message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Addon
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Addon} Addon
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Addon.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Addon message.
             * @function verify
             * @memberof ca2types.Addon
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Addon.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.monthlyRate != null && message.hasOwnProperty("monthlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.monthlyRate);
                    if (error)
                        return "monthlyRate." + error;
                }
                if (message.hourlyRate != null && message.hasOwnProperty("hourlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.hourlyRate);
                    if (error)
                        return "hourlyRate." + error;
                }
                return null;
            };
    
            /**
             * Creates an Addon message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Addon
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Addon} Addon
             */
            Addon.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Addon)
                    return object;
                var message = new $root.ca2types.Addon();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.title != null)
                    message.title = String(object.title);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.monthlyRate != null) {
                    if (typeof object.monthlyRate !== "object")
                        throw TypeError(".ca2types.Addon.monthlyRate: object expected");
                    message.monthlyRate = $root.ca2types.Amount.fromObject(object.monthlyRate);
                }
                if (object.hourlyRate != null) {
                    if (typeof object.hourlyRate !== "object")
                        throw TypeError(".ca2types.Addon.hourlyRate: object expected");
                    message.hourlyRate = $root.ca2types.Amount.fromObject(object.hourlyRate);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an Addon message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Addon
             * @static
             * @param {ca2types.Addon} message Addon
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Addon.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.monthlyRate = null;
                    object.title = "";
                    object.description = "";
                    object.providerId = "";
                    object.hourlyRate = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.monthlyRate != null && message.hasOwnProperty("monthlyRate"))
                    object.monthlyRate = $root.ca2types.Amount.toObject(message.monthlyRate, options);
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.hourlyRate != null && message.hasOwnProperty("hourlyRate"))
                    object.hourlyRate = $root.ca2types.Amount.toObject(message.hourlyRate, options);
                return object;
            };
    
            /**
             * Converts this Addon to JSON.
             * @function toJSON
             * @memberof ca2types.Addon
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Addon.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Addon
             * @function getTypeUrl
             * @memberof ca2types.Addon
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Addon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Addon";
            };
    
            return Addon;
        })();
    
        ca2types.PaymentOption = (function() {
    
            /**
             * Properties of a PaymentOption.
             * @memberof ca2types
             * @interface IPaymentOption
             * @property {string|null} [title] PaymentOption title
             * @property {ca2types.IAmount|null} [amount] PaymentOption amount
             */
    
            /**
             * Constructs a new PaymentOption.
             * @memberof ca2types
             * @classdesc Represents a PaymentOption.
             * @implements IPaymentOption
             * @constructor
             * @param {ca2types.IPaymentOption=} [properties] Properties to set
             */
            function PaymentOption(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PaymentOption title.
             * @member {string} title
             * @memberof ca2types.PaymentOption
             * @instance
             */
            PaymentOption.prototype.title = "";
    
            /**
             * PaymentOption amount.
             * @member {ca2types.IAmount|null|undefined} amount
             * @memberof ca2types.PaymentOption
             * @instance
             */
            PaymentOption.prototype.amount = null;
    
            /**
             * Creates a new PaymentOption instance using the specified properties.
             * @function create
             * @memberof ca2types.PaymentOption
             * @static
             * @param {ca2types.IPaymentOption=} [properties] Properties to set
             * @returns {ca2types.PaymentOption} PaymentOption instance
             */
            PaymentOption.create = function create(properties) {
                return new PaymentOption(properties);
            };
    
            /**
             * Encodes the specified PaymentOption message. Does not implicitly {@link ca2types.PaymentOption.verify|verify} messages.
             * @function encode
             * @memberof ca2types.PaymentOption
             * @static
             * @param {ca2types.IPaymentOption} message PaymentOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    $root.ca2types.Amount.encode(message.amount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PaymentOption message, length delimited. Does not implicitly {@link ca2types.PaymentOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.PaymentOption
             * @static
             * @param {ca2types.IPaymentOption} message PaymentOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PaymentOption message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.PaymentOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.PaymentOption} PaymentOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.PaymentOption();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.title = reader.string();
                            break;
                        }
                    case 3: {
                            message.amount = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PaymentOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.PaymentOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.PaymentOption} PaymentOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PaymentOption message.
             * @function verify
             * @memberof ca2types.PaymentOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaymentOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                if (message.amount != null && message.hasOwnProperty("amount")) {
                    var error = $root.ca2types.Amount.verify(message.amount);
                    if (error)
                        return "amount." + error;
                }
                return null;
            };
    
            /**
             * Creates a PaymentOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.PaymentOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.PaymentOption} PaymentOption
             */
            PaymentOption.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.PaymentOption)
                    return object;
                var message = new $root.ca2types.PaymentOption();
                if (object.title != null)
                    message.title = String(object.title);
                if (object.amount != null) {
                    if (typeof object.amount !== "object")
                        throw TypeError(".ca2types.PaymentOption.amount: object expected");
                    message.amount = $root.ca2types.Amount.fromObject(object.amount);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PaymentOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.PaymentOption
             * @static
             * @param {ca2types.PaymentOption} message PaymentOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaymentOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.title = "";
                    object.amount = null;
                }
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = $root.ca2types.Amount.toObject(message.amount, options);
                return object;
            };
    
            /**
             * Converts this PaymentOption to JSON.
             * @function toJSON
             * @memberof ca2types.PaymentOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaymentOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PaymentOption
             * @function getTypeUrl
             * @memberof ca2types.PaymentOption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaymentOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.PaymentOption";
            };
    
            return PaymentOption;
        })();
    
        ca2types.Tab = (function() {
    
            /**
             * Properties of a Tab.
             * @memberof ca2types
             * @interface ITab
             * @property {string|null} [id] Tab id
             * @property {string|null} [title] Tab title
             */
    
            /**
             * Constructs a new Tab.
             * @memberof ca2types
             * @classdesc Represents a Tab.
             * @implements ITab
             * @constructor
             * @param {ca2types.ITab=} [properties] Properties to set
             */
            function Tab(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Tab id.
             * @member {string} id
             * @memberof ca2types.Tab
             * @instance
             */
            Tab.prototype.id = "";
    
            /**
             * Tab title.
             * @member {string} title
             * @memberof ca2types.Tab
             * @instance
             */
            Tab.prototype.title = "";
    
            /**
             * Creates a new Tab instance using the specified properties.
             * @function create
             * @memberof ca2types.Tab
             * @static
             * @param {ca2types.ITab=} [properties] Properties to set
             * @returns {ca2types.Tab} Tab instance
             */
            Tab.create = function create(properties) {
                return new Tab(properties);
            };
    
            /**
             * Encodes the specified Tab message. Does not implicitly {@link ca2types.Tab.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Tab
             * @static
             * @param {ca2types.ITab} message Tab message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tab.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                return writer;
            };
    
            /**
             * Encodes the specified Tab message, length delimited. Does not implicitly {@link ca2types.Tab.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Tab
             * @static
             * @param {ca2types.ITab} message Tab message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tab.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Tab message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Tab
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Tab} Tab
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tab.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Tab();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.title = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Tab message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Tab
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Tab} Tab
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tab.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Tab message.
             * @function verify
             * @memberof ca2types.Tab
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Tab.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                return null;
            };
    
            /**
             * Creates a Tab message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Tab
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Tab} Tab
             */
            Tab.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Tab)
                    return object;
                var message = new $root.ca2types.Tab();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.title != null)
                    message.title = String(object.title);
                return message;
            };
    
            /**
             * Creates a plain object from a Tab message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Tab
             * @static
             * @param {ca2types.Tab} message Tab
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Tab.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.title = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                return object;
            };
    
            /**
             * Converts this Tab to JSON.
             * @function toJSON
             * @memberof ca2types.Tab
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Tab.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Tab
             * @function getTypeUrl
             * @memberof ca2types.Tab
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Tab.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Tab";
            };
    
            return Tab;
        })();
    
        ca2types.Image = (function() {
    
            /**
             * Properties of an Image.
             * @memberof ca2types
             * @interface IImage
             * @property {string|null} [id] Image id
             * @property {string|null} [family] Image family
             * @property {string|null} [name] Image name
             * @property {string|null} [providerId] Image providerId
             * @property {boolean|null} [isWindows] Image isWindows
             * @property {boolean|null} [passwordDisabled] Image passwordDisabled
             */
    
            /**
             * Constructs a new Image.
             * @memberof ca2types
             * @classdesc Represents an Image.
             * @implements IImage
             * @constructor
             * @param {ca2types.IImage=} [properties] Properties to set
             */
            function Image(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Image id.
             * @member {string} id
             * @memberof ca2types.Image
             * @instance
             */
            Image.prototype.id = "";
    
            /**
             * Image family.
             * @member {string} family
             * @memberof ca2types.Image
             * @instance
             */
            Image.prototype.family = "";
    
            /**
             * Image name.
             * @member {string} name
             * @memberof ca2types.Image
             * @instance
             */
            Image.prototype.name = "";
    
            /**
             * Image providerId.
             * @member {string} providerId
             * @memberof ca2types.Image
             * @instance
             */
            Image.prototype.providerId = "";
    
            /**
             * Image isWindows.
             * @member {boolean} isWindows
             * @memberof ca2types.Image
             * @instance
             */
            Image.prototype.isWindows = false;
    
            /**
             * Image passwordDisabled.
             * @member {boolean} passwordDisabled
             * @memberof ca2types.Image
             * @instance
             */
            Image.prototype.passwordDisabled = false;
    
            /**
             * Creates a new Image instance using the specified properties.
             * @function create
             * @memberof ca2types.Image
             * @static
             * @param {ca2types.IImage=} [properties] Properties to set
             * @returns {ca2types.Image} Image instance
             */
            Image.create = function create(properties) {
                return new Image(properties);
            };
    
            /**
             * Encodes the specified Image message. Does not implicitly {@link ca2types.Image.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Image
             * @static
             * @param {ca2types.IImage} message Image message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Image.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.providerId);
                if (message.family != null && Object.hasOwnProperty.call(message, "family"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.family);
                if (message.isWindows != null && Object.hasOwnProperty.call(message, "isWindows"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isWindows);
                if (message.passwordDisabled != null && Object.hasOwnProperty.call(message, "passwordDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.passwordDisabled);
                return writer;
            };
    
            /**
             * Encodes the specified Image message, length delimited. Does not implicitly {@link ca2types.Image.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Image
             * @static
             * @param {ca2types.IImage} message Image message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Image.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Image message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Image
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Image} Image
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Image.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Image();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 4: {
                            message.family = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 5: {
                            message.isWindows = reader.bool();
                            break;
                        }
                    case 6: {
                            message.passwordDisabled = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Image message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Image
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Image} Image
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Image.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Image message.
             * @function verify
             * @memberof ca2types.Image
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Image.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.family != null && message.hasOwnProperty("family"))
                    if (!$util.isString(message.family))
                        return "family: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.isWindows != null && message.hasOwnProperty("isWindows"))
                    if (typeof message.isWindows !== "boolean")
                        return "isWindows: boolean expected";
                if (message.passwordDisabled != null && message.hasOwnProperty("passwordDisabled"))
                    if (typeof message.passwordDisabled !== "boolean")
                        return "passwordDisabled: boolean expected";
                return null;
            };
    
            /**
             * Creates an Image message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Image
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Image} Image
             */
            Image.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Image)
                    return object;
                var message = new $root.ca2types.Image();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.family != null)
                    message.family = String(object.family);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.isWindows != null)
                    message.isWindows = Boolean(object.isWindows);
                if (object.passwordDisabled != null)
                    message.passwordDisabled = Boolean(object.passwordDisabled);
                return message;
            };
    
            /**
             * Creates a plain object from an Image message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Image
             * @static
             * @param {ca2types.Image} message Image
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Image.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.name = "";
                    object.providerId = "";
                    object.family = "";
                    object.isWindows = false;
                    object.passwordDisabled = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.family != null && message.hasOwnProperty("family"))
                    object.family = message.family;
                if (message.isWindows != null && message.hasOwnProperty("isWindows"))
                    object.isWindows = message.isWindows;
                if (message.passwordDisabled != null && message.hasOwnProperty("passwordDisabled"))
                    object.passwordDisabled = message.passwordDisabled;
                return object;
            };
    
            /**
             * Converts this Image to JSON.
             * @function toJSON
             * @memberof ca2types.Image
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Image.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Image
             * @function getTypeUrl
             * @memberof ca2types.Image
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Image.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Image";
            };
    
            return Image;
        })();
    
        /**
         * BalanceType enum.
         * @name ca2types.BalanceType
         * @enum {number}
         * @property {number} BT_UNKNOWN=0 BT_UNKNOWN value
         * @property {number} BT_MAIN=1 BT_MAIN value
         * @property {number} BT_BONUS=2 BT_BONUS value
         */
        ca2types.BalanceType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BT_UNKNOWN"] = 0;
            values[valuesById[1] = "BT_MAIN"] = 1;
            values[valuesById[2] = "BT_BONUS"] = 2;
            return values;
        })();
    
        ca2types.Balance = (function() {
    
            /**
             * Properties of a Balance.
             * @memberof ca2types
             * @interface IBalance
             * @property {ca2types.BalanceType|null} [type] Balance type
             * @property {ca2types.IAmount|null} [amount] Balance amount
             */
    
            /**
             * Constructs a new Balance.
             * @memberof ca2types
             * @classdesc Represents a Balance.
             * @implements IBalance
             * @constructor
             * @param {ca2types.IBalance=} [properties] Properties to set
             */
            function Balance(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Balance type.
             * @member {ca2types.BalanceType} type
             * @memberof ca2types.Balance
             * @instance
             */
            Balance.prototype.type = 0;
    
            /**
             * Balance amount.
             * @member {ca2types.IAmount|null|undefined} amount
             * @memberof ca2types.Balance
             * @instance
             */
            Balance.prototype.amount = null;
    
            /**
             * Creates a new Balance instance using the specified properties.
             * @function create
             * @memberof ca2types.Balance
             * @static
             * @param {ca2types.IBalance=} [properties] Properties to set
             * @returns {ca2types.Balance} Balance instance
             */
            Balance.create = function create(properties) {
                return new Balance(properties);
            };
    
            /**
             * Encodes the specified Balance message. Does not implicitly {@link ca2types.Balance.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Balance
             * @static
             * @param {ca2types.IBalance} message Balance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Balance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    $root.ca2types.Amount.encode(message.amount, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Balance message, length delimited. Does not implicitly {@link ca2types.Balance.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Balance
             * @static
             * @param {ca2types.IBalance} message Balance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Balance.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Balance message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Balance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Balance} Balance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Balance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Balance();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type = reader.int32();
                            break;
                        }
                    case 4: {
                            message.amount = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Balance message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Balance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Balance} Balance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Balance.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Balance message.
             * @function verify
             * @memberof ca2types.Balance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Balance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.amount != null && message.hasOwnProperty("amount")) {
                    var error = $root.ca2types.Amount.verify(message.amount);
                    if (error)
                        return "amount." + error;
                }
                return null;
            };
    
            /**
             * Creates a Balance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Balance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Balance} Balance
             */
            Balance.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Balance)
                    return object;
                var message = new $root.ca2types.Balance();
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "BT_UNKNOWN":
                case 0:
                    message.type = 0;
                    break;
                case "BT_MAIN":
                case 1:
                    message.type = 1;
                    break;
                case "BT_BONUS":
                case 2:
                    message.type = 2;
                    break;
                }
                if (object.amount != null) {
                    if (typeof object.amount !== "object")
                        throw TypeError(".ca2types.Balance.amount: object expected");
                    message.amount = $root.ca2types.Amount.fromObject(object.amount);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Balance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Balance
             * @static
             * @param {ca2types.Balance} message Balance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Balance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "BT_UNKNOWN" : 0;
                    object.amount = null;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.ca2types.BalanceType[message.type] === undefined ? message.type : $root.ca2types.BalanceType[message.type] : message.type;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = $root.ca2types.Amount.toObject(message.amount, options);
                return object;
            };
    
            /**
             * Converts this Balance to JSON.
             * @function toJSON
             * @memberof ca2types.Balance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Balance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Balance
             * @function getTypeUrl
             * @memberof ca2types.Balance
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Balance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Balance";
            };
    
            return Balance;
        })();
    
        /**
         * PaymentStatus enum.
         * @name ca2types.PaymentStatus
         * @enum {number}
         * @property {number} PST_UNKNOWN=0 PST_UNKNOWN value
         * @property {number} PST_PENDING=1 PST_PENDING value
         * @property {number} PST_SUCCESS=2 PST_SUCCESS value
         */
        ca2types.PaymentStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PST_UNKNOWN"] = 0;
            values[valuesById[1] = "PST_PENDING"] = 1;
            values[valuesById[2] = "PST_SUCCESS"] = 2;
            return values;
        })();
    
        ca2types.Payment = (function() {
    
            /**
             * Properties of a Payment.
             * @memberof ca2types
             * @interface IPayment
             * @property {string|null} [foreignId] Payment foreignId
             * @property {ca2types.PaymentStatus|null} [status] Payment status
             */
    
            /**
             * Constructs a new Payment.
             * @memberof ca2types
             * @classdesc Represents a Payment.
             * @implements IPayment
             * @constructor
             * @param {ca2types.IPayment=} [properties] Properties to set
             */
            function Payment(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Payment foreignId.
             * @member {string} foreignId
             * @memberof ca2types.Payment
             * @instance
             */
            Payment.prototype.foreignId = "";
    
            /**
             * Payment status.
             * @member {ca2types.PaymentStatus} status
             * @memberof ca2types.Payment
             * @instance
             */
            Payment.prototype.status = 0;
    
            /**
             * Creates a new Payment instance using the specified properties.
             * @function create
             * @memberof ca2types.Payment
             * @static
             * @param {ca2types.IPayment=} [properties] Properties to set
             * @returns {ca2types.Payment} Payment instance
             */
            Payment.create = function create(properties) {
                return new Payment(properties);
            };
    
            /**
             * Encodes the specified Payment message. Does not implicitly {@link ca2types.Payment.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Payment
             * @static
             * @param {ca2types.IPayment} message Payment message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Payment.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.foreignId != null && Object.hasOwnProperty.call(message, "foreignId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.foreignId);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                return writer;
            };
    
            /**
             * Encodes the specified Payment message, length delimited. Does not implicitly {@link ca2types.Payment.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Payment
             * @static
             * @param {ca2types.IPayment} message Payment message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Payment.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Payment message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Payment
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Payment} Payment
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Payment.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Payment();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.foreignId = reader.string();
                            break;
                        }
                    case 2: {
                            message.status = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Payment message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Payment
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Payment} Payment
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Payment.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Payment message.
             * @function verify
             * @memberof ca2types.Payment
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Payment.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.foreignId != null && message.hasOwnProperty("foreignId"))
                    if (!$util.isString(message.foreignId))
                        return "foreignId: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a Payment message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Payment
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Payment} Payment
             */
            Payment.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Payment)
                    return object;
                var message = new $root.ca2types.Payment();
                if (object.foreignId != null)
                    message.foreignId = String(object.foreignId);
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "PST_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "PST_PENDING":
                case 1:
                    message.status = 1;
                    break;
                case "PST_SUCCESS":
                case 2:
                    message.status = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Payment message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Payment
             * @static
             * @param {ca2types.Payment} message Payment
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Payment.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.foreignId = "";
                    object.status = options.enums === String ? "PST_UNKNOWN" : 0;
                }
                if (message.foreignId != null && message.hasOwnProperty("foreignId"))
                    object.foreignId = message.foreignId;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.ca2types.PaymentStatus[message.status] === undefined ? message.status : $root.ca2types.PaymentStatus[message.status] : message.status;
                return object;
            };
    
            /**
             * Converts this Payment to JSON.
             * @function toJSON
             * @memberof ca2types.Payment
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Payment.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Payment
             * @function getTypeUrl
             * @memberof ca2types.Payment
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Payment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Payment";
            };
    
            return Payment;
        })();
    
        /**
         * BalanceOperationType enum.
         * @name ca2types.BalanceOperationType
         * @enum {number}
         * @property {number} BOT_UNKNOWN=0 BOT_UNKNOWN value
         * @property {number} BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE=1 BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE value
         * @property {number} BOT_PROMO_CODE=2 BOT_PROMO_CODE value
         * @property {number} BOT_REFERRAL_OPERATION=3 BOT_REFERRAL_OPERATION value
         * @property {number} BOT_INSTANCE_HOUR=4 BOT_INSTANCE_HOUR value
         * @property {number} BOT_ADMIN_CORRECTION=5 BOT_ADMIN_CORRECTION value
         * @property {number} BOT_PAYMENT=6 BOT_PAYMENT value
         */
        ca2types.BalanceOperationType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BOT_UNKNOWN"] = 0;
            values[valuesById[1] = "BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE"] = 1;
            values[valuesById[2] = "BOT_PROMO_CODE"] = 2;
            values[valuesById[3] = "BOT_REFERRAL_OPERATION"] = 3;
            values[valuesById[4] = "BOT_INSTANCE_HOUR"] = 4;
            values[valuesById[5] = "BOT_ADMIN_CORRECTION"] = 5;
            values[valuesById[6] = "BOT_PAYMENT"] = 6;
            return values;
        })();
    
        ca2types.BalanceOperation = (function() {
    
            /**
             * Properties of a BalanceOperation.
             * @memberof ca2types
             * @interface IBalanceOperation
             * @property {Long|null} [id] BalanceOperation id
             * @property {ca2types.BalanceType|null} [balanceType] BalanceOperation balanceType
             * @property {ca2types.BalanceOperationType|null} [type] BalanceOperation type
             * @property {Long|null} [createdAt] BalanceOperation createdAt
             * @property {ca2types.IAmount|null} [amount] BalanceOperation amount
             * @property {string|null} [description] BalanceOperation description
             */
    
            /**
             * Constructs a new BalanceOperation.
             * @memberof ca2types
             * @classdesc Represents a BalanceOperation.
             * @implements IBalanceOperation
             * @constructor
             * @param {ca2types.IBalanceOperation=} [properties] Properties to set
             */
            function BalanceOperation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalanceOperation id.
             * @member {Long} id
             * @memberof ca2types.BalanceOperation
             * @instance
             */
            BalanceOperation.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * BalanceOperation balanceType.
             * @member {ca2types.BalanceType} balanceType
             * @memberof ca2types.BalanceOperation
             * @instance
             */
            BalanceOperation.prototype.balanceType = 0;
    
            /**
             * BalanceOperation type.
             * @member {ca2types.BalanceOperationType} type
             * @memberof ca2types.BalanceOperation
             * @instance
             */
            BalanceOperation.prototype.type = 0;
    
            /**
             * BalanceOperation createdAt.
             * @member {Long} createdAt
             * @memberof ca2types.BalanceOperation
             * @instance
             */
            BalanceOperation.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * BalanceOperation amount.
             * @member {ca2types.IAmount|null|undefined} amount
             * @memberof ca2types.BalanceOperation
             * @instance
             */
            BalanceOperation.prototype.amount = null;
    
            /**
             * BalanceOperation description.
             * @member {string} description
             * @memberof ca2types.BalanceOperation
             * @instance
             */
            BalanceOperation.prototype.description = "";
    
            /**
             * Creates a new BalanceOperation instance using the specified properties.
             * @function create
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {ca2types.IBalanceOperation=} [properties] Properties to set
             * @returns {ca2types.BalanceOperation} BalanceOperation instance
             */
            BalanceOperation.create = function create(properties) {
                return new BalanceOperation(properties);
            };
    
            /**
             * Encodes the specified BalanceOperation message. Does not implicitly {@link ca2types.BalanceOperation.verify|verify} messages.
             * @function encode
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {ca2types.IBalanceOperation} message BalanceOperation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balanceType != null && Object.hasOwnProperty.call(message, "balanceType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.balanceType);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.createdAt);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.id);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.description);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    $root.ca2types.Amount.encode(message.amount, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BalanceOperation message, length delimited. Does not implicitly {@link ca2types.BalanceOperation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {ca2types.IBalanceOperation} message BalanceOperation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalanceOperation message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.BalanceOperation} BalanceOperation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.BalanceOperation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 5: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 1: {
                            message.balanceType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.createdAt = reader.uint64();
                            break;
                        }
                    case 8: {
                            message.amount = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.description = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalanceOperation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.BalanceOperation} BalanceOperation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalanceOperation message.
             * @function verify
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalanceOperation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.balanceType != null && message.hasOwnProperty("balanceType"))
                    switch (message.balanceType) {
                    default:
                        return "balanceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.amount != null && message.hasOwnProperty("amount")) {
                    var error = $root.ca2types.Amount.verify(message.amount);
                    if (error)
                        return "amount." + error;
                }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };
    
            /**
             * Creates a BalanceOperation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.BalanceOperation} BalanceOperation
             */
            BalanceOperation.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.BalanceOperation)
                    return object;
                var message = new $root.ca2types.BalanceOperation();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                switch (object.balanceType) {
                default:
                    if (typeof object.balanceType === "number") {
                        message.balanceType = object.balanceType;
                        break;
                    }
                    break;
                case "BT_UNKNOWN":
                case 0:
                    message.balanceType = 0;
                    break;
                case "BT_MAIN":
                case 1:
                    message.balanceType = 1;
                    break;
                case "BT_BONUS":
                case 2:
                    message.balanceType = 2;
                    break;
                }
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "BOT_UNKNOWN":
                case 0:
                    message.type = 0;
                    break;
                case "BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE":
                case 1:
                    message.type = 1;
                    break;
                case "BOT_PROMO_CODE":
                case 2:
                    message.type = 2;
                    break;
                case "BOT_REFERRAL_OPERATION":
                case 3:
                    message.type = 3;
                    break;
                case "BOT_INSTANCE_HOUR":
                case 4:
                    message.type = 4;
                    break;
                case "BOT_ADMIN_CORRECTION":
                case 5:
                    message.type = 5;
                    break;
                case "BOT_PAYMENT":
                case 6:
                    message.type = 6;
                    break;
                }
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                if (object.amount != null) {
                    if (typeof object.amount !== "object")
                        throw TypeError(".ca2types.BalanceOperation.amount: object expected");
                    message.amount = $root.ca2types.Amount.fromObject(object.amount);
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };
    
            /**
             * Creates a plain object from a BalanceOperation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {ca2types.BalanceOperation} message BalanceOperation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalanceOperation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.balanceType = options.enums === String ? "BT_UNKNOWN" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "BOT_UNKNOWN" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.description = "";
                    object.amount = null;
                }
                if (message.balanceType != null && message.hasOwnProperty("balanceType"))
                    object.balanceType = options.enums === String ? $root.ca2types.BalanceType[message.balanceType] === undefined ? message.balanceType : $root.ca2types.BalanceType[message.balanceType] : message.balanceType;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.ca2types.BalanceOperationType[message.type] === undefined ? message.type : $root.ca2types.BalanceOperationType[message.type] : message.type;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = $root.ca2types.Amount.toObject(message.amount, options);
                return object;
            };
    
            /**
             * Converts this BalanceOperation to JSON.
             * @function toJSON
             * @memberof ca2types.BalanceOperation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalanceOperation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalanceOperation
             * @function getTypeUrl
             * @memberof ca2types.BalanceOperation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalanceOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.BalanceOperation";
            };
    
            return BalanceOperation;
        })();
    
        /**
         * InstanceStatus enum.
         * @name ca2types.InstanceStatus
         * @enum {number}
         * @property {number} IS_UNKNOWN=0 IS_UNKNOWN value
         * @property {number} IS_CREATING=6 IS_CREATING value
         * @property {number} IS_DELETING=14 IS_DELETING value
         * @property {number} IS_RESTARTING=17 IS_RESTARTING value
         * @property {number} IS_STARTING=8 IS_STARTING value
         * @property {number} IS_STOPPING=7 IS_STOPPING value
         * @property {number} IS_REINSTALLING=9 IS_REINSTALLING value
         * @property {number} IS_RESIZING=5 IS_RESIZING value
         * @property {number} IS_ACTIVE=2 IS_ACTIVE value
         * @property {number} IS_PROCESSING=18 IS_PROCESSING value
         * @property {number} IS_STOPPED=10 IS_STOPPED value
         * @property {number} IS_DELETED=15 IS_DELETED value
         * @property {number} IS_ERROR=16 IS_ERROR value
         */
        ca2types.InstanceStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "IS_UNKNOWN"] = 0;
            values[valuesById[6] = "IS_CREATING"] = 6;
            values[valuesById[14] = "IS_DELETING"] = 14;
            values[valuesById[17] = "IS_RESTARTING"] = 17;
            values[valuesById[8] = "IS_STARTING"] = 8;
            values[valuesById[7] = "IS_STOPPING"] = 7;
            values[valuesById[9] = "IS_REINSTALLING"] = 9;
            values[valuesById[5] = "IS_RESIZING"] = 5;
            values[valuesById[2] = "IS_ACTIVE"] = 2;
            values[valuesById[18] = "IS_PROCESSING"] = 18;
            values[valuesById[10] = "IS_STOPPED"] = 10;
            values[valuesById[15] = "IS_DELETED"] = 15;
            values[valuesById[16] = "IS_ERROR"] = 16;
            return values;
        })();
    
        ca2types.Instance = (function() {
    
            /**
             * Properties of an Instance.
             * @memberof ca2types
             * @interface IInstance
             * @property {number|null} [id] Instance id
             * @property {string|null} [planId] Instance planId
             * @property {string|null} [datacenterId] Instance datacenterId
             * @property {string|null} [imageId] Instance imageId
             * @property {Array.<number>|null} [sshKeyIds] Instance sshKeyIds
             * @property {string|null} [rootPassword] Instance rootPassword
             * @property {string|null} [rootUsername] Instance rootUsername
             * @property {string|null} [name] Instance name
             * @property {boolean|null} [isImportant] Instance isImportant
             * @property {string|null} [ipv4] Instance ipv4
             * @property {string|null} [ipv6] Instance ipv6
             * @property {string|null} [errorDescription] Instance errorDescription
             * @property {Array.<string>|null} [enabledAddonIds] Instance enabledAddonIds
             * @property {string|null} [providerId] Instance providerId
             * @property {Long|null} [uptime] Instance uptime
             * @property {ca2types.IAmount|null} [totalCharge] Instance totalCharge
             * @property {ca2types.InstanceStatus|null} [instanceStatus] Instance instanceStatus
             * @property {string|null} [initialSetupScript] Instance initialSetupScript
             * @property {boolean|null} [allowDelete] Instance allowDelete
             * @property {boolean|null} [allowStart] Instance allowStart
             * @property {boolean|null} [allowStop] Instance allowStop
             * @property {boolean|null} [allowRestart] Instance allowRestart
             * @property {boolean|null} [allowUpdateSshKeys] Instance allowUpdateSshKeys
             */
    
            /**
             * Constructs a new Instance.
             * @memberof ca2types
             * @classdesc Represents an Instance.
             * @implements IInstance
             * @constructor
             * @param {ca2types.IInstance=} [properties] Properties to set
             */
            function Instance(properties) {
                this.sshKeyIds = [];
                this.enabledAddonIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Instance id.
             * @member {number} id
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.id = 0;
    
            /**
             * Instance planId.
             * @member {string} planId
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.planId = "";
    
            /**
             * Instance datacenterId.
             * @member {string} datacenterId
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.datacenterId = "";
    
            /**
             * Instance imageId.
             * @member {string} imageId
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.imageId = "";
    
            /**
             * Instance sshKeyIds.
             * @member {Array.<number>} sshKeyIds
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.sshKeyIds = $util.emptyArray;
    
            /**
             * Instance rootPassword.
             * @member {string} rootPassword
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.rootPassword = "";
    
            /**
             * Instance rootUsername.
             * @member {string} rootUsername
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.rootUsername = "";
    
            /**
             * Instance name.
             * @member {string} name
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.name = "";
    
            /**
             * Instance isImportant.
             * @member {boolean} isImportant
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.isImportant = false;
    
            /**
             * Instance ipv4.
             * @member {string} ipv4
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.ipv4 = "";
    
            /**
             * Instance ipv6.
             * @member {string} ipv6
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.ipv6 = "";
    
            /**
             * Instance errorDescription.
             * @member {string} errorDescription
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.errorDescription = "";
    
            /**
             * Instance enabledAddonIds.
             * @member {Array.<string>} enabledAddonIds
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.enabledAddonIds = $util.emptyArray;
    
            /**
             * Instance providerId.
             * @member {string} providerId
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.providerId = "";
    
            /**
             * Instance uptime.
             * @member {Long} uptime
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.uptime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Instance totalCharge.
             * @member {ca2types.IAmount|null|undefined} totalCharge
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.totalCharge = null;
    
            /**
             * Instance instanceStatus.
             * @member {ca2types.InstanceStatus} instanceStatus
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.instanceStatus = 0;
    
            /**
             * Instance initialSetupScript.
             * @member {string} initialSetupScript
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.initialSetupScript = "";
    
            /**
             * Instance allowDelete.
             * @member {boolean} allowDelete
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.allowDelete = false;
    
            /**
             * Instance allowStart.
             * @member {boolean} allowStart
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.allowStart = false;
    
            /**
             * Instance allowStop.
             * @member {boolean} allowStop
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.allowStop = false;
    
            /**
             * Instance allowRestart.
             * @member {boolean} allowRestart
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.allowRestart = false;
    
            /**
             * Instance allowUpdateSshKeys.
             * @member {boolean} allowUpdateSshKeys
             * @memberof ca2types.Instance
             * @instance
             */
            Instance.prototype.allowUpdateSshKeys = false;
    
            /**
             * Creates a new Instance instance using the specified properties.
             * @function create
             * @memberof ca2types.Instance
             * @static
             * @param {ca2types.IInstance=} [properties] Properties to set
             * @returns {ca2types.Instance} Instance instance
             */
            Instance.create = function create(properties) {
                return new Instance(properties);
            };
    
            /**
             * Encodes the specified Instance message. Does not implicitly {@link ca2types.Instance.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Instance
             * @static
             * @param {ca2types.IInstance} message Instance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Instance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.planId);
                if (message.datacenterId != null && Object.hasOwnProperty.call(message, "datacenterId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.datacenterId);
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.imageId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
                if (message.isImportant != null && Object.hasOwnProperty.call(message, "isImportant"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isImportant);
                if (message.ipv4 != null && Object.hasOwnProperty.call(message, "ipv4"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.ipv4);
                if (message.ipv6 != null && Object.hasOwnProperty.call(message, "ipv6"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.ipv6);
                if (message.errorDescription != null && Object.hasOwnProperty.call(message, "errorDescription"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.errorDescription);
                if (message.instanceStatus != null && Object.hasOwnProperty.call(message, "instanceStatus"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.instanceStatus);
                if (message.initialSetupScript != null && Object.hasOwnProperty.call(message, "initialSetupScript"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.initialSetupScript);
                if (message.rootPassword != null && Object.hasOwnProperty.call(message, "rootPassword"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.rootPassword);
                if (message.enabledAddonIds != null && message.enabledAddonIds.length)
                    for (var i = 0; i < message.enabledAddonIds.length; ++i)
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.enabledAddonIds[i]);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.providerId);
                if (message.uptime != null && Object.hasOwnProperty.call(message, "uptime"))
                    writer.uint32(/* id 16, wireType 0 =*/128).uint64(message.uptime);
                if (message.totalCharge != null && Object.hasOwnProperty.call(message, "totalCharge"))
                    $root.ca2types.Amount.encode(message.totalCharge, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.allowDelete != null && Object.hasOwnProperty.call(message, "allowDelete"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.allowDelete);
                if (message.allowStart != null && Object.hasOwnProperty.call(message, "allowStart"))
                    writer.uint32(/* id 21, wireType 0 =*/168).bool(message.allowStart);
                if (message.allowStop != null && Object.hasOwnProperty.call(message, "allowStop"))
                    writer.uint32(/* id 22, wireType 0 =*/176).bool(message.allowStop);
                if (message.allowRestart != null && Object.hasOwnProperty.call(message, "allowRestart"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.allowRestart);
                if (message.allowUpdateSshKeys != null && Object.hasOwnProperty.call(message, "allowUpdateSshKeys"))
                    writer.uint32(/* id 25, wireType 0 =*/200).bool(message.allowUpdateSshKeys);
                if (message.sshKeyIds != null && message.sshKeyIds.length) {
                    writer.uint32(/* id 26, wireType 2 =*/210).fork();
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        writer.uint32(message.sshKeyIds[i]);
                    writer.ldelim();
                }
                if (message.rootUsername != null && Object.hasOwnProperty.call(message, "rootUsername"))
                    writer.uint32(/* id 27, wireType 2 =*/218).string(message.rootUsername);
                return writer;
            };
    
            /**
             * Encodes the specified Instance message, length delimited. Does not implicitly {@link ca2types.Instance.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Instance
             * @static
             * @param {ca2types.IInstance} message Instance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Instance.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Instance message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Instance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Instance} Instance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Instance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Instance();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.planId = reader.string();
                            break;
                        }
                    case 3: {
                            message.datacenterId = reader.string();
                            break;
                        }
                    case 4: {
                            message.imageId = reader.string();
                            break;
                        }
                    case 26: {
                            if (!(message.sshKeyIds && message.sshKeyIds.length))
                                message.sshKeyIds = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.sshKeyIds.push(reader.uint32());
                            } else
                                message.sshKeyIds.push(reader.uint32());
                            break;
                        }
                    case 13: {
                            message.rootPassword = reader.string();
                            break;
                        }
                    case 27: {
                            message.rootUsername = reader.string();
                            break;
                        }
                    case 6: {
                            message.name = reader.string();
                            break;
                        }
                    case 7: {
                            message.isImportant = reader.bool();
                            break;
                        }
                    case 8: {
                            message.ipv4 = reader.string();
                            break;
                        }
                    case 9: {
                            message.ipv6 = reader.string();
                            break;
                        }
                    case 10: {
                            message.errorDescription = reader.string();
                            break;
                        }
                    case 14: {
                            if (!(message.enabledAddonIds && message.enabledAddonIds.length))
                                message.enabledAddonIds = [];
                            message.enabledAddonIds.push(reader.string());
                            break;
                        }
                    case 15: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 16: {
                            message.uptime = reader.uint64();
                            break;
                        }
                    case 18: {
                            message.totalCharge = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.instanceStatus = reader.int32();
                            break;
                        }
                    case 12: {
                            message.initialSetupScript = reader.string();
                            break;
                        }
                    case 20: {
                            message.allowDelete = reader.bool();
                            break;
                        }
                    case 21: {
                            message.allowStart = reader.bool();
                            break;
                        }
                    case 22: {
                            message.allowStop = reader.bool();
                            break;
                        }
                    case 23: {
                            message.allowRestart = reader.bool();
                            break;
                        }
                    case 25: {
                            message.allowUpdateSshKeys = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Instance message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Instance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Instance} Instance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Instance.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Instance message.
             * @function verify
             * @memberof ca2types.Instance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Instance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.planId != null && message.hasOwnProperty("planId"))
                    if (!$util.isString(message.planId))
                        return "planId: string expected";
                if (message.datacenterId != null && message.hasOwnProperty("datacenterId"))
                    if (!$util.isString(message.datacenterId))
                        return "datacenterId: string expected";
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                if (message.sshKeyIds != null && message.hasOwnProperty("sshKeyIds")) {
                    if (!Array.isArray(message.sshKeyIds))
                        return "sshKeyIds: array expected";
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        if (!$util.isInteger(message.sshKeyIds[i]))
                            return "sshKeyIds: integer[] expected";
                }
                if (message.rootPassword != null && message.hasOwnProperty("rootPassword"))
                    if (!$util.isString(message.rootPassword))
                        return "rootPassword: string expected";
                if (message.rootUsername != null && message.hasOwnProperty("rootUsername"))
                    if (!$util.isString(message.rootUsername))
                        return "rootUsername: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isImportant != null && message.hasOwnProperty("isImportant"))
                    if (typeof message.isImportant !== "boolean")
                        return "isImportant: boolean expected";
                if (message.ipv4 != null && message.hasOwnProperty("ipv4"))
                    if (!$util.isString(message.ipv4))
                        return "ipv4: string expected";
                if (message.ipv6 != null && message.hasOwnProperty("ipv6"))
                    if (!$util.isString(message.ipv6))
                        return "ipv6: string expected";
                if (message.errorDescription != null && message.hasOwnProperty("errorDescription"))
                    if (!$util.isString(message.errorDescription))
                        return "errorDescription: string expected";
                if (message.enabledAddonIds != null && message.hasOwnProperty("enabledAddonIds")) {
                    if (!Array.isArray(message.enabledAddonIds))
                        return "enabledAddonIds: array expected";
                    for (var i = 0; i < message.enabledAddonIds.length; ++i)
                        if (!$util.isString(message.enabledAddonIds[i]))
                            return "enabledAddonIds: string[] expected";
                }
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.uptime != null && message.hasOwnProperty("uptime"))
                    if (!$util.isInteger(message.uptime) && !(message.uptime && $util.isInteger(message.uptime.low) && $util.isInteger(message.uptime.high)))
                        return "uptime: integer|Long expected";
                if (message.totalCharge != null && message.hasOwnProperty("totalCharge")) {
                    var error = $root.ca2types.Amount.verify(message.totalCharge);
                    if (error)
                        return "totalCharge." + error;
                }
                if (message.instanceStatus != null && message.hasOwnProperty("instanceStatus"))
                    switch (message.instanceStatus) {
                    default:
                        return "instanceStatus: enum value expected";
                    case 0:
                    case 6:
                    case 14:
                    case 17:
                    case 8:
                    case 7:
                    case 9:
                    case 5:
                    case 2:
                    case 18:
                    case 10:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.initialSetupScript != null && message.hasOwnProperty("initialSetupScript"))
                    if (!$util.isString(message.initialSetupScript))
                        return "initialSetupScript: string expected";
                if (message.allowDelete != null && message.hasOwnProperty("allowDelete"))
                    if (typeof message.allowDelete !== "boolean")
                        return "allowDelete: boolean expected";
                if (message.allowStart != null && message.hasOwnProperty("allowStart"))
                    if (typeof message.allowStart !== "boolean")
                        return "allowStart: boolean expected";
                if (message.allowStop != null && message.hasOwnProperty("allowStop"))
                    if (typeof message.allowStop !== "boolean")
                        return "allowStop: boolean expected";
                if (message.allowRestart != null && message.hasOwnProperty("allowRestart"))
                    if (typeof message.allowRestart !== "boolean")
                        return "allowRestart: boolean expected";
                if (message.allowUpdateSshKeys != null && message.hasOwnProperty("allowUpdateSshKeys"))
                    if (typeof message.allowUpdateSshKeys !== "boolean")
                        return "allowUpdateSshKeys: boolean expected";
                return null;
            };
    
            /**
             * Creates an Instance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Instance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Instance} Instance
             */
            Instance.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Instance)
                    return object;
                var message = new $root.ca2types.Instance();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.planId != null)
                    message.planId = String(object.planId);
                if (object.datacenterId != null)
                    message.datacenterId = String(object.datacenterId);
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                if (object.sshKeyIds) {
                    if (!Array.isArray(object.sshKeyIds))
                        throw TypeError(".ca2types.Instance.sshKeyIds: array expected");
                    message.sshKeyIds = [];
                    for (var i = 0; i < object.sshKeyIds.length; ++i)
                        message.sshKeyIds[i] = object.sshKeyIds[i] >>> 0;
                }
                if (object.rootPassword != null)
                    message.rootPassword = String(object.rootPassword);
                if (object.rootUsername != null)
                    message.rootUsername = String(object.rootUsername);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isImportant != null)
                    message.isImportant = Boolean(object.isImportant);
                if (object.ipv4 != null)
                    message.ipv4 = String(object.ipv4);
                if (object.ipv6 != null)
                    message.ipv6 = String(object.ipv6);
                if (object.errorDescription != null)
                    message.errorDescription = String(object.errorDescription);
                if (object.enabledAddonIds) {
                    if (!Array.isArray(object.enabledAddonIds))
                        throw TypeError(".ca2types.Instance.enabledAddonIds: array expected");
                    message.enabledAddonIds = [];
                    for (var i = 0; i < object.enabledAddonIds.length; ++i)
                        message.enabledAddonIds[i] = String(object.enabledAddonIds[i]);
                }
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.uptime != null)
                    if ($util.Long)
                        (message.uptime = $util.Long.fromValue(object.uptime)).unsigned = true;
                    else if (typeof object.uptime === "string")
                        message.uptime = parseInt(object.uptime, 10);
                    else if (typeof object.uptime === "number")
                        message.uptime = object.uptime;
                    else if (typeof object.uptime === "object")
                        message.uptime = new $util.LongBits(object.uptime.low >>> 0, object.uptime.high >>> 0).toNumber(true);
                if (object.totalCharge != null) {
                    if (typeof object.totalCharge !== "object")
                        throw TypeError(".ca2types.Instance.totalCharge: object expected");
                    message.totalCharge = $root.ca2types.Amount.fromObject(object.totalCharge);
                }
                switch (object.instanceStatus) {
                default:
                    if (typeof object.instanceStatus === "number") {
                        message.instanceStatus = object.instanceStatus;
                        break;
                    }
                    break;
                case "IS_UNKNOWN":
                case 0:
                    message.instanceStatus = 0;
                    break;
                case "IS_CREATING":
                case 6:
                    message.instanceStatus = 6;
                    break;
                case "IS_DELETING":
                case 14:
                    message.instanceStatus = 14;
                    break;
                case "IS_RESTARTING":
                case 17:
                    message.instanceStatus = 17;
                    break;
                case "IS_STARTING":
                case 8:
                    message.instanceStatus = 8;
                    break;
                case "IS_STOPPING":
                case 7:
                    message.instanceStatus = 7;
                    break;
                case "IS_REINSTALLING":
                case 9:
                    message.instanceStatus = 9;
                    break;
                case "IS_RESIZING":
                case 5:
                    message.instanceStatus = 5;
                    break;
                case "IS_ACTIVE":
                case 2:
                    message.instanceStatus = 2;
                    break;
                case "IS_PROCESSING":
                case 18:
                    message.instanceStatus = 18;
                    break;
                case "IS_STOPPED":
                case 10:
                    message.instanceStatus = 10;
                    break;
                case "IS_DELETED":
                case 15:
                    message.instanceStatus = 15;
                    break;
                case "IS_ERROR":
                case 16:
                    message.instanceStatus = 16;
                    break;
                }
                if (object.initialSetupScript != null)
                    message.initialSetupScript = String(object.initialSetupScript);
                if (object.allowDelete != null)
                    message.allowDelete = Boolean(object.allowDelete);
                if (object.allowStart != null)
                    message.allowStart = Boolean(object.allowStart);
                if (object.allowStop != null)
                    message.allowStop = Boolean(object.allowStop);
                if (object.allowRestart != null)
                    message.allowRestart = Boolean(object.allowRestart);
                if (object.allowUpdateSshKeys != null)
                    message.allowUpdateSshKeys = Boolean(object.allowUpdateSshKeys);
                return message;
            };
    
            /**
             * Creates a plain object from an Instance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Instance
             * @static
             * @param {ca2types.Instance} message Instance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Instance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.enabledAddonIds = [];
                    object.sshKeyIds = [];
                }
                if (options.defaults) {
                    object.id = 0;
                    object.planId = "";
                    object.datacenterId = "";
                    object.imageId = "";
                    object.name = "";
                    object.isImportant = false;
                    object.ipv4 = "";
                    object.ipv6 = "";
                    object.errorDescription = "";
                    object.instanceStatus = options.enums === String ? "IS_UNKNOWN" : 0;
                    object.initialSetupScript = "";
                    object.rootPassword = "";
                    object.providerId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.uptime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.uptime = options.longs === String ? "0" : 0;
                    object.totalCharge = null;
                    object.allowDelete = false;
                    object.allowStart = false;
                    object.allowStop = false;
                    object.allowRestart = false;
                    object.allowUpdateSshKeys = false;
                    object.rootUsername = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.planId != null && message.hasOwnProperty("planId"))
                    object.planId = message.planId;
                if (message.datacenterId != null && message.hasOwnProperty("datacenterId"))
                    object.datacenterId = message.datacenterId;
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isImportant != null && message.hasOwnProperty("isImportant"))
                    object.isImportant = message.isImportant;
                if (message.ipv4 != null && message.hasOwnProperty("ipv4"))
                    object.ipv4 = message.ipv4;
                if (message.ipv6 != null && message.hasOwnProperty("ipv6"))
                    object.ipv6 = message.ipv6;
                if (message.errorDescription != null && message.hasOwnProperty("errorDescription"))
                    object.errorDescription = message.errorDescription;
                if (message.instanceStatus != null && message.hasOwnProperty("instanceStatus"))
                    object.instanceStatus = options.enums === String ? $root.ca2types.InstanceStatus[message.instanceStatus] === undefined ? message.instanceStatus : $root.ca2types.InstanceStatus[message.instanceStatus] : message.instanceStatus;
                if (message.initialSetupScript != null && message.hasOwnProperty("initialSetupScript"))
                    object.initialSetupScript = message.initialSetupScript;
                if (message.rootPassword != null && message.hasOwnProperty("rootPassword"))
                    object.rootPassword = message.rootPassword;
                if (message.enabledAddonIds && message.enabledAddonIds.length) {
                    object.enabledAddonIds = [];
                    for (var j = 0; j < message.enabledAddonIds.length; ++j)
                        object.enabledAddonIds[j] = message.enabledAddonIds[j];
                }
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.uptime != null && message.hasOwnProperty("uptime"))
                    if (typeof message.uptime === "number")
                        object.uptime = options.longs === String ? String(message.uptime) : message.uptime;
                    else
                        object.uptime = options.longs === String ? $util.Long.prototype.toString.call(message.uptime) : options.longs === Number ? new $util.LongBits(message.uptime.low >>> 0, message.uptime.high >>> 0).toNumber(true) : message.uptime;
                if (message.totalCharge != null && message.hasOwnProperty("totalCharge"))
                    object.totalCharge = $root.ca2types.Amount.toObject(message.totalCharge, options);
                if (message.allowDelete != null && message.hasOwnProperty("allowDelete"))
                    object.allowDelete = message.allowDelete;
                if (message.allowStart != null && message.hasOwnProperty("allowStart"))
                    object.allowStart = message.allowStart;
                if (message.allowStop != null && message.hasOwnProperty("allowStop"))
                    object.allowStop = message.allowStop;
                if (message.allowRestart != null && message.hasOwnProperty("allowRestart"))
                    object.allowRestart = message.allowRestart;
                if (message.allowUpdateSshKeys != null && message.hasOwnProperty("allowUpdateSshKeys"))
                    object.allowUpdateSshKeys = message.allowUpdateSshKeys;
                if (message.sshKeyIds && message.sshKeyIds.length) {
                    object.sshKeyIds = [];
                    for (var j = 0; j < message.sshKeyIds.length; ++j)
                        object.sshKeyIds[j] = message.sshKeyIds[j];
                }
                if (message.rootUsername != null && message.hasOwnProperty("rootUsername"))
                    object.rootUsername = message.rootUsername;
                return object;
            };
    
            /**
             * Converts this Instance to JSON.
             * @function toJSON
             * @memberof ca2types.Instance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Instance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Instance
             * @function getTypeUrl
             * @memberof ca2types.Instance
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Instance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Instance";
            };
    
            return Instance;
        })();
    
        /**
         * InstanceChangeSource enum.
         * @name ca2types.InstanceChangeSource
         * @enum {number}
         * @property {number} ICS_UNKNOWN=0 ICS_UNKNOWN value
         * @property {number} ICS_USER=1 ICS_USER value
         * @property {number} ICS_PROVIDER=2 ICS_PROVIDER value
         */
        ca2types.InstanceChangeSource = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ICS_UNKNOWN"] = 0;
            values[valuesById[1] = "ICS_USER"] = 1;
            values[valuesById[2] = "ICS_PROVIDER"] = 2;
            return values;
        })();
    
        ca2types.InstanceChange = (function() {
    
            /**
             * Properties of an InstanceChange.
             * @memberof ca2types
             * @interface IInstanceChange
             * @property {number|null} [instanceId] InstanceChange instanceId
             * @property {Long|null} [createdAt] InstanceChange createdAt
             * @property {ca2types.InstanceChangeSource|null} [source] InstanceChange source
             * @property {ca2types.InstanceStatus|null} [instanceStatus] InstanceChange instanceStatus
             * @property {string|null} [ipv4] InstanceChange ipv4
             * @property {string|null} [ipv6] InstanceChange ipv6
             * @property {string|null} [name] InstanceChange name
             * @property {boolean|null} [isImportant] InstanceChange isImportant
             */
    
            /**
             * Constructs a new InstanceChange.
             * @memberof ca2types
             * @classdesc Represents an InstanceChange.
             * @implements IInstanceChange
             * @constructor
             * @param {ca2types.IInstanceChange=} [properties] Properties to set
             */
            function InstanceChange(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * InstanceChange instanceId.
             * @member {number} instanceId
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.instanceId = 0;
    
            /**
             * InstanceChange createdAt.
             * @member {Long} createdAt
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * InstanceChange source.
             * @member {ca2types.InstanceChangeSource} source
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.source = 0;
    
            /**
             * InstanceChange instanceStatus.
             * @member {ca2types.InstanceStatus} instanceStatus
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.instanceStatus = 0;
    
            /**
             * InstanceChange ipv4.
             * @member {string} ipv4
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.ipv4 = "";
    
            /**
             * InstanceChange ipv6.
             * @member {string} ipv6
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.ipv6 = "";
    
            /**
             * InstanceChange name.
             * @member {string} name
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.name = "";
    
            /**
             * InstanceChange isImportant.
             * @member {boolean} isImportant
             * @memberof ca2types.InstanceChange
             * @instance
             */
            InstanceChange.prototype.isImportant = false;
    
            /**
             * Creates a new InstanceChange instance using the specified properties.
             * @function create
             * @memberof ca2types.InstanceChange
             * @static
             * @param {ca2types.IInstanceChange=} [properties] Properties to set
             * @returns {ca2types.InstanceChange} InstanceChange instance
             */
            InstanceChange.create = function create(properties) {
                return new InstanceChange(properties);
            };
    
            /**
             * Encodes the specified InstanceChange message. Does not implicitly {@link ca2types.InstanceChange.verify|verify} messages.
             * @function encode
             * @memberof ca2types.InstanceChange
             * @static
             * @param {ca2types.IInstanceChange} message InstanceChange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceChange.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.instanceStatus != null && Object.hasOwnProperty.call(message, "instanceStatus"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.instanceStatus);
                if (message.ipv4 != null && Object.hasOwnProperty.call(message, "ipv4"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.ipv4);
                if (message.ipv6 != null && Object.hasOwnProperty.call(message, "ipv6"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.ipv6);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
                if (message.isImportant != null && Object.hasOwnProperty.call(message, "isImportant"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isImportant);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.source);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.createdAt);
                return writer;
            };
    
            /**
             * Encodes the specified InstanceChange message, length delimited. Does not implicitly {@link ca2types.InstanceChange.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.InstanceChange
             * @static
             * @param {ca2types.IInstanceChange} message InstanceChange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceChange.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an InstanceChange message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.InstanceChange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.InstanceChange} InstanceChange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceChange.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.InstanceChange();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 11: {
                            message.createdAt = reader.uint64();
                            break;
                        }
                    case 10: {
                            message.source = reader.int32();
                            break;
                        }
                    case 2: {
                            message.instanceStatus = reader.int32();
                            break;
                        }
                    case 5: {
                            message.ipv4 = reader.string();
                            break;
                        }
                    case 6: {
                            message.ipv6 = reader.string();
                            break;
                        }
                    case 7: {
                            message.name = reader.string();
                            break;
                        }
                    case 8: {
                            message.isImportant = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an InstanceChange message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.InstanceChange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.InstanceChange} InstanceChange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceChange.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an InstanceChange message.
             * @function verify
             * @memberof ca2types.InstanceChange
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InstanceChange.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.instanceStatus != null && message.hasOwnProperty("instanceStatus"))
                    switch (message.instanceStatus) {
                    default:
                        return "instanceStatus: enum value expected";
                    case 0:
                    case 6:
                    case 14:
                    case 17:
                    case 8:
                    case 7:
                    case 9:
                    case 5:
                    case 2:
                    case 18:
                    case 10:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.ipv4 != null && message.hasOwnProperty("ipv4"))
                    if (!$util.isString(message.ipv4))
                        return "ipv4: string expected";
                if (message.ipv6 != null && message.hasOwnProperty("ipv6"))
                    if (!$util.isString(message.ipv6))
                        return "ipv6: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isImportant != null && message.hasOwnProperty("isImportant"))
                    if (typeof message.isImportant !== "boolean")
                        return "isImportant: boolean expected";
                return null;
            };
    
            /**
             * Creates an InstanceChange message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.InstanceChange
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.InstanceChange} InstanceChange
             */
            InstanceChange.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.InstanceChange)
                    return object;
                var message = new $root.ca2types.InstanceChange();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                switch (object.source) {
                default:
                    if (typeof object.source === "number") {
                        message.source = object.source;
                        break;
                    }
                    break;
                case "ICS_UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "ICS_USER":
                case 1:
                    message.source = 1;
                    break;
                case "ICS_PROVIDER":
                case 2:
                    message.source = 2;
                    break;
                }
                switch (object.instanceStatus) {
                default:
                    if (typeof object.instanceStatus === "number") {
                        message.instanceStatus = object.instanceStatus;
                        break;
                    }
                    break;
                case "IS_UNKNOWN":
                case 0:
                    message.instanceStatus = 0;
                    break;
                case "IS_CREATING":
                case 6:
                    message.instanceStatus = 6;
                    break;
                case "IS_DELETING":
                case 14:
                    message.instanceStatus = 14;
                    break;
                case "IS_RESTARTING":
                case 17:
                    message.instanceStatus = 17;
                    break;
                case "IS_STARTING":
                case 8:
                    message.instanceStatus = 8;
                    break;
                case "IS_STOPPING":
                case 7:
                    message.instanceStatus = 7;
                    break;
                case "IS_REINSTALLING":
                case 9:
                    message.instanceStatus = 9;
                    break;
                case "IS_RESIZING":
                case 5:
                    message.instanceStatus = 5;
                    break;
                case "IS_ACTIVE":
                case 2:
                    message.instanceStatus = 2;
                    break;
                case "IS_PROCESSING":
                case 18:
                    message.instanceStatus = 18;
                    break;
                case "IS_STOPPED":
                case 10:
                    message.instanceStatus = 10;
                    break;
                case "IS_DELETED":
                case 15:
                    message.instanceStatus = 15;
                    break;
                case "IS_ERROR":
                case 16:
                    message.instanceStatus = 16;
                    break;
                }
                if (object.ipv4 != null)
                    message.ipv4 = String(object.ipv4);
                if (object.ipv6 != null)
                    message.ipv6 = String(object.ipv6);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isImportant != null)
                    message.isImportant = Boolean(object.isImportant);
                return message;
            };
    
            /**
             * Creates a plain object from an InstanceChange message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.InstanceChange
             * @static
             * @param {ca2types.InstanceChange} message InstanceChange
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InstanceChange.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.instanceId = 0;
                    object.instanceStatus = options.enums === String ? "IS_UNKNOWN" : 0;
                    object.ipv4 = "";
                    object.ipv6 = "";
                    object.name = "";
                    object.isImportant = false;
                    object.source = options.enums === String ? "ICS_UNKNOWN" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.instanceStatus != null && message.hasOwnProperty("instanceStatus"))
                    object.instanceStatus = options.enums === String ? $root.ca2types.InstanceStatus[message.instanceStatus] === undefined ? message.instanceStatus : $root.ca2types.InstanceStatus[message.instanceStatus] : message.instanceStatus;
                if (message.ipv4 != null && message.hasOwnProperty("ipv4"))
                    object.ipv4 = message.ipv4;
                if (message.ipv6 != null && message.hasOwnProperty("ipv6"))
                    object.ipv6 = message.ipv6;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isImportant != null && message.hasOwnProperty("isImportant"))
                    object.isImportant = message.isImportant;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.ca2types.InstanceChangeSource[message.source] === undefined ? message.source : $root.ca2types.InstanceChangeSource[message.source] : message.source;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                return object;
            };
    
            /**
             * Converts this InstanceChange to JSON.
             * @function toJSON
             * @memberof ca2types.InstanceChange
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InstanceChange.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for InstanceChange
             * @function getTypeUrl
             * @memberof ca2types.InstanceChange
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InstanceChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.InstanceChange";
            };
    
            return InstanceChange;
        })();
    
        /**
         * PaymentSystem enum.
         * @name ca2types.PaymentSystem
         * @enum {number}
         * @property {number} PS_UNKNOWN=0 PS_UNKNOWN value
         * @property {number} PS_COINS_PAID=1 PS_COINS_PAID value
         * @property {number} PS_FAKE=99 PS_FAKE value
         */
        ca2types.PaymentSystem = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PS_UNKNOWN"] = 0;
            values[valuesById[1] = "PS_COINS_PAID"] = 1;
            values[valuesById[99] = "PS_FAKE"] = 99;
            return values;
        })();
    
        ca2types.Currency = (function() {
    
            /**
             * Properties of a Currency.
             * @memberof ca2types
             * @interface ICurrency
             * @property {string|null} [code] Currency code
             * @property {string|null} [title] Currency title
             * @property {ca2types.IAmount|null} [rate] Currency rate
             * @property {ca2types.IAmount|null} [minAmount] Currency minAmount
             * @property {number|null} [places] Currency places
             */
    
            /**
             * Constructs a new Currency.
             * @memberof ca2types
             * @classdesc Represents a Currency.
             * @implements ICurrency
             * @constructor
             * @param {ca2types.ICurrency=} [properties] Properties to set
             */
            function Currency(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Currency code.
             * @member {string} code
             * @memberof ca2types.Currency
             * @instance
             */
            Currency.prototype.code = "";
    
            /**
             * Currency title.
             * @member {string} title
             * @memberof ca2types.Currency
             * @instance
             */
            Currency.prototype.title = "";
    
            /**
             * Currency rate.
             * @member {ca2types.IAmount|null|undefined} rate
             * @memberof ca2types.Currency
             * @instance
             */
            Currency.prototype.rate = null;
    
            /**
             * Currency minAmount.
             * @member {ca2types.IAmount|null|undefined} minAmount
             * @memberof ca2types.Currency
             * @instance
             */
            Currency.prototype.minAmount = null;
    
            /**
             * Currency places.
             * @member {number} places
             * @memberof ca2types.Currency
             * @instance
             */
            Currency.prototype.places = 0;
    
            /**
             * Creates a new Currency instance using the specified properties.
             * @function create
             * @memberof ca2types.Currency
             * @static
             * @param {ca2types.ICurrency=} [properties] Properties to set
             * @returns {ca2types.Currency} Currency instance
             */
            Currency.create = function create(properties) {
                return new Currency(properties);
            };
    
            /**
             * Encodes the specified Currency message. Does not implicitly {@link ca2types.Currency.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Currency
             * @static
             * @param {ca2types.ICurrency} message Currency message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Currency.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                if (message.places != null && Object.hasOwnProperty.call(message, "places"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.places);
                if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                    $root.ca2types.Amount.encode(message.rate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.minAmount != null && Object.hasOwnProperty.call(message, "minAmount"))
                    $root.ca2types.Amount.encode(message.minAmount, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Currency message, length delimited. Does not implicitly {@link ca2types.Currency.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Currency
             * @static
             * @param {ca2types.ICurrency} message Currency message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Currency.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Currency message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Currency
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Currency} Currency
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Currency.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Currency();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.title = reader.string();
                            break;
                        }
                    case 6: {
                            message.rate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.minAmount = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.places = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Currency message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Currency
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Currency} Currency
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Currency.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Currency message.
             * @function verify
             * @memberof ca2types.Currency
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Currency.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                if (message.rate != null && message.hasOwnProperty("rate")) {
                    var error = $root.ca2types.Amount.verify(message.rate);
                    if (error)
                        return "rate." + error;
                }
                if (message.minAmount != null && message.hasOwnProperty("minAmount")) {
                    var error = $root.ca2types.Amount.verify(message.minAmount);
                    if (error)
                        return "minAmount." + error;
                }
                if (message.places != null && message.hasOwnProperty("places"))
                    if (!$util.isInteger(message.places))
                        return "places: integer expected";
                return null;
            };
    
            /**
             * Creates a Currency message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Currency
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Currency} Currency
             */
            Currency.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Currency)
                    return object;
                var message = new $root.ca2types.Currency();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.title != null)
                    message.title = String(object.title);
                if (object.rate != null) {
                    if (typeof object.rate !== "object")
                        throw TypeError(".ca2types.Currency.rate: object expected");
                    message.rate = $root.ca2types.Amount.fromObject(object.rate);
                }
                if (object.minAmount != null) {
                    if (typeof object.minAmount !== "object")
                        throw TypeError(".ca2types.Currency.minAmount: object expected");
                    message.minAmount = $root.ca2types.Amount.fromObject(object.minAmount);
                }
                if (object.places != null)
                    message.places = object.places >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a Currency message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Currency
             * @static
             * @param {ca2types.Currency} message Currency
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Currency.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.title = "";
                    object.places = 0;
                    object.rate = null;
                    object.minAmount = null;
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                if (message.places != null && message.hasOwnProperty("places"))
                    object.places = message.places;
                if (message.rate != null && message.hasOwnProperty("rate"))
                    object.rate = $root.ca2types.Amount.toObject(message.rate, options);
                if (message.minAmount != null && message.hasOwnProperty("minAmount"))
                    object.minAmount = $root.ca2types.Amount.toObject(message.minAmount, options);
                return object;
            };
    
            /**
             * Converts this Currency to JSON.
             * @function toJSON
             * @memberof ca2types.Currency
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Currency.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Currency
             * @function getTypeUrl
             * @memberof ca2types.Currency
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Currency.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Currency";
            };
    
            return Currency;
        })();
    
        ca2types.TLD = (function() {
    
            /**
             * Properties of a TLD.
             * @memberof ca2types
             * @interface ITLD
             * @property {string|null} [name] TLD name
             * @property {ca2types.IAmount|null} [minRegistrationPrice] TLD minRegistrationPrice
             * @property {ca2types.IAmount|null} [minRenewalPrice] TLD minRenewalPrice
             */
    
            /**
             * Constructs a new TLD.
             * @memberof ca2types
             * @classdesc Represents a TLD.
             * @implements ITLD
             * @constructor
             * @param {ca2types.ITLD=} [properties] Properties to set
             */
            function TLD(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TLD name.
             * @member {string} name
             * @memberof ca2types.TLD
             * @instance
             */
            TLD.prototype.name = "";
    
            /**
             * TLD minRegistrationPrice.
             * @member {ca2types.IAmount|null|undefined} minRegistrationPrice
             * @memberof ca2types.TLD
             * @instance
             */
            TLD.prototype.minRegistrationPrice = null;
    
            /**
             * TLD minRenewalPrice.
             * @member {ca2types.IAmount|null|undefined} minRenewalPrice
             * @memberof ca2types.TLD
             * @instance
             */
            TLD.prototype.minRenewalPrice = null;
    
            /**
             * Creates a new TLD instance using the specified properties.
             * @function create
             * @memberof ca2types.TLD
             * @static
             * @param {ca2types.ITLD=} [properties] Properties to set
             * @returns {ca2types.TLD} TLD instance
             */
            TLD.create = function create(properties) {
                return new TLD(properties);
            };
    
            /**
             * Encodes the specified TLD message. Does not implicitly {@link ca2types.TLD.verify|verify} messages.
             * @function encode
             * @memberof ca2types.TLD
             * @static
             * @param {ca2types.ITLD} message TLD message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLD.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.minRegistrationPrice != null && Object.hasOwnProperty.call(message, "minRegistrationPrice"))
                    $root.ca2types.Amount.encode(message.minRegistrationPrice, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.minRenewalPrice != null && Object.hasOwnProperty.call(message, "minRenewalPrice"))
                    $root.ca2types.Amount.encode(message.minRenewalPrice, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TLD message, length delimited. Does not implicitly {@link ca2types.TLD.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.TLD
             * @static
             * @param {ca2types.ITLD} message TLD message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLD.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TLD message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.TLD
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.TLD} TLD
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLD.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.TLD();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.minRegistrationPrice = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.minRenewalPrice = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TLD message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.TLD
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.TLD} TLD
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLD.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TLD message.
             * @function verify
             * @memberof ca2types.TLD
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TLD.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.minRegistrationPrice != null && message.hasOwnProperty("minRegistrationPrice")) {
                    var error = $root.ca2types.Amount.verify(message.minRegistrationPrice);
                    if (error)
                        return "minRegistrationPrice." + error;
                }
                if (message.minRenewalPrice != null && message.hasOwnProperty("minRenewalPrice")) {
                    var error = $root.ca2types.Amount.verify(message.minRenewalPrice);
                    if (error)
                        return "minRenewalPrice." + error;
                }
                return null;
            };
    
            /**
             * Creates a TLD message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.TLD
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.TLD} TLD
             */
            TLD.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.TLD)
                    return object;
                var message = new $root.ca2types.TLD();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.minRegistrationPrice != null) {
                    if (typeof object.minRegistrationPrice !== "object")
                        throw TypeError(".ca2types.TLD.minRegistrationPrice: object expected");
                    message.minRegistrationPrice = $root.ca2types.Amount.fromObject(object.minRegistrationPrice);
                }
                if (object.minRenewalPrice != null) {
                    if (typeof object.minRenewalPrice !== "object")
                        throw TypeError(".ca2types.TLD.minRenewalPrice: object expected");
                    message.minRenewalPrice = $root.ca2types.Amount.fromObject(object.minRenewalPrice);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TLD message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.TLD
             * @static
             * @param {ca2types.TLD} message TLD
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TLD.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.minRegistrationPrice = null;
                    object.minRenewalPrice = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.minRegistrationPrice != null && message.hasOwnProperty("minRegistrationPrice"))
                    object.minRegistrationPrice = $root.ca2types.Amount.toObject(message.minRegistrationPrice, options);
                if (message.minRenewalPrice != null && message.hasOwnProperty("minRenewalPrice"))
                    object.minRenewalPrice = $root.ca2types.Amount.toObject(message.minRenewalPrice, options);
                return object;
            };
    
            /**
             * Converts this TLD to JSON.
             * @function toJSON
             * @memberof ca2types.TLD
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TLD.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TLD
             * @function getTypeUrl
             * @memberof ca2types.TLD
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TLD.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.TLD";
            };
    
            return TLD;
        })();
    
        ca2types.TLDGroup = (function() {
    
            /**
             * Properties of a TLDGroup.
             * @memberof ca2types
             * @interface ITLDGroup
             * @property {string|null} [id] TLDGroup id
             * @property {string|null} [title] TLDGroup title
             * @property {Array.<string>|null} [tldsNames] TLDGroup tldsNames
             */
    
            /**
             * Constructs a new TLDGroup.
             * @memberof ca2types
             * @classdesc Represents a TLDGroup.
             * @implements ITLDGroup
             * @constructor
             * @param {ca2types.ITLDGroup=} [properties] Properties to set
             */
            function TLDGroup(properties) {
                this.tldsNames = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TLDGroup id.
             * @member {string} id
             * @memberof ca2types.TLDGroup
             * @instance
             */
            TLDGroup.prototype.id = "";
    
            /**
             * TLDGroup title.
             * @member {string} title
             * @memberof ca2types.TLDGroup
             * @instance
             */
            TLDGroup.prototype.title = "";
    
            /**
             * TLDGroup tldsNames.
             * @member {Array.<string>} tldsNames
             * @memberof ca2types.TLDGroup
             * @instance
             */
            TLDGroup.prototype.tldsNames = $util.emptyArray;
    
            /**
             * Creates a new TLDGroup instance using the specified properties.
             * @function create
             * @memberof ca2types.TLDGroup
             * @static
             * @param {ca2types.ITLDGroup=} [properties] Properties to set
             * @returns {ca2types.TLDGroup} TLDGroup instance
             */
            TLDGroup.create = function create(properties) {
                return new TLDGroup(properties);
            };
    
            /**
             * Encodes the specified TLDGroup message. Does not implicitly {@link ca2types.TLDGroup.verify|verify} messages.
             * @function encode
             * @memberof ca2types.TLDGroup
             * @static
             * @param {ca2types.ITLDGroup} message TLDGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLDGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                if (message.tldsNames != null && message.tldsNames.length)
                    for (var i = 0; i < message.tldsNames.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.tldsNames[i]);
                return writer;
            };
    
            /**
             * Encodes the specified TLDGroup message, length delimited. Does not implicitly {@link ca2types.TLDGroup.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.TLDGroup
             * @static
             * @param {ca2types.ITLDGroup} message TLDGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLDGroup.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TLDGroup message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.TLDGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.TLDGroup} TLDGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLDGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.TLDGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.title = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.tldsNames && message.tldsNames.length))
                                message.tldsNames = [];
                            message.tldsNames.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TLDGroup message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.TLDGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.TLDGroup} TLDGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLDGroup.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TLDGroup message.
             * @function verify
             * @memberof ca2types.TLDGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TLDGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                if (message.tldsNames != null && message.hasOwnProperty("tldsNames")) {
                    if (!Array.isArray(message.tldsNames))
                        return "tldsNames: array expected";
                    for (var i = 0; i < message.tldsNames.length; ++i)
                        if (!$util.isString(message.tldsNames[i]))
                            return "tldsNames: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a TLDGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.TLDGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.TLDGroup} TLDGroup
             */
            TLDGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.TLDGroup)
                    return object;
                var message = new $root.ca2types.TLDGroup();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.title != null)
                    message.title = String(object.title);
                if (object.tldsNames) {
                    if (!Array.isArray(object.tldsNames))
                        throw TypeError(".ca2types.TLDGroup.tldsNames: array expected");
                    message.tldsNames = [];
                    for (var i = 0; i < object.tldsNames.length; ++i)
                        message.tldsNames[i] = String(object.tldsNames[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TLDGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.TLDGroup
             * @static
             * @param {ca2types.TLDGroup} message TLDGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TLDGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tldsNames = [];
                if (options.defaults) {
                    object.id = "";
                    object.title = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                if (message.tldsNames && message.tldsNames.length) {
                    object.tldsNames = [];
                    for (var j = 0; j < message.tldsNames.length; ++j)
                        object.tldsNames[j] = message.tldsNames[j];
                }
                return object;
            };
    
            /**
             * Converts this TLDGroup to JSON.
             * @function toJSON
             * @memberof ca2types.TLDGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TLDGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TLDGroup
             * @function getTypeUrl
             * @memberof ca2types.TLDGroup
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TLDGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.TLDGroup";
            };
    
            return TLDGroup;
        })();
    
        ca2types.DomainSuggestion = (function() {
    
            /**
             * Properties of a DomainSuggestion.
             * @memberof ca2types
             * @interface IDomainSuggestion
             * @property {string|null} [id] DomainSuggestion id
             * @property {string|null} [name] DomainSuggestion name
             * @property {boolean|null} [isReady] DomainSuggestion isReady
             * @property {boolean|null} [isAvailable] DomainSuggestion isAvailable
             * @property {boolean|null} [isPremium] DomainSuggestion isPremium
             * @property {boolean|null} [isCheckError] DomainSuggestion isCheckError
             * @property {ca2types.IAmount|null} [oldRegistrationPrice] DomainSuggestion oldRegistrationPrice
             * @property {ca2types.IAmount|null} [registrationPrice] DomainSuggestion registrationPrice
             * @property {ca2types.IAmount|null} [renewalPrice] DomainSuggestion renewalPrice
             * @property {string|null} [whoisUrl] DomainSuggestion whoisUrl
             * @property {string|null} [siteUrl] DomainSuggestion siteUrl
             */
    
            /**
             * Constructs a new DomainSuggestion.
             * @memberof ca2types
             * @classdesc Represents a DomainSuggestion.
             * @implements IDomainSuggestion
             * @constructor
             * @param {ca2types.IDomainSuggestion=} [properties] Properties to set
             */
            function DomainSuggestion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DomainSuggestion id.
             * @member {string} id
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.id = "";
    
            /**
             * DomainSuggestion name.
             * @member {string} name
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.name = "";
    
            /**
             * DomainSuggestion isReady.
             * @member {boolean} isReady
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.isReady = false;
    
            /**
             * DomainSuggestion isAvailable.
             * @member {boolean} isAvailable
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.isAvailable = false;
    
            /**
             * DomainSuggestion isPremium.
             * @member {boolean} isPremium
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.isPremium = false;
    
            /**
             * DomainSuggestion isCheckError.
             * @member {boolean} isCheckError
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.isCheckError = false;
    
            /**
             * DomainSuggestion oldRegistrationPrice.
             * @member {ca2types.IAmount|null|undefined} oldRegistrationPrice
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.oldRegistrationPrice = null;
    
            /**
             * DomainSuggestion registrationPrice.
             * @member {ca2types.IAmount|null|undefined} registrationPrice
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.registrationPrice = null;
    
            /**
             * DomainSuggestion renewalPrice.
             * @member {ca2types.IAmount|null|undefined} renewalPrice
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.renewalPrice = null;
    
            /**
             * DomainSuggestion whoisUrl.
             * @member {string} whoisUrl
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.whoisUrl = "";
    
            /**
             * DomainSuggestion siteUrl.
             * @member {string} siteUrl
             * @memberof ca2types.DomainSuggestion
             * @instance
             */
            DomainSuggestion.prototype.siteUrl = "";
    
            /**
             * Creates a new DomainSuggestion instance using the specified properties.
             * @function create
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {ca2types.IDomainSuggestion=} [properties] Properties to set
             * @returns {ca2types.DomainSuggestion} DomainSuggestion instance
             */
            DomainSuggestion.create = function create(properties) {
                return new DomainSuggestion(properties);
            };
    
            /**
             * Encodes the specified DomainSuggestion message. Does not implicitly {@link ca2types.DomainSuggestion.verify|verify} messages.
             * @function encode
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {ca2types.IDomainSuggestion} message DomainSuggestion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainSuggestion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.isReady != null && Object.hasOwnProperty.call(message, "isReady"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isReady);
                if (message.isAvailable != null && Object.hasOwnProperty.call(message, "isAvailable"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAvailable);
                if (message.isPremium != null && Object.hasOwnProperty.call(message, "isPremium"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isPremium);
                if (message.oldRegistrationPrice != null && Object.hasOwnProperty.call(message, "oldRegistrationPrice"))
                    $root.ca2types.Amount.encode(message.oldRegistrationPrice, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.registrationPrice != null && Object.hasOwnProperty.call(message, "registrationPrice"))
                    $root.ca2types.Amount.encode(message.registrationPrice, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.renewalPrice != null && Object.hasOwnProperty.call(message, "renewalPrice"))
                    $root.ca2types.Amount.encode(message.renewalPrice, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.whoisUrl != null && Object.hasOwnProperty.call(message, "whoisUrl"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.whoisUrl);
                if (message.siteUrl != null && Object.hasOwnProperty.call(message, "siteUrl"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.siteUrl);
                if (message.isCheckError != null && Object.hasOwnProperty.call(message, "isCheckError"))
                    writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isCheckError);
                return writer;
            };
    
            /**
             * Encodes the specified DomainSuggestion message, length delimited. Does not implicitly {@link ca2types.DomainSuggestion.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {ca2types.IDomainSuggestion} message DomainSuggestion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainSuggestion.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DomainSuggestion message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.DomainSuggestion} DomainSuggestion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainSuggestion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.DomainSuggestion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.isReady = reader.bool();
                            break;
                        }
                    case 4: {
                            message.isAvailable = reader.bool();
                            break;
                        }
                    case 5: {
                            message.isPremium = reader.bool();
                            break;
                        }
                    case 11: {
                            message.isCheckError = reader.bool();
                            break;
                        }
                    case 6: {
                            message.oldRegistrationPrice = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.registrationPrice = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.renewalPrice = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.whoisUrl = reader.string();
                            break;
                        }
                    case 10: {
                            message.siteUrl = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DomainSuggestion message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.DomainSuggestion} DomainSuggestion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainSuggestion.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DomainSuggestion message.
             * @function verify
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainSuggestion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isReady != null && message.hasOwnProperty("isReady"))
                    if (typeof message.isReady !== "boolean")
                        return "isReady: boolean expected";
                if (message.isAvailable != null && message.hasOwnProperty("isAvailable"))
                    if (typeof message.isAvailable !== "boolean")
                        return "isAvailable: boolean expected";
                if (message.isPremium != null && message.hasOwnProperty("isPremium"))
                    if (typeof message.isPremium !== "boolean")
                        return "isPremium: boolean expected";
                if (message.isCheckError != null && message.hasOwnProperty("isCheckError"))
                    if (typeof message.isCheckError !== "boolean")
                        return "isCheckError: boolean expected";
                if (message.oldRegistrationPrice != null && message.hasOwnProperty("oldRegistrationPrice")) {
                    var error = $root.ca2types.Amount.verify(message.oldRegistrationPrice);
                    if (error)
                        return "oldRegistrationPrice." + error;
                }
                if (message.registrationPrice != null && message.hasOwnProperty("registrationPrice")) {
                    var error = $root.ca2types.Amount.verify(message.registrationPrice);
                    if (error)
                        return "registrationPrice." + error;
                }
                if (message.renewalPrice != null && message.hasOwnProperty("renewalPrice")) {
                    var error = $root.ca2types.Amount.verify(message.renewalPrice);
                    if (error)
                        return "renewalPrice." + error;
                }
                if (message.whoisUrl != null && message.hasOwnProperty("whoisUrl"))
                    if (!$util.isString(message.whoisUrl))
                        return "whoisUrl: string expected";
                if (message.siteUrl != null && message.hasOwnProperty("siteUrl"))
                    if (!$util.isString(message.siteUrl))
                        return "siteUrl: string expected";
                return null;
            };
    
            /**
             * Creates a DomainSuggestion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.DomainSuggestion} DomainSuggestion
             */
            DomainSuggestion.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.DomainSuggestion)
                    return object;
                var message = new $root.ca2types.DomainSuggestion();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isReady != null)
                    message.isReady = Boolean(object.isReady);
                if (object.isAvailable != null)
                    message.isAvailable = Boolean(object.isAvailable);
                if (object.isPremium != null)
                    message.isPremium = Boolean(object.isPremium);
                if (object.isCheckError != null)
                    message.isCheckError = Boolean(object.isCheckError);
                if (object.oldRegistrationPrice != null) {
                    if (typeof object.oldRegistrationPrice !== "object")
                        throw TypeError(".ca2types.DomainSuggestion.oldRegistrationPrice: object expected");
                    message.oldRegistrationPrice = $root.ca2types.Amount.fromObject(object.oldRegistrationPrice);
                }
                if (object.registrationPrice != null) {
                    if (typeof object.registrationPrice !== "object")
                        throw TypeError(".ca2types.DomainSuggestion.registrationPrice: object expected");
                    message.registrationPrice = $root.ca2types.Amount.fromObject(object.registrationPrice);
                }
                if (object.renewalPrice != null) {
                    if (typeof object.renewalPrice !== "object")
                        throw TypeError(".ca2types.DomainSuggestion.renewalPrice: object expected");
                    message.renewalPrice = $root.ca2types.Amount.fromObject(object.renewalPrice);
                }
                if (object.whoisUrl != null)
                    message.whoisUrl = String(object.whoisUrl);
                if (object.siteUrl != null)
                    message.siteUrl = String(object.siteUrl);
                return message;
            };
    
            /**
             * Creates a plain object from a DomainSuggestion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {ca2types.DomainSuggestion} message DomainSuggestion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainSuggestion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.name = "";
                    object.isReady = false;
                    object.isAvailable = false;
                    object.isPremium = false;
                    object.oldRegistrationPrice = null;
                    object.registrationPrice = null;
                    object.renewalPrice = null;
                    object.whoisUrl = "";
                    object.siteUrl = "";
                    object.isCheckError = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isReady != null && message.hasOwnProperty("isReady"))
                    object.isReady = message.isReady;
                if (message.isAvailable != null && message.hasOwnProperty("isAvailable"))
                    object.isAvailable = message.isAvailable;
                if (message.isPremium != null && message.hasOwnProperty("isPremium"))
                    object.isPremium = message.isPremium;
                if (message.oldRegistrationPrice != null && message.hasOwnProperty("oldRegistrationPrice"))
                    object.oldRegistrationPrice = $root.ca2types.Amount.toObject(message.oldRegistrationPrice, options);
                if (message.registrationPrice != null && message.hasOwnProperty("registrationPrice"))
                    object.registrationPrice = $root.ca2types.Amount.toObject(message.registrationPrice, options);
                if (message.renewalPrice != null && message.hasOwnProperty("renewalPrice"))
                    object.renewalPrice = $root.ca2types.Amount.toObject(message.renewalPrice, options);
                if (message.whoisUrl != null && message.hasOwnProperty("whoisUrl"))
                    object.whoisUrl = message.whoisUrl;
                if (message.siteUrl != null && message.hasOwnProperty("siteUrl"))
                    object.siteUrl = message.siteUrl;
                if (message.isCheckError != null && message.hasOwnProperty("isCheckError"))
                    object.isCheckError = message.isCheckError;
                return object;
            };
    
            /**
             * Converts this DomainSuggestion to JSON.
             * @function toJSON
             * @memberof ca2types.DomainSuggestion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainSuggestion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DomainSuggestion
             * @function getTypeUrl
             * @memberof ca2types.DomainSuggestion
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainSuggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.DomainSuggestion";
            };
    
            return DomainSuggestion;
        })();
    
        /**
         * DomainSeller enum.
         * @name ca2types.DomainSeller
         * @enum {number}
         * @property {number} DS_UNKNOWN=0 DS_UNKNOWN value
         * @property {number} DS_NAMECHEAP=1 DS_NAMECHEAP value
         * @property {number} DS_NAMESILO=2 DS_NAMESILO value
         */
        ca2types.DomainSeller = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DS_UNKNOWN"] = 0;
            values[valuesById[1] = "DS_NAMECHEAP"] = 1;
            values[valuesById[2] = "DS_NAMESILO"] = 2;
            return values;
        })();
    
        /**
         * DomainPriceType enum.
         * @name ca2types.DomainPriceType
         * @enum {number}
         * @property {number} DPT_UNKNOWN=0 DPT_UNKNOWN value
         * @property {number} DPT_REGISTRATION=1 DPT_REGISTRATION value
         * @property {number} DPT_RENEWAL=2 DPT_RENEWAL value
         */
        ca2types.DomainPriceType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DPT_UNKNOWN"] = 0;
            values[valuesById[1] = "DPT_REGISTRATION"] = 1;
            values[valuesById[2] = "DPT_RENEWAL"] = 2;
            return values;
        })();
    
        /**
         * DomainStatus enum.
         * @name ca2types.DomainStatus
         * @enum {number}
         * @property {number} DST_UNKNOWN=0 DST_UNKNOWN value
         * @property {number} DST_PROCESSING=1 DST_PROCESSING value
         * @property {number} DST_ERROR=2 DST_ERROR value
         * @property {number} DST_ACTIVE=3 DST_ACTIVE value
         * @property {number} DST_DELETED=4 DST_DELETED value
         */
        ca2types.DomainStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DST_UNKNOWN"] = 0;
            values[valuesById[1] = "DST_PROCESSING"] = 1;
            values[valuesById[2] = "DST_ERROR"] = 2;
            values[valuesById[3] = "DST_ACTIVE"] = 3;
            values[valuesById[4] = "DST_DELETED"] = 4;
            return values;
        })();
    
        ca2types.Domain = (function() {
    
            /**
             * Properties of a Domain.
             * @memberof ca2types
             * @interface IDomain
             * @property {Long|null} [id] Domain id
             * @property {string|null} [name] Domain name
             * @property {ca2types.DomainSeller|null} [seller] Domain seller
             * @property {number|null} [contactId] Domain contactId
             * @property {number|null} [termYears] Domain termYears
             * @property {Long|null} [createdAt] Domain createdAt
             * @property {Long|null} [expiresAt] Domain expiresAt
             * @property {boolean|null} [whoisPrivacy] Domain whoisPrivacy
             * @property {boolean|null} [autoRenew] Domain autoRenew
             * @property {ca2types.DomainStatus|null} [status] Domain status
             * @property {string|null} [error] Domain error
             */
    
            /**
             * Constructs a new Domain.
             * @memberof ca2types
             * @classdesc Represents a Domain.
             * @implements IDomain
             * @constructor
             * @param {ca2types.IDomain=} [properties] Properties to set
             */
            function Domain(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Domain id.
             * @member {Long} id
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Domain name.
             * @member {string} name
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.name = "";
    
            /**
             * Domain seller.
             * @member {ca2types.DomainSeller} seller
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.seller = 0;
    
            /**
             * Domain contactId.
             * @member {number} contactId
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.contactId = 0;
    
            /**
             * Domain termYears.
             * @member {number} termYears
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.termYears = 0;
    
            /**
             * Domain createdAt.
             * @member {Long} createdAt
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Domain expiresAt.
             * @member {Long} expiresAt
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.expiresAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Domain whoisPrivacy.
             * @member {boolean} whoisPrivacy
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.whoisPrivacy = false;
    
            /**
             * Domain autoRenew.
             * @member {boolean} autoRenew
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.autoRenew = false;
    
            /**
             * Domain status.
             * @member {ca2types.DomainStatus} status
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.status = 0;
    
            /**
             * Domain error.
             * @member {string} error
             * @memberof ca2types.Domain
             * @instance
             */
            Domain.prototype.error = "";
    
            /**
             * Creates a new Domain instance using the specified properties.
             * @function create
             * @memberof ca2types.Domain
             * @static
             * @param {ca2types.IDomain=} [properties] Properties to set
             * @returns {ca2types.Domain} Domain instance
             */
            Domain.create = function create(properties) {
                return new Domain(properties);
            };
    
            /**
             * Encodes the specified Domain message. Does not implicitly {@link ca2types.Domain.verify|verify} messages.
             * @function encode
             * @memberof ca2types.Domain
             * @static
             * @param {ca2types.IDomain} message Domain message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Domain.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.seller != null && Object.hasOwnProperty.call(message, "seller"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.seller);
                if (message.contactId != null && Object.hasOwnProperty.call(message, "contactId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.contactId);
                if (message.termYears != null && Object.hasOwnProperty.call(message, "termYears"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.termYears);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.createdAt);
                if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.expiresAt);
                if (message.whoisPrivacy != null && Object.hasOwnProperty.call(message, "whoisPrivacy"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.whoisPrivacy);
                if (message.autoRenew != null && Object.hasOwnProperty.call(message, "autoRenew"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.autoRenew);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.error);
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified Domain message, length delimited. Does not implicitly {@link ca2types.Domain.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.Domain
             * @static
             * @param {ca2types.IDomain} message Domain message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Domain.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Domain message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.Domain
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.Domain} Domain
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Domain.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.Domain();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 11: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.seller = reader.int32();
                            break;
                        }
                    case 3: {
                            message.contactId = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.termYears = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.createdAt = reader.uint64();
                            break;
                        }
                    case 6: {
                            message.expiresAt = reader.uint64();
                            break;
                        }
                    case 7: {
                            message.whoisPrivacy = reader.bool();
                            break;
                        }
                    case 8: {
                            message.autoRenew = reader.bool();
                            break;
                        }
                    case 9: {
                            message.status = reader.int32();
                            break;
                        }
                    case 10: {
                            message.error = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Domain message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.Domain
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.Domain} Domain
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Domain.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Domain message.
             * @function verify
             * @memberof ca2types.Domain
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Domain.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.seller != null && message.hasOwnProperty("seller"))
                    switch (message.seller) {
                    default:
                        return "seller: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.contactId != null && message.hasOwnProperty("contactId"))
                    if (!$util.isInteger(message.contactId))
                        return "contactId: integer expected";
                if (message.termYears != null && message.hasOwnProperty("termYears"))
                    if (!$util.isInteger(message.termYears))
                        return "termYears: integer expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                    if (!$util.isInteger(message.expiresAt) && !(message.expiresAt && $util.isInteger(message.expiresAt.low) && $util.isInteger(message.expiresAt.high)))
                        return "expiresAt: integer|Long expected";
                if (message.whoisPrivacy != null && message.hasOwnProperty("whoisPrivacy"))
                    if (typeof message.whoisPrivacy !== "boolean")
                        return "whoisPrivacy: boolean expected";
                if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                    if (typeof message.autoRenew !== "boolean")
                        return "autoRenew: boolean expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.error != null && message.hasOwnProperty("error"))
                    if (!$util.isString(message.error))
                        return "error: string expected";
                return null;
            };
    
            /**
             * Creates a Domain message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.Domain
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.Domain} Domain
             */
            Domain.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.Domain)
                    return object;
                var message = new $root.ca2types.Domain();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                switch (object.seller) {
                default:
                    if (typeof object.seller === "number") {
                        message.seller = object.seller;
                        break;
                    }
                    break;
                case "DS_UNKNOWN":
                case 0:
                    message.seller = 0;
                    break;
                case "DS_NAMECHEAP":
                case 1:
                    message.seller = 1;
                    break;
                case "DS_NAMESILO":
                case 2:
                    message.seller = 2;
                    break;
                }
                if (object.contactId != null)
                    message.contactId = object.contactId >>> 0;
                if (object.termYears != null)
                    message.termYears = object.termYears >>> 0;
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                if (object.expiresAt != null)
                    if ($util.Long)
                        (message.expiresAt = $util.Long.fromValue(object.expiresAt)).unsigned = true;
                    else if (typeof object.expiresAt === "string")
                        message.expiresAt = parseInt(object.expiresAt, 10);
                    else if (typeof object.expiresAt === "number")
                        message.expiresAt = object.expiresAt;
                    else if (typeof object.expiresAt === "object")
                        message.expiresAt = new $util.LongBits(object.expiresAt.low >>> 0, object.expiresAt.high >>> 0).toNumber(true);
                if (object.whoisPrivacy != null)
                    message.whoisPrivacy = Boolean(object.whoisPrivacy);
                if (object.autoRenew != null)
                    message.autoRenew = Boolean(object.autoRenew);
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "DST_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "DST_PROCESSING":
                case 1:
                    message.status = 1;
                    break;
                case "DST_ERROR":
                case 2:
                    message.status = 2;
                    break;
                case "DST_ACTIVE":
                case 3:
                    message.status = 3;
                    break;
                case "DST_DELETED":
                case 4:
                    message.status = 4;
                    break;
                }
                if (object.error != null)
                    message.error = String(object.error);
                return message;
            };
    
            /**
             * Creates a plain object from a Domain message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.Domain
             * @static
             * @param {ca2types.Domain} message Domain
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Domain.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.seller = options.enums === String ? "DS_UNKNOWN" : 0;
                    object.contactId = 0;
                    object.termYears = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.expiresAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.expiresAt = options.longs === String ? "0" : 0;
                    object.whoisPrivacy = false;
                    object.autoRenew = false;
                    object.status = options.enums === String ? "DST_UNKNOWN" : 0;
                    object.error = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.seller != null && message.hasOwnProperty("seller"))
                    object.seller = options.enums === String ? $root.ca2types.DomainSeller[message.seller] === undefined ? message.seller : $root.ca2types.DomainSeller[message.seller] : message.seller;
                if (message.contactId != null && message.hasOwnProperty("contactId"))
                    object.contactId = message.contactId;
                if (message.termYears != null && message.hasOwnProperty("termYears"))
                    object.termYears = message.termYears;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                    if (typeof message.expiresAt === "number")
                        object.expiresAt = options.longs === String ? String(message.expiresAt) : message.expiresAt;
                    else
                        object.expiresAt = options.longs === String ? $util.Long.prototype.toString.call(message.expiresAt) : options.longs === Number ? new $util.LongBits(message.expiresAt.low >>> 0, message.expiresAt.high >>> 0).toNumber(true) : message.expiresAt;
                if (message.whoisPrivacy != null && message.hasOwnProperty("whoisPrivacy"))
                    object.whoisPrivacy = message.whoisPrivacy;
                if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                    object.autoRenew = message.autoRenew;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.ca2types.DomainStatus[message.status] === undefined ? message.status : $root.ca2types.DomainStatus[message.status] : message.status;
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = message.error;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                return object;
            };
    
            /**
             * Converts this Domain to JSON.
             * @function toJSON
             * @memberof ca2types.Domain
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Domain.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Domain
             * @function getTypeUrl
             * @memberof ca2types.Domain
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Domain.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.Domain";
            };
    
            return Domain;
        })();
    
        ca2types.DomainContact = (function() {
    
            /**
             * Properties of a DomainContact.
             * @memberof ca2types
             * @interface IDomainContact
             * @property {number|null} [id] DomainContact id
             * @property {Long|null} [createdAt] DomainContact createdAt
             * @property {string|null} [firstName] DomainContact firstName
             * @property {string|null} [lastName] DomainContact lastName
             * @property {string|null} [mailingAddress] DomainContact mailingAddress
             * @property {string|null} [mailingCity] DomainContact mailingCity
             * @property {string|null} [mailingState] DomainContact mailingState
             * @property {string|null} [mailingZip] DomainContact mailingZip
             * @property {string|null} [mailingCountryCode] DomainContact mailingCountryCode
             * @property {string|null} [emailAddress] DomainContact emailAddress
             * @property {string|null} [phoneNumber] DomainContact phoneNumber
             */
    
            /**
             * Constructs a new DomainContact.
             * @memberof ca2types
             * @classdesc Represents a DomainContact.
             * @implements IDomainContact
             * @constructor
             * @param {ca2types.IDomainContact=} [properties] Properties to set
             */
            function DomainContact(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DomainContact id.
             * @member {number} id
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.id = 0;
    
            /**
             * DomainContact createdAt.
             * @member {Long} createdAt
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * DomainContact firstName.
             * @member {string} firstName
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.firstName = "";
    
            /**
             * DomainContact lastName.
             * @member {string} lastName
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.lastName = "";
    
            /**
             * DomainContact mailingAddress.
             * @member {string} mailingAddress
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.mailingAddress = "";
    
            /**
             * DomainContact mailingCity.
             * @member {string} mailingCity
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.mailingCity = "";
    
            /**
             * DomainContact mailingState.
             * @member {string} mailingState
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.mailingState = "";
    
            /**
             * DomainContact mailingZip.
             * @member {string} mailingZip
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.mailingZip = "";
    
            /**
             * DomainContact mailingCountryCode.
             * @member {string} mailingCountryCode
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.mailingCountryCode = "";
    
            /**
             * DomainContact emailAddress.
             * @member {string} emailAddress
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.emailAddress = "";
    
            /**
             * DomainContact phoneNumber.
             * @member {string} phoneNumber
             * @memberof ca2types.DomainContact
             * @instance
             */
            DomainContact.prototype.phoneNumber = "";
    
            /**
             * Creates a new DomainContact instance using the specified properties.
             * @function create
             * @memberof ca2types.DomainContact
             * @static
             * @param {ca2types.IDomainContact=} [properties] Properties to set
             * @returns {ca2types.DomainContact} DomainContact instance
             */
            DomainContact.create = function create(properties) {
                return new DomainContact(properties);
            };
    
            /**
             * Encodes the specified DomainContact message. Does not implicitly {@link ca2types.DomainContact.verify|verify} messages.
             * @function encode
             * @memberof ca2types.DomainContact
             * @static
             * @param {ca2types.IDomainContact} message DomainContact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainContact.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                if (message.mailingAddress != null && Object.hasOwnProperty.call(message, "mailingAddress"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.mailingAddress);
                if (message.mailingCity != null && Object.hasOwnProperty.call(message, "mailingCity"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.mailingCity);
                if (message.mailingState != null && Object.hasOwnProperty.call(message, "mailingState"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.mailingState);
                if (message.mailingZip != null && Object.hasOwnProperty.call(message, "mailingZip"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.mailingZip);
                if (message.mailingCountryCode != null && Object.hasOwnProperty.call(message, "mailingCountryCode"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.mailingCountryCode);
                if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.emailAddress);
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.phoneNumber);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.createdAt);
                return writer;
            };
    
            /**
             * Encodes the specified DomainContact message, length delimited. Does not implicitly {@link ca2types.DomainContact.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2types.DomainContact
             * @static
             * @param {ca2types.IDomainContact} message DomainContact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainContact.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DomainContact message from the specified reader or buffer.
             * @function decode
             * @memberof ca2types.DomainContact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2types.DomainContact} DomainContact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainContact.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2types.DomainContact();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 11: {
                            message.createdAt = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.firstName = reader.string();
                            break;
                        }
                    case 3: {
                            message.lastName = reader.string();
                            break;
                        }
                    case 4: {
                            message.mailingAddress = reader.string();
                            break;
                        }
                    case 5: {
                            message.mailingCity = reader.string();
                            break;
                        }
                    case 6: {
                            message.mailingState = reader.string();
                            break;
                        }
                    case 7: {
                            message.mailingZip = reader.string();
                            break;
                        }
                    case 8: {
                            message.mailingCountryCode = reader.string();
                            break;
                        }
                    case 9: {
                            message.emailAddress = reader.string();
                            break;
                        }
                    case 10: {
                            message.phoneNumber = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DomainContact message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2types.DomainContact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2types.DomainContact} DomainContact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainContact.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DomainContact message.
             * @function verify
             * @memberof ca2types.DomainContact
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainContact.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    if (!$util.isString(message.firstName))
                        return "firstName: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                    if (!$util.isString(message.mailingAddress))
                        return "mailingAddress: string expected";
                if (message.mailingCity != null && message.hasOwnProperty("mailingCity"))
                    if (!$util.isString(message.mailingCity))
                        return "mailingCity: string expected";
                if (message.mailingState != null && message.hasOwnProperty("mailingState"))
                    if (!$util.isString(message.mailingState))
                        return "mailingState: string expected";
                if (message.mailingZip != null && message.hasOwnProperty("mailingZip"))
                    if (!$util.isString(message.mailingZip))
                        return "mailingZip: string expected";
                if (message.mailingCountryCode != null && message.hasOwnProperty("mailingCountryCode"))
                    if (!$util.isString(message.mailingCountryCode))
                        return "mailingCountryCode: string expected";
                if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                    if (!$util.isString(message.emailAddress))
                        return "emailAddress: string expected";
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    if (!$util.isString(message.phoneNumber))
                        return "phoneNumber: string expected";
                return null;
            };
    
            /**
             * Creates a DomainContact message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2types.DomainContact
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2types.DomainContact} DomainContact
             */
            DomainContact.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2types.DomainContact)
                    return object;
                var message = new $root.ca2types.DomainContact();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                if (object.firstName != null)
                    message.firstName = String(object.firstName);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                if (object.mailingAddress != null)
                    message.mailingAddress = String(object.mailingAddress);
                if (object.mailingCity != null)
                    message.mailingCity = String(object.mailingCity);
                if (object.mailingState != null)
                    message.mailingState = String(object.mailingState);
                if (object.mailingZip != null)
                    message.mailingZip = String(object.mailingZip);
                if (object.mailingCountryCode != null)
                    message.mailingCountryCode = String(object.mailingCountryCode);
                if (object.emailAddress != null)
                    message.emailAddress = String(object.emailAddress);
                if (object.phoneNumber != null)
                    message.phoneNumber = String(object.phoneNumber);
                return message;
            };
    
            /**
             * Creates a plain object from a DomainContact message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2types.DomainContact
             * @static
             * @param {ca2types.DomainContact} message DomainContact
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainContact.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.firstName = "";
                    object.lastName = "";
                    object.mailingAddress = "";
                    object.mailingCity = "";
                    object.mailingState = "";
                    object.mailingZip = "";
                    object.mailingCountryCode = "";
                    object.emailAddress = "";
                    object.phoneNumber = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    object.firstName = message.firstName;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                    object.mailingAddress = message.mailingAddress;
                if (message.mailingCity != null && message.hasOwnProperty("mailingCity"))
                    object.mailingCity = message.mailingCity;
                if (message.mailingState != null && message.hasOwnProperty("mailingState"))
                    object.mailingState = message.mailingState;
                if (message.mailingZip != null && message.hasOwnProperty("mailingZip"))
                    object.mailingZip = message.mailingZip;
                if (message.mailingCountryCode != null && message.hasOwnProperty("mailingCountryCode"))
                    object.mailingCountryCode = message.mailingCountryCode;
                if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                    object.emailAddress = message.emailAddress;
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = message.phoneNumber;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                return object;
            };
    
            /**
             * Converts this DomainContact to JSON.
             * @function toJSON
             * @memberof ca2types.DomainContact
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainContact.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DomainContact
             * @function getTypeUrl
             * @memberof ca2types.DomainContact
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainContact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2types.DomainContact";
            };
    
            return DomainContact;
        })();
    
        return ca2types;
    })();
    
    $root.ca2 = (function() {
    
        /**
         * Namespace ca2.
         * @exports ca2
         * @namespace
         */
        var ca2 = {};
    
        ca2.ClientRequest = (function() {
    
            /**
             * Properties of a ClientRequest.
             * @memberof ca2
             * @interface IClientRequest
             * @property {Uint8Array|null} [trx] ClientRequest trx
             * @property {string|null} [token] ClientRequest token
             * @property {ca2auth.IRequest|null} [auth] ClientRequest auth
             * @property {ca2billing.IRequest|null} [billing] ClientRequest billing
             * @property {ca2instances.IRequest|null} [instances] ClientRequest instances
             * @property {ca2referrals.IRequest|null} [referrals] ClientRequest referrals
             * @property {ca2servers.IRequest|null} [servers] ClientRequest servers
             * @property {ca2ssh.IRequest|null} [ssh] ClientRequest ssh
             * @property {ca2users.IRequest|null} [users] ClientRequest users
             * @property {ca2utils.IRequest|null} [utils] ClientRequest utils
             * @property {ca2domains.IRequest|null} [domains] ClientRequest domains
             */
    
            /**
             * Constructs a new ClientRequest.
             * @memberof ca2
             * @classdesc Represents a ClientRequest.
             * @implements IClientRequest
             * @constructor
             * @param {ca2.IClientRequest=} [properties] Properties to set
             */
            function ClientRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientRequest trx.
             * @member {Uint8Array} trx
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.trx = $util.newBuffer([]);
    
            /**
             * ClientRequest token.
             * @member {string} token
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.token = "";
    
            /**
             * ClientRequest auth.
             * @member {ca2auth.IRequest|null|undefined} auth
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.auth = null;
    
            /**
             * ClientRequest billing.
             * @member {ca2billing.IRequest|null|undefined} billing
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.billing = null;
    
            /**
             * ClientRequest instances.
             * @member {ca2instances.IRequest|null|undefined} instances
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.instances = null;
    
            /**
             * ClientRequest referrals.
             * @member {ca2referrals.IRequest|null|undefined} referrals
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.referrals = null;
    
            /**
             * ClientRequest servers.
             * @member {ca2servers.IRequest|null|undefined} servers
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.servers = null;
    
            /**
             * ClientRequest ssh.
             * @member {ca2ssh.IRequest|null|undefined} ssh
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.ssh = null;
    
            /**
             * ClientRequest users.
             * @member {ca2users.IRequest|null|undefined} users
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.users = null;
    
            /**
             * ClientRequest utils.
             * @member {ca2utils.IRequest|null|undefined} utils
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.utils = null;
    
            /**
             * ClientRequest domains.
             * @member {ca2domains.IRequest|null|undefined} domains
             * @memberof ca2.ClientRequest
             * @instance
             */
            ClientRequest.prototype.domains = null;
    
            /**
             * Creates a new ClientRequest instance using the specified properties.
             * @function create
             * @memberof ca2.ClientRequest
             * @static
             * @param {ca2.IClientRequest=} [properties] Properties to set
             * @returns {ca2.ClientRequest} ClientRequest instance
             */
            ClientRequest.create = function create(properties) {
                return new ClientRequest(properties);
            };
    
            /**
             * Encodes the specified ClientRequest message. Does not implicitly {@link ca2.ClientRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2.ClientRequest
             * @static
             * @param {ca2.IClientRequest} message ClientRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trx != null && Object.hasOwnProperty.call(message, "trx"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.trx);
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
                if (message.utils != null && Object.hasOwnProperty.call(message, "utils"))
                    $root.ca2utils.Request.encode(message.utils, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                    $root.ca2auth.Request.encode(message.auth, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.users != null && Object.hasOwnProperty.call(message, "users"))
                    $root.ca2users.Request.encode(message.users, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.ssh != null && Object.hasOwnProperty.call(message, "ssh"))
                    $root.ca2ssh.Request.encode(message.ssh, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.referrals != null && Object.hasOwnProperty.call(message, "referrals"))
                    $root.ca2referrals.Request.encode(message.referrals, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.servers != null && Object.hasOwnProperty.call(message, "servers"))
                    $root.ca2servers.Request.encode(message.servers, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.billing != null && Object.hasOwnProperty.call(message, "billing"))
                    $root.ca2billing.Request.encode(message.billing, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.instances != null && Object.hasOwnProperty.call(message, "instances"))
                    $root.ca2instances.Request.encode(message.instances, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.domains != null && Object.hasOwnProperty.call(message, "domains"))
                    $root.ca2domains.Request.encode(message.domains, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientRequest message, length delimited. Does not implicitly {@link ca2.ClientRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2.ClientRequest
             * @static
             * @param {ca2.IClientRequest} message ClientRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2.ClientRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2.ClientRequest} ClientRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2.ClientRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.trx = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.token = reader.string();
                            break;
                        }
                    case 11: {
                            message.auth = $root.ca2auth.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.billing = $root.ca2billing.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.instances = $root.ca2instances.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.referrals = $root.ca2referrals.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.servers = $root.ca2servers.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.ssh = $root.ca2ssh.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.users = $root.ca2users.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.utils = $root.ca2utils.Request.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.domains = $root.ca2domains.Request.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2.ClientRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2.ClientRequest} ClientRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientRequest message.
             * @function verify
             * @memberof ca2.ClientRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trx != null && message.hasOwnProperty("trx"))
                    if (!(message.trx && typeof message.trx.length === "number" || $util.isString(message.trx)))
                        return "trx: buffer expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.auth != null && message.hasOwnProperty("auth")) {
                    var error = $root.ca2auth.Request.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                if (message.billing != null && message.hasOwnProperty("billing")) {
                    var error = $root.ca2billing.Request.verify(message.billing);
                    if (error)
                        return "billing." + error;
                }
                if (message.instances != null && message.hasOwnProperty("instances")) {
                    var error = $root.ca2instances.Request.verify(message.instances);
                    if (error)
                        return "instances." + error;
                }
                if (message.referrals != null && message.hasOwnProperty("referrals")) {
                    var error = $root.ca2referrals.Request.verify(message.referrals);
                    if (error)
                        return "referrals." + error;
                }
                if (message.servers != null && message.hasOwnProperty("servers")) {
                    var error = $root.ca2servers.Request.verify(message.servers);
                    if (error)
                        return "servers." + error;
                }
                if (message.ssh != null && message.hasOwnProperty("ssh")) {
                    var error = $root.ca2ssh.Request.verify(message.ssh);
                    if (error)
                        return "ssh." + error;
                }
                if (message.users != null && message.hasOwnProperty("users")) {
                    var error = $root.ca2users.Request.verify(message.users);
                    if (error)
                        return "users." + error;
                }
                if (message.utils != null && message.hasOwnProperty("utils")) {
                    var error = $root.ca2utils.Request.verify(message.utils);
                    if (error)
                        return "utils." + error;
                }
                if (message.domains != null && message.hasOwnProperty("domains")) {
                    var error = $root.ca2domains.Request.verify(message.domains);
                    if (error)
                        return "domains." + error;
                }
                return null;
            };
    
            /**
             * Creates a ClientRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2.ClientRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2.ClientRequest} ClientRequest
             */
            ClientRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2.ClientRequest)
                    return object;
                var message = new $root.ca2.ClientRequest();
                if (object.trx != null)
                    if (typeof object.trx === "string")
                        $util.base64.decode(object.trx, message.trx = $util.newBuffer($util.base64.length(object.trx)), 0);
                    else if (object.trx.length >= 0)
                        message.trx = object.trx;
                if (object.token != null)
                    message.token = String(object.token);
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".ca2.ClientRequest.auth: object expected");
                    message.auth = $root.ca2auth.Request.fromObject(object.auth);
                }
                if (object.billing != null) {
                    if (typeof object.billing !== "object")
                        throw TypeError(".ca2.ClientRequest.billing: object expected");
                    message.billing = $root.ca2billing.Request.fromObject(object.billing);
                }
                if (object.instances != null) {
                    if (typeof object.instances !== "object")
                        throw TypeError(".ca2.ClientRequest.instances: object expected");
                    message.instances = $root.ca2instances.Request.fromObject(object.instances);
                }
                if (object.referrals != null) {
                    if (typeof object.referrals !== "object")
                        throw TypeError(".ca2.ClientRequest.referrals: object expected");
                    message.referrals = $root.ca2referrals.Request.fromObject(object.referrals);
                }
                if (object.servers != null) {
                    if (typeof object.servers !== "object")
                        throw TypeError(".ca2.ClientRequest.servers: object expected");
                    message.servers = $root.ca2servers.Request.fromObject(object.servers);
                }
                if (object.ssh != null) {
                    if (typeof object.ssh !== "object")
                        throw TypeError(".ca2.ClientRequest.ssh: object expected");
                    message.ssh = $root.ca2ssh.Request.fromObject(object.ssh);
                }
                if (object.users != null) {
                    if (typeof object.users !== "object")
                        throw TypeError(".ca2.ClientRequest.users: object expected");
                    message.users = $root.ca2users.Request.fromObject(object.users);
                }
                if (object.utils != null) {
                    if (typeof object.utils !== "object")
                        throw TypeError(".ca2.ClientRequest.utils: object expected");
                    message.utils = $root.ca2utils.Request.fromObject(object.utils);
                }
                if (object.domains != null) {
                    if (typeof object.domains !== "object")
                        throw TypeError(".ca2.ClientRequest.domains: object expected");
                    message.domains = $root.ca2domains.Request.fromObject(object.domains);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2.ClientRequest
             * @static
             * @param {ca2.ClientRequest} message ClientRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.trx = "";
                    else {
                        object.trx = [];
                        if (options.bytes !== Array)
                            object.trx = $util.newBuffer(object.trx);
                    }
                    object.token = "";
                    object.utils = null;
                    object.auth = null;
                    object.users = null;
                    object.ssh = null;
                    object.referrals = null;
                    object.servers = null;
                    object.billing = null;
                    object.instances = null;
                    object.domains = null;
                }
                if (message.trx != null && message.hasOwnProperty("trx"))
                    object.trx = options.bytes === String ? $util.base64.encode(message.trx, 0, message.trx.length) : options.bytes === Array ? Array.prototype.slice.call(message.trx) : message.trx;
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.utils != null && message.hasOwnProperty("utils"))
                    object.utils = $root.ca2utils.Request.toObject(message.utils, options);
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.ca2auth.Request.toObject(message.auth, options);
                if (message.users != null && message.hasOwnProperty("users"))
                    object.users = $root.ca2users.Request.toObject(message.users, options);
                if (message.ssh != null && message.hasOwnProperty("ssh"))
                    object.ssh = $root.ca2ssh.Request.toObject(message.ssh, options);
                if (message.referrals != null && message.hasOwnProperty("referrals"))
                    object.referrals = $root.ca2referrals.Request.toObject(message.referrals, options);
                if (message.servers != null && message.hasOwnProperty("servers"))
                    object.servers = $root.ca2servers.Request.toObject(message.servers, options);
                if (message.billing != null && message.hasOwnProperty("billing"))
                    object.billing = $root.ca2billing.Request.toObject(message.billing, options);
                if (message.instances != null && message.hasOwnProperty("instances"))
                    object.instances = $root.ca2instances.Request.toObject(message.instances, options);
                if (message.domains != null && message.hasOwnProperty("domains"))
                    object.domains = $root.ca2domains.Request.toObject(message.domains, options);
                return object;
            };
    
            /**
             * Converts this ClientRequest to JSON.
             * @function toJSON
             * @memberof ca2.ClientRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ClientRequest
             * @function getTypeUrl
             * @memberof ca2.ClientRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ClientRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2.ClientRequest";
            };
    
            return ClientRequest;
        })();
    
        /**
         * ServerError enum.
         * @name ca2.ServerError
         * @enum {number}
         * @property {number} SE_UNKNOWN_ERROR=0 SE_UNKNOWN_ERROR value
         * @property {number} SE_TRX_REQUIRED_ERROR=1 SE_TRX_REQUIRED_ERROR value
         * @property {number} SE_INVALID_TRX_ERROR=2 SE_INVALID_TRX_ERROR value
         * @property {number} SE_INVALID_TOKEN_ERROR=4 SE_INVALID_TOKEN_ERROR value
         * @property {number} SE_EXPIRED_TOKEN_ERROR=5 SE_EXPIRED_TOKEN_ERROR value
         * @property {number} SE_INTERNAL_SERVER_ERROR=6 SE_INTERNAL_SERVER_ERROR value
         * @property {number} SE_TOKEN_REQUIRED_ERROR=7 SE_TOKEN_REQUIRED_ERROR value
         * @property {number} SE_RATE_LIMIT_EXCEEDED_ERROR=8 SE_RATE_LIMIT_EXCEEDED_ERROR value
         */
        ca2.ServerError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "SE_TRX_REQUIRED_ERROR"] = 1;
            values[valuesById[2] = "SE_INVALID_TRX_ERROR"] = 2;
            values[valuesById[4] = "SE_INVALID_TOKEN_ERROR"] = 4;
            values[valuesById[5] = "SE_EXPIRED_TOKEN_ERROR"] = 5;
            values[valuesById[6] = "SE_INTERNAL_SERVER_ERROR"] = 6;
            values[valuesById[7] = "SE_TOKEN_REQUIRED_ERROR"] = 7;
            values[valuesById[8] = "SE_RATE_LIMIT_EXCEEDED_ERROR"] = 8;
            return values;
        })();
    
        ca2.ServerResponse = (function() {
    
            /**
             * Properties of a ServerResponse.
             * @memberof ca2
             * @interface IServerResponse
             * @property {Uint8Array|null} [trx] ServerResponse trx
             * @property {Array.<ca2.ServerError>|null} [errors] ServerResponse errors
             * @property {ca2auth.IResponse|null} [auth] ServerResponse auth
             * @property {ca2billing.IResponse|null} [billing] ServerResponse billing
             * @property {ca2instances.IResponse|null} [instances] ServerResponse instances
             * @property {ca2referrals.IResponse|null} [referrals] ServerResponse referrals
             * @property {ca2servers.IResponse|null} [servers] ServerResponse servers
             * @property {ca2ssh.IResponse|null} [ssh] ServerResponse ssh
             * @property {ca2users.IResponse|null} [users] ServerResponse users
             * @property {ca2utils.IResponse|null} [utils] ServerResponse utils
             * @property {ca2domains.IResponse|null} [domains] ServerResponse domains
             */
    
            /**
             * Constructs a new ServerResponse.
             * @memberof ca2
             * @classdesc Represents a ServerResponse.
             * @implements IServerResponse
             * @constructor
             * @param {ca2.IServerResponse=} [properties] Properties to set
             */
            function ServerResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServerResponse trx.
             * @member {Uint8Array} trx
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.trx = $util.newBuffer([]);
    
            /**
             * ServerResponse errors.
             * @member {Array.<ca2.ServerError>} errors
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.errors = $util.emptyArray;
    
            /**
             * ServerResponse auth.
             * @member {ca2auth.IResponse|null|undefined} auth
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.auth = null;
    
            /**
             * ServerResponse billing.
             * @member {ca2billing.IResponse|null|undefined} billing
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.billing = null;
    
            /**
             * ServerResponse instances.
             * @member {ca2instances.IResponse|null|undefined} instances
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.instances = null;
    
            /**
             * ServerResponse referrals.
             * @member {ca2referrals.IResponse|null|undefined} referrals
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.referrals = null;
    
            /**
             * ServerResponse servers.
             * @member {ca2servers.IResponse|null|undefined} servers
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.servers = null;
    
            /**
             * ServerResponse ssh.
             * @member {ca2ssh.IResponse|null|undefined} ssh
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.ssh = null;
    
            /**
             * ServerResponse users.
             * @member {ca2users.IResponse|null|undefined} users
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.users = null;
    
            /**
             * ServerResponse utils.
             * @member {ca2utils.IResponse|null|undefined} utils
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.utils = null;
    
            /**
             * ServerResponse domains.
             * @member {ca2domains.IResponse|null|undefined} domains
             * @memberof ca2.ServerResponse
             * @instance
             */
            ServerResponse.prototype.domains = null;
    
            /**
             * Creates a new ServerResponse instance using the specified properties.
             * @function create
             * @memberof ca2.ServerResponse
             * @static
             * @param {ca2.IServerResponse=} [properties] Properties to set
             * @returns {ca2.ServerResponse} ServerResponse instance
             */
            ServerResponse.create = function create(properties) {
                return new ServerResponse(properties);
            };
    
            /**
             * Encodes the specified ServerResponse message. Does not implicitly {@link ca2.ServerResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2.ServerResponse
             * @static
             * @param {ca2.IServerResponse} message ServerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trx != null && Object.hasOwnProperty.call(message, "trx"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.trx);
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.utils != null && Object.hasOwnProperty.call(message, "utils"))
                    $root.ca2utils.Response.encode(message.utils, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                    $root.ca2auth.Response.encode(message.auth, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.users != null && Object.hasOwnProperty.call(message, "users"))
                    $root.ca2users.Response.encode(message.users, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.ssh != null && Object.hasOwnProperty.call(message, "ssh"))
                    $root.ca2ssh.Response.encode(message.ssh, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.referrals != null && Object.hasOwnProperty.call(message, "referrals"))
                    $root.ca2referrals.Response.encode(message.referrals, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.servers != null && Object.hasOwnProperty.call(message, "servers"))
                    $root.ca2servers.Response.encode(message.servers, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.billing != null && Object.hasOwnProperty.call(message, "billing"))
                    $root.ca2billing.Response.encode(message.billing, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.instances != null && Object.hasOwnProperty.call(message, "instances"))
                    $root.ca2instances.Response.encode(message.instances, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.domains != null && Object.hasOwnProperty.call(message, "domains"))
                    $root.ca2domains.Response.encode(message.domains, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ServerResponse message, length delimited. Does not implicitly {@link ca2.ServerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2.ServerResponse
             * @static
             * @param {ca2.IServerResponse} message ServerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2.ServerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2.ServerResponse} ServerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2.ServerResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.trx = reader.bytes();
                            break;
                        }
                    case 2: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 11: {
                            message.auth = $root.ca2auth.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.billing = $root.ca2billing.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.instances = $root.ca2instances.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.referrals = $root.ca2referrals.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.servers = $root.ca2servers.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.ssh = $root.ca2ssh.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.users = $root.ca2users.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.utils = $root.ca2utils.Response.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.domains = $root.ca2domains.Response.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2.ServerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2.ServerResponse} ServerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServerResponse message.
             * @function verify
             * @memberof ca2.ServerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trx != null && message.hasOwnProperty("trx"))
                    if (!(message.trx && typeof message.trx.length === "number" || $util.isString(message.trx)))
                        return "trx: buffer expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                }
                if (message.auth != null && message.hasOwnProperty("auth")) {
                    var error = $root.ca2auth.Response.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                if (message.billing != null && message.hasOwnProperty("billing")) {
                    var error = $root.ca2billing.Response.verify(message.billing);
                    if (error)
                        return "billing." + error;
                }
                if (message.instances != null && message.hasOwnProperty("instances")) {
                    var error = $root.ca2instances.Response.verify(message.instances);
                    if (error)
                        return "instances." + error;
                }
                if (message.referrals != null && message.hasOwnProperty("referrals")) {
                    var error = $root.ca2referrals.Response.verify(message.referrals);
                    if (error)
                        return "referrals." + error;
                }
                if (message.servers != null && message.hasOwnProperty("servers")) {
                    var error = $root.ca2servers.Response.verify(message.servers);
                    if (error)
                        return "servers." + error;
                }
                if (message.ssh != null && message.hasOwnProperty("ssh")) {
                    var error = $root.ca2ssh.Response.verify(message.ssh);
                    if (error)
                        return "ssh." + error;
                }
                if (message.users != null && message.hasOwnProperty("users")) {
                    var error = $root.ca2users.Response.verify(message.users);
                    if (error)
                        return "users." + error;
                }
                if (message.utils != null && message.hasOwnProperty("utils")) {
                    var error = $root.ca2utils.Response.verify(message.utils);
                    if (error)
                        return "utils." + error;
                }
                if (message.domains != null && message.hasOwnProperty("domains")) {
                    var error = $root.ca2domains.Response.verify(message.domains);
                    if (error)
                        return "domains." + error;
                }
                return null;
            };
    
            /**
             * Creates a ServerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2.ServerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2.ServerResponse} ServerResponse
             */
            ServerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2.ServerResponse)
                    return object;
                var message = new $root.ca2.ServerResponse();
                if (object.trx != null)
                    if (typeof object.trx === "string")
                        $util.base64.decode(object.trx, message.trx = $util.newBuffer($util.base64.length(object.trx)), 0);
                    else if (object.trx.length >= 0)
                        message.trx = object.trx;
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2.ServerResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "SE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "SE_TRX_REQUIRED_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "SE_INVALID_TRX_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "SE_INVALID_TOKEN_ERROR":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        case "SE_EXPIRED_TOKEN_ERROR":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        case "SE_INTERNAL_SERVER_ERROR":
                        case 6:
                            message.errors[i] = 6;
                            break;
                        case "SE_TOKEN_REQUIRED_ERROR":
                        case 7:
                            message.errors[i] = 7;
                            break;
                        case "SE_RATE_LIMIT_EXCEEDED_ERROR":
                        case 8:
                            message.errors[i] = 8;
                            break;
                        }
                }
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".ca2.ServerResponse.auth: object expected");
                    message.auth = $root.ca2auth.Response.fromObject(object.auth);
                }
                if (object.billing != null) {
                    if (typeof object.billing !== "object")
                        throw TypeError(".ca2.ServerResponse.billing: object expected");
                    message.billing = $root.ca2billing.Response.fromObject(object.billing);
                }
                if (object.instances != null) {
                    if (typeof object.instances !== "object")
                        throw TypeError(".ca2.ServerResponse.instances: object expected");
                    message.instances = $root.ca2instances.Response.fromObject(object.instances);
                }
                if (object.referrals != null) {
                    if (typeof object.referrals !== "object")
                        throw TypeError(".ca2.ServerResponse.referrals: object expected");
                    message.referrals = $root.ca2referrals.Response.fromObject(object.referrals);
                }
                if (object.servers != null) {
                    if (typeof object.servers !== "object")
                        throw TypeError(".ca2.ServerResponse.servers: object expected");
                    message.servers = $root.ca2servers.Response.fromObject(object.servers);
                }
                if (object.ssh != null) {
                    if (typeof object.ssh !== "object")
                        throw TypeError(".ca2.ServerResponse.ssh: object expected");
                    message.ssh = $root.ca2ssh.Response.fromObject(object.ssh);
                }
                if (object.users != null) {
                    if (typeof object.users !== "object")
                        throw TypeError(".ca2.ServerResponse.users: object expected");
                    message.users = $root.ca2users.Response.fromObject(object.users);
                }
                if (object.utils != null) {
                    if (typeof object.utils !== "object")
                        throw TypeError(".ca2.ServerResponse.utils: object expected");
                    message.utils = $root.ca2utils.Response.fromObject(object.utils);
                }
                if (object.domains != null) {
                    if (typeof object.domains !== "object")
                        throw TypeError(".ca2.ServerResponse.domains: object expected");
                    message.domains = $root.ca2domains.Response.fromObject(object.domains);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ServerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2.ServerResponse
             * @static
             * @param {ca2.ServerResponse} message ServerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults) {
                    if (options.bytes === String)
                        object.trx = "";
                    else {
                        object.trx = [];
                        if (options.bytes !== Array)
                            object.trx = $util.newBuffer(object.trx);
                    }
                    object.utils = null;
                    object.auth = null;
                    object.users = null;
                    object.ssh = null;
                    object.referrals = null;
                    object.servers = null;
                    object.billing = null;
                    object.instances = null;
                    object.domains = null;
                }
                if (message.trx != null && message.hasOwnProperty("trx"))
                    object.trx = options.bytes === String ? $util.base64.encode(message.trx, 0, message.trx.length) : options.bytes === Array ? Array.prototype.slice.call(message.trx) : message.trx;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2.ServerError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2.ServerError[message.errors[j]] : message.errors[j];
                }
                if (message.utils != null && message.hasOwnProperty("utils"))
                    object.utils = $root.ca2utils.Response.toObject(message.utils, options);
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.ca2auth.Response.toObject(message.auth, options);
                if (message.users != null && message.hasOwnProperty("users"))
                    object.users = $root.ca2users.Response.toObject(message.users, options);
                if (message.ssh != null && message.hasOwnProperty("ssh"))
                    object.ssh = $root.ca2ssh.Response.toObject(message.ssh, options);
                if (message.referrals != null && message.hasOwnProperty("referrals"))
                    object.referrals = $root.ca2referrals.Response.toObject(message.referrals, options);
                if (message.servers != null && message.hasOwnProperty("servers"))
                    object.servers = $root.ca2servers.Response.toObject(message.servers, options);
                if (message.billing != null && message.hasOwnProperty("billing"))
                    object.billing = $root.ca2billing.Response.toObject(message.billing, options);
                if (message.instances != null && message.hasOwnProperty("instances"))
                    object.instances = $root.ca2instances.Response.toObject(message.instances, options);
                if (message.domains != null && message.hasOwnProperty("domains"))
                    object.domains = $root.ca2domains.Response.toObject(message.domains, options);
                return object;
            };
    
            /**
             * Converts this ServerResponse to JSON.
             * @function toJSON
             * @memberof ca2.ServerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServerResponse
             * @function getTypeUrl
             * @memberof ca2.ServerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2.ServerResponse";
            };
    
            return ServerResponse;
        })();
    
        ca2.WsAuth = (function() {
    
            /**
             * Properties of a WsAuth.
             * @memberof ca2
             * @interface IWsAuth
             * @property {string|null} [token] WsAuth token
             */
    
            /**
             * Constructs a new WsAuth.
             * @memberof ca2
             * @classdesc Represents a WsAuth.
             * @implements IWsAuth
             * @constructor
             * @param {ca2.IWsAuth=} [properties] Properties to set
             */
            function WsAuth(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * WsAuth token.
             * @member {string} token
             * @memberof ca2.WsAuth
             * @instance
             */
            WsAuth.prototype.token = "";
    
            /**
             * Creates a new WsAuth instance using the specified properties.
             * @function create
             * @memberof ca2.WsAuth
             * @static
             * @param {ca2.IWsAuth=} [properties] Properties to set
             * @returns {ca2.WsAuth} WsAuth instance
             */
            WsAuth.create = function create(properties) {
                return new WsAuth(properties);
            };
    
            /**
             * Encodes the specified WsAuth message. Does not implicitly {@link ca2.WsAuth.verify|verify} messages.
             * @function encode
             * @memberof ca2.WsAuth
             * @static
             * @param {ca2.IWsAuth} message WsAuth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsAuth.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
                return writer;
            };
    
            /**
             * Encodes the specified WsAuth message, length delimited. Does not implicitly {@link ca2.WsAuth.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2.WsAuth
             * @static
             * @param {ca2.IWsAuth} message WsAuth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsAuth.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a WsAuth message from the specified reader or buffer.
             * @function decode
             * @memberof ca2.WsAuth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2.WsAuth} WsAuth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsAuth.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2.WsAuth();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.token = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a WsAuth message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2.WsAuth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2.WsAuth} WsAuth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsAuth.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a WsAuth message.
             * @function verify
             * @memberof ca2.WsAuth
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WsAuth.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };
    
            /**
             * Creates a WsAuth message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2.WsAuth
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2.WsAuth} WsAuth
             */
            WsAuth.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2.WsAuth)
                    return object;
                var message = new $root.ca2.WsAuth();
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };
    
            /**
             * Creates a plain object from a WsAuth message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2.WsAuth
             * @static
             * @param {ca2.WsAuth} message WsAuth
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WsAuth.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.token = "";
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };
    
            /**
             * Converts this WsAuth to JSON.
             * @function toJSON
             * @memberof ca2.WsAuth
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WsAuth.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for WsAuth
             * @function getTypeUrl
             * @memberof ca2.WsAuth
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WsAuth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2.WsAuth";
            };
    
            return WsAuth;
        })();
    
        ca2.WsEvent = (function() {
    
            /**
             * Properties of a WsEvent.
             * @memberof ca2
             * @interface IWsEvent
             * @property {Array.<ca2.ServerError>|null} [errors] WsEvent errors
             * @property {ca2billing.IWsEvent|null} [billing] WsEvent billing
             * @property {ca2instances.IWsEvent|null} [instances] WsEvent instances
             * @property {ca2domains.IDomainCheckWsEvent|null} [domainCheck] WsEvent domainCheck
             * @property {ca2domains.IDomainEvent|null} [domains] WsEvent domains
             */
    
            /**
             * Constructs a new WsEvent.
             * @memberof ca2
             * @classdesc Represents a WsEvent.
             * @implements IWsEvent
             * @constructor
             * @param {ca2.IWsEvent=} [properties] Properties to set
             */
            function WsEvent(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * WsEvent errors.
             * @member {Array.<ca2.ServerError>} errors
             * @memberof ca2.WsEvent
             * @instance
             */
            WsEvent.prototype.errors = $util.emptyArray;
    
            /**
             * WsEvent billing.
             * @member {ca2billing.IWsEvent|null|undefined} billing
             * @memberof ca2.WsEvent
             * @instance
             */
            WsEvent.prototype.billing = null;
    
            /**
             * WsEvent instances.
             * @member {ca2instances.IWsEvent|null|undefined} instances
             * @memberof ca2.WsEvent
             * @instance
             */
            WsEvent.prototype.instances = null;
    
            /**
             * WsEvent domainCheck.
             * @member {ca2domains.IDomainCheckWsEvent|null|undefined} domainCheck
             * @memberof ca2.WsEvent
             * @instance
             */
            WsEvent.prototype.domainCheck = null;
    
            /**
             * WsEvent domains.
             * @member {ca2domains.IDomainEvent|null|undefined} domains
             * @memberof ca2.WsEvent
             * @instance
             */
            WsEvent.prototype.domains = null;
    
            /**
             * Creates a new WsEvent instance using the specified properties.
             * @function create
             * @memberof ca2.WsEvent
             * @static
             * @param {ca2.IWsEvent=} [properties] Properties to set
             * @returns {ca2.WsEvent} WsEvent instance
             */
            WsEvent.create = function create(properties) {
                return new WsEvent(properties);
            };
    
            /**
             * Encodes the specified WsEvent message. Does not implicitly {@link ca2.WsEvent.verify|verify} messages.
             * @function encode
             * @memberof ca2.WsEvent
             * @static
             * @param {ca2.IWsEvent} message WsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.billing != null && Object.hasOwnProperty.call(message, "billing"))
                    $root.ca2billing.WsEvent.encode(message.billing, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.instances != null && Object.hasOwnProperty.call(message, "instances"))
                    $root.ca2instances.WsEvent.encode(message.instances, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.domainCheck != null && Object.hasOwnProperty.call(message, "domainCheck"))
                    $root.ca2domains.DomainCheckWsEvent.encode(message.domainCheck, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.domains != null && Object.hasOwnProperty.call(message, "domains"))
                    $root.ca2domains.DomainEvent.encode(message.domains, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified WsEvent message, length delimited. Does not implicitly {@link ca2.WsEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2.WsEvent
             * @static
             * @param {ca2.IWsEvent} message WsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a WsEvent message from the specified reader or buffer.
             * @function decode
             * @memberof ca2.WsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2.WsEvent} WsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2.WsEvent();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 1: {
                            message.billing = $root.ca2billing.WsEvent.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.instances = $root.ca2instances.WsEvent.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.domainCheck = $root.ca2domains.DomainCheckWsEvent.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.domains = $root.ca2domains.DomainEvent.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a WsEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2.WsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2.WsEvent} WsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a WsEvent message.
             * @function verify
             * @memberof ca2.WsEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WsEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                }
                if (message.billing != null && message.hasOwnProperty("billing")) {
                    var error = $root.ca2billing.WsEvent.verify(message.billing);
                    if (error)
                        return "billing." + error;
                }
                if (message.instances != null && message.hasOwnProperty("instances")) {
                    var error = $root.ca2instances.WsEvent.verify(message.instances);
                    if (error)
                        return "instances." + error;
                }
                if (message.domainCheck != null && message.hasOwnProperty("domainCheck")) {
                    var error = $root.ca2domains.DomainCheckWsEvent.verify(message.domainCheck);
                    if (error)
                        return "domainCheck." + error;
                }
                if (message.domains != null && message.hasOwnProperty("domains")) {
                    var error = $root.ca2domains.DomainEvent.verify(message.domains);
                    if (error)
                        return "domains." + error;
                }
                return null;
            };
    
            /**
             * Creates a WsEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2.WsEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2.WsEvent} WsEvent
             */
            WsEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2.WsEvent)
                    return object;
                var message = new $root.ca2.WsEvent();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2.WsEvent.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "SE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "SE_TRX_REQUIRED_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "SE_INVALID_TRX_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "SE_INVALID_TOKEN_ERROR":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        case "SE_EXPIRED_TOKEN_ERROR":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        case "SE_INTERNAL_SERVER_ERROR":
                        case 6:
                            message.errors[i] = 6;
                            break;
                        case "SE_TOKEN_REQUIRED_ERROR":
                        case 7:
                            message.errors[i] = 7;
                            break;
                        case "SE_RATE_LIMIT_EXCEEDED_ERROR":
                        case 8:
                            message.errors[i] = 8;
                            break;
                        }
                }
                if (object.billing != null) {
                    if (typeof object.billing !== "object")
                        throw TypeError(".ca2.WsEvent.billing: object expected");
                    message.billing = $root.ca2billing.WsEvent.fromObject(object.billing);
                }
                if (object.instances != null) {
                    if (typeof object.instances !== "object")
                        throw TypeError(".ca2.WsEvent.instances: object expected");
                    message.instances = $root.ca2instances.WsEvent.fromObject(object.instances);
                }
                if (object.domainCheck != null) {
                    if (typeof object.domainCheck !== "object")
                        throw TypeError(".ca2.WsEvent.domainCheck: object expected");
                    message.domainCheck = $root.ca2domains.DomainCheckWsEvent.fromObject(object.domainCheck);
                }
                if (object.domains != null) {
                    if (typeof object.domains !== "object")
                        throw TypeError(".ca2.WsEvent.domains: object expected");
                    message.domains = $root.ca2domains.DomainEvent.fromObject(object.domains);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a WsEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2.WsEvent
             * @static
             * @param {ca2.WsEvent} message WsEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WsEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults) {
                    object.billing = null;
                    object.instances = null;
                    object.domainCheck = null;
                    object.domains = null;
                }
                if (message.billing != null && message.hasOwnProperty("billing"))
                    object.billing = $root.ca2billing.WsEvent.toObject(message.billing, options);
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2.ServerError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2.ServerError[message.errors[j]] : message.errors[j];
                }
                if (message.instances != null && message.hasOwnProperty("instances"))
                    object.instances = $root.ca2instances.WsEvent.toObject(message.instances, options);
                if (message.domainCheck != null && message.hasOwnProperty("domainCheck"))
                    object.domainCheck = $root.ca2domains.DomainCheckWsEvent.toObject(message.domainCheck, options);
                if (message.domains != null && message.hasOwnProperty("domains"))
                    object.domains = $root.ca2domains.DomainEvent.toObject(message.domains, options);
                return object;
            };
    
            /**
             * Converts this WsEvent to JSON.
             * @function toJSON
             * @memberof ca2.WsEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WsEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for WsEvent
             * @function getTypeUrl
             * @memberof ca2.WsEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WsEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2.WsEvent";
            };
    
            return WsEvent;
        })();
    
        return ca2;
    })();
    
    $root.ca2billing = (function() {
    
        /**
         * Namespace ca2billing.
         * @exports ca2billing
         * @namespace
         */
        var ca2billing = {};
    
        ca2billing.WsEvent = (function() {
    
            /**
             * Properties of a WsEvent.
             * @memberof ca2billing
             * @interface IWsEvent
             * @property {Array.<ca2types.IBalance>|null} [balances] WsEvent balances
             * @property {Array.<ca2types.IPayment>|null} [payments] WsEvent payments
             */
    
            /**
             * Constructs a new WsEvent.
             * @memberof ca2billing
             * @classdesc Represents a WsEvent.
             * @implements IWsEvent
             * @constructor
             * @param {ca2billing.IWsEvent=} [properties] Properties to set
             */
            function WsEvent(properties) {
                this.balances = [];
                this.payments = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * WsEvent balances.
             * @member {Array.<ca2types.IBalance>} balances
             * @memberof ca2billing.WsEvent
             * @instance
             */
            WsEvent.prototype.balances = $util.emptyArray;
    
            /**
             * WsEvent payments.
             * @member {Array.<ca2types.IPayment>} payments
             * @memberof ca2billing.WsEvent
             * @instance
             */
            WsEvent.prototype.payments = $util.emptyArray;
    
            /**
             * Creates a new WsEvent instance using the specified properties.
             * @function create
             * @memberof ca2billing.WsEvent
             * @static
             * @param {ca2billing.IWsEvent=} [properties] Properties to set
             * @returns {ca2billing.WsEvent} WsEvent instance
             */
            WsEvent.create = function create(properties) {
                return new WsEvent(properties);
            };
    
            /**
             * Encodes the specified WsEvent message. Does not implicitly {@link ca2billing.WsEvent.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.WsEvent
             * @static
             * @param {ca2billing.IWsEvent} message WsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balances != null && message.balances.length)
                    for (var i = 0; i < message.balances.length; ++i)
                        $root.ca2types.Balance.encode(message.balances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.payments != null && message.payments.length)
                    for (var i = 0; i < message.payments.length; ++i)
                        $root.ca2types.Payment.encode(message.payments[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified WsEvent message, length delimited. Does not implicitly {@link ca2billing.WsEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.WsEvent
             * @static
             * @param {ca2billing.IWsEvent} message WsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a WsEvent message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.WsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.WsEvent} WsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.WsEvent();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.balances && message.balances.length))
                                message.balances = [];
                            message.balances.push($root.ca2types.Balance.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.payments && message.payments.length))
                                message.payments = [];
                            message.payments.push($root.ca2types.Payment.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a WsEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.WsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.WsEvent} WsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a WsEvent message.
             * @function verify
             * @memberof ca2billing.WsEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WsEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (!Array.isArray(message.balances))
                        return "balances: array expected";
                    for (var i = 0; i < message.balances.length; ++i) {
                        var error = $root.ca2types.Balance.verify(message.balances[i]);
                        if (error)
                            return "balances." + error;
                    }
                }
                if (message.payments != null && message.hasOwnProperty("payments")) {
                    if (!Array.isArray(message.payments))
                        return "payments: array expected";
                    for (var i = 0; i < message.payments.length; ++i) {
                        var error = $root.ca2types.Payment.verify(message.payments[i]);
                        if (error)
                            return "payments." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a WsEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.WsEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.WsEvent} WsEvent
             */
            WsEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.WsEvent)
                    return object;
                var message = new $root.ca2billing.WsEvent();
                if (object.balances) {
                    if (!Array.isArray(object.balances))
                        throw TypeError(".ca2billing.WsEvent.balances: array expected");
                    message.balances = [];
                    for (var i = 0; i < object.balances.length; ++i) {
                        if (typeof object.balances[i] !== "object")
                            throw TypeError(".ca2billing.WsEvent.balances: object expected");
                        message.balances[i] = $root.ca2types.Balance.fromObject(object.balances[i]);
                    }
                }
                if (object.payments) {
                    if (!Array.isArray(object.payments))
                        throw TypeError(".ca2billing.WsEvent.payments: array expected");
                    message.payments = [];
                    for (var i = 0; i < object.payments.length; ++i) {
                        if (typeof object.payments[i] !== "object")
                            throw TypeError(".ca2billing.WsEvent.payments: object expected");
                        message.payments[i] = $root.ca2types.Payment.fromObject(object.payments[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a WsEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.WsEvent
             * @static
             * @param {ca2billing.WsEvent} message WsEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WsEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.balances = [];
                    object.payments = [];
                }
                if (message.balances && message.balances.length) {
                    object.balances = [];
                    for (var j = 0; j < message.balances.length; ++j)
                        object.balances[j] = $root.ca2types.Balance.toObject(message.balances[j], options);
                }
                if (message.payments && message.payments.length) {
                    object.payments = [];
                    for (var j = 0; j < message.payments.length; ++j)
                        object.payments[j] = $root.ca2types.Payment.toObject(message.payments[j], options);
                }
                return object;
            };
    
            /**
             * Converts this WsEvent to JSON.
             * @function toJSON
             * @memberof ca2billing.WsEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WsEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for WsEvent
             * @function getTypeUrl
             * @memberof ca2billing.WsEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WsEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.WsEvent";
            };
    
            return WsEvent;
        })();
    
        ca2billing.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2billing
             * @interface IRequest
             * @property {ca2billing.IApplyPromoCodeRequest|null} [applyPromoCode] Request applyPromoCode
             * @property {ca2billing.IBalanceOperationsRequest|null} [balanceOperations] Request balanceOperations
             * @property {ca2billing.IBalanceSettingsRequest|null} [settings] Request settings
             * @property {ca2billing.IBalancesRequest|null} [balances] Request balances
             * @property {ca2billing.IBonusWithdrawRequest|null} [bonusWithdraw] Request bonusWithdraw
             * @property {ca2billing.ITopUpBalanceRequest|null} [topUpBalance] Request topUpBalance
             * @property {ca2billing.ICurrenciesRequest|null} [currencies] Request currencies
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2billing
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2billing.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request applyPromoCode.
             * @member {ca2billing.IApplyPromoCodeRequest|null|undefined} applyPromoCode
             * @memberof ca2billing.Request
             * @instance
             */
            Request.prototype.applyPromoCode = null;
    
            /**
             * Request balanceOperations.
             * @member {ca2billing.IBalanceOperationsRequest|null|undefined} balanceOperations
             * @memberof ca2billing.Request
             * @instance
             */
            Request.prototype.balanceOperations = null;
    
            /**
             * Request settings.
             * @member {ca2billing.IBalanceSettingsRequest|null|undefined} settings
             * @memberof ca2billing.Request
             * @instance
             */
            Request.prototype.settings = null;
    
            /**
             * Request balances.
             * @member {ca2billing.IBalancesRequest|null|undefined} balances
             * @memberof ca2billing.Request
             * @instance
             */
            Request.prototype.balances = null;
    
            /**
             * Request bonusWithdraw.
             * @member {ca2billing.IBonusWithdrawRequest|null|undefined} bonusWithdraw
             * @memberof ca2billing.Request
             * @instance
             */
            Request.prototype.bonusWithdraw = null;
    
            /**
             * Request topUpBalance.
             * @member {ca2billing.ITopUpBalanceRequest|null|undefined} topUpBalance
             * @memberof ca2billing.Request
             * @instance
             */
            Request.prototype.topUpBalance = null;
    
            /**
             * Request currencies.
             * @member {ca2billing.ICurrenciesRequest|null|undefined} currencies
             * @memberof ca2billing.Request
             * @instance
             */
            Request.prototype.currencies = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2billing.Request
             * @static
             * @param {ca2billing.IRequest=} [properties] Properties to set
             * @returns {ca2billing.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2billing.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.Request
             * @static
             * @param {ca2billing.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balanceOperations != null && Object.hasOwnProperty.call(message, "balanceOperations"))
                    $root.ca2billing.BalanceOperationsRequest.encode(message.balanceOperations, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.balances != null && Object.hasOwnProperty.call(message, "balances"))
                    $root.ca2billing.BalancesRequest.encode(message.balances, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.bonusWithdraw != null && Object.hasOwnProperty.call(message, "bonusWithdraw"))
                    $root.ca2billing.BonusWithdrawRequest.encode(message.bonusWithdraw, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.applyPromoCode != null && Object.hasOwnProperty.call(message, "applyPromoCode"))
                    $root.ca2billing.ApplyPromoCodeRequest.encode(message.applyPromoCode, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.topUpBalance != null && Object.hasOwnProperty.call(message, "topUpBalance"))
                    $root.ca2billing.TopUpBalanceRequest.encode(message.topUpBalance, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.ca2billing.BalanceSettingsRequest.encode(message.settings, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.currencies != null && Object.hasOwnProperty.call(message, "currencies"))
                    $root.ca2billing.CurrenciesRequest.encode(message.currencies, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2billing.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.Request
             * @static
             * @param {ca2billing.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 5: {
                            message.applyPromoCode = $root.ca2billing.ApplyPromoCodeRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1: {
                            message.balanceOperations = $root.ca2billing.BalanceOperationsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.settings = $root.ca2billing.BalanceSettingsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.balances = $root.ca2billing.BalancesRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.bonusWithdraw = $root.ca2billing.BonusWithdrawRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.topUpBalance = $root.ca2billing.TopUpBalanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.currencies = $root.ca2billing.CurrenciesRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2billing.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.applyPromoCode != null && message.hasOwnProperty("applyPromoCode")) {
                    var error = $root.ca2billing.ApplyPromoCodeRequest.verify(message.applyPromoCode);
                    if (error)
                        return "applyPromoCode." + error;
                }
                if (message.balanceOperations != null && message.hasOwnProperty("balanceOperations")) {
                    var error = $root.ca2billing.BalanceOperationsRequest.verify(message.balanceOperations);
                    if (error)
                        return "balanceOperations." + error;
                }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    var error = $root.ca2billing.BalanceSettingsRequest.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    var error = $root.ca2billing.BalancesRequest.verify(message.balances);
                    if (error)
                        return "balances." + error;
                }
                if (message.bonusWithdraw != null && message.hasOwnProperty("bonusWithdraw")) {
                    var error = $root.ca2billing.BonusWithdrawRequest.verify(message.bonusWithdraw);
                    if (error)
                        return "bonusWithdraw." + error;
                }
                if (message.topUpBalance != null && message.hasOwnProperty("topUpBalance")) {
                    var error = $root.ca2billing.TopUpBalanceRequest.verify(message.topUpBalance);
                    if (error)
                        return "topUpBalance." + error;
                }
                if (message.currencies != null && message.hasOwnProperty("currencies")) {
                    var error = $root.ca2billing.CurrenciesRequest.verify(message.currencies);
                    if (error)
                        return "currencies." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.Request)
                    return object;
                var message = new $root.ca2billing.Request();
                if (object.applyPromoCode != null) {
                    if (typeof object.applyPromoCode !== "object")
                        throw TypeError(".ca2billing.Request.applyPromoCode: object expected");
                    message.applyPromoCode = $root.ca2billing.ApplyPromoCodeRequest.fromObject(object.applyPromoCode);
                }
                if (object.balanceOperations != null) {
                    if (typeof object.balanceOperations !== "object")
                        throw TypeError(".ca2billing.Request.balanceOperations: object expected");
                    message.balanceOperations = $root.ca2billing.BalanceOperationsRequest.fromObject(object.balanceOperations);
                }
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".ca2billing.Request.settings: object expected");
                    message.settings = $root.ca2billing.BalanceSettingsRequest.fromObject(object.settings);
                }
                if (object.balances != null) {
                    if (typeof object.balances !== "object")
                        throw TypeError(".ca2billing.Request.balances: object expected");
                    message.balances = $root.ca2billing.BalancesRequest.fromObject(object.balances);
                }
                if (object.bonusWithdraw != null) {
                    if (typeof object.bonusWithdraw !== "object")
                        throw TypeError(".ca2billing.Request.bonusWithdraw: object expected");
                    message.bonusWithdraw = $root.ca2billing.BonusWithdrawRequest.fromObject(object.bonusWithdraw);
                }
                if (object.topUpBalance != null) {
                    if (typeof object.topUpBalance !== "object")
                        throw TypeError(".ca2billing.Request.topUpBalance: object expected");
                    message.topUpBalance = $root.ca2billing.TopUpBalanceRequest.fromObject(object.topUpBalance);
                }
                if (object.currencies != null) {
                    if (typeof object.currencies !== "object")
                        throw TypeError(".ca2billing.Request.currencies: object expected");
                    message.currencies = $root.ca2billing.CurrenciesRequest.fromObject(object.currencies);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.Request
             * @static
             * @param {ca2billing.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.balanceOperations = null;
                    object.balances = null;
                    object.bonusWithdraw = null;
                    object.applyPromoCode = null;
                    object.topUpBalance = null;
                    object.settings = null;
                    object.currencies = null;
                }
                if (message.balanceOperations != null && message.hasOwnProperty("balanceOperations"))
                    object.balanceOperations = $root.ca2billing.BalanceOperationsRequest.toObject(message.balanceOperations, options);
                if (message.balances != null && message.hasOwnProperty("balances"))
                    object.balances = $root.ca2billing.BalancesRequest.toObject(message.balances, options);
                if (message.bonusWithdraw != null && message.hasOwnProperty("bonusWithdraw"))
                    object.bonusWithdraw = $root.ca2billing.BonusWithdrawRequest.toObject(message.bonusWithdraw, options);
                if (message.applyPromoCode != null && message.hasOwnProperty("applyPromoCode"))
                    object.applyPromoCode = $root.ca2billing.ApplyPromoCodeRequest.toObject(message.applyPromoCode, options);
                if (message.topUpBalance != null && message.hasOwnProperty("topUpBalance"))
                    object.topUpBalance = $root.ca2billing.TopUpBalanceRequest.toObject(message.topUpBalance, options);
                if (message.settings != null && message.hasOwnProperty("settings"))
                    object.settings = $root.ca2billing.BalanceSettingsRequest.toObject(message.settings, options);
                if (message.currencies != null && message.hasOwnProperty("currencies"))
                    object.currencies = $root.ca2billing.CurrenciesRequest.toObject(message.currencies, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2billing.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2billing.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.Request";
            };
    
            return Request;
        })();
    
        ca2billing.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2billing
             * @interface IResponse
             * @property {ca2billing.IApplyPromoCodeResponse|null} [applyPromoCode] Response applyPromoCode
             * @property {ca2billing.IBalanceOperationsResponse|null} [balanceOperations] Response balanceOperations
             * @property {ca2billing.IBalanceSettingsResponse|null} [settings] Response settings
             * @property {ca2billing.IBalancesResponse|null} [balances] Response balances
             * @property {ca2billing.IBonusWithdrawResponse|null} [bonusWithdraw] Response bonusWithdraw
             * @property {ca2billing.ITopUpBalanceResponse|null} [topUpBalance] Response topUpBalance
             * @property {ca2billing.ICurrenciesResponse|null} [currencies] Response currencies
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2billing
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2billing.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response applyPromoCode.
             * @member {ca2billing.IApplyPromoCodeResponse|null|undefined} applyPromoCode
             * @memberof ca2billing.Response
             * @instance
             */
            Response.prototype.applyPromoCode = null;
    
            /**
             * Response balanceOperations.
             * @member {ca2billing.IBalanceOperationsResponse|null|undefined} balanceOperations
             * @memberof ca2billing.Response
             * @instance
             */
            Response.prototype.balanceOperations = null;
    
            /**
             * Response settings.
             * @member {ca2billing.IBalanceSettingsResponse|null|undefined} settings
             * @memberof ca2billing.Response
             * @instance
             */
            Response.prototype.settings = null;
    
            /**
             * Response balances.
             * @member {ca2billing.IBalancesResponse|null|undefined} balances
             * @memberof ca2billing.Response
             * @instance
             */
            Response.prototype.balances = null;
    
            /**
             * Response bonusWithdraw.
             * @member {ca2billing.IBonusWithdrawResponse|null|undefined} bonusWithdraw
             * @memberof ca2billing.Response
             * @instance
             */
            Response.prototype.bonusWithdraw = null;
    
            /**
             * Response topUpBalance.
             * @member {ca2billing.ITopUpBalanceResponse|null|undefined} topUpBalance
             * @memberof ca2billing.Response
             * @instance
             */
            Response.prototype.topUpBalance = null;
    
            /**
             * Response currencies.
             * @member {ca2billing.ICurrenciesResponse|null|undefined} currencies
             * @memberof ca2billing.Response
             * @instance
             */
            Response.prototype.currencies = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2billing.Response
             * @static
             * @param {ca2billing.IResponse=} [properties] Properties to set
             * @returns {ca2billing.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2billing.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.Response
             * @static
             * @param {ca2billing.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balanceOperations != null && Object.hasOwnProperty.call(message, "balanceOperations"))
                    $root.ca2billing.BalanceOperationsResponse.encode(message.balanceOperations, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.balances != null && Object.hasOwnProperty.call(message, "balances"))
                    $root.ca2billing.BalancesResponse.encode(message.balances, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.bonusWithdraw != null && Object.hasOwnProperty.call(message, "bonusWithdraw"))
                    $root.ca2billing.BonusWithdrawResponse.encode(message.bonusWithdraw, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.applyPromoCode != null && Object.hasOwnProperty.call(message, "applyPromoCode"))
                    $root.ca2billing.ApplyPromoCodeResponse.encode(message.applyPromoCode, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.topUpBalance != null && Object.hasOwnProperty.call(message, "topUpBalance"))
                    $root.ca2billing.TopUpBalanceResponse.encode(message.topUpBalance, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.ca2billing.BalanceSettingsResponse.encode(message.settings, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.currencies != null && Object.hasOwnProperty.call(message, "currencies"))
                    $root.ca2billing.CurrenciesResponse.encode(message.currencies, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2billing.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.Response
             * @static
             * @param {ca2billing.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 5: {
                            message.applyPromoCode = $root.ca2billing.ApplyPromoCodeResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1: {
                            message.balanceOperations = $root.ca2billing.BalanceOperationsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.settings = $root.ca2billing.BalanceSettingsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.balances = $root.ca2billing.BalancesResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.bonusWithdraw = $root.ca2billing.BonusWithdrawResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.topUpBalance = $root.ca2billing.TopUpBalanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.currencies = $root.ca2billing.CurrenciesResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2billing.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.applyPromoCode != null && message.hasOwnProperty("applyPromoCode")) {
                    var error = $root.ca2billing.ApplyPromoCodeResponse.verify(message.applyPromoCode);
                    if (error)
                        return "applyPromoCode." + error;
                }
                if (message.balanceOperations != null && message.hasOwnProperty("balanceOperations")) {
                    var error = $root.ca2billing.BalanceOperationsResponse.verify(message.balanceOperations);
                    if (error)
                        return "balanceOperations." + error;
                }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    var error = $root.ca2billing.BalanceSettingsResponse.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    var error = $root.ca2billing.BalancesResponse.verify(message.balances);
                    if (error)
                        return "balances." + error;
                }
                if (message.bonusWithdraw != null && message.hasOwnProperty("bonusWithdraw")) {
                    var error = $root.ca2billing.BonusWithdrawResponse.verify(message.bonusWithdraw);
                    if (error)
                        return "bonusWithdraw." + error;
                }
                if (message.topUpBalance != null && message.hasOwnProperty("topUpBalance")) {
                    var error = $root.ca2billing.TopUpBalanceResponse.verify(message.topUpBalance);
                    if (error)
                        return "topUpBalance." + error;
                }
                if (message.currencies != null && message.hasOwnProperty("currencies")) {
                    var error = $root.ca2billing.CurrenciesResponse.verify(message.currencies);
                    if (error)
                        return "currencies." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.Response)
                    return object;
                var message = new $root.ca2billing.Response();
                if (object.applyPromoCode != null) {
                    if (typeof object.applyPromoCode !== "object")
                        throw TypeError(".ca2billing.Response.applyPromoCode: object expected");
                    message.applyPromoCode = $root.ca2billing.ApplyPromoCodeResponse.fromObject(object.applyPromoCode);
                }
                if (object.balanceOperations != null) {
                    if (typeof object.balanceOperations !== "object")
                        throw TypeError(".ca2billing.Response.balanceOperations: object expected");
                    message.balanceOperations = $root.ca2billing.BalanceOperationsResponse.fromObject(object.balanceOperations);
                }
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".ca2billing.Response.settings: object expected");
                    message.settings = $root.ca2billing.BalanceSettingsResponse.fromObject(object.settings);
                }
                if (object.balances != null) {
                    if (typeof object.balances !== "object")
                        throw TypeError(".ca2billing.Response.balances: object expected");
                    message.balances = $root.ca2billing.BalancesResponse.fromObject(object.balances);
                }
                if (object.bonusWithdraw != null) {
                    if (typeof object.bonusWithdraw !== "object")
                        throw TypeError(".ca2billing.Response.bonusWithdraw: object expected");
                    message.bonusWithdraw = $root.ca2billing.BonusWithdrawResponse.fromObject(object.bonusWithdraw);
                }
                if (object.topUpBalance != null) {
                    if (typeof object.topUpBalance !== "object")
                        throw TypeError(".ca2billing.Response.topUpBalance: object expected");
                    message.topUpBalance = $root.ca2billing.TopUpBalanceResponse.fromObject(object.topUpBalance);
                }
                if (object.currencies != null) {
                    if (typeof object.currencies !== "object")
                        throw TypeError(".ca2billing.Response.currencies: object expected");
                    message.currencies = $root.ca2billing.CurrenciesResponse.fromObject(object.currencies);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.Response
             * @static
             * @param {ca2billing.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.balanceOperations = null;
                    object.balances = null;
                    object.bonusWithdraw = null;
                    object.applyPromoCode = null;
                    object.topUpBalance = null;
                    object.settings = null;
                    object.currencies = null;
                }
                if (message.balanceOperations != null && message.hasOwnProperty("balanceOperations"))
                    object.balanceOperations = $root.ca2billing.BalanceOperationsResponse.toObject(message.balanceOperations, options);
                if (message.balances != null && message.hasOwnProperty("balances"))
                    object.balances = $root.ca2billing.BalancesResponse.toObject(message.balances, options);
                if (message.bonusWithdraw != null && message.hasOwnProperty("bonusWithdraw"))
                    object.bonusWithdraw = $root.ca2billing.BonusWithdrawResponse.toObject(message.bonusWithdraw, options);
                if (message.applyPromoCode != null && message.hasOwnProperty("applyPromoCode"))
                    object.applyPromoCode = $root.ca2billing.ApplyPromoCodeResponse.toObject(message.applyPromoCode, options);
                if (message.topUpBalance != null && message.hasOwnProperty("topUpBalance"))
                    object.topUpBalance = $root.ca2billing.TopUpBalanceResponse.toObject(message.topUpBalance, options);
                if (message.settings != null && message.hasOwnProperty("settings"))
                    object.settings = $root.ca2billing.BalanceSettingsResponse.toObject(message.settings, options);
                if (message.currencies != null && message.hasOwnProperty("currencies"))
                    object.currencies = $root.ca2billing.CurrenciesResponse.toObject(message.currencies, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2billing.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2billing.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.Response";
            };
    
            return Response;
        })();
    
        ca2billing.BalanceSettingsRequest = (function() {
    
            /**
             * Properties of a BalanceSettingsRequest.
             * @memberof ca2billing
             * @interface IBalanceSettingsRequest
             */
    
            /**
             * Constructs a new BalanceSettingsRequest.
             * @memberof ca2billing
             * @classdesc Represents a BalanceSettingsRequest.
             * @implements IBalanceSettingsRequest
             * @constructor
             * @param {ca2billing.IBalanceSettingsRequest=} [properties] Properties to set
             */
            function BalanceSettingsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new BalanceSettingsRequest instance using the specified properties.
             * @function create
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {ca2billing.IBalanceSettingsRequest=} [properties] Properties to set
             * @returns {ca2billing.BalanceSettingsRequest} BalanceSettingsRequest instance
             */
            BalanceSettingsRequest.create = function create(properties) {
                return new BalanceSettingsRequest(properties);
            };
    
            /**
             * Encodes the specified BalanceSettingsRequest message. Does not implicitly {@link ca2billing.BalanceSettingsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {ca2billing.IBalanceSettingsRequest} message BalanceSettingsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceSettingsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified BalanceSettingsRequest message, length delimited. Does not implicitly {@link ca2billing.BalanceSettingsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {ca2billing.IBalanceSettingsRequest} message BalanceSettingsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalanceSettingsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BalanceSettingsRequest} BalanceSettingsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceSettingsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BalanceSettingsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalanceSettingsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BalanceSettingsRequest} BalanceSettingsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalanceSettingsRequest message.
             * @function verify
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalanceSettingsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a BalanceSettingsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BalanceSettingsRequest} BalanceSettingsRequest
             */
            BalanceSettingsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BalanceSettingsRequest)
                    return object;
                return new $root.ca2billing.BalanceSettingsRequest();
            };
    
            /**
             * Creates a plain object from a BalanceSettingsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {ca2billing.BalanceSettingsRequest} message BalanceSettingsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalanceSettingsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this BalanceSettingsRequest to JSON.
             * @function toJSON
             * @memberof ca2billing.BalanceSettingsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalanceSettingsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalanceSettingsRequest
             * @function getTypeUrl
             * @memberof ca2billing.BalanceSettingsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalanceSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BalanceSettingsRequest";
            };
    
            return BalanceSettingsRequest;
        })();
    
        ca2billing.BalanceSettingsResponse = (function() {
    
            /**
             * Properties of a BalanceSettingsResponse.
             * @memberof ca2billing
             * @interface IBalanceSettingsResponse
             * @property {ca2types.IAmount|null} [minAmountToTransferFromBonusToPrimary] BalanceSettingsResponse minAmountToTransferFromBonusToPrimary
             */
    
            /**
             * Constructs a new BalanceSettingsResponse.
             * @memberof ca2billing
             * @classdesc Represents a BalanceSettingsResponse.
             * @implements IBalanceSettingsResponse
             * @constructor
             * @param {ca2billing.IBalanceSettingsResponse=} [properties] Properties to set
             */
            function BalanceSettingsResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalanceSettingsResponse minAmountToTransferFromBonusToPrimary.
             * @member {ca2types.IAmount|null|undefined} minAmountToTransferFromBonusToPrimary
             * @memberof ca2billing.BalanceSettingsResponse
             * @instance
             */
            BalanceSettingsResponse.prototype.minAmountToTransferFromBonusToPrimary = null;
    
            /**
             * Creates a new BalanceSettingsResponse instance using the specified properties.
             * @function create
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {ca2billing.IBalanceSettingsResponse=} [properties] Properties to set
             * @returns {ca2billing.BalanceSettingsResponse} BalanceSettingsResponse instance
             */
            BalanceSettingsResponse.create = function create(properties) {
                return new BalanceSettingsResponse(properties);
            };
    
            /**
             * Encodes the specified BalanceSettingsResponse message. Does not implicitly {@link ca2billing.BalanceSettingsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {ca2billing.IBalanceSettingsResponse} message BalanceSettingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceSettingsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.minAmountToTransferFromBonusToPrimary != null && Object.hasOwnProperty.call(message, "minAmountToTransferFromBonusToPrimary"))
                    $root.ca2types.Amount.encode(message.minAmountToTransferFromBonusToPrimary, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BalanceSettingsResponse message, length delimited. Does not implicitly {@link ca2billing.BalanceSettingsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {ca2billing.IBalanceSettingsResponse} message BalanceSettingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalanceSettingsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BalanceSettingsResponse} BalanceSettingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceSettingsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BalanceSettingsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 4: {
                            message.minAmountToTransferFromBonusToPrimary = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalanceSettingsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BalanceSettingsResponse} BalanceSettingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalanceSettingsResponse message.
             * @function verify
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalanceSettingsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.minAmountToTransferFromBonusToPrimary != null && message.hasOwnProperty("minAmountToTransferFromBonusToPrimary")) {
                    var error = $root.ca2types.Amount.verify(message.minAmountToTransferFromBonusToPrimary);
                    if (error)
                        return "minAmountToTransferFromBonusToPrimary." + error;
                }
                return null;
            };
    
            /**
             * Creates a BalanceSettingsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BalanceSettingsResponse} BalanceSettingsResponse
             */
            BalanceSettingsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BalanceSettingsResponse)
                    return object;
                var message = new $root.ca2billing.BalanceSettingsResponse();
                if (object.minAmountToTransferFromBonusToPrimary != null) {
                    if (typeof object.minAmountToTransferFromBonusToPrimary !== "object")
                        throw TypeError(".ca2billing.BalanceSettingsResponse.minAmountToTransferFromBonusToPrimary: object expected");
                    message.minAmountToTransferFromBonusToPrimary = $root.ca2types.Amount.fromObject(object.minAmountToTransferFromBonusToPrimary);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BalanceSettingsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {ca2billing.BalanceSettingsResponse} message BalanceSettingsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalanceSettingsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.minAmountToTransferFromBonusToPrimary = null;
                if (message.minAmountToTransferFromBonusToPrimary != null && message.hasOwnProperty("minAmountToTransferFromBonusToPrimary"))
                    object.minAmountToTransferFromBonusToPrimary = $root.ca2types.Amount.toObject(message.minAmountToTransferFromBonusToPrimary, options);
                return object;
            };
    
            /**
             * Converts this BalanceSettingsResponse to JSON.
             * @function toJSON
             * @memberof ca2billing.BalanceSettingsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalanceSettingsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalanceSettingsResponse
             * @function getTypeUrl
             * @memberof ca2billing.BalanceSettingsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalanceSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BalanceSettingsResponse";
            };
    
            return BalanceSettingsResponse;
        })();
    
        ca2billing.BalancesRequest = (function() {
    
            /**
             * Properties of a BalancesRequest.
             * @memberof ca2billing
             * @interface IBalancesRequest
             */
    
            /**
             * Constructs a new BalancesRequest.
             * @memberof ca2billing
             * @classdesc Represents a BalancesRequest.
             * @implements IBalancesRequest
             * @constructor
             * @param {ca2billing.IBalancesRequest=} [properties] Properties to set
             */
            function BalancesRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new BalancesRequest instance using the specified properties.
             * @function create
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {ca2billing.IBalancesRequest=} [properties] Properties to set
             * @returns {ca2billing.BalancesRequest} BalancesRequest instance
             */
            BalancesRequest.create = function create(properties) {
                return new BalancesRequest(properties);
            };
    
            /**
             * Encodes the specified BalancesRequest message. Does not implicitly {@link ca2billing.BalancesRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {ca2billing.IBalancesRequest} message BalancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified BalancesRequest message, length delimited. Does not implicitly {@link ca2billing.BalancesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {ca2billing.IBalancesRequest} message BalancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalancesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BalancesRequest} BalancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BalancesRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalancesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BalancesRequest} BalancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalancesRequest message.
             * @function verify
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalancesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a BalancesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BalancesRequest} BalancesRequest
             */
            BalancesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BalancesRequest)
                    return object;
                return new $root.ca2billing.BalancesRequest();
            };
    
            /**
             * Creates a plain object from a BalancesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {ca2billing.BalancesRequest} message BalancesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalancesRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this BalancesRequest to JSON.
             * @function toJSON
             * @memberof ca2billing.BalancesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalancesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalancesRequest
             * @function getTypeUrl
             * @memberof ca2billing.BalancesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalancesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BalancesRequest";
            };
    
            return BalancesRequest;
        })();
    
        ca2billing.BalancesResponse = (function() {
    
            /**
             * Properties of a BalancesResponse.
             * @memberof ca2billing
             * @interface IBalancesResponse
             * @property {Array.<ca2types.IBalance>|null} [items] BalancesResponse items
             */
    
            /**
             * Constructs a new BalancesResponse.
             * @memberof ca2billing
             * @classdesc Represents a BalancesResponse.
             * @implements IBalancesResponse
             * @constructor
             * @param {ca2billing.IBalancesResponse=} [properties] Properties to set
             */
            function BalancesResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalancesResponse items.
             * @member {Array.<ca2types.IBalance>} items
             * @memberof ca2billing.BalancesResponse
             * @instance
             */
            BalancesResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new BalancesResponse instance using the specified properties.
             * @function create
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {ca2billing.IBalancesResponse=} [properties] Properties to set
             * @returns {ca2billing.BalancesResponse} BalancesResponse instance
             */
            BalancesResponse.create = function create(properties) {
                return new BalancesResponse(properties);
            };
    
            /**
             * Encodes the specified BalancesResponse message. Does not implicitly {@link ca2billing.BalancesResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {ca2billing.IBalancesResponse} message BalancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Balance.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BalancesResponse message, length delimited. Does not implicitly {@link ca2billing.BalancesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {ca2billing.IBalancesResponse} message BalancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalancesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BalancesResponse} BalancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BalancesResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Balance.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalancesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BalancesResponse} BalancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalancesResponse message.
             * @function verify
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalancesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Balance.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a BalancesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BalancesResponse} BalancesResponse
             */
            BalancesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BalancesResponse)
                    return object;
                var message = new $root.ca2billing.BalancesResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2billing.BalancesResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2billing.BalancesResponse.items: object expected");
                        message.items[i] = $root.ca2types.Balance.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BalancesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {ca2billing.BalancesResponse} message BalancesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalancesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Balance.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this BalancesResponse to JSON.
             * @function toJSON
             * @memberof ca2billing.BalancesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalancesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalancesResponse
             * @function getTypeUrl
             * @memberof ca2billing.BalancesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalancesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BalancesResponse";
            };
    
            return BalancesResponse;
        })();
    
        /**
         * BonusWithdrawType enum.
         * @name ca2billing.BonusWithdrawType
         * @enum {number}
         * @property {number} BWT_PRIMARY_BALANCE=0 BWT_PRIMARY_BALANCE value
         * @property {number} BTW_CASH_OUT=1 BTW_CASH_OUT value
         */
        ca2billing.BonusWithdrawType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BWT_PRIMARY_BALANCE"] = 0;
            values[valuesById[1] = "BTW_CASH_OUT"] = 1;
            return values;
        })();
    
        ca2billing.BonusWithdrawRequest = (function() {
    
            /**
             * Properties of a BonusWithdrawRequest.
             * @memberof ca2billing
             * @interface IBonusWithdrawRequest
             * @property {ca2billing.BonusWithdrawType|null} [type] BonusWithdrawRequest type
             * @property {number|null} [amountToWithdrawUsd] BonusWithdrawRequest amountToWithdrawUsd
             */
    
            /**
             * Constructs a new BonusWithdrawRequest.
             * @memberof ca2billing
             * @classdesc Represents a BonusWithdrawRequest.
             * @implements IBonusWithdrawRequest
             * @constructor
             * @param {ca2billing.IBonusWithdrawRequest=} [properties] Properties to set
             */
            function BonusWithdrawRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BonusWithdrawRequest type.
             * @member {ca2billing.BonusWithdrawType} type
             * @memberof ca2billing.BonusWithdrawRequest
             * @instance
             */
            BonusWithdrawRequest.prototype.type = 0;
    
            /**
             * BonusWithdrawRequest amountToWithdrawUsd.
             * @member {number} amountToWithdrawUsd
             * @memberof ca2billing.BonusWithdrawRequest
             * @instance
             */
            BonusWithdrawRequest.prototype.amountToWithdrawUsd = 0;
    
            /**
             * Creates a new BonusWithdrawRequest instance using the specified properties.
             * @function create
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {ca2billing.IBonusWithdrawRequest=} [properties] Properties to set
             * @returns {ca2billing.BonusWithdrawRequest} BonusWithdrawRequest instance
             */
            BonusWithdrawRequest.create = function create(properties) {
                return new BonusWithdrawRequest(properties);
            };
    
            /**
             * Encodes the specified BonusWithdrawRequest message. Does not implicitly {@link ca2billing.BonusWithdrawRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {ca2billing.IBonusWithdrawRequest} message BonusWithdrawRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BonusWithdrawRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.amountToWithdrawUsd != null && Object.hasOwnProperty.call(message, "amountToWithdrawUsd"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.amountToWithdrawUsd);
                return writer;
            };
    
            /**
             * Encodes the specified BonusWithdrawRequest message, length delimited. Does not implicitly {@link ca2billing.BonusWithdrawRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {ca2billing.IBonusWithdrawRequest} message BonusWithdrawRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BonusWithdrawRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BonusWithdrawRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BonusWithdrawRequest} BonusWithdrawRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BonusWithdrawRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BonusWithdrawRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.amountToWithdrawUsd = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BonusWithdrawRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BonusWithdrawRequest} BonusWithdrawRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BonusWithdrawRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BonusWithdrawRequest message.
             * @function verify
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BonusWithdrawRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.amountToWithdrawUsd != null && message.hasOwnProperty("amountToWithdrawUsd"))
                    if (!$util.isInteger(message.amountToWithdrawUsd))
                        return "amountToWithdrawUsd: integer expected";
                return null;
            };
    
            /**
             * Creates a BonusWithdrawRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BonusWithdrawRequest} BonusWithdrawRequest
             */
            BonusWithdrawRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BonusWithdrawRequest)
                    return object;
                var message = new $root.ca2billing.BonusWithdrawRequest();
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "BWT_PRIMARY_BALANCE":
                case 0:
                    message.type = 0;
                    break;
                case "BTW_CASH_OUT":
                case 1:
                    message.type = 1;
                    break;
                }
                if (object.amountToWithdrawUsd != null)
                    message.amountToWithdrawUsd = object.amountToWithdrawUsd >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a BonusWithdrawRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {ca2billing.BonusWithdrawRequest} message BonusWithdrawRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BonusWithdrawRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "BWT_PRIMARY_BALANCE" : 0;
                    object.amountToWithdrawUsd = 0;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.ca2billing.BonusWithdrawType[message.type] === undefined ? message.type : $root.ca2billing.BonusWithdrawType[message.type] : message.type;
                if (message.amountToWithdrawUsd != null && message.hasOwnProperty("amountToWithdrawUsd"))
                    object.amountToWithdrawUsd = message.amountToWithdrawUsd;
                return object;
            };
    
            /**
             * Converts this BonusWithdrawRequest to JSON.
             * @function toJSON
             * @memberof ca2billing.BonusWithdrawRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BonusWithdrawRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BonusWithdrawRequest
             * @function getTypeUrl
             * @memberof ca2billing.BonusWithdrawRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BonusWithdrawRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BonusWithdrawRequest";
            };
    
            return BonusWithdrawRequest;
        })();
    
        /**
         * BonusWithdrawError enum.
         * @name ca2billing.BonusWithdrawError
         * @enum {number}
         * @property {number} BWE_UNKNOWN_ERROR=0 BWE_UNKNOWN_ERROR value
         * @property {number} BWE_NOT_ENOUGH_BONUS_BALANCE=1 BWE_NOT_ENOUGH_BONUS_BALANCE value
         * @property {number} BWE_AMOUNT_TOO_SMALL=2 BWE_AMOUNT_TOO_SMALL value
         */
        ca2billing.BonusWithdrawError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BWE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "BWE_NOT_ENOUGH_BONUS_BALANCE"] = 1;
            values[valuesById[2] = "BWE_AMOUNT_TOO_SMALL"] = 2;
            return values;
        })();
    
        ca2billing.BonusWithdrawResponse = (function() {
    
            /**
             * Properties of a BonusWithdrawResponse.
             * @memberof ca2billing
             * @interface IBonusWithdrawResponse
             * @property {Array.<ca2billing.BonusWithdrawError>|null} [errors] BonusWithdrawResponse errors
             */
    
            /**
             * Constructs a new BonusWithdrawResponse.
             * @memberof ca2billing
             * @classdesc Represents a BonusWithdrawResponse.
             * @implements IBonusWithdrawResponse
             * @constructor
             * @param {ca2billing.IBonusWithdrawResponse=} [properties] Properties to set
             */
            function BonusWithdrawResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BonusWithdrawResponse errors.
             * @member {Array.<ca2billing.BonusWithdrawError>} errors
             * @memberof ca2billing.BonusWithdrawResponse
             * @instance
             */
            BonusWithdrawResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new BonusWithdrawResponse instance using the specified properties.
             * @function create
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {ca2billing.IBonusWithdrawResponse=} [properties] Properties to set
             * @returns {ca2billing.BonusWithdrawResponse} BonusWithdrawResponse instance
             */
            BonusWithdrawResponse.create = function create(properties) {
                return new BonusWithdrawResponse(properties);
            };
    
            /**
             * Encodes the specified BonusWithdrawResponse message. Does not implicitly {@link ca2billing.BonusWithdrawResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {ca2billing.IBonusWithdrawResponse} message BonusWithdrawResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BonusWithdrawResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified BonusWithdrawResponse message, length delimited. Does not implicitly {@link ca2billing.BonusWithdrawResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {ca2billing.IBonusWithdrawResponse} message BonusWithdrawResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BonusWithdrawResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BonusWithdrawResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BonusWithdrawResponse} BonusWithdrawResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BonusWithdrawResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BonusWithdrawResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BonusWithdrawResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BonusWithdrawResponse} BonusWithdrawResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BonusWithdrawResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BonusWithdrawResponse message.
             * @function verify
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BonusWithdrawResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a BonusWithdrawResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BonusWithdrawResponse} BonusWithdrawResponse
             */
            BonusWithdrawResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BonusWithdrawResponse)
                    return object;
                var message = new $root.ca2billing.BonusWithdrawResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2billing.BonusWithdrawResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "BWE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "BWE_NOT_ENOUGH_BONUS_BALANCE":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "BWE_AMOUNT_TOO_SMALL":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BonusWithdrawResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {ca2billing.BonusWithdrawResponse} message BonusWithdrawResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BonusWithdrawResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2billing.BonusWithdrawError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2billing.BonusWithdrawError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this BonusWithdrawResponse to JSON.
             * @function toJSON
             * @memberof ca2billing.BonusWithdrawResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BonusWithdrawResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BonusWithdrawResponse
             * @function getTypeUrl
             * @memberof ca2billing.BonusWithdrawResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BonusWithdrawResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BonusWithdrawResponse";
            };
    
            return BonusWithdrawResponse;
        })();
    
        /**
         * BalanceOperationSort enum.
         * @name ca2billing.BalanceOperationSort
         * @enum {number}
         * @property {number} BOS_DEFAULT=0 BOS_DEFAULT value
         * @property {number} BOS_CREATED_AT_DESC=1 BOS_CREATED_AT_DESC value
         * @property {number} BOS_CREATED_AT_ASC=2 BOS_CREATED_AT_ASC value
         * @property {number} BOS_AMOUNT_DESC=3 BOS_AMOUNT_DESC value
         * @property {number} BOS_AMOUNT_ASC=4 BOS_AMOUNT_ASC value
         */
        ca2billing.BalanceOperationSort = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BOS_DEFAULT"] = 0;
            values[valuesById[1] = "BOS_CREATED_AT_DESC"] = 1;
            values[valuesById[2] = "BOS_CREATED_AT_ASC"] = 2;
            values[valuesById[3] = "BOS_AMOUNT_DESC"] = 3;
            values[valuesById[4] = "BOS_AMOUNT_ASC"] = 4;
            return values;
        })();
    
        ca2billing.BalanceOperationsRequest = (function() {
    
            /**
             * Properties of a BalanceOperationsRequest.
             * @memberof ca2billing
             * @interface IBalanceOperationsRequest
             * @property {number|null} [limit] BalanceOperationsRequest limit
             * @property {number|null} [offset] BalanceOperationsRequest offset
             * @property {ca2types.BalanceType|null} [balanceType] BalanceOperationsRequest balanceType
             * @property {Array.<ca2types.BalanceOperationType>|null} [operationTypes] BalanceOperationsRequest operationTypes
             * @property {ca2types.IUint64Range|null} [createdAt] BalanceOperationsRequest createdAt
             * @property {ca2billing.BalanceOperationSort|null} [sortOrder] BalanceOperationsRequest sortOrder
             */
    
            /**
             * Constructs a new BalanceOperationsRequest.
             * @memberof ca2billing
             * @classdesc Represents a BalanceOperationsRequest.
             * @implements IBalanceOperationsRequest
             * @constructor
             * @param {ca2billing.IBalanceOperationsRequest=} [properties] Properties to set
             */
            function BalanceOperationsRequest(properties) {
                this.operationTypes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalanceOperationsRequest limit.
             * @member {number} limit
             * @memberof ca2billing.BalanceOperationsRequest
             * @instance
             */
            BalanceOperationsRequest.prototype.limit = 0;
    
            /**
             * BalanceOperationsRequest offset.
             * @member {number} offset
             * @memberof ca2billing.BalanceOperationsRequest
             * @instance
             */
            BalanceOperationsRequest.prototype.offset = 0;
    
            /**
             * BalanceOperationsRequest balanceType.
             * @member {ca2types.BalanceType} balanceType
             * @memberof ca2billing.BalanceOperationsRequest
             * @instance
             */
            BalanceOperationsRequest.prototype.balanceType = 0;
    
            /**
             * BalanceOperationsRequest operationTypes.
             * @member {Array.<ca2types.BalanceOperationType>} operationTypes
             * @memberof ca2billing.BalanceOperationsRequest
             * @instance
             */
            BalanceOperationsRequest.prototype.operationTypes = $util.emptyArray;
    
            /**
             * BalanceOperationsRequest createdAt.
             * @member {ca2types.IUint64Range|null|undefined} createdAt
             * @memberof ca2billing.BalanceOperationsRequest
             * @instance
             */
            BalanceOperationsRequest.prototype.createdAt = null;
    
            /**
             * BalanceOperationsRequest sortOrder.
             * @member {ca2billing.BalanceOperationSort} sortOrder
             * @memberof ca2billing.BalanceOperationsRequest
             * @instance
             */
            BalanceOperationsRequest.prototype.sortOrder = 0;
    
            /**
             * Creates a new BalanceOperationsRequest instance using the specified properties.
             * @function create
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {ca2billing.IBalanceOperationsRequest=} [properties] Properties to set
             * @returns {ca2billing.BalanceOperationsRequest} BalanceOperationsRequest instance
             */
            BalanceOperationsRequest.create = function create(properties) {
                return new BalanceOperationsRequest(properties);
            };
    
            /**
             * Encodes the specified BalanceOperationsRequest message. Does not implicitly {@link ca2billing.BalanceOperationsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {ca2billing.IBalanceOperationsRequest} message BalanceOperationsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperationsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
                if (message.operationTypes != null && message.operationTypes.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.operationTypes.length; ++i)
                        writer.int32(message.operationTypes[i]);
                    writer.ldelim();
                }
                if (message.balanceType != null && Object.hasOwnProperty.call(message, "balanceType"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.balanceType);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.ca2types.Uint64Range.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                return writer;
            };
    
            /**
             * Encodes the specified BalanceOperationsRequest message, length delimited. Does not implicitly {@link ca2billing.BalanceOperationsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {ca2billing.IBalanceOperationsRequest} message BalanceOperationsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalanceOperationsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BalanceOperationsRequest} BalanceOperationsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperationsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BalanceOperationsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.limit = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.offset = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.balanceType = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.operationTypes && message.operationTypes.length))
                                message.operationTypes = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.operationTypes.push(reader.int32());
                            } else
                                message.operationTypes.push(reader.int32());
                            break;
                        }
                    case 5: {
                            message.createdAt = $root.ca2types.Uint64Range.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.sortOrder = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalanceOperationsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BalanceOperationsRequest} BalanceOperationsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperationsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalanceOperationsRequest message.
             * @function verify
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalanceOperationsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.balanceType != null && message.hasOwnProperty("balanceType"))
                    switch (message.balanceType) {
                    default:
                        return "balanceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.operationTypes != null && message.hasOwnProperty("operationTypes")) {
                    if (!Array.isArray(message.operationTypes))
                        return "operationTypes: array expected";
                    for (var i = 0; i < message.operationTypes.length; ++i)
                        switch (message.operationTypes[i]) {
                        default:
                            return "operationTypes: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                    var error = $root.ca2types.Uint64Range.verify(message.createdAt);
                    if (error)
                        return "createdAt." + error;
                }
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a BalanceOperationsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BalanceOperationsRequest} BalanceOperationsRequest
             */
            BalanceOperationsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BalanceOperationsRequest)
                    return object;
                var message = new $root.ca2billing.BalanceOperationsRequest();
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                switch (object.balanceType) {
                default:
                    if (typeof object.balanceType === "number") {
                        message.balanceType = object.balanceType;
                        break;
                    }
                    break;
                case "BT_UNKNOWN":
                case 0:
                    message.balanceType = 0;
                    break;
                case "BT_MAIN":
                case 1:
                    message.balanceType = 1;
                    break;
                case "BT_BONUS":
                case 2:
                    message.balanceType = 2;
                    break;
                }
                if (object.operationTypes) {
                    if (!Array.isArray(object.operationTypes))
                        throw TypeError(".ca2billing.BalanceOperationsRequest.operationTypes: array expected");
                    message.operationTypes = [];
                    for (var i = 0; i < object.operationTypes.length; ++i)
                        switch (object.operationTypes[i]) {
                        default:
                            if (typeof object.operationTypes[i] === "number") {
                                message.operationTypes[i] = object.operationTypes[i];
                                break;
                            }
                        case "BOT_UNKNOWN":
                        case 0:
                            message.operationTypes[i] = 0;
                            break;
                        case "BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE":
                        case 1:
                            message.operationTypes[i] = 1;
                            break;
                        case "BOT_PROMO_CODE":
                        case 2:
                            message.operationTypes[i] = 2;
                            break;
                        case "BOT_REFERRAL_OPERATION":
                        case 3:
                            message.operationTypes[i] = 3;
                            break;
                        case "BOT_INSTANCE_HOUR":
                        case 4:
                            message.operationTypes[i] = 4;
                            break;
                        case "BOT_ADMIN_CORRECTION":
                        case 5:
                            message.operationTypes[i] = 5;
                            break;
                        case "BOT_PAYMENT":
                        case 6:
                            message.operationTypes[i] = 6;
                            break;
                        }
                }
                if (object.createdAt != null) {
                    if (typeof object.createdAt !== "object")
                        throw TypeError(".ca2billing.BalanceOperationsRequest.createdAt: object expected");
                    message.createdAt = $root.ca2types.Uint64Range.fromObject(object.createdAt);
                }
                switch (object.sortOrder) {
                default:
                    if (typeof object.sortOrder === "number") {
                        message.sortOrder = object.sortOrder;
                        break;
                    }
                    break;
                case "BOS_DEFAULT":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "BOS_CREATED_AT_DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                case "BOS_CREATED_AT_ASC":
                case 2:
                    message.sortOrder = 2;
                    break;
                case "BOS_AMOUNT_DESC":
                case 3:
                    message.sortOrder = 3;
                    break;
                case "BOS_AMOUNT_ASC":
                case 4:
                    message.sortOrder = 4;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BalanceOperationsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {ca2billing.BalanceOperationsRequest} message BalanceOperationsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalanceOperationsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.operationTypes = [];
                if (options.defaults) {
                    object.limit = 0;
                    object.offset = 0;
                    object.balanceType = options.enums === String ? "BT_UNKNOWN" : 0;
                    object.createdAt = null;
                    object.sortOrder = options.enums === String ? "BOS_DEFAULT" : 0;
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.operationTypes && message.operationTypes.length) {
                    object.operationTypes = [];
                    for (var j = 0; j < message.operationTypes.length; ++j)
                        object.operationTypes[j] = options.enums === String ? $root.ca2types.BalanceOperationType[message.operationTypes[j]] === undefined ? message.operationTypes[j] : $root.ca2types.BalanceOperationType[message.operationTypes[j]] : message.operationTypes[j];
                }
                if (message.balanceType != null && message.hasOwnProperty("balanceType"))
                    object.balanceType = options.enums === String ? $root.ca2types.BalanceType[message.balanceType] === undefined ? message.balanceType : $root.ca2types.BalanceType[message.balanceType] : message.balanceType;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    object.createdAt = $root.ca2types.Uint64Range.toObject(message.createdAt, options);
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.ca2billing.BalanceOperationSort[message.sortOrder] === undefined ? message.sortOrder : $root.ca2billing.BalanceOperationSort[message.sortOrder] : message.sortOrder;
                return object;
            };
    
            /**
             * Converts this BalanceOperationsRequest to JSON.
             * @function toJSON
             * @memberof ca2billing.BalanceOperationsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalanceOperationsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalanceOperationsRequest
             * @function getTypeUrl
             * @memberof ca2billing.BalanceOperationsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalanceOperationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BalanceOperationsRequest";
            };
    
            return BalanceOperationsRequest;
        })();
    
        /**
         * BalanceOperationsError enum.
         * @name ca2billing.BalanceOperationsError
         * @enum {number}
         * @property {number} BOE_UNKNOWN_ERROR=0 BOE_UNKNOWN_ERROR value
         * @property {number} BOE_INVALID_BALANCE_TYPE=1 BOE_INVALID_BALANCE_TYPE value
         */
        ca2billing.BalanceOperationsError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BOE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "BOE_INVALID_BALANCE_TYPE"] = 1;
            return values;
        })();
    
        ca2billing.BalanceOperationsResponse = (function() {
    
            /**
             * Properties of a BalanceOperationsResponse.
             * @memberof ca2billing
             * @interface IBalanceOperationsResponse
             * @property {Array.<ca2billing.BalanceOperationsError>|null} [errors] BalanceOperationsResponse errors
             * @property {Array.<ca2types.IBalanceOperation>|null} [items] BalanceOperationsResponse items
             * @property {number|null} [total] BalanceOperationsResponse total
             * @property {ca2billing.BalanceOperationSort|null} [sort] BalanceOperationsResponse sort
             */
    
            /**
             * Constructs a new BalanceOperationsResponse.
             * @memberof ca2billing
             * @classdesc Represents a BalanceOperationsResponse.
             * @implements IBalanceOperationsResponse
             * @constructor
             * @param {ca2billing.IBalanceOperationsResponse=} [properties] Properties to set
             */
            function BalanceOperationsResponse(properties) {
                this.errors = [];
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalanceOperationsResponse errors.
             * @member {Array.<ca2billing.BalanceOperationsError>} errors
             * @memberof ca2billing.BalanceOperationsResponse
             * @instance
             */
            BalanceOperationsResponse.prototype.errors = $util.emptyArray;
    
            /**
             * BalanceOperationsResponse items.
             * @member {Array.<ca2types.IBalanceOperation>} items
             * @memberof ca2billing.BalanceOperationsResponse
             * @instance
             */
            BalanceOperationsResponse.prototype.items = $util.emptyArray;
    
            /**
             * BalanceOperationsResponse total.
             * @member {number} total
             * @memberof ca2billing.BalanceOperationsResponse
             * @instance
             */
            BalanceOperationsResponse.prototype.total = 0;
    
            /**
             * BalanceOperationsResponse sort.
             * @member {ca2billing.BalanceOperationSort} sort
             * @memberof ca2billing.BalanceOperationsResponse
             * @instance
             */
            BalanceOperationsResponse.prototype.sort = 0;
    
            /**
             * Creates a new BalanceOperationsResponse instance using the specified properties.
             * @function create
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {ca2billing.IBalanceOperationsResponse=} [properties] Properties to set
             * @returns {ca2billing.BalanceOperationsResponse} BalanceOperationsResponse instance
             */
            BalanceOperationsResponse.create = function create(properties) {
                return new BalanceOperationsResponse(properties);
            };
    
            /**
             * Encodes the specified BalanceOperationsResponse message. Does not implicitly {@link ca2billing.BalanceOperationsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {ca2billing.IBalanceOperationsResponse} message BalanceOperationsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperationsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.BalanceOperation.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.total);
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.sort != null && Object.hasOwnProperty.call(message, "sort"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sort);
                return writer;
            };
    
            /**
             * Encodes the specified BalanceOperationsResponse message, length delimited. Does not implicitly {@link ca2billing.BalanceOperationsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {ca2billing.IBalanceOperationsResponse} message BalanceOperationsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalanceOperationsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.BalanceOperationsResponse} BalanceOperationsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperationsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.BalanceOperationsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.BalanceOperation.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.total = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.sort = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalanceOperationsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.BalanceOperationsResponse} BalanceOperationsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperationsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalanceOperationsResponse message.
             * @function verify
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalanceOperationsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                            break;
                        }
                }
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.BalanceOperation.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                if (message.sort != null && message.hasOwnProperty("sort"))
                    switch (message.sort) {
                    default:
                        return "sort: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a BalanceOperationsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.BalanceOperationsResponse} BalanceOperationsResponse
             */
            BalanceOperationsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.BalanceOperationsResponse)
                    return object;
                var message = new $root.ca2billing.BalanceOperationsResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2billing.BalanceOperationsResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "BOE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "BOE_INVALID_BALANCE_TYPE":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        }
                }
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2billing.BalanceOperationsResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2billing.BalanceOperationsResponse.items: object expected");
                        message.items[i] = $root.ca2types.BalanceOperation.fromObject(object.items[i]);
                    }
                }
                if (object.total != null)
                    message.total = object.total >>> 0;
                switch (object.sort) {
                default:
                    if (typeof object.sort === "number") {
                        message.sort = object.sort;
                        break;
                    }
                    break;
                case "BOS_DEFAULT":
                case 0:
                    message.sort = 0;
                    break;
                case "BOS_CREATED_AT_DESC":
                case 1:
                    message.sort = 1;
                    break;
                case "BOS_CREATED_AT_ASC":
                case 2:
                    message.sort = 2;
                    break;
                case "BOS_AMOUNT_DESC":
                case 3:
                    message.sort = 3;
                    break;
                case "BOS_AMOUNT_ASC":
                case 4:
                    message.sort = 4;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BalanceOperationsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {ca2billing.BalanceOperationsResponse} message BalanceOperationsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalanceOperationsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.items = [];
                    object.errors = [];
                }
                if (options.defaults) {
                    object.total = 0;
                    object.sort = options.enums === String ? "BOS_DEFAULT" : 0;
                }
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.BalanceOperation.toObject(message.items[j], options);
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2billing.BalanceOperationsError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2billing.BalanceOperationsError[message.errors[j]] : message.errors[j];
                }
                if (message.sort != null && message.hasOwnProperty("sort"))
                    object.sort = options.enums === String ? $root.ca2billing.BalanceOperationSort[message.sort] === undefined ? message.sort : $root.ca2billing.BalanceOperationSort[message.sort] : message.sort;
                return object;
            };
    
            /**
             * Converts this BalanceOperationsResponse to JSON.
             * @function toJSON
             * @memberof ca2billing.BalanceOperationsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalanceOperationsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalanceOperationsResponse
             * @function getTypeUrl
             * @memberof ca2billing.BalanceOperationsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalanceOperationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.BalanceOperationsResponse";
            };
    
            return BalanceOperationsResponse;
        })();
    
        ca2billing.ApplyPromoCodeRequest = (function() {
    
            /**
             * Properties of an ApplyPromoCodeRequest.
             * @memberof ca2billing
             * @interface IApplyPromoCodeRequest
             * @property {string|null} [promoCode] ApplyPromoCodeRequest promoCode
             */
    
            /**
             * Constructs a new ApplyPromoCodeRequest.
             * @memberof ca2billing
             * @classdesc Represents an ApplyPromoCodeRequest.
             * @implements IApplyPromoCodeRequest
             * @constructor
             * @param {ca2billing.IApplyPromoCodeRequest=} [properties] Properties to set
             */
            function ApplyPromoCodeRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ApplyPromoCodeRequest promoCode.
             * @member {string} promoCode
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @instance
             */
            ApplyPromoCodeRequest.prototype.promoCode = "";
    
            /**
             * Creates a new ApplyPromoCodeRequest instance using the specified properties.
             * @function create
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {ca2billing.IApplyPromoCodeRequest=} [properties] Properties to set
             * @returns {ca2billing.ApplyPromoCodeRequest} ApplyPromoCodeRequest instance
             */
            ApplyPromoCodeRequest.create = function create(properties) {
                return new ApplyPromoCodeRequest(properties);
            };
    
            /**
             * Encodes the specified ApplyPromoCodeRequest message. Does not implicitly {@link ca2billing.ApplyPromoCodeRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {ca2billing.IApplyPromoCodeRequest} message ApplyPromoCodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApplyPromoCodeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.promoCode != null && Object.hasOwnProperty.call(message, "promoCode"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.promoCode);
                return writer;
            };
    
            /**
             * Encodes the specified ApplyPromoCodeRequest message, length delimited. Does not implicitly {@link ca2billing.ApplyPromoCodeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {ca2billing.IApplyPromoCodeRequest} message ApplyPromoCodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApplyPromoCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ApplyPromoCodeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.ApplyPromoCodeRequest} ApplyPromoCodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApplyPromoCodeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.ApplyPromoCodeRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.promoCode = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ApplyPromoCodeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.ApplyPromoCodeRequest} ApplyPromoCodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApplyPromoCodeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ApplyPromoCodeRequest message.
             * @function verify
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ApplyPromoCodeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.promoCode != null && message.hasOwnProperty("promoCode"))
                    if (!$util.isString(message.promoCode))
                        return "promoCode: string expected";
                return null;
            };
    
            /**
             * Creates an ApplyPromoCodeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.ApplyPromoCodeRequest} ApplyPromoCodeRequest
             */
            ApplyPromoCodeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.ApplyPromoCodeRequest)
                    return object;
                var message = new $root.ca2billing.ApplyPromoCodeRequest();
                if (object.promoCode != null)
                    message.promoCode = String(object.promoCode);
                return message;
            };
    
            /**
             * Creates a plain object from an ApplyPromoCodeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {ca2billing.ApplyPromoCodeRequest} message ApplyPromoCodeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ApplyPromoCodeRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.promoCode = "";
                if (message.promoCode != null && message.hasOwnProperty("promoCode"))
                    object.promoCode = message.promoCode;
                return object;
            };
    
            /**
             * Converts this ApplyPromoCodeRequest to JSON.
             * @function toJSON
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ApplyPromoCodeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ApplyPromoCodeRequest
             * @function getTypeUrl
             * @memberof ca2billing.ApplyPromoCodeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ApplyPromoCodeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.ApplyPromoCodeRequest";
            };
    
            return ApplyPromoCodeRequest;
        })();
    
        /**
         * ApplyPromoCodeError enum.
         * @name ca2billing.ApplyPromoCodeError
         * @enum {number}
         * @property {number} APE_UNKNOWN_ERROR=0 APE_UNKNOWN_ERROR value
         * @property {number} APE_INVALID_PROMO_CODE=1 APE_INVALID_PROMO_CODE value
         * @property {number} APE_ALREADY_USED=2 APE_ALREADY_USED value
         * @property {number} APE_EXPIRED=3 APE_EXPIRED value
         */
        ca2billing.ApplyPromoCodeError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "APE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "APE_INVALID_PROMO_CODE"] = 1;
            values[valuesById[2] = "APE_ALREADY_USED"] = 2;
            values[valuesById[3] = "APE_EXPIRED"] = 3;
            return values;
        })();
    
        ca2billing.ApplyPromoCodeResponse = (function() {
    
            /**
             * Properties of an ApplyPromoCodeResponse.
             * @memberof ca2billing
             * @interface IApplyPromoCodeResponse
             * @property {Array.<ca2billing.ApplyPromoCodeError>|null} [errors] ApplyPromoCodeResponse errors
             */
    
            /**
             * Constructs a new ApplyPromoCodeResponse.
             * @memberof ca2billing
             * @classdesc Represents an ApplyPromoCodeResponse.
             * @implements IApplyPromoCodeResponse
             * @constructor
             * @param {ca2billing.IApplyPromoCodeResponse=} [properties] Properties to set
             */
            function ApplyPromoCodeResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ApplyPromoCodeResponse errors.
             * @member {Array.<ca2billing.ApplyPromoCodeError>} errors
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @instance
             */
            ApplyPromoCodeResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new ApplyPromoCodeResponse instance using the specified properties.
             * @function create
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {ca2billing.IApplyPromoCodeResponse=} [properties] Properties to set
             * @returns {ca2billing.ApplyPromoCodeResponse} ApplyPromoCodeResponse instance
             */
            ApplyPromoCodeResponse.create = function create(properties) {
                return new ApplyPromoCodeResponse(properties);
            };
    
            /**
             * Encodes the specified ApplyPromoCodeResponse message. Does not implicitly {@link ca2billing.ApplyPromoCodeResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {ca2billing.IApplyPromoCodeResponse} message ApplyPromoCodeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApplyPromoCodeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified ApplyPromoCodeResponse message, length delimited. Does not implicitly {@link ca2billing.ApplyPromoCodeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {ca2billing.IApplyPromoCodeResponse} message ApplyPromoCodeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApplyPromoCodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ApplyPromoCodeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.ApplyPromoCodeResponse} ApplyPromoCodeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApplyPromoCodeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.ApplyPromoCodeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ApplyPromoCodeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.ApplyPromoCodeResponse} ApplyPromoCodeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApplyPromoCodeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ApplyPromoCodeResponse message.
             * @function verify
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ApplyPromoCodeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates an ApplyPromoCodeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.ApplyPromoCodeResponse} ApplyPromoCodeResponse
             */
            ApplyPromoCodeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.ApplyPromoCodeResponse)
                    return object;
                var message = new $root.ca2billing.ApplyPromoCodeResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2billing.ApplyPromoCodeResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "APE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "APE_INVALID_PROMO_CODE":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "APE_ALREADY_USED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "APE_EXPIRED":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an ApplyPromoCodeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {ca2billing.ApplyPromoCodeResponse} message ApplyPromoCodeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ApplyPromoCodeResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2billing.ApplyPromoCodeError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2billing.ApplyPromoCodeError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this ApplyPromoCodeResponse to JSON.
             * @function toJSON
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ApplyPromoCodeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ApplyPromoCodeResponse
             * @function getTypeUrl
             * @memberof ca2billing.ApplyPromoCodeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ApplyPromoCodeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.ApplyPromoCodeResponse";
            };
    
            return ApplyPromoCodeResponse;
        })();
    
        ca2billing.CurrenciesRequest = (function() {
    
            /**
             * Properties of a CurrenciesRequest.
             * @memberof ca2billing
             * @interface ICurrenciesRequest
             * @property {ca2types.PaymentSystem|null} [paymentSystem] CurrenciesRequest paymentSystem
             */
    
            /**
             * Constructs a new CurrenciesRequest.
             * @memberof ca2billing
             * @classdesc Represents a CurrenciesRequest.
             * @implements ICurrenciesRequest
             * @constructor
             * @param {ca2billing.ICurrenciesRequest=} [properties] Properties to set
             */
            function CurrenciesRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CurrenciesRequest paymentSystem.
             * @member {ca2types.PaymentSystem} paymentSystem
             * @memberof ca2billing.CurrenciesRequest
             * @instance
             */
            CurrenciesRequest.prototype.paymentSystem = 0;
    
            /**
             * Creates a new CurrenciesRequest instance using the specified properties.
             * @function create
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {ca2billing.ICurrenciesRequest=} [properties] Properties to set
             * @returns {ca2billing.CurrenciesRequest} CurrenciesRequest instance
             */
            CurrenciesRequest.create = function create(properties) {
                return new CurrenciesRequest(properties);
            };
    
            /**
             * Encodes the specified CurrenciesRequest message. Does not implicitly {@link ca2billing.CurrenciesRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {ca2billing.ICurrenciesRequest} message CurrenciesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CurrenciesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.paymentSystem != null && Object.hasOwnProperty.call(message, "paymentSystem"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.paymentSystem);
                return writer;
            };
    
            /**
             * Encodes the specified CurrenciesRequest message, length delimited. Does not implicitly {@link ca2billing.CurrenciesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {ca2billing.ICurrenciesRequest} message CurrenciesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CurrenciesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CurrenciesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.CurrenciesRequest} CurrenciesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CurrenciesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.CurrenciesRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.paymentSystem = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CurrenciesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.CurrenciesRequest} CurrenciesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CurrenciesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CurrenciesRequest message.
             * @function verify
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CurrenciesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.paymentSystem != null && message.hasOwnProperty("paymentSystem"))
                    switch (message.paymentSystem) {
                    default:
                        return "paymentSystem: enum value expected";
                    case 0:
                    case 1:
                    case 99:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a CurrenciesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.CurrenciesRequest} CurrenciesRequest
             */
            CurrenciesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.CurrenciesRequest)
                    return object;
                var message = new $root.ca2billing.CurrenciesRequest();
                switch (object.paymentSystem) {
                default:
                    if (typeof object.paymentSystem === "number") {
                        message.paymentSystem = object.paymentSystem;
                        break;
                    }
                    break;
                case "PS_UNKNOWN":
                case 0:
                    message.paymentSystem = 0;
                    break;
                case "PS_COINS_PAID":
                case 1:
                    message.paymentSystem = 1;
                    break;
                case "PS_FAKE":
                case 99:
                    message.paymentSystem = 99;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CurrenciesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {ca2billing.CurrenciesRequest} message CurrenciesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CurrenciesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.paymentSystem = options.enums === String ? "PS_UNKNOWN" : 0;
                if (message.paymentSystem != null && message.hasOwnProperty("paymentSystem"))
                    object.paymentSystem = options.enums === String ? $root.ca2types.PaymentSystem[message.paymentSystem] === undefined ? message.paymentSystem : $root.ca2types.PaymentSystem[message.paymentSystem] : message.paymentSystem;
                return object;
            };
    
            /**
             * Converts this CurrenciesRequest to JSON.
             * @function toJSON
             * @memberof ca2billing.CurrenciesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CurrenciesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CurrenciesRequest
             * @function getTypeUrl
             * @memberof ca2billing.CurrenciesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CurrenciesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.CurrenciesRequest";
            };
    
            return CurrenciesRequest;
        })();
    
        ca2billing.CurrenciesResponse = (function() {
    
            /**
             * Properties of a CurrenciesResponse.
             * @memberof ca2billing
             * @interface ICurrenciesResponse
             * @property {Array.<ca2types.ICurrency>|null} [items] CurrenciesResponse items
             */
    
            /**
             * Constructs a new CurrenciesResponse.
             * @memberof ca2billing
             * @classdesc Represents a CurrenciesResponse.
             * @implements ICurrenciesResponse
             * @constructor
             * @param {ca2billing.ICurrenciesResponse=} [properties] Properties to set
             */
            function CurrenciesResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CurrenciesResponse items.
             * @member {Array.<ca2types.ICurrency>} items
             * @memberof ca2billing.CurrenciesResponse
             * @instance
             */
            CurrenciesResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new CurrenciesResponse instance using the specified properties.
             * @function create
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {ca2billing.ICurrenciesResponse=} [properties] Properties to set
             * @returns {ca2billing.CurrenciesResponse} CurrenciesResponse instance
             */
            CurrenciesResponse.create = function create(properties) {
                return new CurrenciesResponse(properties);
            };
    
            /**
             * Encodes the specified CurrenciesResponse message. Does not implicitly {@link ca2billing.CurrenciesResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {ca2billing.ICurrenciesResponse} message CurrenciesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CurrenciesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Currency.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CurrenciesResponse message, length delimited. Does not implicitly {@link ca2billing.CurrenciesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {ca2billing.ICurrenciesResponse} message CurrenciesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CurrenciesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CurrenciesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.CurrenciesResponse} CurrenciesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CurrenciesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.CurrenciesResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Currency.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CurrenciesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.CurrenciesResponse} CurrenciesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CurrenciesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CurrenciesResponse message.
             * @function verify
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CurrenciesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Currency.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CurrenciesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.CurrenciesResponse} CurrenciesResponse
             */
            CurrenciesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.CurrenciesResponse)
                    return object;
                var message = new $root.ca2billing.CurrenciesResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2billing.CurrenciesResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2billing.CurrenciesResponse.items: object expected");
                        message.items[i] = $root.ca2types.Currency.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CurrenciesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {ca2billing.CurrenciesResponse} message CurrenciesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CurrenciesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Currency.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this CurrenciesResponse to JSON.
             * @function toJSON
             * @memberof ca2billing.CurrenciesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CurrenciesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CurrenciesResponse
             * @function getTypeUrl
             * @memberof ca2billing.CurrenciesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CurrenciesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.CurrenciesResponse";
            };
    
            return CurrenciesResponse;
        })();
    
        ca2billing.TopUpBalanceRequest = (function() {
    
            /**
             * Properties of a TopUpBalanceRequest.
             * @memberof ca2billing
             * @interface ITopUpBalanceRequest
             * @property {ca2types.PaymentSystem|null} [paymentSystem] TopUpBalanceRequest paymentSystem
             * @property {number|null} [amountUsd] TopUpBalanceRequest amountUsd
             * @property {string|null} [currencyCode] TopUpBalanceRequest currencyCode
             */
    
            /**
             * Constructs a new TopUpBalanceRequest.
             * @memberof ca2billing
             * @classdesc Represents a TopUpBalanceRequest.
             * @implements ITopUpBalanceRequest
             * @constructor
             * @param {ca2billing.ITopUpBalanceRequest=} [properties] Properties to set
             */
            function TopUpBalanceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TopUpBalanceRequest paymentSystem.
             * @member {ca2types.PaymentSystem} paymentSystem
             * @memberof ca2billing.TopUpBalanceRequest
             * @instance
             */
            TopUpBalanceRequest.prototype.paymentSystem = 0;
    
            /**
             * TopUpBalanceRequest amountUsd.
             * @member {number} amountUsd
             * @memberof ca2billing.TopUpBalanceRequest
             * @instance
             */
            TopUpBalanceRequest.prototype.amountUsd = 0;
    
            /**
             * TopUpBalanceRequest currencyCode.
             * @member {string} currencyCode
             * @memberof ca2billing.TopUpBalanceRequest
             * @instance
             */
            TopUpBalanceRequest.prototype.currencyCode = "";
    
            /**
             * Creates a new TopUpBalanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {ca2billing.ITopUpBalanceRequest=} [properties] Properties to set
             * @returns {ca2billing.TopUpBalanceRequest} TopUpBalanceRequest instance
             */
            TopUpBalanceRequest.create = function create(properties) {
                return new TopUpBalanceRequest(properties);
            };
    
            /**
             * Encodes the specified TopUpBalanceRequest message. Does not implicitly {@link ca2billing.TopUpBalanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {ca2billing.ITopUpBalanceRequest} message TopUpBalanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopUpBalanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.paymentSystem != null && Object.hasOwnProperty.call(message, "paymentSystem"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.paymentSystem);
                if (message.amountUsd != null && Object.hasOwnProperty.call(message, "amountUsd"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.amountUsd);
                if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.currencyCode);
                return writer;
            };
    
            /**
             * Encodes the specified TopUpBalanceRequest message, length delimited. Does not implicitly {@link ca2billing.TopUpBalanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {ca2billing.ITopUpBalanceRequest} message TopUpBalanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopUpBalanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TopUpBalanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.TopUpBalanceRequest} TopUpBalanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopUpBalanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.TopUpBalanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.paymentSystem = reader.int32();
                            break;
                        }
                    case 2: {
                            message.amountUsd = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.currencyCode = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TopUpBalanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.TopUpBalanceRequest} TopUpBalanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopUpBalanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TopUpBalanceRequest message.
             * @function verify
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TopUpBalanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.paymentSystem != null && message.hasOwnProperty("paymentSystem"))
                    switch (message.paymentSystem) {
                    default:
                        return "paymentSystem: enum value expected";
                    case 0:
                    case 1:
                    case 99:
                        break;
                    }
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    if (!$util.isInteger(message.amountUsd))
                        return "amountUsd: integer expected";
                if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                    if (!$util.isString(message.currencyCode))
                        return "currencyCode: string expected";
                return null;
            };
    
            /**
             * Creates a TopUpBalanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.TopUpBalanceRequest} TopUpBalanceRequest
             */
            TopUpBalanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.TopUpBalanceRequest)
                    return object;
                var message = new $root.ca2billing.TopUpBalanceRequest();
                switch (object.paymentSystem) {
                default:
                    if (typeof object.paymentSystem === "number") {
                        message.paymentSystem = object.paymentSystem;
                        break;
                    }
                    break;
                case "PS_UNKNOWN":
                case 0:
                    message.paymentSystem = 0;
                    break;
                case "PS_COINS_PAID":
                case 1:
                    message.paymentSystem = 1;
                    break;
                case "PS_FAKE":
                case 99:
                    message.paymentSystem = 99;
                    break;
                }
                if (object.amountUsd != null)
                    message.amountUsd = object.amountUsd >>> 0;
                if (object.currencyCode != null)
                    message.currencyCode = String(object.currencyCode);
                return message;
            };
    
            /**
             * Creates a plain object from a TopUpBalanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {ca2billing.TopUpBalanceRequest} message TopUpBalanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TopUpBalanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.paymentSystem = options.enums === String ? "PS_UNKNOWN" : 0;
                    object.amountUsd = 0;
                    object.currencyCode = "";
                }
                if (message.paymentSystem != null && message.hasOwnProperty("paymentSystem"))
                    object.paymentSystem = options.enums === String ? $root.ca2types.PaymentSystem[message.paymentSystem] === undefined ? message.paymentSystem : $root.ca2types.PaymentSystem[message.paymentSystem] : message.paymentSystem;
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    object.amountUsd = message.amountUsd;
                if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                    object.currencyCode = message.currencyCode;
                return object;
            };
    
            /**
             * Converts this TopUpBalanceRequest to JSON.
             * @function toJSON
             * @memberof ca2billing.TopUpBalanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TopUpBalanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TopUpBalanceRequest
             * @function getTypeUrl
             * @memberof ca2billing.TopUpBalanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TopUpBalanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.TopUpBalanceRequest";
            };
    
            return TopUpBalanceRequest;
        })();
    
        /**
         * TopUpBalanceError enum.
         * @name ca2billing.TopUpBalanceError
         * @enum {number}
         * @property {number} TUBE_UNKNOWN_ERROR=0 TUBE_UNKNOWN_ERROR value
         * @property {number} TUBE_AMOUNT_TOO_SMALL=1 TUBE_AMOUNT_TOO_SMALL value
         * @property {number} TUBE_INVALID_PAYMENT_SYSTEM=2 TUBE_INVALID_PAYMENT_SYSTEM value
         * @property {number} TUBE_INVALID_CURRENCY_CODE=3 TUBE_INVALID_CURRENCY_CODE value
         */
        ca2billing.TopUpBalanceError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TUBE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "TUBE_AMOUNT_TOO_SMALL"] = 1;
            values[valuesById[2] = "TUBE_INVALID_PAYMENT_SYSTEM"] = 2;
            values[valuesById[3] = "TUBE_INVALID_CURRENCY_CODE"] = 3;
            return values;
        })();
    
        ca2billing.TopUpBalanceResponse = (function() {
    
            /**
             * Properties of a TopUpBalanceResponse.
             * @memberof ca2billing
             * @interface ITopUpBalanceResponse
             * @property {Array.<ca2billing.TopUpBalanceError>|null} [errors] TopUpBalanceResponse errors
             * @property {ca2types.IAmount|null} [amount] TopUpBalanceResponse amount
             * @property {string|null} [currencyCode] TopUpBalanceResponse currencyCode
             * @property {string|null} [address] TopUpBalanceResponse address
             * @property {string|null} [amountToPay] TopUpBalanceResponse amountToPay
             * @property {Long|null} [validUntil] TopUpBalanceResponse validUntil
             * @property {string|null} [foreignId] TopUpBalanceResponse foreignId
             */
    
            /**
             * Constructs a new TopUpBalanceResponse.
             * @memberof ca2billing
             * @classdesc Represents a TopUpBalanceResponse.
             * @implements ITopUpBalanceResponse
             * @constructor
             * @param {ca2billing.ITopUpBalanceResponse=} [properties] Properties to set
             */
            function TopUpBalanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TopUpBalanceResponse errors.
             * @member {Array.<ca2billing.TopUpBalanceError>} errors
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             */
            TopUpBalanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * TopUpBalanceResponse amount.
             * @member {ca2types.IAmount|null|undefined} amount
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             */
            TopUpBalanceResponse.prototype.amount = null;
    
            /**
             * TopUpBalanceResponse currencyCode.
             * @member {string} currencyCode
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             */
            TopUpBalanceResponse.prototype.currencyCode = "";
    
            /**
             * TopUpBalanceResponse address.
             * @member {string} address
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             */
            TopUpBalanceResponse.prototype.address = "";
    
            /**
             * TopUpBalanceResponse amountToPay.
             * @member {string} amountToPay
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             */
            TopUpBalanceResponse.prototype.amountToPay = "";
    
            /**
             * TopUpBalanceResponse validUntil.
             * @member {Long} validUntil
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             */
            TopUpBalanceResponse.prototype.validUntil = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * TopUpBalanceResponse foreignId.
             * @member {string} foreignId
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             */
            TopUpBalanceResponse.prototype.foreignId = "";
    
            /**
             * Creates a new TopUpBalanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {ca2billing.ITopUpBalanceResponse=} [properties] Properties to set
             * @returns {ca2billing.TopUpBalanceResponse} TopUpBalanceResponse instance
             */
            TopUpBalanceResponse.create = function create(properties) {
                return new TopUpBalanceResponse(properties);
            };
    
            /**
             * Encodes the specified TopUpBalanceResponse message. Does not implicitly {@link ca2billing.TopUpBalanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {ca2billing.ITopUpBalanceResponse} message TopUpBalanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopUpBalanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.currencyCode);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.address);
                if (message.amountToPay != null && Object.hasOwnProperty.call(message, "amountToPay"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.amountToPay);
                if (message.validUntil != null && Object.hasOwnProperty.call(message, "validUntil"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.validUntil);
                if (message.foreignId != null && Object.hasOwnProperty.call(message, "foreignId"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.foreignId);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    $root.ca2types.Amount.encode(message.amount, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TopUpBalanceResponse message, length delimited. Does not implicitly {@link ca2billing.TopUpBalanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {ca2billing.ITopUpBalanceResponse} message TopUpBalanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopUpBalanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TopUpBalanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2billing.TopUpBalanceResponse} TopUpBalanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopUpBalanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2billing.TopUpBalanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 8: {
                            message.amount = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.currencyCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.address = reader.string();
                            break;
                        }
                    case 5: {
                            message.amountToPay = reader.string();
                            break;
                        }
                    case 6: {
                            message.validUntil = reader.uint64();
                            break;
                        }
                    case 7: {
                            message.foreignId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TopUpBalanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2billing.TopUpBalanceResponse} TopUpBalanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopUpBalanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TopUpBalanceResponse message.
             * @function verify
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TopUpBalanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                if (message.amount != null && message.hasOwnProperty("amount")) {
                    var error = $root.ca2types.Amount.verify(message.amount);
                    if (error)
                        return "amount." + error;
                }
                if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                    if (!$util.isString(message.currencyCode))
                        return "currencyCode: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.amountToPay != null && message.hasOwnProperty("amountToPay"))
                    if (!$util.isString(message.amountToPay))
                        return "amountToPay: string expected";
                if (message.validUntil != null && message.hasOwnProperty("validUntil"))
                    if (!$util.isInteger(message.validUntil) && !(message.validUntil && $util.isInteger(message.validUntil.low) && $util.isInteger(message.validUntil.high)))
                        return "validUntil: integer|Long expected";
                if (message.foreignId != null && message.hasOwnProperty("foreignId"))
                    if (!$util.isString(message.foreignId))
                        return "foreignId: string expected";
                return null;
            };
    
            /**
             * Creates a TopUpBalanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2billing.TopUpBalanceResponse} TopUpBalanceResponse
             */
            TopUpBalanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2billing.TopUpBalanceResponse)
                    return object;
                var message = new $root.ca2billing.TopUpBalanceResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2billing.TopUpBalanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "TUBE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "TUBE_AMOUNT_TOO_SMALL":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "TUBE_INVALID_PAYMENT_SYSTEM":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "TUBE_INVALID_CURRENCY_CODE":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                if (object.amount != null) {
                    if (typeof object.amount !== "object")
                        throw TypeError(".ca2billing.TopUpBalanceResponse.amount: object expected");
                    message.amount = $root.ca2types.Amount.fromObject(object.amount);
                }
                if (object.currencyCode != null)
                    message.currencyCode = String(object.currencyCode);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.amountToPay != null)
                    message.amountToPay = String(object.amountToPay);
                if (object.validUntil != null)
                    if ($util.Long)
                        (message.validUntil = $util.Long.fromValue(object.validUntil)).unsigned = true;
                    else if (typeof object.validUntil === "string")
                        message.validUntil = parseInt(object.validUntil, 10);
                    else if (typeof object.validUntil === "number")
                        message.validUntil = object.validUntil;
                    else if (typeof object.validUntil === "object")
                        message.validUntil = new $util.LongBits(object.validUntil.low >>> 0, object.validUntil.high >>> 0).toNumber(true);
                if (object.foreignId != null)
                    message.foreignId = String(object.foreignId);
                return message;
            };
    
            /**
             * Creates a plain object from a TopUpBalanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {ca2billing.TopUpBalanceResponse} message TopUpBalanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TopUpBalanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults) {
                    object.currencyCode = "";
                    object.address = "";
                    object.amountToPay = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.validUntil = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.validUntil = options.longs === String ? "0" : 0;
                    object.foreignId = "";
                    object.amount = null;
                }
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2billing.TopUpBalanceError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2billing.TopUpBalanceError[message.errors[j]] : message.errors[j];
                }
                if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                    object.currencyCode = message.currencyCode;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.amountToPay != null && message.hasOwnProperty("amountToPay"))
                    object.amountToPay = message.amountToPay;
                if (message.validUntil != null && message.hasOwnProperty("validUntil"))
                    if (typeof message.validUntil === "number")
                        object.validUntil = options.longs === String ? String(message.validUntil) : message.validUntil;
                    else
                        object.validUntil = options.longs === String ? $util.Long.prototype.toString.call(message.validUntil) : options.longs === Number ? new $util.LongBits(message.validUntil.low >>> 0, message.validUntil.high >>> 0).toNumber(true) : message.validUntil;
                if (message.foreignId != null && message.hasOwnProperty("foreignId"))
                    object.foreignId = message.foreignId;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = $root.ca2types.Amount.toObject(message.amount, options);
                return object;
            };
    
            /**
             * Converts this TopUpBalanceResponse to JSON.
             * @function toJSON
             * @memberof ca2billing.TopUpBalanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TopUpBalanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TopUpBalanceResponse
             * @function getTypeUrl
             * @memberof ca2billing.TopUpBalanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TopUpBalanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2billing.TopUpBalanceResponse";
            };
    
            return TopUpBalanceResponse;
        })();
    
        return ca2billing;
    })();
    
    $root.ca2domains = (function() {
    
        /**
         * Namespace ca2domains.
         * @exports ca2domains
         * @namespace
         */
        var ca2domains = {};
    
        ca2domains.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2domains
             * @interface IRequest
             * @property {ca2domains.ICheckDomainRequest|null} [checkDomain] Request checkDomain
             * @property {ca2domains.ITLDListRequest|null} [tldList] Request tldList
             * @property {ca2domains.IRegisterDomainRequest|null} [registerDomain] Request registerDomain
             * @property {ca2domains.IDomainsListRequest|null} [domainsList] Request domainsList
             * @property {ca2domains.ICreateOrUpdateContactRequest|null} [createOrUpdateContact] Request createOrUpdateContact
             * @property {ca2domains.IContactsListRequest|null} [contactsList] Request contactsList
             * @property {ca2domains.IDeleteContactRequest|null} [deleteContact] Request deleteContact
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2domains
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2domains.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request checkDomain.
             * @member {ca2domains.ICheckDomainRequest|null|undefined} checkDomain
             * @memberof ca2domains.Request
             * @instance
             */
            Request.prototype.checkDomain = null;
    
            /**
             * Request tldList.
             * @member {ca2domains.ITLDListRequest|null|undefined} tldList
             * @memberof ca2domains.Request
             * @instance
             */
            Request.prototype.tldList = null;
    
            /**
             * Request registerDomain.
             * @member {ca2domains.IRegisterDomainRequest|null|undefined} registerDomain
             * @memberof ca2domains.Request
             * @instance
             */
            Request.prototype.registerDomain = null;
    
            /**
             * Request domainsList.
             * @member {ca2domains.IDomainsListRequest|null|undefined} domainsList
             * @memberof ca2domains.Request
             * @instance
             */
            Request.prototype.domainsList = null;
    
            /**
             * Request createOrUpdateContact.
             * @member {ca2domains.ICreateOrUpdateContactRequest|null|undefined} createOrUpdateContact
             * @memberof ca2domains.Request
             * @instance
             */
            Request.prototype.createOrUpdateContact = null;
    
            /**
             * Request contactsList.
             * @member {ca2domains.IContactsListRequest|null|undefined} contactsList
             * @memberof ca2domains.Request
             * @instance
             */
            Request.prototype.contactsList = null;
    
            /**
             * Request deleteContact.
             * @member {ca2domains.IDeleteContactRequest|null|undefined} deleteContact
             * @memberof ca2domains.Request
             * @instance
             */
            Request.prototype.deleteContact = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2domains.Request
             * @static
             * @param {ca2domains.IRequest=} [properties] Properties to set
             * @returns {ca2domains.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2domains.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.Request
             * @static
             * @param {ca2domains.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.checkDomain != null && Object.hasOwnProperty.call(message, "checkDomain"))
                    $root.ca2domains.CheckDomainRequest.encode(message.checkDomain, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.tldList != null && Object.hasOwnProperty.call(message, "tldList"))
                    $root.ca2domains.TLDListRequest.encode(message.tldList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.registerDomain != null && Object.hasOwnProperty.call(message, "registerDomain"))
                    $root.ca2domains.RegisterDomainRequest.encode(message.registerDomain, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.domainsList != null && Object.hasOwnProperty.call(message, "domainsList"))
                    $root.ca2domains.DomainsListRequest.encode(message.domainsList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.contactsList != null && Object.hasOwnProperty.call(message, "contactsList"))
                    $root.ca2domains.ContactsListRequest.encode(message.contactsList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.deleteContact != null && Object.hasOwnProperty.call(message, "deleteContact"))
                    $root.ca2domains.DeleteContactRequest.encode(message.deleteContact, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.createOrUpdateContact != null && Object.hasOwnProperty.call(message, "createOrUpdateContact"))
                    $root.ca2domains.CreateOrUpdateContactRequest.encode(message.createOrUpdateContact, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2domains.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.Request
             * @static
             * @param {ca2domains.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.checkDomain = $root.ca2domains.CheckDomainRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.tldList = $root.ca2domains.TLDListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.registerDomain = $root.ca2domains.RegisterDomainRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.domainsList = $root.ca2domains.DomainsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.createOrUpdateContact = $root.ca2domains.CreateOrUpdateContactRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.contactsList = $root.ca2domains.ContactsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.deleteContact = $root.ca2domains.DeleteContactRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2domains.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.checkDomain != null && message.hasOwnProperty("checkDomain")) {
                    var error = $root.ca2domains.CheckDomainRequest.verify(message.checkDomain);
                    if (error)
                        return "checkDomain." + error;
                }
                if (message.tldList != null && message.hasOwnProperty("tldList")) {
                    var error = $root.ca2domains.TLDListRequest.verify(message.tldList);
                    if (error)
                        return "tldList." + error;
                }
                if (message.registerDomain != null && message.hasOwnProperty("registerDomain")) {
                    var error = $root.ca2domains.RegisterDomainRequest.verify(message.registerDomain);
                    if (error)
                        return "registerDomain." + error;
                }
                if (message.domainsList != null && message.hasOwnProperty("domainsList")) {
                    var error = $root.ca2domains.DomainsListRequest.verify(message.domainsList);
                    if (error)
                        return "domainsList." + error;
                }
                if (message.createOrUpdateContact != null && message.hasOwnProperty("createOrUpdateContact")) {
                    var error = $root.ca2domains.CreateOrUpdateContactRequest.verify(message.createOrUpdateContact);
                    if (error)
                        return "createOrUpdateContact." + error;
                }
                if (message.contactsList != null && message.hasOwnProperty("contactsList")) {
                    var error = $root.ca2domains.ContactsListRequest.verify(message.contactsList);
                    if (error)
                        return "contactsList." + error;
                }
                if (message.deleteContact != null && message.hasOwnProperty("deleteContact")) {
                    var error = $root.ca2domains.DeleteContactRequest.verify(message.deleteContact);
                    if (error)
                        return "deleteContact." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.Request)
                    return object;
                var message = new $root.ca2domains.Request();
                if (object.checkDomain != null) {
                    if (typeof object.checkDomain !== "object")
                        throw TypeError(".ca2domains.Request.checkDomain: object expected");
                    message.checkDomain = $root.ca2domains.CheckDomainRequest.fromObject(object.checkDomain);
                }
                if (object.tldList != null) {
                    if (typeof object.tldList !== "object")
                        throw TypeError(".ca2domains.Request.tldList: object expected");
                    message.tldList = $root.ca2domains.TLDListRequest.fromObject(object.tldList);
                }
                if (object.registerDomain != null) {
                    if (typeof object.registerDomain !== "object")
                        throw TypeError(".ca2domains.Request.registerDomain: object expected");
                    message.registerDomain = $root.ca2domains.RegisterDomainRequest.fromObject(object.registerDomain);
                }
                if (object.domainsList != null) {
                    if (typeof object.domainsList !== "object")
                        throw TypeError(".ca2domains.Request.domainsList: object expected");
                    message.domainsList = $root.ca2domains.DomainsListRequest.fromObject(object.domainsList);
                }
                if (object.createOrUpdateContact != null) {
                    if (typeof object.createOrUpdateContact !== "object")
                        throw TypeError(".ca2domains.Request.createOrUpdateContact: object expected");
                    message.createOrUpdateContact = $root.ca2domains.CreateOrUpdateContactRequest.fromObject(object.createOrUpdateContact);
                }
                if (object.contactsList != null) {
                    if (typeof object.contactsList !== "object")
                        throw TypeError(".ca2domains.Request.contactsList: object expected");
                    message.contactsList = $root.ca2domains.ContactsListRequest.fromObject(object.contactsList);
                }
                if (object.deleteContact != null) {
                    if (typeof object.deleteContact !== "object")
                        throw TypeError(".ca2domains.Request.deleteContact: object expected");
                    message.deleteContact = $root.ca2domains.DeleteContactRequest.fromObject(object.deleteContact);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.Request
             * @static
             * @param {ca2domains.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.checkDomain = null;
                    object.tldList = null;
                    object.registerDomain = null;
                    object.domainsList = null;
                    object.contactsList = null;
                    object.deleteContact = null;
                    object.createOrUpdateContact = null;
                }
                if (message.checkDomain != null && message.hasOwnProperty("checkDomain"))
                    object.checkDomain = $root.ca2domains.CheckDomainRequest.toObject(message.checkDomain, options);
                if (message.tldList != null && message.hasOwnProperty("tldList"))
                    object.tldList = $root.ca2domains.TLDListRequest.toObject(message.tldList, options);
                if (message.registerDomain != null && message.hasOwnProperty("registerDomain"))
                    object.registerDomain = $root.ca2domains.RegisterDomainRequest.toObject(message.registerDomain, options);
                if (message.domainsList != null && message.hasOwnProperty("domainsList"))
                    object.domainsList = $root.ca2domains.DomainsListRequest.toObject(message.domainsList, options);
                if (message.contactsList != null && message.hasOwnProperty("contactsList"))
                    object.contactsList = $root.ca2domains.ContactsListRequest.toObject(message.contactsList, options);
                if (message.deleteContact != null && message.hasOwnProperty("deleteContact"))
                    object.deleteContact = $root.ca2domains.DeleteContactRequest.toObject(message.deleteContact, options);
                if (message.createOrUpdateContact != null && message.hasOwnProperty("createOrUpdateContact"))
                    object.createOrUpdateContact = $root.ca2domains.CreateOrUpdateContactRequest.toObject(message.createOrUpdateContact, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2domains.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2domains.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.Request";
            };
    
            return Request;
        })();
    
        ca2domains.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2domains
             * @interface IResponse
             * @property {ca2domains.ICheckDomainResponse|null} [checkDomain] Response checkDomain
             * @property {ca2domains.ITLDListResponse|null} [tldList] Response tldList
             * @property {ca2domains.IRegisterDomainResponse|null} [registerDomain] Response registerDomain
             * @property {ca2domains.IDomainsListResponse|null} [domainsList] Response domainsList
             * @property {ca2domains.ICreateOrUpdateContactResponse|null} [createOrUpdateContact] Response createOrUpdateContact
             * @property {ca2domains.IContactsListResponse|null} [contactsList] Response contactsList
             * @property {ca2domains.IDeleteContactResponse|null} [deleteContact] Response deleteContact
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2domains
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2domains.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response checkDomain.
             * @member {ca2domains.ICheckDomainResponse|null|undefined} checkDomain
             * @memberof ca2domains.Response
             * @instance
             */
            Response.prototype.checkDomain = null;
    
            /**
             * Response tldList.
             * @member {ca2domains.ITLDListResponse|null|undefined} tldList
             * @memberof ca2domains.Response
             * @instance
             */
            Response.prototype.tldList = null;
    
            /**
             * Response registerDomain.
             * @member {ca2domains.IRegisterDomainResponse|null|undefined} registerDomain
             * @memberof ca2domains.Response
             * @instance
             */
            Response.prototype.registerDomain = null;
    
            /**
             * Response domainsList.
             * @member {ca2domains.IDomainsListResponse|null|undefined} domainsList
             * @memberof ca2domains.Response
             * @instance
             */
            Response.prototype.domainsList = null;
    
            /**
             * Response createOrUpdateContact.
             * @member {ca2domains.ICreateOrUpdateContactResponse|null|undefined} createOrUpdateContact
             * @memberof ca2domains.Response
             * @instance
             */
            Response.prototype.createOrUpdateContact = null;
    
            /**
             * Response contactsList.
             * @member {ca2domains.IContactsListResponse|null|undefined} contactsList
             * @memberof ca2domains.Response
             * @instance
             */
            Response.prototype.contactsList = null;
    
            /**
             * Response deleteContact.
             * @member {ca2domains.IDeleteContactResponse|null|undefined} deleteContact
             * @memberof ca2domains.Response
             * @instance
             */
            Response.prototype.deleteContact = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2domains.Response
             * @static
             * @param {ca2domains.IResponse=} [properties] Properties to set
             * @returns {ca2domains.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2domains.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.Response
             * @static
             * @param {ca2domains.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.checkDomain != null && Object.hasOwnProperty.call(message, "checkDomain"))
                    $root.ca2domains.CheckDomainResponse.encode(message.checkDomain, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.registerDomain != null && Object.hasOwnProperty.call(message, "registerDomain"))
                    $root.ca2domains.RegisterDomainResponse.encode(message.registerDomain, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.tldList != null && Object.hasOwnProperty.call(message, "tldList"))
                    $root.ca2domains.TLDListResponse.encode(message.tldList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.domainsList != null && Object.hasOwnProperty.call(message, "domainsList"))
                    $root.ca2domains.DomainsListResponse.encode(message.domainsList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.contactsList != null && Object.hasOwnProperty.call(message, "contactsList"))
                    $root.ca2domains.ContactsListResponse.encode(message.contactsList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.deleteContact != null && Object.hasOwnProperty.call(message, "deleteContact"))
                    $root.ca2domains.DeleteContactResponse.encode(message.deleteContact, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.createOrUpdateContact != null && Object.hasOwnProperty.call(message, "createOrUpdateContact"))
                    $root.ca2domains.CreateOrUpdateContactResponse.encode(message.createOrUpdateContact, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2domains.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.Response
             * @static
             * @param {ca2domains.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.checkDomain = $root.ca2domains.CheckDomainResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.tldList = $root.ca2domains.TLDListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.registerDomain = $root.ca2domains.RegisterDomainResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.domainsList = $root.ca2domains.DomainsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.createOrUpdateContact = $root.ca2domains.CreateOrUpdateContactResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.contactsList = $root.ca2domains.ContactsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.deleteContact = $root.ca2domains.DeleteContactResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2domains.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.checkDomain != null && message.hasOwnProperty("checkDomain")) {
                    var error = $root.ca2domains.CheckDomainResponse.verify(message.checkDomain);
                    if (error)
                        return "checkDomain." + error;
                }
                if (message.tldList != null && message.hasOwnProperty("tldList")) {
                    var error = $root.ca2domains.TLDListResponse.verify(message.tldList);
                    if (error)
                        return "tldList." + error;
                }
                if (message.registerDomain != null && message.hasOwnProperty("registerDomain")) {
                    var error = $root.ca2domains.RegisterDomainResponse.verify(message.registerDomain);
                    if (error)
                        return "registerDomain." + error;
                }
                if (message.domainsList != null && message.hasOwnProperty("domainsList")) {
                    var error = $root.ca2domains.DomainsListResponse.verify(message.domainsList);
                    if (error)
                        return "domainsList." + error;
                }
                if (message.createOrUpdateContact != null && message.hasOwnProperty("createOrUpdateContact")) {
                    var error = $root.ca2domains.CreateOrUpdateContactResponse.verify(message.createOrUpdateContact);
                    if (error)
                        return "createOrUpdateContact." + error;
                }
                if (message.contactsList != null && message.hasOwnProperty("contactsList")) {
                    var error = $root.ca2domains.ContactsListResponse.verify(message.contactsList);
                    if (error)
                        return "contactsList." + error;
                }
                if (message.deleteContact != null && message.hasOwnProperty("deleteContact")) {
                    var error = $root.ca2domains.DeleteContactResponse.verify(message.deleteContact);
                    if (error)
                        return "deleteContact." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.Response)
                    return object;
                var message = new $root.ca2domains.Response();
                if (object.checkDomain != null) {
                    if (typeof object.checkDomain !== "object")
                        throw TypeError(".ca2domains.Response.checkDomain: object expected");
                    message.checkDomain = $root.ca2domains.CheckDomainResponse.fromObject(object.checkDomain);
                }
                if (object.tldList != null) {
                    if (typeof object.tldList !== "object")
                        throw TypeError(".ca2domains.Response.tldList: object expected");
                    message.tldList = $root.ca2domains.TLDListResponse.fromObject(object.tldList);
                }
                if (object.registerDomain != null) {
                    if (typeof object.registerDomain !== "object")
                        throw TypeError(".ca2domains.Response.registerDomain: object expected");
                    message.registerDomain = $root.ca2domains.RegisterDomainResponse.fromObject(object.registerDomain);
                }
                if (object.domainsList != null) {
                    if (typeof object.domainsList !== "object")
                        throw TypeError(".ca2domains.Response.domainsList: object expected");
                    message.domainsList = $root.ca2domains.DomainsListResponse.fromObject(object.domainsList);
                }
                if (object.createOrUpdateContact != null) {
                    if (typeof object.createOrUpdateContact !== "object")
                        throw TypeError(".ca2domains.Response.createOrUpdateContact: object expected");
                    message.createOrUpdateContact = $root.ca2domains.CreateOrUpdateContactResponse.fromObject(object.createOrUpdateContact);
                }
                if (object.contactsList != null) {
                    if (typeof object.contactsList !== "object")
                        throw TypeError(".ca2domains.Response.contactsList: object expected");
                    message.contactsList = $root.ca2domains.ContactsListResponse.fromObject(object.contactsList);
                }
                if (object.deleteContact != null) {
                    if (typeof object.deleteContact !== "object")
                        throw TypeError(".ca2domains.Response.deleteContact: object expected");
                    message.deleteContact = $root.ca2domains.DeleteContactResponse.fromObject(object.deleteContact);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.Response
             * @static
             * @param {ca2domains.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.checkDomain = null;
                    object.registerDomain = null;
                    object.tldList = null;
                    object.domainsList = null;
                    object.contactsList = null;
                    object.deleteContact = null;
                    object.createOrUpdateContact = null;
                }
                if (message.checkDomain != null && message.hasOwnProperty("checkDomain"))
                    object.checkDomain = $root.ca2domains.CheckDomainResponse.toObject(message.checkDomain, options);
                if (message.registerDomain != null && message.hasOwnProperty("registerDomain"))
                    object.registerDomain = $root.ca2domains.RegisterDomainResponse.toObject(message.registerDomain, options);
                if (message.tldList != null && message.hasOwnProperty("tldList"))
                    object.tldList = $root.ca2domains.TLDListResponse.toObject(message.tldList, options);
                if (message.domainsList != null && message.hasOwnProperty("domainsList"))
                    object.domainsList = $root.ca2domains.DomainsListResponse.toObject(message.domainsList, options);
                if (message.contactsList != null && message.hasOwnProperty("contactsList"))
                    object.contactsList = $root.ca2domains.ContactsListResponse.toObject(message.contactsList, options);
                if (message.deleteContact != null && message.hasOwnProperty("deleteContact"))
                    object.deleteContact = $root.ca2domains.DeleteContactResponse.toObject(message.deleteContact, options);
                if (message.createOrUpdateContact != null && message.hasOwnProperty("createOrUpdateContact"))
                    object.createOrUpdateContact = $root.ca2domains.CreateOrUpdateContactResponse.toObject(message.createOrUpdateContact, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2domains.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2domains.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.Response";
            };
    
            return Response;
        })();
    
        /**
         * CheckDomainError enum.
         * @name ca2domains.CheckDomainError
         * @enum {number}
         * @property {number} CDN_UNKNOWN=0 CDN_UNKNOWN value
         * @property {number} CDN_INVALID_DOMAIN=1 CDN_INVALID_DOMAIN value
         */
        ca2domains.CheckDomainError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CDN_UNKNOWN"] = 0;
            values[valuesById[1] = "CDN_INVALID_DOMAIN"] = 1;
            return values;
        })();
    
        ca2domains.CheckDomainRequest = (function() {
    
            /**
             * Properties of a CheckDomainRequest.
             * @memberof ca2domains
             * @interface ICheckDomainRequest
             * @property {string|null} [domain] CheckDomainRequest domain
             * @property {boolean|null} [hideVariations] CheckDomainRequest hideVariations
             * @property {Array.<string>|null} [tldNames] CheckDomainRequest tldNames
             */
    
            /**
             * Constructs a new CheckDomainRequest.
             * @memberof ca2domains
             * @classdesc Represents a CheckDomainRequest.
             * @implements ICheckDomainRequest
             * @constructor
             * @param {ca2domains.ICheckDomainRequest=} [properties] Properties to set
             */
            function CheckDomainRequest(properties) {
                this.tldNames = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CheckDomainRequest domain.
             * @member {string} domain
             * @memberof ca2domains.CheckDomainRequest
             * @instance
             */
            CheckDomainRequest.prototype.domain = "";
    
            /**
             * CheckDomainRequest hideVariations.
             * @member {boolean} hideVariations
             * @memberof ca2domains.CheckDomainRequest
             * @instance
             */
            CheckDomainRequest.prototype.hideVariations = false;
    
            /**
             * CheckDomainRequest tldNames.
             * @member {Array.<string>} tldNames
             * @memberof ca2domains.CheckDomainRequest
             * @instance
             */
            CheckDomainRequest.prototype.tldNames = $util.emptyArray;
    
            /**
             * Creates a new CheckDomainRequest instance using the specified properties.
             * @function create
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {ca2domains.ICheckDomainRequest=} [properties] Properties to set
             * @returns {ca2domains.CheckDomainRequest} CheckDomainRequest instance
             */
            CheckDomainRequest.create = function create(properties) {
                return new CheckDomainRequest(properties);
            };
    
            /**
             * Encodes the specified CheckDomainRequest message. Does not implicitly {@link ca2domains.CheckDomainRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {ca2domains.ICheckDomainRequest} message CheckDomainRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckDomainRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.domain);
                if (message.hideVariations != null && Object.hasOwnProperty.call(message, "hideVariations"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.hideVariations);
                if (message.tldNames != null && message.tldNames.length)
                    for (var i = 0; i < message.tldNames.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.tldNames[i]);
                return writer;
            };
    
            /**
             * Encodes the specified CheckDomainRequest message, length delimited. Does not implicitly {@link ca2domains.CheckDomainRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {ca2domains.ICheckDomainRequest} message CheckDomainRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckDomainRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CheckDomainRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.CheckDomainRequest} CheckDomainRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckDomainRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.CheckDomainRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.domain = reader.string();
                            break;
                        }
                    case 4: {
                            message.hideVariations = reader.bool();
                            break;
                        }
                    case 5: {
                            if (!(message.tldNames && message.tldNames.length))
                                message.tldNames = [];
                            message.tldNames.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CheckDomainRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.CheckDomainRequest} CheckDomainRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckDomainRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CheckDomainRequest message.
             * @function verify
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckDomainRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domain != null && message.hasOwnProperty("domain"))
                    if (!$util.isString(message.domain))
                        return "domain: string expected";
                if (message.hideVariations != null && message.hasOwnProperty("hideVariations"))
                    if (typeof message.hideVariations !== "boolean")
                        return "hideVariations: boolean expected";
                if (message.tldNames != null && message.hasOwnProperty("tldNames")) {
                    if (!Array.isArray(message.tldNames))
                        return "tldNames: array expected";
                    for (var i = 0; i < message.tldNames.length; ++i)
                        if (!$util.isString(message.tldNames[i]))
                            return "tldNames: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a CheckDomainRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.CheckDomainRequest} CheckDomainRequest
             */
            CheckDomainRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.CheckDomainRequest)
                    return object;
                var message = new $root.ca2domains.CheckDomainRequest();
                if (object.domain != null)
                    message.domain = String(object.domain);
                if (object.hideVariations != null)
                    message.hideVariations = Boolean(object.hideVariations);
                if (object.tldNames) {
                    if (!Array.isArray(object.tldNames))
                        throw TypeError(".ca2domains.CheckDomainRequest.tldNames: array expected");
                    message.tldNames = [];
                    for (var i = 0; i < object.tldNames.length; ++i)
                        message.tldNames[i] = String(object.tldNames[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CheckDomainRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {ca2domains.CheckDomainRequest} message CheckDomainRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckDomainRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tldNames = [];
                if (options.defaults) {
                    object.domain = "";
                    object.hideVariations = false;
                }
                if (message.domain != null && message.hasOwnProperty("domain"))
                    object.domain = message.domain;
                if (message.hideVariations != null && message.hasOwnProperty("hideVariations"))
                    object.hideVariations = message.hideVariations;
                if (message.tldNames && message.tldNames.length) {
                    object.tldNames = [];
                    for (var j = 0; j < message.tldNames.length; ++j)
                        object.tldNames[j] = message.tldNames[j];
                }
                return object;
            };
    
            /**
             * Converts this CheckDomainRequest to JSON.
             * @function toJSON
             * @memberof ca2domains.CheckDomainRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckDomainRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CheckDomainRequest
             * @function getTypeUrl
             * @memberof ca2domains.CheckDomainRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckDomainRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.CheckDomainRequest";
            };
    
            return CheckDomainRequest;
        })();
    
        ca2domains.CheckDomainResponse = (function() {
    
            /**
             * Properties of a CheckDomainResponse.
             * @memberof ca2domains
             * @interface ICheckDomainResponse
             * @property {Array.<ca2domains.CheckDomainError>|null} [errors] CheckDomainResponse errors
             * @property {string|null} [checkId] CheckDomainResponse checkId
             * @property {Array.<ca2types.IDomainSuggestion>|null} [suggestions] CheckDomainResponse suggestions
             */
    
            /**
             * Constructs a new CheckDomainResponse.
             * @memberof ca2domains
             * @classdesc Represents a CheckDomainResponse.
             * @implements ICheckDomainResponse
             * @constructor
             * @param {ca2domains.ICheckDomainResponse=} [properties] Properties to set
             */
            function CheckDomainResponse(properties) {
                this.errors = [];
                this.suggestions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CheckDomainResponse errors.
             * @member {Array.<ca2domains.CheckDomainError>} errors
             * @memberof ca2domains.CheckDomainResponse
             * @instance
             */
            CheckDomainResponse.prototype.errors = $util.emptyArray;
    
            /**
             * CheckDomainResponse checkId.
             * @member {string} checkId
             * @memberof ca2domains.CheckDomainResponse
             * @instance
             */
            CheckDomainResponse.prototype.checkId = "";
    
            /**
             * CheckDomainResponse suggestions.
             * @member {Array.<ca2types.IDomainSuggestion>} suggestions
             * @memberof ca2domains.CheckDomainResponse
             * @instance
             */
            CheckDomainResponse.prototype.suggestions = $util.emptyArray;
    
            /**
             * Creates a new CheckDomainResponse instance using the specified properties.
             * @function create
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {ca2domains.ICheckDomainResponse=} [properties] Properties to set
             * @returns {ca2domains.CheckDomainResponse} CheckDomainResponse instance
             */
            CheckDomainResponse.create = function create(properties) {
                return new CheckDomainResponse(properties);
            };
    
            /**
             * Encodes the specified CheckDomainResponse message. Does not implicitly {@link ca2domains.CheckDomainResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {ca2domains.ICheckDomainResponse} message CheckDomainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckDomainResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.suggestions != null && message.suggestions.length)
                    for (var i = 0; i < message.suggestions.length; ++i)
                        $root.ca2types.DomainSuggestion.encode(message.suggestions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.checkId != null && Object.hasOwnProperty.call(message, "checkId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.checkId);
                return writer;
            };
    
            /**
             * Encodes the specified CheckDomainResponse message, length delimited. Does not implicitly {@link ca2domains.CheckDomainResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {ca2domains.ICheckDomainResponse} message CheckDomainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckDomainResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CheckDomainResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.CheckDomainResponse} CheckDomainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckDomainResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.CheckDomainResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 3: {
                            message.checkId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.suggestions && message.suggestions.length))
                                message.suggestions = [];
                            message.suggestions.push($root.ca2types.DomainSuggestion.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CheckDomainResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.CheckDomainResponse} CheckDomainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckDomainResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CheckDomainResponse message.
             * @function verify
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckDomainResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                            break;
                        }
                }
                if (message.checkId != null && message.hasOwnProperty("checkId"))
                    if (!$util.isString(message.checkId))
                        return "checkId: string expected";
                if (message.suggestions != null && message.hasOwnProperty("suggestions")) {
                    if (!Array.isArray(message.suggestions))
                        return "suggestions: array expected";
                    for (var i = 0; i < message.suggestions.length; ++i) {
                        var error = $root.ca2types.DomainSuggestion.verify(message.suggestions[i]);
                        if (error)
                            return "suggestions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CheckDomainResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.CheckDomainResponse} CheckDomainResponse
             */
            CheckDomainResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.CheckDomainResponse)
                    return object;
                var message = new $root.ca2domains.CheckDomainResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2domains.CheckDomainResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "CDN_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "CDN_INVALID_DOMAIN":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        }
                }
                if (object.checkId != null)
                    message.checkId = String(object.checkId);
                if (object.suggestions) {
                    if (!Array.isArray(object.suggestions))
                        throw TypeError(".ca2domains.CheckDomainResponse.suggestions: array expected");
                    message.suggestions = [];
                    for (var i = 0; i < object.suggestions.length; ++i) {
                        if (typeof object.suggestions[i] !== "object")
                            throw TypeError(".ca2domains.CheckDomainResponse.suggestions: object expected");
                        message.suggestions[i] = $root.ca2types.DomainSuggestion.fromObject(object.suggestions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CheckDomainResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {ca2domains.CheckDomainResponse} message CheckDomainResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckDomainResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.errors = [];
                    object.suggestions = [];
                }
                if (options.defaults)
                    object.checkId = "";
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2domains.CheckDomainError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2domains.CheckDomainError[message.errors[j]] : message.errors[j];
                }
                if (message.suggestions && message.suggestions.length) {
                    object.suggestions = [];
                    for (var j = 0; j < message.suggestions.length; ++j)
                        object.suggestions[j] = $root.ca2types.DomainSuggestion.toObject(message.suggestions[j], options);
                }
                if (message.checkId != null && message.hasOwnProperty("checkId"))
                    object.checkId = message.checkId;
                return object;
            };
    
            /**
             * Converts this CheckDomainResponse to JSON.
             * @function toJSON
             * @memberof ca2domains.CheckDomainResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckDomainResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CheckDomainResponse
             * @function getTypeUrl
             * @memberof ca2domains.CheckDomainResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckDomainResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.CheckDomainResponse";
            };
    
            return CheckDomainResponse;
        })();
    
        ca2domains.TLDListRequest = (function() {
    
            /**
             * Properties of a TLDListRequest.
             * @memberof ca2domains
             * @interface ITLDListRequest
             */
    
            /**
             * Constructs a new TLDListRequest.
             * @memberof ca2domains
             * @classdesc Represents a TLDListRequest.
             * @implements ITLDListRequest
             * @constructor
             * @param {ca2domains.ITLDListRequest=} [properties] Properties to set
             */
            function TLDListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new TLDListRequest instance using the specified properties.
             * @function create
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {ca2domains.ITLDListRequest=} [properties] Properties to set
             * @returns {ca2domains.TLDListRequest} TLDListRequest instance
             */
            TLDListRequest.create = function create(properties) {
                return new TLDListRequest(properties);
            };
    
            /**
             * Encodes the specified TLDListRequest message. Does not implicitly {@link ca2domains.TLDListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {ca2domains.ITLDListRequest} message TLDListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLDListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified TLDListRequest message, length delimited. Does not implicitly {@link ca2domains.TLDListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {ca2domains.ITLDListRequest} message TLDListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLDListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TLDListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.TLDListRequest} TLDListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLDListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.TLDListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TLDListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.TLDListRequest} TLDListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLDListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TLDListRequest message.
             * @function verify
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TLDListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a TLDListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.TLDListRequest} TLDListRequest
             */
            TLDListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.TLDListRequest)
                    return object;
                return new $root.ca2domains.TLDListRequest();
            };
    
            /**
             * Creates a plain object from a TLDListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {ca2domains.TLDListRequest} message TLDListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TLDListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this TLDListRequest to JSON.
             * @function toJSON
             * @memberof ca2domains.TLDListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TLDListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TLDListRequest
             * @function getTypeUrl
             * @memberof ca2domains.TLDListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TLDListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.TLDListRequest";
            };
    
            return TLDListRequest;
        })();
    
        ca2domains.TLDListResponse = (function() {
    
            /**
             * Properties of a TLDListResponse.
             * @memberof ca2domains
             * @interface ITLDListResponse
             * @property {Array.<ca2types.ITLDGroup>|null} [groups] TLDListResponse groups
             * @property {Array.<string>|null} [topTldNames] TLDListResponse topTldNames
             * @property {Array.<ca2types.ITLD>|null} [tlds] TLDListResponse tlds
             */
    
            /**
             * Constructs a new TLDListResponse.
             * @memberof ca2domains
             * @classdesc Represents a TLDListResponse.
             * @implements ITLDListResponse
             * @constructor
             * @param {ca2domains.ITLDListResponse=} [properties] Properties to set
             */
            function TLDListResponse(properties) {
                this.groups = [];
                this.topTldNames = [];
                this.tlds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TLDListResponse groups.
             * @member {Array.<ca2types.ITLDGroup>} groups
             * @memberof ca2domains.TLDListResponse
             * @instance
             */
            TLDListResponse.prototype.groups = $util.emptyArray;
    
            /**
             * TLDListResponse topTldNames.
             * @member {Array.<string>} topTldNames
             * @memberof ca2domains.TLDListResponse
             * @instance
             */
            TLDListResponse.prototype.topTldNames = $util.emptyArray;
    
            /**
             * TLDListResponse tlds.
             * @member {Array.<ca2types.ITLD>} tlds
             * @memberof ca2domains.TLDListResponse
             * @instance
             */
            TLDListResponse.prototype.tlds = $util.emptyArray;
    
            /**
             * Creates a new TLDListResponse instance using the specified properties.
             * @function create
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {ca2domains.ITLDListResponse=} [properties] Properties to set
             * @returns {ca2domains.TLDListResponse} TLDListResponse instance
             */
            TLDListResponse.create = function create(properties) {
                return new TLDListResponse(properties);
            };
    
            /**
             * Encodes the specified TLDListResponse message. Does not implicitly {@link ca2domains.TLDListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {ca2domains.ITLDListResponse} message TLDListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLDListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groups != null && message.groups.length)
                    for (var i = 0; i < message.groups.length; ++i)
                        $root.ca2types.TLDGroup.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.topTldNames != null && message.topTldNames.length)
                    for (var i = 0; i < message.topTldNames.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.topTldNames[i]);
                if (message.tlds != null && message.tlds.length)
                    for (var i = 0; i < message.tlds.length; ++i)
                        $root.ca2types.TLD.encode(message.tlds[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TLDListResponse message, length delimited. Does not implicitly {@link ca2domains.TLDListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {ca2domains.ITLDListResponse} message TLDListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TLDListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TLDListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.TLDListResponse} TLDListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLDListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.TLDListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.groups && message.groups.length))
                                message.groups = [];
                            message.groups.push($root.ca2types.TLDGroup.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.topTldNames && message.topTldNames.length))
                                message.topTldNames = [];
                            message.topTldNames.push(reader.string());
                            break;
                        }
                    case 3: {
                            if (!(message.tlds && message.tlds.length))
                                message.tlds = [];
                            message.tlds.push($root.ca2types.TLD.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TLDListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.TLDListResponse} TLDListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TLDListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TLDListResponse message.
             * @function verify
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TLDListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groups != null && message.hasOwnProperty("groups")) {
                    if (!Array.isArray(message.groups))
                        return "groups: array expected";
                    for (var i = 0; i < message.groups.length; ++i) {
                        var error = $root.ca2types.TLDGroup.verify(message.groups[i]);
                        if (error)
                            return "groups." + error;
                    }
                }
                if (message.topTldNames != null && message.hasOwnProperty("topTldNames")) {
                    if (!Array.isArray(message.topTldNames))
                        return "topTldNames: array expected";
                    for (var i = 0; i < message.topTldNames.length; ++i)
                        if (!$util.isString(message.topTldNames[i]))
                            return "topTldNames: string[] expected";
                }
                if (message.tlds != null && message.hasOwnProperty("tlds")) {
                    if (!Array.isArray(message.tlds))
                        return "tlds: array expected";
                    for (var i = 0; i < message.tlds.length; ++i) {
                        var error = $root.ca2types.TLD.verify(message.tlds[i]);
                        if (error)
                            return "tlds." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TLDListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.TLDListResponse} TLDListResponse
             */
            TLDListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.TLDListResponse)
                    return object;
                var message = new $root.ca2domains.TLDListResponse();
                if (object.groups) {
                    if (!Array.isArray(object.groups))
                        throw TypeError(".ca2domains.TLDListResponse.groups: array expected");
                    message.groups = [];
                    for (var i = 0; i < object.groups.length; ++i) {
                        if (typeof object.groups[i] !== "object")
                            throw TypeError(".ca2domains.TLDListResponse.groups: object expected");
                        message.groups[i] = $root.ca2types.TLDGroup.fromObject(object.groups[i]);
                    }
                }
                if (object.topTldNames) {
                    if (!Array.isArray(object.topTldNames))
                        throw TypeError(".ca2domains.TLDListResponse.topTldNames: array expected");
                    message.topTldNames = [];
                    for (var i = 0; i < object.topTldNames.length; ++i)
                        message.topTldNames[i] = String(object.topTldNames[i]);
                }
                if (object.tlds) {
                    if (!Array.isArray(object.tlds))
                        throw TypeError(".ca2domains.TLDListResponse.tlds: array expected");
                    message.tlds = [];
                    for (var i = 0; i < object.tlds.length; ++i) {
                        if (typeof object.tlds[i] !== "object")
                            throw TypeError(".ca2domains.TLDListResponse.tlds: object expected");
                        message.tlds[i] = $root.ca2types.TLD.fromObject(object.tlds[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TLDListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {ca2domains.TLDListResponse} message TLDListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TLDListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.groups = [];
                    object.topTldNames = [];
                    object.tlds = [];
                }
                if (message.groups && message.groups.length) {
                    object.groups = [];
                    for (var j = 0; j < message.groups.length; ++j)
                        object.groups[j] = $root.ca2types.TLDGroup.toObject(message.groups[j], options);
                }
                if (message.topTldNames && message.topTldNames.length) {
                    object.topTldNames = [];
                    for (var j = 0; j < message.topTldNames.length; ++j)
                        object.topTldNames[j] = message.topTldNames[j];
                }
                if (message.tlds && message.tlds.length) {
                    object.tlds = [];
                    for (var j = 0; j < message.tlds.length; ++j)
                        object.tlds[j] = $root.ca2types.TLD.toObject(message.tlds[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TLDListResponse to JSON.
             * @function toJSON
             * @memberof ca2domains.TLDListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TLDListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TLDListResponse
             * @function getTypeUrl
             * @memberof ca2domains.TLDListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TLDListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.TLDListResponse";
            };
    
            return TLDListResponse;
        })();
    
        ca2domains.DomainCheckWsEvent = (function() {
    
            /**
             * Properties of a DomainCheckWsEvent.
             * @memberof ca2domains
             * @interface IDomainCheckWsEvent
             * @property {string|null} [checkId] DomainCheckWsEvent checkId
             * @property {Array.<ca2types.IDomainSuggestion>|null} [suggestions] DomainCheckWsEvent suggestions
             */
    
            /**
             * Constructs a new DomainCheckWsEvent.
             * @memberof ca2domains
             * @classdesc Represents a DomainCheckWsEvent.
             * @implements IDomainCheckWsEvent
             * @constructor
             * @param {ca2domains.IDomainCheckWsEvent=} [properties] Properties to set
             */
            function DomainCheckWsEvent(properties) {
                this.suggestions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DomainCheckWsEvent checkId.
             * @member {string} checkId
             * @memberof ca2domains.DomainCheckWsEvent
             * @instance
             */
            DomainCheckWsEvent.prototype.checkId = "";
    
            /**
             * DomainCheckWsEvent suggestions.
             * @member {Array.<ca2types.IDomainSuggestion>} suggestions
             * @memberof ca2domains.DomainCheckWsEvent
             * @instance
             */
            DomainCheckWsEvent.prototype.suggestions = $util.emptyArray;
    
            /**
             * Creates a new DomainCheckWsEvent instance using the specified properties.
             * @function create
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {ca2domains.IDomainCheckWsEvent=} [properties] Properties to set
             * @returns {ca2domains.DomainCheckWsEvent} DomainCheckWsEvent instance
             */
            DomainCheckWsEvent.create = function create(properties) {
                return new DomainCheckWsEvent(properties);
            };
    
            /**
             * Encodes the specified DomainCheckWsEvent message. Does not implicitly {@link ca2domains.DomainCheckWsEvent.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {ca2domains.IDomainCheckWsEvent} message DomainCheckWsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainCheckWsEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.checkId != null && Object.hasOwnProperty.call(message, "checkId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.checkId);
                if (message.suggestions != null && message.suggestions.length)
                    for (var i = 0; i < message.suggestions.length; ++i)
                        $root.ca2types.DomainSuggestion.encode(message.suggestions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified DomainCheckWsEvent message, length delimited. Does not implicitly {@link ca2domains.DomainCheckWsEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {ca2domains.IDomainCheckWsEvent} message DomainCheckWsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainCheckWsEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DomainCheckWsEvent message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.DomainCheckWsEvent} DomainCheckWsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainCheckWsEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.DomainCheckWsEvent();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.checkId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.suggestions && message.suggestions.length))
                                message.suggestions = [];
                            message.suggestions.push($root.ca2types.DomainSuggestion.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DomainCheckWsEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.DomainCheckWsEvent} DomainCheckWsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainCheckWsEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DomainCheckWsEvent message.
             * @function verify
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainCheckWsEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.checkId != null && message.hasOwnProperty("checkId"))
                    if (!$util.isString(message.checkId))
                        return "checkId: string expected";
                if (message.suggestions != null && message.hasOwnProperty("suggestions")) {
                    if (!Array.isArray(message.suggestions))
                        return "suggestions: array expected";
                    for (var i = 0; i < message.suggestions.length; ++i) {
                        var error = $root.ca2types.DomainSuggestion.verify(message.suggestions[i]);
                        if (error)
                            return "suggestions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a DomainCheckWsEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.DomainCheckWsEvent} DomainCheckWsEvent
             */
            DomainCheckWsEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.DomainCheckWsEvent)
                    return object;
                var message = new $root.ca2domains.DomainCheckWsEvent();
                if (object.checkId != null)
                    message.checkId = String(object.checkId);
                if (object.suggestions) {
                    if (!Array.isArray(object.suggestions))
                        throw TypeError(".ca2domains.DomainCheckWsEvent.suggestions: array expected");
                    message.suggestions = [];
                    for (var i = 0; i < object.suggestions.length; ++i) {
                        if (typeof object.suggestions[i] !== "object")
                            throw TypeError(".ca2domains.DomainCheckWsEvent.suggestions: object expected");
                        message.suggestions[i] = $root.ca2types.DomainSuggestion.fromObject(object.suggestions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DomainCheckWsEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {ca2domains.DomainCheckWsEvent} message DomainCheckWsEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainCheckWsEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.suggestions = [];
                if (options.defaults)
                    object.checkId = "";
                if (message.checkId != null && message.hasOwnProperty("checkId"))
                    object.checkId = message.checkId;
                if (message.suggestions && message.suggestions.length) {
                    object.suggestions = [];
                    for (var j = 0; j < message.suggestions.length; ++j)
                        object.suggestions[j] = $root.ca2types.DomainSuggestion.toObject(message.suggestions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this DomainCheckWsEvent to JSON.
             * @function toJSON
             * @memberof ca2domains.DomainCheckWsEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainCheckWsEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DomainCheckWsEvent
             * @function getTypeUrl
             * @memberof ca2domains.DomainCheckWsEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainCheckWsEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.DomainCheckWsEvent";
            };
    
            return DomainCheckWsEvent;
        })();
    
        ca2domains.DomainEvent = (function() {
    
            /**
             * Properties of a DomainEvent.
             * @memberof ca2domains
             * @interface IDomainEvent
             * @property {Array.<ca2types.IDomain>|null} [domains] DomainEvent domains
             */
    
            /**
             * Constructs a new DomainEvent.
             * @memberof ca2domains
             * @classdesc Represents a DomainEvent.
             * @implements IDomainEvent
             * @constructor
             * @param {ca2domains.IDomainEvent=} [properties] Properties to set
             */
            function DomainEvent(properties) {
                this.domains = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DomainEvent domains.
             * @member {Array.<ca2types.IDomain>} domains
             * @memberof ca2domains.DomainEvent
             * @instance
             */
            DomainEvent.prototype.domains = $util.emptyArray;
    
            /**
             * Creates a new DomainEvent instance using the specified properties.
             * @function create
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {ca2domains.IDomainEvent=} [properties] Properties to set
             * @returns {ca2domains.DomainEvent} DomainEvent instance
             */
            DomainEvent.create = function create(properties) {
                return new DomainEvent(properties);
            };
    
            /**
             * Encodes the specified DomainEvent message. Does not implicitly {@link ca2domains.DomainEvent.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {ca2domains.IDomainEvent} message DomainEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domains != null && message.domains.length)
                    for (var i = 0; i < message.domains.length; ++i)
                        $root.ca2types.Domain.encode(message.domains[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified DomainEvent message, length delimited. Does not implicitly {@link ca2domains.DomainEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {ca2domains.IDomainEvent} message DomainEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DomainEvent message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.DomainEvent} DomainEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.DomainEvent();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.domains && message.domains.length))
                                message.domains = [];
                            message.domains.push($root.ca2types.Domain.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DomainEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.DomainEvent} DomainEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DomainEvent message.
             * @function verify
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domains != null && message.hasOwnProperty("domains")) {
                    if (!Array.isArray(message.domains))
                        return "domains: array expected";
                    for (var i = 0; i < message.domains.length; ++i) {
                        var error = $root.ca2types.Domain.verify(message.domains[i]);
                        if (error)
                            return "domains." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a DomainEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.DomainEvent} DomainEvent
             */
            DomainEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.DomainEvent)
                    return object;
                var message = new $root.ca2domains.DomainEvent();
                if (object.domains) {
                    if (!Array.isArray(object.domains))
                        throw TypeError(".ca2domains.DomainEvent.domains: array expected");
                    message.domains = [];
                    for (var i = 0; i < object.domains.length; ++i) {
                        if (typeof object.domains[i] !== "object")
                            throw TypeError(".ca2domains.DomainEvent.domains: object expected");
                        message.domains[i] = $root.ca2types.Domain.fromObject(object.domains[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DomainEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {ca2domains.DomainEvent} message DomainEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.domains = [];
                if (message.domains && message.domains.length) {
                    object.domains = [];
                    for (var j = 0; j < message.domains.length; ++j)
                        object.domains[j] = $root.ca2types.Domain.toObject(message.domains[j], options);
                }
                return object;
            };
    
            /**
             * Converts this DomainEvent to JSON.
             * @function toJSON
             * @memberof ca2domains.DomainEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DomainEvent
             * @function getTypeUrl
             * @memberof ca2domains.DomainEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.DomainEvent";
            };
    
            return DomainEvent;
        })();
    
        ca2domains.RegisterDomainRequest = (function() {
    
            /**
             * Properties of a RegisterDomainRequest.
             * @memberof ca2domains
             * @interface IRegisterDomainRequest
             * @property {boolean|null} [dryRun] RegisterDomainRequest dryRun
             * @property {string|null} [domain] RegisterDomainRequest domain
             * @property {number|null} [termYears] RegisterDomainRequest termYears
             * @property {boolean|null} [whoisPrivacy] RegisterDomainRequest whoisPrivacy
             * @property {boolean|null} [autoRenew] RegisterDomainRequest autoRenew
             * @property {number|null} [existingContactId] RegisterDomainRequest existingContactId
             * @property {ca2types.IDomainContact|null} [newContact] RegisterDomainRequest newContact
             */
    
            /**
             * Constructs a new RegisterDomainRequest.
             * @memberof ca2domains
             * @classdesc Represents a RegisterDomainRequest.
             * @implements IRegisterDomainRequest
             * @constructor
             * @param {ca2domains.IRegisterDomainRequest=} [properties] Properties to set
             */
            function RegisterDomainRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RegisterDomainRequest dryRun.
             * @member {boolean} dryRun
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            RegisterDomainRequest.prototype.dryRun = false;
    
            /**
             * RegisterDomainRequest domain.
             * @member {string} domain
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            RegisterDomainRequest.prototype.domain = "";
    
            /**
             * RegisterDomainRequest termYears.
             * @member {number} termYears
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            RegisterDomainRequest.prototype.termYears = 0;
    
            /**
             * RegisterDomainRequest whoisPrivacy.
             * @member {boolean} whoisPrivacy
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            RegisterDomainRequest.prototype.whoisPrivacy = false;
    
            /**
             * RegisterDomainRequest autoRenew.
             * @member {boolean} autoRenew
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            RegisterDomainRequest.prototype.autoRenew = false;
    
            /**
             * RegisterDomainRequest existingContactId.
             * @member {number|null|undefined} existingContactId
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            RegisterDomainRequest.prototype.existingContactId = null;
    
            /**
             * RegisterDomainRequest newContact.
             * @member {ca2types.IDomainContact|null|undefined} newContact
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            RegisterDomainRequest.prototype.newContact = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * RegisterDomainRequest contact.
             * @member {"existingContactId"|"newContact"|undefined} contact
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             */
            Object.defineProperty(RegisterDomainRequest.prototype, "contact", {
                get: $util.oneOfGetter($oneOfFields = ["existingContactId", "newContact"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new RegisterDomainRequest instance using the specified properties.
             * @function create
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {ca2domains.IRegisterDomainRequest=} [properties] Properties to set
             * @returns {ca2domains.RegisterDomainRequest} RegisterDomainRequest instance
             */
            RegisterDomainRequest.create = function create(properties) {
                return new RegisterDomainRequest(properties);
            };
    
            /**
             * Encodes the specified RegisterDomainRequest message. Does not implicitly {@link ca2domains.RegisterDomainRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {ca2domains.IRegisterDomainRequest} message RegisterDomainRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterDomainRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.dryRun);
                if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.domain);
                if (message.termYears != null && Object.hasOwnProperty.call(message, "termYears"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.termYears);
                if (message.existingContactId != null && Object.hasOwnProperty.call(message, "existingContactId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.existingContactId);
                if (message.newContact != null && Object.hasOwnProperty.call(message, "newContact"))
                    $root.ca2types.DomainContact.encode(message.newContact, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.whoisPrivacy != null && Object.hasOwnProperty.call(message, "whoisPrivacy"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.whoisPrivacy);
                if (message.autoRenew != null && Object.hasOwnProperty.call(message, "autoRenew"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.autoRenew);
                return writer;
            };
    
            /**
             * Encodes the specified RegisterDomainRequest message, length delimited. Does not implicitly {@link ca2domains.RegisterDomainRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {ca2domains.IRegisterDomainRequest} message RegisterDomainRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterDomainRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RegisterDomainRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.RegisterDomainRequest} RegisterDomainRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterDomainRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.RegisterDomainRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dryRun = reader.bool();
                            break;
                        }
                    case 2: {
                            message.domain = reader.string();
                            break;
                        }
                    case 3: {
                            message.termYears = reader.uint32();
                            break;
                        }
                    case 6: {
                            message.whoisPrivacy = reader.bool();
                            break;
                        }
                    case 7: {
                            message.autoRenew = reader.bool();
                            break;
                        }
                    case 4: {
                            message.existingContactId = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.newContact = $root.ca2types.DomainContact.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RegisterDomainRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.RegisterDomainRequest} RegisterDomainRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterDomainRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RegisterDomainRequest message.
             * @function verify
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegisterDomainRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    if (typeof message.dryRun !== "boolean")
                        return "dryRun: boolean expected";
                if (message.domain != null && message.hasOwnProperty("domain"))
                    if (!$util.isString(message.domain))
                        return "domain: string expected";
                if (message.termYears != null && message.hasOwnProperty("termYears"))
                    if (!$util.isInteger(message.termYears))
                        return "termYears: integer expected";
                if (message.whoisPrivacy != null && message.hasOwnProperty("whoisPrivacy"))
                    if (typeof message.whoisPrivacy !== "boolean")
                        return "whoisPrivacy: boolean expected";
                if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                    if (typeof message.autoRenew !== "boolean")
                        return "autoRenew: boolean expected";
                if (message.existingContactId != null && message.hasOwnProperty("existingContactId")) {
                    properties.contact = 1;
                    if (!$util.isInteger(message.existingContactId))
                        return "existingContactId: integer expected";
                }
                if (message.newContact != null && message.hasOwnProperty("newContact")) {
                    if (properties.contact === 1)
                        return "contact: multiple values";
                    properties.contact = 1;
                    {
                        var error = $root.ca2types.DomainContact.verify(message.newContact);
                        if (error)
                            return "newContact." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a RegisterDomainRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.RegisterDomainRequest} RegisterDomainRequest
             */
            RegisterDomainRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.RegisterDomainRequest)
                    return object;
                var message = new $root.ca2domains.RegisterDomainRequest();
                if (object.dryRun != null)
                    message.dryRun = Boolean(object.dryRun);
                if (object.domain != null)
                    message.domain = String(object.domain);
                if (object.termYears != null)
                    message.termYears = object.termYears >>> 0;
                if (object.whoisPrivacy != null)
                    message.whoisPrivacy = Boolean(object.whoisPrivacy);
                if (object.autoRenew != null)
                    message.autoRenew = Boolean(object.autoRenew);
                if (object.existingContactId != null)
                    message.existingContactId = object.existingContactId >>> 0;
                if (object.newContact != null) {
                    if (typeof object.newContact !== "object")
                        throw TypeError(".ca2domains.RegisterDomainRequest.newContact: object expected");
                    message.newContact = $root.ca2types.DomainContact.fromObject(object.newContact);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RegisterDomainRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {ca2domains.RegisterDomainRequest} message RegisterDomainRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegisterDomainRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.dryRun = false;
                    object.domain = "";
                    object.termYears = 0;
                    object.whoisPrivacy = false;
                    object.autoRenew = false;
                }
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    object.dryRun = message.dryRun;
                if (message.domain != null && message.hasOwnProperty("domain"))
                    object.domain = message.domain;
                if (message.termYears != null && message.hasOwnProperty("termYears"))
                    object.termYears = message.termYears;
                if (message.existingContactId != null && message.hasOwnProperty("existingContactId")) {
                    object.existingContactId = message.existingContactId;
                    if (options.oneofs)
                        object.contact = "existingContactId";
                }
                if (message.newContact != null && message.hasOwnProperty("newContact")) {
                    object.newContact = $root.ca2types.DomainContact.toObject(message.newContact, options);
                    if (options.oneofs)
                        object.contact = "newContact";
                }
                if (message.whoisPrivacy != null && message.hasOwnProperty("whoisPrivacy"))
                    object.whoisPrivacy = message.whoisPrivacy;
                if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                    object.autoRenew = message.autoRenew;
                return object;
            };
    
            /**
             * Converts this RegisterDomainRequest to JSON.
             * @function toJSON
             * @memberof ca2domains.RegisterDomainRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegisterDomainRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RegisterDomainRequest
             * @function getTypeUrl
             * @memberof ca2domains.RegisterDomainRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RegisterDomainRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.RegisterDomainRequest";
            };
    
            return RegisterDomainRequest;
        })();
    
        /**
         * RegisterDomainError enum.
         * @name ca2domains.RegisterDomainError
         * @enum {number}
         * @property {number} RDE_UNKNOWN=0 RDE_UNKNOWN value
         * @property {number} RDE_INVALID_OR_NOT_AVAILABLE_DOMAIN=1 RDE_INVALID_OR_NOT_AVAILABLE_DOMAIN value
         * @property {number} RDE_NOT_ENOUGH_BALANCE=2 RDE_NOT_ENOUGH_BALANCE value
         * @property {number} RDE_INVALID_TERM_YEARS=3 RDE_INVALID_TERM_YEARS value
         * @property {number} RDE_INVALID_EXISTING_CONTACT_ID=4 RDE_INVALID_EXISTING_CONTACT_ID value
         * @property {number} RDE_INVALID_NEW_CONTACT=5 RDE_INVALID_NEW_CONTACT value
         * @property {number} RDE_EXISTING_CONTACT_SHOULD_BE_FIXED=6 RDE_EXISTING_CONTACT_SHOULD_BE_FIXED value
         */
        ca2domains.RegisterDomainError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RDE_UNKNOWN"] = 0;
            values[valuesById[1] = "RDE_INVALID_OR_NOT_AVAILABLE_DOMAIN"] = 1;
            values[valuesById[2] = "RDE_NOT_ENOUGH_BALANCE"] = 2;
            values[valuesById[3] = "RDE_INVALID_TERM_YEARS"] = 3;
            values[valuesById[4] = "RDE_INVALID_EXISTING_CONTACT_ID"] = 4;
            values[valuesById[5] = "RDE_INVALID_NEW_CONTACT"] = 5;
            values[valuesById[6] = "RDE_EXISTING_CONTACT_SHOULD_BE_FIXED"] = 6;
            return values;
        })();
    
        ca2domains.RegisterDomainResponse = (function() {
    
            /**
             * Properties of a RegisterDomainResponse.
             * @memberof ca2domains
             * @interface IRegisterDomainResponse
             * @property {boolean|null} [dryRun] RegisterDomainResponse dryRun
             * @property {Array.<ca2domains.RegisterDomainError>|null} [errors] RegisterDomainResponse errors
             * @property {Array.<ca2domains.ContactError>|null} [contactErrors] RegisterDomainResponse contactErrors
             * @property {ca2types.IDomain|null} [domain] RegisterDomainResponse domain
             */
    
            /**
             * Constructs a new RegisterDomainResponse.
             * @memberof ca2domains
             * @classdesc Represents a RegisterDomainResponse.
             * @implements IRegisterDomainResponse
             * @constructor
             * @param {ca2domains.IRegisterDomainResponse=} [properties] Properties to set
             */
            function RegisterDomainResponse(properties) {
                this.errors = [];
                this.contactErrors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RegisterDomainResponse dryRun.
             * @member {boolean} dryRun
             * @memberof ca2domains.RegisterDomainResponse
             * @instance
             */
            RegisterDomainResponse.prototype.dryRun = false;
    
            /**
             * RegisterDomainResponse errors.
             * @member {Array.<ca2domains.RegisterDomainError>} errors
             * @memberof ca2domains.RegisterDomainResponse
             * @instance
             */
            RegisterDomainResponse.prototype.errors = $util.emptyArray;
    
            /**
             * RegisterDomainResponse contactErrors.
             * @member {Array.<ca2domains.ContactError>} contactErrors
             * @memberof ca2domains.RegisterDomainResponse
             * @instance
             */
            RegisterDomainResponse.prototype.contactErrors = $util.emptyArray;
    
            /**
             * RegisterDomainResponse domain.
             * @member {ca2types.IDomain|null|undefined} domain
             * @memberof ca2domains.RegisterDomainResponse
             * @instance
             */
            RegisterDomainResponse.prototype.domain = null;
    
            /**
             * Creates a new RegisterDomainResponse instance using the specified properties.
             * @function create
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {ca2domains.IRegisterDomainResponse=} [properties] Properties to set
             * @returns {ca2domains.RegisterDomainResponse} RegisterDomainResponse instance
             */
            RegisterDomainResponse.create = function create(properties) {
                return new RegisterDomainResponse(properties);
            };
    
            /**
             * Encodes the specified RegisterDomainResponse message. Does not implicitly {@link ca2domains.RegisterDomainResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {ca2domains.IRegisterDomainResponse} message RegisterDomainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterDomainResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.dryRun);
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.contactErrors != null && message.contactErrors.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.contactErrors.length; ++i)
                        writer.int32(message.contactErrors[i]);
                    writer.ldelim();
                }
                if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                    $root.ca2types.Domain.encode(message.domain, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RegisterDomainResponse message, length delimited. Does not implicitly {@link ca2domains.RegisterDomainResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {ca2domains.IRegisterDomainResponse} message RegisterDomainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterDomainResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RegisterDomainResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.RegisterDomainResponse} RegisterDomainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterDomainResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.RegisterDomainResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dryRun = reader.bool();
                            break;
                        }
                    case 2: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 3: {
                            if (!(message.contactErrors && message.contactErrors.length))
                                message.contactErrors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.contactErrors.push(reader.int32());
                            } else
                                message.contactErrors.push(reader.int32());
                            break;
                        }
                    case 4: {
                            message.domain = $root.ca2types.Domain.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RegisterDomainResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.RegisterDomainResponse} RegisterDomainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterDomainResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RegisterDomainResponse message.
             * @function verify
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegisterDomainResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    if (typeof message.dryRun !== "boolean")
                        return "dryRun: boolean expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.contactErrors != null && message.hasOwnProperty("contactErrors")) {
                    if (!Array.isArray(message.contactErrors))
                        return "contactErrors: array expected";
                    for (var i = 0; i < message.contactErrors.length; ++i)
                        switch (message.contactErrors[i]) {
                        default:
                            return "contactErrors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                            break;
                        }
                }
                if (message.domain != null && message.hasOwnProperty("domain")) {
                    var error = $root.ca2types.Domain.verify(message.domain);
                    if (error)
                        return "domain." + error;
                }
                return null;
            };
    
            /**
             * Creates a RegisterDomainResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.RegisterDomainResponse} RegisterDomainResponse
             */
            RegisterDomainResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.RegisterDomainResponse)
                    return object;
                var message = new $root.ca2domains.RegisterDomainResponse();
                if (object.dryRun != null)
                    message.dryRun = Boolean(object.dryRun);
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2domains.RegisterDomainResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "RDE_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "RDE_INVALID_OR_NOT_AVAILABLE_DOMAIN":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "RDE_NOT_ENOUGH_BALANCE":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "RDE_INVALID_TERM_YEARS":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "RDE_INVALID_EXISTING_CONTACT_ID":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        case "RDE_INVALID_NEW_CONTACT":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        case "RDE_EXISTING_CONTACT_SHOULD_BE_FIXED":
                        case 6:
                            message.errors[i] = 6;
                            break;
                        }
                }
                if (object.contactErrors) {
                    if (!Array.isArray(object.contactErrors))
                        throw TypeError(".ca2domains.RegisterDomainResponse.contactErrors: array expected");
                    message.contactErrors = [];
                    for (var i = 0; i < object.contactErrors.length; ++i)
                        switch (object.contactErrors[i]) {
                        default:
                            if (typeof object.contactErrors[i] === "number") {
                                message.contactErrors[i] = object.contactErrors[i];
                                break;
                            }
                        case "COCE_UNKNOWN":
                        case 0:
                            message.contactErrors[i] = 0;
                            break;
                        case "COCE_INVALID_CONTACT_ID":
                        case 1:
                            message.contactErrors[i] = 1;
                            break;
                        case "COCE_FIRST_NAME_REQUIRED":
                        case 2:
                            message.contactErrors[i] = 2;
                            break;
                        case "COCE_LAST_NAME_REQUIRED":
                        case 3:
                            message.contactErrors[i] = 3;
                            break;
                        case "COCE_MAILING_ADDRESS_REQUIRED":
                        case 4:
                            message.contactErrors[i] = 4;
                            break;
                        case "COCE_MAILING_CITY_REQUIRED":
                        case 5:
                            message.contactErrors[i] = 5;
                            break;
                        case "COCE_MAILING_STATE_REQUIRED":
                        case 6:
                            message.contactErrors[i] = 6;
                            break;
                        case "COCE_MAILING_ZIP_REQUIRED":
                        case 7:
                            message.contactErrors[i] = 7;
                            break;
                        case "COCE_MAILING_COUNTRY_CODE_REQUIRED":
                        case 8:
                            message.contactErrors[i] = 8;
                            break;
                        case "COCE_EMAIL_ADDRESS_REQUIRED":
                        case 9:
                            message.contactErrors[i] = 9;
                            break;
                        case "COCE_PHONE_NUMBER_REQUIRED":
                        case 10:
                            message.contactErrors[i] = 10;
                            break;
                        case "COCE_EMAIL_ADDRESS_INVALID":
                        case 11:
                            message.contactErrors[i] = 11;
                            break;
                        case "COCE_PHONE_NUMBER_INVALID":
                        case 12:
                            message.contactErrors[i] = 12;
                            break;
                        }
                }
                if (object.domain != null) {
                    if (typeof object.domain !== "object")
                        throw TypeError(".ca2domains.RegisterDomainResponse.domain: object expected");
                    message.domain = $root.ca2types.Domain.fromObject(object.domain);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RegisterDomainResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {ca2domains.RegisterDomainResponse} message RegisterDomainResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegisterDomainResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.errors = [];
                    object.contactErrors = [];
                }
                if (options.defaults) {
                    object.dryRun = false;
                    object.domain = null;
                }
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    object.dryRun = message.dryRun;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2domains.RegisterDomainError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2domains.RegisterDomainError[message.errors[j]] : message.errors[j];
                }
                if (message.contactErrors && message.contactErrors.length) {
                    object.contactErrors = [];
                    for (var j = 0; j < message.contactErrors.length; ++j)
                        object.contactErrors[j] = options.enums === String ? $root.ca2domains.ContactError[message.contactErrors[j]] === undefined ? message.contactErrors[j] : $root.ca2domains.ContactError[message.contactErrors[j]] : message.contactErrors[j];
                }
                if (message.domain != null && message.hasOwnProperty("domain"))
                    object.domain = $root.ca2types.Domain.toObject(message.domain, options);
                return object;
            };
    
            /**
             * Converts this RegisterDomainResponse to JSON.
             * @function toJSON
             * @memberof ca2domains.RegisterDomainResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegisterDomainResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RegisterDomainResponse
             * @function getTypeUrl
             * @memberof ca2domains.RegisterDomainResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RegisterDomainResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.RegisterDomainResponse";
            };
    
            return RegisterDomainResponse;
        })();
    
        ca2domains.DomainsListRequest = (function() {
    
            /**
             * Properties of a DomainsListRequest.
             * @memberof ca2domains
             * @interface IDomainsListRequest
             */
    
            /**
             * Constructs a new DomainsListRequest.
             * @memberof ca2domains
             * @classdesc Represents a DomainsListRequest.
             * @implements IDomainsListRequest
             * @constructor
             * @param {ca2domains.IDomainsListRequest=} [properties] Properties to set
             */
            function DomainsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new DomainsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {ca2domains.IDomainsListRequest=} [properties] Properties to set
             * @returns {ca2domains.DomainsListRequest} DomainsListRequest instance
             */
            DomainsListRequest.create = function create(properties) {
                return new DomainsListRequest(properties);
            };
    
            /**
             * Encodes the specified DomainsListRequest message. Does not implicitly {@link ca2domains.DomainsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {ca2domains.IDomainsListRequest} message DomainsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified DomainsListRequest message, length delimited. Does not implicitly {@link ca2domains.DomainsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {ca2domains.IDomainsListRequest} message DomainsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DomainsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.DomainsListRequest} DomainsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.DomainsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DomainsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.DomainsListRequest} DomainsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DomainsListRequest message.
             * @function verify
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a DomainsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.DomainsListRequest} DomainsListRequest
             */
            DomainsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.DomainsListRequest)
                    return object;
                return new $root.ca2domains.DomainsListRequest();
            };
    
            /**
             * Creates a plain object from a DomainsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {ca2domains.DomainsListRequest} message DomainsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this DomainsListRequest to JSON.
             * @function toJSON
             * @memberof ca2domains.DomainsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DomainsListRequest
             * @function getTypeUrl
             * @memberof ca2domains.DomainsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.DomainsListRequest";
            };
    
            return DomainsListRequest;
        })();
    
        ca2domains.DomainsListResponse = (function() {
    
            /**
             * Properties of a DomainsListResponse.
             * @memberof ca2domains
             * @interface IDomainsListResponse
             * @property {Array.<ca2types.IDomain>|null} [domains] DomainsListResponse domains
             */
    
            /**
             * Constructs a new DomainsListResponse.
             * @memberof ca2domains
             * @classdesc Represents a DomainsListResponse.
             * @implements IDomainsListResponse
             * @constructor
             * @param {ca2domains.IDomainsListResponse=} [properties] Properties to set
             */
            function DomainsListResponse(properties) {
                this.domains = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DomainsListResponse domains.
             * @member {Array.<ca2types.IDomain>} domains
             * @memberof ca2domains.DomainsListResponse
             * @instance
             */
            DomainsListResponse.prototype.domains = $util.emptyArray;
    
            /**
             * Creates a new DomainsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {ca2domains.IDomainsListResponse=} [properties] Properties to set
             * @returns {ca2domains.DomainsListResponse} DomainsListResponse instance
             */
            DomainsListResponse.create = function create(properties) {
                return new DomainsListResponse(properties);
            };
    
            /**
             * Encodes the specified DomainsListResponse message. Does not implicitly {@link ca2domains.DomainsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {ca2domains.IDomainsListResponse} message DomainsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domains != null && message.domains.length)
                    for (var i = 0; i < message.domains.length; ++i)
                        $root.ca2types.Domain.encode(message.domains[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified DomainsListResponse message, length delimited. Does not implicitly {@link ca2domains.DomainsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {ca2domains.IDomainsListResponse} message DomainsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DomainsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.DomainsListResponse} DomainsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.DomainsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.domains && message.domains.length))
                                message.domains = [];
                            message.domains.push($root.ca2types.Domain.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DomainsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.DomainsListResponse} DomainsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DomainsListResponse message.
             * @function verify
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domains != null && message.hasOwnProperty("domains")) {
                    if (!Array.isArray(message.domains))
                        return "domains: array expected";
                    for (var i = 0; i < message.domains.length; ++i) {
                        var error = $root.ca2types.Domain.verify(message.domains[i]);
                        if (error)
                            return "domains." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a DomainsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.DomainsListResponse} DomainsListResponse
             */
            DomainsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.DomainsListResponse)
                    return object;
                var message = new $root.ca2domains.DomainsListResponse();
                if (object.domains) {
                    if (!Array.isArray(object.domains))
                        throw TypeError(".ca2domains.DomainsListResponse.domains: array expected");
                    message.domains = [];
                    for (var i = 0; i < object.domains.length; ++i) {
                        if (typeof object.domains[i] !== "object")
                            throw TypeError(".ca2domains.DomainsListResponse.domains: object expected");
                        message.domains[i] = $root.ca2types.Domain.fromObject(object.domains[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DomainsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {ca2domains.DomainsListResponse} message DomainsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.domains = [];
                if (message.domains && message.domains.length) {
                    object.domains = [];
                    for (var j = 0; j < message.domains.length; ++j)
                        object.domains[j] = $root.ca2types.Domain.toObject(message.domains[j], options);
                }
                return object;
            };
    
            /**
             * Converts this DomainsListResponse to JSON.
             * @function toJSON
             * @memberof ca2domains.DomainsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DomainsListResponse
             * @function getTypeUrl
             * @memberof ca2domains.DomainsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.DomainsListResponse";
            };
    
            return DomainsListResponse;
        })();
    
        /**
         * ContactError enum.
         * @name ca2domains.ContactError
         * @enum {number}
         * @property {number} COCE_UNKNOWN=0 COCE_UNKNOWN value
         * @property {number} COCE_INVALID_CONTACT_ID=1 COCE_INVALID_CONTACT_ID value
         * @property {number} COCE_FIRST_NAME_REQUIRED=2 COCE_FIRST_NAME_REQUIRED value
         * @property {number} COCE_LAST_NAME_REQUIRED=3 COCE_LAST_NAME_REQUIRED value
         * @property {number} COCE_MAILING_ADDRESS_REQUIRED=4 COCE_MAILING_ADDRESS_REQUIRED value
         * @property {number} COCE_MAILING_CITY_REQUIRED=5 COCE_MAILING_CITY_REQUIRED value
         * @property {number} COCE_MAILING_STATE_REQUIRED=6 COCE_MAILING_STATE_REQUIRED value
         * @property {number} COCE_MAILING_ZIP_REQUIRED=7 COCE_MAILING_ZIP_REQUIRED value
         * @property {number} COCE_MAILING_COUNTRY_CODE_REQUIRED=8 COCE_MAILING_COUNTRY_CODE_REQUIRED value
         * @property {number} COCE_EMAIL_ADDRESS_REQUIRED=9 COCE_EMAIL_ADDRESS_REQUIRED value
         * @property {number} COCE_PHONE_NUMBER_REQUIRED=10 COCE_PHONE_NUMBER_REQUIRED value
         * @property {number} COCE_EMAIL_ADDRESS_INVALID=11 COCE_EMAIL_ADDRESS_INVALID value
         * @property {number} COCE_PHONE_NUMBER_INVALID=12 COCE_PHONE_NUMBER_INVALID value
         */
        ca2domains.ContactError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "COCE_UNKNOWN"] = 0;
            values[valuesById[1] = "COCE_INVALID_CONTACT_ID"] = 1;
            values[valuesById[2] = "COCE_FIRST_NAME_REQUIRED"] = 2;
            values[valuesById[3] = "COCE_LAST_NAME_REQUIRED"] = 3;
            values[valuesById[4] = "COCE_MAILING_ADDRESS_REQUIRED"] = 4;
            values[valuesById[5] = "COCE_MAILING_CITY_REQUIRED"] = 5;
            values[valuesById[6] = "COCE_MAILING_STATE_REQUIRED"] = 6;
            values[valuesById[7] = "COCE_MAILING_ZIP_REQUIRED"] = 7;
            values[valuesById[8] = "COCE_MAILING_COUNTRY_CODE_REQUIRED"] = 8;
            values[valuesById[9] = "COCE_EMAIL_ADDRESS_REQUIRED"] = 9;
            values[valuesById[10] = "COCE_PHONE_NUMBER_REQUIRED"] = 10;
            values[valuesById[11] = "COCE_EMAIL_ADDRESS_INVALID"] = 11;
            values[valuesById[12] = "COCE_PHONE_NUMBER_INVALID"] = 12;
            return values;
        })();
    
        ca2domains.CreateOrUpdateContactRequest = (function() {
    
            /**
             * Properties of a CreateOrUpdateContactRequest.
             * @memberof ca2domains
             * @interface ICreateOrUpdateContactRequest
             * @property {ca2types.IDomainContact|null} [contact] CreateOrUpdateContactRequest contact
             */
    
            /**
             * Constructs a new CreateOrUpdateContactRequest.
             * @memberof ca2domains
             * @classdesc Represents a CreateOrUpdateContactRequest.
             * @implements ICreateOrUpdateContactRequest
             * @constructor
             * @param {ca2domains.ICreateOrUpdateContactRequest=} [properties] Properties to set
             */
            function CreateOrUpdateContactRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateOrUpdateContactRequest contact.
             * @member {ca2types.IDomainContact|null|undefined} contact
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @instance
             */
            CreateOrUpdateContactRequest.prototype.contact = null;
    
            /**
             * Creates a new CreateOrUpdateContactRequest instance using the specified properties.
             * @function create
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {ca2domains.ICreateOrUpdateContactRequest=} [properties] Properties to set
             * @returns {ca2domains.CreateOrUpdateContactRequest} CreateOrUpdateContactRequest instance
             */
            CreateOrUpdateContactRequest.create = function create(properties) {
                return new CreateOrUpdateContactRequest(properties);
            };
    
            /**
             * Encodes the specified CreateOrUpdateContactRequest message. Does not implicitly {@link ca2domains.CreateOrUpdateContactRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {ca2domains.ICreateOrUpdateContactRequest} message CreateOrUpdateContactRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrUpdateContactRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.contact != null && Object.hasOwnProperty.call(message, "contact"))
                    $root.ca2types.DomainContact.encode(message.contact, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateOrUpdateContactRequest message, length delimited. Does not implicitly {@link ca2domains.CreateOrUpdateContactRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {ca2domains.ICreateOrUpdateContactRequest} message CreateOrUpdateContactRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrUpdateContactRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateOrUpdateContactRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.CreateOrUpdateContactRequest} CreateOrUpdateContactRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrUpdateContactRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.CreateOrUpdateContactRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.contact = $root.ca2types.DomainContact.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateOrUpdateContactRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.CreateOrUpdateContactRequest} CreateOrUpdateContactRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrUpdateContactRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateOrUpdateContactRequest message.
             * @function verify
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateOrUpdateContactRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.contact != null && message.hasOwnProperty("contact")) {
                    var error = $root.ca2types.DomainContact.verify(message.contact);
                    if (error)
                        return "contact." + error;
                }
                return null;
            };
    
            /**
             * Creates a CreateOrUpdateContactRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.CreateOrUpdateContactRequest} CreateOrUpdateContactRequest
             */
            CreateOrUpdateContactRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.CreateOrUpdateContactRequest)
                    return object;
                var message = new $root.ca2domains.CreateOrUpdateContactRequest();
                if (object.contact != null) {
                    if (typeof object.contact !== "object")
                        throw TypeError(".ca2domains.CreateOrUpdateContactRequest.contact: object expected");
                    message.contact = $root.ca2types.DomainContact.fromObject(object.contact);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateOrUpdateContactRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {ca2domains.CreateOrUpdateContactRequest} message CreateOrUpdateContactRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateOrUpdateContactRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.contact = null;
                if (message.contact != null && message.hasOwnProperty("contact"))
                    object.contact = $root.ca2types.DomainContact.toObject(message.contact, options);
                return object;
            };
    
            /**
             * Converts this CreateOrUpdateContactRequest to JSON.
             * @function toJSON
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateOrUpdateContactRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateOrUpdateContactRequest
             * @function getTypeUrl
             * @memberof ca2domains.CreateOrUpdateContactRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateOrUpdateContactRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.CreateOrUpdateContactRequest";
            };
    
            return CreateOrUpdateContactRequest;
        })();
    
        ca2domains.CreateOrUpdateContactResponse = (function() {
    
            /**
             * Properties of a CreateOrUpdateContactResponse.
             * @memberof ca2domains
             * @interface ICreateOrUpdateContactResponse
             * @property {ca2types.IDomainContact|null} [contact] CreateOrUpdateContactResponse contact
             * @property {Array.<ca2domains.ContactError>|null} [errors] CreateOrUpdateContactResponse errors
             */
    
            /**
             * Constructs a new CreateOrUpdateContactResponse.
             * @memberof ca2domains
             * @classdesc Represents a CreateOrUpdateContactResponse.
             * @implements ICreateOrUpdateContactResponse
             * @constructor
             * @param {ca2domains.ICreateOrUpdateContactResponse=} [properties] Properties to set
             */
            function CreateOrUpdateContactResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateOrUpdateContactResponse contact.
             * @member {ca2types.IDomainContact|null|undefined} contact
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @instance
             */
            CreateOrUpdateContactResponse.prototype.contact = null;
    
            /**
             * CreateOrUpdateContactResponse errors.
             * @member {Array.<ca2domains.ContactError>} errors
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @instance
             */
            CreateOrUpdateContactResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new CreateOrUpdateContactResponse instance using the specified properties.
             * @function create
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {ca2domains.ICreateOrUpdateContactResponse=} [properties] Properties to set
             * @returns {ca2domains.CreateOrUpdateContactResponse} CreateOrUpdateContactResponse instance
             */
            CreateOrUpdateContactResponse.create = function create(properties) {
                return new CreateOrUpdateContactResponse(properties);
            };
    
            /**
             * Encodes the specified CreateOrUpdateContactResponse message. Does not implicitly {@link ca2domains.CreateOrUpdateContactResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {ca2domains.ICreateOrUpdateContactResponse} message CreateOrUpdateContactResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrUpdateContactResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.contact != null && Object.hasOwnProperty.call(message, "contact"))
                    $root.ca2types.DomainContact.encode(message.contact, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified CreateOrUpdateContactResponse message, length delimited. Does not implicitly {@link ca2domains.CreateOrUpdateContactResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {ca2domains.ICreateOrUpdateContactResponse} message CreateOrUpdateContactResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrUpdateContactResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateOrUpdateContactResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.CreateOrUpdateContactResponse} CreateOrUpdateContactResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrUpdateContactResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.CreateOrUpdateContactResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.contact = $root.ca2types.DomainContact.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateOrUpdateContactResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.CreateOrUpdateContactResponse} CreateOrUpdateContactResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrUpdateContactResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateOrUpdateContactResponse message.
             * @function verify
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateOrUpdateContactResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.contact != null && message.hasOwnProperty("contact")) {
                    var error = $root.ca2types.DomainContact.verify(message.contact);
                    if (error)
                        return "contact." + error;
                }
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a CreateOrUpdateContactResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.CreateOrUpdateContactResponse} CreateOrUpdateContactResponse
             */
            CreateOrUpdateContactResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.CreateOrUpdateContactResponse)
                    return object;
                var message = new $root.ca2domains.CreateOrUpdateContactResponse();
                if (object.contact != null) {
                    if (typeof object.contact !== "object")
                        throw TypeError(".ca2domains.CreateOrUpdateContactResponse.contact: object expected");
                    message.contact = $root.ca2types.DomainContact.fromObject(object.contact);
                }
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2domains.CreateOrUpdateContactResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "COCE_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "COCE_INVALID_CONTACT_ID":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "COCE_FIRST_NAME_REQUIRED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "COCE_LAST_NAME_REQUIRED":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "COCE_MAILING_ADDRESS_REQUIRED":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        case "COCE_MAILING_CITY_REQUIRED":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        case "COCE_MAILING_STATE_REQUIRED":
                        case 6:
                            message.errors[i] = 6;
                            break;
                        case "COCE_MAILING_ZIP_REQUIRED":
                        case 7:
                            message.errors[i] = 7;
                            break;
                        case "COCE_MAILING_COUNTRY_CODE_REQUIRED":
                        case 8:
                            message.errors[i] = 8;
                            break;
                        case "COCE_EMAIL_ADDRESS_REQUIRED":
                        case 9:
                            message.errors[i] = 9;
                            break;
                        case "COCE_PHONE_NUMBER_REQUIRED":
                        case 10:
                            message.errors[i] = 10;
                            break;
                        case "COCE_EMAIL_ADDRESS_INVALID":
                        case 11:
                            message.errors[i] = 11;
                            break;
                        case "COCE_PHONE_NUMBER_INVALID":
                        case 12:
                            message.errors[i] = 12;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateOrUpdateContactResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {ca2domains.CreateOrUpdateContactResponse} message CreateOrUpdateContactResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateOrUpdateContactResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.contact = null;
                if (message.contact != null && message.hasOwnProperty("contact"))
                    object.contact = $root.ca2types.DomainContact.toObject(message.contact, options);
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2domains.ContactError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2domains.ContactError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this CreateOrUpdateContactResponse to JSON.
             * @function toJSON
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateOrUpdateContactResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateOrUpdateContactResponse
             * @function getTypeUrl
             * @memberof ca2domains.CreateOrUpdateContactResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateOrUpdateContactResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.CreateOrUpdateContactResponse";
            };
    
            return CreateOrUpdateContactResponse;
        })();
    
        ca2domains.ContactsListRequest = (function() {
    
            /**
             * Properties of a ContactsListRequest.
             * @memberof ca2domains
             * @interface IContactsListRequest
             */
    
            /**
             * Constructs a new ContactsListRequest.
             * @memberof ca2domains
             * @classdesc Represents a ContactsListRequest.
             * @implements IContactsListRequest
             * @constructor
             * @param {ca2domains.IContactsListRequest=} [properties] Properties to set
             */
            function ContactsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ContactsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {ca2domains.IContactsListRequest=} [properties] Properties to set
             * @returns {ca2domains.ContactsListRequest} ContactsListRequest instance
             */
            ContactsListRequest.create = function create(properties) {
                return new ContactsListRequest(properties);
            };
    
            /**
             * Encodes the specified ContactsListRequest message. Does not implicitly {@link ca2domains.ContactsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {ca2domains.IContactsListRequest} message ContactsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ContactsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ContactsListRequest message, length delimited. Does not implicitly {@link ca2domains.ContactsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {ca2domains.IContactsListRequest} message ContactsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ContactsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ContactsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.ContactsListRequest} ContactsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ContactsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.ContactsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ContactsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.ContactsListRequest} ContactsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ContactsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ContactsListRequest message.
             * @function verify
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ContactsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ContactsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.ContactsListRequest} ContactsListRequest
             */
            ContactsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.ContactsListRequest)
                    return object;
                return new $root.ca2domains.ContactsListRequest();
            };
    
            /**
             * Creates a plain object from a ContactsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {ca2domains.ContactsListRequest} message ContactsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ContactsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ContactsListRequest to JSON.
             * @function toJSON
             * @memberof ca2domains.ContactsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ContactsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ContactsListRequest
             * @function getTypeUrl
             * @memberof ca2domains.ContactsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ContactsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.ContactsListRequest";
            };
    
            return ContactsListRequest;
        })();
    
        ca2domains.ContactsListResponse = (function() {
    
            /**
             * Properties of a ContactsListResponse.
             * @memberof ca2domains
             * @interface IContactsListResponse
             * @property {Array.<ca2types.IDomainContact>|null} [contacts] ContactsListResponse contacts
             */
    
            /**
             * Constructs a new ContactsListResponse.
             * @memberof ca2domains
             * @classdesc Represents a ContactsListResponse.
             * @implements IContactsListResponse
             * @constructor
             * @param {ca2domains.IContactsListResponse=} [properties] Properties to set
             */
            function ContactsListResponse(properties) {
                this.contacts = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ContactsListResponse contacts.
             * @member {Array.<ca2types.IDomainContact>} contacts
             * @memberof ca2domains.ContactsListResponse
             * @instance
             */
            ContactsListResponse.prototype.contacts = $util.emptyArray;
    
            /**
             * Creates a new ContactsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {ca2domains.IContactsListResponse=} [properties] Properties to set
             * @returns {ca2domains.ContactsListResponse} ContactsListResponse instance
             */
            ContactsListResponse.create = function create(properties) {
                return new ContactsListResponse(properties);
            };
    
            /**
             * Encodes the specified ContactsListResponse message. Does not implicitly {@link ca2domains.ContactsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {ca2domains.IContactsListResponse} message ContactsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ContactsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.contacts != null && message.contacts.length)
                    for (var i = 0; i < message.contacts.length; ++i)
                        $root.ca2types.DomainContact.encode(message.contacts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ContactsListResponse message, length delimited. Does not implicitly {@link ca2domains.ContactsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {ca2domains.IContactsListResponse} message ContactsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ContactsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ContactsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.ContactsListResponse} ContactsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ContactsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.ContactsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.contacts && message.contacts.length))
                                message.contacts = [];
                            message.contacts.push($root.ca2types.DomainContact.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ContactsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.ContactsListResponse} ContactsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ContactsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ContactsListResponse message.
             * @function verify
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ContactsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.contacts != null && message.hasOwnProperty("contacts")) {
                    if (!Array.isArray(message.contacts))
                        return "contacts: array expected";
                    for (var i = 0; i < message.contacts.length; ++i) {
                        var error = $root.ca2types.DomainContact.verify(message.contacts[i]);
                        if (error)
                            return "contacts." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ContactsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.ContactsListResponse} ContactsListResponse
             */
            ContactsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.ContactsListResponse)
                    return object;
                var message = new $root.ca2domains.ContactsListResponse();
                if (object.contacts) {
                    if (!Array.isArray(object.contacts))
                        throw TypeError(".ca2domains.ContactsListResponse.contacts: array expected");
                    message.contacts = [];
                    for (var i = 0; i < object.contacts.length; ++i) {
                        if (typeof object.contacts[i] !== "object")
                            throw TypeError(".ca2domains.ContactsListResponse.contacts: object expected");
                        message.contacts[i] = $root.ca2types.DomainContact.fromObject(object.contacts[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ContactsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {ca2domains.ContactsListResponse} message ContactsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ContactsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.contacts = [];
                if (message.contacts && message.contacts.length) {
                    object.contacts = [];
                    for (var j = 0; j < message.contacts.length; ++j)
                        object.contacts[j] = $root.ca2types.DomainContact.toObject(message.contacts[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ContactsListResponse to JSON.
             * @function toJSON
             * @memberof ca2domains.ContactsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ContactsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ContactsListResponse
             * @function getTypeUrl
             * @memberof ca2domains.ContactsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ContactsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.ContactsListResponse";
            };
    
            return ContactsListResponse;
        })();
    
        ca2domains.DeleteContactRequest = (function() {
    
            /**
             * Properties of a DeleteContactRequest.
             * @memberof ca2domains
             * @interface IDeleteContactRequest
             * @property {number|null} [contactId] DeleteContactRequest contactId
             */
    
            /**
             * Constructs a new DeleteContactRequest.
             * @memberof ca2domains
             * @classdesc Represents a DeleteContactRequest.
             * @implements IDeleteContactRequest
             * @constructor
             * @param {ca2domains.IDeleteContactRequest=} [properties] Properties to set
             */
            function DeleteContactRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteContactRequest contactId.
             * @member {number} contactId
             * @memberof ca2domains.DeleteContactRequest
             * @instance
             */
            DeleteContactRequest.prototype.contactId = 0;
    
            /**
             * Creates a new DeleteContactRequest instance using the specified properties.
             * @function create
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {ca2domains.IDeleteContactRequest=} [properties] Properties to set
             * @returns {ca2domains.DeleteContactRequest} DeleteContactRequest instance
             */
            DeleteContactRequest.create = function create(properties) {
                return new DeleteContactRequest(properties);
            };
    
            /**
             * Encodes the specified DeleteContactRequest message. Does not implicitly {@link ca2domains.DeleteContactRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {ca2domains.IDeleteContactRequest} message DeleteContactRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteContactRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.contactId != null && Object.hasOwnProperty.call(message, "contactId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.contactId);
                return writer;
            };
    
            /**
             * Encodes the specified DeleteContactRequest message, length delimited. Does not implicitly {@link ca2domains.DeleteContactRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {ca2domains.IDeleteContactRequest} message DeleteContactRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteContactRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteContactRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.DeleteContactRequest} DeleteContactRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteContactRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.DeleteContactRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.contactId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteContactRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.DeleteContactRequest} DeleteContactRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteContactRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteContactRequest message.
             * @function verify
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteContactRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.contactId != null && message.hasOwnProperty("contactId"))
                    if (!$util.isInteger(message.contactId))
                        return "contactId: integer expected";
                return null;
            };
    
            /**
             * Creates a DeleteContactRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.DeleteContactRequest} DeleteContactRequest
             */
            DeleteContactRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.DeleteContactRequest)
                    return object;
                var message = new $root.ca2domains.DeleteContactRequest();
                if (object.contactId != null)
                    message.contactId = object.contactId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteContactRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {ca2domains.DeleteContactRequest} message DeleteContactRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteContactRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.contactId = 0;
                if (message.contactId != null && message.hasOwnProperty("contactId"))
                    object.contactId = message.contactId;
                return object;
            };
    
            /**
             * Converts this DeleteContactRequest to JSON.
             * @function toJSON
             * @memberof ca2domains.DeleteContactRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteContactRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeleteContactRequest
             * @function getTypeUrl
             * @memberof ca2domains.DeleteContactRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteContactRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.DeleteContactRequest";
            };
    
            return DeleteContactRequest;
        })();
    
        /**
         * DeleteContactError enum.
         * @name ca2domains.DeleteContactError
         * @enum {number}
         * @property {number} DCE_UNKNOWN=0 DCE_UNKNOWN value
         * @property {number} DCE_CONTACT_NOT_FOUND=1 DCE_CONTACT_NOT_FOUND value
         * @property {number} DCE_CONTACT_USED=2 DCE_CONTACT_USED value
         */
        ca2domains.DeleteContactError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DCE_UNKNOWN"] = 0;
            values[valuesById[1] = "DCE_CONTACT_NOT_FOUND"] = 1;
            values[valuesById[2] = "DCE_CONTACT_USED"] = 2;
            return values;
        })();
    
        ca2domains.DeleteContactResponse = (function() {
    
            /**
             * Properties of a DeleteContactResponse.
             * @memberof ca2domains
             * @interface IDeleteContactResponse
             * @property {Array.<ca2domains.DeleteContactError>|null} [errors] DeleteContactResponse errors
             */
    
            /**
             * Constructs a new DeleteContactResponse.
             * @memberof ca2domains
             * @classdesc Represents a DeleteContactResponse.
             * @implements IDeleteContactResponse
             * @constructor
             * @param {ca2domains.IDeleteContactResponse=} [properties] Properties to set
             */
            function DeleteContactResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteContactResponse errors.
             * @member {Array.<ca2domains.DeleteContactError>} errors
             * @memberof ca2domains.DeleteContactResponse
             * @instance
             */
            DeleteContactResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new DeleteContactResponse instance using the specified properties.
             * @function create
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {ca2domains.IDeleteContactResponse=} [properties] Properties to set
             * @returns {ca2domains.DeleteContactResponse} DeleteContactResponse instance
             */
            DeleteContactResponse.create = function create(properties) {
                return new DeleteContactResponse(properties);
            };
    
            /**
             * Encodes the specified DeleteContactResponse message. Does not implicitly {@link ca2domains.DeleteContactResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {ca2domains.IDeleteContactResponse} message DeleteContactResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteContactResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified DeleteContactResponse message, length delimited. Does not implicitly {@link ca2domains.DeleteContactResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {ca2domains.IDeleteContactResponse} message DeleteContactResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteContactResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteContactResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2domains.DeleteContactResponse} DeleteContactResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteContactResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2domains.DeleteContactResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteContactResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2domains.DeleteContactResponse} DeleteContactResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteContactResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteContactResponse message.
             * @function verify
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteContactResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a DeleteContactResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2domains.DeleteContactResponse} DeleteContactResponse
             */
            DeleteContactResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2domains.DeleteContactResponse)
                    return object;
                var message = new $root.ca2domains.DeleteContactResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2domains.DeleteContactResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "DCE_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "DCE_CONTACT_NOT_FOUND":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "DCE_CONTACT_USED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteContactResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {ca2domains.DeleteContactResponse} message DeleteContactResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteContactResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2domains.DeleteContactError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2domains.DeleteContactError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this DeleteContactResponse to JSON.
             * @function toJSON
             * @memberof ca2domains.DeleteContactResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteContactResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeleteContactResponse
             * @function getTypeUrl
             * @memberof ca2domains.DeleteContactResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteContactResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2domains.DeleteContactResponse";
            };
    
            return DeleteContactResponse;
        })();
    
        return ca2domains;
    })();
    
    $root.ca2instances = (function() {
    
        /**
         * Namespace ca2instances.
         * @exports ca2instances
         * @namespace
         */
        var ca2instances = {};
    
        ca2instances.WsEvent = (function() {
    
            /**
             * Properties of a WsEvent.
             * @memberof ca2instances
             * @interface IWsEvent
             * @property {Array.<ca2types.IInstance>|null} [updatedInstances] WsEvent updatedInstances
             */
    
            /**
             * Constructs a new WsEvent.
             * @memberof ca2instances
             * @classdesc Represents a WsEvent.
             * @implements IWsEvent
             * @constructor
             * @param {ca2instances.IWsEvent=} [properties] Properties to set
             */
            function WsEvent(properties) {
                this.updatedInstances = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * WsEvent updatedInstances.
             * @member {Array.<ca2types.IInstance>} updatedInstances
             * @memberof ca2instances.WsEvent
             * @instance
             */
            WsEvent.prototype.updatedInstances = $util.emptyArray;
    
            /**
             * Creates a new WsEvent instance using the specified properties.
             * @function create
             * @memberof ca2instances.WsEvent
             * @static
             * @param {ca2instances.IWsEvent=} [properties] Properties to set
             * @returns {ca2instances.WsEvent} WsEvent instance
             */
            WsEvent.create = function create(properties) {
                return new WsEvent(properties);
            };
    
            /**
             * Encodes the specified WsEvent message. Does not implicitly {@link ca2instances.WsEvent.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.WsEvent
             * @static
             * @param {ca2instances.IWsEvent} message WsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.updatedInstances != null && message.updatedInstances.length)
                    for (var i = 0; i < message.updatedInstances.length; ++i)
                        $root.ca2types.Instance.encode(message.updatedInstances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified WsEvent message, length delimited. Does not implicitly {@link ca2instances.WsEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.WsEvent
             * @static
             * @param {ca2instances.IWsEvent} message WsEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WsEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a WsEvent message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.WsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.WsEvent} WsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.WsEvent();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.updatedInstances && message.updatedInstances.length))
                                message.updatedInstances = [];
                            message.updatedInstances.push($root.ca2types.Instance.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a WsEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.WsEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.WsEvent} WsEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WsEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a WsEvent message.
             * @function verify
             * @memberof ca2instances.WsEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WsEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.updatedInstances != null && message.hasOwnProperty("updatedInstances")) {
                    if (!Array.isArray(message.updatedInstances))
                        return "updatedInstances: array expected";
                    for (var i = 0; i < message.updatedInstances.length; ++i) {
                        var error = $root.ca2types.Instance.verify(message.updatedInstances[i]);
                        if (error)
                            return "updatedInstances." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a WsEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.WsEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.WsEvent} WsEvent
             */
            WsEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.WsEvent)
                    return object;
                var message = new $root.ca2instances.WsEvent();
                if (object.updatedInstances) {
                    if (!Array.isArray(object.updatedInstances))
                        throw TypeError(".ca2instances.WsEvent.updatedInstances: array expected");
                    message.updatedInstances = [];
                    for (var i = 0; i < object.updatedInstances.length; ++i) {
                        if (typeof object.updatedInstances[i] !== "object")
                            throw TypeError(".ca2instances.WsEvent.updatedInstances: object expected");
                        message.updatedInstances[i] = $root.ca2types.Instance.fromObject(object.updatedInstances[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a WsEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.WsEvent
             * @static
             * @param {ca2instances.WsEvent} message WsEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WsEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.updatedInstances = [];
                if (message.updatedInstances && message.updatedInstances.length) {
                    object.updatedInstances = [];
                    for (var j = 0; j < message.updatedInstances.length; ++j)
                        object.updatedInstances[j] = $root.ca2types.Instance.toObject(message.updatedInstances[j], options);
                }
                return object;
            };
    
            /**
             * Converts this WsEvent to JSON.
             * @function toJSON
             * @memberof ca2instances.WsEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WsEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for WsEvent
             * @function getTypeUrl
             * @memberof ca2instances.WsEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WsEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.WsEvent";
            };
    
            return WsEvent;
        })();
    
        ca2instances.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2instances
             * @interface IRequest
             * @property {ca2instances.ICreateInstanceRequest|null} [createInstance] Request createInstance
             * @property {ca2instances.IDeleteInstanceRequest|null} [deleteInstance] Request deleteInstance
             * @property {ca2instances.IInstanceChangeListRequest|null} [instanceChangeList] Request instanceChangeList
             * @property {ca2instances.IInstanceListRequest|null} [instanceList] Request instanceList
             * @property {ca2instances.IReinstallInstanceRequest|null} [reinstallInstance] Request reinstallInstance
             * @property {ca2instances.IRestartInstanceRequest|null} [restartInstance] Request restartInstance
             * @property {ca2instances.IStartInstanceRequest|null} [startInstance] Request startInstance
             * @property {ca2instances.IStopInstanceRequest|null} [stopInstance] Request stopInstance
             * @property {ca2instances.IUpdateInstanceRequest|null} [updateInstance] Request updateInstance
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2instances
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2instances.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request createInstance.
             * @member {ca2instances.ICreateInstanceRequest|null|undefined} createInstance
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.createInstance = null;
    
            /**
             * Request deleteInstance.
             * @member {ca2instances.IDeleteInstanceRequest|null|undefined} deleteInstance
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.deleteInstance = null;
    
            /**
             * Request instanceChangeList.
             * @member {ca2instances.IInstanceChangeListRequest|null|undefined} instanceChangeList
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.instanceChangeList = null;
    
            /**
             * Request instanceList.
             * @member {ca2instances.IInstanceListRequest|null|undefined} instanceList
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.instanceList = null;
    
            /**
             * Request reinstallInstance.
             * @member {ca2instances.IReinstallInstanceRequest|null|undefined} reinstallInstance
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.reinstallInstance = null;
    
            /**
             * Request restartInstance.
             * @member {ca2instances.IRestartInstanceRequest|null|undefined} restartInstance
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.restartInstance = null;
    
            /**
             * Request startInstance.
             * @member {ca2instances.IStartInstanceRequest|null|undefined} startInstance
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.startInstance = null;
    
            /**
             * Request stopInstance.
             * @member {ca2instances.IStopInstanceRequest|null|undefined} stopInstance
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.stopInstance = null;
    
            /**
             * Request updateInstance.
             * @member {ca2instances.IUpdateInstanceRequest|null|undefined} updateInstance
             * @memberof ca2instances.Request
             * @instance
             */
            Request.prototype.updateInstance = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2instances.Request
             * @static
             * @param {ca2instances.IRequest=} [properties] Properties to set
             * @returns {ca2instances.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2instances.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.Request
             * @static
             * @param {ca2instances.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.createInstance != null && Object.hasOwnProperty.call(message, "createInstance"))
                    $root.ca2instances.CreateInstanceRequest.encode(message.createInstance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.deleteInstance != null && Object.hasOwnProperty.call(message, "deleteInstance"))
                    $root.ca2instances.DeleteInstanceRequest.encode(message.deleteInstance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.instanceList != null && Object.hasOwnProperty.call(message, "instanceList"))
                    $root.ca2instances.InstanceListRequest.encode(message.instanceList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.stopInstance != null && Object.hasOwnProperty.call(message, "stopInstance"))
                    $root.ca2instances.StopInstanceRequest.encode(message.stopInstance, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.restartInstance != null && Object.hasOwnProperty.call(message, "restartInstance"))
                    $root.ca2instances.RestartInstanceRequest.encode(message.restartInstance, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.startInstance != null && Object.hasOwnProperty.call(message, "startInstance"))
                    $root.ca2instances.StartInstanceRequest.encode(message.startInstance, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.reinstallInstance != null && Object.hasOwnProperty.call(message, "reinstallInstance"))
                    $root.ca2instances.ReinstallInstanceRequest.encode(message.reinstallInstance, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.instanceChangeList != null && Object.hasOwnProperty.call(message, "instanceChangeList"))
                    $root.ca2instances.InstanceChangeListRequest.encode(message.instanceChangeList, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.updateInstance != null && Object.hasOwnProperty.call(message, "updateInstance"))
                    $root.ca2instances.UpdateInstanceRequest.encode(message.updateInstance, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2instances.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.Request
             * @static
             * @param {ca2instances.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.createInstance = $root.ca2instances.CreateInstanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.deleteInstance = $root.ca2instances.DeleteInstanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.instanceChangeList = $root.ca2instances.InstanceChangeListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.instanceList = $root.ca2instances.InstanceListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.reinstallInstance = $root.ca2instances.ReinstallInstanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.restartInstance = $root.ca2instances.RestartInstanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.startInstance = $root.ca2instances.StartInstanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.stopInstance = $root.ca2instances.StopInstanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.updateInstance = $root.ca2instances.UpdateInstanceRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2instances.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.createInstance != null && message.hasOwnProperty("createInstance")) {
                    var error = $root.ca2instances.CreateInstanceRequest.verify(message.createInstance);
                    if (error)
                        return "createInstance." + error;
                }
                if (message.deleteInstance != null && message.hasOwnProperty("deleteInstance")) {
                    var error = $root.ca2instances.DeleteInstanceRequest.verify(message.deleteInstance);
                    if (error)
                        return "deleteInstance." + error;
                }
                if (message.instanceChangeList != null && message.hasOwnProperty("instanceChangeList")) {
                    var error = $root.ca2instances.InstanceChangeListRequest.verify(message.instanceChangeList);
                    if (error)
                        return "instanceChangeList." + error;
                }
                if (message.instanceList != null && message.hasOwnProperty("instanceList")) {
                    var error = $root.ca2instances.InstanceListRequest.verify(message.instanceList);
                    if (error)
                        return "instanceList." + error;
                }
                if (message.reinstallInstance != null && message.hasOwnProperty("reinstallInstance")) {
                    var error = $root.ca2instances.ReinstallInstanceRequest.verify(message.reinstallInstance);
                    if (error)
                        return "reinstallInstance." + error;
                }
                if (message.restartInstance != null && message.hasOwnProperty("restartInstance")) {
                    var error = $root.ca2instances.RestartInstanceRequest.verify(message.restartInstance);
                    if (error)
                        return "restartInstance." + error;
                }
                if (message.startInstance != null && message.hasOwnProperty("startInstance")) {
                    var error = $root.ca2instances.StartInstanceRequest.verify(message.startInstance);
                    if (error)
                        return "startInstance." + error;
                }
                if (message.stopInstance != null && message.hasOwnProperty("stopInstance")) {
                    var error = $root.ca2instances.StopInstanceRequest.verify(message.stopInstance);
                    if (error)
                        return "stopInstance." + error;
                }
                if (message.updateInstance != null && message.hasOwnProperty("updateInstance")) {
                    var error = $root.ca2instances.UpdateInstanceRequest.verify(message.updateInstance);
                    if (error)
                        return "updateInstance." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.Request)
                    return object;
                var message = new $root.ca2instances.Request();
                if (object.createInstance != null) {
                    if (typeof object.createInstance !== "object")
                        throw TypeError(".ca2instances.Request.createInstance: object expected");
                    message.createInstance = $root.ca2instances.CreateInstanceRequest.fromObject(object.createInstance);
                }
                if (object.deleteInstance != null) {
                    if (typeof object.deleteInstance !== "object")
                        throw TypeError(".ca2instances.Request.deleteInstance: object expected");
                    message.deleteInstance = $root.ca2instances.DeleteInstanceRequest.fromObject(object.deleteInstance);
                }
                if (object.instanceChangeList != null) {
                    if (typeof object.instanceChangeList !== "object")
                        throw TypeError(".ca2instances.Request.instanceChangeList: object expected");
                    message.instanceChangeList = $root.ca2instances.InstanceChangeListRequest.fromObject(object.instanceChangeList);
                }
                if (object.instanceList != null) {
                    if (typeof object.instanceList !== "object")
                        throw TypeError(".ca2instances.Request.instanceList: object expected");
                    message.instanceList = $root.ca2instances.InstanceListRequest.fromObject(object.instanceList);
                }
                if (object.reinstallInstance != null) {
                    if (typeof object.reinstallInstance !== "object")
                        throw TypeError(".ca2instances.Request.reinstallInstance: object expected");
                    message.reinstallInstance = $root.ca2instances.ReinstallInstanceRequest.fromObject(object.reinstallInstance);
                }
                if (object.restartInstance != null) {
                    if (typeof object.restartInstance !== "object")
                        throw TypeError(".ca2instances.Request.restartInstance: object expected");
                    message.restartInstance = $root.ca2instances.RestartInstanceRequest.fromObject(object.restartInstance);
                }
                if (object.startInstance != null) {
                    if (typeof object.startInstance !== "object")
                        throw TypeError(".ca2instances.Request.startInstance: object expected");
                    message.startInstance = $root.ca2instances.StartInstanceRequest.fromObject(object.startInstance);
                }
                if (object.stopInstance != null) {
                    if (typeof object.stopInstance !== "object")
                        throw TypeError(".ca2instances.Request.stopInstance: object expected");
                    message.stopInstance = $root.ca2instances.StopInstanceRequest.fromObject(object.stopInstance);
                }
                if (object.updateInstance != null) {
                    if (typeof object.updateInstance !== "object")
                        throw TypeError(".ca2instances.Request.updateInstance: object expected");
                    message.updateInstance = $root.ca2instances.UpdateInstanceRequest.fromObject(object.updateInstance);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.Request
             * @static
             * @param {ca2instances.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.createInstance = null;
                    object.deleteInstance = null;
                    object.instanceList = null;
                    object.stopInstance = null;
                    object.restartInstance = null;
                    object.startInstance = null;
                    object.reinstallInstance = null;
                    object.instanceChangeList = null;
                    object.updateInstance = null;
                }
                if (message.createInstance != null && message.hasOwnProperty("createInstance"))
                    object.createInstance = $root.ca2instances.CreateInstanceRequest.toObject(message.createInstance, options);
                if (message.deleteInstance != null && message.hasOwnProperty("deleteInstance"))
                    object.deleteInstance = $root.ca2instances.DeleteInstanceRequest.toObject(message.deleteInstance, options);
                if (message.instanceList != null && message.hasOwnProperty("instanceList"))
                    object.instanceList = $root.ca2instances.InstanceListRequest.toObject(message.instanceList, options);
                if (message.stopInstance != null && message.hasOwnProperty("stopInstance"))
                    object.stopInstance = $root.ca2instances.StopInstanceRequest.toObject(message.stopInstance, options);
                if (message.restartInstance != null && message.hasOwnProperty("restartInstance"))
                    object.restartInstance = $root.ca2instances.RestartInstanceRequest.toObject(message.restartInstance, options);
                if (message.startInstance != null && message.hasOwnProperty("startInstance"))
                    object.startInstance = $root.ca2instances.StartInstanceRequest.toObject(message.startInstance, options);
                if (message.reinstallInstance != null && message.hasOwnProperty("reinstallInstance"))
                    object.reinstallInstance = $root.ca2instances.ReinstallInstanceRequest.toObject(message.reinstallInstance, options);
                if (message.instanceChangeList != null && message.hasOwnProperty("instanceChangeList"))
                    object.instanceChangeList = $root.ca2instances.InstanceChangeListRequest.toObject(message.instanceChangeList, options);
                if (message.updateInstance != null && message.hasOwnProperty("updateInstance"))
                    object.updateInstance = $root.ca2instances.UpdateInstanceRequest.toObject(message.updateInstance, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2instances.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2instances.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.Request";
            };
    
            return Request;
        })();
    
        ca2instances.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2instances
             * @interface IResponse
             * @property {ca2instances.ICreateInstanceResponse|null} [createInstance] Response createInstance
             * @property {ca2instances.IDeleteInstanceResponse|null} [deleteInstance] Response deleteInstance
             * @property {ca2instances.IInstanceChangeListResponse|null} [instanceChangeList] Response instanceChangeList
             * @property {ca2instances.IInstanceListResponse|null} [instanceList] Response instanceList
             * @property {ca2instances.IReinstallInstanceResponse|null} [reinstallInstance] Response reinstallInstance
             * @property {ca2instances.IRestartInstanceResponse|null} [restartInstance] Response restartInstance
             * @property {ca2instances.IStartInstanceResponse|null} [startInstance] Response startInstance
             * @property {ca2instances.IStopInstanceResponse|null} [stopInstance] Response stopInstance
             * @property {ca2instances.IUpdateInstanceResponse|null} [updateInstance] Response updateInstance
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2instances
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2instances.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response createInstance.
             * @member {ca2instances.ICreateInstanceResponse|null|undefined} createInstance
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.createInstance = null;
    
            /**
             * Response deleteInstance.
             * @member {ca2instances.IDeleteInstanceResponse|null|undefined} deleteInstance
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.deleteInstance = null;
    
            /**
             * Response instanceChangeList.
             * @member {ca2instances.IInstanceChangeListResponse|null|undefined} instanceChangeList
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.instanceChangeList = null;
    
            /**
             * Response instanceList.
             * @member {ca2instances.IInstanceListResponse|null|undefined} instanceList
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.instanceList = null;
    
            /**
             * Response reinstallInstance.
             * @member {ca2instances.IReinstallInstanceResponse|null|undefined} reinstallInstance
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.reinstallInstance = null;
    
            /**
             * Response restartInstance.
             * @member {ca2instances.IRestartInstanceResponse|null|undefined} restartInstance
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.restartInstance = null;
    
            /**
             * Response startInstance.
             * @member {ca2instances.IStartInstanceResponse|null|undefined} startInstance
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.startInstance = null;
    
            /**
             * Response stopInstance.
             * @member {ca2instances.IStopInstanceResponse|null|undefined} stopInstance
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.stopInstance = null;
    
            /**
             * Response updateInstance.
             * @member {ca2instances.IUpdateInstanceResponse|null|undefined} updateInstance
             * @memberof ca2instances.Response
             * @instance
             */
            Response.prototype.updateInstance = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2instances.Response
             * @static
             * @param {ca2instances.IResponse=} [properties] Properties to set
             * @returns {ca2instances.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2instances.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.Response
             * @static
             * @param {ca2instances.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.createInstance != null && Object.hasOwnProperty.call(message, "createInstance"))
                    $root.ca2instances.CreateInstanceResponse.encode(message.createInstance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.deleteInstance != null && Object.hasOwnProperty.call(message, "deleteInstance"))
                    $root.ca2instances.DeleteInstanceResponse.encode(message.deleteInstance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.instanceList != null && Object.hasOwnProperty.call(message, "instanceList"))
                    $root.ca2instances.InstanceListResponse.encode(message.instanceList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.stopInstance != null && Object.hasOwnProperty.call(message, "stopInstance"))
                    $root.ca2instances.StopInstanceResponse.encode(message.stopInstance, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.restartInstance != null && Object.hasOwnProperty.call(message, "restartInstance"))
                    $root.ca2instances.RestartInstanceResponse.encode(message.restartInstance, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.startInstance != null && Object.hasOwnProperty.call(message, "startInstance"))
                    $root.ca2instances.StartInstanceResponse.encode(message.startInstance, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.reinstallInstance != null && Object.hasOwnProperty.call(message, "reinstallInstance"))
                    $root.ca2instances.ReinstallInstanceResponse.encode(message.reinstallInstance, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.instanceChangeList != null && Object.hasOwnProperty.call(message, "instanceChangeList"))
                    $root.ca2instances.InstanceChangeListResponse.encode(message.instanceChangeList, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.updateInstance != null && Object.hasOwnProperty.call(message, "updateInstance"))
                    $root.ca2instances.UpdateInstanceResponse.encode(message.updateInstance, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2instances.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.Response
             * @static
             * @param {ca2instances.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.createInstance = $root.ca2instances.CreateInstanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.deleteInstance = $root.ca2instances.DeleteInstanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.instanceChangeList = $root.ca2instances.InstanceChangeListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.instanceList = $root.ca2instances.InstanceListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.reinstallInstance = $root.ca2instances.ReinstallInstanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.restartInstance = $root.ca2instances.RestartInstanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.startInstance = $root.ca2instances.StartInstanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.stopInstance = $root.ca2instances.StopInstanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.updateInstance = $root.ca2instances.UpdateInstanceResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2instances.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.createInstance != null && message.hasOwnProperty("createInstance")) {
                    var error = $root.ca2instances.CreateInstanceResponse.verify(message.createInstance);
                    if (error)
                        return "createInstance." + error;
                }
                if (message.deleteInstance != null && message.hasOwnProperty("deleteInstance")) {
                    var error = $root.ca2instances.DeleteInstanceResponse.verify(message.deleteInstance);
                    if (error)
                        return "deleteInstance." + error;
                }
                if (message.instanceChangeList != null && message.hasOwnProperty("instanceChangeList")) {
                    var error = $root.ca2instances.InstanceChangeListResponse.verify(message.instanceChangeList);
                    if (error)
                        return "instanceChangeList." + error;
                }
                if (message.instanceList != null && message.hasOwnProperty("instanceList")) {
                    var error = $root.ca2instances.InstanceListResponse.verify(message.instanceList);
                    if (error)
                        return "instanceList." + error;
                }
                if (message.reinstallInstance != null && message.hasOwnProperty("reinstallInstance")) {
                    var error = $root.ca2instances.ReinstallInstanceResponse.verify(message.reinstallInstance);
                    if (error)
                        return "reinstallInstance." + error;
                }
                if (message.restartInstance != null && message.hasOwnProperty("restartInstance")) {
                    var error = $root.ca2instances.RestartInstanceResponse.verify(message.restartInstance);
                    if (error)
                        return "restartInstance." + error;
                }
                if (message.startInstance != null && message.hasOwnProperty("startInstance")) {
                    var error = $root.ca2instances.StartInstanceResponse.verify(message.startInstance);
                    if (error)
                        return "startInstance." + error;
                }
                if (message.stopInstance != null && message.hasOwnProperty("stopInstance")) {
                    var error = $root.ca2instances.StopInstanceResponse.verify(message.stopInstance);
                    if (error)
                        return "stopInstance." + error;
                }
                if (message.updateInstance != null && message.hasOwnProperty("updateInstance")) {
                    var error = $root.ca2instances.UpdateInstanceResponse.verify(message.updateInstance);
                    if (error)
                        return "updateInstance." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.Response)
                    return object;
                var message = new $root.ca2instances.Response();
                if (object.createInstance != null) {
                    if (typeof object.createInstance !== "object")
                        throw TypeError(".ca2instances.Response.createInstance: object expected");
                    message.createInstance = $root.ca2instances.CreateInstanceResponse.fromObject(object.createInstance);
                }
                if (object.deleteInstance != null) {
                    if (typeof object.deleteInstance !== "object")
                        throw TypeError(".ca2instances.Response.deleteInstance: object expected");
                    message.deleteInstance = $root.ca2instances.DeleteInstanceResponse.fromObject(object.deleteInstance);
                }
                if (object.instanceChangeList != null) {
                    if (typeof object.instanceChangeList !== "object")
                        throw TypeError(".ca2instances.Response.instanceChangeList: object expected");
                    message.instanceChangeList = $root.ca2instances.InstanceChangeListResponse.fromObject(object.instanceChangeList);
                }
                if (object.instanceList != null) {
                    if (typeof object.instanceList !== "object")
                        throw TypeError(".ca2instances.Response.instanceList: object expected");
                    message.instanceList = $root.ca2instances.InstanceListResponse.fromObject(object.instanceList);
                }
                if (object.reinstallInstance != null) {
                    if (typeof object.reinstallInstance !== "object")
                        throw TypeError(".ca2instances.Response.reinstallInstance: object expected");
                    message.reinstallInstance = $root.ca2instances.ReinstallInstanceResponse.fromObject(object.reinstallInstance);
                }
                if (object.restartInstance != null) {
                    if (typeof object.restartInstance !== "object")
                        throw TypeError(".ca2instances.Response.restartInstance: object expected");
                    message.restartInstance = $root.ca2instances.RestartInstanceResponse.fromObject(object.restartInstance);
                }
                if (object.startInstance != null) {
                    if (typeof object.startInstance !== "object")
                        throw TypeError(".ca2instances.Response.startInstance: object expected");
                    message.startInstance = $root.ca2instances.StartInstanceResponse.fromObject(object.startInstance);
                }
                if (object.stopInstance != null) {
                    if (typeof object.stopInstance !== "object")
                        throw TypeError(".ca2instances.Response.stopInstance: object expected");
                    message.stopInstance = $root.ca2instances.StopInstanceResponse.fromObject(object.stopInstance);
                }
                if (object.updateInstance != null) {
                    if (typeof object.updateInstance !== "object")
                        throw TypeError(".ca2instances.Response.updateInstance: object expected");
                    message.updateInstance = $root.ca2instances.UpdateInstanceResponse.fromObject(object.updateInstance);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.Response
             * @static
             * @param {ca2instances.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.createInstance = null;
                    object.deleteInstance = null;
                    object.instanceList = null;
                    object.stopInstance = null;
                    object.restartInstance = null;
                    object.startInstance = null;
                    object.reinstallInstance = null;
                    object.instanceChangeList = null;
                    object.updateInstance = null;
                }
                if (message.createInstance != null && message.hasOwnProperty("createInstance"))
                    object.createInstance = $root.ca2instances.CreateInstanceResponse.toObject(message.createInstance, options);
                if (message.deleteInstance != null && message.hasOwnProperty("deleteInstance"))
                    object.deleteInstance = $root.ca2instances.DeleteInstanceResponse.toObject(message.deleteInstance, options);
                if (message.instanceList != null && message.hasOwnProperty("instanceList"))
                    object.instanceList = $root.ca2instances.InstanceListResponse.toObject(message.instanceList, options);
                if (message.stopInstance != null && message.hasOwnProperty("stopInstance"))
                    object.stopInstance = $root.ca2instances.StopInstanceResponse.toObject(message.stopInstance, options);
                if (message.restartInstance != null && message.hasOwnProperty("restartInstance"))
                    object.restartInstance = $root.ca2instances.RestartInstanceResponse.toObject(message.restartInstance, options);
                if (message.startInstance != null && message.hasOwnProperty("startInstance"))
                    object.startInstance = $root.ca2instances.StartInstanceResponse.toObject(message.startInstance, options);
                if (message.reinstallInstance != null && message.hasOwnProperty("reinstallInstance"))
                    object.reinstallInstance = $root.ca2instances.ReinstallInstanceResponse.toObject(message.reinstallInstance, options);
                if (message.instanceChangeList != null && message.hasOwnProperty("instanceChangeList"))
                    object.instanceChangeList = $root.ca2instances.InstanceChangeListResponse.toObject(message.instanceChangeList, options);
                if (message.updateInstance != null && message.hasOwnProperty("updateInstance"))
                    object.updateInstance = $root.ca2instances.UpdateInstanceResponse.toObject(message.updateInstance, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2instances.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2instances.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.Response";
            };
    
            return Response;
        })();
    
        ca2instances.CreateInstanceRequest = (function() {
    
            /**
             * Properties of a CreateInstanceRequest.
             * @memberof ca2instances
             * @interface ICreateInstanceRequest
             * @property {boolean|null} [dryRun] CreateInstanceRequest dryRun
             * @property {string|null} [planId] CreateInstanceRequest planId
             * @property {string|null} [datacenterId] CreateInstanceRequest datacenterId
             * @property {string|null} [imageId] CreateInstanceRequest imageId
             * @property {Array.<number>|null} [sshKeyIds] CreateInstanceRequest sshKeyIds
             * @property {string|null} [initialSetupScript] CreateInstanceRequest initialSetupScript
             * @property {string|null} [name] CreateInstanceRequest name
             * @property {boolean|null} [isImportant] CreateInstanceRequest isImportant
             * @property {string|null} [promoCode] CreateInstanceRequest promoCode
             * @property {boolean|null} [generateNewSshKey] CreateInstanceRequest generateNewSshKey
             * @property {boolean|null} [generatePassword] CreateInstanceRequest generatePassword
             * @property {Array.<string>|null} [enabledAddonIds] CreateInstanceRequest enabledAddonIds
             * @property {string|null} [forceUsername] CreateInstanceRequest forceUsername
             * @property {string|null} [forcePassword] CreateInstanceRequest forcePassword
             */
    
            /**
             * Constructs a new CreateInstanceRequest.
             * @memberof ca2instances
             * @classdesc Represents a CreateInstanceRequest.
             * @implements ICreateInstanceRequest
             * @constructor
             * @param {ca2instances.ICreateInstanceRequest=} [properties] Properties to set
             */
            function CreateInstanceRequest(properties) {
                this.sshKeyIds = [];
                this.enabledAddonIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateInstanceRequest dryRun.
             * @member {boolean} dryRun
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.dryRun = false;
    
            /**
             * CreateInstanceRequest planId.
             * @member {string} planId
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.planId = "";
    
            /**
             * CreateInstanceRequest datacenterId.
             * @member {string} datacenterId
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.datacenterId = "";
    
            /**
             * CreateInstanceRequest imageId.
             * @member {string} imageId
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.imageId = "";
    
            /**
             * CreateInstanceRequest sshKeyIds.
             * @member {Array.<number>} sshKeyIds
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.sshKeyIds = $util.emptyArray;
    
            /**
             * CreateInstanceRequest initialSetupScript.
             * @member {string} initialSetupScript
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.initialSetupScript = "";
    
            /**
             * CreateInstanceRequest name.
             * @member {string} name
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.name = "";
    
            /**
             * CreateInstanceRequest isImportant.
             * @member {boolean} isImportant
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.isImportant = false;
    
            /**
             * CreateInstanceRequest promoCode.
             * @member {string} promoCode
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.promoCode = "";
    
            /**
             * CreateInstanceRequest generateNewSshKey.
             * @member {boolean} generateNewSshKey
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.generateNewSshKey = false;
    
            /**
             * CreateInstanceRequest generatePassword.
             * @member {boolean} generatePassword
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.generatePassword = false;
    
            /**
             * CreateInstanceRequest enabledAddonIds.
             * @member {Array.<string>} enabledAddonIds
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.enabledAddonIds = $util.emptyArray;
    
            /**
             * CreateInstanceRequest forceUsername.
             * @member {string} forceUsername
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.forceUsername = "";
    
            /**
             * CreateInstanceRequest forcePassword.
             * @member {string} forcePassword
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             */
            CreateInstanceRequest.prototype.forcePassword = "";
    
            /**
             * Creates a new CreateInstanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {ca2instances.ICreateInstanceRequest=} [properties] Properties to set
             * @returns {ca2instances.CreateInstanceRequest} CreateInstanceRequest instance
             */
            CreateInstanceRequest.create = function create(properties) {
                return new CreateInstanceRequest(properties);
            };
    
            /**
             * Encodes the specified CreateInstanceRequest message. Does not implicitly {@link ca2instances.CreateInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {ca2instances.ICreateInstanceRequest} message CreateInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.planId);
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageId);
                if (message.sshKeyIds != null && message.sshKeyIds.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        writer.uint32(message.sshKeyIds[i]);
                    writer.ldelim();
                }
                if (message.initialSetupScript != null && Object.hasOwnProperty.call(message, "initialSetupScript"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.initialSetupScript);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
                if (message.isImportant != null && Object.hasOwnProperty.call(message, "isImportant"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isImportant);
                if (message.promoCode != null && Object.hasOwnProperty.call(message, "promoCode"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.promoCode);
                if (message.generateNewSshKey != null && Object.hasOwnProperty.call(message, "generateNewSshKey"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.generateNewSshKey);
                if (message.datacenterId != null && Object.hasOwnProperty.call(message, "datacenterId"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.datacenterId);
                if (message.enabledAddonIds != null && message.enabledAddonIds.length)
                    for (var i = 0; i < message.enabledAddonIds.length; ++i)
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.enabledAddonIds[i]);
                if (message.forceUsername != null && Object.hasOwnProperty.call(message, "forceUsername"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.forceUsername);
                if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                    writer.uint32(/* id 14, wireType 0 =*/112).bool(message.dryRun);
                if (message.generatePassword != null && Object.hasOwnProperty.call(message, "generatePassword"))
                    writer.uint32(/* id 15, wireType 0 =*/120).bool(message.generatePassword);
                if (message.forcePassword != null && Object.hasOwnProperty.call(message, "forcePassword"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.forcePassword);
                return writer;
            };
    
            /**
             * Encodes the specified CreateInstanceRequest message, length delimited. Does not implicitly {@link ca2instances.CreateInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {ca2instances.ICreateInstanceRequest} message CreateInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.CreateInstanceRequest} CreateInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.CreateInstanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 14: {
                            message.dryRun = reader.bool();
                            break;
                        }
                    case 1: {
                            message.planId = reader.string();
                            break;
                        }
                    case 11: {
                            message.datacenterId = reader.string();
                            break;
                        }
                    case 3: {
                            message.imageId = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.sshKeyIds && message.sshKeyIds.length))
                                message.sshKeyIds = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.sshKeyIds.push(reader.uint32());
                            } else
                                message.sshKeyIds.push(reader.uint32());
                            break;
                        }
                    case 5: {
                            message.initialSetupScript = reader.string();
                            break;
                        }
                    case 6: {
                            message.name = reader.string();
                            break;
                        }
                    case 7: {
                            message.isImportant = reader.bool();
                            break;
                        }
                    case 8: {
                            message.promoCode = reader.string();
                            break;
                        }
                    case 9: {
                            message.generateNewSshKey = reader.bool();
                            break;
                        }
                    case 15: {
                            message.generatePassword = reader.bool();
                            break;
                        }
                    case 12: {
                            if (!(message.enabledAddonIds && message.enabledAddonIds.length))
                                message.enabledAddonIds = [];
                            message.enabledAddonIds.push(reader.string());
                            break;
                        }
                    case 13: {
                            message.forceUsername = reader.string();
                            break;
                        }
                    case 16: {
                            message.forcePassword = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.CreateInstanceRequest} CreateInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateInstanceRequest message.
             * @function verify
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    if (typeof message.dryRun !== "boolean")
                        return "dryRun: boolean expected";
                if (message.planId != null && message.hasOwnProperty("planId"))
                    if (!$util.isString(message.planId))
                        return "planId: string expected";
                if (message.datacenterId != null && message.hasOwnProperty("datacenterId"))
                    if (!$util.isString(message.datacenterId))
                        return "datacenterId: string expected";
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                if (message.sshKeyIds != null && message.hasOwnProperty("sshKeyIds")) {
                    if (!Array.isArray(message.sshKeyIds))
                        return "sshKeyIds: array expected";
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        if (!$util.isInteger(message.sshKeyIds[i]))
                            return "sshKeyIds: integer[] expected";
                }
                if (message.initialSetupScript != null && message.hasOwnProperty("initialSetupScript"))
                    if (!$util.isString(message.initialSetupScript))
                        return "initialSetupScript: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isImportant != null && message.hasOwnProperty("isImportant"))
                    if (typeof message.isImportant !== "boolean")
                        return "isImportant: boolean expected";
                if (message.promoCode != null && message.hasOwnProperty("promoCode"))
                    if (!$util.isString(message.promoCode))
                        return "promoCode: string expected";
                if (message.generateNewSshKey != null && message.hasOwnProperty("generateNewSshKey"))
                    if (typeof message.generateNewSshKey !== "boolean")
                        return "generateNewSshKey: boolean expected";
                if (message.generatePassword != null && message.hasOwnProperty("generatePassword"))
                    if (typeof message.generatePassword !== "boolean")
                        return "generatePassword: boolean expected";
                if (message.enabledAddonIds != null && message.hasOwnProperty("enabledAddonIds")) {
                    if (!Array.isArray(message.enabledAddonIds))
                        return "enabledAddonIds: array expected";
                    for (var i = 0; i < message.enabledAddonIds.length; ++i)
                        if (!$util.isString(message.enabledAddonIds[i]))
                            return "enabledAddonIds: string[] expected";
                }
                if (message.forceUsername != null && message.hasOwnProperty("forceUsername"))
                    if (!$util.isString(message.forceUsername))
                        return "forceUsername: string expected";
                if (message.forcePassword != null && message.hasOwnProperty("forcePassword"))
                    if (!$util.isString(message.forcePassword))
                        return "forcePassword: string expected";
                return null;
            };
    
            /**
             * Creates a CreateInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.CreateInstanceRequest} CreateInstanceRequest
             */
            CreateInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.CreateInstanceRequest)
                    return object;
                var message = new $root.ca2instances.CreateInstanceRequest();
                if (object.dryRun != null)
                    message.dryRun = Boolean(object.dryRun);
                if (object.planId != null)
                    message.planId = String(object.planId);
                if (object.datacenterId != null)
                    message.datacenterId = String(object.datacenterId);
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                if (object.sshKeyIds) {
                    if (!Array.isArray(object.sshKeyIds))
                        throw TypeError(".ca2instances.CreateInstanceRequest.sshKeyIds: array expected");
                    message.sshKeyIds = [];
                    for (var i = 0; i < object.sshKeyIds.length; ++i)
                        message.sshKeyIds[i] = object.sshKeyIds[i] >>> 0;
                }
                if (object.initialSetupScript != null)
                    message.initialSetupScript = String(object.initialSetupScript);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isImportant != null)
                    message.isImportant = Boolean(object.isImportant);
                if (object.promoCode != null)
                    message.promoCode = String(object.promoCode);
                if (object.generateNewSshKey != null)
                    message.generateNewSshKey = Boolean(object.generateNewSshKey);
                if (object.generatePassword != null)
                    message.generatePassword = Boolean(object.generatePassword);
                if (object.enabledAddonIds) {
                    if (!Array.isArray(object.enabledAddonIds))
                        throw TypeError(".ca2instances.CreateInstanceRequest.enabledAddonIds: array expected");
                    message.enabledAddonIds = [];
                    for (var i = 0; i < object.enabledAddonIds.length; ++i)
                        message.enabledAddonIds[i] = String(object.enabledAddonIds[i]);
                }
                if (object.forceUsername != null)
                    message.forceUsername = String(object.forceUsername);
                if (object.forcePassword != null)
                    message.forcePassword = String(object.forcePassword);
                return message;
            };
    
            /**
             * Creates a plain object from a CreateInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {ca2instances.CreateInstanceRequest} message CreateInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.sshKeyIds = [];
                    object.enabledAddonIds = [];
                }
                if (options.defaults) {
                    object.planId = "";
                    object.imageId = "";
                    object.initialSetupScript = "";
                    object.name = "";
                    object.isImportant = false;
                    object.promoCode = "";
                    object.generateNewSshKey = false;
                    object.datacenterId = "";
                    object.forceUsername = "";
                    object.dryRun = false;
                    object.generatePassword = false;
                    object.forcePassword = "";
                }
                if (message.planId != null && message.hasOwnProperty("planId"))
                    object.planId = message.planId;
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                if (message.sshKeyIds && message.sshKeyIds.length) {
                    object.sshKeyIds = [];
                    for (var j = 0; j < message.sshKeyIds.length; ++j)
                        object.sshKeyIds[j] = message.sshKeyIds[j];
                }
                if (message.initialSetupScript != null && message.hasOwnProperty("initialSetupScript"))
                    object.initialSetupScript = message.initialSetupScript;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isImportant != null && message.hasOwnProperty("isImportant"))
                    object.isImportant = message.isImportant;
                if (message.promoCode != null && message.hasOwnProperty("promoCode"))
                    object.promoCode = message.promoCode;
                if (message.generateNewSshKey != null && message.hasOwnProperty("generateNewSshKey"))
                    object.generateNewSshKey = message.generateNewSshKey;
                if (message.datacenterId != null && message.hasOwnProperty("datacenterId"))
                    object.datacenterId = message.datacenterId;
                if (message.enabledAddonIds && message.enabledAddonIds.length) {
                    object.enabledAddonIds = [];
                    for (var j = 0; j < message.enabledAddonIds.length; ++j)
                        object.enabledAddonIds[j] = message.enabledAddonIds[j];
                }
                if (message.forceUsername != null && message.hasOwnProperty("forceUsername"))
                    object.forceUsername = message.forceUsername;
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    object.dryRun = message.dryRun;
                if (message.generatePassword != null && message.hasOwnProperty("generatePassword"))
                    object.generatePassword = message.generatePassword;
                if (message.forcePassword != null && message.hasOwnProperty("forcePassword"))
                    object.forcePassword = message.forcePassword;
                return object;
            };
    
            /**
             * Converts this CreateInstanceRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.CreateInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateInstanceRequest
             * @function getTypeUrl
             * @memberof ca2instances.CreateInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.CreateInstanceRequest";
            };
    
            return CreateInstanceRequest;
        })();
    
        /**
         * CreateInstanceError enum.
         * @name ca2instances.CreateInstanceError
         * @enum {number}
         * @property {number} CSE_UNKNOWN=0 CSE_UNKNOWN value
         * @property {number} CSE_INVALID_PLAN_ID=1 CSE_INVALID_PLAN_ID value
         * @property {number} CSE_INVALID_DATACENTER_ID=2 CSE_INVALID_DATACENTER_ID value
         * @property {number} CSE_INVALID_IMAGE_ID=3 CSE_INVALID_IMAGE_ID value
         * @property {number} CSE_INVALID_SSH_KEY_ID=4 CSE_INVALID_SSH_KEY_ID value
         * @property {number} CSE_INVALID_INITIAL_SETUP_SCRIPT=5 CSE_INVALID_INITIAL_SETUP_SCRIPT value
         * @property {number} CSE_INVALID_PROMO_CODE=6 CSE_INVALID_PROMO_CODE value
         * @property {number} CSE_SERVER_NAME_TOO_LONG=7 CSE_SERVER_NAME_TOO_LONG value
         * @property {number} CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS=9 CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS value
         * @property {number} CSE_NOT_ENOUGH_BALANCE=8 CSE_NOT_ENOUGH_BALANCE value
         * @property {number} CSE_ROOT_PASSWORD_OR_SSH_KEYS_REQUIRED=11 CSE_ROOT_PASSWORD_OR_SSH_KEYS_REQUIRED value
         * @property {number} CSE_PLAN_DOES_NOT_SUPPORT_WINDOWS=12 CSE_PLAN_DOES_NOT_SUPPORT_WINDOWS value
         * @property {number} CSE_PASSWORD_NOT_STRONG=13 CSE_PASSWORD_NOT_STRONG value
         * @property {number} CSE_INVALID_WINDOWS_USERNAME=14 CSE_INVALID_WINDOWS_USERNAME value
         * @property {number} CSE_PASSWORD_NOT_ALLOWED_FOR_THIS_IMAGE=15 CSE_PASSWORD_NOT_ALLOWED_FOR_THIS_IMAGE value
         */
        ca2instances.CreateInstanceError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CSE_UNKNOWN"] = 0;
            values[valuesById[1] = "CSE_INVALID_PLAN_ID"] = 1;
            values[valuesById[2] = "CSE_INVALID_DATACENTER_ID"] = 2;
            values[valuesById[3] = "CSE_INVALID_IMAGE_ID"] = 3;
            values[valuesById[4] = "CSE_INVALID_SSH_KEY_ID"] = 4;
            values[valuesById[5] = "CSE_INVALID_INITIAL_SETUP_SCRIPT"] = 5;
            values[valuesById[6] = "CSE_INVALID_PROMO_CODE"] = 6;
            values[valuesById[7] = "CSE_SERVER_NAME_TOO_LONG"] = 7;
            values[valuesById[9] = "CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS"] = 9;
            values[valuesById[8] = "CSE_NOT_ENOUGH_BALANCE"] = 8;
            values[valuesById[11] = "CSE_ROOT_PASSWORD_OR_SSH_KEYS_REQUIRED"] = 11;
            values[valuesById[12] = "CSE_PLAN_DOES_NOT_SUPPORT_WINDOWS"] = 12;
            values[valuesById[13] = "CSE_PASSWORD_NOT_STRONG"] = 13;
            values[valuesById[14] = "CSE_INVALID_WINDOWS_USERNAME"] = 14;
            values[valuesById[15] = "CSE_PASSWORD_NOT_ALLOWED_FOR_THIS_IMAGE"] = 15;
            return values;
        })();
    
        ca2instances.CreateInstanceResponse = (function() {
    
            /**
             * Properties of a CreateInstanceResponse.
             * @memberof ca2instances
             * @interface ICreateInstanceResponse
             * @property {boolean|null} [dryRun] CreateInstanceResponse dryRun
             * @property {Array.<ca2instances.CreateInstanceError>|null} [errors] CreateInstanceResponse errors
             * @property {ca2types.IInstance|null} [instance] CreateInstanceResponse instance
             * @property {string|null} [generatedSshKey] CreateInstanceResponse generatedSshKey
             */
    
            /**
             * Constructs a new CreateInstanceResponse.
             * @memberof ca2instances
             * @classdesc Represents a CreateInstanceResponse.
             * @implements ICreateInstanceResponse
             * @constructor
             * @param {ca2instances.ICreateInstanceResponse=} [properties] Properties to set
             */
            function CreateInstanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateInstanceResponse dryRun.
             * @member {boolean} dryRun
             * @memberof ca2instances.CreateInstanceResponse
             * @instance
             */
            CreateInstanceResponse.prototype.dryRun = false;
    
            /**
             * CreateInstanceResponse errors.
             * @member {Array.<ca2instances.CreateInstanceError>} errors
             * @memberof ca2instances.CreateInstanceResponse
             * @instance
             */
            CreateInstanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * CreateInstanceResponse instance.
             * @member {ca2types.IInstance|null|undefined} instance
             * @memberof ca2instances.CreateInstanceResponse
             * @instance
             */
            CreateInstanceResponse.prototype.instance = null;
    
            /**
             * CreateInstanceResponse generatedSshKey.
             * @member {string} generatedSshKey
             * @memberof ca2instances.CreateInstanceResponse
             * @instance
             */
            CreateInstanceResponse.prototype.generatedSshKey = "";
    
            /**
             * Creates a new CreateInstanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {ca2instances.ICreateInstanceResponse=} [properties] Properties to set
             * @returns {ca2instances.CreateInstanceResponse} CreateInstanceResponse instance
             */
            CreateInstanceResponse.create = function create(properties) {
                return new CreateInstanceResponse(properties);
            };
    
            /**
             * Encodes the specified CreateInstanceResponse message. Does not implicitly {@link ca2instances.CreateInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {ca2instances.ICreateInstanceResponse} message CreateInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.instance != null && Object.hasOwnProperty.call(message, "instance"))
                    $root.ca2types.Instance.encode(message.instance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.generatedSshKey != null && Object.hasOwnProperty.call(message, "generatedSshKey"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.generatedSshKey);
                if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.dryRun);
                return writer;
            };
    
            /**
             * Encodes the specified CreateInstanceResponse message, length delimited. Does not implicitly {@link ca2instances.CreateInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {ca2instances.ICreateInstanceResponse} message CreateInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.CreateInstanceResponse} CreateInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.CreateInstanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 4: {
                            message.dryRun = reader.bool();
                            break;
                        }
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.instance = $root.ca2types.Instance.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.generatedSshKey = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.CreateInstanceResponse} CreateInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateInstanceResponse message.
             * @function verify
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    if (typeof message.dryRun !== "boolean")
                        return "dryRun: boolean expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 9:
                        case 8:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                            break;
                        }
                }
                if (message.instance != null && message.hasOwnProperty("instance")) {
                    var error = $root.ca2types.Instance.verify(message.instance);
                    if (error)
                        return "instance." + error;
                }
                if (message.generatedSshKey != null && message.hasOwnProperty("generatedSshKey"))
                    if (!$util.isString(message.generatedSshKey))
                        return "generatedSshKey: string expected";
                return null;
            };
    
            /**
             * Creates a CreateInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.CreateInstanceResponse} CreateInstanceResponse
             */
            CreateInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.CreateInstanceResponse)
                    return object;
                var message = new $root.ca2instances.CreateInstanceResponse();
                if (object.dryRun != null)
                    message.dryRun = Boolean(object.dryRun);
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2instances.CreateInstanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "CSE_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "CSE_INVALID_PLAN_ID":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "CSE_INVALID_DATACENTER_ID":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "CSE_INVALID_IMAGE_ID":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "CSE_INVALID_SSH_KEY_ID":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        case "CSE_INVALID_INITIAL_SETUP_SCRIPT":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        case "CSE_INVALID_PROMO_CODE":
                        case 6:
                            message.errors[i] = 6;
                            break;
                        case "CSE_SERVER_NAME_TOO_LONG":
                        case 7:
                            message.errors[i] = 7;
                            break;
                        case "CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS":
                        case 9:
                            message.errors[i] = 9;
                            break;
                        case "CSE_NOT_ENOUGH_BALANCE":
                        case 8:
                            message.errors[i] = 8;
                            break;
                        case "CSE_ROOT_PASSWORD_OR_SSH_KEYS_REQUIRED":
                        case 11:
                            message.errors[i] = 11;
                            break;
                        case "CSE_PLAN_DOES_NOT_SUPPORT_WINDOWS":
                        case 12:
                            message.errors[i] = 12;
                            break;
                        case "CSE_PASSWORD_NOT_STRONG":
                        case 13:
                            message.errors[i] = 13;
                            break;
                        case "CSE_INVALID_WINDOWS_USERNAME":
                        case 14:
                            message.errors[i] = 14;
                            break;
                        case "CSE_PASSWORD_NOT_ALLOWED_FOR_THIS_IMAGE":
                        case 15:
                            message.errors[i] = 15;
                            break;
                        }
                }
                if (object.instance != null) {
                    if (typeof object.instance !== "object")
                        throw TypeError(".ca2instances.CreateInstanceResponse.instance: object expected");
                    message.instance = $root.ca2types.Instance.fromObject(object.instance);
                }
                if (object.generatedSshKey != null)
                    message.generatedSshKey = String(object.generatedSshKey);
                return message;
            };
    
            /**
             * Creates a plain object from a CreateInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {ca2instances.CreateInstanceResponse} message CreateInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults) {
                    object.instance = null;
                    object.generatedSshKey = "";
                    object.dryRun = false;
                }
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2instances.CreateInstanceError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2instances.CreateInstanceError[message.errors[j]] : message.errors[j];
                }
                if (message.instance != null && message.hasOwnProperty("instance"))
                    object.instance = $root.ca2types.Instance.toObject(message.instance, options);
                if (message.generatedSshKey != null && message.hasOwnProperty("generatedSshKey"))
                    object.generatedSshKey = message.generatedSshKey;
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    object.dryRun = message.dryRun;
                return object;
            };
    
            /**
             * Converts this CreateInstanceResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.CreateInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateInstanceResponse
             * @function getTypeUrl
             * @memberof ca2instances.CreateInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.CreateInstanceResponse";
            };
    
            return CreateInstanceResponse;
        })();
    
        ca2instances.DeleteInstanceRequest = (function() {
    
            /**
             * Properties of a DeleteInstanceRequest.
             * @memberof ca2instances
             * @interface IDeleteInstanceRequest
             * @property {number|null} [instanceId] DeleteInstanceRequest instanceId
             */
    
            /**
             * Constructs a new DeleteInstanceRequest.
             * @memberof ca2instances
             * @classdesc Represents a DeleteInstanceRequest.
             * @implements IDeleteInstanceRequest
             * @constructor
             * @param {ca2instances.IDeleteInstanceRequest=} [properties] Properties to set
             */
            function DeleteInstanceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteInstanceRequest instanceId.
             * @member {number} instanceId
             * @memberof ca2instances.DeleteInstanceRequest
             * @instance
             */
            DeleteInstanceRequest.prototype.instanceId = 0;
    
            /**
             * Creates a new DeleteInstanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {ca2instances.IDeleteInstanceRequest=} [properties] Properties to set
             * @returns {ca2instances.DeleteInstanceRequest} DeleteInstanceRequest instance
             */
            DeleteInstanceRequest.create = function create(properties) {
                return new DeleteInstanceRequest(properties);
            };
    
            /**
             * Encodes the specified DeleteInstanceRequest message. Does not implicitly {@link ca2instances.DeleteInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {ca2instances.IDeleteInstanceRequest} message DeleteInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified DeleteInstanceRequest message, length delimited. Does not implicitly {@link ca2instances.DeleteInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {ca2instances.IDeleteInstanceRequest} message DeleteInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.DeleteInstanceRequest} DeleteInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.DeleteInstanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.DeleteInstanceRequest} DeleteInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteInstanceRequest message.
             * @function verify
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a DeleteInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.DeleteInstanceRequest} DeleteInstanceRequest
             */
            DeleteInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.DeleteInstanceRequest)
                    return object;
                var message = new $root.ca2instances.DeleteInstanceRequest();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {ca2instances.DeleteInstanceRequest} message DeleteInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this DeleteInstanceRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.DeleteInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeleteInstanceRequest
             * @function getTypeUrl
             * @memberof ca2instances.DeleteInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.DeleteInstanceRequest";
            };
    
            return DeleteInstanceRequest;
        })();
    
        /**
         * InstanceOperationError enum.
         * @name ca2instances.InstanceOperationError
         * @enum {number}
         * @property {number} IOP_UNKNOWN=0 IOP_UNKNOWN value
         * @property {number} IOP_INSTANCE_NOT_FOUND=1 IOP_INSTANCE_NOT_FOUND value
         * @property {number} IOP_NOT_ALLOWED=2 IOP_NOT_ALLOWED value
         * @property {number} IOP_BALANCE_IS_LESS_THAN_ZERO=3 IOP_BALANCE_IS_LESS_THAN_ZERO value
         */
        ca2instances.InstanceOperationError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "IOP_UNKNOWN"] = 0;
            values[valuesById[1] = "IOP_INSTANCE_NOT_FOUND"] = 1;
            values[valuesById[2] = "IOP_NOT_ALLOWED"] = 2;
            values[valuesById[3] = "IOP_BALANCE_IS_LESS_THAN_ZERO"] = 3;
            return values;
        })();
    
        ca2instances.DeleteInstanceResponse = (function() {
    
            /**
             * Properties of a DeleteInstanceResponse.
             * @memberof ca2instances
             * @interface IDeleteInstanceResponse
             * @property {Array.<ca2instances.InstanceOperationError>|null} [errors] DeleteInstanceResponse errors
             */
    
            /**
             * Constructs a new DeleteInstanceResponse.
             * @memberof ca2instances
             * @classdesc Represents a DeleteInstanceResponse.
             * @implements IDeleteInstanceResponse
             * @constructor
             * @param {ca2instances.IDeleteInstanceResponse=} [properties] Properties to set
             */
            function DeleteInstanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteInstanceResponse errors.
             * @member {Array.<ca2instances.InstanceOperationError>} errors
             * @memberof ca2instances.DeleteInstanceResponse
             * @instance
             */
            DeleteInstanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new DeleteInstanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {ca2instances.IDeleteInstanceResponse=} [properties] Properties to set
             * @returns {ca2instances.DeleteInstanceResponse} DeleteInstanceResponse instance
             */
            DeleteInstanceResponse.create = function create(properties) {
                return new DeleteInstanceResponse(properties);
            };
    
            /**
             * Encodes the specified DeleteInstanceResponse message. Does not implicitly {@link ca2instances.DeleteInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {ca2instances.IDeleteInstanceResponse} message DeleteInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified DeleteInstanceResponse message, length delimited. Does not implicitly {@link ca2instances.DeleteInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {ca2instances.IDeleteInstanceResponse} message DeleteInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.DeleteInstanceResponse} DeleteInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.DeleteInstanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.DeleteInstanceResponse} DeleteInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteInstanceResponse message.
             * @function verify
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a DeleteInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.DeleteInstanceResponse} DeleteInstanceResponse
             */
            DeleteInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.DeleteInstanceResponse)
                    return object;
                var message = new $root.ca2instances.DeleteInstanceResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2instances.DeleteInstanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "IOP_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "IOP_INSTANCE_NOT_FOUND":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "IOP_NOT_ALLOWED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "IOP_BALANCE_IS_LESS_THAN_ZERO":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {ca2instances.DeleteInstanceResponse} message DeleteInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2instances.InstanceOperationError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2instances.InstanceOperationError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this DeleteInstanceResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.DeleteInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeleteInstanceResponse
             * @function getTypeUrl
             * @memberof ca2instances.DeleteInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.DeleteInstanceResponse";
            };
    
            return DeleteInstanceResponse;
        })();
    
        /**
         * InstanceSortOrder enum.
         * @name ca2instances.InstanceSortOrder
         * @enum {number}
         * @property {number} ISO_CREATED_AT_ASC=0 ISO_CREATED_AT_ASC value
         * @property {number} ISO_CREATED_AT_DESC=1 ISO_CREATED_AT_DESC value
         * @property {number} ISO_NAME_ASC=2 ISO_NAME_ASC value
         * @property {number} ISO_NAME_DESC=3 ISO_NAME_DESC value
         * @property {number} ISO_STATUS_ASC=4 ISO_STATUS_ASC value
         * @property {number} ISO_STATUS_DESC=5 ISO_STATUS_DESC value
         */
        ca2instances.InstanceSortOrder = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ISO_CREATED_AT_ASC"] = 0;
            values[valuesById[1] = "ISO_CREATED_AT_DESC"] = 1;
            values[valuesById[2] = "ISO_NAME_ASC"] = 2;
            values[valuesById[3] = "ISO_NAME_DESC"] = 3;
            values[valuesById[4] = "ISO_STATUS_ASC"] = 4;
            values[valuesById[5] = "ISO_STATUS_DESC"] = 5;
            return values;
        })();
    
        ca2instances.InstanceListRequest = (function() {
    
            /**
             * Properties of an InstanceListRequest.
             * @memberof ca2instances
             * @interface IInstanceListRequest
             * @property {Array.<number>|null} [ids] InstanceListRequest ids
             * @property {boolean|null} [includeDeleted] InstanceListRequest includeDeleted
             * @property {ca2instances.InstanceSortOrder|null} [sortOrder] InstanceListRequest sortOrder
             */
    
            /**
             * Constructs a new InstanceListRequest.
             * @memberof ca2instances
             * @classdesc Represents an InstanceListRequest.
             * @implements IInstanceListRequest
             * @constructor
             * @param {ca2instances.IInstanceListRequest=} [properties] Properties to set
             */
            function InstanceListRequest(properties) {
                this.ids = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * InstanceListRequest ids.
             * @member {Array.<number>} ids
             * @memberof ca2instances.InstanceListRequest
             * @instance
             */
            InstanceListRequest.prototype.ids = $util.emptyArray;
    
            /**
             * InstanceListRequest includeDeleted.
             * @member {boolean} includeDeleted
             * @memberof ca2instances.InstanceListRequest
             * @instance
             */
            InstanceListRequest.prototype.includeDeleted = false;
    
            /**
             * InstanceListRequest sortOrder.
             * @member {ca2instances.InstanceSortOrder} sortOrder
             * @memberof ca2instances.InstanceListRequest
             * @instance
             */
            InstanceListRequest.prototype.sortOrder = 0;
    
            /**
             * Creates a new InstanceListRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {ca2instances.IInstanceListRequest=} [properties] Properties to set
             * @returns {ca2instances.InstanceListRequest} InstanceListRequest instance
             */
            InstanceListRequest.create = function create(properties) {
                return new InstanceListRequest(properties);
            };
    
            /**
             * Encodes the specified InstanceListRequest message. Does not implicitly {@link ca2instances.InstanceListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {ca2instances.IInstanceListRequest} message InstanceListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.includeDeleted != null && Object.hasOwnProperty.call(message, "includeDeleted"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeDeleted);
                if (message.ids != null && message.ids.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.ids.length; ++i)
                        writer.uint32(message.ids[i]);
                    writer.ldelim();
                }
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sortOrder);
                return writer;
            };
    
            /**
             * Encodes the specified InstanceListRequest message, length delimited. Does not implicitly {@link ca2instances.InstanceListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {ca2instances.IInstanceListRequest} message InstanceListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an InstanceListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.InstanceListRequest} InstanceListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.InstanceListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.ids && message.ids.length))
                                message.ids = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.ids.push(reader.uint32());
                            } else
                                message.ids.push(reader.uint32());
                            break;
                        }
                    case 1: {
                            message.includeDeleted = reader.bool();
                            break;
                        }
                    case 3: {
                            message.sortOrder = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an InstanceListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.InstanceListRequest} InstanceListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an InstanceListRequest message.
             * @function verify
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InstanceListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ids != null && message.hasOwnProperty("ids")) {
                    if (!Array.isArray(message.ids))
                        return "ids: array expected";
                    for (var i = 0; i < message.ids.length; ++i)
                        if (!$util.isInteger(message.ids[i]))
                            return "ids: integer[] expected";
                }
                if (message.includeDeleted != null && message.hasOwnProperty("includeDeleted"))
                    if (typeof message.includeDeleted !== "boolean")
                        return "includeDeleted: boolean expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates an InstanceListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.InstanceListRequest} InstanceListRequest
             */
            InstanceListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.InstanceListRequest)
                    return object;
                var message = new $root.ca2instances.InstanceListRequest();
                if (object.ids) {
                    if (!Array.isArray(object.ids))
                        throw TypeError(".ca2instances.InstanceListRequest.ids: array expected");
                    message.ids = [];
                    for (var i = 0; i < object.ids.length; ++i)
                        message.ids[i] = object.ids[i] >>> 0;
                }
                if (object.includeDeleted != null)
                    message.includeDeleted = Boolean(object.includeDeleted);
                switch (object.sortOrder) {
                default:
                    if (typeof object.sortOrder === "number") {
                        message.sortOrder = object.sortOrder;
                        break;
                    }
                    break;
                case "ISO_CREATED_AT_ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "ISO_CREATED_AT_DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                case "ISO_NAME_ASC":
                case 2:
                    message.sortOrder = 2;
                    break;
                case "ISO_NAME_DESC":
                case 3:
                    message.sortOrder = 3;
                    break;
                case "ISO_STATUS_ASC":
                case 4:
                    message.sortOrder = 4;
                    break;
                case "ISO_STATUS_DESC":
                case 5:
                    message.sortOrder = 5;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from an InstanceListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {ca2instances.InstanceListRequest} message InstanceListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InstanceListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.ids = [];
                if (options.defaults) {
                    object.includeDeleted = false;
                    object.sortOrder = options.enums === String ? "ISO_CREATED_AT_ASC" : 0;
                }
                if (message.includeDeleted != null && message.hasOwnProperty("includeDeleted"))
                    object.includeDeleted = message.includeDeleted;
                if (message.ids && message.ids.length) {
                    object.ids = [];
                    for (var j = 0; j < message.ids.length; ++j)
                        object.ids[j] = message.ids[j];
                }
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.ca2instances.InstanceSortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.ca2instances.InstanceSortOrder[message.sortOrder] : message.sortOrder;
                return object;
            };
    
            /**
             * Converts this InstanceListRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.InstanceListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InstanceListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for InstanceListRequest
             * @function getTypeUrl
             * @memberof ca2instances.InstanceListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InstanceListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.InstanceListRequest";
            };
    
            return InstanceListRequest;
        })();
    
        ca2instances.InstanceListResponse = (function() {
    
            /**
             * Properties of an InstanceListResponse.
             * @memberof ca2instances
             * @interface IInstanceListResponse
             * @property {Array.<ca2types.IInstance>|null} [items] InstanceListResponse items
             */
    
            /**
             * Constructs a new InstanceListResponse.
             * @memberof ca2instances
             * @classdesc Represents an InstanceListResponse.
             * @implements IInstanceListResponse
             * @constructor
             * @param {ca2instances.IInstanceListResponse=} [properties] Properties to set
             */
            function InstanceListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * InstanceListResponse items.
             * @member {Array.<ca2types.IInstance>} items
             * @memberof ca2instances.InstanceListResponse
             * @instance
             */
            InstanceListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new InstanceListResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {ca2instances.IInstanceListResponse=} [properties] Properties to set
             * @returns {ca2instances.InstanceListResponse} InstanceListResponse instance
             */
            InstanceListResponse.create = function create(properties) {
                return new InstanceListResponse(properties);
            };
    
            /**
             * Encodes the specified InstanceListResponse message. Does not implicitly {@link ca2instances.InstanceListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {ca2instances.IInstanceListResponse} message InstanceListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Instance.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified InstanceListResponse message, length delimited. Does not implicitly {@link ca2instances.InstanceListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {ca2instances.IInstanceListResponse} message InstanceListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an InstanceListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.InstanceListResponse} InstanceListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.InstanceListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Instance.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an InstanceListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.InstanceListResponse} InstanceListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an InstanceListResponse message.
             * @function verify
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InstanceListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Instance.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an InstanceListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.InstanceListResponse} InstanceListResponse
             */
            InstanceListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.InstanceListResponse)
                    return object;
                var message = new $root.ca2instances.InstanceListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2instances.InstanceListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2instances.InstanceListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Instance.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an InstanceListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {ca2instances.InstanceListResponse} message InstanceListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InstanceListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Instance.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this InstanceListResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.InstanceListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InstanceListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for InstanceListResponse
             * @function getTypeUrl
             * @memberof ca2instances.InstanceListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InstanceListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.InstanceListResponse";
            };
    
            return InstanceListResponse;
        })();
    
        ca2instances.StartInstanceRequest = (function() {
    
            /**
             * Properties of a StartInstanceRequest.
             * @memberof ca2instances
             * @interface IStartInstanceRequest
             * @property {number|null} [instanceId] StartInstanceRequest instanceId
             */
    
            /**
             * Constructs a new StartInstanceRequest.
             * @memberof ca2instances
             * @classdesc Represents a StartInstanceRequest.
             * @implements IStartInstanceRequest
             * @constructor
             * @param {ca2instances.IStartInstanceRequest=} [properties] Properties to set
             */
            function StartInstanceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StartInstanceRequest instanceId.
             * @member {number} instanceId
             * @memberof ca2instances.StartInstanceRequest
             * @instance
             */
            StartInstanceRequest.prototype.instanceId = 0;
    
            /**
             * Creates a new StartInstanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {ca2instances.IStartInstanceRequest=} [properties] Properties to set
             * @returns {ca2instances.StartInstanceRequest} StartInstanceRequest instance
             */
            StartInstanceRequest.create = function create(properties) {
                return new StartInstanceRequest(properties);
            };
    
            /**
             * Encodes the specified StartInstanceRequest message. Does not implicitly {@link ca2instances.StartInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {ca2instances.IStartInstanceRequest} message StartInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified StartInstanceRequest message, length delimited. Does not implicitly {@link ca2instances.StartInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {ca2instances.IStartInstanceRequest} message StartInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StartInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.StartInstanceRequest} StartInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.StartInstanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StartInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.StartInstanceRequest} StartInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StartInstanceRequest message.
             * @function verify
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a StartInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.StartInstanceRequest} StartInstanceRequest
             */
            StartInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.StartInstanceRequest)
                    return object;
                var message = new $root.ca2instances.StartInstanceRequest();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a StartInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {ca2instances.StartInstanceRequest} message StartInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this StartInstanceRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.StartInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StartInstanceRequest
             * @function getTypeUrl
             * @memberof ca2instances.StartInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.StartInstanceRequest";
            };
    
            return StartInstanceRequest;
        })();
    
        ca2instances.StartInstanceResponse = (function() {
    
            /**
             * Properties of a StartInstanceResponse.
             * @memberof ca2instances
             * @interface IStartInstanceResponse
             * @property {Array.<ca2instances.InstanceOperationError>|null} [errors] StartInstanceResponse errors
             */
    
            /**
             * Constructs a new StartInstanceResponse.
             * @memberof ca2instances
             * @classdesc Represents a StartInstanceResponse.
             * @implements IStartInstanceResponse
             * @constructor
             * @param {ca2instances.IStartInstanceResponse=} [properties] Properties to set
             */
            function StartInstanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StartInstanceResponse errors.
             * @member {Array.<ca2instances.InstanceOperationError>} errors
             * @memberof ca2instances.StartInstanceResponse
             * @instance
             */
            StartInstanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new StartInstanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {ca2instances.IStartInstanceResponse=} [properties] Properties to set
             * @returns {ca2instances.StartInstanceResponse} StartInstanceResponse instance
             */
            StartInstanceResponse.create = function create(properties) {
                return new StartInstanceResponse(properties);
            };
    
            /**
             * Encodes the specified StartInstanceResponse message. Does not implicitly {@link ca2instances.StartInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {ca2instances.IStartInstanceResponse} message StartInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified StartInstanceResponse message, length delimited. Does not implicitly {@link ca2instances.StartInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {ca2instances.IStartInstanceResponse} message StartInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StartInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.StartInstanceResponse} StartInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.StartInstanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StartInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.StartInstanceResponse} StartInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StartInstanceResponse message.
             * @function verify
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a StartInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.StartInstanceResponse} StartInstanceResponse
             */
            StartInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.StartInstanceResponse)
                    return object;
                var message = new $root.ca2instances.StartInstanceResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2instances.StartInstanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "IOP_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "IOP_INSTANCE_NOT_FOUND":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "IOP_NOT_ALLOWED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "IOP_BALANCE_IS_LESS_THAN_ZERO":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a StartInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {ca2instances.StartInstanceResponse} message StartInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2instances.InstanceOperationError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2instances.InstanceOperationError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this StartInstanceResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.StartInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StartInstanceResponse
             * @function getTypeUrl
             * @memberof ca2instances.StartInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.StartInstanceResponse";
            };
    
            return StartInstanceResponse;
        })();
    
        ca2instances.StopInstanceRequest = (function() {
    
            /**
             * Properties of a StopInstanceRequest.
             * @memberof ca2instances
             * @interface IStopInstanceRequest
             * @property {number|null} [instanceId] StopInstanceRequest instanceId
             */
    
            /**
             * Constructs a new StopInstanceRequest.
             * @memberof ca2instances
             * @classdesc Represents a StopInstanceRequest.
             * @implements IStopInstanceRequest
             * @constructor
             * @param {ca2instances.IStopInstanceRequest=} [properties] Properties to set
             */
            function StopInstanceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StopInstanceRequest instanceId.
             * @member {number} instanceId
             * @memberof ca2instances.StopInstanceRequest
             * @instance
             */
            StopInstanceRequest.prototype.instanceId = 0;
    
            /**
             * Creates a new StopInstanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {ca2instances.IStopInstanceRequest=} [properties] Properties to set
             * @returns {ca2instances.StopInstanceRequest} StopInstanceRequest instance
             */
            StopInstanceRequest.create = function create(properties) {
                return new StopInstanceRequest(properties);
            };
    
            /**
             * Encodes the specified StopInstanceRequest message. Does not implicitly {@link ca2instances.StopInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {ca2instances.IStopInstanceRequest} message StopInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified StopInstanceRequest message, length delimited. Does not implicitly {@link ca2instances.StopInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {ca2instances.IStopInstanceRequest} message StopInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StopInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.StopInstanceRequest} StopInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.StopInstanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StopInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.StopInstanceRequest} StopInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StopInstanceRequest message.
             * @function verify
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StopInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a StopInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.StopInstanceRequest} StopInstanceRequest
             */
            StopInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.StopInstanceRequest)
                    return object;
                var message = new $root.ca2instances.StopInstanceRequest();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a StopInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {ca2instances.StopInstanceRequest} message StopInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StopInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this StopInstanceRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.StopInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StopInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StopInstanceRequest
             * @function getTypeUrl
             * @memberof ca2instances.StopInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StopInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.StopInstanceRequest";
            };
    
            return StopInstanceRequest;
        })();
    
        ca2instances.StopInstanceResponse = (function() {
    
            /**
             * Properties of a StopInstanceResponse.
             * @memberof ca2instances
             * @interface IStopInstanceResponse
             * @property {Array.<ca2instances.InstanceOperationError>|null} [errors] StopInstanceResponse errors
             */
    
            /**
             * Constructs a new StopInstanceResponse.
             * @memberof ca2instances
             * @classdesc Represents a StopInstanceResponse.
             * @implements IStopInstanceResponse
             * @constructor
             * @param {ca2instances.IStopInstanceResponse=} [properties] Properties to set
             */
            function StopInstanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StopInstanceResponse errors.
             * @member {Array.<ca2instances.InstanceOperationError>} errors
             * @memberof ca2instances.StopInstanceResponse
             * @instance
             */
            StopInstanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new StopInstanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {ca2instances.IStopInstanceResponse=} [properties] Properties to set
             * @returns {ca2instances.StopInstanceResponse} StopInstanceResponse instance
             */
            StopInstanceResponse.create = function create(properties) {
                return new StopInstanceResponse(properties);
            };
    
            /**
             * Encodes the specified StopInstanceResponse message. Does not implicitly {@link ca2instances.StopInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {ca2instances.IStopInstanceResponse} message StopInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified StopInstanceResponse message, length delimited. Does not implicitly {@link ca2instances.StopInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {ca2instances.IStopInstanceResponse} message StopInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StopInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.StopInstanceResponse} StopInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.StopInstanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StopInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.StopInstanceResponse} StopInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StopInstanceResponse message.
             * @function verify
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StopInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a StopInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.StopInstanceResponse} StopInstanceResponse
             */
            StopInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.StopInstanceResponse)
                    return object;
                var message = new $root.ca2instances.StopInstanceResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2instances.StopInstanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "IOP_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "IOP_INSTANCE_NOT_FOUND":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "IOP_NOT_ALLOWED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "IOP_BALANCE_IS_LESS_THAN_ZERO":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a StopInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {ca2instances.StopInstanceResponse} message StopInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StopInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2instances.InstanceOperationError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2instances.InstanceOperationError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this StopInstanceResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.StopInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StopInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StopInstanceResponse
             * @function getTypeUrl
             * @memberof ca2instances.StopInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StopInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.StopInstanceResponse";
            };
    
            return StopInstanceResponse;
        })();
    
        ca2instances.RestartInstanceRequest = (function() {
    
            /**
             * Properties of a RestartInstanceRequest.
             * @memberof ca2instances
             * @interface IRestartInstanceRequest
             * @property {number|null} [instanceId] RestartInstanceRequest instanceId
             */
    
            /**
             * Constructs a new RestartInstanceRequest.
             * @memberof ca2instances
             * @classdesc Represents a RestartInstanceRequest.
             * @implements IRestartInstanceRequest
             * @constructor
             * @param {ca2instances.IRestartInstanceRequest=} [properties] Properties to set
             */
            function RestartInstanceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RestartInstanceRequest instanceId.
             * @member {number} instanceId
             * @memberof ca2instances.RestartInstanceRequest
             * @instance
             */
            RestartInstanceRequest.prototype.instanceId = 0;
    
            /**
             * Creates a new RestartInstanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {ca2instances.IRestartInstanceRequest=} [properties] Properties to set
             * @returns {ca2instances.RestartInstanceRequest} RestartInstanceRequest instance
             */
            RestartInstanceRequest.create = function create(properties) {
                return new RestartInstanceRequest(properties);
            };
    
            /**
             * Encodes the specified RestartInstanceRequest message. Does not implicitly {@link ca2instances.RestartInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {ca2instances.IRestartInstanceRequest} message RestartInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RestartInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified RestartInstanceRequest message, length delimited. Does not implicitly {@link ca2instances.RestartInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {ca2instances.IRestartInstanceRequest} message RestartInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RestartInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RestartInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.RestartInstanceRequest} RestartInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RestartInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.RestartInstanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RestartInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.RestartInstanceRequest} RestartInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RestartInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RestartInstanceRequest message.
             * @function verify
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RestartInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a RestartInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.RestartInstanceRequest} RestartInstanceRequest
             */
            RestartInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.RestartInstanceRequest)
                    return object;
                var message = new $root.ca2instances.RestartInstanceRequest();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a RestartInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {ca2instances.RestartInstanceRequest} message RestartInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RestartInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this RestartInstanceRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.RestartInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RestartInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RestartInstanceRequest
             * @function getTypeUrl
             * @memberof ca2instances.RestartInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RestartInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.RestartInstanceRequest";
            };
    
            return RestartInstanceRequest;
        })();
    
        ca2instances.RestartInstanceResponse = (function() {
    
            /**
             * Properties of a RestartInstanceResponse.
             * @memberof ca2instances
             * @interface IRestartInstanceResponse
             * @property {Array.<ca2instances.InstanceOperationError>|null} [errors] RestartInstanceResponse errors
             */
    
            /**
             * Constructs a new RestartInstanceResponse.
             * @memberof ca2instances
             * @classdesc Represents a RestartInstanceResponse.
             * @implements IRestartInstanceResponse
             * @constructor
             * @param {ca2instances.IRestartInstanceResponse=} [properties] Properties to set
             */
            function RestartInstanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RestartInstanceResponse errors.
             * @member {Array.<ca2instances.InstanceOperationError>} errors
             * @memberof ca2instances.RestartInstanceResponse
             * @instance
             */
            RestartInstanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new RestartInstanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {ca2instances.IRestartInstanceResponse=} [properties] Properties to set
             * @returns {ca2instances.RestartInstanceResponse} RestartInstanceResponse instance
             */
            RestartInstanceResponse.create = function create(properties) {
                return new RestartInstanceResponse(properties);
            };
    
            /**
             * Encodes the specified RestartInstanceResponse message. Does not implicitly {@link ca2instances.RestartInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {ca2instances.IRestartInstanceResponse} message RestartInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RestartInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified RestartInstanceResponse message, length delimited. Does not implicitly {@link ca2instances.RestartInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {ca2instances.IRestartInstanceResponse} message RestartInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RestartInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RestartInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.RestartInstanceResponse} RestartInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RestartInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.RestartInstanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RestartInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.RestartInstanceResponse} RestartInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RestartInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RestartInstanceResponse message.
             * @function verify
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RestartInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a RestartInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.RestartInstanceResponse} RestartInstanceResponse
             */
            RestartInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.RestartInstanceResponse)
                    return object;
                var message = new $root.ca2instances.RestartInstanceResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2instances.RestartInstanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "IOP_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "IOP_INSTANCE_NOT_FOUND":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "IOP_NOT_ALLOWED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "IOP_BALANCE_IS_LESS_THAN_ZERO":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RestartInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {ca2instances.RestartInstanceResponse} message RestartInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RestartInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2instances.InstanceOperationError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2instances.InstanceOperationError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this RestartInstanceResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.RestartInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RestartInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RestartInstanceResponse
             * @function getTypeUrl
             * @memberof ca2instances.RestartInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RestartInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.RestartInstanceResponse";
            };
    
            return RestartInstanceResponse;
        })();
    
        ca2instances.ReinstallInstanceRequest = (function() {
    
            /**
             * Properties of a ReinstallInstanceRequest.
             * @memberof ca2instances
             * @interface IReinstallInstanceRequest
             * @property {boolean|null} [dryRun] ReinstallInstanceRequest dryRun
             * @property {number|null} [instanceId] ReinstallInstanceRequest instanceId
             * @property {Array.<number>|null} [sshKeyIds] ReinstallInstanceRequest sshKeyIds
             * @property {string|null} [imageId] ReinstallInstanceRequest imageId
             */
    
            /**
             * Constructs a new ReinstallInstanceRequest.
             * @memberof ca2instances
             * @classdesc Represents a ReinstallInstanceRequest.
             * @implements IReinstallInstanceRequest
             * @constructor
             * @param {ca2instances.IReinstallInstanceRequest=} [properties] Properties to set
             */
            function ReinstallInstanceRequest(properties) {
                this.sshKeyIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ReinstallInstanceRequest dryRun.
             * @member {boolean} dryRun
             * @memberof ca2instances.ReinstallInstanceRequest
             * @instance
             */
            ReinstallInstanceRequest.prototype.dryRun = false;
    
            /**
             * ReinstallInstanceRequest instanceId.
             * @member {number} instanceId
             * @memberof ca2instances.ReinstallInstanceRequest
             * @instance
             */
            ReinstallInstanceRequest.prototype.instanceId = 0;
    
            /**
             * ReinstallInstanceRequest sshKeyIds.
             * @member {Array.<number>} sshKeyIds
             * @memberof ca2instances.ReinstallInstanceRequest
             * @instance
             */
            ReinstallInstanceRequest.prototype.sshKeyIds = $util.emptyArray;
    
            /**
             * ReinstallInstanceRequest imageId.
             * @member {string} imageId
             * @memberof ca2instances.ReinstallInstanceRequest
             * @instance
             */
            ReinstallInstanceRequest.prototype.imageId = "";
    
            /**
             * Creates a new ReinstallInstanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {ca2instances.IReinstallInstanceRequest=} [properties] Properties to set
             * @returns {ca2instances.ReinstallInstanceRequest} ReinstallInstanceRequest instance
             */
            ReinstallInstanceRequest.create = function create(properties) {
                return new ReinstallInstanceRequest(properties);
            };
    
            /**
             * Encodes the specified ReinstallInstanceRequest message. Does not implicitly {@link ca2instances.ReinstallInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {ca2instances.IReinstallInstanceRequest} message ReinstallInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReinstallInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.dryRun);
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.instanceId);
                if (message.sshKeyIds != null && message.sshKeyIds.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        writer.uint32(message.sshKeyIds[i]);
                    writer.ldelim();
                }
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.imageId);
                return writer;
            };
    
            /**
             * Encodes the specified ReinstallInstanceRequest message, length delimited. Does not implicitly {@link ca2instances.ReinstallInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {ca2instances.IReinstallInstanceRequest} message ReinstallInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReinstallInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ReinstallInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.ReinstallInstanceRequest} ReinstallInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReinstallInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.ReinstallInstanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dryRun = reader.bool();
                            break;
                        }
                    case 2: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 3: {
                            if (!(message.sshKeyIds && message.sshKeyIds.length))
                                message.sshKeyIds = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.sshKeyIds.push(reader.uint32());
                            } else
                                message.sshKeyIds.push(reader.uint32());
                            break;
                        }
                    case 4: {
                            message.imageId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ReinstallInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.ReinstallInstanceRequest} ReinstallInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReinstallInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ReinstallInstanceRequest message.
             * @function verify
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReinstallInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    if (typeof message.dryRun !== "boolean")
                        return "dryRun: boolean expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.sshKeyIds != null && message.hasOwnProperty("sshKeyIds")) {
                    if (!Array.isArray(message.sshKeyIds))
                        return "sshKeyIds: array expected";
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        if (!$util.isInteger(message.sshKeyIds[i]))
                            return "sshKeyIds: integer[] expected";
                }
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                return null;
            };
    
            /**
             * Creates a ReinstallInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.ReinstallInstanceRequest} ReinstallInstanceRequest
             */
            ReinstallInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.ReinstallInstanceRequest)
                    return object;
                var message = new $root.ca2instances.ReinstallInstanceRequest();
                if (object.dryRun != null)
                    message.dryRun = Boolean(object.dryRun);
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.sshKeyIds) {
                    if (!Array.isArray(object.sshKeyIds))
                        throw TypeError(".ca2instances.ReinstallInstanceRequest.sshKeyIds: array expected");
                    message.sshKeyIds = [];
                    for (var i = 0; i < object.sshKeyIds.length; ++i)
                        message.sshKeyIds[i] = object.sshKeyIds[i] >>> 0;
                }
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                return message;
            };
    
            /**
             * Creates a plain object from a ReinstallInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {ca2instances.ReinstallInstanceRequest} message ReinstallInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReinstallInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.sshKeyIds = [];
                if (options.defaults) {
                    object.dryRun = false;
                    object.instanceId = 0;
                    object.imageId = "";
                }
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    object.dryRun = message.dryRun;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.sshKeyIds && message.sshKeyIds.length) {
                    object.sshKeyIds = [];
                    for (var j = 0; j < message.sshKeyIds.length; ++j)
                        object.sshKeyIds[j] = message.sshKeyIds[j];
                }
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                return object;
            };
    
            /**
             * Converts this ReinstallInstanceRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.ReinstallInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReinstallInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ReinstallInstanceRequest
             * @function getTypeUrl
             * @memberof ca2instances.ReinstallInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReinstallInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.ReinstallInstanceRequest";
            };
    
            return ReinstallInstanceRequest;
        })();
    
        /**
         * ReinstallInstanceError enum.
         * @name ca2instances.ReinstallInstanceError
         * @enum {number}
         * @property {number} RIE_UNKNOWN=0 RIE_UNKNOWN value
         * @property {number} RIE_INSTANCE_NOT_FOUND=1 RIE_INSTANCE_NOT_FOUND value
         * @property {number} RIE_NOT_ALLOWED=2 RIE_NOT_ALLOWED value
         * @property {number} RIA_CHANGE_KEYS_NOT_ALLOWED=3 RIA_CHANGE_KEYS_NOT_ALLOWED value
         * @property {number} RIA_INVALID_SSH_KEY_ID=4 RIA_INVALID_SSH_KEY_ID value
         * @property {number} RIA_INVALID_IMAGE_ID=5 RIA_INVALID_IMAGE_ID value
         */
        ca2instances.ReinstallInstanceError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RIE_UNKNOWN"] = 0;
            values[valuesById[1] = "RIE_INSTANCE_NOT_FOUND"] = 1;
            values[valuesById[2] = "RIE_NOT_ALLOWED"] = 2;
            values[valuesById[3] = "RIA_CHANGE_KEYS_NOT_ALLOWED"] = 3;
            values[valuesById[4] = "RIA_INVALID_SSH_KEY_ID"] = 4;
            values[valuesById[5] = "RIA_INVALID_IMAGE_ID"] = 5;
            return values;
        })();
    
        ca2instances.ReinstallInstanceResponse = (function() {
    
            /**
             * Properties of a ReinstallInstanceResponse.
             * @memberof ca2instances
             * @interface IReinstallInstanceResponse
             * @property {boolean|null} [dryRun] ReinstallInstanceResponse dryRun
             * @property {Array.<ca2instances.ReinstallInstanceError>|null} [errors] ReinstallInstanceResponse errors
             */
    
            /**
             * Constructs a new ReinstallInstanceResponse.
             * @memberof ca2instances
             * @classdesc Represents a ReinstallInstanceResponse.
             * @implements IReinstallInstanceResponse
             * @constructor
             * @param {ca2instances.IReinstallInstanceResponse=} [properties] Properties to set
             */
            function ReinstallInstanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ReinstallInstanceResponse dryRun.
             * @member {boolean} dryRun
             * @memberof ca2instances.ReinstallInstanceResponse
             * @instance
             */
            ReinstallInstanceResponse.prototype.dryRun = false;
    
            /**
             * ReinstallInstanceResponse errors.
             * @member {Array.<ca2instances.ReinstallInstanceError>} errors
             * @memberof ca2instances.ReinstallInstanceResponse
             * @instance
             */
            ReinstallInstanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new ReinstallInstanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {ca2instances.IReinstallInstanceResponse=} [properties] Properties to set
             * @returns {ca2instances.ReinstallInstanceResponse} ReinstallInstanceResponse instance
             */
            ReinstallInstanceResponse.create = function create(properties) {
                return new ReinstallInstanceResponse(properties);
            };
    
            /**
             * Encodes the specified ReinstallInstanceResponse message. Does not implicitly {@link ca2instances.ReinstallInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {ca2instances.IReinstallInstanceResponse} message ReinstallInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReinstallInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.dryRun);
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified ReinstallInstanceResponse message, length delimited. Does not implicitly {@link ca2instances.ReinstallInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {ca2instances.IReinstallInstanceResponse} message ReinstallInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReinstallInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ReinstallInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.ReinstallInstanceResponse} ReinstallInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReinstallInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.ReinstallInstanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dryRun = reader.bool();
                            break;
                        }
                    case 2: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ReinstallInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.ReinstallInstanceResponse} ReinstallInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReinstallInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ReinstallInstanceResponse message.
             * @function verify
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReinstallInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    if (typeof message.dryRun !== "boolean")
                        return "dryRun: boolean expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a ReinstallInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.ReinstallInstanceResponse} ReinstallInstanceResponse
             */
            ReinstallInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.ReinstallInstanceResponse)
                    return object;
                var message = new $root.ca2instances.ReinstallInstanceResponse();
                if (object.dryRun != null)
                    message.dryRun = Boolean(object.dryRun);
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2instances.ReinstallInstanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "RIE_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "RIE_INSTANCE_NOT_FOUND":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "RIE_NOT_ALLOWED":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "RIA_CHANGE_KEYS_NOT_ALLOWED":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "RIA_INVALID_SSH_KEY_ID":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        case "RIA_INVALID_IMAGE_ID":
                        case 5:
                            message.errors[i] = 5;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ReinstallInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {ca2instances.ReinstallInstanceResponse} message ReinstallInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReinstallInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.dryRun = false;
                if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                    object.dryRun = message.dryRun;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2instances.ReinstallInstanceError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2instances.ReinstallInstanceError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this ReinstallInstanceResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.ReinstallInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReinstallInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ReinstallInstanceResponse
             * @function getTypeUrl
             * @memberof ca2instances.ReinstallInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReinstallInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.ReinstallInstanceResponse";
            };
    
            return ReinstallInstanceResponse;
        })();
    
        ca2instances.InstanceChangeListRequest = (function() {
    
            /**
             * Properties of an InstanceChangeListRequest.
             * @memberof ca2instances
             * @interface IInstanceChangeListRequest
             * @property {number|null} [instanceId] InstanceChangeListRequest instanceId
             * @property {number|null} [limit] InstanceChangeListRequest limit
             * @property {number|null} [offset] InstanceChangeListRequest offset
             */
    
            /**
             * Constructs a new InstanceChangeListRequest.
             * @memberof ca2instances
             * @classdesc Represents an InstanceChangeListRequest.
             * @implements IInstanceChangeListRequest
             * @constructor
             * @param {ca2instances.IInstanceChangeListRequest=} [properties] Properties to set
             */
            function InstanceChangeListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * InstanceChangeListRequest instanceId.
             * @member {number} instanceId
             * @memberof ca2instances.InstanceChangeListRequest
             * @instance
             */
            InstanceChangeListRequest.prototype.instanceId = 0;
    
            /**
             * InstanceChangeListRequest limit.
             * @member {number} limit
             * @memberof ca2instances.InstanceChangeListRequest
             * @instance
             */
            InstanceChangeListRequest.prototype.limit = 0;
    
            /**
             * InstanceChangeListRequest offset.
             * @member {number} offset
             * @memberof ca2instances.InstanceChangeListRequest
             * @instance
             */
            InstanceChangeListRequest.prototype.offset = 0;
    
            /**
             * Creates a new InstanceChangeListRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {ca2instances.IInstanceChangeListRequest=} [properties] Properties to set
             * @returns {ca2instances.InstanceChangeListRequest} InstanceChangeListRequest instance
             */
            InstanceChangeListRequest.create = function create(properties) {
                return new InstanceChangeListRequest(properties);
            };
    
            /**
             * Encodes the specified InstanceChangeListRequest message. Does not implicitly {@link ca2instances.InstanceChangeListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {ca2instances.IInstanceChangeListRequest} message InstanceChangeListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceChangeListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.offset);
                return writer;
            };
    
            /**
             * Encodes the specified InstanceChangeListRequest message, length delimited. Does not implicitly {@link ca2instances.InstanceChangeListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {ca2instances.IInstanceChangeListRequest} message InstanceChangeListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceChangeListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an InstanceChangeListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.InstanceChangeListRequest} InstanceChangeListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceChangeListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.InstanceChangeListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.limit = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.offset = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an InstanceChangeListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.InstanceChangeListRequest} InstanceChangeListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceChangeListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an InstanceChangeListRequest message.
             * @function verify
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InstanceChangeListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                return null;
            };
    
            /**
             * Creates an InstanceChangeListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.InstanceChangeListRequest} InstanceChangeListRequest
             */
            InstanceChangeListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.InstanceChangeListRequest)
                    return object;
                var message = new $root.ca2instances.InstanceChangeListRequest();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an InstanceChangeListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {ca2instances.InstanceChangeListRequest} message InstanceChangeListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InstanceChangeListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.instanceId = 0;
                    object.limit = 0;
                    object.offset = 0;
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                return object;
            };
    
            /**
             * Converts this InstanceChangeListRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.InstanceChangeListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InstanceChangeListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for InstanceChangeListRequest
             * @function getTypeUrl
             * @memberof ca2instances.InstanceChangeListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InstanceChangeListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.InstanceChangeListRequest";
            };
    
            return InstanceChangeListRequest;
        })();
    
        ca2instances.InstanceChangeListResponse = (function() {
    
            /**
             * Properties of an InstanceChangeListResponse.
             * @memberof ca2instances
             * @interface IInstanceChangeListResponse
             * @property {Array.<ca2types.IInstanceChange>|null} [items] InstanceChangeListResponse items
             * @property {number|null} [total] InstanceChangeListResponse total
             */
    
            /**
             * Constructs a new InstanceChangeListResponse.
             * @memberof ca2instances
             * @classdesc Represents an InstanceChangeListResponse.
             * @implements IInstanceChangeListResponse
             * @constructor
             * @param {ca2instances.IInstanceChangeListResponse=} [properties] Properties to set
             */
            function InstanceChangeListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * InstanceChangeListResponse items.
             * @member {Array.<ca2types.IInstanceChange>} items
             * @memberof ca2instances.InstanceChangeListResponse
             * @instance
             */
            InstanceChangeListResponse.prototype.items = $util.emptyArray;
    
            /**
             * InstanceChangeListResponse total.
             * @member {number} total
             * @memberof ca2instances.InstanceChangeListResponse
             * @instance
             */
            InstanceChangeListResponse.prototype.total = 0;
    
            /**
             * Creates a new InstanceChangeListResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {ca2instances.IInstanceChangeListResponse=} [properties] Properties to set
             * @returns {ca2instances.InstanceChangeListResponse} InstanceChangeListResponse instance
             */
            InstanceChangeListResponse.create = function create(properties) {
                return new InstanceChangeListResponse(properties);
            };
    
            /**
             * Encodes the specified InstanceChangeListResponse message. Does not implicitly {@link ca2instances.InstanceChangeListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {ca2instances.IInstanceChangeListResponse} message InstanceChangeListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceChangeListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.InstanceChange.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.total);
                return writer;
            };
    
            /**
             * Encodes the specified InstanceChangeListResponse message, length delimited. Does not implicitly {@link ca2instances.InstanceChangeListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {ca2instances.IInstanceChangeListResponse} message InstanceChangeListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceChangeListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an InstanceChangeListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.InstanceChangeListResponse} InstanceChangeListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceChangeListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.InstanceChangeListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.InstanceChange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.total = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an InstanceChangeListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.InstanceChangeListResponse} InstanceChangeListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceChangeListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an InstanceChangeListResponse message.
             * @function verify
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InstanceChangeListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.InstanceChange.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                return null;
            };
    
            /**
             * Creates an InstanceChangeListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.InstanceChangeListResponse} InstanceChangeListResponse
             */
            InstanceChangeListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.InstanceChangeListResponse)
                    return object;
                var message = new $root.ca2instances.InstanceChangeListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2instances.InstanceChangeListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2instances.InstanceChangeListResponse.items: object expected");
                        message.items[i] = $root.ca2types.InstanceChange.fromObject(object.items[i]);
                    }
                }
                if (object.total != null)
                    message.total = object.total >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an InstanceChangeListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {ca2instances.InstanceChangeListResponse} message InstanceChangeListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InstanceChangeListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults)
                    object.total = 0;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.InstanceChange.toObject(message.items[j], options);
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                return object;
            };
    
            /**
             * Converts this InstanceChangeListResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.InstanceChangeListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InstanceChangeListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for InstanceChangeListResponse
             * @function getTypeUrl
             * @memberof ca2instances.InstanceChangeListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InstanceChangeListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.InstanceChangeListResponse";
            };
    
            return InstanceChangeListResponse;
        })();
    
        ca2instances.UpdateInstanceRequest = (function() {
    
            /**
             * Properties of an UpdateInstanceRequest.
             * @memberof ca2instances
             * @interface IUpdateInstanceRequest
             * @property {number|null} [instanceId] UpdateInstanceRequest instanceId
             * @property {string|null} [name] UpdateInstanceRequest name
             * @property {boolean|null} [isImportant] UpdateInstanceRequest isImportant
             */
    
            /**
             * Constructs a new UpdateInstanceRequest.
             * @memberof ca2instances
             * @classdesc Represents an UpdateInstanceRequest.
             * @implements IUpdateInstanceRequest
             * @constructor
             * @param {ca2instances.IUpdateInstanceRequest=} [properties] Properties to set
             */
            function UpdateInstanceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateInstanceRequest instanceId.
             * @member {number} instanceId
             * @memberof ca2instances.UpdateInstanceRequest
             * @instance
             */
            UpdateInstanceRequest.prototype.instanceId = 0;
    
            /**
             * UpdateInstanceRequest name.
             * @member {string|null|undefined} name
             * @memberof ca2instances.UpdateInstanceRequest
             * @instance
             */
            UpdateInstanceRequest.prototype.name = null;
    
            /**
             * UpdateInstanceRequest isImportant.
             * @member {boolean|null|undefined} isImportant
             * @memberof ca2instances.UpdateInstanceRequest
             * @instance
             */
            UpdateInstanceRequest.prototype.isImportant = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * UpdateInstanceRequest _name.
             * @member {"name"|undefined} _name
             * @memberof ca2instances.UpdateInstanceRequest
             * @instance
             */
            Object.defineProperty(UpdateInstanceRequest.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * UpdateInstanceRequest _isImportant.
             * @member {"isImportant"|undefined} _isImportant
             * @memberof ca2instances.UpdateInstanceRequest
             * @instance
             */
            Object.defineProperty(UpdateInstanceRequest.prototype, "_isImportant", {
                get: $util.oneOfGetter($oneOfFields = ["isImportant"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new UpdateInstanceRequest instance using the specified properties.
             * @function create
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {ca2instances.IUpdateInstanceRequest=} [properties] Properties to set
             * @returns {ca2instances.UpdateInstanceRequest} UpdateInstanceRequest instance
             */
            UpdateInstanceRequest.create = function create(properties) {
                return new UpdateInstanceRequest(properties);
            };
    
            /**
             * Encodes the specified UpdateInstanceRequest message. Does not implicitly {@link ca2instances.UpdateInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {ca2instances.IUpdateInstanceRequest} message UpdateInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.isImportant != null && Object.hasOwnProperty.call(message, "isImportant"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isImportant);
                return writer;
            };
    
            /**
             * Encodes the specified UpdateInstanceRequest message, length delimited. Does not implicitly {@link ca2instances.UpdateInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {ca2instances.IUpdateInstanceRequest} message UpdateInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.UpdateInstanceRequest} UpdateInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.UpdateInstanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.isImportant = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.UpdateInstanceRequest} UpdateInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateInstanceRequest message.
             * @function verify
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    properties._name = 1;
                    if (!$util.isString(message.name))
                        return "name: string expected";
                }
                if (message.isImportant != null && message.hasOwnProperty("isImportant")) {
                    properties._isImportant = 1;
                    if (typeof message.isImportant !== "boolean")
                        return "isImportant: boolean expected";
                }
                return null;
            };
    
            /**
             * Creates an UpdateInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.UpdateInstanceRequest} UpdateInstanceRequest
             */
            UpdateInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.UpdateInstanceRequest)
                    return object;
                var message = new $root.ca2instances.UpdateInstanceRequest();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isImportant != null)
                    message.isImportant = Boolean(object.isImportant);
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {ca2instances.UpdateInstanceRequest} message UpdateInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.name != null && message.hasOwnProperty("name")) {
                    object.name = message.name;
                    if (options.oneofs)
                        object._name = "name";
                }
                if (message.isImportant != null && message.hasOwnProperty("isImportant")) {
                    object.isImportant = message.isImportant;
                    if (options.oneofs)
                        object._isImportant = "isImportant";
                }
                return object;
            };
    
            /**
             * Converts this UpdateInstanceRequest to JSON.
             * @function toJSON
             * @memberof ca2instances.UpdateInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateInstanceRequest
             * @function getTypeUrl
             * @memberof ca2instances.UpdateInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.UpdateInstanceRequest";
            };
    
            return UpdateInstanceRequest;
        })();
    
        /**
         * UpdateInstanceError enum.
         * @name ca2instances.UpdateInstanceError
         * @enum {number}
         * @property {number} UIE_UNKNOWN=0 UIE_UNKNOWN value
         * @property {number} UIE_INSTANCE_NOT_FOUND=1 UIE_INSTANCE_NOT_FOUND value
         * @property {number} UIE_SERVER_NAME_TOO_LONG=3 UIE_SERVER_NAME_TOO_LONG value
         * @property {number} UIE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS=4 UIE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS value
         */
        ca2instances.UpdateInstanceError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UIE_UNKNOWN"] = 0;
            values[valuesById[1] = "UIE_INSTANCE_NOT_FOUND"] = 1;
            values[valuesById[3] = "UIE_SERVER_NAME_TOO_LONG"] = 3;
            values[valuesById[4] = "UIE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS"] = 4;
            return values;
        })();
    
        ca2instances.UpdateInstanceResponse = (function() {
    
            /**
             * Properties of an UpdateInstanceResponse.
             * @memberof ca2instances
             * @interface IUpdateInstanceResponse
             * @property {Array.<ca2instances.UpdateInstanceError>|null} [errors] UpdateInstanceResponse errors
             * @property {ca2types.IInstance|null} [instance] UpdateInstanceResponse instance
             */
    
            /**
             * Constructs a new UpdateInstanceResponse.
             * @memberof ca2instances
             * @classdesc Represents an UpdateInstanceResponse.
             * @implements IUpdateInstanceResponse
             * @constructor
             * @param {ca2instances.IUpdateInstanceResponse=} [properties] Properties to set
             */
            function UpdateInstanceResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateInstanceResponse errors.
             * @member {Array.<ca2instances.UpdateInstanceError>} errors
             * @memberof ca2instances.UpdateInstanceResponse
             * @instance
             */
            UpdateInstanceResponse.prototype.errors = $util.emptyArray;
    
            /**
             * UpdateInstanceResponse instance.
             * @member {ca2types.IInstance|null|undefined} instance
             * @memberof ca2instances.UpdateInstanceResponse
             * @instance
             */
            UpdateInstanceResponse.prototype.instance = null;
    
            /**
             * Creates a new UpdateInstanceResponse instance using the specified properties.
             * @function create
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {ca2instances.IUpdateInstanceResponse=} [properties] Properties to set
             * @returns {ca2instances.UpdateInstanceResponse} UpdateInstanceResponse instance
             */
            UpdateInstanceResponse.create = function create(properties) {
                return new UpdateInstanceResponse(properties);
            };
    
            /**
             * Encodes the specified UpdateInstanceResponse message. Does not implicitly {@link ca2instances.UpdateInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {ca2instances.IUpdateInstanceResponse} message UpdateInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.instance != null && Object.hasOwnProperty.call(message, "instance"))
                    $root.ca2types.Instance.encode(message.instance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UpdateInstanceResponse message, length delimited. Does not implicitly {@link ca2instances.UpdateInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {ca2instances.IUpdateInstanceResponse} message UpdateInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2instances.UpdateInstanceResponse} UpdateInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2instances.UpdateInstanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.instance = $root.ca2types.Instance.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2instances.UpdateInstanceResponse} UpdateInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateInstanceResponse message.
             * @function verify
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 3:
                        case 4:
                            break;
                        }
                }
                if (message.instance != null && message.hasOwnProperty("instance")) {
                    var error = $root.ca2types.Instance.verify(message.instance);
                    if (error)
                        return "instance." + error;
                }
                return null;
            };
    
            /**
             * Creates an UpdateInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2instances.UpdateInstanceResponse} UpdateInstanceResponse
             */
            UpdateInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2instances.UpdateInstanceResponse)
                    return object;
                var message = new $root.ca2instances.UpdateInstanceResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2instances.UpdateInstanceResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "UIE_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "UIE_INSTANCE_NOT_FOUND":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "UIE_SERVER_NAME_TOO_LONG":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        case "UIE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS":
                        case 4:
                            message.errors[i] = 4;
                            break;
                        }
                }
                if (object.instance != null) {
                    if (typeof object.instance !== "object")
                        throw TypeError(".ca2instances.UpdateInstanceResponse.instance: object expected");
                    message.instance = $root.ca2types.Instance.fromObject(object.instance);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {ca2instances.UpdateInstanceResponse} message UpdateInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.instance = null;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2instances.UpdateInstanceError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2instances.UpdateInstanceError[message.errors[j]] : message.errors[j];
                }
                if (message.instance != null && message.hasOwnProperty("instance"))
                    object.instance = $root.ca2types.Instance.toObject(message.instance, options);
                return object;
            };
    
            /**
             * Converts this UpdateInstanceResponse to JSON.
             * @function toJSON
             * @memberof ca2instances.UpdateInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateInstanceResponse
             * @function getTypeUrl
             * @memberof ca2instances.UpdateInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2instances.UpdateInstanceResponse";
            };
    
            return UpdateInstanceResponse;
        })();
    
        return ca2instances;
    })();
    
    $root.ca2referrals = (function() {
    
        /**
         * Namespace ca2referrals.
         * @exports ca2referrals
         * @namespace
         */
        var ca2referrals = {};
    
        ca2referrals.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2referrals
             * @interface IRequest
             * @property {ca2referrals.IReferralCodeRequest|null} [referralCode] Request referralCode
             * @property {ca2referrals.IReferralsListRequest|null} [referralsList] Request referralsList
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2referrals
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2referrals.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request referralCode.
             * @member {ca2referrals.IReferralCodeRequest|null|undefined} referralCode
             * @memberof ca2referrals.Request
             * @instance
             */
            Request.prototype.referralCode = null;
    
            /**
             * Request referralsList.
             * @member {ca2referrals.IReferralsListRequest|null|undefined} referralsList
             * @memberof ca2referrals.Request
             * @instance
             */
            Request.prototype.referralsList = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2referrals.Request
             * @static
             * @param {ca2referrals.IRequest=} [properties] Properties to set
             * @returns {ca2referrals.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2referrals.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2referrals.Request
             * @static
             * @param {ca2referrals.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.referralsList != null && Object.hasOwnProperty.call(message, "referralsList"))
                    $root.ca2referrals.ReferralsListRequest.encode(message.referralsList, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.referralCode != null && Object.hasOwnProperty.call(message, "referralCode"))
                    $root.ca2referrals.ReferralCodeRequest.encode(message.referralCode, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2referrals.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2referrals.Request
             * @static
             * @param {ca2referrals.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2referrals.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2referrals.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2referrals.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 31: {
                            message.referralCode = $root.ca2referrals.ReferralCodeRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 30: {
                            message.referralsList = $root.ca2referrals.ReferralsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2referrals.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2referrals.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2referrals.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.referralCode != null && message.hasOwnProperty("referralCode")) {
                    var error = $root.ca2referrals.ReferralCodeRequest.verify(message.referralCode);
                    if (error)
                        return "referralCode." + error;
                }
                if (message.referralsList != null && message.hasOwnProperty("referralsList")) {
                    var error = $root.ca2referrals.ReferralsListRequest.verify(message.referralsList);
                    if (error)
                        return "referralsList." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2referrals.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2referrals.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2referrals.Request)
                    return object;
                var message = new $root.ca2referrals.Request();
                if (object.referralCode != null) {
                    if (typeof object.referralCode !== "object")
                        throw TypeError(".ca2referrals.Request.referralCode: object expected");
                    message.referralCode = $root.ca2referrals.ReferralCodeRequest.fromObject(object.referralCode);
                }
                if (object.referralsList != null) {
                    if (typeof object.referralsList !== "object")
                        throw TypeError(".ca2referrals.Request.referralsList: object expected");
                    message.referralsList = $root.ca2referrals.ReferralsListRequest.fromObject(object.referralsList);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2referrals.Request
             * @static
             * @param {ca2referrals.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.referralsList = null;
                    object.referralCode = null;
                }
                if (message.referralsList != null && message.hasOwnProperty("referralsList"))
                    object.referralsList = $root.ca2referrals.ReferralsListRequest.toObject(message.referralsList, options);
                if (message.referralCode != null && message.hasOwnProperty("referralCode"))
                    object.referralCode = $root.ca2referrals.ReferralCodeRequest.toObject(message.referralCode, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2referrals.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2referrals.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2referrals.Request";
            };
    
            return Request;
        })();
    
        ca2referrals.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2referrals
             * @interface IResponse
             * @property {ca2referrals.IReferralCodeResponse|null} [referralCode] Response referralCode
             * @property {ca2referrals.IReferralsListResponse|null} [referralsList] Response referralsList
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2referrals
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2referrals.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response referralCode.
             * @member {ca2referrals.IReferralCodeResponse|null|undefined} referralCode
             * @memberof ca2referrals.Response
             * @instance
             */
            Response.prototype.referralCode = null;
    
            /**
             * Response referralsList.
             * @member {ca2referrals.IReferralsListResponse|null|undefined} referralsList
             * @memberof ca2referrals.Response
             * @instance
             */
            Response.prototype.referralsList = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2referrals.Response
             * @static
             * @param {ca2referrals.IResponse=} [properties] Properties to set
             * @returns {ca2referrals.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2referrals.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2referrals.Response
             * @static
             * @param {ca2referrals.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.referralsList != null && Object.hasOwnProperty.call(message, "referralsList"))
                    $root.ca2referrals.ReferralsListResponse.encode(message.referralsList, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.referralCode != null && Object.hasOwnProperty.call(message, "referralCode"))
                    $root.ca2referrals.ReferralCodeResponse.encode(message.referralCode, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2referrals.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2referrals.Response
             * @static
             * @param {ca2referrals.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2referrals.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2referrals.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2referrals.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 31: {
                            message.referralCode = $root.ca2referrals.ReferralCodeResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 30: {
                            message.referralsList = $root.ca2referrals.ReferralsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2referrals.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2referrals.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2referrals.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.referralCode != null && message.hasOwnProperty("referralCode")) {
                    var error = $root.ca2referrals.ReferralCodeResponse.verify(message.referralCode);
                    if (error)
                        return "referralCode." + error;
                }
                if (message.referralsList != null && message.hasOwnProperty("referralsList")) {
                    var error = $root.ca2referrals.ReferralsListResponse.verify(message.referralsList);
                    if (error)
                        return "referralsList." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2referrals.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2referrals.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2referrals.Response)
                    return object;
                var message = new $root.ca2referrals.Response();
                if (object.referralCode != null) {
                    if (typeof object.referralCode !== "object")
                        throw TypeError(".ca2referrals.Response.referralCode: object expected");
                    message.referralCode = $root.ca2referrals.ReferralCodeResponse.fromObject(object.referralCode);
                }
                if (object.referralsList != null) {
                    if (typeof object.referralsList !== "object")
                        throw TypeError(".ca2referrals.Response.referralsList: object expected");
                    message.referralsList = $root.ca2referrals.ReferralsListResponse.fromObject(object.referralsList);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2referrals.Response
             * @static
             * @param {ca2referrals.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.referralsList = null;
                    object.referralCode = null;
                }
                if (message.referralsList != null && message.hasOwnProperty("referralsList"))
                    object.referralsList = $root.ca2referrals.ReferralsListResponse.toObject(message.referralsList, options);
                if (message.referralCode != null && message.hasOwnProperty("referralCode"))
                    object.referralCode = $root.ca2referrals.ReferralCodeResponse.toObject(message.referralCode, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2referrals.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2referrals.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2referrals.Response";
            };
    
            return Response;
        })();
    
        ca2referrals.ReferralsListRequest = (function() {
    
            /**
             * Properties of a ReferralsListRequest.
             * @memberof ca2referrals
             * @interface IReferralsListRequest
             * @property {number|null} [limit] ReferralsListRequest limit
             * @property {number|null} [offset] ReferralsListRequest offset
             */
    
            /**
             * Constructs a new ReferralsListRequest.
             * @memberof ca2referrals
             * @classdesc Represents a ReferralsListRequest.
             * @implements IReferralsListRequest
             * @constructor
             * @param {ca2referrals.IReferralsListRequest=} [properties] Properties to set
             */
            function ReferralsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ReferralsListRequest limit.
             * @member {number} limit
             * @memberof ca2referrals.ReferralsListRequest
             * @instance
             */
            ReferralsListRequest.prototype.limit = 0;
    
            /**
             * ReferralsListRequest offset.
             * @member {number} offset
             * @memberof ca2referrals.ReferralsListRequest
             * @instance
             */
            ReferralsListRequest.prototype.offset = 0;
    
            /**
             * Creates a new ReferralsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {ca2referrals.IReferralsListRequest=} [properties] Properties to set
             * @returns {ca2referrals.ReferralsListRequest} ReferralsListRequest instance
             */
            ReferralsListRequest.create = function create(properties) {
                return new ReferralsListRequest(properties);
            };
    
            /**
             * Encodes the specified ReferralsListRequest message. Does not implicitly {@link ca2referrals.ReferralsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {ca2referrals.IReferralsListRequest} message ReferralsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
                return writer;
            };
    
            /**
             * Encodes the specified ReferralsListRequest message, length delimited. Does not implicitly {@link ca2referrals.ReferralsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {ca2referrals.IReferralsListRequest} message ReferralsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ReferralsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2referrals.ReferralsListRequest} ReferralsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2referrals.ReferralsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.limit = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.offset = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ReferralsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2referrals.ReferralsListRequest} ReferralsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ReferralsListRequest message.
             * @function verify
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReferralsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                return null;
            };
    
            /**
             * Creates a ReferralsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2referrals.ReferralsListRequest} ReferralsListRequest
             */
            ReferralsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2referrals.ReferralsListRequest)
                    return object;
                var message = new $root.ca2referrals.ReferralsListRequest();
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ReferralsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {ca2referrals.ReferralsListRequest} message ReferralsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReferralsListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.limit = 0;
                    object.offset = 0;
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                return object;
            };
    
            /**
             * Converts this ReferralsListRequest to JSON.
             * @function toJSON
             * @memberof ca2referrals.ReferralsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReferralsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ReferralsListRequest
             * @function getTypeUrl
             * @memberof ca2referrals.ReferralsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReferralsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2referrals.ReferralsListRequest";
            };
    
            return ReferralsListRequest;
        })();
    
        ca2referrals.ReferralsListResponse = (function() {
    
            /**
             * Properties of a ReferralsListResponse.
             * @memberof ca2referrals
             * @interface IReferralsListResponse
             * @property {Array.<ca2types.IReferral>|null} [items] ReferralsListResponse items
             * @property {number|null} [total] ReferralsListResponse total
             */
    
            /**
             * Constructs a new ReferralsListResponse.
             * @memberof ca2referrals
             * @classdesc Represents a ReferralsListResponse.
             * @implements IReferralsListResponse
             * @constructor
             * @param {ca2referrals.IReferralsListResponse=} [properties] Properties to set
             */
            function ReferralsListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ReferralsListResponse items.
             * @member {Array.<ca2types.IReferral>} items
             * @memberof ca2referrals.ReferralsListResponse
             * @instance
             */
            ReferralsListResponse.prototype.items = $util.emptyArray;
    
            /**
             * ReferralsListResponse total.
             * @member {number} total
             * @memberof ca2referrals.ReferralsListResponse
             * @instance
             */
            ReferralsListResponse.prototype.total = 0;
    
            /**
             * Creates a new ReferralsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {ca2referrals.IReferralsListResponse=} [properties] Properties to set
             * @returns {ca2referrals.ReferralsListResponse} ReferralsListResponse instance
             */
            ReferralsListResponse.create = function create(properties) {
                return new ReferralsListResponse(properties);
            };
    
            /**
             * Encodes the specified ReferralsListResponse message. Does not implicitly {@link ca2referrals.ReferralsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {ca2referrals.IReferralsListResponse} message ReferralsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Referral.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.total);
                return writer;
            };
    
            /**
             * Encodes the specified ReferralsListResponse message, length delimited. Does not implicitly {@link ca2referrals.ReferralsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {ca2referrals.IReferralsListResponse} message ReferralsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ReferralsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2referrals.ReferralsListResponse} ReferralsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2referrals.ReferralsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Referral.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.total = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ReferralsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2referrals.ReferralsListResponse} ReferralsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ReferralsListResponse message.
             * @function verify
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReferralsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Referral.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                return null;
            };
    
            /**
             * Creates a ReferralsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2referrals.ReferralsListResponse} ReferralsListResponse
             */
            ReferralsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2referrals.ReferralsListResponse)
                    return object;
                var message = new $root.ca2referrals.ReferralsListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2referrals.ReferralsListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2referrals.ReferralsListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Referral.fromObject(object.items[i]);
                    }
                }
                if (object.total != null)
                    message.total = object.total >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ReferralsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {ca2referrals.ReferralsListResponse} message ReferralsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReferralsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults)
                    object.total = 0;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Referral.toObject(message.items[j], options);
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                return object;
            };
    
            /**
             * Converts this ReferralsListResponse to JSON.
             * @function toJSON
             * @memberof ca2referrals.ReferralsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReferralsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ReferralsListResponse
             * @function getTypeUrl
             * @memberof ca2referrals.ReferralsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReferralsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2referrals.ReferralsListResponse";
            };
    
            return ReferralsListResponse;
        })();
    
        ca2referrals.ReferralCodeRequest = (function() {
    
            /**
             * Properties of a ReferralCodeRequest.
             * @memberof ca2referrals
             * @interface IReferralCodeRequest
             */
    
            /**
             * Constructs a new ReferralCodeRequest.
             * @memberof ca2referrals
             * @classdesc Represents a ReferralCodeRequest.
             * @implements IReferralCodeRequest
             * @constructor
             * @param {ca2referrals.IReferralCodeRequest=} [properties] Properties to set
             */
            function ReferralCodeRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ReferralCodeRequest instance using the specified properties.
             * @function create
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {ca2referrals.IReferralCodeRequest=} [properties] Properties to set
             * @returns {ca2referrals.ReferralCodeRequest} ReferralCodeRequest instance
             */
            ReferralCodeRequest.create = function create(properties) {
                return new ReferralCodeRequest(properties);
            };
    
            /**
             * Encodes the specified ReferralCodeRequest message. Does not implicitly {@link ca2referrals.ReferralCodeRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {ca2referrals.IReferralCodeRequest} message ReferralCodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralCodeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ReferralCodeRequest message, length delimited. Does not implicitly {@link ca2referrals.ReferralCodeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {ca2referrals.IReferralCodeRequest} message ReferralCodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ReferralCodeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2referrals.ReferralCodeRequest} ReferralCodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralCodeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2referrals.ReferralCodeRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ReferralCodeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2referrals.ReferralCodeRequest} ReferralCodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralCodeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ReferralCodeRequest message.
             * @function verify
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReferralCodeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ReferralCodeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2referrals.ReferralCodeRequest} ReferralCodeRequest
             */
            ReferralCodeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2referrals.ReferralCodeRequest)
                    return object;
                return new $root.ca2referrals.ReferralCodeRequest();
            };
    
            /**
             * Creates a plain object from a ReferralCodeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {ca2referrals.ReferralCodeRequest} message ReferralCodeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReferralCodeRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ReferralCodeRequest to JSON.
             * @function toJSON
             * @memberof ca2referrals.ReferralCodeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReferralCodeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ReferralCodeRequest
             * @function getTypeUrl
             * @memberof ca2referrals.ReferralCodeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReferralCodeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2referrals.ReferralCodeRequest";
            };
    
            return ReferralCodeRequest;
        })();
    
        ca2referrals.ReferralCodeResponse = (function() {
    
            /**
             * Properties of a ReferralCodeResponse.
             * @memberof ca2referrals
             * @interface IReferralCodeResponse
             * @property {string|null} [code] ReferralCodeResponse code
             */
    
            /**
             * Constructs a new ReferralCodeResponse.
             * @memberof ca2referrals
             * @classdesc Represents a ReferralCodeResponse.
             * @implements IReferralCodeResponse
             * @constructor
             * @param {ca2referrals.IReferralCodeResponse=} [properties] Properties to set
             */
            function ReferralCodeResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ReferralCodeResponse code.
             * @member {string} code
             * @memberof ca2referrals.ReferralCodeResponse
             * @instance
             */
            ReferralCodeResponse.prototype.code = "";
    
            /**
             * Creates a new ReferralCodeResponse instance using the specified properties.
             * @function create
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {ca2referrals.IReferralCodeResponse=} [properties] Properties to set
             * @returns {ca2referrals.ReferralCodeResponse} ReferralCodeResponse instance
             */
            ReferralCodeResponse.create = function create(properties) {
                return new ReferralCodeResponse(properties);
            };
    
            /**
             * Encodes the specified ReferralCodeResponse message. Does not implicitly {@link ca2referrals.ReferralCodeResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {ca2referrals.IReferralCodeResponse} message ReferralCodeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralCodeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                return writer;
            };
    
            /**
             * Encodes the specified ReferralCodeResponse message, length delimited. Does not implicitly {@link ca2referrals.ReferralCodeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {ca2referrals.IReferralCodeResponse} message ReferralCodeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReferralCodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ReferralCodeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2referrals.ReferralCodeResponse} ReferralCodeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralCodeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2referrals.ReferralCodeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ReferralCodeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2referrals.ReferralCodeResponse} ReferralCodeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReferralCodeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ReferralCodeResponse message.
             * @function verify
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReferralCodeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                return null;
            };
    
            /**
             * Creates a ReferralCodeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2referrals.ReferralCodeResponse} ReferralCodeResponse
             */
            ReferralCodeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2referrals.ReferralCodeResponse)
                    return object;
                var message = new $root.ca2referrals.ReferralCodeResponse();
                if (object.code != null)
                    message.code = String(object.code);
                return message;
            };
    
            /**
             * Creates a plain object from a ReferralCodeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {ca2referrals.ReferralCodeResponse} message ReferralCodeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReferralCodeResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.code = "";
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };
    
            /**
             * Converts this ReferralCodeResponse to JSON.
             * @function toJSON
             * @memberof ca2referrals.ReferralCodeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReferralCodeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ReferralCodeResponse
             * @function getTypeUrl
             * @memberof ca2referrals.ReferralCodeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReferralCodeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2referrals.ReferralCodeResponse";
            };
    
            return ReferralCodeResponse;
        })();
    
        return ca2referrals;
    })();
    
    $root.ca2servers = (function() {
    
        /**
         * Namespace ca2servers.
         * @exports ca2servers
         * @namespace
         */
        var ca2servers = {};
    
        ca2servers.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2servers
             * @interface IRequest
             * @property {ca2servers.ICPUModelsListRequest|null} [cpuModelsList] Request cpuModelsList
             * @property {ca2servers.ICPUsOptionsRequest|null} [cpusOptions] Request cpusOptions
             * @property {ca2servers.IGPUModelsListRequest|null} [gpuModelsList] Request gpuModelsList
             * @property {ca2servers.IPriceRangeRequest|null} [priceRange] Request priceRange
             * @property {ca2servers.ITabsListRequest|null} [tabsList] Request tabsList
             * @property {ca2servers.IImagesListRequest|null} [imagesList] Request imagesList
             * @property {ca2servers.IPlansListRequest|null} [plansList] Request plansList
             * @property {ca2servers.IProvidersListRequest|null} [providersList] Request providersList
             * @property {ca2servers.IRamOptionsRequest|null} [ramOptions] Request ramOptions
             * @property {ca2servers.IRegionsListRequest|null} [regionsList] Request regionsList
             * @property {ca2servers.IDatacenterListRequest|null} [datacenterList] Request datacenterList
             * @property {ca2servers.IServerClassesListRequest|null} [serverClassesList] Request serverClassesList
             * @property {ca2servers.IStorageOptionsRequest|null} [storageOptions] Request storageOptions
             * @property {ca2servers.ITransferOptionsRequest|null} [transferOptions] Request transferOptions
             * @property {ca2servers.IBenefitsRequest|null} [benefits] Request benefits
             * @property {ca2servers.IAddonsListRequest|null} [addonsList] Request addonsList
             * @property {ca2servers.IPaymentOptionsRequest|null} [paymentOptions] Request paymentOptions
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2servers
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2servers.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request cpuModelsList.
             * @member {ca2servers.ICPUModelsListRequest|null|undefined} cpuModelsList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.cpuModelsList = null;
    
            /**
             * Request cpusOptions.
             * @member {ca2servers.ICPUsOptionsRequest|null|undefined} cpusOptions
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.cpusOptions = null;
    
            /**
             * Request gpuModelsList.
             * @member {ca2servers.IGPUModelsListRequest|null|undefined} gpuModelsList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.gpuModelsList = null;
    
            /**
             * Request priceRange.
             * @member {ca2servers.IPriceRangeRequest|null|undefined} priceRange
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.priceRange = null;
    
            /**
             * Request tabsList.
             * @member {ca2servers.ITabsListRequest|null|undefined} tabsList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.tabsList = null;
    
            /**
             * Request imagesList.
             * @member {ca2servers.IImagesListRequest|null|undefined} imagesList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.imagesList = null;
    
            /**
             * Request plansList.
             * @member {ca2servers.IPlansListRequest|null|undefined} plansList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.plansList = null;
    
            /**
             * Request providersList.
             * @member {ca2servers.IProvidersListRequest|null|undefined} providersList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.providersList = null;
    
            /**
             * Request ramOptions.
             * @member {ca2servers.IRamOptionsRequest|null|undefined} ramOptions
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.ramOptions = null;
    
            /**
             * Request regionsList.
             * @member {ca2servers.IRegionsListRequest|null|undefined} regionsList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.regionsList = null;
    
            /**
             * Request datacenterList.
             * @member {ca2servers.IDatacenterListRequest|null|undefined} datacenterList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.datacenterList = null;
    
            /**
             * Request serverClassesList.
             * @member {ca2servers.IServerClassesListRequest|null|undefined} serverClassesList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.serverClassesList = null;
    
            /**
             * Request storageOptions.
             * @member {ca2servers.IStorageOptionsRequest|null|undefined} storageOptions
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.storageOptions = null;
    
            /**
             * Request transferOptions.
             * @member {ca2servers.ITransferOptionsRequest|null|undefined} transferOptions
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.transferOptions = null;
    
            /**
             * Request benefits.
             * @member {ca2servers.IBenefitsRequest|null|undefined} benefits
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.benefits = null;
    
            /**
             * Request addonsList.
             * @member {ca2servers.IAddonsListRequest|null|undefined} addonsList
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.addonsList = null;
    
            /**
             * Request paymentOptions.
             * @member {ca2servers.IPaymentOptionsRequest|null|undefined} paymentOptions
             * @memberof ca2servers.Request
             * @instance
             */
            Request.prototype.paymentOptions = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2servers.Request
             * @static
             * @param {ca2servers.IRequest=} [properties] Properties to set
             * @returns {ca2servers.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2servers.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.Request
             * @static
             * @param {ca2servers.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.plansList != null && Object.hasOwnProperty.call(message, "plansList"))
                    $root.ca2servers.PlansListRequest.encode(message.plansList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.providersList != null && Object.hasOwnProperty.call(message, "providersList"))
                    $root.ca2servers.ProvidersListRequest.encode(message.providersList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.serverClassesList != null && Object.hasOwnProperty.call(message, "serverClassesList"))
                    $root.ca2servers.ServerClassesListRequest.encode(message.serverClassesList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.regionsList != null && Object.hasOwnProperty.call(message, "regionsList"))
                    $root.ca2servers.RegionsListRequest.encode(message.regionsList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.cpuModelsList != null && Object.hasOwnProperty.call(message, "cpuModelsList"))
                    $root.ca2servers.CPUModelsListRequest.encode(message.cpuModelsList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.ramOptions != null && Object.hasOwnProperty.call(message, "ramOptions"))
                    $root.ca2servers.RamOptionsRequest.encode(message.ramOptions, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.storageOptions != null && Object.hasOwnProperty.call(message, "storageOptions"))
                    $root.ca2servers.StorageOptionsRequest.encode(message.storageOptions, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.cpusOptions != null && Object.hasOwnProperty.call(message, "cpusOptions"))
                    $root.ca2servers.CPUsOptionsRequest.encode(message.cpusOptions, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.transferOptions != null && Object.hasOwnProperty.call(message, "transferOptions"))
                    $root.ca2servers.TransferOptionsRequest.encode(message.transferOptions, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.imagesList != null && Object.hasOwnProperty.call(message, "imagesList"))
                    $root.ca2servers.ImagesListRequest.encode(message.imagesList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.tabsList != null && Object.hasOwnProperty.call(message, "tabsList"))
                    $root.ca2servers.TabsListRequest.encode(message.tabsList, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.benefits != null && Object.hasOwnProperty.call(message, "benefits"))
                    $root.ca2servers.BenefitsRequest.encode(message.benefits, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.datacenterList != null && Object.hasOwnProperty.call(message, "datacenterList"))
                    $root.ca2servers.DatacenterListRequest.encode(message.datacenterList, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.priceRange != null && Object.hasOwnProperty.call(message, "priceRange"))
                    $root.ca2servers.PriceRangeRequest.encode(message.priceRange, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.addonsList != null && Object.hasOwnProperty.call(message, "addonsList"))
                    $root.ca2servers.AddonsListRequest.encode(message.addonsList, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.paymentOptions != null && Object.hasOwnProperty.call(message, "paymentOptions"))
                    $root.ca2servers.PaymentOptionsRequest.encode(message.paymentOptions, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.gpuModelsList != null && Object.hasOwnProperty.call(message, "gpuModelsList"))
                    $root.ca2servers.GPUModelsListRequest.encode(message.gpuModelsList, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2servers.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.Request
             * @static
             * @param {ca2servers.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 5: {
                            message.cpuModelsList = $root.ca2servers.CPUModelsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.cpusOptions = $root.ca2servers.CPUsOptionsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.gpuModelsList = $root.ca2servers.GPUModelsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.priceRange = $root.ca2servers.PriceRangeRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.tabsList = $root.ca2servers.TabsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.imagesList = $root.ca2servers.ImagesListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1: {
                            message.plansList = $root.ca2servers.PlansListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.providersList = $root.ca2servers.ProvidersListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.ramOptions = $root.ca2servers.RamOptionsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.regionsList = $root.ca2servers.RegionsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.datacenterList = $root.ca2servers.DatacenterListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.serverClassesList = $root.ca2servers.ServerClassesListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.storageOptions = $root.ca2servers.StorageOptionsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.transferOptions = $root.ca2servers.TransferOptionsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.benefits = $root.ca2servers.BenefitsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.addonsList = $root.ca2servers.AddonsListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.paymentOptions = $root.ca2servers.PaymentOptionsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2servers.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cpuModelsList != null && message.hasOwnProperty("cpuModelsList")) {
                    var error = $root.ca2servers.CPUModelsListRequest.verify(message.cpuModelsList);
                    if (error)
                        return "cpuModelsList." + error;
                }
                if (message.cpusOptions != null && message.hasOwnProperty("cpusOptions")) {
                    var error = $root.ca2servers.CPUsOptionsRequest.verify(message.cpusOptions);
                    if (error)
                        return "cpusOptions." + error;
                }
                if (message.gpuModelsList != null && message.hasOwnProperty("gpuModelsList")) {
                    var error = $root.ca2servers.GPUModelsListRequest.verify(message.gpuModelsList);
                    if (error)
                        return "gpuModelsList." + error;
                }
                if (message.priceRange != null && message.hasOwnProperty("priceRange")) {
                    var error = $root.ca2servers.PriceRangeRequest.verify(message.priceRange);
                    if (error)
                        return "priceRange." + error;
                }
                if (message.tabsList != null && message.hasOwnProperty("tabsList")) {
                    var error = $root.ca2servers.TabsListRequest.verify(message.tabsList);
                    if (error)
                        return "tabsList." + error;
                }
                if (message.imagesList != null && message.hasOwnProperty("imagesList")) {
                    var error = $root.ca2servers.ImagesListRequest.verify(message.imagesList);
                    if (error)
                        return "imagesList." + error;
                }
                if (message.plansList != null && message.hasOwnProperty("plansList")) {
                    var error = $root.ca2servers.PlansListRequest.verify(message.plansList);
                    if (error)
                        return "plansList." + error;
                }
                if (message.providersList != null && message.hasOwnProperty("providersList")) {
                    var error = $root.ca2servers.ProvidersListRequest.verify(message.providersList);
                    if (error)
                        return "providersList." + error;
                }
                if (message.ramOptions != null && message.hasOwnProperty("ramOptions")) {
                    var error = $root.ca2servers.RamOptionsRequest.verify(message.ramOptions);
                    if (error)
                        return "ramOptions." + error;
                }
                if (message.regionsList != null && message.hasOwnProperty("regionsList")) {
                    var error = $root.ca2servers.RegionsListRequest.verify(message.regionsList);
                    if (error)
                        return "regionsList." + error;
                }
                if (message.datacenterList != null && message.hasOwnProperty("datacenterList")) {
                    var error = $root.ca2servers.DatacenterListRequest.verify(message.datacenterList);
                    if (error)
                        return "datacenterList." + error;
                }
                if (message.serverClassesList != null && message.hasOwnProperty("serverClassesList")) {
                    var error = $root.ca2servers.ServerClassesListRequest.verify(message.serverClassesList);
                    if (error)
                        return "serverClassesList." + error;
                }
                if (message.storageOptions != null && message.hasOwnProperty("storageOptions")) {
                    var error = $root.ca2servers.StorageOptionsRequest.verify(message.storageOptions);
                    if (error)
                        return "storageOptions." + error;
                }
                if (message.transferOptions != null && message.hasOwnProperty("transferOptions")) {
                    var error = $root.ca2servers.TransferOptionsRequest.verify(message.transferOptions);
                    if (error)
                        return "transferOptions." + error;
                }
                if (message.benefits != null && message.hasOwnProperty("benefits")) {
                    var error = $root.ca2servers.BenefitsRequest.verify(message.benefits);
                    if (error)
                        return "benefits." + error;
                }
                if (message.addonsList != null && message.hasOwnProperty("addonsList")) {
                    var error = $root.ca2servers.AddonsListRequest.verify(message.addonsList);
                    if (error)
                        return "addonsList." + error;
                }
                if (message.paymentOptions != null && message.hasOwnProperty("paymentOptions")) {
                    var error = $root.ca2servers.PaymentOptionsRequest.verify(message.paymentOptions);
                    if (error)
                        return "paymentOptions." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.Request)
                    return object;
                var message = new $root.ca2servers.Request();
                if (object.cpuModelsList != null) {
                    if (typeof object.cpuModelsList !== "object")
                        throw TypeError(".ca2servers.Request.cpuModelsList: object expected");
                    message.cpuModelsList = $root.ca2servers.CPUModelsListRequest.fromObject(object.cpuModelsList);
                }
                if (object.cpusOptions != null) {
                    if (typeof object.cpusOptions !== "object")
                        throw TypeError(".ca2servers.Request.cpusOptions: object expected");
                    message.cpusOptions = $root.ca2servers.CPUsOptionsRequest.fromObject(object.cpusOptions);
                }
                if (object.gpuModelsList != null) {
                    if (typeof object.gpuModelsList !== "object")
                        throw TypeError(".ca2servers.Request.gpuModelsList: object expected");
                    message.gpuModelsList = $root.ca2servers.GPUModelsListRequest.fromObject(object.gpuModelsList);
                }
                if (object.priceRange != null) {
                    if (typeof object.priceRange !== "object")
                        throw TypeError(".ca2servers.Request.priceRange: object expected");
                    message.priceRange = $root.ca2servers.PriceRangeRequest.fromObject(object.priceRange);
                }
                if (object.tabsList != null) {
                    if (typeof object.tabsList !== "object")
                        throw TypeError(".ca2servers.Request.tabsList: object expected");
                    message.tabsList = $root.ca2servers.TabsListRequest.fromObject(object.tabsList);
                }
                if (object.imagesList != null) {
                    if (typeof object.imagesList !== "object")
                        throw TypeError(".ca2servers.Request.imagesList: object expected");
                    message.imagesList = $root.ca2servers.ImagesListRequest.fromObject(object.imagesList);
                }
                if (object.plansList != null) {
                    if (typeof object.plansList !== "object")
                        throw TypeError(".ca2servers.Request.plansList: object expected");
                    message.plansList = $root.ca2servers.PlansListRequest.fromObject(object.plansList);
                }
                if (object.providersList != null) {
                    if (typeof object.providersList !== "object")
                        throw TypeError(".ca2servers.Request.providersList: object expected");
                    message.providersList = $root.ca2servers.ProvidersListRequest.fromObject(object.providersList);
                }
                if (object.ramOptions != null) {
                    if (typeof object.ramOptions !== "object")
                        throw TypeError(".ca2servers.Request.ramOptions: object expected");
                    message.ramOptions = $root.ca2servers.RamOptionsRequest.fromObject(object.ramOptions);
                }
                if (object.regionsList != null) {
                    if (typeof object.regionsList !== "object")
                        throw TypeError(".ca2servers.Request.regionsList: object expected");
                    message.regionsList = $root.ca2servers.RegionsListRequest.fromObject(object.regionsList);
                }
                if (object.datacenterList != null) {
                    if (typeof object.datacenterList !== "object")
                        throw TypeError(".ca2servers.Request.datacenterList: object expected");
                    message.datacenterList = $root.ca2servers.DatacenterListRequest.fromObject(object.datacenterList);
                }
                if (object.serverClassesList != null) {
                    if (typeof object.serverClassesList !== "object")
                        throw TypeError(".ca2servers.Request.serverClassesList: object expected");
                    message.serverClassesList = $root.ca2servers.ServerClassesListRequest.fromObject(object.serverClassesList);
                }
                if (object.storageOptions != null) {
                    if (typeof object.storageOptions !== "object")
                        throw TypeError(".ca2servers.Request.storageOptions: object expected");
                    message.storageOptions = $root.ca2servers.StorageOptionsRequest.fromObject(object.storageOptions);
                }
                if (object.transferOptions != null) {
                    if (typeof object.transferOptions !== "object")
                        throw TypeError(".ca2servers.Request.transferOptions: object expected");
                    message.transferOptions = $root.ca2servers.TransferOptionsRequest.fromObject(object.transferOptions);
                }
                if (object.benefits != null) {
                    if (typeof object.benefits !== "object")
                        throw TypeError(".ca2servers.Request.benefits: object expected");
                    message.benefits = $root.ca2servers.BenefitsRequest.fromObject(object.benefits);
                }
                if (object.addonsList != null) {
                    if (typeof object.addonsList !== "object")
                        throw TypeError(".ca2servers.Request.addonsList: object expected");
                    message.addonsList = $root.ca2servers.AddonsListRequest.fromObject(object.addonsList);
                }
                if (object.paymentOptions != null) {
                    if (typeof object.paymentOptions !== "object")
                        throw TypeError(".ca2servers.Request.paymentOptions: object expected");
                    message.paymentOptions = $root.ca2servers.PaymentOptionsRequest.fromObject(object.paymentOptions);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.Request
             * @static
             * @param {ca2servers.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.plansList = null;
                    object.providersList = null;
                    object.serverClassesList = null;
                    object.regionsList = null;
                    object.cpuModelsList = null;
                    object.ramOptions = null;
                    object.storageOptions = null;
                    object.cpusOptions = null;
                    object.transferOptions = null;
                    object.imagesList = null;
                    object.tabsList = null;
                    object.benefits = null;
                    object.datacenterList = null;
                    object.priceRange = null;
                    object.addonsList = null;
                    object.paymentOptions = null;
                    object.gpuModelsList = null;
                }
                if (message.plansList != null && message.hasOwnProperty("plansList"))
                    object.plansList = $root.ca2servers.PlansListRequest.toObject(message.plansList, options);
                if (message.providersList != null && message.hasOwnProperty("providersList"))
                    object.providersList = $root.ca2servers.ProvidersListRequest.toObject(message.providersList, options);
                if (message.serverClassesList != null && message.hasOwnProperty("serverClassesList"))
                    object.serverClassesList = $root.ca2servers.ServerClassesListRequest.toObject(message.serverClassesList, options);
                if (message.regionsList != null && message.hasOwnProperty("regionsList"))
                    object.regionsList = $root.ca2servers.RegionsListRequest.toObject(message.regionsList, options);
                if (message.cpuModelsList != null && message.hasOwnProperty("cpuModelsList"))
                    object.cpuModelsList = $root.ca2servers.CPUModelsListRequest.toObject(message.cpuModelsList, options);
                if (message.ramOptions != null && message.hasOwnProperty("ramOptions"))
                    object.ramOptions = $root.ca2servers.RamOptionsRequest.toObject(message.ramOptions, options);
                if (message.storageOptions != null && message.hasOwnProperty("storageOptions"))
                    object.storageOptions = $root.ca2servers.StorageOptionsRequest.toObject(message.storageOptions, options);
                if (message.cpusOptions != null && message.hasOwnProperty("cpusOptions"))
                    object.cpusOptions = $root.ca2servers.CPUsOptionsRequest.toObject(message.cpusOptions, options);
                if (message.transferOptions != null && message.hasOwnProperty("transferOptions"))
                    object.transferOptions = $root.ca2servers.TransferOptionsRequest.toObject(message.transferOptions, options);
                if (message.imagesList != null && message.hasOwnProperty("imagesList"))
                    object.imagesList = $root.ca2servers.ImagesListRequest.toObject(message.imagesList, options);
                if (message.tabsList != null && message.hasOwnProperty("tabsList"))
                    object.tabsList = $root.ca2servers.TabsListRequest.toObject(message.tabsList, options);
                if (message.benefits != null && message.hasOwnProperty("benefits"))
                    object.benefits = $root.ca2servers.BenefitsRequest.toObject(message.benefits, options);
                if (message.datacenterList != null && message.hasOwnProperty("datacenterList"))
                    object.datacenterList = $root.ca2servers.DatacenterListRequest.toObject(message.datacenterList, options);
                if (message.priceRange != null && message.hasOwnProperty("priceRange"))
                    object.priceRange = $root.ca2servers.PriceRangeRequest.toObject(message.priceRange, options);
                if (message.addonsList != null && message.hasOwnProperty("addonsList"))
                    object.addonsList = $root.ca2servers.AddonsListRequest.toObject(message.addonsList, options);
                if (message.paymentOptions != null && message.hasOwnProperty("paymentOptions"))
                    object.paymentOptions = $root.ca2servers.PaymentOptionsRequest.toObject(message.paymentOptions, options);
                if (message.gpuModelsList != null && message.hasOwnProperty("gpuModelsList"))
                    object.gpuModelsList = $root.ca2servers.GPUModelsListRequest.toObject(message.gpuModelsList, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2servers.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2servers.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.Request";
            };
    
            return Request;
        })();
    
        ca2servers.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2servers
             * @interface IResponse
             * @property {ca2servers.ICPUModelsListResponse|null} [cpuModelsList] Response cpuModelsList
             * @property {ca2servers.ICPUsOptionsResponse|null} [cpusOptions] Response cpusOptions
             * @property {ca2servers.IGPUModelsListResponse|null} [gpuModelsList] Response gpuModelsList
             * @property {ca2servers.ITabsListResponse|null} [tabsList] Response tabsList
             * @property {ca2servers.IPriceRangeResponse|null} [priceRange] Response priceRange
             * @property {ca2servers.IImagesListResponse|null} [imagesList] Response imagesList
             * @property {ca2servers.IPlansListResponse|null} [plansList] Response plansList
             * @property {ca2servers.IProvidersListResponse|null} [providersList] Response providersList
             * @property {ca2servers.IRamOptionsResponse|null} [ramOptions] Response ramOptions
             * @property {ca2servers.IRegionsListResponse|null} [regionsList] Response regionsList
             * @property {ca2servers.IDatacenterListResponse|null} [datacenterList] Response datacenterList
             * @property {ca2servers.IServerClassesListResponse|null} [serverClassesList] Response serverClassesList
             * @property {ca2servers.IStorageOptionsResponse|null} [storageOptions] Response storageOptions
             * @property {ca2servers.ITransferOptionsResponse|null} [transferOptions] Response transferOptions
             * @property {ca2servers.IBenefitsResponse|null} [benefits] Response benefits
             * @property {ca2servers.IAddonsListResponse|null} [addonsList] Response addonsList
             * @property {ca2servers.IPaymentOptionsResponse|null} [paymentOptions] Response paymentOptions
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2servers
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2servers.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response cpuModelsList.
             * @member {ca2servers.ICPUModelsListResponse|null|undefined} cpuModelsList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.cpuModelsList = null;
    
            /**
             * Response cpusOptions.
             * @member {ca2servers.ICPUsOptionsResponse|null|undefined} cpusOptions
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.cpusOptions = null;
    
            /**
             * Response gpuModelsList.
             * @member {ca2servers.IGPUModelsListResponse|null|undefined} gpuModelsList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.gpuModelsList = null;
    
            /**
             * Response tabsList.
             * @member {ca2servers.ITabsListResponse|null|undefined} tabsList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.tabsList = null;
    
            /**
             * Response priceRange.
             * @member {ca2servers.IPriceRangeResponse|null|undefined} priceRange
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.priceRange = null;
    
            /**
             * Response imagesList.
             * @member {ca2servers.IImagesListResponse|null|undefined} imagesList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.imagesList = null;
    
            /**
             * Response plansList.
             * @member {ca2servers.IPlansListResponse|null|undefined} plansList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.plansList = null;
    
            /**
             * Response providersList.
             * @member {ca2servers.IProvidersListResponse|null|undefined} providersList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.providersList = null;
    
            /**
             * Response ramOptions.
             * @member {ca2servers.IRamOptionsResponse|null|undefined} ramOptions
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.ramOptions = null;
    
            /**
             * Response regionsList.
             * @member {ca2servers.IRegionsListResponse|null|undefined} regionsList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.regionsList = null;
    
            /**
             * Response datacenterList.
             * @member {ca2servers.IDatacenterListResponse|null|undefined} datacenterList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.datacenterList = null;
    
            /**
             * Response serverClassesList.
             * @member {ca2servers.IServerClassesListResponse|null|undefined} serverClassesList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.serverClassesList = null;
    
            /**
             * Response storageOptions.
             * @member {ca2servers.IStorageOptionsResponse|null|undefined} storageOptions
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.storageOptions = null;
    
            /**
             * Response transferOptions.
             * @member {ca2servers.ITransferOptionsResponse|null|undefined} transferOptions
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.transferOptions = null;
    
            /**
             * Response benefits.
             * @member {ca2servers.IBenefitsResponse|null|undefined} benefits
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.benefits = null;
    
            /**
             * Response addonsList.
             * @member {ca2servers.IAddonsListResponse|null|undefined} addonsList
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.addonsList = null;
    
            /**
             * Response paymentOptions.
             * @member {ca2servers.IPaymentOptionsResponse|null|undefined} paymentOptions
             * @memberof ca2servers.Response
             * @instance
             */
            Response.prototype.paymentOptions = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2servers.Response
             * @static
             * @param {ca2servers.IResponse=} [properties] Properties to set
             * @returns {ca2servers.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2servers.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.Response
             * @static
             * @param {ca2servers.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.plansList != null && Object.hasOwnProperty.call(message, "plansList"))
                    $root.ca2servers.PlansListResponse.encode(message.plansList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.providersList != null && Object.hasOwnProperty.call(message, "providersList"))
                    $root.ca2servers.ProvidersListResponse.encode(message.providersList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.serverClassesList != null && Object.hasOwnProperty.call(message, "serverClassesList"))
                    $root.ca2servers.ServerClassesListResponse.encode(message.serverClassesList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.regionsList != null && Object.hasOwnProperty.call(message, "regionsList"))
                    $root.ca2servers.RegionsListResponse.encode(message.regionsList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.cpuModelsList != null && Object.hasOwnProperty.call(message, "cpuModelsList"))
                    $root.ca2servers.CPUModelsListResponse.encode(message.cpuModelsList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.ramOptions != null && Object.hasOwnProperty.call(message, "ramOptions"))
                    $root.ca2servers.RamOptionsResponse.encode(message.ramOptions, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.storageOptions != null && Object.hasOwnProperty.call(message, "storageOptions"))
                    $root.ca2servers.StorageOptionsResponse.encode(message.storageOptions, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.cpusOptions != null && Object.hasOwnProperty.call(message, "cpusOptions"))
                    $root.ca2servers.CPUsOptionsResponse.encode(message.cpusOptions, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.transferOptions != null && Object.hasOwnProperty.call(message, "transferOptions"))
                    $root.ca2servers.TransferOptionsResponse.encode(message.transferOptions, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.imagesList != null && Object.hasOwnProperty.call(message, "imagesList"))
                    $root.ca2servers.ImagesListResponse.encode(message.imagesList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.tabsList != null && Object.hasOwnProperty.call(message, "tabsList"))
                    $root.ca2servers.TabsListResponse.encode(message.tabsList, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.benefits != null && Object.hasOwnProperty.call(message, "benefits"))
                    $root.ca2servers.BenefitsResponse.encode(message.benefits, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.datacenterList != null && Object.hasOwnProperty.call(message, "datacenterList"))
                    $root.ca2servers.DatacenterListResponse.encode(message.datacenterList, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.priceRange != null && Object.hasOwnProperty.call(message, "priceRange"))
                    $root.ca2servers.PriceRangeResponse.encode(message.priceRange, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.addonsList != null && Object.hasOwnProperty.call(message, "addonsList"))
                    $root.ca2servers.AddonsListResponse.encode(message.addonsList, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.paymentOptions != null && Object.hasOwnProperty.call(message, "paymentOptions"))
                    $root.ca2servers.PaymentOptionsResponse.encode(message.paymentOptions, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.gpuModelsList != null && Object.hasOwnProperty.call(message, "gpuModelsList"))
                    $root.ca2servers.GPUModelsListResponse.encode(message.gpuModelsList, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2servers.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.Response
             * @static
             * @param {ca2servers.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 5: {
                            message.cpuModelsList = $root.ca2servers.CPUModelsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.cpusOptions = $root.ca2servers.CPUsOptionsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.gpuModelsList = $root.ca2servers.GPUModelsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.tabsList = $root.ca2servers.TabsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.priceRange = $root.ca2servers.PriceRangeResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.imagesList = $root.ca2servers.ImagesListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1: {
                            message.plansList = $root.ca2servers.PlansListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.providersList = $root.ca2servers.ProvidersListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.ramOptions = $root.ca2servers.RamOptionsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.regionsList = $root.ca2servers.RegionsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.datacenterList = $root.ca2servers.DatacenterListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.serverClassesList = $root.ca2servers.ServerClassesListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.storageOptions = $root.ca2servers.StorageOptionsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.transferOptions = $root.ca2servers.TransferOptionsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.benefits = $root.ca2servers.BenefitsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.addonsList = $root.ca2servers.AddonsListResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.paymentOptions = $root.ca2servers.PaymentOptionsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2servers.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cpuModelsList != null && message.hasOwnProperty("cpuModelsList")) {
                    var error = $root.ca2servers.CPUModelsListResponse.verify(message.cpuModelsList);
                    if (error)
                        return "cpuModelsList." + error;
                }
                if (message.cpusOptions != null && message.hasOwnProperty("cpusOptions")) {
                    var error = $root.ca2servers.CPUsOptionsResponse.verify(message.cpusOptions);
                    if (error)
                        return "cpusOptions." + error;
                }
                if (message.gpuModelsList != null && message.hasOwnProperty("gpuModelsList")) {
                    var error = $root.ca2servers.GPUModelsListResponse.verify(message.gpuModelsList);
                    if (error)
                        return "gpuModelsList." + error;
                }
                if (message.tabsList != null && message.hasOwnProperty("tabsList")) {
                    var error = $root.ca2servers.TabsListResponse.verify(message.tabsList);
                    if (error)
                        return "tabsList." + error;
                }
                if (message.priceRange != null && message.hasOwnProperty("priceRange")) {
                    var error = $root.ca2servers.PriceRangeResponse.verify(message.priceRange);
                    if (error)
                        return "priceRange." + error;
                }
                if (message.imagesList != null && message.hasOwnProperty("imagesList")) {
                    var error = $root.ca2servers.ImagesListResponse.verify(message.imagesList);
                    if (error)
                        return "imagesList." + error;
                }
                if (message.plansList != null && message.hasOwnProperty("plansList")) {
                    var error = $root.ca2servers.PlansListResponse.verify(message.plansList);
                    if (error)
                        return "plansList." + error;
                }
                if (message.providersList != null && message.hasOwnProperty("providersList")) {
                    var error = $root.ca2servers.ProvidersListResponse.verify(message.providersList);
                    if (error)
                        return "providersList." + error;
                }
                if (message.ramOptions != null && message.hasOwnProperty("ramOptions")) {
                    var error = $root.ca2servers.RamOptionsResponse.verify(message.ramOptions);
                    if (error)
                        return "ramOptions." + error;
                }
                if (message.regionsList != null && message.hasOwnProperty("regionsList")) {
                    var error = $root.ca2servers.RegionsListResponse.verify(message.regionsList);
                    if (error)
                        return "regionsList." + error;
                }
                if (message.datacenterList != null && message.hasOwnProperty("datacenterList")) {
                    var error = $root.ca2servers.DatacenterListResponse.verify(message.datacenterList);
                    if (error)
                        return "datacenterList." + error;
                }
                if (message.serverClassesList != null && message.hasOwnProperty("serverClassesList")) {
                    var error = $root.ca2servers.ServerClassesListResponse.verify(message.serverClassesList);
                    if (error)
                        return "serverClassesList." + error;
                }
                if (message.storageOptions != null && message.hasOwnProperty("storageOptions")) {
                    var error = $root.ca2servers.StorageOptionsResponse.verify(message.storageOptions);
                    if (error)
                        return "storageOptions." + error;
                }
                if (message.transferOptions != null && message.hasOwnProperty("transferOptions")) {
                    var error = $root.ca2servers.TransferOptionsResponse.verify(message.transferOptions);
                    if (error)
                        return "transferOptions." + error;
                }
                if (message.benefits != null && message.hasOwnProperty("benefits")) {
                    var error = $root.ca2servers.BenefitsResponse.verify(message.benefits);
                    if (error)
                        return "benefits." + error;
                }
                if (message.addonsList != null && message.hasOwnProperty("addonsList")) {
                    var error = $root.ca2servers.AddonsListResponse.verify(message.addonsList);
                    if (error)
                        return "addonsList." + error;
                }
                if (message.paymentOptions != null && message.hasOwnProperty("paymentOptions")) {
                    var error = $root.ca2servers.PaymentOptionsResponse.verify(message.paymentOptions);
                    if (error)
                        return "paymentOptions." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.Response)
                    return object;
                var message = new $root.ca2servers.Response();
                if (object.cpuModelsList != null) {
                    if (typeof object.cpuModelsList !== "object")
                        throw TypeError(".ca2servers.Response.cpuModelsList: object expected");
                    message.cpuModelsList = $root.ca2servers.CPUModelsListResponse.fromObject(object.cpuModelsList);
                }
                if (object.cpusOptions != null) {
                    if (typeof object.cpusOptions !== "object")
                        throw TypeError(".ca2servers.Response.cpusOptions: object expected");
                    message.cpusOptions = $root.ca2servers.CPUsOptionsResponse.fromObject(object.cpusOptions);
                }
                if (object.gpuModelsList != null) {
                    if (typeof object.gpuModelsList !== "object")
                        throw TypeError(".ca2servers.Response.gpuModelsList: object expected");
                    message.gpuModelsList = $root.ca2servers.GPUModelsListResponse.fromObject(object.gpuModelsList);
                }
                if (object.tabsList != null) {
                    if (typeof object.tabsList !== "object")
                        throw TypeError(".ca2servers.Response.tabsList: object expected");
                    message.tabsList = $root.ca2servers.TabsListResponse.fromObject(object.tabsList);
                }
                if (object.priceRange != null) {
                    if (typeof object.priceRange !== "object")
                        throw TypeError(".ca2servers.Response.priceRange: object expected");
                    message.priceRange = $root.ca2servers.PriceRangeResponse.fromObject(object.priceRange);
                }
                if (object.imagesList != null) {
                    if (typeof object.imagesList !== "object")
                        throw TypeError(".ca2servers.Response.imagesList: object expected");
                    message.imagesList = $root.ca2servers.ImagesListResponse.fromObject(object.imagesList);
                }
                if (object.plansList != null) {
                    if (typeof object.plansList !== "object")
                        throw TypeError(".ca2servers.Response.plansList: object expected");
                    message.plansList = $root.ca2servers.PlansListResponse.fromObject(object.plansList);
                }
                if (object.providersList != null) {
                    if (typeof object.providersList !== "object")
                        throw TypeError(".ca2servers.Response.providersList: object expected");
                    message.providersList = $root.ca2servers.ProvidersListResponse.fromObject(object.providersList);
                }
                if (object.ramOptions != null) {
                    if (typeof object.ramOptions !== "object")
                        throw TypeError(".ca2servers.Response.ramOptions: object expected");
                    message.ramOptions = $root.ca2servers.RamOptionsResponse.fromObject(object.ramOptions);
                }
                if (object.regionsList != null) {
                    if (typeof object.regionsList !== "object")
                        throw TypeError(".ca2servers.Response.regionsList: object expected");
                    message.regionsList = $root.ca2servers.RegionsListResponse.fromObject(object.regionsList);
                }
                if (object.datacenterList != null) {
                    if (typeof object.datacenterList !== "object")
                        throw TypeError(".ca2servers.Response.datacenterList: object expected");
                    message.datacenterList = $root.ca2servers.DatacenterListResponse.fromObject(object.datacenterList);
                }
                if (object.serverClassesList != null) {
                    if (typeof object.serverClassesList !== "object")
                        throw TypeError(".ca2servers.Response.serverClassesList: object expected");
                    message.serverClassesList = $root.ca2servers.ServerClassesListResponse.fromObject(object.serverClassesList);
                }
                if (object.storageOptions != null) {
                    if (typeof object.storageOptions !== "object")
                        throw TypeError(".ca2servers.Response.storageOptions: object expected");
                    message.storageOptions = $root.ca2servers.StorageOptionsResponse.fromObject(object.storageOptions);
                }
                if (object.transferOptions != null) {
                    if (typeof object.transferOptions !== "object")
                        throw TypeError(".ca2servers.Response.transferOptions: object expected");
                    message.transferOptions = $root.ca2servers.TransferOptionsResponse.fromObject(object.transferOptions);
                }
                if (object.benefits != null) {
                    if (typeof object.benefits !== "object")
                        throw TypeError(".ca2servers.Response.benefits: object expected");
                    message.benefits = $root.ca2servers.BenefitsResponse.fromObject(object.benefits);
                }
                if (object.addonsList != null) {
                    if (typeof object.addonsList !== "object")
                        throw TypeError(".ca2servers.Response.addonsList: object expected");
                    message.addonsList = $root.ca2servers.AddonsListResponse.fromObject(object.addonsList);
                }
                if (object.paymentOptions != null) {
                    if (typeof object.paymentOptions !== "object")
                        throw TypeError(".ca2servers.Response.paymentOptions: object expected");
                    message.paymentOptions = $root.ca2servers.PaymentOptionsResponse.fromObject(object.paymentOptions);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.Response
             * @static
             * @param {ca2servers.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.plansList = null;
                    object.providersList = null;
                    object.serverClassesList = null;
                    object.regionsList = null;
                    object.cpuModelsList = null;
                    object.ramOptions = null;
                    object.storageOptions = null;
                    object.cpusOptions = null;
                    object.transferOptions = null;
                    object.imagesList = null;
                    object.tabsList = null;
                    object.benefits = null;
                    object.datacenterList = null;
                    object.priceRange = null;
                    object.addonsList = null;
                    object.paymentOptions = null;
                    object.gpuModelsList = null;
                }
                if (message.plansList != null && message.hasOwnProperty("plansList"))
                    object.plansList = $root.ca2servers.PlansListResponse.toObject(message.plansList, options);
                if (message.providersList != null && message.hasOwnProperty("providersList"))
                    object.providersList = $root.ca2servers.ProvidersListResponse.toObject(message.providersList, options);
                if (message.serverClassesList != null && message.hasOwnProperty("serverClassesList"))
                    object.serverClassesList = $root.ca2servers.ServerClassesListResponse.toObject(message.serverClassesList, options);
                if (message.regionsList != null && message.hasOwnProperty("regionsList"))
                    object.regionsList = $root.ca2servers.RegionsListResponse.toObject(message.regionsList, options);
                if (message.cpuModelsList != null && message.hasOwnProperty("cpuModelsList"))
                    object.cpuModelsList = $root.ca2servers.CPUModelsListResponse.toObject(message.cpuModelsList, options);
                if (message.ramOptions != null && message.hasOwnProperty("ramOptions"))
                    object.ramOptions = $root.ca2servers.RamOptionsResponse.toObject(message.ramOptions, options);
                if (message.storageOptions != null && message.hasOwnProperty("storageOptions"))
                    object.storageOptions = $root.ca2servers.StorageOptionsResponse.toObject(message.storageOptions, options);
                if (message.cpusOptions != null && message.hasOwnProperty("cpusOptions"))
                    object.cpusOptions = $root.ca2servers.CPUsOptionsResponse.toObject(message.cpusOptions, options);
                if (message.transferOptions != null && message.hasOwnProperty("transferOptions"))
                    object.transferOptions = $root.ca2servers.TransferOptionsResponse.toObject(message.transferOptions, options);
                if (message.imagesList != null && message.hasOwnProperty("imagesList"))
                    object.imagesList = $root.ca2servers.ImagesListResponse.toObject(message.imagesList, options);
                if (message.tabsList != null && message.hasOwnProperty("tabsList"))
                    object.tabsList = $root.ca2servers.TabsListResponse.toObject(message.tabsList, options);
                if (message.benefits != null && message.hasOwnProperty("benefits"))
                    object.benefits = $root.ca2servers.BenefitsResponse.toObject(message.benefits, options);
                if (message.datacenterList != null && message.hasOwnProperty("datacenterList"))
                    object.datacenterList = $root.ca2servers.DatacenterListResponse.toObject(message.datacenterList, options);
                if (message.priceRange != null && message.hasOwnProperty("priceRange"))
                    object.priceRange = $root.ca2servers.PriceRangeResponse.toObject(message.priceRange, options);
                if (message.addonsList != null && message.hasOwnProperty("addonsList"))
                    object.addonsList = $root.ca2servers.AddonsListResponse.toObject(message.addonsList, options);
                if (message.paymentOptions != null && message.hasOwnProperty("paymentOptions"))
                    object.paymentOptions = $root.ca2servers.PaymentOptionsResponse.toObject(message.paymentOptions, options);
                if (message.gpuModelsList != null && message.hasOwnProperty("gpuModelsList"))
                    object.gpuModelsList = $root.ca2servers.GPUModelsListResponse.toObject(message.gpuModelsList, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2servers.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2servers.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.Response";
            };
    
            return Response;
        })();
    
        ca2servers.ProvidersListRequest = (function() {
    
            /**
             * Properties of a ProvidersListRequest.
             * @memberof ca2servers
             * @interface IProvidersListRequest
             */
    
            /**
             * Constructs a new ProvidersListRequest.
             * @memberof ca2servers
             * @classdesc Represents a ProvidersListRequest.
             * @implements IProvidersListRequest
             * @constructor
             * @param {ca2servers.IProvidersListRequest=} [properties] Properties to set
             */
            function ProvidersListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ProvidersListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {ca2servers.IProvidersListRequest=} [properties] Properties to set
             * @returns {ca2servers.ProvidersListRequest} ProvidersListRequest instance
             */
            ProvidersListRequest.create = function create(properties) {
                return new ProvidersListRequest(properties);
            };
    
            /**
             * Encodes the specified ProvidersListRequest message. Does not implicitly {@link ca2servers.ProvidersListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {ca2servers.IProvidersListRequest} message ProvidersListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProvidersListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ProvidersListRequest message, length delimited. Does not implicitly {@link ca2servers.ProvidersListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {ca2servers.IProvidersListRequest} message ProvidersListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProvidersListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ProvidersListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.ProvidersListRequest} ProvidersListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProvidersListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.ProvidersListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ProvidersListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.ProvidersListRequest} ProvidersListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProvidersListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ProvidersListRequest message.
             * @function verify
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProvidersListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ProvidersListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.ProvidersListRequest} ProvidersListRequest
             */
            ProvidersListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.ProvidersListRequest)
                    return object;
                return new $root.ca2servers.ProvidersListRequest();
            };
    
            /**
             * Creates a plain object from a ProvidersListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {ca2servers.ProvidersListRequest} message ProvidersListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProvidersListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ProvidersListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.ProvidersListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProvidersListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ProvidersListRequest
             * @function getTypeUrl
             * @memberof ca2servers.ProvidersListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProvidersListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.ProvidersListRequest";
            };
    
            return ProvidersListRequest;
        })();
    
        ca2servers.ProvidersListResponse = (function() {
    
            /**
             * Properties of a ProvidersListResponse.
             * @memberof ca2servers
             * @interface IProvidersListResponse
             * @property {Array.<ca2types.IProvider>|null} [items] ProvidersListResponse items
             */
    
            /**
             * Constructs a new ProvidersListResponse.
             * @memberof ca2servers
             * @classdesc Represents a ProvidersListResponse.
             * @implements IProvidersListResponse
             * @constructor
             * @param {ca2servers.IProvidersListResponse=} [properties] Properties to set
             */
            function ProvidersListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ProvidersListResponse items.
             * @member {Array.<ca2types.IProvider>} items
             * @memberof ca2servers.ProvidersListResponse
             * @instance
             */
            ProvidersListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new ProvidersListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {ca2servers.IProvidersListResponse=} [properties] Properties to set
             * @returns {ca2servers.ProvidersListResponse} ProvidersListResponse instance
             */
            ProvidersListResponse.create = function create(properties) {
                return new ProvidersListResponse(properties);
            };
    
            /**
             * Encodes the specified ProvidersListResponse message. Does not implicitly {@link ca2servers.ProvidersListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {ca2servers.IProvidersListResponse} message ProvidersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProvidersListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Provider.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ProvidersListResponse message, length delimited. Does not implicitly {@link ca2servers.ProvidersListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {ca2servers.IProvidersListResponse} message ProvidersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProvidersListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ProvidersListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.ProvidersListResponse} ProvidersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProvidersListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.ProvidersListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Provider.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ProvidersListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.ProvidersListResponse} ProvidersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProvidersListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ProvidersListResponse message.
             * @function verify
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProvidersListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Provider.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ProvidersListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.ProvidersListResponse} ProvidersListResponse
             */
            ProvidersListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.ProvidersListResponse)
                    return object;
                var message = new $root.ca2servers.ProvidersListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.ProvidersListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.ProvidersListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Provider.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ProvidersListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {ca2servers.ProvidersListResponse} message ProvidersListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProvidersListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Provider.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ProvidersListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.ProvidersListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProvidersListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ProvidersListResponse
             * @function getTypeUrl
             * @memberof ca2servers.ProvidersListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProvidersListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.ProvidersListResponse";
            };
    
            return ProvidersListResponse;
        })();
    
        ca2servers.CPUModelsListRequest = (function() {
    
            /**
             * Properties of a CPUModelsListRequest.
             * @memberof ca2servers
             * @interface ICPUModelsListRequest
             */
    
            /**
             * Constructs a new CPUModelsListRequest.
             * @memberof ca2servers
             * @classdesc Represents a CPUModelsListRequest.
             * @implements ICPUModelsListRequest
             * @constructor
             * @param {ca2servers.ICPUModelsListRequest=} [properties] Properties to set
             */
            function CPUModelsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new CPUModelsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {ca2servers.ICPUModelsListRequest=} [properties] Properties to set
             * @returns {ca2servers.CPUModelsListRequest} CPUModelsListRequest instance
             */
            CPUModelsListRequest.create = function create(properties) {
                return new CPUModelsListRequest(properties);
            };
    
            /**
             * Encodes the specified CPUModelsListRequest message. Does not implicitly {@link ca2servers.CPUModelsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {ca2servers.ICPUModelsListRequest} message CPUModelsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUModelsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified CPUModelsListRequest message, length delimited. Does not implicitly {@link ca2servers.CPUModelsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {ca2servers.ICPUModelsListRequest} message CPUModelsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUModelsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CPUModelsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.CPUModelsListRequest} CPUModelsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUModelsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.CPUModelsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CPUModelsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.CPUModelsListRequest} CPUModelsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUModelsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CPUModelsListRequest message.
             * @function verify
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CPUModelsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a CPUModelsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.CPUModelsListRequest} CPUModelsListRequest
             */
            CPUModelsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.CPUModelsListRequest)
                    return object;
                return new $root.ca2servers.CPUModelsListRequest();
            };
    
            /**
             * Creates a plain object from a CPUModelsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {ca2servers.CPUModelsListRequest} message CPUModelsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CPUModelsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this CPUModelsListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.CPUModelsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CPUModelsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CPUModelsListRequest
             * @function getTypeUrl
             * @memberof ca2servers.CPUModelsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CPUModelsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.CPUModelsListRequest";
            };
    
            return CPUModelsListRequest;
        })();
    
        ca2servers.CPUModelsListResponse = (function() {
    
            /**
             * Properties of a CPUModelsListResponse.
             * @memberof ca2servers
             * @interface ICPUModelsListResponse
             * @property {Array.<string>|null} [models] CPUModelsListResponse models
             */
    
            /**
             * Constructs a new CPUModelsListResponse.
             * @memberof ca2servers
             * @classdesc Represents a CPUModelsListResponse.
             * @implements ICPUModelsListResponse
             * @constructor
             * @param {ca2servers.ICPUModelsListResponse=} [properties] Properties to set
             */
            function CPUModelsListResponse(properties) {
                this.models = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CPUModelsListResponse models.
             * @member {Array.<string>} models
             * @memberof ca2servers.CPUModelsListResponse
             * @instance
             */
            CPUModelsListResponse.prototype.models = $util.emptyArray;
    
            /**
             * Creates a new CPUModelsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {ca2servers.ICPUModelsListResponse=} [properties] Properties to set
             * @returns {ca2servers.CPUModelsListResponse} CPUModelsListResponse instance
             */
            CPUModelsListResponse.create = function create(properties) {
                return new CPUModelsListResponse(properties);
            };
    
            /**
             * Encodes the specified CPUModelsListResponse message. Does not implicitly {@link ca2servers.CPUModelsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {ca2servers.ICPUModelsListResponse} message CPUModelsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUModelsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.models != null && message.models.length)
                    for (var i = 0; i < message.models.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.models[i]);
                return writer;
            };
    
            /**
             * Encodes the specified CPUModelsListResponse message, length delimited. Does not implicitly {@link ca2servers.CPUModelsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {ca2servers.ICPUModelsListResponse} message CPUModelsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUModelsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CPUModelsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.CPUModelsListResponse} CPUModelsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUModelsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.CPUModelsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.models && message.models.length))
                                message.models = [];
                            message.models.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CPUModelsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.CPUModelsListResponse} CPUModelsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUModelsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CPUModelsListResponse message.
             * @function verify
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CPUModelsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.models != null && message.hasOwnProperty("models")) {
                    if (!Array.isArray(message.models))
                        return "models: array expected";
                    for (var i = 0; i < message.models.length; ++i)
                        if (!$util.isString(message.models[i]))
                            return "models: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a CPUModelsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.CPUModelsListResponse} CPUModelsListResponse
             */
            CPUModelsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.CPUModelsListResponse)
                    return object;
                var message = new $root.ca2servers.CPUModelsListResponse();
                if (object.models) {
                    if (!Array.isArray(object.models))
                        throw TypeError(".ca2servers.CPUModelsListResponse.models: array expected");
                    message.models = [];
                    for (var i = 0; i < object.models.length; ++i)
                        message.models[i] = String(object.models[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CPUModelsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {ca2servers.CPUModelsListResponse} message CPUModelsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CPUModelsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.models = [];
                if (message.models && message.models.length) {
                    object.models = [];
                    for (var j = 0; j < message.models.length; ++j)
                        object.models[j] = message.models[j];
                }
                return object;
            };
    
            /**
             * Converts this CPUModelsListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.CPUModelsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CPUModelsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CPUModelsListResponse
             * @function getTypeUrl
             * @memberof ca2servers.CPUModelsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CPUModelsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.CPUModelsListResponse";
            };
    
            return CPUModelsListResponse;
        })();
    
        ca2servers.RegionsListRequest = (function() {
    
            /**
             * Properties of a RegionsListRequest.
             * @memberof ca2servers
             * @interface IRegionsListRequest
             */
    
            /**
             * Constructs a new RegionsListRequest.
             * @memberof ca2servers
             * @classdesc Represents a RegionsListRequest.
             * @implements IRegionsListRequest
             * @constructor
             * @param {ca2servers.IRegionsListRequest=} [properties] Properties to set
             */
            function RegionsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new RegionsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {ca2servers.IRegionsListRequest=} [properties] Properties to set
             * @returns {ca2servers.RegionsListRequest} RegionsListRequest instance
             */
            RegionsListRequest.create = function create(properties) {
                return new RegionsListRequest(properties);
            };
    
            /**
             * Encodes the specified RegionsListRequest message. Does not implicitly {@link ca2servers.RegionsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {ca2servers.IRegionsListRequest} message RegionsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegionsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified RegionsListRequest message, length delimited. Does not implicitly {@link ca2servers.RegionsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {ca2servers.IRegionsListRequest} message RegionsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegionsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RegionsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.RegionsListRequest} RegionsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegionsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.RegionsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RegionsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.RegionsListRequest} RegionsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegionsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RegionsListRequest message.
             * @function verify
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegionsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a RegionsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.RegionsListRequest} RegionsListRequest
             */
            RegionsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.RegionsListRequest)
                    return object;
                return new $root.ca2servers.RegionsListRequest();
            };
    
            /**
             * Creates a plain object from a RegionsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {ca2servers.RegionsListRequest} message RegionsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegionsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this RegionsListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.RegionsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegionsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RegionsListRequest
             * @function getTypeUrl
             * @memberof ca2servers.RegionsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RegionsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.RegionsListRequest";
            };
    
            return RegionsListRequest;
        })();
    
        ca2servers.RegionsListResponse = (function() {
    
            /**
             * Properties of a RegionsListResponse.
             * @memberof ca2servers
             * @interface IRegionsListResponse
             * @property {Array.<ca2types.IRegion>|null} [items] RegionsListResponse items
             */
    
            /**
             * Constructs a new RegionsListResponse.
             * @memberof ca2servers
             * @classdesc Represents a RegionsListResponse.
             * @implements IRegionsListResponse
             * @constructor
             * @param {ca2servers.IRegionsListResponse=} [properties] Properties to set
             */
            function RegionsListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RegionsListResponse items.
             * @member {Array.<ca2types.IRegion>} items
             * @memberof ca2servers.RegionsListResponse
             * @instance
             */
            RegionsListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new RegionsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {ca2servers.IRegionsListResponse=} [properties] Properties to set
             * @returns {ca2servers.RegionsListResponse} RegionsListResponse instance
             */
            RegionsListResponse.create = function create(properties) {
                return new RegionsListResponse(properties);
            };
    
            /**
             * Encodes the specified RegionsListResponse message. Does not implicitly {@link ca2servers.RegionsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {ca2servers.IRegionsListResponse} message RegionsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegionsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Region.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RegionsListResponse message, length delimited. Does not implicitly {@link ca2servers.RegionsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {ca2servers.IRegionsListResponse} message RegionsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegionsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RegionsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.RegionsListResponse} RegionsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegionsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.RegionsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Region.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RegionsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.RegionsListResponse} RegionsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegionsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RegionsListResponse message.
             * @function verify
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegionsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Region.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a RegionsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.RegionsListResponse} RegionsListResponse
             */
            RegionsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.RegionsListResponse)
                    return object;
                var message = new $root.ca2servers.RegionsListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.RegionsListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.RegionsListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Region.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RegionsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {ca2servers.RegionsListResponse} message RegionsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegionsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Region.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this RegionsListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.RegionsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegionsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RegionsListResponse
             * @function getTypeUrl
             * @memberof ca2servers.RegionsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RegionsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.RegionsListResponse";
            };
    
            return RegionsListResponse;
        })();
    
        ca2servers.DatacenterListRequest = (function() {
    
            /**
             * Properties of a DatacenterListRequest.
             * @memberof ca2servers
             * @interface IDatacenterListRequest
             */
    
            /**
             * Constructs a new DatacenterListRequest.
             * @memberof ca2servers
             * @classdesc Represents a DatacenterListRequest.
             * @implements IDatacenterListRequest
             * @constructor
             * @param {ca2servers.IDatacenterListRequest=} [properties] Properties to set
             */
            function DatacenterListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new DatacenterListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {ca2servers.IDatacenterListRequest=} [properties] Properties to set
             * @returns {ca2servers.DatacenterListRequest} DatacenterListRequest instance
             */
            DatacenterListRequest.create = function create(properties) {
                return new DatacenterListRequest(properties);
            };
    
            /**
             * Encodes the specified DatacenterListRequest message. Does not implicitly {@link ca2servers.DatacenterListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {ca2servers.IDatacenterListRequest} message DatacenterListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DatacenterListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified DatacenterListRequest message, length delimited. Does not implicitly {@link ca2servers.DatacenterListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {ca2servers.IDatacenterListRequest} message DatacenterListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DatacenterListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DatacenterListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.DatacenterListRequest} DatacenterListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DatacenterListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.DatacenterListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DatacenterListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.DatacenterListRequest} DatacenterListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DatacenterListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DatacenterListRequest message.
             * @function verify
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DatacenterListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a DatacenterListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.DatacenterListRequest} DatacenterListRequest
             */
            DatacenterListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.DatacenterListRequest)
                    return object;
                return new $root.ca2servers.DatacenterListRequest();
            };
    
            /**
             * Creates a plain object from a DatacenterListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {ca2servers.DatacenterListRequest} message DatacenterListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DatacenterListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this DatacenterListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.DatacenterListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DatacenterListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DatacenterListRequest
             * @function getTypeUrl
             * @memberof ca2servers.DatacenterListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DatacenterListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.DatacenterListRequest";
            };
    
            return DatacenterListRequest;
        })();
    
        ca2servers.DatacenterListResponse = (function() {
    
            /**
             * Properties of a DatacenterListResponse.
             * @memberof ca2servers
             * @interface IDatacenterListResponse
             * @property {Array.<ca2types.IDatacenter>|null} [items] DatacenterListResponse items
             */
    
            /**
             * Constructs a new DatacenterListResponse.
             * @memberof ca2servers
             * @classdesc Represents a DatacenterListResponse.
             * @implements IDatacenterListResponse
             * @constructor
             * @param {ca2servers.IDatacenterListResponse=} [properties] Properties to set
             */
            function DatacenterListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DatacenterListResponse items.
             * @member {Array.<ca2types.IDatacenter>} items
             * @memberof ca2servers.DatacenterListResponse
             * @instance
             */
            DatacenterListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new DatacenterListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {ca2servers.IDatacenterListResponse=} [properties] Properties to set
             * @returns {ca2servers.DatacenterListResponse} DatacenterListResponse instance
             */
            DatacenterListResponse.create = function create(properties) {
                return new DatacenterListResponse(properties);
            };
    
            /**
             * Encodes the specified DatacenterListResponse message. Does not implicitly {@link ca2servers.DatacenterListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {ca2servers.IDatacenterListResponse} message DatacenterListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DatacenterListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Datacenter.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified DatacenterListResponse message, length delimited. Does not implicitly {@link ca2servers.DatacenterListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {ca2servers.IDatacenterListResponse} message DatacenterListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DatacenterListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DatacenterListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.DatacenterListResponse} DatacenterListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DatacenterListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.DatacenterListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Datacenter.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DatacenterListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.DatacenterListResponse} DatacenterListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DatacenterListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DatacenterListResponse message.
             * @function verify
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DatacenterListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Datacenter.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a DatacenterListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.DatacenterListResponse} DatacenterListResponse
             */
            DatacenterListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.DatacenterListResponse)
                    return object;
                var message = new $root.ca2servers.DatacenterListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.DatacenterListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.DatacenterListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Datacenter.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DatacenterListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {ca2servers.DatacenterListResponse} message DatacenterListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DatacenterListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Datacenter.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this DatacenterListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.DatacenterListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DatacenterListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DatacenterListResponse
             * @function getTypeUrl
             * @memberof ca2servers.DatacenterListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DatacenterListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.DatacenterListResponse";
            };
    
            return DatacenterListResponse;
        })();
    
        ca2servers.ServerClassesListRequest = (function() {
    
            /**
             * Properties of a ServerClassesListRequest.
             * @memberof ca2servers
             * @interface IServerClassesListRequest
             * @property {Array.<string>|null} [providerIds] ServerClassesListRequest providerIds
             */
    
            /**
             * Constructs a new ServerClassesListRequest.
             * @memberof ca2servers
             * @classdesc Represents a ServerClassesListRequest.
             * @implements IServerClassesListRequest
             * @constructor
             * @param {ca2servers.IServerClassesListRequest=} [properties] Properties to set
             */
            function ServerClassesListRequest(properties) {
                this.providerIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServerClassesListRequest providerIds.
             * @member {Array.<string>} providerIds
             * @memberof ca2servers.ServerClassesListRequest
             * @instance
             */
            ServerClassesListRequest.prototype.providerIds = $util.emptyArray;
    
            /**
             * Creates a new ServerClassesListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {ca2servers.IServerClassesListRequest=} [properties] Properties to set
             * @returns {ca2servers.ServerClassesListRequest} ServerClassesListRequest instance
             */
            ServerClassesListRequest.create = function create(properties) {
                return new ServerClassesListRequest(properties);
            };
    
            /**
             * Encodes the specified ServerClassesListRequest message. Does not implicitly {@link ca2servers.ServerClassesListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {ca2servers.IServerClassesListRequest} message ServerClassesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerClassesListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.providerIds != null && message.providerIds.length)
                    for (var i = 0; i < message.providerIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerIds[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ServerClassesListRequest message, length delimited. Does not implicitly {@link ca2servers.ServerClassesListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {ca2servers.IServerClassesListRequest} message ServerClassesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerClassesListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServerClassesListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.ServerClassesListRequest} ServerClassesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerClassesListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.ServerClassesListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.providerIds && message.providerIds.length))
                                message.providerIds = [];
                            message.providerIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServerClassesListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.ServerClassesListRequest} ServerClassesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerClassesListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServerClassesListRequest message.
             * @function verify
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerClassesListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.providerIds != null && message.hasOwnProperty("providerIds")) {
                    if (!Array.isArray(message.providerIds))
                        return "providerIds: array expected";
                    for (var i = 0; i < message.providerIds.length; ++i)
                        if (!$util.isString(message.providerIds[i]))
                            return "providerIds: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ServerClassesListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.ServerClassesListRequest} ServerClassesListRequest
             */
            ServerClassesListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.ServerClassesListRequest)
                    return object;
                var message = new $root.ca2servers.ServerClassesListRequest();
                if (object.providerIds) {
                    if (!Array.isArray(object.providerIds))
                        throw TypeError(".ca2servers.ServerClassesListRequest.providerIds: array expected");
                    message.providerIds = [];
                    for (var i = 0; i < object.providerIds.length; ++i)
                        message.providerIds[i] = String(object.providerIds[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ServerClassesListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {ca2servers.ServerClassesListRequest} message ServerClassesListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerClassesListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.providerIds = [];
                if (message.providerIds && message.providerIds.length) {
                    object.providerIds = [];
                    for (var j = 0; j < message.providerIds.length; ++j)
                        object.providerIds[j] = message.providerIds[j];
                }
                return object;
            };
    
            /**
             * Converts this ServerClassesListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.ServerClassesListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerClassesListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServerClassesListRequest
             * @function getTypeUrl
             * @memberof ca2servers.ServerClassesListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServerClassesListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.ServerClassesListRequest";
            };
    
            return ServerClassesListRequest;
        })();
    
        ca2servers.ServerClassesListResponse = (function() {
    
            /**
             * Properties of a ServerClassesListResponse.
             * @memberof ca2servers
             * @interface IServerClassesListResponse
             * @property {Array.<ca2types.IServerClass>|null} [items] ServerClassesListResponse items
             */
    
            /**
             * Constructs a new ServerClassesListResponse.
             * @memberof ca2servers
             * @classdesc Represents a ServerClassesListResponse.
             * @implements IServerClassesListResponse
             * @constructor
             * @param {ca2servers.IServerClassesListResponse=} [properties] Properties to set
             */
            function ServerClassesListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServerClassesListResponse items.
             * @member {Array.<ca2types.IServerClass>} items
             * @memberof ca2servers.ServerClassesListResponse
             * @instance
             */
            ServerClassesListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new ServerClassesListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {ca2servers.IServerClassesListResponse=} [properties] Properties to set
             * @returns {ca2servers.ServerClassesListResponse} ServerClassesListResponse instance
             */
            ServerClassesListResponse.create = function create(properties) {
                return new ServerClassesListResponse(properties);
            };
    
            /**
             * Encodes the specified ServerClassesListResponse message. Does not implicitly {@link ca2servers.ServerClassesListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {ca2servers.IServerClassesListResponse} message ServerClassesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerClassesListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.ServerClass.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ServerClassesListResponse message, length delimited. Does not implicitly {@link ca2servers.ServerClassesListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {ca2servers.IServerClassesListResponse} message ServerClassesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerClassesListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServerClassesListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.ServerClassesListResponse} ServerClassesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerClassesListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.ServerClassesListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.ServerClass.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServerClassesListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.ServerClassesListResponse} ServerClassesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerClassesListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServerClassesListResponse message.
             * @function verify
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerClassesListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.ServerClass.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ServerClassesListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.ServerClassesListResponse} ServerClassesListResponse
             */
            ServerClassesListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.ServerClassesListResponse)
                    return object;
                var message = new $root.ca2servers.ServerClassesListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.ServerClassesListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.ServerClassesListResponse.items: object expected");
                        message.items[i] = $root.ca2types.ServerClass.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ServerClassesListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {ca2servers.ServerClassesListResponse} message ServerClassesListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerClassesListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.ServerClass.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ServerClassesListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.ServerClassesListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerClassesListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServerClassesListResponse
             * @function getTypeUrl
             * @memberof ca2servers.ServerClassesListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServerClassesListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.ServerClassesListResponse";
            };
    
            return ServerClassesListResponse;
        })();
    
        ca2servers.PlansListRequest = (function() {
    
            /**
             * Properties of a PlansListRequest.
             * @memberof ca2servers
             * @interface IPlansListRequest
             * @property {Array.<string>|null} [ids] PlansListRequest ids
             * @property {Array.<string>|null} [providerIds] PlansListRequest providerIds
             * @property {Array.<string>|null} [serverClassIds] PlansListRequest serverClassIds
             * @property {ca2types.IFloatRange|null} [monthlyRateUsdRange] PlansListRequest monthlyRateUsdRange
             * @property {ca2types.IFloatRange|null} [hourlyRateUsdRange] PlansListRequest hourlyRateUsdRange
             * @property {ca2types.IUint64Range|null} [ramRange] PlansListRequest ramRange
             * @property {Array.<Long>|null} [ramValues] PlansListRequest ramValues
             * @property {ca2types.IUint32Range|null} [cpusRange] PlansListRequest cpusRange
             * @property {Array.<number>|null} [cpusValues] PlansListRequest cpusValues
             * @property {ca2types.IUint64Range|null} [storageRange] PlansListRequest storageRange
             * @property {Array.<Long>|null} [storageValues] PlansListRequest storageValues
             * @property {ca2types.IUint64Range|null} [transferRange] PlansListRequest transferRange
             * @property {Array.<Long>|null} [transferValues] PlansListRequest transferValues
             * @property {Array.<string>|null} [cpuModels] PlansListRequest cpuModels
             * @property {Array.<string>|null} [gpuModels] PlansListRequest gpuModels
             * @property {string|null} [tabId] PlansListRequest tabId
             * @property {Array.<string>|null} [regionIds] PlansListRequest regionIds
             */
    
            /**
             * Constructs a new PlansListRequest.
             * @memberof ca2servers
             * @classdesc Represents a PlansListRequest.
             * @implements IPlansListRequest
             * @constructor
             * @param {ca2servers.IPlansListRequest=} [properties] Properties to set
             */
            function PlansListRequest(properties) {
                this.ids = [];
                this.providerIds = [];
                this.serverClassIds = [];
                this.ramValues = [];
                this.cpusValues = [];
                this.storageValues = [];
                this.transferValues = [];
                this.cpuModels = [];
                this.gpuModels = [];
                this.regionIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PlansListRequest ids.
             * @member {Array.<string>} ids
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.ids = $util.emptyArray;
    
            /**
             * PlansListRequest providerIds.
             * @member {Array.<string>} providerIds
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.providerIds = $util.emptyArray;
    
            /**
             * PlansListRequest serverClassIds.
             * @member {Array.<string>} serverClassIds
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.serverClassIds = $util.emptyArray;
    
            /**
             * PlansListRequest monthlyRateUsdRange.
             * @member {ca2types.IFloatRange|null|undefined} monthlyRateUsdRange
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.monthlyRateUsdRange = null;
    
            /**
             * PlansListRequest hourlyRateUsdRange.
             * @member {ca2types.IFloatRange|null|undefined} hourlyRateUsdRange
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.hourlyRateUsdRange = null;
    
            /**
             * PlansListRequest ramRange.
             * @member {ca2types.IUint64Range|null|undefined} ramRange
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.ramRange = null;
    
            /**
             * PlansListRequest ramValues.
             * @member {Array.<Long>} ramValues
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.ramValues = $util.emptyArray;
    
            /**
             * PlansListRequest cpusRange.
             * @member {ca2types.IUint32Range|null|undefined} cpusRange
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.cpusRange = null;
    
            /**
             * PlansListRequest cpusValues.
             * @member {Array.<number>} cpusValues
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.cpusValues = $util.emptyArray;
    
            /**
             * PlansListRequest storageRange.
             * @member {ca2types.IUint64Range|null|undefined} storageRange
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.storageRange = null;
    
            /**
             * PlansListRequest storageValues.
             * @member {Array.<Long>} storageValues
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.storageValues = $util.emptyArray;
    
            /**
             * PlansListRequest transferRange.
             * @member {ca2types.IUint64Range|null|undefined} transferRange
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.transferRange = null;
    
            /**
             * PlansListRequest transferValues.
             * @member {Array.<Long>} transferValues
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.transferValues = $util.emptyArray;
    
            /**
             * PlansListRequest cpuModels.
             * @member {Array.<string>} cpuModels
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.cpuModels = $util.emptyArray;
    
            /**
             * PlansListRequest gpuModels.
             * @member {Array.<string>} gpuModels
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.gpuModels = $util.emptyArray;
    
            /**
             * PlansListRequest tabId.
             * @member {string} tabId
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.tabId = "";
    
            /**
             * PlansListRequest regionIds.
             * @member {Array.<string>} regionIds
             * @memberof ca2servers.PlansListRequest
             * @instance
             */
            PlansListRequest.prototype.regionIds = $util.emptyArray;
    
            /**
             * Creates a new PlansListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {ca2servers.IPlansListRequest=} [properties] Properties to set
             * @returns {ca2servers.PlansListRequest} PlansListRequest instance
             */
            PlansListRequest.create = function create(properties) {
                return new PlansListRequest(properties);
            };
    
            /**
             * Encodes the specified PlansListRequest message. Does not implicitly {@link ca2servers.PlansListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {ca2servers.IPlansListRequest} message PlansListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlansListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.providerIds != null && message.providerIds.length)
                    for (var i = 0; i < message.providerIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerIds[i]);
                if (message.monthlyRateUsdRange != null && Object.hasOwnProperty.call(message, "monthlyRateUsdRange"))
                    $root.ca2types.FloatRange.encode(message.monthlyRateUsdRange, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.ramRange != null && Object.hasOwnProperty.call(message, "ramRange"))
                    $root.ca2types.Uint64Range.encode(message.ramRange, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.cpusRange != null && Object.hasOwnProperty.call(message, "cpusRange"))
                    $root.ca2types.Uint32Range.encode(message.cpusRange, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.storageRange != null && Object.hasOwnProperty.call(message, "storageRange"))
                    $root.ca2types.Uint64Range.encode(message.storageRange, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.transferRange != null && Object.hasOwnProperty.call(message, "transferRange"))
                    $root.ca2types.Uint64Range.encode(message.transferRange, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.cpuModels != null && message.cpuModels.length)
                    for (var i = 0; i < message.cpuModels.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.cpuModels[i]);
                if (message.hourlyRateUsdRange != null && Object.hasOwnProperty.call(message, "hourlyRateUsdRange"))
                    $root.ca2types.FloatRange.encode(message.hourlyRateUsdRange, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.serverClassIds != null && message.serverClassIds.length)
                    for (var i = 0; i < message.serverClassIds.length; ++i)
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.serverClassIds[i]);
                if (message.ramValues != null && message.ramValues.length) {
                    writer.uint32(/* id 10, wireType 2 =*/82).fork();
                    for (var i = 0; i < message.ramValues.length; ++i)
                        writer.uint64(message.ramValues[i]);
                    writer.ldelim();
                }
                if (message.cpusValues != null && message.cpusValues.length) {
                    writer.uint32(/* id 11, wireType 2 =*/90).fork();
                    for (var i = 0; i < message.cpusValues.length; ++i)
                        writer.uint32(message.cpusValues[i]);
                    writer.ldelim();
                }
                if (message.storageValues != null && message.storageValues.length) {
                    writer.uint32(/* id 12, wireType 2 =*/98).fork();
                    for (var i = 0; i < message.storageValues.length; ++i)
                        writer.uint64(message.storageValues[i]);
                    writer.ldelim();
                }
                if (message.transferValues != null && message.transferValues.length) {
                    writer.uint32(/* id 13, wireType 2 =*/106).fork();
                    for (var i = 0; i < message.transferValues.length; ++i)
                        writer.uint64(message.transferValues[i]);
                    writer.ldelim();
                }
                if (message.tabId != null && Object.hasOwnProperty.call(message, "tabId"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.tabId);
                if (message.regionIds != null && message.regionIds.length)
                    for (var i = 0; i < message.regionIds.length; ++i)
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.regionIds[i]);
                if (message.ids != null && message.ids.length)
                    for (var i = 0; i < message.ids.length; ++i)
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.ids[i]);
                if (message.gpuModels != null && message.gpuModels.length)
                    for (var i = 0; i < message.gpuModels.length; ++i)
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.gpuModels[i]);
                return writer;
            };
    
            /**
             * Encodes the specified PlansListRequest message, length delimited. Does not implicitly {@link ca2servers.PlansListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {ca2servers.IPlansListRequest} message PlansListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlansListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PlansListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.PlansListRequest} PlansListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlansListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.PlansListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 16: {
                            if (!(message.ids && message.ids.length))
                                message.ids = [];
                            message.ids.push(reader.string());
                            break;
                        }
                    case 1: {
                            if (!(message.providerIds && message.providerIds.length))
                                message.providerIds = [];
                            message.providerIds.push(reader.string());
                            break;
                        }
                    case 9: {
                            if (!(message.serverClassIds && message.serverClassIds.length))
                                message.serverClassIds = [];
                            message.serverClassIds.push(reader.string());
                            break;
                        }
                    case 2: {
                            message.monthlyRateUsdRange = $root.ca2types.FloatRange.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.hourlyRateUsdRange = $root.ca2types.FloatRange.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.ramRange = $root.ca2types.Uint64Range.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            if (!(message.ramValues && message.ramValues.length))
                                message.ramValues = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.ramValues.push(reader.uint64());
                            } else
                                message.ramValues.push(reader.uint64());
                            break;
                        }
                    case 4: {
                            message.cpusRange = $root.ca2types.Uint32Range.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            if (!(message.cpusValues && message.cpusValues.length))
                                message.cpusValues = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.cpusValues.push(reader.uint32());
                            } else
                                message.cpusValues.push(reader.uint32());
                            break;
                        }
                    case 5: {
                            message.storageRange = $root.ca2types.Uint64Range.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            if (!(message.storageValues && message.storageValues.length))
                                message.storageValues = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.storageValues.push(reader.uint64());
                            } else
                                message.storageValues.push(reader.uint64());
                            break;
                        }
                    case 6: {
                            message.transferRange = $root.ca2types.Uint64Range.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            if (!(message.transferValues && message.transferValues.length))
                                message.transferValues = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.transferValues.push(reader.uint64());
                            } else
                                message.transferValues.push(reader.uint64());
                            break;
                        }
                    case 7: {
                            if (!(message.cpuModels && message.cpuModels.length))
                                message.cpuModels = [];
                            message.cpuModels.push(reader.string());
                            break;
                        }
                    case 17: {
                            if (!(message.gpuModels && message.gpuModels.length))
                                message.gpuModels = [];
                            message.gpuModels.push(reader.string());
                            break;
                        }
                    case 14: {
                            message.tabId = reader.string();
                            break;
                        }
                    case 15: {
                            if (!(message.regionIds && message.regionIds.length))
                                message.regionIds = [];
                            message.regionIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PlansListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.PlansListRequest} PlansListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlansListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PlansListRequest message.
             * @function verify
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PlansListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ids != null && message.hasOwnProperty("ids")) {
                    if (!Array.isArray(message.ids))
                        return "ids: array expected";
                    for (var i = 0; i < message.ids.length; ++i)
                        if (!$util.isString(message.ids[i]))
                            return "ids: string[] expected";
                }
                if (message.providerIds != null && message.hasOwnProperty("providerIds")) {
                    if (!Array.isArray(message.providerIds))
                        return "providerIds: array expected";
                    for (var i = 0; i < message.providerIds.length; ++i)
                        if (!$util.isString(message.providerIds[i]))
                            return "providerIds: string[] expected";
                }
                if (message.serverClassIds != null && message.hasOwnProperty("serverClassIds")) {
                    if (!Array.isArray(message.serverClassIds))
                        return "serverClassIds: array expected";
                    for (var i = 0; i < message.serverClassIds.length; ++i)
                        if (!$util.isString(message.serverClassIds[i]))
                            return "serverClassIds: string[] expected";
                }
                if (message.monthlyRateUsdRange != null && message.hasOwnProperty("monthlyRateUsdRange")) {
                    var error = $root.ca2types.FloatRange.verify(message.monthlyRateUsdRange);
                    if (error)
                        return "monthlyRateUsdRange." + error;
                }
                if (message.hourlyRateUsdRange != null && message.hasOwnProperty("hourlyRateUsdRange")) {
                    var error = $root.ca2types.FloatRange.verify(message.hourlyRateUsdRange);
                    if (error)
                        return "hourlyRateUsdRange." + error;
                }
                if (message.ramRange != null && message.hasOwnProperty("ramRange")) {
                    var error = $root.ca2types.Uint64Range.verify(message.ramRange);
                    if (error)
                        return "ramRange." + error;
                }
                if (message.ramValues != null && message.hasOwnProperty("ramValues")) {
                    if (!Array.isArray(message.ramValues))
                        return "ramValues: array expected";
                    for (var i = 0; i < message.ramValues.length; ++i)
                        if (!$util.isInteger(message.ramValues[i]) && !(message.ramValues[i] && $util.isInteger(message.ramValues[i].low) && $util.isInteger(message.ramValues[i].high)))
                            return "ramValues: integer|Long[] expected";
                }
                if (message.cpusRange != null && message.hasOwnProperty("cpusRange")) {
                    var error = $root.ca2types.Uint32Range.verify(message.cpusRange);
                    if (error)
                        return "cpusRange." + error;
                }
                if (message.cpusValues != null && message.hasOwnProperty("cpusValues")) {
                    if (!Array.isArray(message.cpusValues))
                        return "cpusValues: array expected";
                    for (var i = 0; i < message.cpusValues.length; ++i)
                        if (!$util.isInteger(message.cpusValues[i]))
                            return "cpusValues: integer[] expected";
                }
                if (message.storageRange != null && message.hasOwnProperty("storageRange")) {
                    var error = $root.ca2types.Uint64Range.verify(message.storageRange);
                    if (error)
                        return "storageRange." + error;
                }
                if (message.storageValues != null && message.hasOwnProperty("storageValues")) {
                    if (!Array.isArray(message.storageValues))
                        return "storageValues: array expected";
                    for (var i = 0; i < message.storageValues.length; ++i)
                        if (!$util.isInteger(message.storageValues[i]) && !(message.storageValues[i] && $util.isInteger(message.storageValues[i].low) && $util.isInteger(message.storageValues[i].high)))
                            return "storageValues: integer|Long[] expected";
                }
                if (message.transferRange != null && message.hasOwnProperty("transferRange")) {
                    var error = $root.ca2types.Uint64Range.verify(message.transferRange);
                    if (error)
                        return "transferRange." + error;
                }
                if (message.transferValues != null && message.hasOwnProperty("transferValues")) {
                    if (!Array.isArray(message.transferValues))
                        return "transferValues: array expected";
                    for (var i = 0; i < message.transferValues.length; ++i)
                        if (!$util.isInteger(message.transferValues[i]) && !(message.transferValues[i] && $util.isInteger(message.transferValues[i].low) && $util.isInteger(message.transferValues[i].high)))
                            return "transferValues: integer|Long[] expected";
                }
                if (message.cpuModels != null && message.hasOwnProperty("cpuModels")) {
                    if (!Array.isArray(message.cpuModels))
                        return "cpuModels: array expected";
                    for (var i = 0; i < message.cpuModels.length; ++i)
                        if (!$util.isString(message.cpuModels[i]))
                            return "cpuModels: string[] expected";
                }
                if (message.gpuModels != null && message.hasOwnProperty("gpuModels")) {
                    if (!Array.isArray(message.gpuModels))
                        return "gpuModels: array expected";
                    for (var i = 0; i < message.gpuModels.length; ++i)
                        if (!$util.isString(message.gpuModels[i]))
                            return "gpuModels: string[] expected";
                }
                if (message.tabId != null && message.hasOwnProperty("tabId"))
                    if (!$util.isString(message.tabId))
                        return "tabId: string expected";
                if (message.regionIds != null && message.hasOwnProperty("regionIds")) {
                    if (!Array.isArray(message.regionIds))
                        return "regionIds: array expected";
                    for (var i = 0; i < message.regionIds.length; ++i)
                        if (!$util.isString(message.regionIds[i]))
                            return "regionIds: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a PlansListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.PlansListRequest} PlansListRequest
             */
            PlansListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.PlansListRequest)
                    return object;
                var message = new $root.ca2servers.PlansListRequest();
                if (object.ids) {
                    if (!Array.isArray(object.ids))
                        throw TypeError(".ca2servers.PlansListRequest.ids: array expected");
                    message.ids = [];
                    for (var i = 0; i < object.ids.length; ++i)
                        message.ids[i] = String(object.ids[i]);
                }
                if (object.providerIds) {
                    if (!Array.isArray(object.providerIds))
                        throw TypeError(".ca2servers.PlansListRequest.providerIds: array expected");
                    message.providerIds = [];
                    for (var i = 0; i < object.providerIds.length; ++i)
                        message.providerIds[i] = String(object.providerIds[i]);
                }
                if (object.serverClassIds) {
                    if (!Array.isArray(object.serverClassIds))
                        throw TypeError(".ca2servers.PlansListRequest.serverClassIds: array expected");
                    message.serverClassIds = [];
                    for (var i = 0; i < object.serverClassIds.length; ++i)
                        message.serverClassIds[i] = String(object.serverClassIds[i]);
                }
                if (object.monthlyRateUsdRange != null) {
                    if (typeof object.monthlyRateUsdRange !== "object")
                        throw TypeError(".ca2servers.PlansListRequest.monthlyRateUsdRange: object expected");
                    message.monthlyRateUsdRange = $root.ca2types.FloatRange.fromObject(object.monthlyRateUsdRange);
                }
                if (object.hourlyRateUsdRange != null) {
                    if (typeof object.hourlyRateUsdRange !== "object")
                        throw TypeError(".ca2servers.PlansListRequest.hourlyRateUsdRange: object expected");
                    message.hourlyRateUsdRange = $root.ca2types.FloatRange.fromObject(object.hourlyRateUsdRange);
                }
                if (object.ramRange != null) {
                    if (typeof object.ramRange !== "object")
                        throw TypeError(".ca2servers.PlansListRequest.ramRange: object expected");
                    message.ramRange = $root.ca2types.Uint64Range.fromObject(object.ramRange);
                }
                if (object.ramValues) {
                    if (!Array.isArray(object.ramValues))
                        throw TypeError(".ca2servers.PlansListRequest.ramValues: array expected");
                    message.ramValues = [];
                    for (var i = 0; i < object.ramValues.length; ++i)
                        if ($util.Long)
                            (message.ramValues[i] = $util.Long.fromValue(object.ramValues[i])).unsigned = true;
                        else if (typeof object.ramValues[i] === "string")
                            message.ramValues[i] = parseInt(object.ramValues[i], 10);
                        else if (typeof object.ramValues[i] === "number")
                            message.ramValues[i] = object.ramValues[i];
                        else if (typeof object.ramValues[i] === "object")
                            message.ramValues[i] = new $util.LongBits(object.ramValues[i].low >>> 0, object.ramValues[i].high >>> 0).toNumber(true);
                }
                if (object.cpusRange != null) {
                    if (typeof object.cpusRange !== "object")
                        throw TypeError(".ca2servers.PlansListRequest.cpusRange: object expected");
                    message.cpusRange = $root.ca2types.Uint32Range.fromObject(object.cpusRange);
                }
                if (object.cpusValues) {
                    if (!Array.isArray(object.cpusValues))
                        throw TypeError(".ca2servers.PlansListRequest.cpusValues: array expected");
                    message.cpusValues = [];
                    for (var i = 0; i < object.cpusValues.length; ++i)
                        message.cpusValues[i] = object.cpusValues[i] >>> 0;
                }
                if (object.storageRange != null) {
                    if (typeof object.storageRange !== "object")
                        throw TypeError(".ca2servers.PlansListRequest.storageRange: object expected");
                    message.storageRange = $root.ca2types.Uint64Range.fromObject(object.storageRange);
                }
                if (object.storageValues) {
                    if (!Array.isArray(object.storageValues))
                        throw TypeError(".ca2servers.PlansListRequest.storageValues: array expected");
                    message.storageValues = [];
                    for (var i = 0; i < object.storageValues.length; ++i)
                        if ($util.Long)
                            (message.storageValues[i] = $util.Long.fromValue(object.storageValues[i])).unsigned = true;
                        else if (typeof object.storageValues[i] === "string")
                            message.storageValues[i] = parseInt(object.storageValues[i], 10);
                        else if (typeof object.storageValues[i] === "number")
                            message.storageValues[i] = object.storageValues[i];
                        else if (typeof object.storageValues[i] === "object")
                            message.storageValues[i] = new $util.LongBits(object.storageValues[i].low >>> 0, object.storageValues[i].high >>> 0).toNumber(true);
                }
                if (object.transferRange != null) {
                    if (typeof object.transferRange !== "object")
                        throw TypeError(".ca2servers.PlansListRequest.transferRange: object expected");
                    message.transferRange = $root.ca2types.Uint64Range.fromObject(object.transferRange);
                }
                if (object.transferValues) {
                    if (!Array.isArray(object.transferValues))
                        throw TypeError(".ca2servers.PlansListRequest.transferValues: array expected");
                    message.transferValues = [];
                    for (var i = 0; i < object.transferValues.length; ++i)
                        if ($util.Long)
                            (message.transferValues[i] = $util.Long.fromValue(object.transferValues[i])).unsigned = true;
                        else if (typeof object.transferValues[i] === "string")
                            message.transferValues[i] = parseInt(object.transferValues[i], 10);
                        else if (typeof object.transferValues[i] === "number")
                            message.transferValues[i] = object.transferValues[i];
                        else if (typeof object.transferValues[i] === "object")
                            message.transferValues[i] = new $util.LongBits(object.transferValues[i].low >>> 0, object.transferValues[i].high >>> 0).toNumber(true);
                }
                if (object.cpuModels) {
                    if (!Array.isArray(object.cpuModels))
                        throw TypeError(".ca2servers.PlansListRequest.cpuModels: array expected");
                    message.cpuModels = [];
                    for (var i = 0; i < object.cpuModels.length; ++i)
                        message.cpuModels[i] = String(object.cpuModels[i]);
                }
                if (object.gpuModels) {
                    if (!Array.isArray(object.gpuModels))
                        throw TypeError(".ca2servers.PlansListRequest.gpuModels: array expected");
                    message.gpuModels = [];
                    for (var i = 0; i < object.gpuModels.length; ++i)
                        message.gpuModels[i] = String(object.gpuModels[i]);
                }
                if (object.tabId != null)
                    message.tabId = String(object.tabId);
                if (object.regionIds) {
                    if (!Array.isArray(object.regionIds))
                        throw TypeError(".ca2servers.PlansListRequest.regionIds: array expected");
                    message.regionIds = [];
                    for (var i = 0; i < object.regionIds.length; ++i)
                        message.regionIds[i] = String(object.regionIds[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PlansListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {ca2servers.PlansListRequest} message PlansListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PlansListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.providerIds = [];
                    object.cpuModels = [];
                    object.serverClassIds = [];
                    object.ramValues = [];
                    object.cpusValues = [];
                    object.storageValues = [];
                    object.transferValues = [];
                    object.regionIds = [];
                    object.ids = [];
                    object.gpuModels = [];
                }
                if (options.defaults) {
                    object.monthlyRateUsdRange = null;
                    object.ramRange = null;
                    object.cpusRange = null;
                    object.storageRange = null;
                    object.transferRange = null;
                    object.hourlyRateUsdRange = null;
                    object.tabId = "";
                }
                if (message.providerIds && message.providerIds.length) {
                    object.providerIds = [];
                    for (var j = 0; j < message.providerIds.length; ++j)
                        object.providerIds[j] = message.providerIds[j];
                }
                if (message.monthlyRateUsdRange != null && message.hasOwnProperty("monthlyRateUsdRange"))
                    object.monthlyRateUsdRange = $root.ca2types.FloatRange.toObject(message.monthlyRateUsdRange, options);
                if (message.ramRange != null && message.hasOwnProperty("ramRange"))
                    object.ramRange = $root.ca2types.Uint64Range.toObject(message.ramRange, options);
                if (message.cpusRange != null && message.hasOwnProperty("cpusRange"))
                    object.cpusRange = $root.ca2types.Uint32Range.toObject(message.cpusRange, options);
                if (message.storageRange != null && message.hasOwnProperty("storageRange"))
                    object.storageRange = $root.ca2types.Uint64Range.toObject(message.storageRange, options);
                if (message.transferRange != null && message.hasOwnProperty("transferRange"))
                    object.transferRange = $root.ca2types.Uint64Range.toObject(message.transferRange, options);
                if (message.cpuModels && message.cpuModels.length) {
                    object.cpuModels = [];
                    for (var j = 0; j < message.cpuModels.length; ++j)
                        object.cpuModels[j] = message.cpuModels[j];
                }
                if (message.hourlyRateUsdRange != null && message.hasOwnProperty("hourlyRateUsdRange"))
                    object.hourlyRateUsdRange = $root.ca2types.FloatRange.toObject(message.hourlyRateUsdRange, options);
                if (message.serverClassIds && message.serverClassIds.length) {
                    object.serverClassIds = [];
                    for (var j = 0; j < message.serverClassIds.length; ++j)
                        object.serverClassIds[j] = message.serverClassIds[j];
                }
                if (message.ramValues && message.ramValues.length) {
                    object.ramValues = [];
                    for (var j = 0; j < message.ramValues.length; ++j)
                        if (typeof message.ramValues[j] === "number")
                            object.ramValues[j] = options.longs === String ? String(message.ramValues[j]) : message.ramValues[j];
                        else
                            object.ramValues[j] = options.longs === String ? $util.Long.prototype.toString.call(message.ramValues[j]) : options.longs === Number ? new $util.LongBits(message.ramValues[j].low >>> 0, message.ramValues[j].high >>> 0).toNumber(true) : message.ramValues[j];
                }
                if (message.cpusValues && message.cpusValues.length) {
                    object.cpusValues = [];
                    for (var j = 0; j < message.cpusValues.length; ++j)
                        object.cpusValues[j] = message.cpusValues[j];
                }
                if (message.storageValues && message.storageValues.length) {
                    object.storageValues = [];
                    for (var j = 0; j < message.storageValues.length; ++j)
                        if (typeof message.storageValues[j] === "number")
                            object.storageValues[j] = options.longs === String ? String(message.storageValues[j]) : message.storageValues[j];
                        else
                            object.storageValues[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storageValues[j]) : options.longs === Number ? new $util.LongBits(message.storageValues[j].low >>> 0, message.storageValues[j].high >>> 0).toNumber(true) : message.storageValues[j];
                }
                if (message.transferValues && message.transferValues.length) {
                    object.transferValues = [];
                    for (var j = 0; j < message.transferValues.length; ++j)
                        if (typeof message.transferValues[j] === "number")
                            object.transferValues[j] = options.longs === String ? String(message.transferValues[j]) : message.transferValues[j];
                        else
                            object.transferValues[j] = options.longs === String ? $util.Long.prototype.toString.call(message.transferValues[j]) : options.longs === Number ? new $util.LongBits(message.transferValues[j].low >>> 0, message.transferValues[j].high >>> 0).toNumber(true) : message.transferValues[j];
                }
                if (message.tabId != null && message.hasOwnProperty("tabId"))
                    object.tabId = message.tabId;
                if (message.regionIds && message.regionIds.length) {
                    object.regionIds = [];
                    for (var j = 0; j < message.regionIds.length; ++j)
                        object.regionIds[j] = message.regionIds[j];
                }
                if (message.ids && message.ids.length) {
                    object.ids = [];
                    for (var j = 0; j < message.ids.length; ++j)
                        object.ids[j] = message.ids[j];
                }
                if (message.gpuModels && message.gpuModels.length) {
                    object.gpuModels = [];
                    for (var j = 0; j < message.gpuModels.length; ++j)
                        object.gpuModels[j] = message.gpuModels[j];
                }
                return object;
            };
    
            /**
             * Converts this PlansListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.PlansListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PlansListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PlansListRequest
             * @function getTypeUrl
             * @memberof ca2servers.PlansListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PlansListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.PlansListRequest";
            };
    
            return PlansListRequest;
        })();
    
        ca2servers.PlansListResponse = (function() {
    
            /**
             * Properties of a PlansListResponse.
             * @memberof ca2servers
             * @interface IPlansListResponse
             * @property {Array.<ca2types.IServerPlan>|null} [items] PlansListResponse items
             */
    
            /**
             * Constructs a new PlansListResponse.
             * @memberof ca2servers
             * @classdesc Represents a PlansListResponse.
             * @implements IPlansListResponse
             * @constructor
             * @param {ca2servers.IPlansListResponse=} [properties] Properties to set
             */
            function PlansListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PlansListResponse items.
             * @member {Array.<ca2types.IServerPlan>} items
             * @memberof ca2servers.PlansListResponse
             * @instance
             */
            PlansListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new PlansListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {ca2servers.IPlansListResponse=} [properties] Properties to set
             * @returns {ca2servers.PlansListResponse} PlansListResponse instance
             */
            PlansListResponse.create = function create(properties) {
                return new PlansListResponse(properties);
            };
    
            /**
             * Encodes the specified PlansListResponse message. Does not implicitly {@link ca2servers.PlansListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {ca2servers.IPlansListResponse} message PlansListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlansListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.ServerPlan.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PlansListResponse message, length delimited. Does not implicitly {@link ca2servers.PlansListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {ca2servers.IPlansListResponse} message PlansListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlansListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PlansListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.PlansListResponse} PlansListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlansListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.PlansListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.ServerPlan.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PlansListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.PlansListResponse} PlansListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlansListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PlansListResponse message.
             * @function verify
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PlansListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.ServerPlan.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a PlansListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.PlansListResponse} PlansListResponse
             */
            PlansListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.PlansListResponse)
                    return object;
                var message = new $root.ca2servers.PlansListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.PlansListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.PlansListResponse.items: object expected");
                        message.items[i] = $root.ca2types.ServerPlan.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PlansListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {ca2servers.PlansListResponse} message PlansListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PlansListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.ServerPlan.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this PlansListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.PlansListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PlansListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PlansListResponse
             * @function getTypeUrl
             * @memberof ca2servers.PlansListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PlansListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.PlansListResponse";
            };
    
            return PlansListResponse;
        })();
    
        ca2servers.RamOptionsRequest = (function() {
    
            /**
             * Properties of a RamOptionsRequest.
             * @memberof ca2servers
             * @interface IRamOptionsRequest
             */
    
            /**
             * Constructs a new RamOptionsRequest.
             * @memberof ca2servers
             * @classdesc Represents a RamOptionsRequest.
             * @implements IRamOptionsRequest
             * @constructor
             * @param {ca2servers.IRamOptionsRequest=} [properties] Properties to set
             */
            function RamOptionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new RamOptionsRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {ca2servers.IRamOptionsRequest=} [properties] Properties to set
             * @returns {ca2servers.RamOptionsRequest} RamOptionsRequest instance
             */
            RamOptionsRequest.create = function create(properties) {
                return new RamOptionsRequest(properties);
            };
    
            /**
             * Encodes the specified RamOptionsRequest message. Does not implicitly {@link ca2servers.RamOptionsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {ca2servers.IRamOptionsRequest} message RamOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RamOptionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified RamOptionsRequest message, length delimited. Does not implicitly {@link ca2servers.RamOptionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {ca2servers.IRamOptionsRequest} message RamOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RamOptionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RamOptionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.RamOptionsRequest} RamOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RamOptionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.RamOptionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RamOptionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.RamOptionsRequest} RamOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RamOptionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RamOptionsRequest message.
             * @function verify
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RamOptionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a RamOptionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.RamOptionsRequest} RamOptionsRequest
             */
            RamOptionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.RamOptionsRequest)
                    return object;
                return new $root.ca2servers.RamOptionsRequest();
            };
    
            /**
             * Creates a plain object from a RamOptionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {ca2servers.RamOptionsRequest} message RamOptionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RamOptionsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this RamOptionsRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.RamOptionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RamOptionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RamOptionsRequest
             * @function getTypeUrl
             * @memberof ca2servers.RamOptionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RamOptionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.RamOptionsRequest";
            };
    
            return RamOptionsRequest;
        })();
    
        ca2servers.RamOptionsResponse = (function() {
    
            /**
             * Properties of a RamOptionsResponse.
             * @memberof ca2servers
             * @interface IRamOptionsResponse
             * @property {Array.<Long>|null} [items] RamOptionsResponse items
             */
    
            /**
             * Constructs a new RamOptionsResponse.
             * @memberof ca2servers
             * @classdesc Represents a RamOptionsResponse.
             * @implements IRamOptionsResponse
             * @constructor
             * @param {ca2servers.IRamOptionsResponse=} [properties] Properties to set
             */
            function RamOptionsResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RamOptionsResponse items.
             * @member {Array.<Long>} items
             * @memberof ca2servers.RamOptionsResponse
             * @instance
             */
            RamOptionsResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new RamOptionsResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {ca2servers.IRamOptionsResponse=} [properties] Properties to set
             * @returns {ca2servers.RamOptionsResponse} RamOptionsResponse instance
             */
            RamOptionsResponse.create = function create(properties) {
                return new RamOptionsResponse(properties);
            };
    
            /**
             * Encodes the specified RamOptionsResponse message. Does not implicitly {@link ca2servers.RamOptionsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {ca2servers.IRamOptionsResponse} message RamOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RamOptionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.items.length; ++i)
                        writer.uint64(message.items[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified RamOptionsResponse message, length delimited. Does not implicitly {@link ca2servers.RamOptionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {ca2servers.IRamOptionsResponse} message RamOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RamOptionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RamOptionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.RamOptionsResponse} RamOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RamOptionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.RamOptionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.items.push(reader.uint64());
                            } else
                                message.items.push(reader.uint64());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RamOptionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.RamOptionsResponse} RamOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RamOptionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RamOptionsResponse message.
             * @function verify
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RamOptionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i)
                        if (!$util.isInteger(message.items[i]) && !(message.items[i] && $util.isInteger(message.items[i].low) && $util.isInteger(message.items[i].high)))
                            return "items: integer|Long[] expected";
                }
                return null;
            };
    
            /**
             * Creates a RamOptionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.RamOptionsResponse} RamOptionsResponse
             */
            RamOptionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.RamOptionsResponse)
                    return object;
                var message = new $root.ca2servers.RamOptionsResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.RamOptionsResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i)
                        if ($util.Long)
                            (message.items[i] = $util.Long.fromValue(object.items[i])).unsigned = true;
                        else if (typeof object.items[i] === "string")
                            message.items[i] = parseInt(object.items[i], 10);
                        else if (typeof object.items[i] === "number")
                            message.items[i] = object.items[i];
                        else if (typeof object.items[i] === "object")
                            message.items[i] = new $util.LongBits(object.items[i].low >>> 0, object.items[i].high >>> 0).toNumber(true);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RamOptionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {ca2servers.RamOptionsResponse} message RamOptionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RamOptionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        if (typeof message.items[j] === "number")
                            object.items[j] = options.longs === String ? String(message.items[j]) : message.items[j];
                        else
                            object.items[j] = options.longs === String ? $util.Long.prototype.toString.call(message.items[j]) : options.longs === Number ? new $util.LongBits(message.items[j].low >>> 0, message.items[j].high >>> 0).toNumber(true) : message.items[j];
                }
                return object;
            };
    
            /**
             * Converts this RamOptionsResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.RamOptionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RamOptionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RamOptionsResponse
             * @function getTypeUrl
             * @memberof ca2servers.RamOptionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RamOptionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.RamOptionsResponse";
            };
    
            return RamOptionsResponse;
        })();
    
        ca2servers.StorageOptionsRequest = (function() {
    
            /**
             * Properties of a StorageOptionsRequest.
             * @memberof ca2servers
             * @interface IStorageOptionsRequest
             */
    
            /**
             * Constructs a new StorageOptionsRequest.
             * @memberof ca2servers
             * @classdesc Represents a StorageOptionsRequest.
             * @implements IStorageOptionsRequest
             * @constructor
             * @param {ca2servers.IStorageOptionsRequest=} [properties] Properties to set
             */
            function StorageOptionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new StorageOptionsRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {ca2servers.IStorageOptionsRequest=} [properties] Properties to set
             * @returns {ca2servers.StorageOptionsRequest} StorageOptionsRequest instance
             */
            StorageOptionsRequest.create = function create(properties) {
                return new StorageOptionsRequest(properties);
            };
    
            /**
             * Encodes the specified StorageOptionsRequest message. Does not implicitly {@link ca2servers.StorageOptionsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {ca2servers.IStorageOptionsRequest} message StorageOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StorageOptionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified StorageOptionsRequest message, length delimited. Does not implicitly {@link ca2servers.StorageOptionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {ca2servers.IStorageOptionsRequest} message StorageOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StorageOptionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StorageOptionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.StorageOptionsRequest} StorageOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StorageOptionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.StorageOptionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StorageOptionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.StorageOptionsRequest} StorageOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StorageOptionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StorageOptionsRequest message.
             * @function verify
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StorageOptionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a StorageOptionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.StorageOptionsRequest} StorageOptionsRequest
             */
            StorageOptionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.StorageOptionsRequest)
                    return object;
                return new $root.ca2servers.StorageOptionsRequest();
            };
    
            /**
             * Creates a plain object from a StorageOptionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {ca2servers.StorageOptionsRequest} message StorageOptionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StorageOptionsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this StorageOptionsRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.StorageOptionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StorageOptionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StorageOptionsRequest
             * @function getTypeUrl
             * @memberof ca2servers.StorageOptionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StorageOptionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.StorageOptionsRequest";
            };
    
            return StorageOptionsRequest;
        })();
    
        ca2servers.StorageOptionsResponse = (function() {
    
            /**
             * Properties of a StorageOptionsResponse.
             * @memberof ca2servers
             * @interface IStorageOptionsResponse
             * @property {Array.<Long>|null} [items] StorageOptionsResponse items
             */
    
            /**
             * Constructs a new StorageOptionsResponse.
             * @memberof ca2servers
             * @classdesc Represents a StorageOptionsResponse.
             * @implements IStorageOptionsResponse
             * @constructor
             * @param {ca2servers.IStorageOptionsResponse=} [properties] Properties to set
             */
            function StorageOptionsResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StorageOptionsResponse items.
             * @member {Array.<Long>} items
             * @memberof ca2servers.StorageOptionsResponse
             * @instance
             */
            StorageOptionsResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new StorageOptionsResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {ca2servers.IStorageOptionsResponse=} [properties] Properties to set
             * @returns {ca2servers.StorageOptionsResponse} StorageOptionsResponse instance
             */
            StorageOptionsResponse.create = function create(properties) {
                return new StorageOptionsResponse(properties);
            };
    
            /**
             * Encodes the specified StorageOptionsResponse message. Does not implicitly {@link ca2servers.StorageOptionsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {ca2servers.IStorageOptionsResponse} message StorageOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StorageOptionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.items.length; ++i)
                        writer.uint64(message.items[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified StorageOptionsResponse message, length delimited. Does not implicitly {@link ca2servers.StorageOptionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {ca2servers.IStorageOptionsResponse} message StorageOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StorageOptionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StorageOptionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.StorageOptionsResponse} StorageOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StorageOptionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.StorageOptionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.items.push(reader.uint64());
                            } else
                                message.items.push(reader.uint64());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StorageOptionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.StorageOptionsResponse} StorageOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StorageOptionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StorageOptionsResponse message.
             * @function verify
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StorageOptionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i)
                        if (!$util.isInteger(message.items[i]) && !(message.items[i] && $util.isInteger(message.items[i].low) && $util.isInteger(message.items[i].high)))
                            return "items: integer|Long[] expected";
                }
                return null;
            };
    
            /**
             * Creates a StorageOptionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.StorageOptionsResponse} StorageOptionsResponse
             */
            StorageOptionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.StorageOptionsResponse)
                    return object;
                var message = new $root.ca2servers.StorageOptionsResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.StorageOptionsResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i)
                        if ($util.Long)
                            (message.items[i] = $util.Long.fromValue(object.items[i])).unsigned = true;
                        else if (typeof object.items[i] === "string")
                            message.items[i] = parseInt(object.items[i], 10);
                        else if (typeof object.items[i] === "number")
                            message.items[i] = object.items[i];
                        else if (typeof object.items[i] === "object")
                            message.items[i] = new $util.LongBits(object.items[i].low >>> 0, object.items[i].high >>> 0).toNumber(true);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a StorageOptionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {ca2servers.StorageOptionsResponse} message StorageOptionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StorageOptionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        if (typeof message.items[j] === "number")
                            object.items[j] = options.longs === String ? String(message.items[j]) : message.items[j];
                        else
                            object.items[j] = options.longs === String ? $util.Long.prototype.toString.call(message.items[j]) : options.longs === Number ? new $util.LongBits(message.items[j].low >>> 0, message.items[j].high >>> 0).toNumber(true) : message.items[j];
                }
                return object;
            };
    
            /**
             * Converts this StorageOptionsResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.StorageOptionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StorageOptionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StorageOptionsResponse
             * @function getTypeUrl
             * @memberof ca2servers.StorageOptionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StorageOptionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.StorageOptionsResponse";
            };
    
            return StorageOptionsResponse;
        })();
    
        ca2servers.CPUsOptionsRequest = (function() {
    
            /**
             * Properties of a CPUsOptionsRequest.
             * @memberof ca2servers
             * @interface ICPUsOptionsRequest
             */
    
            /**
             * Constructs a new CPUsOptionsRequest.
             * @memberof ca2servers
             * @classdesc Represents a CPUsOptionsRequest.
             * @implements ICPUsOptionsRequest
             * @constructor
             * @param {ca2servers.ICPUsOptionsRequest=} [properties] Properties to set
             */
            function CPUsOptionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new CPUsOptionsRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {ca2servers.ICPUsOptionsRequest=} [properties] Properties to set
             * @returns {ca2servers.CPUsOptionsRequest} CPUsOptionsRequest instance
             */
            CPUsOptionsRequest.create = function create(properties) {
                return new CPUsOptionsRequest(properties);
            };
    
            /**
             * Encodes the specified CPUsOptionsRequest message. Does not implicitly {@link ca2servers.CPUsOptionsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {ca2servers.ICPUsOptionsRequest} message CPUsOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUsOptionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified CPUsOptionsRequest message, length delimited. Does not implicitly {@link ca2servers.CPUsOptionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {ca2servers.ICPUsOptionsRequest} message CPUsOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUsOptionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CPUsOptionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.CPUsOptionsRequest} CPUsOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUsOptionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.CPUsOptionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CPUsOptionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.CPUsOptionsRequest} CPUsOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUsOptionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CPUsOptionsRequest message.
             * @function verify
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CPUsOptionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a CPUsOptionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.CPUsOptionsRequest} CPUsOptionsRequest
             */
            CPUsOptionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.CPUsOptionsRequest)
                    return object;
                return new $root.ca2servers.CPUsOptionsRequest();
            };
    
            /**
             * Creates a plain object from a CPUsOptionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {ca2servers.CPUsOptionsRequest} message CPUsOptionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CPUsOptionsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this CPUsOptionsRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.CPUsOptionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CPUsOptionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CPUsOptionsRequest
             * @function getTypeUrl
             * @memberof ca2servers.CPUsOptionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CPUsOptionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.CPUsOptionsRequest";
            };
    
            return CPUsOptionsRequest;
        })();
    
        ca2servers.CPUsOptionsResponse = (function() {
    
            /**
             * Properties of a CPUsOptionsResponse.
             * @memberof ca2servers
             * @interface ICPUsOptionsResponse
             * @property {Array.<number>|null} [items] CPUsOptionsResponse items
             */
    
            /**
             * Constructs a new CPUsOptionsResponse.
             * @memberof ca2servers
             * @classdesc Represents a CPUsOptionsResponse.
             * @implements ICPUsOptionsResponse
             * @constructor
             * @param {ca2servers.ICPUsOptionsResponse=} [properties] Properties to set
             */
            function CPUsOptionsResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CPUsOptionsResponse items.
             * @member {Array.<number>} items
             * @memberof ca2servers.CPUsOptionsResponse
             * @instance
             */
            CPUsOptionsResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new CPUsOptionsResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {ca2servers.ICPUsOptionsResponse=} [properties] Properties to set
             * @returns {ca2servers.CPUsOptionsResponse} CPUsOptionsResponse instance
             */
            CPUsOptionsResponse.create = function create(properties) {
                return new CPUsOptionsResponse(properties);
            };
    
            /**
             * Encodes the specified CPUsOptionsResponse message. Does not implicitly {@link ca2servers.CPUsOptionsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {ca2servers.ICPUsOptionsResponse} message CPUsOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUsOptionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.items.length; ++i)
                        writer.uint32(message.items[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified CPUsOptionsResponse message, length delimited. Does not implicitly {@link ca2servers.CPUsOptionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {ca2servers.ICPUsOptionsResponse} message CPUsOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPUsOptionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CPUsOptionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.CPUsOptionsResponse} CPUsOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUsOptionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.CPUsOptionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.items.push(reader.uint32());
                            } else
                                message.items.push(reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CPUsOptionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.CPUsOptionsResponse} CPUsOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPUsOptionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CPUsOptionsResponse message.
             * @function verify
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CPUsOptionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i)
                        if (!$util.isInteger(message.items[i]))
                            return "items: integer[] expected";
                }
                return null;
            };
    
            /**
             * Creates a CPUsOptionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.CPUsOptionsResponse} CPUsOptionsResponse
             */
            CPUsOptionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.CPUsOptionsResponse)
                    return object;
                var message = new $root.ca2servers.CPUsOptionsResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.CPUsOptionsResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i)
                        message.items[i] = object.items[i] >>> 0;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CPUsOptionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {ca2servers.CPUsOptionsResponse} message CPUsOptionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CPUsOptionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = message.items[j];
                }
                return object;
            };
    
            /**
             * Converts this CPUsOptionsResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.CPUsOptionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CPUsOptionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CPUsOptionsResponse
             * @function getTypeUrl
             * @memberof ca2servers.CPUsOptionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CPUsOptionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.CPUsOptionsResponse";
            };
    
            return CPUsOptionsResponse;
        })();
    
        ca2servers.TransferOptionsRequest = (function() {
    
            /**
             * Properties of a TransferOptionsRequest.
             * @memberof ca2servers
             * @interface ITransferOptionsRequest
             */
    
            /**
             * Constructs a new TransferOptionsRequest.
             * @memberof ca2servers
             * @classdesc Represents a TransferOptionsRequest.
             * @implements ITransferOptionsRequest
             * @constructor
             * @param {ca2servers.ITransferOptionsRequest=} [properties] Properties to set
             */
            function TransferOptionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new TransferOptionsRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {ca2servers.ITransferOptionsRequest=} [properties] Properties to set
             * @returns {ca2servers.TransferOptionsRequest} TransferOptionsRequest instance
             */
            TransferOptionsRequest.create = function create(properties) {
                return new TransferOptionsRequest(properties);
            };
    
            /**
             * Encodes the specified TransferOptionsRequest message. Does not implicitly {@link ca2servers.TransferOptionsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {ca2servers.ITransferOptionsRequest} message TransferOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferOptionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified TransferOptionsRequest message, length delimited. Does not implicitly {@link ca2servers.TransferOptionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {ca2servers.ITransferOptionsRequest} message TransferOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferOptionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransferOptionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.TransferOptionsRequest} TransferOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferOptionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.TransferOptionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransferOptionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.TransferOptionsRequest} TransferOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferOptionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransferOptionsRequest message.
             * @function verify
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransferOptionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a TransferOptionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.TransferOptionsRequest} TransferOptionsRequest
             */
            TransferOptionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.TransferOptionsRequest)
                    return object;
                return new $root.ca2servers.TransferOptionsRequest();
            };
    
            /**
             * Creates a plain object from a TransferOptionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {ca2servers.TransferOptionsRequest} message TransferOptionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferOptionsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this TransferOptionsRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.TransferOptionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferOptionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TransferOptionsRequest
             * @function getTypeUrl
             * @memberof ca2servers.TransferOptionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TransferOptionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.TransferOptionsRequest";
            };
    
            return TransferOptionsRequest;
        })();
    
        ca2servers.TransferOptionsResponse = (function() {
    
            /**
             * Properties of a TransferOptionsResponse.
             * @memberof ca2servers
             * @interface ITransferOptionsResponse
             * @property {Array.<Long>|null} [items] TransferOptionsResponse items
             */
    
            /**
             * Constructs a new TransferOptionsResponse.
             * @memberof ca2servers
             * @classdesc Represents a TransferOptionsResponse.
             * @implements ITransferOptionsResponse
             * @constructor
             * @param {ca2servers.ITransferOptionsResponse=} [properties] Properties to set
             */
            function TransferOptionsResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransferOptionsResponse items.
             * @member {Array.<Long>} items
             * @memberof ca2servers.TransferOptionsResponse
             * @instance
             */
            TransferOptionsResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new TransferOptionsResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {ca2servers.ITransferOptionsResponse=} [properties] Properties to set
             * @returns {ca2servers.TransferOptionsResponse} TransferOptionsResponse instance
             */
            TransferOptionsResponse.create = function create(properties) {
                return new TransferOptionsResponse(properties);
            };
    
            /**
             * Encodes the specified TransferOptionsResponse message. Does not implicitly {@link ca2servers.TransferOptionsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {ca2servers.ITransferOptionsResponse} message TransferOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferOptionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.items.length; ++i)
                        writer.uint64(message.items[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified TransferOptionsResponse message, length delimited. Does not implicitly {@link ca2servers.TransferOptionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {ca2servers.ITransferOptionsResponse} message TransferOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferOptionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransferOptionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.TransferOptionsResponse} TransferOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferOptionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.TransferOptionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.items.push(reader.uint64());
                            } else
                                message.items.push(reader.uint64());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransferOptionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.TransferOptionsResponse} TransferOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferOptionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransferOptionsResponse message.
             * @function verify
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransferOptionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i)
                        if (!$util.isInteger(message.items[i]) && !(message.items[i] && $util.isInteger(message.items[i].low) && $util.isInteger(message.items[i].high)))
                            return "items: integer|Long[] expected";
                }
                return null;
            };
    
            /**
             * Creates a TransferOptionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.TransferOptionsResponse} TransferOptionsResponse
             */
            TransferOptionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.TransferOptionsResponse)
                    return object;
                var message = new $root.ca2servers.TransferOptionsResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.TransferOptionsResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i)
                        if ($util.Long)
                            (message.items[i] = $util.Long.fromValue(object.items[i])).unsigned = true;
                        else if (typeof object.items[i] === "string")
                            message.items[i] = parseInt(object.items[i], 10);
                        else if (typeof object.items[i] === "number")
                            message.items[i] = object.items[i];
                        else if (typeof object.items[i] === "object")
                            message.items[i] = new $util.LongBits(object.items[i].low >>> 0, object.items[i].high >>> 0).toNumber(true);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TransferOptionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {ca2servers.TransferOptionsResponse} message TransferOptionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferOptionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        if (typeof message.items[j] === "number")
                            object.items[j] = options.longs === String ? String(message.items[j]) : message.items[j];
                        else
                            object.items[j] = options.longs === String ? $util.Long.prototype.toString.call(message.items[j]) : options.longs === Number ? new $util.LongBits(message.items[j].low >>> 0, message.items[j].high >>> 0).toNumber(true) : message.items[j];
                }
                return object;
            };
    
            /**
             * Converts this TransferOptionsResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.TransferOptionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferOptionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TransferOptionsResponse
             * @function getTypeUrl
             * @memberof ca2servers.TransferOptionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TransferOptionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.TransferOptionsResponse";
            };
    
            return TransferOptionsResponse;
        })();
    
        ca2servers.ImagesListRequest = (function() {
    
            /**
             * Properties of an ImagesListRequest.
             * @memberof ca2servers
             * @interface IImagesListRequest
             * @property {Array.<string>|null} [providerIds] ImagesListRequest providerIds
             */
    
            /**
             * Constructs a new ImagesListRequest.
             * @memberof ca2servers
             * @classdesc Represents an ImagesListRequest.
             * @implements IImagesListRequest
             * @constructor
             * @param {ca2servers.IImagesListRequest=} [properties] Properties to set
             */
            function ImagesListRequest(properties) {
                this.providerIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ImagesListRequest providerIds.
             * @member {Array.<string>} providerIds
             * @memberof ca2servers.ImagesListRequest
             * @instance
             */
            ImagesListRequest.prototype.providerIds = $util.emptyArray;
    
            /**
             * Creates a new ImagesListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {ca2servers.IImagesListRequest=} [properties] Properties to set
             * @returns {ca2servers.ImagesListRequest} ImagesListRequest instance
             */
            ImagesListRequest.create = function create(properties) {
                return new ImagesListRequest(properties);
            };
    
            /**
             * Encodes the specified ImagesListRequest message. Does not implicitly {@link ca2servers.ImagesListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {ca2servers.IImagesListRequest} message ImagesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImagesListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.providerIds != null && message.providerIds.length)
                    for (var i = 0; i < message.providerIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerIds[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ImagesListRequest message, length delimited. Does not implicitly {@link ca2servers.ImagesListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {ca2servers.IImagesListRequest} message ImagesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImagesListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ImagesListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.ImagesListRequest} ImagesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImagesListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.ImagesListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.providerIds && message.providerIds.length))
                                message.providerIds = [];
                            message.providerIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ImagesListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.ImagesListRequest} ImagesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImagesListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ImagesListRequest message.
             * @function verify
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ImagesListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.providerIds != null && message.hasOwnProperty("providerIds")) {
                    if (!Array.isArray(message.providerIds))
                        return "providerIds: array expected";
                    for (var i = 0; i < message.providerIds.length; ++i)
                        if (!$util.isString(message.providerIds[i]))
                            return "providerIds: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates an ImagesListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.ImagesListRequest} ImagesListRequest
             */
            ImagesListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.ImagesListRequest)
                    return object;
                var message = new $root.ca2servers.ImagesListRequest();
                if (object.providerIds) {
                    if (!Array.isArray(object.providerIds))
                        throw TypeError(".ca2servers.ImagesListRequest.providerIds: array expected");
                    message.providerIds = [];
                    for (var i = 0; i < object.providerIds.length; ++i)
                        message.providerIds[i] = String(object.providerIds[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an ImagesListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {ca2servers.ImagesListRequest} message ImagesListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ImagesListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.providerIds = [];
                if (message.providerIds && message.providerIds.length) {
                    object.providerIds = [];
                    for (var j = 0; j < message.providerIds.length; ++j)
                        object.providerIds[j] = message.providerIds[j];
                }
                return object;
            };
    
            /**
             * Converts this ImagesListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.ImagesListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ImagesListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ImagesListRequest
             * @function getTypeUrl
             * @memberof ca2servers.ImagesListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ImagesListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.ImagesListRequest";
            };
    
            return ImagesListRequest;
        })();
    
        ca2servers.ImagesListResponse = (function() {
    
            /**
             * Properties of an ImagesListResponse.
             * @memberof ca2servers
             * @interface IImagesListResponse
             * @property {Array.<ca2types.IImage>|null} [items] ImagesListResponse items
             */
    
            /**
             * Constructs a new ImagesListResponse.
             * @memberof ca2servers
             * @classdesc Represents an ImagesListResponse.
             * @implements IImagesListResponse
             * @constructor
             * @param {ca2servers.IImagesListResponse=} [properties] Properties to set
             */
            function ImagesListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ImagesListResponse items.
             * @member {Array.<ca2types.IImage>} items
             * @memberof ca2servers.ImagesListResponse
             * @instance
             */
            ImagesListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new ImagesListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {ca2servers.IImagesListResponse=} [properties] Properties to set
             * @returns {ca2servers.ImagesListResponse} ImagesListResponse instance
             */
            ImagesListResponse.create = function create(properties) {
                return new ImagesListResponse(properties);
            };
    
            /**
             * Encodes the specified ImagesListResponse message. Does not implicitly {@link ca2servers.ImagesListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {ca2servers.IImagesListResponse} message ImagesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImagesListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Image.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ImagesListResponse message, length delimited. Does not implicitly {@link ca2servers.ImagesListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {ca2servers.IImagesListResponse} message ImagesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImagesListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ImagesListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.ImagesListResponse} ImagesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImagesListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.ImagesListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Image.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ImagesListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.ImagesListResponse} ImagesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImagesListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ImagesListResponse message.
             * @function verify
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ImagesListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Image.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an ImagesListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.ImagesListResponse} ImagesListResponse
             */
            ImagesListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.ImagesListResponse)
                    return object;
                var message = new $root.ca2servers.ImagesListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.ImagesListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.ImagesListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Image.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an ImagesListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {ca2servers.ImagesListResponse} message ImagesListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ImagesListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Image.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ImagesListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.ImagesListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ImagesListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ImagesListResponse
             * @function getTypeUrl
             * @memberof ca2servers.ImagesListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ImagesListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.ImagesListResponse";
            };
    
            return ImagesListResponse;
        })();
    
        ca2servers.TabsListRequest = (function() {
    
            /**
             * Properties of a TabsListRequest.
             * @memberof ca2servers
             * @interface ITabsListRequest
             */
    
            /**
             * Constructs a new TabsListRequest.
             * @memberof ca2servers
             * @classdesc Represents a TabsListRequest.
             * @implements ITabsListRequest
             * @constructor
             * @param {ca2servers.ITabsListRequest=} [properties] Properties to set
             */
            function TabsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new TabsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {ca2servers.ITabsListRequest=} [properties] Properties to set
             * @returns {ca2servers.TabsListRequest} TabsListRequest instance
             */
            TabsListRequest.create = function create(properties) {
                return new TabsListRequest(properties);
            };
    
            /**
             * Encodes the specified TabsListRequest message. Does not implicitly {@link ca2servers.TabsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {ca2servers.ITabsListRequest} message TabsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TabsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified TabsListRequest message, length delimited. Does not implicitly {@link ca2servers.TabsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {ca2servers.ITabsListRequest} message TabsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TabsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TabsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.TabsListRequest} TabsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TabsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.TabsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TabsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.TabsListRequest} TabsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TabsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TabsListRequest message.
             * @function verify
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TabsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a TabsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.TabsListRequest} TabsListRequest
             */
            TabsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.TabsListRequest)
                    return object;
                return new $root.ca2servers.TabsListRequest();
            };
    
            /**
             * Creates a plain object from a TabsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {ca2servers.TabsListRequest} message TabsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TabsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this TabsListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.TabsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TabsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TabsListRequest
             * @function getTypeUrl
             * @memberof ca2servers.TabsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TabsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.TabsListRequest";
            };
    
            return TabsListRequest;
        })();
    
        ca2servers.TabsListResponse = (function() {
    
            /**
             * Properties of a TabsListResponse.
             * @memberof ca2servers
             * @interface ITabsListResponse
             * @property {Array.<ca2types.ITab>|null} [items] TabsListResponse items
             */
    
            /**
             * Constructs a new TabsListResponse.
             * @memberof ca2servers
             * @classdesc Represents a TabsListResponse.
             * @implements ITabsListResponse
             * @constructor
             * @param {ca2servers.ITabsListResponse=} [properties] Properties to set
             */
            function TabsListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TabsListResponse items.
             * @member {Array.<ca2types.ITab>} items
             * @memberof ca2servers.TabsListResponse
             * @instance
             */
            TabsListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new TabsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {ca2servers.ITabsListResponse=} [properties] Properties to set
             * @returns {ca2servers.TabsListResponse} TabsListResponse instance
             */
            TabsListResponse.create = function create(properties) {
                return new TabsListResponse(properties);
            };
    
            /**
             * Encodes the specified TabsListResponse message. Does not implicitly {@link ca2servers.TabsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {ca2servers.ITabsListResponse} message TabsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TabsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Tab.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TabsListResponse message, length delimited. Does not implicitly {@link ca2servers.TabsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {ca2servers.ITabsListResponse} message TabsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TabsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TabsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.TabsListResponse} TabsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TabsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.TabsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Tab.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TabsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.TabsListResponse} TabsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TabsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TabsListResponse message.
             * @function verify
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TabsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Tab.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TabsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.TabsListResponse} TabsListResponse
             */
            TabsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.TabsListResponse)
                    return object;
                var message = new $root.ca2servers.TabsListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.TabsListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.TabsListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Tab.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TabsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {ca2servers.TabsListResponse} message TabsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TabsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Tab.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TabsListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.TabsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TabsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TabsListResponse
             * @function getTypeUrl
             * @memberof ca2servers.TabsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TabsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.TabsListResponse";
            };
    
            return TabsListResponse;
        })();
    
        ca2servers.BenefitsRequest = (function() {
    
            /**
             * Properties of a BenefitsRequest.
             * @memberof ca2servers
             * @interface IBenefitsRequest
             */
    
            /**
             * Constructs a new BenefitsRequest.
             * @memberof ca2servers
             * @classdesc Represents a BenefitsRequest.
             * @implements IBenefitsRequest
             * @constructor
             * @param {ca2servers.IBenefitsRequest=} [properties] Properties to set
             */
            function BenefitsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new BenefitsRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {ca2servers.IBenefitsRequest=} [properties] Properties to set
             * @returns {ca2servers.BenefitsRequest} BenefitsRequest instance
             */
            BenefitsRequest.create = function create(properties) {
                return new BenefitsRequest(properties);
            };
    
            /**
             * Encodes the specified BenefitsRequest message. Does not implicitly {@link ca2servers.BenefitsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {ca2servers.IBenefitsRequest} message BenefitsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BenefitsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified BenefitsRequest message, length delimited. Does not implicitly {@link ca2servers.BenefitsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {ca2servers.IBenefitsRequest} message BenefitsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BenefitsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BenefitsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.BenefitsRequest} BenefitsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BenefitsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.BenefitsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BenefitsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.BenefitsRequest} BenefitsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BenefitsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BenefitsRequest message.
             * @function verify
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BenefitsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a BenefitsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.BenefitsRequest} BenefitsRequest
             */
            BenefitsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.BenefitsRequest)
                    return object;
                return new $root.ca2servers.BenefitsRequest();
            };
    
            /**
             * Creates a plain object from a BenefitsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {ca2servers.BenefitsRequest} message BenefitsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BenefitsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this BenefitsRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.BenefitsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BenefitsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BenefitsRequest
             * @function getTypeUrl
             * @memberof ca2servers.BenefitsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BenefitsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.BenefitsRequest";
            };
    
            return BenefitsRequest;
        })();
    
        ca2servers.BenefitsResponse = (function() {
    
            /**
             * Properties of a BenefitsResponse.
             * @memberof ca2servers
             * @interface IBenefitsResponse
             * @property {Array.<ca2types.IBenefit>|null} [items] BenefitsResponse items
             */
    
            /**
             * Constructs a new BenefitsResponse.
             * @memberof ca2servers
             * @classdesc Represents a BenefitsResponse.
             * @implements IBenefitsResponse
             * @constructor
             * @param {ca2servers.IBenefitsResponse=} [properties] Properties to set
             */
            function BenefitsResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BenefitsResponse items.
             * @member {Array.<ca2types.IBenefit>} items
             * @memberof ca2servers.BenefitsResponse
             * @instance
             */
            BenefitsResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new BenefitsResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {ca2servers.IBenefitsResponse=} [properties] Properties to set
             * @returns {ca2servers.BenefitsResponse} BenefitsResponse instance
             */
            BenefitsResponse.create = function create(properties) {
                return new BenefitsResponse(properties);
            };
    
            /**
             * Encodes the specified BenefitsResponse message. Does not implicitly {@link ca2servers.BenefitsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {ca2servers.IBenefitsResponse} message BenefitsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BenefitsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Benefit.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BenefitsResponse message, length delimited. Does not implicitly {@link ca2servers.BenefitsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {ca2servers.IBenefitsResponse} message BenefitsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BenefitsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BenefitsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.BenefitsResponse} BenefitsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BenefitsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.BenefitsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Benefit.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BenefitsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.BenefitsResponse} BenefitsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BenefitsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BenefitsResponse message.
             * @function verify
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BenefitsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Benefit.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a BenefitsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.BenefitsResponse} BenefitsResponse
             */
            BenefitsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.BenefitsResponse)
                    return object;
                var message = new $root.ca2servers.BenefitsResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.BenefitsResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.BenefitsResponse.items: object expected");
                        message.items[i] = $root.ca2types.Benefit.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BenefitsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {ca2servers.BenefitsResponse} message BenefitsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BenefitsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Benefit.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this BenefitsResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.BenefitsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BenefitsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BenefitsResponse
             * @function getTypeUrl
             * @memberof ca2servers.BenefitsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BenefitsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.BenefitsResponse";
            };
    
            return BenefitsResponse;
        })();
    
        ca2servers.PriceRangeRequest = (function() {
    
            /**
             * Properties of a PriceRangeRequest.
             * @memberof ca2servers
             * @interface IPriceRangeRequest
             */
    
            /**
             * Constructs a new PriceRangeRequest.
             * @memberof ca2servers
             * @classdesc Represents a PriceRangeRequest.
             * @implements IPriceRangeRequest
             * @constructor
             * @param {ca2servers.IPriceRangeRequest=} [properties] Properties to set
             */
            function PriceRangeRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new PriceRangeRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {ca2servers.IPriceRangeRequest=} [properties] Properties to set
             * @returns {ca2servers.PriceRangeRequest} PriceRangeRequest instance
             */
            PriceRangeRequest.create = function create(properties) {
                return new PriceRangeRequest(properties);
            };
    
            /**
             * Encodes the specified PriceRangeRequest message. Does not implicitly {@link ca2servers.PriceRangeRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {ca2servers.IPriceRangeRequest} message PriceRangeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PriceRangeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified PriceRangeRequest message, length delimited. Does not implicitly {@link ca2servers.PriceRangeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {ca2servers.IPriceRangeRequest} message PriceRangeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PriceRangeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PriceRangeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.PriceRangeRequest} PriceRangeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PriceRangeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.PriceRangeRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PriceRangeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.PriceRangeRequest} PriceRangeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PriceRangeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PriceRangeRequest message.
             * @function verify
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PriceRangeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a PriceRangeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.PriceRangeRequest} PriceRangeRequest
             */
            PriceRangeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.PriceRangeRequest)
                    return object;
                return new $root.ca2servers.PriceRangeRequest();
            };
    
            /**
             * Creates a plain object from a PriceRangeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {ca2servers.PriceRangeRequest} message PriceRangeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PriceRangeRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this PriceRangeRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.PriceRangeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PriceRangeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PriceRangeRequest
             * @function getTypeUrl
             * @memberof ca2servers.PriceRangeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PriceRangeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.PriceRangeRequest";
            };
    
            return PriceRangeRequest;
        })();
    
        ca2servers.PriceRangeResponse = (function() {
    
            /**
             * Properties of a PriceRangeResponse.
             * @memberof ca2servers
             * @interface IPriceRangeResponse
             * @property {ca2types.IAmount|null} [minMonthlyRate] PriceRangeResponse minMonthlyRate
             * @property {ca2types.IAmount|null} [maxMonthlyRate] PriceRangeResponse maxMonthlyRate
             */
    
            /**
             * Constructs a new PriceRangeResponse.
             * @memberof ca2servers
             * @classdesc Represents a PriceRangeResponse.
             * @implements IPriceRangeResponse
             * @constructor
             * @param {ca2servers.IPriceRangeResponse=} [properties] Properties to set
             */
            function PriceRangeResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PriceRangeResponse minMonthlyRate.
             * @member {ca2types.IAmount|null|undefined} minMonthlyRate
             * @memberof ca2servers.PriceRangeResponse
             * @instance
             */
            PriceRangeResponse.prototype.minMonthlyRate = null;
    
            /**
             * PriceRangeResponse maxMonthlyRate.
             * @member {ca2types.IAmount|null|undefined} maxMonthlyRate
             * @memberof ca2servers.PriceRangeResponse
             * @instance
             */
            PriceRangeResponse.prototype.maxMonthlyRate = null;
    
            /**
             * Creates a new PriceRangeResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {ca2servers.IPriceRangeResponse=} [properties] Properties to set
             * @returns {ca2servers.PriceRangeResponse} PriceRangeResponse instance
             */
            PriceRangeResponse.create = function create(properties) {
                return new PriceRangeResponse(properties);
            };
    
            /**
             * Encodes the specified PriceRangeResponse message. Does not implicitly {@link ca2servers.PriceRangeResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {ca2servers.IPriceRangeResponse} message PriceRangeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PriceRangeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.minMonthlyRate != null && Object.hasOwnProperty.call(message, "minMonthlyRate"))
                    $root.ca2types.Amount.encode(message.minMonthlyRate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.maxMonthlyRate != null && Object.hasOwnProperty.call(message, "maxMonthlyRate"))
                    $root.ca2types.Amount.encode(message.maxMonthlyRate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PriceRangeResponse message, length delimited. Does not implicitly {@link ca2servers.PriceRangeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {ca2servers.IPriceRangeResponse} message PriceRangeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PriceRangeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PriceRangeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.PriceRangeResponse} PriceRangeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PriceRangeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.PriceRangeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            message.minMonthlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.maxMonthlyRate = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PriceRangeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.PriceRangeResponse} PriceRangeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PriceRangeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PriceRangeResponse message.
             * @function verify
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PriceRangeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.minMonthlyRate != null && message.hasOwnProperty("minMonthlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.minMonthlyRate);
                    if (error)
                        return "minMonthlyRate." + error;
                }
                if (message.maxMonthlyRate != null && message.hasOwnProperty("maxMonthlyRate")) {
                    var error = $root.ca2types.Amount.verify(message.maxMonthlyRate);
                    if (error)
                        return "maxMonthlyRate." + error;
                }
                return null;
            };
    
            /**
             * Creates a PriceRangeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.PriceRangeResponse} PriceRangeResponse
             */
            PriceRangeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.PriceRangeResponse)
                    return object;
                var message = new $root.ca2servers.PriceRangeResponse();
                if (object.minMonthlyRate != null) {
                    if (typeof object.minMonthlyRate !== "object")
                        throw TypeError(".ca2servers.PriceRangeResponse.minMonthlyRate: object expected");
                    message.minMonthlyRate = $root.ca2types.Amount.fromObject(object.minMonthlyRate);
                }
                if (object.maxMonthlyRate != null) {
                    if (typeof object.maxMonthlyRate !== "object")
                        throw TypeError(".ca2servers.PriceRangeResponse.maxMonthlyRate: object expected");
                    message.maxMonthlyRate = $root.ca2types.Amount.fromObject(object.maxMonthlyRate);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PriceRangeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {ca2servers.PriceRangeResponse} message PriceRangeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PriceRangeResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.minMonthlyRate = null;
                    object.maxMonthlyRate = null;
                }
                if (message.minMonthlyRate != null && message.hasOwnProperty("minMonthlyRate"))
                    object.minMonthlyRate = $root.ca2types.Amount.toObject(message.minMonthlyRate, options);
                if (message.maxMonthlyRate != null && message.hasOwnProperty("maxMonthlyRate"))
                    object.maxMonthlyRate = $root.ca2types.Amount.toObject(message.maxMonthlyRate, options);
                return object;
            };
    
            /**
             * Converts this PriceRangeResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.PriceRangeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PriceRangeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PriceRangeResponse
             * @function getTypeUrl
             * @memberof ca2servers.PriceRangeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PriceRangeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.PriceRangeResponse";
            };
    
            return PriceRangeResponse;
        })();
    
        ca2servers.AddonsListRequest = (function() {
    
            /**
             * Properties of an AddonsListRequest.
             * @memberof ca2servers
             * @interface IAddonsListRequest
             */
    
            /**
             * Constructs a new AddonsListRequest.
             * @memberof ca2servers
             * @classdesc Represents an AddonsListRequest.
             * @implements IAddonsListRequest
             * @constructor
             * @param {ca2servers.IAddonsListRequest=} [properties] Properties to set
             */
            function AddonsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AddonsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {ca2servers.IAddonsListRequest=} [properties] Properties to set
             * @returns {ca2servers.AddonsListRequest} AddonsListRequest instance
             */
            AddonsListRequest.create = function create(properties) {
                return new AddonsListRequest(properties);
            };
    
            /**
             * Encodes the specified AddonsListRequest message. Does not implicitly {@link ca2servers.AddonsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {ca2servers.IAddonsListRequest} message AddonsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddonsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AddonsListRequest message, length delimited. Does not implicitly {@link ca2servers.AddonsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {ca2servers.IAddonsListRequest} message AddonsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddonsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AddonsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.AddonsListRequest} AddonsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddonsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.AddonsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AddonsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.AddonsListRequest} AddonsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddonsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AddonsListRequest message.
             * @function verify
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddonsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AddonsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.AddonsListRequest} AddonsListRequest
             */
            AddonsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.AddonsListRequest)
                    return object;
                return new $root.ca2servers.AddonsListRequest();
            };
    
            /**
             * Creates a plain object from an AddonsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {ca2servers.AddonsListRequest} message AddonsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddonsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AddonsListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.AddonsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddonsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AddonsListRequest
             * @function getTypeUrl
             * @memberof ca2servers.AddonsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddonsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.AddonsListRequest";
            };
    
            return AddonsListRequest;
        })();
    
        ca2servers.AddonsListResponse = (function() {
    
            /**
             * Properties of an AddonsListResponse.
             * @memberof ca2servers
             * @interface IAddonsListResponse
             * @property {Array.<ca2types.IAddon>|null} [items] AddonsListResponse items
             */
    
            /**
             * Constructs a new AddonsListResponse.
             * @memberof ca2servers
             * @classdesc Represents an AddonsListResponse.
             * @implements IAddonsListResponse
             * @constructor
             * @param {ca2servers.IAddonsListResponse=} [properties] Properties to set
             */
            function AddonsListResponse(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AddonsListResponse items.
             * @member {Array.<ca2types.IAddon>} items
             * @memberof ca2servers.AddonsListResponse
             * @instance
             */
            AddonsListResponse.prototype.items = $util.emptyArray;
    
            /**
             * Creates a new AddonsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {ca2servers.IAddonsListResponse=} [properties] Properties to set
             * @returns {ca2servers.AddonsListResponse} AddonsListResponse instance
             */
            AddonsListResponse.create = function create(properties) {
                return new AddonsListResponse(properties);
            };
    
            /**
             * Encodes the specified AddonsListResponse message. Does not implicitly {@link ca2servers.AddonsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {ca2servers.IAddonsListResponse} message AddonsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddonsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.Addon.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AddonsListResponse message, length delimited. Does not implicitly {@link ca2servers.AddonsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {ca2servers.IAddonsListResponse} message AddonsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddonsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AddonsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.AddonsListResponse} AddonsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddonsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.AddonsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.Addon.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AddonsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.AddonsListResponse} AddonsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddonsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AddonsListResponse message.
             * @function verify
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddonsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.Addon.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an AddonsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.AddonsListResponse} AddonsListResponse
             */
            AddonsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.AddonsListResponse)
                    return object;
                var message = new $root.ca2servers.AddonsListResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.AddonsListResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.AddonsListResponse.items: object expected");
                        message.items[i] = $root.ca2types.Addon.fromObject(object.items[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AddonsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {ca2servers.AddonsListResponse} message AddonsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddonsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.Addon.toObject(message.items[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AddonsListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.AddonsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddonsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AddonsListResponse
             * @function getTypeUrl
             * @memberof ca2servers.AddonsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddonsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.AddonsListResponse";
            };
    
            return AddonsListResponse;
        })();
    
        ca2servers.PaymentOptionsRequest = (function() {
    
            /**
             * Properties of a PaymentOptionsRequest.
             * @memberof ca2servers
             * @interface IPaymentOptionsRequest
             * @property {string|null} [planId] PaymentOptionsRequest planId
             * @property {string|null} [imageId] PaymentOptionsRequest imageId
             * @property {Array.<string>|null} [enabledAddonIds] PaymentOptionsRequest enabledAddonIds
             * @property {number|null} [amountUsd] PaymentOptionsRequest amountUsd
             */
    
            /**
             * Constructs a new PaymentOptionsRequest.
             * @memberof ca2servers
             * @classdesc Represents a PaymentOptionsRequest.
             * @implements IPaymentOptionsRequest
             * @constructor
             * @param {ca2servers.IPaymentOptionsRequest=} [properties] Properties to set
             */
            function PaymentOptionsRequest(properties) {
                this.enabledAddonIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PaymentOptionsRequest planId.
             * @member {string} planId
             * @memberof ca2servers.PaymentOptionsRequest
             * @instance
             */
            PaymentOptionsRequest.prototype.planId = "";
    
            /**
             * PaymentOptionsRequest imageId.
             * @member {string} imageId
             * @memberof ca2servers.PaymentOptionsRequest
             * @instance
             */
            PaymentOptionsRequest.prototype.imageId = "";
    
            /**
             * PaymentOptionsRequest enabledAddonIds.
             * @member {Array.<string>} enabledAddonIds
             * @memberof ca2servers.PaymentOptionsRequest
             * @instance
             */
            PaymentOptionsRequest.prototype.enabledAddonIds = $util.emptyArray;
    
            /**
             * PaymentOptionsRequest amountUsd.
             * @member {number} amountUsd
             * @memberof ca2servers.PaymentOptionsRequest
             * @instance
             */
            PaymentOptionsRequest.prototype.amountUsd = 0;
    
            /**
             * Creates a new PaymentOptionsRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {ca2servers.IPaymentOptionsRequest=} [properties] Properties to set
             * @returns {ca2servers.PaymentOptionsRequest} PaymentOptionsRequest instance
             */
            PaymentOptionsRequest.create = function create(properties) {
                return new PaymentOptionsRequest(properties);
            };
    
            /**
             * Encodes the specified PaymentOptionsRequest message. Does not implicitly {@link ca2servers.PaymentOptionsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {ca2servers.IPaymentOptionsRequest} message PaymentOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentOptionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.planId);
                if (message.enabledAddonIds != null && message.enabledAddonIds.length)
                    for (var i = 0; i < message.enabledAddonIds.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.enabledAddonIds[i]);
                if (message.amountUsd != null && Object.hasOwnProperty.call(message, "amountUsd"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.amountUsd);
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.imageId);
                return writer;
            };
    
            /**
             * Encodes the specified PaymentOptionsRequest message, length delimited. Does not implicitly {@link ca2servers.PaymentOptionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {ca2servers.IPaymentOptionsRequest} message PaymentOptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentOptionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PaymentOptionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.PaymentOptionsRequest} PaymentOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentOptionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.PaymentOptionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.planId = reader.string();
                            break;
                        }
                    case 5: {
                            message.imageId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.enabledAddonIds && message.enabledAddonIds.length))
                                message.enabledAddonIds = [];
                            message.enabledAddonIds.push(reader.string());
                            break;
                        }
                    case 3: {
                            message.amountUsd = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PaymentOptionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.PaymentOptionsRequest} PaymentOptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentOptionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PaymentOptionsRequest message.
             * @function verify
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaymentOptionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.planId != null && message.hasOwnProperty("planId"))
                    if (!$util.isString(message.planId))
                        return "planId: string expected";
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                if (message.enabledAddonIds != null && message.hasOwnProperty("enabledAddonIds")) {
                    if (!Array.isArray(message.enabledAddonIds))
                        return "enabledAddonIds: array expected";
                    for (var i = 0; i < message.enabledAddonIds.length; ++i)
                        if (!$util.isString(message.enabledAddonIds[i]))
                            return "enabledAddonIds: string[] expected";
                }
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    if (!$util.isInteger(message.amountUsd))
                        return "amountUsd: integer expected";
                return null;
            };
    
            /**
             * Creates a PaymentOptionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.PaymentOptionsRequest} PaymentOptionsRequest
             */
            PaymentOptionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.PaymentOptionsRequest)
                    return object;
                var message = new $root.ca2servers.PaymentOptionsRequest();
                if (object.planId != null)
                    message.planId = String(object.planId);
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                if (object.enabledAddonIds) {
                    if (!Array.isArray(object.enabledAddonIds))
                        throw TypeError(".ca2servers.PaymentOptionsRequest.enabledAddonIds: array expected");
                    message.enabledAddonIds = [];
                    for (var i = 0; i < object.enabledAddonIds.length; ++i)
                        message.enabledAddonIds[i] = String(object.enabledAddonIds[i]);
                }
                if (object.amountUsd != null)
                    message.amountUsd = object.amountUsd >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a PaymentOptionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {ca2servers.PaymentOptionsRequest} message PaymentOptionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaymentOptionsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.enabledAddonIds = [];
                if (options.defaults) {
                    object.planId = "";
                    object.amountUsd = 0;
                    object.imageId = "";
                }
                if (message.planId != null && message.hasOwnProperty("planId"))
                    object.planId = message.planId;
                if (message.enabledAddonIds && message.enabledAddonIds.length) {
                    object.enabledAddonIds = [];
                    for (var j = 0; j < message.enabledAddonIds.length; ++j)
                        object.enabledAddonIds[j] = message.enabledAddonIds[j];
                }
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    object.amountUsd = message.amountUsd;
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                return object;
            };
    
            /**
             * Converts this PaymentOptionsRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.PaymentOptionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaymentOptionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PaymentOptionsRequest
             * @function getTypeUrl
             * @memberof ca2servers.PaymentOptionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaymentOptionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.PaymentOptionsRequest";
            };
    
            return PaymentOptionsRequest;
        })();
    
        /**
         * PaymentOptionError enum.
         * @name ca2servers.PaymentOptionError
         * @enum {number}
         * @property {number} PO_ERROR_UNKNOWN=0 PO_ERROR_UNKNOWN value
         * @property {number} PO_ERROR_AMOUNT_TOO_HIGH=1 PO_ERROR_AMOUNT_TOO_HIGH value
         */
        ca2servers.PaymentOptionError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PO_ERROR_UNKNOWN"] = 0;
            values[valuesById[1] = "PO_ERROR_AMOUNT_TOO_HIGH"] = 1;
            return values;
        })();
    
        ca2servers.PaymentOptionsResponse = (function() {
    
            /**
             * Properties of a PaymentOptionsResponse.
             * @memberof ca2servers
             * @interface IPaymentOptionsResponse
             * @property {Array.<ca2servers.PaymentOptionError>|null} [errors] PaymentOptionsResponse errors
             * @property {Array.<ca2types.IPaymentOption>|null} [items] PaymentOptionsResponse items
             * @property {ca2types.IAmount|null} [firstPaymentWithAddons] PaymentOptionsResponse firstPaymentWithAddons
             * @property {number|null} [hoursLeft] PaymentOptionsResponse hoursLeft
             * @property {number|null} [hoursLeftWithGivenAmount] PaymentOptionsResponse hoursLeftWithGivenAmount
             * @property {ca2types.IAmount|null} [maxAmount] PaymentOptionsResponse maxAmount
             * @property {ca2types.IAmount|null} [chargePerHour] PaymentOptionsResponse chargePerHour
             * @property {ca2types.IAmount|null} [chargePerDay] PaymentOptionsResponse chargePerDay
             * @property {ca2types.IAmount|null} [chargePerMonth] PaymentOptionsResponse chargePerMonth
             */
    
            /**
             * Constructs a new PaymentOptionsResponse.
             * @memberof ca2servers
             * @classdesc Represents a PaymentOptionsResponse.
             * @implements IPaymentOptionsResponse
             * @constructor
             * @param {ca2servers.IPaymentOptionsResponse=} [properties] Properties to set
             */
            function PaymentOptionsResponse(properties) {
                this.errors = [];
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PaymentOptionsResponse errors.
             * @member {Array.<ca2servers.PaymentOptionError>} errors
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.errors = $util.emptyArray;
    
            /**
             * PaymentOptionsResponse items.
             * @member {Array.<ca2types.IPaymentOption>} items
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.items = $util.emptyArray;
    
            /**
             * PaymentOptionsResponse firstPaymentWithAddons.
             * @member {ca2types.IAmount|null|undefined} firstPaymentWithAddons
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.firstPaymentWithAddons = null;
    
            /**
             * PaymentOptionsResponse hoursLeft.
             * @member {number} hoursLeft
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.hoursLeft = 0;
    
            /**
             * PaymentOptionsResponse hoursLeftWithGivenAmount.
             * @member {number} hoursLeftWithGivenAmount
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.hoursLeftWithGivenAmount = 0;
    
            /**
             * PaymentOptionsResponse maxAmount.
             * @member {ca2types.IAmount|null|undefined} maxAmount
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.maxAmount = null;
    
            /**
             * PaymentOptionsResponse chargePerHour.
             * @member {ca2types.IAmount|null|undefined} chargePerHour
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.chargePerHour = null;
    
            /**
             * PaymentOptionsResponse chargePerDay.
             * @member {ca2types.IAmount|null|undefined} chargePerDay
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.chargePerDay = null;
    
            /**
             * PaymentOptionsResponse chargePerMonth.
             * @member {ca2types.IAmount|null|undefined} chargePerMonth
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             */
            PaymentOptionsResponse.prototype.chargePerMonth = null;
    
            /**
             * Creates a new PaymentOptionsResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {ca2servers.IPaymentOptionsResponse=} [properties] Properties to set
             * @returns {ca2servers.PaymentOptionsResponse} PaymentOptionsResponse instance
             */
            PaymentOptionsResponse.create = function create(properties) {
                return new PaymentOptionsResponse(properties);
            };
    
            /**
             * Encodes the specified PaymentOptionsResponse message. Does not implicitly {@link ca2servers.PaymentOptionsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {ca2servers.IPaymentOptionsResponse} message PaymentOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentOptionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.ca2types.PaymentOption.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.hoursLeft != null && Object.hasOwnProperty.call(message, "hoursLeft"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.hoursLeft);
                if (message.hoursLeftWithGivenAmount != null && Object.hasOwnProperty.call(message, "hoursLeftWithGivenAmount"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.hoursLeftWithGivenAmount);
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 7, wireType 2 =*/58).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.firstPaymentWithAddons != null && Object.hasOwnProperty.call(message, "firstPaymentWithAddons"))
                    $root.ca2types.Amount.encode(message.firstPaymentWithAddons, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.maxAmount != null && Object.hasOwnProperty.call(message, "maxAmount"))
                    $root.ca2types.Amount.encode(message.maxAmount, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.chargePerHour != null && Object.hasOwnProperty.call(message, "chargePerHour"))
                    $root.ca2types.Amount.encode(message.chargePerHour, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.chargePerDay != null && Object.hasOwnProperty.call(message, "chargePerDay"))
                    $root.ca2types.Amount.encode(message.chargePerDay, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.chargePerMonth != null && Object.hasOwnProperty.call(message, "chargePerMonth"))
                    $root.ca2types.Amount.encode(message.chargePerMonth, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PaymentOptionsResponse message, length delimited. Does not implicitly {@link ca2servers.PaymentOptionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {ca2servers.IPaymentOptionsResponse} message PaymentOptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentOptionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PaymentOptionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.PaymentOptionsResponse} PaymentOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentOptionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.PaymentOptionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 7: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.ca2types.PaymentOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            message.firstPaymentWithAddons = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.hoursLeft = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.hoursLeftWithGivenAmount = reader.uint32();
                            break;
                        }
                    case 11: {
                            message.maxAmount = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.chargePerHour = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.chargePerDay = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.chargePerMonth = $root.ca2types.Amount.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PaymentOptionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.PaymentOptionsResponse} PaymentOptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentOptionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PaymentOptionsResponse message.
             * @function verify
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaymentOptionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                            break;
                        }
                }
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.ca2types.PaymentOption.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.firstPaymentWithAddons != null && message.hasOwnProperty("firstPaymentWithAddons")) {
                    var error = $root.ca2types.Amount.verify(message.firstPaymentWithAddons);
                    if (error)
                        return "firstPaymentWithAddons." + error;
                }
                if (message.hoursLeft != null && message.hasOwnProperty("hoursLeft"))
                    if (!$util.isInteger(message.hoursLeft))
                        return "hoursLeft: integer expected";
                if (message.hoursLeftWithGivenAmount != null && message.hasOwnProperty("hoursLeftWithGivenAmount"))
                    if (!$util.isInteger(message.hoursLeftWithGivenAmount))
                        return "hoursLeftWithGivenAmount: integer expected";
                if (message.maxAmount != null && message.hasOwnProperty("maxAmount")) {
                    var error = $root.ca2types.Amount.verify(message.maxAmount);
                    if (error)
                        return "maxAmount." + error;
                }
                if (message.chargePerHour != null && message.hasOwnProperty("chargePerHour")) {
                    var error = $root.ca2types.Amount.verify(message.chargePerHour);
                    if (error)
                        return "chargePerHour." + error;
                }
                if (message.chargePerDay != null && message.hasOwnProperty("chargePerDay")) {
                    var error = $root.ca2types.Amount.verify(message.chargePerDay);
                    if (error)
                        return "chargePerDay." + error;
                }
                if (message.chargePerMonth != null && message.hasOwnProperty("chargePerMonth")) {
                    var error = $root.ca2types.Amount.verify(message.chargePerMonth);
                    if (error)
                        return "chargePerMonth." + error;
                }
                return null;
            };
    
            /**
             * Creates a PaymentOptionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.PaymentOptionsResponse} PaymentOptionsResponse
             */
            PaymentOptionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.PaymentOptionsResponse)
                    return object;
                var message = new $root.ca2servers.PaymentOptionsResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2servers.PaymentOptionsResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "PO_ERROR_UNKNOWN":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "PO_ERROR_AMOUNT_TOO_HIGH":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        }
                }
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".ca2servers.PaymentOptionsResponse.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".ca2servers.PaymentOptionsResponse.items: object expected");
                        message.items[i] = $root.ca2types.PaymentOption.fromObject(object.items[i]);
                    }
                }
                if (object.firstPaymentWithAddons != null) {
                    if (typeof object.firstPaymentWithAddons !== "object")
                        throw TypeError(".ca2servers.PaymentOptionsResponse.firstPaymentWithAddons: object expected");
                    message.firstPaymentWithAddons = $root.ca2types.Amount.fromObject(object.firstPaymentWithAddons);
                }
                if (object.hoursLeft != null)
                    message.hoursLeft = object.hoursLeft >>> 0;
                if (object.hoursLeftWithGivenAmount != null)
                    message.hoursLeftWithGivenAmount = object.hoursLeftWithGivenAmount >>> 0;
                if (object.maxAmount != null) {
                    if (typeof object.maxAmount !== "object")
                        throw TypeError(".ca2servers.PaymentOptionsResponse.maxAmount: object expected");
                    message.maxAmount = $root.ca2types.Amount.fromObject(object.maxAmount);
                }
                if (object.chargePerHour != null) {
                    if (typeof object.chargePerHour !== "object")
                        throw TypeError(".ca2servers.PaymentOptionsResponse.chargePerHour: object expected");
                    message.chargePerHour = $root.ca2types.Amount.fromObject(object.chargePerHour);
                }
                if (object.chargePerDay != null) {
                    if (typeof object.chargePerDay !== "object")
                        throw TypeError(".ca2servers.PaymentOptionsResponse.chargePerDay: object expected");
                    message.chargePerDay = $root.ca2types.Amount.fromObject(object.chargePerDay);
                }
                if (object.chargePerMonth != null) {
                    if (typeof object.chargePerMonth !== "object")
                        throw TypeError(".ca2servers.PaymentOptionsResponse.chargePerMonth: object expected");
                    message.chargePerMonth = $root.ca2types.Amount.fromObject(object.chargePerMonth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PaymentOptionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {ca2servers.PaymentOptionsResponse} message PaymentOptionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaymentOptionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.items = [];
                    object.errors = [];
                }
                if (options.defaults) {
                    object.hoursLeft = 0;
                    object.hoursLeftWithGivenAmount = 0;
                    object.firstPaymentWithAddons = null;
                    object.maxAmount = null;
                    object.chargePerHour = null;
                    object.chargePerDay = null;
                    object.chargePerMonth = null;
                }
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.ca2types.PaymentOption.toObject(message.items[j], options);
                }
                if (message.hoursLeft != null && message.hasOwnProperty("hoursLeft"))
                    object.hoursLeft = message.hoursLeft;
                if (message.hoursLeftWithGivenAmount != null && message.hasOwnProperty("hoursLeftWithGivenAmount"))
                    object.hoursLeftWithGivenAmount = message.hoursLeftWithGivenAmount;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2servers.PaymentOptionError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2servers.PaymentOptionError[message.errors[j]] : message.errors[j];
                }
                if (message.firstPaymentWithAddons != null && message.hasOwnProperty("firstPaymentWithAddons"))
                    object.firstPaymentWithAddons = $root.ca2types.Amount.toObject(message.firstPaymentWithAddons, options);
                if (message.maxAmount != null && message.hasOwnProperty("maxAmount"))
                    object.maxAmount = $root.ca2types.Amount.toObject(message.maxAmount, options);
                if (message.chargePerHour != null && message.hasOwnProperty("chargePerHour"))
                    object.chargePerHour = $root.ca2types.Amount.toObject(message.chargePerHour, options);
                if (message.chargePerDay != null && message.hasOwnProperty("chargePerDay"))
                    object.chargePerDay = $root.ca2types.Amount.toObject(message.chargePerDay, options);
                if (message.chargePerMonth != null && message.hasOwnProperty("chargePerMonth"))
                    object.chargePerMonth = $root.ca2types.Amount.toObject(message.chargePerMonth, options);
                return object;
            };
    
            /**
             * Converts this PaymentOptionsResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.PaymentOptionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaymentOptionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PaymentOptionsResponse
             * @function getTypeUrl
             * @memberof ca2servers.PaymentOptionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaymentOptionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.PaymentOptionsResponse";
            };
    
            return PaymentOptionsResponse;
        })();
    
        ca2servers.GPUModelsListRequest = (function() {
    
            /**
             * Properties of a GPUModelsListRequest.
             * @memberof ca2servers
             * @interface IGPUModelsListRequest
             */
    
            /**
             * Constructs a new GPUModelsListRequest.
             * @memberof ca2servers
             * @classdesc Represents a GPUModelsListRequest.
             * @implements IGPUModelsListRequest
             * @constructor
             * @param {ca2servers.IGPUModelsListRequest=} [properties] Properties to set
             */
            function GPUModelsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new GPUModelsListRequest instance using the specified properties.
             * @function create
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {ca2servers.IGPUModelsListRequest=} [properties] Properties to set
             * @returns {ca2servers.GPUModelsListRequest} GPUModelsListRequest instance
             */
            GPUModelsListRequest.create = function create(properties) {
                return new GPUModelsListRequest(properties);
            };
    
            /**
             * Encodes the specified GPUModelsListRequest message. Does not implicitly {@link ca2servers.GPUModelsListRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {ca2servers.IGPUModelsListRequest} message GPUModelsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GPUModelsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified GPUModelsListRequest message, length delimited. Does not implicitly {@link ca2servers.GPUModelsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {ca2servers.IGPUModelsListRequest} message GPUModelsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GPUModelsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GPUModelsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.GPUModelsListRequest} GPUModelsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GPUModelsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.GPUModelsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GPUModelsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.GPUModelsListRequest} GPUModelsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GPUModelsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GPUModelsListRequest message.
             * @function verify
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GPUModelsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a GPUModelsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.GPUModelsListRequest} GPUModelsListRequest
             */
            GPUModelsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.GPUModelsListRequest)
                    return object;
                return new $root.ca2servers.GPUModelsListRequest();
            };
    
            /**
             * Creates a plain object from a GPUModelsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {ca2servers.GPUModelsListRequest} message GPUModelsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GPUModelsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this GPUModelsListRequest to JSON.
             * @function toJSON
             * @memberof ca2servers.GPUModelsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GPUModelsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GPUModelsListRequest
             * @function getTypeUrl
             * @memberof ca2servers.GPUModelsListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GPUModelsListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.GPUModelsListRequest";
            };
    
            return GPUModelsListRequest;
        })();
    
        ca2servers.GPUModelsListResponse = (function() {
    
            /**
             * Properties of a GPUModelsListResponse.
             * @memberof ca2servers
             * @interface IGPUModelsListResponse
             * @property {Array.<string>|null} [models] GPUModelsListResponse models
             */
    
            /**
             * Constructs a new GPUModelsListResponse.
             * @memberof ca2servers
             * @classdesc Represents a GPUModelsListResponse.
             * @implements IGPUModelsListResponse
             * @constructor
             * @param {ca2servers.IGPUModelsListResponse=} [properties] Properties to set
             */
            function GPUModelsListResponse(properties) {
                this.models = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GPUModelsListResponse models.
             * @member {Array.<string>} models
             * @memberof ca2servers.GPUModelsListResponse
             * @instance
             */
            GPUModelsListResponse.prototype.models = $util.emptyArray;
    
            /**
             * Creates a new GPUModelsListResponse instance using the specified properties.
             * @function create
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {ca2servers.IGPUModelsListResponse=} [properties] Properties to set
             * @returns {ca2servers.GPUModelsListResponse} GPUModelsListResponse instance
             */
            GPUModelsListResponse.create = function create(properties) {
                return new GPUModelsListResponse(properties);
            };
    
            /**
             * Encodes the specified GPUModelsListResponse message. Does not implicitly {@link ca2servers.GPUModelsListResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {ca2servers.IGPUModelsListResponse} message GPUModelsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GPUModelsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.models != null && message.models.length)
                    for (var i = 0; i < message.models.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.models[i]);
                return writer;
            };
    
            /**
             * Encodes the specified GPUModelsListResponse message, length delimited. Does not implicitly {@link ca2servers.GPUModelsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {ca2servers.IGPUModelsListResponse} message GPUModelsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GPUModelsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GPUModelsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2servers.GPUModelsListResponse} GPUModelsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GPUModelsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2servers.GPUModelsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.models && message.models.length))
                                message.models = [];
                            message.models.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GPUModelsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2servers.GPUModelsListResponse} GPUModelsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GPUModelsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GPUModelsListResponse message.
             * @function verify
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GPUModelsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.models != null && message.hasOwnProperty("models")) {
                    if (!Array.isArray(message.models))
                        return "models: array expected";
                    for (var i = 0; i < message.models.length; ++i)
                        if (!$util.isString(message.models[i]))
                            return "models: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a GPUModelsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2servers.GPUModelsListResponse} GPUModelsListResponse
             */
            GPUModelsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2servers.GPUModelsListResponse)
                    return object;
                var message = new $root.ca2servers.GPUModelsListResponse();
                if (object.models) {
                    if (!Array.isArray(object.models))
                        throw TypeError(".ca2servers.GPUModelsListResponse.models: array expected");
                    message.models = [];
                    for (var i = 0; i < object.models.length; ++i)
                        message.models[i] = String(object.models[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GPUModelsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {ca2servers.GPUModelsListResponse} message GPUModelsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GPUModelsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.models = [];
                if (message.models && message.models.length) {
                    object.models = [];
                    for (var j = 0; j < message.models.length; ++j)
                        object.models[j] = message.models[j];
                }
                return object;
            };
    
            /**
             * Converts this GPUModelsListResponse to JSON.
             * @function toJSON
             * @memberof ca2servers.GPUModelsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GPUModelsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GPUModelsListResponse
             * @function getTypeUrl
             * @memberof ca2servers.GPUModelsListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GPUModelsListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2servers.GPUModelsListResponse";
            };
    
            return GPUModelsListResponse;
        })();
    
        return ca2servers;
    })();
    
    $root.ca2ssh = (function() {
    
        /**
         * Namespace ca2ssh.
         * @exports ca2ssh
         * @namespace
         */
        var ca2ssh = {};
    
        ca2ssh.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2ssh
             * @interface IRequest
             * @property {ca2ssh.IAddSSHKeyRequest|null} [addSshKey] Request addSshKey
             * @property {ca2ssh.IUpdateSSHKeyRequest|null} [updateSshKey] Request updateSshKey
             * @property {ca2ssh.IListSSHKeysRequest|null} [listSshKeys] Request listSshKeys
             * @property {ca2ssh.IDeleteSSHKeyRequest|null} [deleteSshKey] Request deleteSshKey
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2ssh
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2ssh.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request addSshKey.
             * @member {ca2ssh.IAddSSHKeyRequest|null|undefined} addSshKey
             * @memberof ca2ssh.Request
             * @instance
             */
            Request.prototype.addSshKey = null;
    
            /**
             * Request updateSshKey.
             * @member {ca2ssh.IUpdateSSHKeyRequest|null|undefined} updateSshKey
             * @memberof ca2ssh.Request
             * @instance
             */
            Request.prototype.updateSshKey = null;
    
            /**
             * Request listSshKeys.
             * @member {ca2ssh.IListSSHKeysRequest|null|undefined} listSshKeys
             * @memberof ca2ssh.Request
             * @instance
             */
            Request.prototype.listSshKeys = null;
    
            /**
             * Request deleteSshKey.
             * @member {ca2ssh.IDeleteSSHKeyRequest|null|undefined} deleteSshKey
             * @memberof ca2ssh.Request
             * @instance
             */
            Request.prototype.deleteSshKey = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2ssh.Request
             * @static
             * @param {ca2ssh.IRequest=} [properties] Properties to set
             * @returns {ca2ssh.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2ssh.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.Request
             * @static
             * @param {ca2ssh.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addSshKey != null && Object.hasOwnProperty.call(message, "addSshKey"))
                    $root.ca2ssh.AddSSHKeyRequest.encode(message.addSshKey, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.deleteSshKey != null && Object.hasOwnProperty.call(message, "deleteSshKey"))
                    $root.ca2ssh.DeleteSSHKeyRequest.encode(message.deleteSshKey, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.listSshKeys != null && Object.hasOwnProperty.call(message, "listSshKeys"))
                    $root.ca2ssh.ListSSHKeysRequest.encode(message.listSshKeys, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.updateSshKey != null && Object.hasOwnProperty.call(message, "updateSshKey"))
                    $root.ca2ssh.UpdateSSHKeyRequest.encode(message.updateSshKey, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2ssh.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.Request
             * @static
             * @param {ca2ssh.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 20: {
                            message.addSshKey = $root.ca2ssh.AddSSHKeyRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 23: {
                            message.updateSshKey = $root.ca2ssh.UpdateSSHKeyRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 22: {
                            message.listSshKeys = $root.ca2ssh.ListSSHKeysRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            message.deleteSshKey = $root.ca2ssh.DeleteSSHKeyRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2ssh.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.addSshKey != null && message.hasOwnProperty("addSshKey")) {
                    var error = $root.ca2ssh.AddSSHKeyRequest.verify(message.addSshKey);
                    if (error)
                        return "addSshKey." + error;
                }
                if (message.updateSshKey != null && message.hasOwnProperty("updateSshKey")) {
                    var error = $root.ca2ssh.UpdateSSHKeyRequest.verify(message.updateSshKey);
                    if (error)
                        return "updateSshKey." + error;
                }
                if (message.listSshKeys != null && message.hasOwnProperty("listSshKeys")) {
                    var error = $root.ca2ssh.ListSSHKeysRequest.verify(message.listSshKeys);
                    if (error)
                        return "listSshKeys." + error;
                }
                if (message.deleteSshKey != null && message.hasOwnProperty("deleteSshKey")) {
                    var error = $root.ca2ssh.DeleteSSHKeyRequest.verify(message.deleteSshKey);
                    if (error)
                        return "deleteSshKey." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.Request)
                    return object;
                var message = new $root.ca2ssh.Request();
                if (object.addSshKey != null) {
                    if (typeof object.addSshKey !== "object")
                        throw TypeError(".ca2ssh.Request.addSshKey: object expected");
                    message.addSshKey = $root.ca2ssh.AddSSHKeyRequest.fromObject(object.addSshKey);
                }
                if (object.updateSshKey != null) {
                    if (typeof object.updateSshKey !== "object")
                        throw TypeError(".ca2ssh.Request.updateSshKey: object expected");
                    message.updateSshKey = $root.ca2ssh.UpdateSSHKeyRequest.fromObject(object.updateSshKey);
                }
                if (object.listSshKeys != null) {
                    if (typeof object.listSshKeys !== "object")
                        throw TypeError(".ca2ssh.Request.listSshKeys: object expected");
                    message.listSshKeys = $root.ca2ssh.ListSSHKeysRequest.fromObject(object.listSshKeys);
                }
                if (object.deleteSshKey != null) {
                    if (typeof object.deleteSshKey !== "object")
                        throw TypeError(".ca2ssh.Request.deleteSshKey: object expected");
                    message.deleteSshKey = $root.ca2ssh.DeleteSSHKeyRequest.fromObject(object.deleteSshKey);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.Request
             * @static
             * @param {ca2ssh.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.addSshKey = null;
                    object.deleteSshKey = null;
                    object.listSshKeys = null;
                    object.updateSshKey = null;
                }
                if (message.addSshKey != null && message.hasOwnProperty("addSshKey"))
                    object.addSshKey = $root.ca2ssh.AddSSHKeyRequest.toObject(message.addSshKey, options);
                if (message.deleteSshKey != null && message.hasOwnProperty("deleteSshKey"))
                    object.deleteSshKey = $root.ca2ssh.DeleteSSHKeyRequest.toObject(message.deleteSshKey, options);
                if (message.listSshKeys != null && message.hasOwnProperty("listSshKeys"))
                    object.listSshKeys = $root.ca2ssh.ListSSHKeysRequest.toObject(message.listSshKeys, options);
                if (message.updateSshKey != null && message.hasOwnProperty("updateSshKey"))
                    object.updateSshKey = $root.ca2ssh.UpdateSSHKeyRequest.toObject(message.updateSshKey, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2ssh.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2ssh.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.Request";
            };
    
            return Request;
        })();
    
        ca2ssh.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2ssh
             * @interface IResponse
             * @property {ca2ssh.IAddSSHKeyResponse|null} [addSshKey] Response addSshKey
             * @property {ca2ssh.IUpdateSSHKeyResponse|null} [updateSshKey] Response updateSshKey
             * @property {ca2ssh.IListSSHKeysResponse|null} [listSshKeys] Response listSshKeys
             * @property {ca2ssh.IDeleteSSHKeyResponse|null} [deleteSshKey] Response deleteSshKey
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2ssh
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2ssh.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response addSshKey.
             * @member {ca2ssh.IAddSSHKeyResponse|null|undefined} addSshKey
             * @memberof ca2ssh.Response
             * @instance
             */
            Response.prototype.addSshKey = null;
    
            /**
             * Response updateSshKey.
             * @member {ca2ssh.IUpdateSSHKeyResponse|null|undefined} updateSshKey
             * @memberof ca2ssh.Response
             * @instance
             */
            Response.prototype.updateSshKey = null;
    
            /**
             * Response listSshKeys.
             * @member {ca2ssh.IListSSHKeysResponse|null|undefined} listSshKeys
             * @memberof ca2ssh.Response
             * @instance
             */
            Response.prototype.listSshKeys = null;
    
            /**
             * Response deleteSshKey.
             * @member {ca2ssh.IDeleteSSHKeyResponse|null|undefined} deleteSshKey
             * @memberof ca2ssh.Response
             * @instance
             */
            Response.prototype.deleteSshKey = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2ssh.Response
             * @static
             * @param {ca2ssh.IResponse=} [properties] Properties to set
             * @returns {ca2ssh.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2ssh.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.Response
             * @static
             * @param {ca2ssh.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addSshKey != null && Object.hasOwnProperty.call(message, "addSshKey"))
                    $root.ca2ssh.AddSSHKeyResponse.encode(message.addSshKey, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.deleteSshKey != null && Object.hasOwnProperty.call(message, "deleteSshKey"))
                    $root.ca2ssh.DeleteSSHKeyResponse.encode(message.deleteSshKey, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.listSshKeys != null && Object.hasOwnProperty.call(message, "listSshKeys"))
                    $root.ca2ssh.ListSSHKeysResponse.encode(message.listSshKeys, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.updateSshKey != null && Object.hasOwnProperty.call(message, "updateSshKey"))
                    $root.ca2ssh.UpdateSSHKeyResponse.encode(message.updateSshKey, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2ssh.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.Response
             * @static
             * @param {ca2ssh.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 20: {
                            message.addSshKey = $root.ca2ssh.AddSSHKeyResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 23: {
                            message.updateSshKey = $root.ca2ssh.UpdateSSHKeyResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 22: {
                            message.listSshKeys = $root.ca2ssh.ListSSHKeysResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            message.deleteSshKey = $root.ca2ssh.DeleteSSHKeyResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2ssh.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.addSshKey != null && message.hasOwnProperty("addSshKey")) {
                    var error = $root.ca2ssh.AddSSHKeyResponse.verify(message.addSshKey);
                    if (error)
                        return "addSshKey." + error;
                }
                if (message.updateSshKey != null && message.hasOwnProperty("updateSshKey")) {
                    var error = $root.ca2ssh.UpdateSSHKeyResponse.verify(message.updateSshKey);
                    if (error)
                        return "updateSshKey." + error;
                }
                if (message.listSshKeys != null && message.hasOwnProperty("listSshKeys")) {
                    var error = $root.ca2ssh.ListSSHKeysResponse.verify(message.listSshKeys);
                    if (error)
                        return "listSshKeys." + error;
                }
                if (message.deleteSshKey != null && message.hasOwnProperty("deleteSshKey")) {
                    var error = $root.ca2ssh.DeleteSSHKeyResponse.verify(message.deleteSshKey);
                    if (error)
                        return "deleteSshKey." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.Response)
                    return object;
                var message = new $root.ca2ssh.Response();
                if (object.addSshKey != null) {
                    if (typeof object.addSshKey !== "object")
                        throw TypeError(".ca2ssh.Response.addSshKey: object expected");
                    message.addSshKey = $root.ca2ssh.AddSSHKeyResponse.fromObject(object.addSshKey);
                }
                if (object.updateSshKey != null) {
                    if (typeof object.updateSshKey !== "object")
                        throw TypeError(".ca2ssh.Response.updateSshKey: object expected");
                    message.updateSshKey = $root.ca2ssh.UpdateSSHKeyResponse.fromObject(object.updateSshKey);
                }
                if (object.listSshKeys != null) {
                    if (typeof object.listSshKeys !== "object")
                        throw TypeError(".ca2ssh.Response.listSshKeys: object expected");
                    message.listSshKeys = $root.ca2ssh.ListSSHKeysResponse.fromObject(object.listSshKeys);
                }
                if (object.deleteSshKey != null) {
                    if (typeof object.deleteSshKey !== "object")
                        throw TypeError(".ca2ssh.Response.deleteSshKey: object expected");
                    message.deleteSshKey = $root.ca2ssh.DeleteSSHKeyResponse.fromObject(object.deleteSshKey);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.Response
             * @static
             * @param {ca2ssh.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.addSshKey = null;
                    object.deleteSshKey = null;
                    object.listSshKeys = null;
                    object.updateSshKey = null;
                }
                if (message.addSshKey != null && message.hasOwnProperty("addSshKey"))
                    object.addSshKey = $root.ca2ssh.AddSSHKeyResponse.toObject(message.addSshKey, options);
                if (message.deleteSshKey != null && message.hasOwnProperty("deleteSshKey"))
                    object.deleteSshKey = $root.ca2ssh.DeleteSSHKeyResponse.toObject(message.deleteSshKey, options);
                if (message.listSshKeys != null && message.hasOwnProperty("listSshKeys"))
                    object.listSshKeys = $root.ca2ssh.ListSSHKeysResponse.toObject(message.listSshKeys, options);
                if (message.updateSshKey != null && message.hasOwnProperty("updateSshKey"))
                    object.updateSshKey = $root.ca2ssh.UpdateSSHKeyResponse.toObject(message.updateSshKey, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2ssh.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2ssh.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.Response";
            };
    
            return Response;
        })();
    
        ca2ssh.AddSSHKeyRequest = (function() {
    
            /**
             * Properties of an AddSSHKeyRequest.
             * @memberof ca2ssh
             * @interface IAddSSHKeyRequest
             * @property {string|null} [name] AddSSHKeyRequest name
             * @property {string|null} [publicKey] AddSSHKeyRequest publicKey
             */
    
            /**
             * Constructs a new AddSSHKeyRequest.
             * @memberof ca2ssh
             * @classdesc Represents an AddSSHKeyRequest.
             * @implements IAddSSHKeyRequest
             * @constructor
             * @param {ca2ssh.IAddSSHKeyRequest=} [properties] Properties to set
             */
            function AddSSHKeyRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AddSSHKeyRequest name.
             * @member {string} name
             * @memberof ca2ssh.AddSSHKeyRequest
             * @instance
             */
            AddSSHKeyRequest.prototype.name = "";
    
            /**
             * AddSSHKeyRequest publicKey.
             * @member {string} publicKey
             * @memberof ca2ssh.AddSSHKeyRequest
             * @instance
             */
            AddSSHKeyRequest.prototype.publicKey = "";
    
            /**
             * Creates a new AddSSHKeyRequest instance using the specified properties.
             * @function create
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {ca2ssh.IAddSSHKeyRequest=} [properties] Properties to set
             * @returns {ca2ssh.AddSSHKeyRequest} AddSSHKeyRequest instance
             */
            AddSSHKeyRequest.create = function create(properties) {
                return new AddSSHKeyRequest(properties);
            };
    
            /**
             * Encodes the specified AddSSHKeyRequest message. Does not implicitly {@link ca2ssh.AddSSHKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {ca2ssh.IAddSSHKeyRequest} message AddSSHKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddSSHKeyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.publicKey != null && Object.hasOwnProperty.call(message, "publicKey"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.publicKey);
                return writer;
            };
    
            /**
             * Encodes the specified AddSSHKeyRequest message, length delimited. Does not implicitly {@link ca2ssh.AddSSHKeyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {ca2ssh.IAddSSHKeyRequest} message AddSSHKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddSSHKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AddSSHKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.AddSSHKeyRequest} AddSSHKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddSSHKeyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.AddSSHKeyRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.publicKey = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AddSSHKeyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.AddSSHKeyRequest} AddSSHKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddSSHKeyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AddSSHKeyRequest message.
             * @function verify
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddSSHKeyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                    if (!$util.isString(message.publicKey))
                        return "publicKey: string expected";
                return null;
            };
    
            /**
             * Creates an AddSSHKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.AddSSHKeyRequest} AddSSHKeyRequest
             */
            AddSSHKeyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.AddSSHKeyRequest)
                    return object;
                var message = new $root.ca2ssh.AddSSHKeyRequest();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.publicKey != null)
                    message.publicKey = String(object.publicKey);
                return message;
            };
    
            /**
             * Creates a plain object from an AddSSHKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {ca2ssh.AddSSHKeyRequest} message AddSSHKeyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddSSHKeyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.publicKey = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                    object.publicKey = message.publicKey;
                return object;
            };
    
            /**
             * Converts this AddSSHKeyRequest to JSON.
             * @function toJSON
             * @memberof ca2ssh.AddSSHKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddSSHKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AddSSHKeyRequest
             * @function getTypeUrl
             * @memberof ca2ssh.AddSSHKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddSSHKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.AddSSHKeyRequest";
            };
    
            return AddSSHKeyRequest;
        })();
    
        /**
         * AddSSHKeyError enum.
         * @name ca2ssh.AddSSHKeyError
         * @enum {number}
         * @property {number} ASE_UNKNOWN_ERROR=0 ASE_UNKNOWN_ERROR value
         * @property {number} ASE_INVALID_PUBLIC_KEY_ERROR=1 ASE_INVALID_PUBLIC_KEY_ERROR value
         * @property {number} ASE_EMPTY_NAME_ERROR=2 ASE_EMPTY_NAME_ERROR value
         */
        ca2ssh.AddSSHKeyError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ASE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "ASE_INVALID_PUBLIC_KEY_ERROR"] = 1;
            values[valuesById[2] = "ASE_EMPTY_NAME_ERROR"] = 2;
            return values;
        })();
    
        ca2ssh.AddSSHKeyResponse = (function() {
    
            /**
             * Properties of an AddSSHKeyResponse.
             * @memberof ca2ssh
             * @interface IAddSSHKeyResponse
             * @property {Array.<ca2ssh.AddSSHKeyError>|null} [errors] AddSSHKeyResponse errors
             * @property {ca2types.ISSHKey|null} [sshKey] AddSSHKeyResponse sshKey
             */
    
            /**
             * Constructs a new AddSSHKeyResponse.
             * @memberof ca2ssh
             * @classdesc Represents an AddSSHKeyResponse.
             * @implements IAddSSHKeyResponse
             * @constructor
             * @param {ca2ssh.IAddSSHKeyResponse=} [properties] Properties to set
             */
            function AddSSHKeyResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AddSSHKeyResponse errors.
             * @member {Array.<ca2ssh.AddSSHKeyError>} errors
             * @memberof ca2ssh.AddSSHKeyResponse
             * @instance
             */
            AddSSHKeyResponse.prototype.errors = $util.emptyArray;
    
            /**
             * AddSSHKeyResponse sshKey.
             * @member {ca2types.ISSHKey|null|undefined} sshKey
             * @memberof ca2ssh.AddSSHKeyResponse
             * @instance
             */
            AddSSHKeyResponse.prototype.sshKey = null;
    
            /**
             * Creates a new AddSSHKeyResponse instance using the specified properties.
             * @function create
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {ca2ssh.IAddSSHKeyResponse=} [properties] Properties to set
             * @returns {ca2ssh.AddSSHKeyResponse} AddSSHKeyResponse instance
             */
            AddSSHKeyResponse.create = function create(properties) {
                return new AddSSHKeyResponse(properties);
            };
    
            /**
             * Encodes the specified AddSSHKeyResponse message. Does not implicitly {@link ca2ssh.AddSSHKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {ca2ssh.IAddSSHKeyResponse} message AddSSHKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddSSHKeyResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.sshKey != null && Object.hasOwnProperty.call(message, "sshKey"))
                    $root.ca2types.SSHKey.encode(message.sshKey, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AddSSHKeyResponse message, length delimited. Does not implicitly {@link ca2ssh.AddSSHKeyResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {ca2ssh.IAddSSHKeyResponse} message AddSSHKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddSSHKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AddSSHKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.AddSSHKeyResponse} AddSSHKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddSSHKeyResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.AddSSHKeyResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.sshKey = $root.ca2types.SSHKey.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AddSSHKeyResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.AddSSHKeyResponse} AddSSHKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddSSHKeyResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AddSSHKeyResponse message.
             * @function verify
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddSSHKeyResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                }
                if (message.sshKey != null && message.hasOwnProperty("sshKey")) {
                    var error = $root.ca2types.SSHKey.verify(message.sshKey);
                    if (error)
                        return "sshKey." + error;
                }
                return null;
            };
    
            /**
             * Creates an AddSSHKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.AddSSHKeyResponse} AddSSHKeyResponse
             */
            AddSSHKeyResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.AddSSHKeyResponse)
                    return object;
                var message = new $root.ca2ssh.AddSSHKeyResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2ssh.AddSSHKeyResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "ASE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "ASE_INVALID_PUBLIC_KEY_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "ASE_EMPTY_NAME_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        }
                }
                if (object.sshKey != null) {
                    if (typeof object.sshKey !== "object")
                        throw TypeError(".ca2ssh.AddSSHKeyResponse.sshKey: object expected");
                    message.sshKey = $root.ca2types.SSHKey.fromObject(object.sshKey);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AddSSHKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {ca2ssh.AddSSHKeyResponse} message AddSSHKeyResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddSSHKeyResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.sshKey = null;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2ssh.AddSSHKeyError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2ssh.AddSSHKeyError[message.errors[j]] : message.errors[j];
                }
                if (message.sshKey != null && message.hasOwnProperty("sshKey"))
                    object.sshKey = $root.ca2types.SSHKey.toObject(message.sshKey, options);
                return object;
            };
    
            /**
             * Converts this AddSSHKeyResponse to JSON.
             * @function toJSON
             * @memberof ca2ssh.AddSSHKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddSSHKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AddSSHKeyResponse
             * @function getTypeUrl
             * @memberof ca2ssh.AddSSHKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddSSHKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.AddSSHKeyResponse";
            };
    
            return AddSSHKeyResponse;
        })();
    
        ca2ssh.UpdateSSHKeyRequest = (function() {
    
            /**
             * Properties of an UpdateSSHKeyRequest.
             * @memberof ca2ssh
             * @interface IUpdateSSHKeyRequest
             * @property {number|null} [id] UpdateSSHKeyRequest id
             * @property {string|null} [name] UpdateSSHKeyRequest name
             * @property {string|null} [publicKey] UpdateSSHKeyRequest publicKey
             */
    
            /**
             * Constructs a new UpdateSSHKeyRequest.
             * @memberof ca2ssh
             * @classdesc Represents an UpdateSSHKeyRequest.
             * @implements IUpdateSSHKeyRequest
             * @constructor
             * @param {ca2ssh.IUpdateSSHKeyRequest=} [properties] Properties to set
             */
            function UpdateSSHKeyRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateSSHKeyRequest id.
             * @member {number} id
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @instance
             */
            UpdateSSHKeyRequest.prototype.id = 0;
    
            /**
             * UpdateSSHKeyRequest name.
             * @member {string} name
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @instance
             */
            UpdateSSHKeyRequest.prototype.name = "";
    
            /**
             * UpdateSSHKeyRequest publicKey.
             * @member {string} publicKey
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @instance
             */
            UpdateSSHKeyRequest.prototype.publicKey = "";
    
            /**
             * Creates a new UpdateSSHKeyRequest instance using the specified properties.
             * @function create
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {ca2ssh.IUpdateSSHKeyRequest=} [properties] Properties to set
             * @returns {ca2ssh.UpdateSSHKeyRequest} UpdateSSHKeyRequest instance
             */
            UpdateSSHKeyRequest.create = function create(properties) {
                return new UpdateSSHKeyRequest(properties);
            };
    
            /**
             * Encodes the specified UpdateSSHKeyRequest message. Does not implicitly {@link ca2ssh.UpdateSSHKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {ca2ssh.IUpdateSSHKeyRequest} message UpdateSSHKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSSHKeyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.publicKey != null && Object.hasOwnProperty.call(message, "publicKey"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.publicKey);
                return writer;
            };
    
            /**
             * Encodes the specified UpdateSSHKeyRequest message, length delimited. Does not implicitly {@link ca2ssh.UpdateSSHKeyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {ca2ssh.IUpdateSSHKeyRequest} message UpdateSSHKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSSHKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateSSHKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.UpdateSSHKeyRequest} UpdateSSHKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSSHKeyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.UpdateSSHKeyRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.publicKey = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateSSHKeyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.UpdateSSHKeyRequest} UpdateSSHKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSSHKeyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateSSHKeyRequest message.
             * @function verify
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSSHKeyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                    if (!$util.isString(message.publicKey))
                        return "publicKey: string expected";
                return null;
            };
    
            /**
             * Creates an UpdateSSHKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.UpdateSSHKeyRequest} UpdateSSHKeyRequest
             */
            UpdateSSHKeyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.UpdateSSHKeyRequest)
                    return object;
                var message = new $root.ca2ssh.UpdateSSHKeyRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.publicKey != null)
                    message.publicKey = String(object.publicKey);
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateSSHKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {ca2ssh.UpdateSSHKeyRequest} message UpdateSSHKeyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSSHKeyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                    object.publicKey = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                    object.publicKey = message.publicKey;
                return object;
            };
    
            /**
             * Converts this UpdateSSHKeyRequest to JSON.
             * @function toJSON
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSSHKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateSSHKeyRequest
             * @function getTypeUrl
             * @memberof ca2ssh.UpdateSSHKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateSSHKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.UpdateSSHKeyRequest";
            };
    
            return UpdateSSHKeyRequest;
        })();
    
        /**
         * UpdateSSHKeyError enum.
         * @name ca2ssh.UpdateSSHKeyError
         * @enum {number}
         * @property {number} USE_UNKNOWN_ERROR=0 USE_UNKNOWN_ERROR value
         * @property {number} USE_INVALID_ID_ERROR=1 USE_INVALID_ID_ERROR value
         * @property {number} USE_EMPTY_NAME_ERROR=2 USE_EMPTY_NAME_ERROR value
         * @property {number} USE_INVALID_PUBLIC_KEY_ERROR=3 USE_INVALID_PUBLIC_KEY_ERROR value
         */
        ca2ssh.UpdateSSHKeyError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "USE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "USE_INVALID_ID_ERROR"] = 1;
            values[valuesById[2] = "USE_EMPTY_NAME_ERROR"] = 2;
            values[valuesById[3] = "USE_INVALID_PUBLIC_KEY_ERROR"] = 3;
            return values;
        })();
    
        ca2ssh.UpdateSSHKeyResponse = (function() {
    
            /**
             * Properties of an UpdateSSHKeyResponse.
             * @memberof ca2ssh
             * @interface IUpdateSSHKeyResponse
             * @property {Array.<ca2ssh.UpdateSSHKeyError>|null} [errors] UpdateSSHKeyResponse errors
             * @property {ca2types.ISSHKey|null} [sshKey] UpdateSSHKeyResponse sshKey
             */
    
            /**
             * Constructs a new UpdateSSHKeyResponse.
             * @memberof ca2ssh
             * @classdesc Represents an UpdateSSHKeyResponse.
             * @implements IUpdateSSHKeyResponse
             * @constructor
             * @param {ca2ssh.IUpdateSSHKeyResponse=} [properties] Properties to set
             */
            function UpdateSSHKeyResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateSSHKeyResponse errors.
             * @member {Array.<ca2ssh.UpdateSSHKeyError>} errors
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @instance
             */
            UpdateSSHKeyResponse.prototype.errors = $util.emptyArray;
    
            /**
             * UpdateSSHKeyResponse sshKey.
             * @member {ca2types.ISSHKey|null|undefined} sshKey
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @instance
             */
            UpdateSSHKeyResponse.prototype.sshKey = null;
    
            /**
             * Creates a new UpdateSSHKeyResponse instance using the specified properties.
             * @function create
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {ca2ssh.IUpdateSSHKeyResponse=} [properties] Properties to set
             * @returns {ca2ssh.UpdateSSHKeyResponse} UpdateSSHKeyResponse instance
             */
            UpdateSSHKeyResponse.create = function create(properties) {
                return new UpdateSSHKeyResponse(properties);
            };
    
            /**
             * Encodes the specified UpdateSSHKeyResponse message. Does not implicitly {@link ca2ssh.UpdateSSHKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {ca2ssh.IUpdateSSHKeyResponse} message UpdateSSHKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSSHKeyResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.sshKey != null && Object.hasOwnProperty.call(message, "sshKey"))
                    $root.ca2types.SSHKey.encode(message.sshKey, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UpdateSSHKeyResponse message, length delimited. Does not implicitly {@link ca2ssh.UpdateSSHKeyResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {ca2ssh.IUpdateSSHKeyResponse} message UpdateSSHKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSSHKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateSSHKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.UpdateSSHKeyResponse} UpdateSSHKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSSHKeyResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.UpdateSSHKeyResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.sshKey = $root.ca2types.SSHKey.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateSSHKeyResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.UpdateSSHKeyResponse} UpdateSSHKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSSHKeyResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateSSHKeyResponse message.
             * @function verify
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSSHKeyResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                if (message.sshKey != null && message.hasOwnProperty("sshKey")) {
                    var error = $root.ca2types.SSHKey.verify(message.sshKey);
                    if (error)
                        return "sshKey." + error;
                }
                return null;
            };
    
            /**
             * Creates an UpdateSSHKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.UpdateSSHKeyResponse} UpdateSSHKeyResponse
             */
            UpdateSSHKeyResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.UpdateSSHKeyResponse)
                    return object;
                var message = new $root.ca2ssh.UpdateSSHKeyResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2ssh.UpdateSSHKeyResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "USE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "USE_INVALID_ID_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "USE_EMPTY_NAME_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "USE_INVALID_PUBLIC_KEY_ERROR":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                if (object.sshKey != null) {
                    if (typeof object.sshKey !== "object")
                        throw TypeError(".ca2ssh.UpdateSSHKeyResponse.sshKey: object expected");
                    message.sshKey = $root.ca2types.SSHKey.fromObject(object.sshKey);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateSSHKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {ca2ssh.UpdateSSHKeyResponse} message UpdateSSHKeyResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSSHKeyResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.sshKey = null;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2ssh.UpdateSSHKeyError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2ssh.UpdateSSHKeyError[message.errors[j]] : message.errors[j];
                }
                if (message.sshKey != null && message.hasOwnProperty("sshKey"))
                    object.sshKey = $root.ca2types.SSHKey.toObject(message.sshKey, options);
                return object;
            };
    
            /**
             * Converts this UpdateSSHKeyResponse to JSON.
             * @function toJSON
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSSHKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateSSHKeyResponse
             * @function getTypeUrl
             * @memberof ca2ssh.UpdateSSHKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateSSHKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.UpdateSSHKeyResponse";
            };
    
            return UpdateSSHKeyResponse;
        })();
    
        ca2ssh.DeleteSSHKeyRequest = (function() {
    
            /**
             * Properties of a DeleteSSHKeyRequest.
             * @memberof ca2ssh
             * @interface IDeleteSSHKeyRequest
             * @property {number|null} [id] DeleteSSHKeyRequest id
             */
    
            /**
             * Constructs a new DeleteSSHKeyRequest.
             * @memberof ca2ssh
             * @classdesc Represents a DeleteSSHKeyRequest.
             * @implements IDeleteSSHKeyRequest
             * @constructor
             * @param {ca2ssh.IDeleteSSHKeyRequest=} [properties] Properties to set
             */
            function DeleteSSHKeyRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteSSHKeyRequest id.
             * @member {number} id
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @instance
             */
            DeleteSSHKeyRequest.prototype.id = 0;
    
            /**
             * Creates a new DeleteSSHKeyRequest instance using the specified properties.
             * @function create
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {ca2ssh.IDeleteSSHKeyRequest=} [properties] Properties to set
             * @returns {ca2ssh.DeleteSSHKeyRequest} DeleteSSHKeyRequest instance
             */
            DeleteSSHKeyRequest.create = function create(properties) {
                return new DeleteSSHKeyRequest(properties);
            };
    
            /**
             * Encodes the specified DeleteSSHKeyRequest message. Does not implicitly {@link ca2ssh.DeleteSSHKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {ca2ssh.IDeleteSSHKeyRequest} message DeleteSSHKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSSHKeyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified DeleteSSHKeyRequest message, length delimited. Does not implicitly {@link ca2ssh.DeleteSSHKeyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {ca2ssh.IDeleteSSHKeyRequest} message DeleteSSHKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSSHKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteSSHKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.DeleteSSHKeyRequest} DeleteSSHKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSSHKeyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.DeleteSSHKeyRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteSSHKeyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.DeleteSSHKeyRequest} DeleteSSHKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSSHKeyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteSSHKeyRequest message.
             * @function verify
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteSSHKeyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };
    
            /**
             * Creates a DeleteSSHKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.DeleteSSHKeyRequest} DeleteSSHKeyRequest
             */
            DeleteSSHKeyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.DeleteSSHKeyRequest)
                    return object;
                var message = new $root.ca2ssh.DeleteSSHKeyRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteSSHKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {ca2ssh.DeleteSSHKeyRequest} message DeleteSSHKeyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSSHKeyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };
    
            /**
             * Converts this DeleteSSHKeyRequest to JSON.
             * @function toJSON
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSSHKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeleteSSHKeyRequest
             * @function getTypeUrl
             * @memberof ca2ssh.DeleteSSHKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSSHKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.DeleteSSHKeyRequest";
            };
    
            return DeleteSSHKeyRequest;
        })();
    
        /**
         * DeleteSSHKeyError enum.
         * @name ca2ssh.DeleteSSHKeyError
         * @enum {number}
         * @property {number} DSE_UNKNOWN_ERROR=0 DSE_UNKNOWN_ERROR value
         * @property {number} DSE_INVALID_ID_ERROR=1 DSE_INVALID_ID_ERROR value
         * @property {number} DSE_KEY_USED_ERROR=2 DSE_KEY_USED_ERROR value
         */
        ca2ssh.DeleteSSHKeyError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DSE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "DSE_INVALID_ID_ERROR"] = 1;
            values[valuesById[2] = "DSE_KEY_USED_ERROR"] = 2;
            return values;
        })();
    
        ca2ssh.DeleteSSHKeyResponse = (function() {
    
            /**
             * Properties of a DeleteSSHKeyResponse.
             * @memberof ca2ssh
             * @interface IDeleteSSHKeyResponse
             * @property {Array.<ca2ssh.DeleteSSHKeyError>|null} [errors] DeleteSSHKeyResponse errors
             */
    
            /**
             * Constructs a new DeleteSSHKeyResponse.
             * @memberof ca2ssh
             * @classdesc Represents a DeleteSSHKeyResponse.
             * @implements IDeleteSSHKeyResponse
             * @constructor
             * @param {ca2ssh.IDeleteSSHKeyResponse=} [properties] Properties to set
             */
            function DeleteSSHKeyResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteSSHKeyResponse errors.
             * @member {Array.<ca2ssh.DeleteSSHKeyError>} errors
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @instance
             */
            DeleteSSHKeyResponse.prototype.errors = $util.emptyArray;
    
            /**
             * Creates a new DeleteSSHKeyResponse instance using the specified properties.
             * @function create
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {ca2ssh.IDeleteSSHKeyResponse=} [properties] Properties to set
             * @returns {ca2ssh.DeleteSSHKeyResponse} DeleteSSHKeyResponse instance
             */
            DeleteSSHKeyResponse.create = function create(properties) {
                return new DeleteSSHKeyResponse(properties);
            };
    
            /**
             * Encodes the specified DeleteSSHKeyResponse message. Does not implicitly {@link ca2ssh.DeleteSSHKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {ca2ssh.IDeleteSSHKeyResponse} message DeleteSSHKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSSHKeyResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified DeleteSSHKeyResponse message, length delimited. Does not implicitly {@link ca2ssh.DeleteSSHKeyResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {ca2ssh.IDeleteSSHKeyResponse} message DeleteSSHKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSSHKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteSSHKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.DeleteSSHKeyResponse} DeleteSSHKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSSHKeyResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.DeleteSSHKeyResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteSSHKeyResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.DeleteSSHKeyResponse} DeleteSSHKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSSHKeyResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteSSHKeyResponse message.
             * @function verify
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteSSHKeyResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a DeleteSSHKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.DeleteSSHKeyResponse} DeleteSSHKeyResponse
             */
            DeleteSSHKeyResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.DeleteSSHKeyResponse)
                    return object;
                var message = new $root.ca2ssh.DeleteSSHKeyResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2ssh.DeleteSSHKeyResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "DSE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "DSE_INVALID_ID_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "DSE_KEY_USED_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteSSHKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {ca2ssh.DeleteSSHKeyResponse} message DeleteSSHKeyResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSSHKeyResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2ssh.DeleteSSHKeyError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2ssh.DeleteSSHKeyError[message.errors[j]] : message.errors[j];
                }
                return object;
            };
    
            /**
             * Converts this DeleteSSHKeyResponse to JSON.
             * @function toJSON
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSSHKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeleteSSHKeyResponse
             * @function getTypeUrl
             * @memberof ca2ssh.DeleteSSHKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSSHKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.DeleteSSHKeyResponse";
            };
    
            return DeleteSSHKeyResponse;
        })();
    
        ca2ssh.ListSSHKeysRequest = (function() {
    
            /**
             * Properties of a ListSSHKeysRequest.
             * @memberof ca2ssh
             * @interface IListSSHKeysRequest
             */
    
            /**
             * Constructs a new ListSSHKeysRequest.
             * @memberof ca2ssh
             * @classdesc Represents a ListSSHKeysRequest.
             * @implements IListSSHKeysRequest
             * @constructor
             * @param {ca2ssh.IListSSHKeysRequest=} [properties] Properties to set
             */
            function ListSSHKeysRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ListSSHKeysRequest instance using the specified properties.
             * @function create
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {ca2ssh.IListSSHKeysRequest=} [properties] Properties to set
             * @returns {ca2ssh.ListSSHKeysRequest} ListSSHKeysRequest instance
             */
            ListSSHKeysRequest.create = function create(properties) {
                return new ListSSHKeysRequest(properties);
            };
    
            /**
             * Encodes the specified ListSSHKeysRequest message. Does not implicitly {@link ca2ssh.ListSSHKeysRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {ca2ssh.IListSSHKeysRequest} message ListSSHKeysRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSSHKeysRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ListSSHKeysRequest message, length delimited. Does not implicitly {@link ca2ssh.ListSSHKeysRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {ca2ssh.IListSSHKeysRequest} message ListSSHKeysRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSSHKeysRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListSSHKeysRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.ListSSHKeysRequest} ListSSHKeysRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSSHKeysRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.ListSSHKeysRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListSSHKeysRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.ListSSHKeysRequest} ListSSHKeysRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSSHKeysRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListSSHKeysRequest message.
             * @function verify
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListSSHKeysRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ListSSHKeysRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.ListSSHKeysRequest} ListSSHKeysRequest
             */
            ListSSHKeysRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.ListSSHKeysRequest)
                    return object;
                return new $root.ca2ssh.ListSSHKeysRequest();
            };
    
            /**
             * Creates a plain object from a ListSSHKeysRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {ca2ssh.ListSSHKeysRequest} message ListSSHKeysRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSSHKeysRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ListSSHKeysRequest to JSON.
             * @function toJSON
             * @memberof ca2ssh.ListSSHKeysRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSSHKeysRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ListSSHKeysRequest
             * @function getTypeUrl
             * @memberof ca2ssh.ListSSHKeysRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSSHKeysRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.ListSSHKeysRequest";
            };
    
            return ListSSHKeysRequest;
        })();
    
        ca2ssh.ListSSHKeysResponse = (function() {
    
            /**
             * Properties of a ListSSHKeysResponse.
             * @memberof ca2ssh
             * @interface IListSSHKeysResponse
             * @property {Array.<ca2types.ISSHKey>|null} [sshKeys] ListSSHKeysResponse sshKeys
             */
    
            /**
             * Constructs a new ListSSHKeysResponse.
             * @memberof ca2ssh
             * @classdesc Represents a ListSSHKeysResponse.
             * @implements IListSSHKeysResponse
             * @constructor
             * @param {ca2ssh.IListSSHKeysResponse=} [properties] Properties to set
             */
            function ListSSHKeysResponse(properties) {
                this.sshKeys = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ListSSHKeysResponse sshKeys.
             * @member {Array.<ca2types.ISSHKey>} sshKeys
             * @memberof ca2ssh.ListSSHKeysResponse
             * @instance
             */
            ListSSHKeysResponse.prototype.sshKeys = $util.emptyArray;
    
            /**
             * Creates a new ListSSHKeysResponse instance using the specified properties.
             * @function create
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {ca2ssh.IListSSHKeysResponse=} [properties] Properties to set
             * @returns {ca2ssh.ListSSHKeysResponse} ListSSHKeysResponse instance
             */
            ListSSHKeysResponse.create = function create(properties) {
                return new ListSSHKeysResponse(properties);
            };
    
            /**
             * Encodes the specified ListSSHKeysResponse message. Does not implicitly {@link ca2ssh.ListSSHKeysResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {ca2ssh.IListSSHKeysResponse} message ListSSHKeysResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSSHKeysResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sshKeys != null && message.sshKeys.length)
                    for (var i = 0; i < message.sshKeys.length; ++i)
                        $root.ca2types.SSHKey.encode(message.sshKeys[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ListSSHKeysResponse message, length delimited. Does not implicitly {@link ca2ssh.ListSSHKeysResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {ca2ssh.IListSSHKeysResponse} message ListSSHKeysResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSSHKeysResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListSSHKeysResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2ssh.ListSSHKeysResponse} ListSSHKeysResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSSHKeysResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2ssh.ListSSHKeysResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.sshKeys && message.sshKeys.length))
                                message.sshKeys = [];
                            message.sshKeys.push($root.ca2types.SSHKey.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListSSHKeysResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2ssh.ListSSHKeysResponse} ListSSHKeysResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSSHKeysResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListSSHKeysResponse message.
             * @function verify
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListSSHKeysResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.sshKeys != null && message.hasOwnProperty("sshKeys")) {
                    if (!Array.isArray(message.sshKeys))
                        return "sshKeys: array expected";
                    for (var i = 0; i < message.sshKeys.length; ++i) {
                        var error = $root.ca2types.SSHKey.verify(message.sshKeys[i]);
                        if (error)
                            return "sshKeys." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ListSSHKeysResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2ssh.ListSSHKeysResponse} ListSSHKeysResponse
             */
            ListSSHKeysResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2ssh.ListSSHKeysResponse)
                    return object;
                var message = new $root.ca2ssh.ListSSHKeysResponse();
                if (object.sshKeys) {
                    if (!Array.isArray(object.sshKeys))
                        throw TypeError(".ca2ssh.ListSSHKeysResponse.sshKeys: array expected");
                    message.sshKeys = [];
                    for (var i = 0; i < object.sshKeys.length; ++i) {
                        if (typeof object.sshKeys[i] !== "object")
                            throw TypeError(".ca2ssh.ListSSHKeysResponse.sshKeys: object expected");
                        message.sshKeys[i] = $root.ca2types.SSHKey.fromObject(object.sshKeys[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ListSSHKeysResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {ca2ssh.ListSSHKeysResponse} message ListSSHKeysResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSSHKeysResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.sshKeys = [];
                if (message.sshKeys && message.sshKeys.length) {
                    object.sshKeys = [];
                    for (var j = 0; j < message.sshKeys.length; ++j)
                        object.sshKeys[j] = $root.ca2types.SSHKey.toObject(message.sshKeys[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ListSSHKeysResponse to JSON.
             * @function toJSON
             * @memberof ca2ssh.ListSSHKeysResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSSHKeysResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ListSSHKeysResponse
             * @function getTypeUrl
             * @memberof ca2ssh.ListSSHKeysResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSSHKeysResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2ssh.ListSSHKeysResponse";
            };
    
            return ListSSHKeysResponse;
        })();
    
        return ca2ssh;
    })();
    
    $root.ca2users = (function() {
    
        /**
         * Namespace ca2users.
         * @exports ca2users
         * @namespace
         */
        var ca2users = {};
    
        ca2users.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2users
             * @interface IRequest
             * @property {ca2users.IProfileRequest|null} [profile] Request profile
             * @property {ca2users.IUpdateProfileRequest|null} [updateProfile] Request updateProfile
             * @property {ca2users.IUpdateEmailRequest|null} [updateEmail] Request updateEmail
             * @property {ca2users.IUpdatePasswordRequest|null} [updatePassword] Request updatePassword
             * @property {ca2users.IUpdateEmailConfirmationRequest|null} [updateEmailConfirmation] Request updateEmailConfirmation
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2users
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2users.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request profile.
             * @member {ca2users.IProfileRequest|null|undefined} profile
             * @memberof ca2users.Request
             * @instance
             */
            Request.prototype.profile = null;
    
            /**
             * Request updateProfile.
             * @member {ca2users.IUpdateProfileRequest|null|undefined} updateProfile
             * @memberof ca2users.Request
             * @instance
             */
            Request.prototype.updateProfile = null;
    
            /**
             * Request updateEmail.
             * @member {ca2users.IUpdateEmailRequest|null|undefined} updateEmail
             * @memberof ca2users.Request
             * @instance
             */
            Request.prototype.updateEmail = null;
    
            /**
             * Request updatePassword.
             * @member {ca2users.IUpdatePasswordRequest|null|undefined} updatePassword
             * @memberof ca2users.Request
             * @instance
             */
            Request.prototype.updatePassword = null;
    
            /**
             * Request updateEmailConfirmation.
             * @member {ca2users.IUpdateEmailConfirmationRequest|null|undefined} updateEmailConfirmation
             * @memberof ca2users.Request
             * @instance
             */
            Request.prototype.updateEmailConfirmation = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2users.Request
             * @static
             * @param {ca2users.IRequest=} [properties] Properties to set
             * @returns {ca2users.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2users.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2users.Request
             * @static
             * @param {ca2users.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                    $root.ca2users.ProfileRequest.encode(message.profile, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.updateProfile != null && Object.hasOwnProperty.call(message, "updateProfile"))
                    $root.ca2users.UpdateProfileRequest.encode(message.updateProfile, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.updateEmail != null && Object.hasOwnProperty.call(message, "updateEmail"))
                    $root.ca2users.UpdateEmailRequest.encode(message.updateEmail, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.updatePassword != null && Object.hasOwnProperty.call(message, "updatePassword"))
                    $root.ca2users.UpdatePasswordRequest.encode(message.updatePassword, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.updateEmailConfirmation != null && Object.hasOwnProperty.call(message, "updateEmailConfirmation"))
                    $root.ca2users.UpdateEmailConfirmationRequest.encode(message.updateEmailConfirmation, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2users.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.Request
             * @static
             * @param {ca2users.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 14: {
                            message.profile = $root.ca2users.ProfileRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.updateProfile = $root.ca2users.UpdateProfileRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.updateEmail = $root.ca2users.UpdateEmailRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.updatePassword = $root.ca2users.UpdatePasswordRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.updateEmailConfirmation = $root.ca2users.UpdateEmailConfirmationRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2users.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.profile != null && message.hasOwnProperty("profile")) {
                    var error = $root.ca2users.ProfileRequest.verify(message.profile);
                    if (error)
                        return "profile." + error;
                }
                if (message.updateProfile != null && message.hasOwnProperty("updateProfile")) {
                    var error = $root.ca2users.UpdateProfileRequest.verify(message.updateProfile);
                    if (error)
                        return "updateProfile." + error;
                }
                if (message.updateEmail != null && message.hasOwnProperty("updateEmail")) {
                    var error = $root.ca2users.UpdateEmailRequest.verify(message.updateEmail);
                    if (error)
                        return "updateEmail." + error;
                }
                if (message.updatePassword != null && message.hasOwnProperty("updatePassword")) {
                    var error = $root.ca2users.UpdatePasswordRequest.verify(message.updatePassword);
                    if (error)
                        return "updatePassword." + error;
                }
                if (message.updateEmailConfirmation != null && message.hasOwnProperty("updateEmailConfirmation")) {
                    var error = $root.ca2users.UpdateEmailConfirmationRequest.verify(message.updateEmailConfirmation);
                    if (error)
                        return "updateEmailConfirmation." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.Request)
                    return object;
                var message = new $root.ca2users.Request();
                if (object.profile != null) {
                    if (typeof object.profile !== "object")
                        throw TypeError(".ca2users.Request.profile: object expected");
                    message.profile = $root.ca2users.ProfileRequest.fromObject(object.profile);
                }
                if (object.updateProfile != null) {
                    if (typeof object.updateProfile !== "object")
                        throw TypeError(".ca2users.Request.updateProfile: object expected");
                    message.updateProfile = $root.ca2users.UpdateProfileRequest.fromObject(object.updateProfile);
                }
                if (object.updateEmail != null) {
                    if (typeof object.updateEmail !== "object")
                        throw TypeError(".ca2users.Request.updateEmail: object expected");
                    message.updateEmail = $root.ca2users.UpdateEmailRequest.fromObject(object.updateEmail);
                }
                if (object.updatePassword != null) {
                    if (typeof object.updatePassword !== "object")
                        throw TypeError(".ca2users.Request.updatePassword: object expected");
                    message.updatePassword = $root.ca2users.UpdatePasswordRequest.fromObject(object.updatePassword);
                }
                if (object.updateEmailConfirmation != null) {
                    if (typeof object.updateEmailConfirmation !== "object")
                        throw TypeError(".ca2users.Request.updateEmailConfirmation: object expected");
                    message.updateEmailConfirmation = $root.ca2users.UpdateEmailConfirmationRequest.fromObject(object.updateEmailConfirmation);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.Request
             * @static
             * @param {ca2users.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.profile = null;
                    object.updateProfile = null;
                    object.updateEmail = null;
                    object.updatePassword = null;
                    object.updateEmailConfirmation = null;
                }
                if (message.profile != null && message.hasOwnProperty("profile"))
                    object.profile = $root.ca2users.ProfileRequest.toObject(message.profile, options);
                if (message.updateProfile != null && message.hasOwnProperty("updateProfile"))
                    object.updateProfile = $root.ca2users.UpdateProfileRequest.toObject(message.updateProfile, options);
                if (message.updateEmail != null && message.hasOwnProperty("updateEmail"))
                    object.updateEmail = $root.ca2users.UpdateEmailRequest.toObject(message.updateEmail, options);
                if (message.updatePassword != null && message.hasOwnProperty("updatePassword"))
                    object.updatePassword = $root.ca2users.UpdatePasswordRequest.toObject(message.updatePassword, options);
                if (message.updateEmailConfirmation != null && message.hasOwnProperty("updateEmailConfirmation"))
                    object.updateEmailConfirmation = $root.ca2users.UpdateEmailConfirmationRequest.toObject(message.updateEmailConfirmation, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2users.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2users.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.Request";
            };
    
            return Request;
        })();
    
        ca2users.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2users
             * @interface IResponse
             * @property {ca2users.IProfileResponse|null} [profile] Response profile
             * @property {ca2users.IUpdateProfileResponse|null} [updateProfile] Response updateProfile
             * @property {ca2users.IUpdateEmailResponse|null} [updateEmail] Response updateEmail
             * @property {ca2users.IUpdatePasswordResponse|null} [updatePassword] Response updatePassword
             * @property {ca2users.IUpdateEmailConfirmationResponse|null} [updateEmailConfirmation] Response updateEmailConfirmation
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2users
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2users.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response profile.
             * @member {ca2users.IProfileResponse|null|undefined} profile
             * @memberof ca2users.Response
             * @instance
             */
            Response.prototype.profile = null;
    
            /**
             * Response updateProfile.
             * @member {ca2users.IUpdateProfileResponse|null|undefined} updateProfile
             * @memberof ca2users.Response
             * @instance
             */
            Response.prototype.updateProfile = null;
    
            /**
             * Response updateEmail.
             * @member {ca2users.IUpdateEmailResponse|null|undefined} updateEmail
             * @memberof ca2users.Response
             * @instance
             */
            Response.prototype.updateEmail = null;
    
            /**
             * Response updatePassword.
             * @member {ca2users.IUpdatePasswordResponse|null|undefined} updatePassword
             * @memberof ca2users.Response
             * @instance
             */
            Response.prototype.updatePassword = null;
    
            /**
             * Response updateEmailConfirmation.
             * @member {ca2users.IUpdateEmailConfirmationResponse|null|undefined} updateEmailConfirmation
             * @memberof ca2users.Response
             * @instance
             */
            Response.prototype.updateEmailConfirmation = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2users.Response
             * @static
             * @param {ca2users.IResponse=} [properties] Properties to set
             * @returns {ca2users.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2users.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2users.Response
             * @static
             * @param {ca2users.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                    $root.ca2users.ProfileResponse.encode(message.profile, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.updateProfile != null && Object.hasOwnProperty.call(message, "updateProfile"))
                    $root.ca2users.UpdateProfileResponse.encode(message.updateProfile, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.updateEmail != null && Object.hasOwnProperty.call(message, "updateEmail"))
                    $root.ca2users.UpdateEmailResponse.encode(message.updateEmail, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.updatePassword != null && Object.hasOwnProperty.call(message, "updatePassword"))
                    $root.ca2users.UpdatePasswordResponse.encode(message.updatePassword, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.updateEmailConfirmation != null && Object.hasOwnProperty.call(message, "updateEmailConfirmation"))
                    $root.ca2users.UpdateEmailConfirmationResponse.encode(message.updateEmailConfirmation, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2users.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.Response
             * @static
             * @param {ca2users.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 14: {
                            message.profile = $root.ca2users.ProfileResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.updateProfile = $root.ca2users.UpdateProfileResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.updateEmail = $root.ca2users.UpdateEmailResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.updatePassword = $root.ca2users.UpdatePasswordResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.updateEmailConfirmation = $root.ca2users.UpdateEmailConfirmationResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2users.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.profile != null && message.hasOwnProperty("profile")) {
                    var error = $root.ca2users.ProfileResponse.verify(message.profile);
                    if (error)
                        return "profile." + error;
                }
                if (message.updateProfile != null && message.hasOwnProperty("updateProfile")) {
                    var error = $root.ca2users.UpdateProfileResponse.verify(message.updateProfile);
                    if (error)
                        return "updateProfile." + error;
                }
                if (message.updateEmail != null && message.hasOwnProperty("updateEmail")) {
                    var error = $root.ca2users.UpdateEmailResponse.verify(message.updateEmail);
                    if (error)
                        return "updateEmail." + error;
                }
                if (message.updatePassword != null && message.hasOwnProperty("updatePassword")) {
                    var error = $root.ca2users.UpdatePasswordResponse.verify(message.updatePassword);
                    if (error)
                        return "updatePassword." + error;
                }
                if (message.updateEmailConfirmation != null && message.hasOwnProperty("updateEmailConfirmation")) {
                    var error = $root.ca2users.UpdateEmailConfirmationResponse.verify(message.updateEmailConfirmation);
                    if (error)
                        return "updateEmailConfirmation." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.Response)
                    return object;
                var message = new $root.ca2users.Response();
                if (object.profile != null) {
                    if (typeof object.profile !== "object")
                        throw TypeError(".ca2users.Response.profile: object expected");
                    message.profile = $root.ca2users.ProfileResponse.fromObject(object.profile);
                }
                if (object.updateProfile != null) {
                    if (typeof object.updateProfile !== "object")
                        throw TypeError(".ca2users.Response.updateProfile: object expected");
                    message.updateProfile = $root.ca2users.UpdateProfileResponse.fromObject(object.updateProfile);
                }
                if (object.updateEmail != null) {
                    if (typeof object.updateEmail !== "object")
                        throw TypeError(".ca2users.Response.updateEmail: object expected");
                    message.updateEmail = $root.ca2users.UpdateEmailResponse.fromObject(object.updateEmail);
                }
                if (object.updatePassword != null) {
                    if (typeof object.updatePassword !== "object")
                        throw TypeError(".ca2users.Response.updatePassword: object expected");
                    message.updatePassword = $root.ca2users.UpdatePasswordResponse.fromObject(object.updatePassword);
                }
                if (object.updateEmailConfirmation != null) {
                    if (typeof object.updateEmailConfirmation !== "object")
                        throw TypeError(".ca2users.Response.updateEmailConfirmation: object expected");
                    message.updateEmailConfirmation = $root.ca2users.UpdateEmailConfirmationResponse.fromObject(object.updateEmailConfirmation);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.Response
             * @static
             * @param {ca2users.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.profile = null;
                    object.updateProfile = null;
                    object.updateEmail = null;
                    object.updatePassword = null;
                    object.updateEmailConfirmation = null;
                }
                if (message.profile != null && message.hasOwnProperty("profile"))
                    object.profile = $root.ca2users.ProfileResponse.toObject(message.profile, options);
                if (message.updateProfile != null && message.hasOwnProperty("updateProfile"))
                    object.updateProfile = $root.ca2users.UpdateProfileResponse.toObject(message.updateProfile, options);
                if (message.updateEmail != null && message.hasOwnProperty("updateEmail"))
                    object.updateEmail = $root.ca2users.UpdateEmailResponse.toObject(message.updateEmail, options);
                if (message.updatePassword != null && message.hasOwnProperty("updatePassword"))
                    object.updatePassword = $root.ca2users.UpdatePasswordResponse.toObject(message.updatePassword, options);
                if (message.updateEmailConfirmation != null && message.hasOwnProperty("updateEmailConfirmation"))
                    object.updateEmailConfirmation = $root.ca2users.UpdateEmailConfirmationResponse.toObject(message.updateEmailConfirmation, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2users.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2users.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.Response";
            };
    
            return Response;
        })();
    
        ca2users.ProfileRequest = (function() {
    
            /**
             * Properties of a ProfileRequest.
             * @memberof ca2users
             * @interface IProfileRequest
             */
    
            /**
             * Constructs a new ProfileRequest.
             * @memberof ca2users
             * @classdesc Represents a ProfileRequest.
             * @implements IProfileRequest
             * @constructor
             * @param {ca2users.IProfileRequest=} [properties] Properties to set
             */
            function ProfileRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ProfileRequest instance using the specified properties.
             * @function create
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {ca2users.IProfileRequest=} [properties] Properties to set
             * @returns {ca2users.ProfileRequest} ProfileRequest instance
             */
            ProfileRequest.create = function create(properties) {
                return new ProfileRequest(properties);
            };
    
            /**
             * Encodes the specified ProfileRequest message. Does not implicitly {@link ca2users.ProfileRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {ca2users.IProfileRequest} message ProfileRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProfileRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ProfileRequest message, length delimited. Does not implicitly {@link ca2users.ProfileRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {ca2users.IProfileRequest} message ProfileRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ProfileRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.ProfileRequest} ProfileRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProfileRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.ProfileRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ProfileRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.ProfileRequest} ProfileRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProfileRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ProfileRequest message.
             * @function verify
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProfileRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ProfileRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.ProfileRequest} ProfileRequest
             */
            ProfileRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.ProfileRequest)
                    return object;
                return new $root.ca2users.ProfileRequest();
            };
    
            /**
             * Creates a plain object from a ProfileRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {ca2users.ProfileRequest} message ProfileRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProfileRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ProfileRequest to JSON.
             * @function toJSON
             * @memberof ca2users.ProfileRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProfileRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ProfileRequest
             * @function getTypeUrl
             * @memberof ca2users.ProfileRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.ProfileRequest";
            };
    
            return ProfileRequest;
        })();
    
        ca2users.ProfileResponse = (function() {
    
            /**
             * Properties of a ProfileResponse.
             * @memberof ca2users
             * @interface IProfileResponse
             * @property {ca2types.IUser|null} [user] ProfileResponse user
             */
    
            /**
             * Constructs a new ProfileResponse.
             * @memberof ca2users
             * @classdesc Represents a ProfileResponse.
             * @implements IProfileResponse
             * @constructor
             * @param {ca2users.IProfileResponse=} [properties] Properties to set
             */
            function ProfileResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ProfileResponse user.
             * @member {ca2types.IUser|null|undefined} user
             * @memberof ca2users.ProfileResponse
             * @instance
             */
            ProfileResponse.prototype.user = null;
    
            /**
             * Creates a new ProfileResponse instance using the specified properties.
             * @function create
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {ca2users.IProfileResponse=} [properties] Properties to set
             * @returns {ca2users.ProfileResponse} ProfileResponse instance
             */
            ProfileResponse.create = function create(properties) {
                return new ProfileResponse(properties);
            };
    
            /**
             * Encodes the specified ProfileResponse message. Does not implicitly {@link ca2users.ProfileResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {ca2users.IProfileResponse} message ProfileResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProfileResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.ca2types.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ProfileResponse message, length delimited. Does not implicitly {@link ca2users.ProfileResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {ca2users.IProfileResponse} message ProfileResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ProfileResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.ProfileResponse} ProfileResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProfileResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.ProfileResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.user = $root.ca2types.User.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ProfileResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.ProfileResponse} ProfileResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProfileResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ProfileResponse message.
             * @function verify
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProfileResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.ca2types.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };
    
            /**
             * Creates a ProfileResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.ProfileResponse} ProfileResponse
             */
            ProfileResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.ProfileResponse)
                    return object;
                var message = new $root.ca2users.ProfileResponse();
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".ca2users.ProfileResponse.user: object expected");
                    message.user = $root.ca2types.User.fromObject(object.user);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ProfileResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {ca2users.ProfileResponse} message ProfileResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProfileResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.user = null;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.ca2types.User.toObject(message.user, options);
                return object;
            };
    
            /**
             * Converts this ProfileResponse to JSON.
             * @function toJSON
             * @memberof ca2users.ProfileResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProfileResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ProfileResponse
             * @function getTypeUrl
             * @memberof ca2users.ProfileResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.ProfileResponse";
            };
    
            return ProfileResponse;
        })();
    
        ca2users.UpdateProfileRequest = (function() {
    
            /**
             * Properties of an UpdateProfileRequest.
             * @memberof ca2users
             * @interface IUpdateProfileRequest
             * @property {string|null} [newFullName] UpdateProfileRequest newFullName
             * @property {string|null} [newPhone] UpdateProfileRequest newPhone
             * @property {string|null} [newBillingAddress] UpdateProfileRequest newBillingAddress
             * @property {string|null} [newAdditionalBillingAddress] UpdateProfileRequest newAdditionalBillingAddress
             * @property {string|null} [newTaxId] UpdateProfileRequest newTaxId
             */
    
            /**
             * Constructs a new UpdateProfileRequest.
             * @memberof ca2users
             * @classdesc Represents an UpdateProfileRequest.
             * @implements IUpdateProfileRequest
             * @constructor
             * @param {ca2users.IUpdateProfileRequest=} [properties] Properties to set
             */
            function UpdateProfileRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateProfileRequest newFullName.
             * @member {string|null|undefined} newFullName
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            UpdateProfileRequest.prototype.newFullName = null;
    
            /**
             * UpdateProfileRequest newPhone.
             * @member {string|null|undefined} newPhone
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            UpdateProfileRequest.prototype.newPhone = null;
    
            /**
             * UpdateProfileRequest newBillingAddress.
             * @member {string|null|undefined} newBillingAddress
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            UpdateProfileRequest.prototype.newBillingAddress = null;
    
            /**
             * UpdateProfileRequest newAdditionalBillingAddress.
             * @member {string|null|undefined} newAdditionalBillingAddress
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            UpdateProfileRequest.prototype.newAdditionalBillingAddress = null;
    
            /**
             * UpdateProfileRequest newTaxId.
             * @member {string|null|undefined} newTaxId
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            UpdateProfileRequest.prototype.newTaxId = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * UpdateProfileRequest _newFullName.
             * @member {"newFullName"|undefined} _newFullName
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            Object.defineProperty(UpdateProfileRequest.prototype, "_newFullName", {
                get: $util.oneOfGetter($oneOfFields = ["newFullName"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * UpdateProfileRequest _newPhone.
             * @member {"newPhone"|undefined} _newPhone
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            Object.defineProperty(UpdateProfileRequest.prototype, "_newPhone", {
                get: $util.oneOfGetter($oneOfFields = ["newPhone"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * UpdateProfileRequest _newBillingAddress.
             * @member {"newBillingAddress"|undefined} _newBillingAddress
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            Object.defineProperty(UpdateProfileRequest.prototype, "_newBillingAddress", {
                get: $util.oneOfGetter($oneOfFields = ["newBillingAddress"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * UpdateProfileRequest _newAdditionalBillingAddress.
             * @member {"newAdditionalBillingAddress"|undefined} _newAdditionalBillingAddress
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            Object.defineProperty(UpdateProfileRequest.prototype, "_newAdditionalBillingAddress", {
                get: $util.oneOfGetter($oneOfFields = ["newAdditionalBillingAddress"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * UpdateProfileRequest _newTaxId.
             * @member {"newTaxId"|undefined} _newTaxId
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             */
            Object.defineProperty(UpdateProfileRequest.prototype, "_newTaxId", {
                get: $util.oneOfGetter($oneOfFields = ["newTaxId"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new UpdateProfileRequest instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {ca2users.IUpdateProfileRequest=} [properties] Properties to set
             * @returns {ca2users.UpdateProfileRequest} UpdateProfileRequest instance
             */
            UpdateProfileRequest.create = function create(properties) {
                return new UpdateProfileRequest(properties);
            };
    
            /**
             * Encodes the specified UpdateProfileRequest message. Does not implicitly {@link ca2users.UpdateProfileRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {ca2users.IUpdateProfileRequest} message UpdateProfileRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateProfileRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.newFullName != null && Object.hasOwnProperty.call(message, "newFullName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.newFullName);
                if (message.newPhone != null && Object.hasOwnProperty.call(message, "newPhone"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPhone);
                if (message.newBillingAddress != null && Object.hasOwnProperty.call(message, "newBillingAddress"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.newBillingAddress);
                if (message.newAdditionalBillingAddress != null && Object.hasOwnProperty.call(message, "newAdditionalBillingAddress"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.newAdditionalBillingAddress);
                if (message.newTaxId != null && Object.hasOwnProperty.call(message, "newTaxId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.newTaxId);
                return writer;
            };
    
            /**
             * Encodes the specified UpdateProfileRequest message, length delimited. Does not implicitly {@link ca2users.UpdateProfileRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {ca2users.IUpdateProfileRequest} message UpdateProfileRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateProfileRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdateProfileRequest} UpdateProfileRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateProfileRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdateProfileRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.newFullName = reader.string();
                            break;
                        }
                    case 2: {
                            message.newPhone = reader.string();
                            break;
                        }
                    case 3: {
                            message.newBillingAddress = reader.string();
                            break;
                        }
                    case 4: {
                            message.newAdditionalBillingAddress = reader.string();
                            break;
                        }
                    case 5: {
                            message.newTaxId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateProfileRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdateProfileRequest} UpdateProfileRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateProfileRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateProfileRequest message.
             * @function verify
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateProfileRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.newFullName != null && message.hasOwnProperty("newFullName")) {
                    properties._newFullName = 1;
                    if (!$util.isString(message.newFullName))
                        return "newFullName: string expected";
                }
                if (message.newPhone != null && message.hasOwnProperty("newPhone")) {
                    properties._newPhone = 1;
                    if (!$util.isString(message.newPhone))
                        return "newPhone: string expected";
                }
                if (message.newBillingAddress != null && message.hasOwnProperty("newBillingAddress")) {
                    properties._newBillingAddress = 1;
                    if (!$util.isString(message.newBillingAddress))
                        return "newBillingAddress: string expected";
                }
                if (message.newAdditionalBillingAddress != null && message.hasOwnProperty("newAdditionalBillingAddress")) {
                    properties._newAdditionalBillingAddress = 1;
                    if (!$util.isString(message.newAdditionalBillingAddress))
                        return "newAdditionalBillingAddress: string expected";
                }
                if (message.newTaxId != null && message.hasOwnProperty("newTaxId")) {
                    properties._newTaxId = 1;
                    if (!$util.isString(message.newTaxId))
                        return "newTaxId: string expected";
                }
                return null;
            };
    
            /**
             * Creates an UpdateProfileRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdateProfileRequest} UpdateProfileRequest
             */
            UpdateProfileRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdateProfileRequest)
                    return object;
                var message = new $root.ca2users.UpdateProfileRequest();
                if (object.newFullName != null)
                    message.newFullName = String(object.newFullName);
                if (object.newPhone != null)
                    message.newPhone = String(object.newPhone);
                if (object.newBillingAddress != null)
                    message.newBillingAddress = String(object.newBillingAddress);
                if (object.newAdditionalBillingAddress != null)
                    message.newAdditionalBillingAddress = String(object.newAdditionalBillingAddress);
                if (object.newTaxId != null)
                    message.newTaxId = String(object.newTaxId);
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateProfileRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {ca2users.UpdateProfileRequest} message UpdateProfileRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateProfileRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.newFullName != null && message.hasOwnProperty("newFullName")) {
                    object.newFullName = message.newFullName;
                    if (options.oneofs)
                        object._newFullName = "newFullName";
                }
                if (message.newPhone != null && message.hasOwnProperty("newPhone")) {
                    object.newPhone = message.newPhone;
                    if (options.oneofs)
                        object._newPhone = "newPhone";
                }
                if (message.newBillingAddress != null && message.hasOwnProperty("newBillingAddress")) {
                    object.newBillingAddress = message.newBillingAddress;
                    if (options.oneofs)
                        object._newBillingAddress = "newBillingAddress";
                }
                if (message.newAdditionalBillingAddress != null && message.hasOwnProperty("newAdditionalBillingAddress")) {
                    object.newAdditionalBillingAddress = message.newAdditionalBillingAddress;
                    if (options.oneofs)
                        object._newAdditionalBillingAddress = "newAdditionalBillingAddress";
                }
                if (message.newTaxId != null && message.hasOwnProperty("newTaxId")) {
                    object.newTaxId = message.newTaxId;
                    if (options.oneofs)
                        object._newTaxId = "newTaxId";
                }
                return object;
            };
    
            /**
             * Converts this UpdateProfileRequest to JSON.
             * @function toJSON
             * @memberof ca2users.UpdateProfileRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateProfileRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateProfileRequest
             * @function getTypeUrl
             * @memberof ca2users.UpdateProfileRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdateProfileRequest";
            };
    
            return UpdateProfileRequest;
        })();
    
        ca2users.UpdateProfileResponse = (function() {
    
            /**
             * Properties of an UpdateProfileResponse.
             * @memberof ca2users
             * @interface IUpdateProfileResponse
             * @property {ca2types.IUser|null} [user] UpdateProfileResponse user
             */
    
            /**
             * Constructs a new UpdateProfileResponse.
             * @memberof ca2users
             * @classdesc Represents an UpdateProfileResponse.
             * @implements IUpdateProfileResponse
             * @constructor
             * @param {ca2users.IUpdateProfileResponse=} [properties] Properties to set
             */
            function UpdateProfileResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateProfileResponse user.
             * @member {ca2types.IUser|null|undefined} user
             * @memberof ca2users.UpdateProfileResponse
             * @instance
             */
            UpdateProfileResponse.prototype.user = null;
    
            /**
             * Creates a new UpdateProfileResponse instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {ca2users.IUpdateProfileResponse=} [properties] Properties to set
             * @returns {ca2users.UpdateProfileResponse} UpdateProfileResponse instance
             */
            UpdateProfileResponse.create = function create(properties) {
                return new UpdateProfileResponse(properties);
            };
    
            /**
             * Encodes the specified UpdateProfileResponse message. Does not implicitly {@link ca2users.UpdateProfileResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {ca2users.IUpdateProfileResponse} message UpdateProfileResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateProfileResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.ca2types.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UpdateProfileResponse message, length delimited. Does not implicitly {@link ca2users.UpdateProfileResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {ca2users.IUpdateProfileResponse} message UpdateProfileResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateProfileResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdateProfileResponse} UpdateProfileResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateProfileResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdateProfileResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.user = $root.ca2types.User.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateProfileResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdateProfileResponse} UpdateProfileResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateProfileResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateProfileResponse message.
             * @function verify
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateProfileResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.ca2types.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };
    
            /**
             * Creates an UpdateProfileResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdateProfileResponse} UpdateProfileResponse
             */
            UpdateProfileResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdateProfileResponse)
                    return object;
                var message = new $root.ca2users.UpdateProfileResponse();
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".ca2users.UpdateProfileResponse.user: object expected");
                    message.user = $root.ca2types.User.fromObject(object.user);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateProfileResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {ca2users.UpdateProfileResponse} message UpdateProfileResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateProfileResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.user = null;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.ca2types.User.toObject(message.user, options);
                return object;
            };
    
            /**
             * Converts this UpdateProfileResponse to JSON.
             * @function toJSON
             * @memberof ca2users.UpdateProfileResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateProfileResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateProfileResponse
             * @function getTypeUrl
             * @memberof ca2users.UpdateProfileResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdateProfileResponse";
            };
    
            return UpdateProfileResponse;
        })();
    
        ca2users.UpdateEmailRequest = (function() {
    
            /**
             * Properties of an UpdateEmailRequest.
             * @memberof ca2users
             * @interface IUpdateEmailRequest
             * @property {string|null} [newEmail] UpdateEmailRequest newEmail
             */
    
            /**
             * Constructs a new UpdateEmailRequest.
             * @memberof ca2users
             * @classdesc Represents an UpdateEmailRequest.
             * @implements IUpdateEmailRequest
             * @constructor
             * @param {ca2users.IUpdateEmailRequest=} [properties] Properties to set
             */
            function UpdateEmailRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateEmailRequest newEmail.
             * @member {string} newEmail
             * @memberof ca2users.UpdateEmailRequest
             * @instance
             */
            UpdateEmailRequest.prototype.newEmail = "";
    
            /**
             * Creates a new UpdateEmailRequest instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {ca2users.IUpdateEmailRequest=} [properties] Properties to set
             * @returns {ca2users.UpdateEmailRequest} UpdateEmailRequest instance
             */
            UpdateEmailRequest.create = function create(properties) {
                return new UpdateEmailRequest(properties);
            };
    
            /**
             * Encodes the specified UpdateEmailRequest message. Does not implicitly {@link ca2users.UpdateEmailRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {ca2users.IUpdateEmailRequest} message UpdateEmailRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.newEmail != null && Object.hasOwnProperty.call(message, "newEmail"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.newEmail);
                return writer;
            };
    
            /**
             * Encodes the specified UpdateEmailRequest message, length delimited. Does not implicitly {@link ca2users.UpdateEmailRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {ca2users.IUpdateEmailRequest} message UpdateEmailRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateEmailRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdateEmailRequest} UpdateEmailRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdateEmailRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.newEmail = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateEmailRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdateEmailRequest} UpdateEmailRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateEmailRequest message.
             * @function verify
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateEmailRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                    if (!$util.isString(message.newEmail))
                        return "newEmail: string expected";
                return null;
            };
    
            /**
             * Creates an UpdateEmailRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdateEmailRequest} UpdateEmailRequest
             */
            UpdateEmailRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdateEmailRequest)
                    return object;
                var message = new $root.ca2users.UpdateEmailRequest();
                if (object.newEmail != null)
                    message.newEmail = String(object.newEmail);
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateEmailRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {ca2users.UpdateEmailRequest} message UpdateEmailRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateEmailRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.newEmail = "";
                if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                    object.newEmail = message.newEmail;
                return object;
            };
    
            /**
             * Converts this UpdateEmailRequest to JSON.
             * @function toJSON
             * @memberof ca2users.UpdateEmailRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateEmailRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateEmailRequest
             * @function getTypeUrl
             * @memberof ca2users.UpdateEmailRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdateEmailRequest";
            };
    
            return UpdateEmailRequest;
        })();
    
        /**
         * UpdateEmailError enum.
         * @name ca2users.UpdateEmailError
         * @enum {number}
         * @property {number} UEE_UNKNOWN_ERROR=0 UEE_UNKNOWN_ERROR value
         * @property {number} UEE_INVALID_EMAIL_ERROR=1 UEE_INVALID_EMAIL_ERROR value
         * @property {number} UEE_EMAIL_BELONGS_TO_ANOTHER_USER_ERROR=2 UEE_EMAIL_BELONGS_TO_ANOTHER_USER_ERROR value
         */
        ca2users.UpdateEmailError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UEE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "UEE_INVALID_EMAIL_ERROR"] = 1;
            values[valuesById[2] = "UEE_EMAIL_BELONGS_TO_ANOTHER_USER_ERROR"] = 2;
            return values;
        })();
    
        ca2users.UpdateEmailResponse = (function() {
    
            /**
             * Properties of an UpdateEmailResponse.
             * @memberof ca2users
             * @interface IUpdateEmailResponse
             * @property {Array.<ca2users.UpdateEmailError>|null} [errors] UpdateEmailResponse errors
             * @property {string|null} [email] UpdateEmailResponse email
             * @property {ca2types.ICode|null} [code] UpdateEmailResponse code
             */
    
            /**
             * Constructs a new UpdateEmailResponse.
             * @memberof ca2users
             * @classdesc Represents an UpdateEmailResponse.
             * @implements IUpdateEmailResponse
             * @constructor
             * @param {ca2users.IUpdateEmailResponse=} [properties] Properties to set
             */
            function UpdateEmailResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateEmailResponse errors.
             * @member {Array.<ca2users.UpdateEmailError>} errors
             * @memberof ca2users.UpdateEmailResponse
             * @instance
             */
            UpdateEmailResponse.prototype.errors = $util.emptyArray;
    
            /**
             * UpdateEmailResponse email.
             * @member {string} email
             * @memberof ca2users.UpdateEmailResponse
             * @instance
             */
            UpdateEmailResponse.prototype.email = "";
    
            /**
             * UpdateEmailResponse code.
             * @member {ca2types.ICode|null|undefined} code
             * @memberof ca2users.UpdateEmailResponse
             * @instance
             */
            UpdateEmailResponse.prototype.code = null;
    
            /**
             * Creates a new UpdateEmailResponse instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {ca2users.IUpdateEmailResponse=} [properties] Properties to set
             * @returns {ca2users.UpdateEmailResponse} UpdateEmailResponse instance
             */
            UpdateEmailResponse.create = function create(properties) {
                return new UpdateEmailResponse(properties);
            };
    
            /**
             * Encodes the specified UpdateEmailResponse message. Does not implicitly {@link ca2users.UpdateEmailResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {ca2users.IUpdateEmailResponse} message UpdateEmailResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    $root.ca2types.Code.encode(message.code, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                return writer;
            };
    
            /**
             * Encodes the specified UpdateEmailResponse message, length delimited. Does not implicitly {@link ca2users.UpdateEmailResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {ca2users.IUpdateEmailResponse} message UpdateEmailResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateEmailResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdateEmailResponse} UpdateEmailResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdateEmailResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 3: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.code = $root.ca2types.Code.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateEmailResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdateEmailResponse} UpdateEmailResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateEmailResponse message.
             * @function verify
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateEmailResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.code != null && message.hasOwnProperty("code")) {
                    var error = $root.ca2types.Code.verify(message.code);
                    if (error)
                        return "code." + error;
                }
                return null;
            };
    
            /**
             * Creates an UpdateEmailResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdateEmailResponse} UpdateEmailResponse
             */
            UpdateEmailResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdateEmailResponse)
                    return object;
                var message = new $root.ca2users.UpdateEmailResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2users.UpdateEmailResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "UEE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "UEE_INVALID_EMAIL_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "UEE_EMAIL_BELONGS_TO_ANOTHER_USER_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        }
                }
                if (object.email != null)
                    message.email = String(object.email);
                if (object.code != null) {
                    if (typeof object.code !== "object")
                        throw TypeError(".ca2users.UpdateEmailResponse.code: object expected");
                    message.code = $root.ca2types.Code.fromObject(object.code);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateEmailResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {ca2users.UpdateEmailResponse} message UpdateEmailResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateEmailResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults) {
                    object.code = null;
                    object.email = "";
                }
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2users.UpdateEmailError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2users.UpdateEmailError[message.errors[j]] : message.errors[j];
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = $root.ca2types.Code.toObject(message.code, options);
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };
    
            /**
             * Converts this UpdateEmailResponse to JSON.
             * @function toJSON
             * @memberof ca2users.UpdateEmailResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateEmailResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateEmailResponse
             * @function getTypeUrl
             * @memberof ca2users.UpdateEmailResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateEmailResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdateEmailResponse";
            };
    
            return UpdateEmailResponse;
        })();
    
        ca2users.UpdateEmailConfirmationRequest = (function() {
    
            /**
             * Properties of an UpdateEmailConfirmationRequest.
             * @memberof ca2users
             * @interface IUpdateEmailConfirmationRequest
             * @property {string|null} [code] UpdateEmailConfirmationRequest code
             */
    
            /**
             * Constructs a new UpdateEmailConfirmationRequest.
             * @memberof ca2users
             * @classdesc Represents an UpdateEmailConfirmationRequest.
             * @implements IUpdateEmailConfirmationRequest
             * @constructor
             * @param {ca2users.IUpdateEmailConfirmationRequest=} [properties] Properties to set
             */
            function UpdateEmailConfirmationRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateEmailConfirmationRequest code.
             * @member {string} code
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @instance
             */
            UpdateEmailConfirmationRequest.prototype.code = "";
    
            /**
             * Creates a new UpdateEmailConfirmationRequest instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {ca2users.IUpdateEmailConfirmationRequest=} [properties] Properties to set
             * @returns {ca2users.UpdateEmailConfirmationRequest} UpdateEmailConfirmationRequest instance
             */
            UpdateEmailConfirmationRequest.create = function create(properties) {
                return new UpdateEmailConfirmationRequest(properties);
            };
    
            /**
             * Encodes the specified UpdateEmailConfirmationRequest message. Does not implicitly {@link ca2users.UpdateEmailConfirmationRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {ca2users.IUpdateEmailConfirmationRequest} message UpdateEmailConfirmationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailConfirmationRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                return writer;
            };
    
            /**
             * Encodes the specified UpdateEmailConfirmationRequest message, length delimited. Does not implicitly {@link ca2users.UpdateEmailConfirmationRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {ca2users.IUpdateEmailConfirmationRequest} message UpdateEmailConfirmationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailConfirmationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateEmailConfirmationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdateEmailConfirmationRequest} UpdateEmailConfirmationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailConfirmationRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdateEmailConfirmationRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateEmailConfirmationRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdateEmailConfirmationRequest} UpdateEmailConfirmationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailConfirmationRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateEmailConfirmationRequest message.
             * @function verify
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateEmailConfirmationRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                return null;
            };
    
            /**
             * Creates an UpdateEmailConfirmationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdateEmailConfirmationRequest} UpdateEmailConfirmationRequest
             */
            UpdateEmailConfirmationRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdateEmailConfirmationRequest)
                    return object;
                var message = new $root.ca2users.UpdateEmailConfirmationRequest();
                if (object.code != null)
                    message.code = String(object.code);
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateEmailConfirmationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {ca2users.UpdateEmailConfirmationRequest} message UpdateEmailConfirmationRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateEmailConfirmationRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.code = "";
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };
    
            /**
             * Converts this UpdateEmailConfirmationRequest to JSON.
             * @function toJSON
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateEmailConfirmationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateEmailConfirmationRequest
             * @function getTypeUrl
             * @memberof ca2users.UpdateEmailConfirmationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateEmailConfirmationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdateEmailConfirmationRequest";
            };
    
            return UpdateEmailConfirmationRequest;
        })();
    
        /**
         * UpdateEmailConfirmationError enum.
         * @name ca2users.UpdateEmailConfirmationError
         * @enum {number}
         * @property {number} UECE_UNKNOWN_ERROR=0 UECE_UNKNOWN_ERROR value
         * @property {number} UECE_INVALID_CODE_ERROR=1 UECE_INVALID_CODE_ERROR value
         * @property {number} UECE_CODE_EXPIRED_ERROR=2 UECE_CODE_EXPIRED_ERROR value
         * @property {number} UECE_TOO_MANY_ATTEMPTS_ERROR=3 UECE_TOO_MANY_ATTEMPTS_ERROR value
         */
        ca2users.UpdateEmailConfirmationError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UECE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "UECE_INVALID_CODE_ERROR"] = 1;
            values[valuesById[2] = "UECE_CODE_EXPIRED_ERROR"] = 2;
            values[valuesById[3] = "UECE_TOO_MANY_ATTEMPTS_ERROR"] = 3;
            return values;
        })();
    
        ca2users.UpdateEmailConfirmationResponse = (function() {
    
            /**
             * Properties of an UpdateEmailConfirmationResponse.
             * @memberof ca2users
             * @interface IUpdateEmailConfirmationResponse
             * @property {Array.<ca2users.UpdateEmailConfirmationError>|null} [errors] UpdateEmailConfirmationResponse errors
             * @property {ca2types.IUser|null} [user] UpdateEmailConfirmationResponse user
             */
    
            /**
             * Constructs a new UpdateEmailConfirmationResponse.
             * @memberof ca2users
             * @classdesc Represents an UpdateEmailConfirmationResponse.
             * @implements IUpdateEmailConfirmationResponse
             * @constructor
             * @param {ca2users.IUpdateEmailConfirmationResponse=} [properties] Properties to set
             */
            function UpdateEmailConfirmationResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateEmailConfirmationResponse errors.
             * @member {Array.<ca2users.UpdateEmailConfirmationError>} errors
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @instance
             */
            UpdateEmailConfirmationResponse.prototype.errors = $util.emptyArray;
    
            /**
             * UpdateEmailConfirmationResponse user.
             * @member {ca2types.IUser|null|undefined} user
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @instance
             */
            UpdateEmailConfirmationResponse.prototype.user = null;
    
            /**
             * Creates a new UpdateEmailConfirmationResponse instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {ca2users.IUpdateEmailConfirmationResponse=} [properties] Properties to set
             * @returns {ca2users.UpdateEmailConfirmationResponse} UpdateEmailConfirmationResponse instance
             */
            UpdateEmailConfirmationResponse.create = function create(properties) {
                return new UpdateEmailConfirmationResponse(properties);
            };
    
            /**
             * Encodes the specified UpdateEmailConfirmationResponse message. Does not implicitly {@link ca2users.UpdateEmailConfirmationResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {ca2users.IUpdateEmailConfirmationResponse} message UpdateEmailConfirmationResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailConfirmationResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.ca2types.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UpdateEmailConfirmationResponse message, length delimited. Does not implicitly {@link ca2users.UpdateEmailConfirmationResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {ca2users.IUpdateEmailConfirmationResponse} message UpdateEmailConfirmationResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEmailConfirmationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateEmailConfirmationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdateEmailConfirmationResponse} UpdateEmailConfirmationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailConfirmationResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdateEmailConfirmationResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.user = $root.ca2types.User.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateEmailConfirmationResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdateEmailConfirmationResponse} UpdateEmailConfirmationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEmailConfirmationResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateEmailConfirmationResponse message.
             * @function verify
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateEmailConfirmationResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.ca2types.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };
    
            /**
             * Creates an UpdateEmailConfirmationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdateEmailConfirmationResponse} UpdateEmailConfirmationResponse
             */
            UpdateEmailConfirmationResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdateEmailConfirmationResponse)
                    return object;
                var message = new $root.ca2users.UpdateEmailConfirmationResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2users.UpdateEmailConfirmationResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "UECE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "UECE_INVALID_CODE_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "UECE_CODE_EXPIRED_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "UECE_TOO_MANY_ATTEMPTS_ERROR":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".ca2users.UpdateEmailConfirmationResponse.user: object expected");
                    message.user = $root.ca2types.User.fromObject(object.user);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateEmailConfirmationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {ca2users.UpdateEmailConfirmationResponse} message UpdateEmailConfirmationResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateEmailConfirmationResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.user = null;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2users.UpdateEmailConfirmationError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2users.UpdateEmailConfirmationError[message.errors[j]] : message.errors[j];
                }
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.ca2types.User.toObject(message.user, options);
                return object;
            };
    
            /**
             * Converts this UpdateEmailConfirmationResponse to JSON.
             * @function toJSON
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateEmailConfirmationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdateEmailConfirmationResponse
             * @function getTypeUrl
             * @memberof ca2users.UpdateEmailConfirmationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateEmailConfirmationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdateEmailConfirmationResponse";
            };
    
            return UpdateEmailConfirmationResponse;
        })();
    
        ca2users.UpdatePasswordRequest = (function() {
    
            /**
             * Properties of an UpdatePasswordRequest.
             * @memberof ca2users
             * @interface IUpdatePasswordRequest
             * @property {string|null} [oldPassword] UpdatePasswordRequest oldPassword
             * @property {string|null} [newPassword] UpdatePasswordRequest newPassword
             * @property {string|null} [newPasswordConfirmation] UpdatePasswordRequest newPasswordConfirmation
             */
    
            /**
             * Constructs a new UpdatePasswordRequest.
             * @memberof ca2users
             * @classdesc Represents an UpdatePasswordRequest.
             * @implements IUpdatePasswordRequest
             * @constructor
             * @param {ca2users.IUpdatePasswordRequest=} [properties] Properties to set
             */
            function UpdatePasswordRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdatePasswordRequest oldPassword.
             * @member {string} oldPassword
             * @memberof ca2users.UpdatePasswordRequest
             * @instance
             */
            UpdatePasswordRequest.prototype.oldPassword = "";
    
            /**
             * UpdatePasswordRequest newPassword.
             * @member {string} newPassword
             * @memberof ca2users.UpdatePasswordRequest
             * @instance
             */
            UpdatePasswordRequest.prototype.newPassword = "";
    
            /**
             * UpdatePasswordRequest newPasswordConfirmation.
             * @member {string} newPasswordConfirmation
             * @memberof ca2users.UpdatePasswordRequest
             * @instance
             */
            UpdatePasswordRequest.prototype.newPasswordConfirmation = "";
    
            /**
             * Creates a new UpdatePasswordRequest instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {ca2users.IUpdatePasswordRequest=} [properties] Properties to set
             * @returns {ca2users.UpdatePasswordRequest} UpdatePasswordRequest instance
             */
            UpdatePasswordRequest.create = function create(properties) {
                return new UpdatePasswordRequest(properties);
            };
    
            /**
             * Encodes the specified UpdatePasswordRequest message. Does not implicitly {@link ca2users.UpdatePasswordRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {ca2users.IUpdatePasswordRequest} message UpdatePasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePasswordRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.oldPassword != null && Object.hasOwnProperty.call(message, "oldPassword"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.oldPassword);
                if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPassword);
                if (message.newPasswordConfirmation != null && Object.hasOwnProperty.call(message, "newPasswordConfirmation"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.newPasswordConfirmation);
                return writer;
            };
    
            /**
             * Encodes the specified UpdatePasswordRequest message, length delimited. Does not implicitly {@link ca2users.UpdatePasswordRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {ca2users.IUpdatePasswordRequest} message UpdatePasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdatePasswordRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdatePasswordRequest} UpdatePasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePasswordRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdatePasswordRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.oldPassword = reader.string();
                            break;
                        }
                    case 2: {
                            message.newPassword = reader.string();
                            break;
                        }
                    case 3: {
                            message.newPasswordConfirmation = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdatePasswordRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdatePasswordRequest} UpdatePasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePasswordRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdatePasswordRequest message.
             * @function verify
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdatePasswordRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.oldPassword != null && message.hasOwnProperty("oldPassword"))
                    if (!$util.isString(message.oldPassword))
                        return "oldPassword: string expected";
                if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                    if (!$util.isString(message.newPassword))
                        return "newPassword: string expected";
                if (message.newPasswordConfirmation != null && message.hasOwnProperty("newPasswordConfirmation"))
                    if (!$util.isString(message.newPasswordConfirmation))
                        return "newPasswordConfirmation: string expected";
                return null;
            };
    
            /**
             * Creates an UpdatePasswordRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdatePasswordRequest} UpdatePasswordRequest
             */
            UpdatePasswordRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdatePasswordRequest)
                    return object;
                var message = new $root.ca2users.UpdatePasswordRequest();
                if (object.oldPassword != null)
                    message.oldPassword = String(object.oldPassword);
                if (object.newPassword != null)
                    message.newPassword = String(object.newPassword);
                if (object.newPasswordConfirmation != null)
                    message.newPasswordConfirmation = String(object.newPasswordConfirmation);
                return message;
            };
    
            /**
             * Creates a plain object from an UpdatePasswordRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {ca2users.UpdatePasswordRequest} message UpdatePasswordRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdatePasswordRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.oldPassword = "";
                    object.newPassword = "";
                    object.newPasswordConfirmation = "";
                }
                if (message.oldPassword != null && message.hasOwnProperty("oldPassword"))
                    object.oldPassword = message.oldPassword;
                if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                    object.newPassword = message.newPassword;
                if (message.newPasswordConfirmation != null && message.hasOwnProperty("newPasswordConfirmation"))
                    object.newPasswordConfirmation = message.newPasswordConfirmation;
                return object;
            };
    
            /**
             * Converts this UpdatePasswordRequest to JSON.
             * @function toJSON
             * @memberof ca2users.UpdatePasswordRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdatePasswordRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdatePasswordRequest
             * @function getTypeUrl
             * @memberof ca2users.UpdatePasswordRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdatePasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdatePasswordRequest";
            };
    
            return UpdatePasswordRequest;
        })();
    
        /**
         * UpdatePasswordError enum.
         * @name ca2users.UpdatePasswordError
         * @enum {number}
         * @property {number} UPE_UNKNOWN_ERROR=0 UPE_UNKNOWN_ERROR value
         * @property {number} UPE_INVALID_OLD_PASSWORD_ERROR=1 UPE_INVALID_OLD_PASSWORD_ERROR value
         * @property {number} UPE_PASSWORDS_DO_NOT_MATCH_ERROR=2 UPE_PASSWORDS_DO_NOT_MATCH_ERROR value
         * @property {number} UPE_NEW_PASSWORD_IS_TOO_WEAK_ERROR=3 UPE_NEW_PASSWORD_IS_TOO_WEAK_ERROR value
         */
        ca2users.UpdatePasswordError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UPE_UNKNOWN_ERROR"] = 0;
            values[valuesById[1] = "UPE_INVALID_OLD_PASSWORD_ERROR"] = 1;
            values[valuesById[2] = "UPE_PASSWORDS_DO_NOT_MATCH_ERROR"] = 2;
            values[valuesById[3] = "UPE_NEW_PASSWORD_IS_TOO_WEAK_ERROR"] = 3;
            return values;
        })();
    
        ca2users.UpdatePasswordResponse = (function() {
    
            /**
             * Properties of an UpdatePasswordResponse.
             * @memberof ca2users
             * @interface IUpdatePasswordResponse
             * @property {Array.<ca2users.UpdatePasswordError>|null} [errors] UpdatePasswordResponse errors
             * @property {ca2types.IUser|null} [user] UpdatePasswordResponse user
             */
    
            /**
             * Constructs a new UpdatePasswordResponse.
             * @memberof ca2users
             * @classdesc Represents an UpdatePasswordResponse.
             * @implements IUpdatePasswordResponse
             * @constructor
             * @param {ca2users.IUpdatePasswordResponse=} [properties] Properties to set
             */
            function UpdatePasswordResponse(properties) {
                this.errors = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdatePasswordResponse errors.
             * @member {Array.<ca2users.UpdatePasswordError>} errors
             * @memberof ca2users.UpdatePasswordResponse
             * @instance
             */
            UpdatePasswordResponse.prototype.errors = $util.emptyArray;
    
            /**
             * UpdatePasswordResponse user.
             * @member {ca2types.IUser|null|undefined} user
             * @memberof ca2users.UpdatePasswordResponse
             * @instance
             */
            UpdatePasswordResponse.prototype.user = null;
    
            /**
             * Creates a new UpdatePasswordResponse instance using the specified properties.
             * @function create
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {ca2users.IUpdatePasswordResponse=} [properties] Properties to set
             * @returns {ca2users.UpdatePasswordResponse} UpdatePasswordResponse instance
             */
            UpdatePasswordResponse.create = function create(properties) {
                return new UpdatePasswordResponse(properties);
            };
    
            /**
             * Encodes the specified UpdatePasswordResponse message. Does not implicitly {@link ca2users.UpdatePasswordResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {ca2users.IUpdatePasswordResponse} message UpdatePasswordResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePasswordResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errors != null && message.errors.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.errors.length; ++i)
                        writer.int32(message.errors[i]);
                    writer.ldelim();
                }
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.ca2types.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UpdatePasswordResponse message, length delimited. Does not implicitly {@link ca2users.UpdatePasswordResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {ca2users.IUpdatePasswordResponse} message UpdatePasswordResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdatePasswordResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2users.UpdatePasswordResponse} UpdatePasswordResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePasswordResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2users.UpdatePasswordResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.errors && message.errors.length))
                                message.errors = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.errors.push(reader.int32());
                            } else
                                message.errors.push(reader.int32());
                            break;
                        }
                    case 2: {
                            message.user = $root.ca2types.User.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdatePasswordResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2users.UpdatePasswordResponse} UpdatePasswordResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePasswordResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdatePasswordResponse message.
             * @function verify
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdatePasswordResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errors != null && message.hasOwnProperty("errors")) {
                    if (!Array.isArray(message.errors))
                        return "errors: array expected";
                    for (var i = 0; i < message.errors.length; ++i)
                        switch (message.errors[i]) {
                        default:
                            return "errors: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.ca2types.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };
    
            /**
             * Creates an UpdatePasswordResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2users.UpdatePasswordResponse} UpdatePasswordResponse
             */
            UpdatePasswordResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2users.UpdatePasswordResponse)
                    return object;
                var message = new $root.ca2users.UpdatePasswordResponse();
                if (object.errors) {
                    if (!Array.isArray(object.errors))
                        throw TypeError(".ca2users.UpdatePasswordResponse.errors: array expected");
                    message.errors = [];
                    for (var i = 0; i < object.errors.length; ++i)
                        switch (object.errors[i]) {
                        default:
                            if (typeof object.errors[i] === "number") {
                                message.errors[i] = object.errors[i];
                                break;
                            }
                        case "UPE_UNKNOWN_ERROR":
                        case 0:
                            message.errors[i] = 0;
                            break;
                        case "UPE_INVALID_OLD_PASSWORD_ERROR":
                        case 1:
                            message.errors[i] = 1;
                            break;
                        case "UPE_PASSWORDS_DO_NOT_MATCH_ERROR":
                        case 2:
                            message.errors[i] = 2;
                            break;
                        case "UPE_NEW_PASSWORD_IS_TOO_WEAK_ERROR":
                        case 3:
                            message.errors[i] = 3;
                            break;
                        }
                }
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".ca2users.UpdatePasswordResponse.user: object expected");
                    message.user = $root.ca2types.User.fromObject(object.user);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UpdatePasswordResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {ca2users.UpdatePasswordResponse} message UpdatePasswordResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdatePasswordResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.errors = [];
                if (options.defaults)
                    object.user = null;
                if (message.errors && message.errors.length) {
                    object.errors = [];
                    for (var j = 0; j < message.errors.length; ++j)
                        object.errors[j] = options.enums === String ? $root.ca2users.UpdatePasswordError[message.errors[j]] === undefined ? message.errors[j] : $root.ca2users.UpdatePasswordError[message.errors[j]] : message.errors[j];
                }
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.ca2types.User.toObject(message.user, options);
                return object;
            };
    
            /**
             * Converts this UpdatePasswordResponse to JSON.
             * @function toJSON
             * @memberof ca2users.UpdatePasswordResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdatePasswordResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UpdatePasswordResponse
             * @function getTypeUrl
             * @memberof ca2users.UpdatePasswordResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdatePasswordResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2users.UpdatePasswordResponse";
            };
    
            return UpdatePasswordResponse;
        })();
    
        return ca2users;
    })();
    
    $root.ca2utils = (function() {
    
        /**
         * Namespace ca2utils.
         * @exports ca2utils
         * @namespace
         */
        var ca2utils = {};
    
        ca2utils.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof ca2utils
             * @interface IRequest
             * @property {ca2utils.IOptionsRequest|null} [options] Request options
             * @property {ca2utils.IFakeErrorRequest|null} [fakeError] Request fakeError
             */
    
            /**
             * Constructs a new Request.
             * @memberof ca2utils
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {ca2utils.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request options.
             * @member {ca2utils.IOptionsRequest|null|undefined} options
             * @memberof ca2utils.Request
             * @instance
             */
            Request.prototype.options = null;
    
            /**
             * Request fakeError.
             * @member {ca2utils.IFakeErrorRequest|null|undefined} fakeError
             * @memberof ca2utils.Request
             * @instance
             */
            Request.prototype.fakeError = null;
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof ca2utils.Request
             * @static
             * @param {ca2utils.IRequest=} [properties] Properties to set
             * @returns {ca2utils.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link ca2utils.Request.verify|verify} messages.
             * @function encode
             * @memberof ca2utils.Request
             * @static
             * @param {ca2utils.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.ca2utils.OptionsRequest.encode(message.options, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.fakeError != null && Object.hasOwnProperty.call(message, "fakeError"))
                    $root.ca2utils.FakeErrorRequest.encode(message.fakeError, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link ca2utils.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2utils.Request
             * @static
             * @param {ca2utils.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof ca2utils.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2utils.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2utils.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.options = $root.ca2utils.OptionsRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.fakeError = $root.ca2utils.FakeErrorRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2utils.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2utils.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof ca2utils.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.ca2utils.OptionsRequest.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.fakeError != null && message.hasOwnProperty("fakeError")) {
                    var error = $root.ca2utils.FakeErrorRequest.verify(message.fakeError);
                    if (error)
                        return "fakeError." + error;
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2utils.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2utils.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2utils.Request)
                    return object;
                var message = new $root.ca2utils.Request();
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".ca2utils.Request.options: object expected");
                    message.options = $root.ca2utils.OptionsRequest.fromObject(object.options);
                }
                if (object.fakeError != null) {
                    if (typeof object.fakeError !== "object")
                        throw TypeError(".ca2utils.Request.fakeError: object expected");
                    message.fakeError = $root.ca2utils.FakeErrorRequest.fromObject(object.fakeError);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2utils.Request
             * @static
             * @param {ca2utils.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.options = null;
                    object.fakeError = null;
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.ca2utils.OptionsRequest.toObject(message.options, options);
                if (message.fakeError != null && message.hasOwnProperty("fakeError"))
                    object.fakeError = $root.ca2utils.FakeErrorRequest.toObject(message.fakeError, options);
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof ca2utils.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof ca2utils.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2utils.Request";
            };
    
            return Request;
        })();
    
        ca2utils.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof ca2utils
             * @interface IResponse
             * @property {ca2utils.IOptionsResponse|null} [options] Response options
             */
    
            /**
             * Constructs a new Response.
             * @memberof ca2utils
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {ca2utils.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response options.
             * @member {ca2utils.IOptionsResponse|null|undefined} options
             * @memberof ca2utils.Response
             * @instance
             */
            Response.prototype.options = null;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof ca2utils.Response
             * @static
             * @param {ca2utils.IResponse=} [properties] Properties to set
             * @returns {ca2utils.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link ca2utils.Response.verify|verify} messages.
             * @function encode
             * @memberof ca2utils.Response
             * @static
             * @param {ca2utils.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.ca2utils.OptionsResponse.encode(message.options, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link ca2utils.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2utils.Response
             * @static
             * @param {ca2utils.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof ca2utils.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2utils.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2utils.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.options = $root.ca2utils.OptionsResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2utils.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2utils.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof ca2utils.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.ca2utils.OptionsResponse.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2utils.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2utils.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2utils.Response)
                    return object;
                var message = new $root.ca2utils.Response();
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".ca2utils.Response.options: object expected");
                    message.options = $root.ca2utils.OptionsResponse.fromObject(object.options);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2utils.Response
             * @static
             * @param {ca2utils.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.options = null;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.ca2utils.OptionsResponse.toObject(message.options, options);
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof ca2utils.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof ca2utils.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2utils.Response";
            };
    
            return Response;
        })();
    
        ca2utils.OptionsRequest = (function() {
    
            /**
             * Properties of an OptionsRequest.
             * @memberof ca2utils
             * @interface IOptionsRequest
             */
    
            /**
             * Constructs a new OptionsRequest.
             * @memberof ca2utils
             * @classdesc Represents an OptionsRequest.
             * @implements IOptionsRequest
             * @constructor
             * @param {ca2utils.IOptionsRequest=} [properties] Properties to set
             */
            function OptionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new OptionsRequest instance using the specified properties.
             * @function create
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {ca2utils.IOptionsRequest=} [properties] Properties to set
             * @returns {ca2utils.OptionsRequest} OptionsRequest instance
             */
            OptionsRequest.create = function create(properties) {
                return new OptionsRequest(properties);
            };
    
            /**
             * Encodes the specified OptionsRequest message. Does not implicitly {@link ca2utils.OptionsRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {ca2utils.IOptionsRequest} message OptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified OptionsRequest message, length delimited. Does not implicitly {@link ca2utils.OptionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {ca2utils.IOptionsRequest} message OptionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an OptionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2utils.OptionsRequest} OptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2utils.OptionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an OptionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2utils.OptionsRequest} OptionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an OptionsRequest message.
             * @function verify
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OptionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an OptionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2utils.OptionsRequest} OptionsRequest
             */
            OptionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2utils.OptionsRequest)
                    return object;
                return new $root.ca2utils.OptionsRequest();
            };
    
            /**
             * Creates a plain object from an OptionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {ca2utils.OptionsRequest} message OptionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OptionsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this OptionsRequest to JSON.
             * @function toJSON
             * @memberof ca2utils.OptionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OptionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for OptionsRequest
             * @function getTypeUrl
             * @memberof ca2utils.OptionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OptionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2utils.OptionsRequest";
            };
    
            return OptionsRequest;
        })();
    
        ca2utils.OptionsResponse = (function() {
    
            /**
             * Properties of an OptionsResponse.
             * @memberof ca2utils
             * @interface IOptionsResponse
             * @property {Array.<ca2types.IOAuthProvider>|null} [oauthProviders] OptionsResponse oauthProviders
             * @property {boolean|null} [recaptchaEnabled] OptionsResponse recaptchaEnabled
             * @property {boolean|null} [referralEnabled] OptionsResponse referralEnabled
             * @property {number|null} [minPasswordLength] OptionsResponse minPasswordLength
             * @property {number|null} [maxSshKeyLength] OptionsResponse maxSshKeyLength
             * @property {number|null} [maxServerNameLength] OptionsResponse maxServerNameLength
             */
    
            /**
             * Constructs a new OptionsResponse.
             * @memberof ca2utils
             * @classdesc Represents an OptionsResponse.
             * @implements IOptionsResponse
             * @constructor
             * @param {ca2utils.IOptionsResponse=} [properties] Properties to set
             */
            function OptionsResponse(properties) {
                this.oauthProviders = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * OptionsResponse oauthProviders.
             * @member {Array.<ca2types.IOAuthProvider>} oauthProviders
             * @memberof ca2utils.OptionsResponse
             * @instance
             */
            OptionsResponse.prototype.oauthProviders = $util.emptyArray;
    
            /**
             * OptionsResponse recaptchaEnabled.
             * @member {boolean} recaptchaEnabled
             * @memberof ca2utils.OptionsResponse
             * @instance
             */
            OptionsResponse.prototype.recaptchaEnabled = false;
    
            /**
             * OptionsResponse referralEnabled.
             * @member {boolean} referralEnabled
             * @memberof ca2utils.OptionsResponse
             * @instance
             */
            OptionsResponse.prototype.referralEnabled = false;
    
            /**
             * OptionsResponse minPasswordLength.
             * @member {number} minPasswordLength
             * @memberof ca2utils.OptionsResponse
             * @instance
             */
            OptionsResponse.prototype.minPasswordLength = 0;
    
            /**
             * OptionsResponse maxSshKeyLength.
             * @member {number} maxSshKeyLength
             * @memberof ca2utils.OptionsResponse
             * @instance
             */
            OptionsResponse.prototype.maxSshKeyLength = 0;
    
            /**
             * OptionsResponse maxServerNameLength.
             * @member {number} maxServerNameLength
             * @memberof ca2utils.OptionsResponse
             * @instance
             */
            OptionsResponse.prototype.maxServerNameLength = 0;
    
            /**
             * Creates a new OptionsResponse instance using the specified properties.
             * @function create
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {ca2utils.IOptionsResponse=} [properties] Properties to set
             * @returns {ca2utils.OptionsResponse} OptionsResponse instance
             */
            OptionsResponse.create = function create(properties) {
                return new OptionsResponse(properties);
            };
    
            /**
             * Encodes the specified OptionsResponse message. Does not implicitly {@link ca2utils.OptionsResponse.verify|verify} messages.
             * @function encode
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {ca2utils.IOptionsResponse} message OptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.oauthProviders != null && message.oauthProviders.length)
                    for (var i = 0; i < message.oauthProviders.length; ++i)
                        $root.ca2types.OAuthProvider.encode(message.oauthProviders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.recaptchaEnabled != null && Object.hasOwnProperty.call(message, "recaptchaEnabled"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.recaptchaEnabled);
                if (message.referralEnabled != null && Object.hasOwnProperty.call(message, "referralEnabled"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.referralEnabled);
                if (message.minPasswordLength != null && Object.hasOwnProperty.call(message, "minPasswordLength"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.minPasswordLength);
                if (message.maxSshKeyLength != null && Object.hasOwnProperty.call(message, "maxSshKeyLength"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.maxSshKeyLength);
                if (message.maxServerNameLength != null && Object.hasOwnProperty.call(message, "maxServerNameLength"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.maxServerNameLength);
                return writer;
            };
    
            /**
             * Encodes the specified OptionsResponse message, length delimited. Does not implicitly {@link ca2utils.OptionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {ca2utils.IOptionsResponse} message OptionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an OptionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2utils.OptionsResponse} OptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2utils.OptionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.oauthProviders && message.oauthProviders.length))
                                message.oauthProviders = [];
                            message.oauthProviders.push($root.ca2types.OAuthProvider.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.recaptchaEnabled = reader.bool();
                            break;
                        }
                    case 3: {
                            message.referralEnabled = reader.bool();
                            break;
                        }
                    case 4: {
                            message.minPasswordLength = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.maxSshKeyLength = reader.uint32();
                            break;
                        }
                    case 6: {
                            message.maxServerNameLength = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an OptionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2utils.OptionsResponse} OptionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an OptionsResponse message.
             * @function verify
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OptionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.oauthProviders != null && message.hasOwnProperty("oauthProviders")) {
                    if (!Array.isArray(message.oauthProviders))
                        return "oauthProviders: array expected";
                    for (var i = 0; i < message.oauthProviders.length; ++i) {
                        var error = $root.ca2types.OAuthProvider.verify(message.oauthProviders[i]);
                        if (error)
                            return "oauthProviders." + error;
                    }
                }
                if (message.recaptchaEnabled != null && message.hasOwnProperty("recaptchaEnabled"))
                    if (typeof message.recaptchaEnabled !== "boolean")
                        return "recaptchaEnabled: boolean expected";
                if (message.referralEnabled != null && message.hasOwnProperty("referralEnabled"))
                    if (typeof message.referralEnabled !== "boolean")
                        return "referralEnabled: boolean expected";
                if (message.minPasswordLength != null && message.hasOwnProperty("minPasswordLength"))
                    if (!$util.isInteger(message.minPasswordLength))
                        return "minPasswordLength: integer expected";
                if (message.maxSshKeyLength != null && message.hasOwnProperty("maxSshKeyLength"))
                    if (!$util.isInteger(message.maxSshKeyLength))
                        return "maxSshKeyLength: integer expected";
                if (message.maxServerNameLength != null && message.hasOwnProperty("maxServerNameLength"))
                    if (!$util.isInteger(message.maxServerNameLength))
                        return "maxServerNameLength: integer expected";
                return null;
            };
    
            /**
             * Creates an OptionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2utils.OptionsResponse} OptionsResponse
             */
            OptionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2utils.OptionsResponse)
                    return object;
                var message = new $root.ca2utils.OptionsResponse();
                if (object.oauthProviders) {
                    if (!Array.isArray(object.oauthProviders))
                        throw TypeError(".ca2utils.OptionsResponse.oauthProviders: array expected");
                    message.oauthProviders = [];
                    for (var i = 0; i < object.oauthProviders.length; ++i) {
                        if (typeof object.oauthProviders[i] !== "object")
                            throw TypeError(".ca2utils.OptionsResponse.oauthProviders: object expected");
                        message.oauthProviders[i] = $root.ca2types.OAuthProvider.fromObject(object.oauthProviders[i]);
                    }
                }
                if (object.recaptchaEnabled != null)
                    message.recaptchaEnabled = Boolean(object.recaptchaEnabled);
                if (object.referralEnabled != null)
                    message.referralEnabled = Boolean(object.referralEnabled);
                if (object.minPasswordLength != null)
                    message.minPasswordLength = object.minPasswordLength >>> 0;
                if (object.maxSshKeyLength != null)
                    message.maxSshKeyLength = object.maxSshKeyLength >>> 0;
                if (object.maxServerNameLength != null)
                    message.maxServerNameLength = object.maxServerNameLength >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an OptionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {ca2utils.OptionsResponse} message OptionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OptionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.oauthProviders = [];
                if (options.defaults) {
                    object.recaptchaEnabled = false;
                    object.referralEnabled = false;
                    object.minPasswordLength = 0;
                    object.maxSshKeyLength = 0;
                    object.maxServerNameLength = 0;
                }
                if (message.oauthProviders && message.oauthProviders.length) {
                    object.oauthProviders = [];
                    for (var j = 0; j < message.oauthProviders.length; ++j)
                        object.oauthProviders[j] = $root.ca2types.OAuthProvider.toObject(message.oauthProviders[j], options);
                }
                if (message.recaptchaEnabled != null && message.hasOwnProperty("recaptchaEnabled"))
                    object.recaptchaEnabled = message.recaptchaEnabled;
                if (message.referralEnabled != null && message.hasOwnProperty("referralEnabled"))
                    object.referralEnabled = message.referralEnabled;
                if (message.minPasswordLength != null && message.hasOwnProperty("minPasswordLength"))
                    object.minPasswordLength = message.minPasswordLength;
                if (message.maxSshKeyLength != null && message.hasOwnProperty("maxSshKeyLength"))
                    object.maxSshKeyLength = message.maxSshKeyLength;
                if (message.maxServerNameLength != null && message.hasOwnProperty("maxServerNameLength"))
                    object.maxServerNameLength = message.maxServerNameLength;
                return object;
            };
    
            /**
             * Converts this OptionsResponse to JSON.
             * @function toJSON
             * @memberof ca2utils.OptionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OptionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for OptionsResponse
             * @function getTypeUrl
             * @memberof ca2utils.OptionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OptionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2utils.OptionsResponse";
            };
    
            return OptionsResponse;
        })();
    
        ca2utils.FakeErrorRequest = (function() {
    
            /**
             * Properties of a FakeErrorRequest.
             * @memberof ca2utils
             * @interface IFakeErrorRequest
             */
    
            /**
             * Constructs a new FakeErrorRequest.
             * @memberof ca2utils
             * @classdesc Represents a FakeErrorRequest.
             * @implements IFakeErrorRequest
             * @constructor
             * @param {ca2utils.IFakeErrorRequest=} [properties] Properties to set
             */
            function FakeErrorRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new FakeErrorRequest instance using the specified properties.
             * @function create
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {ca2utils.IFakeErrorRequest=} [properties] Properties to set
             * @returns {ca2utils.FakeErrorRequest} FakeErrorRequest instance
             */
            FakeErrorRequest.create = function create(properties) {
                return new FakeErrorRequest(properties);
            };
    
            /**
             * Encodes the specified FakeErrorRequest message. Does not implicitly {@link ca2utils.FakeErrorRequest.verify|verify} messages.
             * @function encode
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {ca2utils.IFakeErrorRequest} message FakeErrorRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FakeErrorRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified FakeErrorRequest message, length delimited. Does not implicitly {@link ca2utils.FakeErrorRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {ca2utils.IFakeErrorRequest} message FakeErrorRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FakeErrorRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a FakeErrorRequest message from the specified reader or buffer.
             * @function decode
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2utils.FakeErrorRequest} FakeErrorRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FakeErrorRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2utils.FakeErrorRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a FakeErrorRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2utils.FakeErrorRequest} FakeErrorRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FakeErrorRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a FakeErrorRequest message.
             * @function verify
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FakeErrorRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a FakeErrorRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2utils.FakeErrorRequest} FakeErrorRequest
             */
            FakeErrorRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2utils.FakeErrorRequest)
                    return object;
                return new $root.ca2utils.FakeErrorRequest();
            };
    
            /**
             * Creates a plain object from a FakeErrorRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {ca2utils.FakeErrorRequest} message FakeErrorRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FakeErrorRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this FakeErrorRequest to JSON.
             * @function toJSON
             * @memberof ca2utils.FakeErrorRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FakeErrorRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for FakeErrorRequest
             * @function getTypeUrl
             * @memberof ca2utils.FakeErrorRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FakeErrorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2utils.FakeErrorRequest";
            };
    
            return FakeErrorRequest;
        })();
    
        return ca2utils;
    })();
    
    $root.ca2events = (function() {
    
        /**
         * Namespace ca2events.
         * @exports ca2events
         * @namespace
         */
        var ca2events = {};
    
        ca2events.Event = (function() {
    
            /**
             * Properties of an Event.
             * @memberof ca2events
             * @interface IEvent
             * @property {Long|null} [userId] Event userId
             * @property {Long|null} [createdAt] Event createdAt
             * @property {Array.<ca2.IWsEvent>|null} [wsEvents] Event wsEvents
             * @property {Array.<ca2events.INewEmailNotificationInfo>|null} [newEmailNotifications] Event newEmailNotifications
             * @property {Array.<ca2events.IBroadcastInstanceInfo>|null} [broadcastInstances] Event broadcastInstances
             * @property {Array.<ca2events.ICreateInstanceInfo>|null} [createInstances] Event createInstances
             * @property {Array.<ca2events.IDeleteInstanceInfo>|null} [deleteInstances] Event deleteInstances
             * @property {Array.<ca2events.IMarkInstanceAsDeletedInfo>|null} [markInstancesAsDeleted] Event markInstancesAsDeleted
             * @property {Array.<ca2events.IReinstallInstanceInfo>|null} [reinstallInstances] Event reinstallInstances
             * @property {Array.<ca2events.IRestartInstanceInfo>|null} [restartInstances] Event restartInstances
             * @property {Array.<ca2events.IStartInstanceInfo>|null} [startInstances] Event startInstances
             * @property {Array.<ca2events.IStopInstanceInfo>|null} [stopInstances] Event stopInstances
             * @property {Array.<ca2events.IInstanceWasCreatedInfo>|null} [instancesWasCreated] Event instancesWasCreated
             * @property {Array.<ca2events.IPayForInstanceInfo>|null} [payForInstances] Event payForInstances
             * @property {Array.<ca2events.IBalanceOperationInfo>|null} [newBalanceOperations] Event newBalanceOperations
             * @property {Array.<ca2events.IPaymentUpdateInfo>|null} [newPaymentUpdates] Event newPaymentUpdates
             * @property {Array.<ca2events.INewUserInfo>|null} [newUsers] Event newUsers
             * @property {Array.<ca2events.INewWsConnectionInfo>|null} [newWsConnections] Event newWsConnections
             * @property {Array.<ca2events.IVerificationCodeInfo>|null} [newVerificationCodes] Event newVerificationCodes
             * @property {Array.<ca2events.INewCheckDomainInfo>|null} [newCheckDomains] Event newCheckDomains
             * @property {Array.<ca2events.INewRegisterDomainInfo>|null} [newRegisterDomains] Event newRegisterDomains
             */
    
            /**
             * Constructs a new Event.
             * @memberof ca2events
             * @classdesc Represents an Event.
             * @implements IEvent
             * @constructor
             * @param {ca2events.IEvent=} [properties] Properties to set
             */
            function Event(properties) {
                this.wsEvents = [];
                this.newEmailNotifications = [];
                this.broadcastInstances = [];
                this.createInstances = [];
                this.deleteInstances = [];
                this.markInstancesAsDeleted = [];
                this.reinstallInstances = [];
                this.restartInstances = [];
                this.startInstances = [];
                this.stopInstances = [];
                this.instancesWasCreated = [];
                this.payForInstances = [];
                this.newBalanceOperations = [];
                this.newPaymentUpdates = [];
                this.newUsers = [];
                this.newWsConnections = [];
                this.newVerificationCodes = [];
                this.newCheckDomains = [];
                this.newRegisterDomains = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Event userId.
             * @member {Long} userId
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Event createdAt.
             * @member {Long} createdAt
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Event wsEvents.
             * @member {Array.<ca2.IWsEvent>} wsEvents
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.wsEvents = $util.emptyArray;
    
            /**
             * Event newEmailNotifications.
             * @member {Array.<ca2events.INewEmailNotificationInfo>} newEmailNotifications
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newEmailNotifications = $util.emptyArray;
    
            /**
             * Event broadcastInstances.
             * @member {Array.<ca2events.IBroadcastInstanceInfo>} broadcastInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.broadcastInstances = $util.emptyArray;
    
            /**
             * Event createInstances.
             * @member {Array.<ca2events.ICreateInstanceInfo>} createInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.createInstances = $util.emptyArray;
    
            /**
             * Event deleteInstances.
             * @member {Array.<ca2events.IDeleteInstanceInfo>} deleteInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.deleteInstances = $util.emptyArray;
    
            /**
             * Event markInstancesAsDeleted.
             * @member {Array.<ca2events.IMarkInstanceAsDeletedInfo>} markInstancesAsDeleted
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.markInstancesAsDeleted = $util.emptyArray;
    
            /**
             * Event reinstallInstances.
             * @member {Array.<ca2events.IReinstallInstanceInfo>} reinstallInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.reinstallInstances = $util.emptyArray;
    
            /**
             * Event restartInstances.
             * @member {Array.<ca2events.IRestartInstanceInfo>} restartInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.restartInstances = $util.emptyArray;
    
            /**
             * Event startInstances.
             * @member {Array.<ca2events.IStartInstanceInfo>} startInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.startInstances = $util.emptyArray;
    
            /**
             * Event stopInstances.
             * @member {Array.<ca2events.IStopInstanceInfo>} stopInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.stopInstances = $util.emptyArray;
    
            /**
             * Event instancesWasCreated.
             * @member {Array.<ca2events.IInstanceWasCreatedInfo>} instancesWasCreated
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.instancesWasCreated = $util.emptyArray;
    
            /**
             * Event payForInstances.
             * @member {Array.<ca2events.IPayForInstanceInfo>} payForInstances
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.payForInstances = $util.emptyArray;
    
            /**
             * Event newBalanceOperations.
             * @member {Array.<ca2events.IBalanceOperationInfo>} newBalanceOperations
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newBalanceOperations = $util.emptyArray;
    
            /**
             * Event newPaymentUpdates.
             * @member {Array.<ca2events.IPaymentUpdateInfo>} newPaymentUpdates
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newPaymentUpdates = $util.emptyArray;
    
            /**
             * Event newUsers.
             * @member {Array.<ca2events.INewUserInfo>} newUsers
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newUsers = $util.emptyArray;
    
            /**
             * Event newWsConnections.
             * @member {Array.<ca2events.INewWsConnectionInfo>} newWsConnections
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newWsConnections = $util.emptyArray;
    
            /**
             * Event newVerificationCodes.
             * @member {Array.<ca2events.IVerificationCodeInfo>} newVerificationCodes
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newVerificationCodes = $util.emptyArray;
    
            /**
             * Event newCheckDomains.
             * @member {Array.<ca2events.INewCheckDomainInfo>} newCheckDomains
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newCheckDomains = $util.emptyArray;
    
            /**
             * Event newRegisterDomains.
             * @member {Array.<ca2events.INewRegisterDomainInfo>} newRegisterDomains
             * @memberof ca2events.Event
             * @instance
             */
            Event.prototype.newRegisterDomains = $util.emptyArray;
    
            /**
             * Creates a new Event instance using the specified properties.
             * @function create
             * @memberof ca2events.Event
             * @static
             * @param {ca2events.IEvent=} [properties] Properties to set
             * @returns {ca2events.Event} Event instance
             */
            Event.create = function create(properties) {
                return new Event(properties);
            };
    
            /**
             * Encodes the specified Event message. Does not implicitly {@link ca2events.Event.verify|verify} messages.
             * @function encode
             * @memberof ca2events.Event
             * @static
             * @param {ca2events.IEvent} message Event message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Event.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.userId);
                if (message.newUsers != null && message.newUsers.length)
                    for (var i = 0; i < message.newUsers.length; ++i)
                        $root.ca2events.NewUserInfo.encode(message.newUsers[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.wsEvents != null && message.wsEvents.length)
                    for (var i = 0; i < message.wsEvents.length; ++i)
                        $root.ca2.WsEvent.encode(message.wsEvents[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.createdAt);
                if (message.createInstances != null && message.createInstances.length)
                    for (var i = 0; i < message.createInstances.length; ++i)
                        $root.ca2events.CreateInstanceInfo.encode(message.createInstances[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.newBalanceOperations != null && message.newBalanceOperations.length)
                    for (var i = 0; i < message.newBalanceOperations.length; ++i)
                        $root.ca2events.BalanceOperationInfo.encode(message.newBalanceOperations[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.newVerificationCodes != null && message.newVerificationCodes.length)
                    for (var i = 0; i < message.newVerificationCodes.length; ++i)
                        $root.ca2events.VerificationCodeInfo.encode(message.newVerificationCodes[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.newWsConnections != null && message.newWsConnections.length)
                    for (var i = 0; i < message.newWsConnections.length; ++i)
                        $root.ca2events.NewWsConnectionInfo.encode(message.newWsConnections[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.broadcastInstances != null && message.broadcastInstances.length)
                    for (var i = 0; i < message.broadcastInstances.length; ++i)
                        $root.ca2events.BroadcastInstanceInfo.encode(message.broadcastInstances[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.deleteInstances != null && message.deleteInstances.length)
                    for (var i = 0; i < message.deleteInstances.length; ++i)
                        $root.ca2events.DeleteInstanceInfo.encode(message.deleteInstances[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.stopInstances != null && message.stopInstances.length)
                    for (var i = 0; i < message.stopInstances.length; ++i)
                        $root.ca2events.StopInstanceInfo.encode(message.stopInstances[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.restartInstances != null && message.restartInstances.length)
                    for (var i = 0; i < message.restartInstances.length; ++i)
                        $root.ca2events.RestartInstanceInfo.encode(message.restartInstances[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.startInstances != null && message.startInstances.length)
                    for (var i = 0; i < message.startInstances.length; ++i)
                        $root.ca2events.StartInstanceInfo.encode(message.startInstances[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.reinstallInstances != null && message.reinstallInstances.length)
                    for (var i = 0; i < message.reinstallInstances.length; ++i)
                        $root.ca2events.ReinstallInstanceInfo.encode(message.reinstallInstances[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.instancesWasCreated != null && message.instancesWasCreated.length)
                    for (var i = 0; i < message.instancesWasCreated.length; ++i)
                        $root.ca2events.InstanceWasCreatedInfo.encode(message.instancesWasCreated[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.payForInstances != null && message.payForInstances.length)
                    for (var i = 0; i < message.payForInstances.length; ++i)
                        $root.ca2events.PayForInstanceInfo.encode(message.payForInstances[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.newEmailNotifications != null && message.newEmailNotifications.length)
                    for (var i = 0; i < message.newEmailNotifications.length; ++i)
                        $root.ca2events.NewEmailNotificationInfo.encode(message.newEmailNotifications[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.markInstancesAsDeleted != null && message.markInstancesAsDeleted.length)
                    for (var i = 0; i < message.markInstancesAsDeleted.length; ++i)
                        $root.ca2events.MarkInstanceAsDeletedInfo.encode(message.markInstancesAsDeleted[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.newCheckDomains != null && message.newCheckDomains.length)
                    for (var i = 0; i < message.newCheckDomains.length; ++i)
                        $root.ca2events.NewCheckDomainInfo.encode(message.newCheckDomains[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.newRegisterDomains != null && message.newRegisterDomains.length)
                    for (var i = 0; i < message.newRegisterDomains.length; ++i)
                        $root.ca2events.NewRegisterDomainInfo.encode(message.newRegisterDomains[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.newPaymentUpdates != null && message.newPaymentUpdates.length)
                    for (var i = 0; i < message.newPaymentUpdates.length; ++i)
                        $root.ca2events.PaymentUpdateInfo.encode(message.newPaymentUpdates[i], writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Event message, length delimited. Does not implicitly {@link ca2events.Event.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.Event
             * @static
             * @param {ca2events.IEvent} message Event message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Event.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Event message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.Event
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.Event} Event
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Event.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.Event();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.userId = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.createdAt = reader.uint64();
                            break;
                        }
                    case 4: {
                            if (!(message.wsEvents && message.wsEvents.length))
                                message.wsEvents = [];
                            message.wsEvents.push($root.ca2.WsEvent.decode(reader, reader.uint32()));
                            break;
                        }
                    case 18: {
                            if (!(message.newEmailNotifications && message.newEmailNotifications.length))
                                message.newEmailNotifications = [];
                            message.newEmailNotifications.push($root.ca2events.NewEmailNotificationInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            if (!(message.broadcastInstances && message.broadcastInstances.length))
                                message.broadcastInstances = [];
                            message.broadcastInstances.push($root.ca2events.BroadcastInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.createInstances && message.createInstances.length))
                                message.createInstances = [];
                            message.createInstances.push($root.ca2events.CreateInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 11: {
                            if (!(message.deleteInstances && message.deleteInstances.length))
                                message.deleteInstances = [];
                            message.deleteInstances.push($root.ca2events.DeleteInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 19: {
                            if (!(message.markInstancesAsDeleted && message.markInstancesAsDeleted.length))
                                message.markInstancesAsDeleted = [];
                            message.markInstancesAsDeleted.push($root.ca2events.MarkInstanceAsDeletedInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 15: {
                            if (!(message.reinstallInstances && message.reinstallInstances.length))
                                message.reinstallInstances = [];
                            message.reinstallInstances.push($root.ca2events.ReinstallInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 13: {
                            if (!(message.restartInstances && message.restartInstances.length))
                                message.restartInstances = [];
                            message.restartInstances.push($root.ca2events.RestartInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 14: {
                            if (!(message.startInstances && message.startInstances.length))
                                message.startInstances = [];
                            message.startInstances.push($root.ca2events.StartInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 12: {
                            if (!(message.stopInstances && message.stopInstances.length))
                                message.stopInstances = [];
                            message.stopInstances.push($root.ca2events.StopInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 16: {
                            if (!(message.instancesWasCreated && message.instancesWasCreated.length))
                                message.instancesWasCreated = [];
                            message.instancesWasCreated.push($root.ca2events.InstanceWasCreatedInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 17: {
                            if (!(message.payForInstances && message.payForInstances.length))
                                message.payForInstances = [];
                            message.payForInstances.push($root.ca2events.PayForInstanceInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            if (!(message.newBalanceOperations && message.newBalanceOperations.length))
                                message.newBalanceOperations = [];
                            message.newBalanceOperations.push($root.ca2events.BalanceOperationInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 22: {
                            if (!(message.newPaymentUpdates && message.newPaymentUpdates.length))
                                message.newPaymentUpdates = [];
                            message.newPaymentUpdates.push($root.ca2events.PaymentUpdateInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.newUsers && message.newUsers.length))
                                message.newUsers = [];
                            message.newUsers.push($root.ca2events.NewUserInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            if (!(message.newWsConnections && message.newWsConnections.length))
                                message.newWsConnections = [];
                            message.newWsConnections.push($root.ca2events.NewWsConnectionInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            if (!(message.newVerificationCodes && message.newVerificationCodes.length))
                                message.newVerificationCodes = [];
                            message.newVerificationCodes.push($root.ca2events.VerificationCodeInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 20: {
                            if (!(message.newCheckDomains && message.newCheckDomains.length))
                                message.newCheckDomains = [];
                            message.newCheckDomains.push($root.ca2events.NewCheckDomainInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 21: {
                            if (!(message.newRegisterDomains && message.newRegisterDomains.length))
                                message.newRegisterDomains = [];
                            message.newRegisterDomains.push($root.ca2events.NewRegisterDomainInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Event message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.Event
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.Event} Event
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Event.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Event message.
             * @function verify
             * @memberof ca2events.Event
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Event.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isInteger(message.userId) && !(message.userId && $util.isInteger(message.userId.low) && $util.isInteger(message.userId.high)))
                        return "userId: integer|Long expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.wsEvents != null && message.hasOwnProperty("wsEvents")) {
                    if (!Array.isArray(message.wsEvents))
                        return "wsEvents: array expected";
                    for (var i = 0; i < message.wsEvents.length; ++i) {
                        var error = $root.ca2.WsEvent.verify(message.wsEvents[i]);
                        if (error)
                            return "wsEvents." + error;
                    }
                }
                if (message.newEmailNotifications != null && message.hasOwnProperty("newEmailNotifications")) {
                    if (!Array.isArray(message.newEmailNotifications))
                        return "newEmailNotifications: array expected";
                    for (var i = 0; i < message.newEmailNotifications.length; ++i) {
                        var error = $root.ca2events.NewEmailNotificationInfo.verify(message.newEmailNotifications[i]);
                        if (error)
                            return "newEmailNotifications." + error;
                    }
                }
                if (message.broadcastInstances != null && message.hasOwnProperty("broadcastInstances")) {
                    if (!Array.isArray(message.broadcastInstances))
                        return "broadcastInstances: array expected";
                    for (var i = 0; i < message.broadcastInstances.length; ++i) {
                        var error = $root.ca2events.BroadcastInstanceInfo.verify(message.broadcastInstances[i]);
                        if (error)
                            return "broadcastInstances." + error;
                    }
                }
                if (message.createInstances != null && message.hasOwnProperty("createInstances")) {
                    if (!Array.isArray(message.createInstances))
                        return "createInstances: array expected";
                    for (var i = 0; i < message.createInstances.length; ++i) {
                        var error = $root.ca2events.CreateInstanceInfo.verify(message.createInstances[i]);
                        if (error)
                            return "createInstances." + error;
                    }
                }
                if (message.deleteInstances != null && message.hasOwnProperty("deleteInstances")) {
                    if (!Array.isArray(message.deleteInstances))
                        return "deleteInstances: array expected";
                    for (var i = 0; i < message.deleteInstances.length; ++i) {
                        var error = $root.ca2events.DeleteInstanceInfo.verify(message.deleteInstances[i]);
                        if (error)
                            return "deleteInstances." + error;
                    }
                }
                if (message.markInstancesAsDeleted != null && message.hasOwnProperty("markInstancesAsDeleted")) {
                    if (!Array.isArray(message.markInstancesAsDeleted))
                        return "markInstancesAsDeleted: array expected";
                    for (var i = 0; i < message.markInstancesAsDeleted.length; ++i) {
                        var error = $root.ca2events.MarkInstanceAsDeletedInfo.verify(message.markInstancesAsDeleted[i]);
                        if (error)
                            return "markInstancesAsDeleted." + error;
                    }
                }
                if (message.reinstallInstances != null && message.hasOwnProperty("reinstallInstances")) {
                    if (!Array.isArray(message.reinstallInstances))
                        return "reinstallInstances: array expected";
                    for (var i = 0; i < message.reinstallInstances.length; ++i) {
                        var error = $root.ca2events.ReinstallInstanceInfo.verify(message.reinstallInstances[i]);
                        if (error)
                            return "reinstallInstances." + error;
                    }
                }
                if (message.restartInstances != null && message.hasOwnProperty("restartInstances")) {
                    if (!Array.isArray(message.restartInstances))
                        return "restartInstances: array expected";
                    for (var i = 0; i < message.restartInstances.length; ++i) {
                        var error = $root.ca2events.RestartInstanceInfo.verify(message.restartInstances[i]);
                        if (error)
                            return "restartInstances." + error;
                    }
                }
                if (message.startInstances != null && message.hasOwnProperty("startInstances")) {
                    if (!Array.isArray(message.startInstances))
                        return "startInstances: array expected";
                    for (var i = 0; i < message.startInstances.length; ++i) {
                        var error = $root.ca2events.StartInstanceInfo.verify(message.startInstances[i]);
                        if (error)
                            return "startInstances." + error;
                    }
                }
                if (message.stopInstances != null && message.hasOwnProperty("stopInstances")) {
                    if (!Array.isArray(message.stopInstances))
                        return "stopInstances: array expected";
                    for (var i = 0; i < message.stopInstances.length; ++i) {
                        var error = $root.ca2events.StopInstanceInfo.verify(message.stopInstances[i]);
                        if (error)
                            return "stopInstances." + error;
                    }
                }
                if (message.instancesWasCreated != null && message.hasOwnProperty("instancesWasCreated")) {
                    if (!Array.isArray(message.instancesWasCreated))
                        return "instancesWasCreated: array expected";
                    for (var i = 0; i < message.instancesWasCreated.length; ++i) {
                        var error = $root.ca2events.InstanceWasCreatedInfo.verify(message.instancesWasCreated[i]);
                        if (error)
                            return "instancesWasCreated." + error;
                    }
                }
                if (message.payForInstances != null && message.hasOwnProperty("payForInstances")) {
                    if (!Array.isArray(message.payForInstances))
                        return "payForInstances: array expected";
                    for (var i = 0; i < message.payForInstances.length; ++i) {
                        var error = $root.ca2events.PayForInstanceInfo.verify(message.payForInstances[i]);
                        if (error)
                            return "payForInstances." + error;
                    }
                }
                if (message.newBalanceOperations != null && message.hasOwnProperty("newBalanceOperations")) {
                    if (!Array.isArray(message.newBalanceOperations))
                        return "newBalanceOperations: array expected";
                    for (var i = 0; i < message.newBalanceOperations.length; ++i) {
                        var error = $root.ca2events.BalanceOperationInfo.verify(message.newBalanceOperations[i]);
                        if (error)
                            return "newBalanceOperations." + error;
                    }
                }
                if (message.newPaymentUpdates != null && message.hasOwnProperty("newPaymentUpdates")) {
                    if (!Array.isArray(message.newPaymentUpdates))
                        return "newPaymentUpdates: array expected";
                    for (var i = 0; i < message.newPaymentUpdates.length; ++i) {
                        var error = $root.ca2events.PaymentUpdateInfo.verify(message.newPaymentUpdates[i]);
                        if (error)
                            return "newPaymentUpdates." + error;
                    }
                }
                if (message.newUsers != null && message.hasOwnProperty("newUsers")) {
                    if (!Array.isArray(message.newUsers))
                        return "newUsers: array expected";
                    for (var i = 0; i < message.newUsers.length; ++i) {
                        var error = $root.ca2events.NewUserInfo.verify(message.newUsers[i]);
                        if (error)
                            return "newUsers." + error;
                    }
                }
                if (message.newWsConnections != null && message.hasOwnProperty("newWsConnections")) {
                    if (!Array.isArray(message.newWsConnections))
                        return "newWsConnections: array expected";
                    for (var i = 0; i < message.newWsConnections.length; ++i) {
                        var error = $root.ca2events.NewWsConnectionInfo.verify(message.newWsConnections[i]);
                        if (error)
                            return "newWsConnections." + error;
                    }
                }
                if (message.newVerificationCodes != null && message.hasOwnProperty("newVerificationCodes")) {
                    if (!Array.isArray(message.newVerificationCodes))
                        return "newVerificationCodes: array expected";
                    for (var i = 0; i < message.newVerificationCodes.length; ++i) {
                        var error = $root.ca2events.VerificationCodeInfo.verify(message.newVerificationCodes[i]);
                        if (error)
                            return "newVerificationCodes." + error;
                    }
                }
                if (message.newCheckDomains != null && message.hasOwnProperty("newCheckDomains")) {
                    if (!Array.isArray(message.newCheckDomains))
                        return "newCheckDomains: array expected";
                    for (var i = 0; i < message.newCheckDomains.length; ++i) {
                        var error = $root.ca2events.NewCheckDomainInfo.verify(message.newCheckDomains[i]);
                        if (error)
                            return "newCheckDomains." + error;
                    }
                }
                if (message.newRegisterDomains != null && message.hasOwnProperty("newRegisterDomains")) {
                    if (!Array.isArray(message.newRegisterDomains))
                        return "newRegisterDomains: array expected";
                    for (var i = 0; i < message.newRegisterDomains.length; ++i) {
                        var error = $root.ca2events.NewRegisterDomainInfo.verify(message.newRegisterDomains[i]);
                        if (error)
                            return "newRegisterDomains." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an Event message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.Event
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.Event} Event
             */
            Event.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.Event)
                    return object;
                var message = new $root.ca2events.Event();
                if (object.userId != null)
                    if ($util.Long)
                        (message.userId = $util.Long.fromValue(object.userId)).unsigned = true;
                    else if (typeof object.userId === "string")
                        message.userId = parseInt(object.userId, 10);
                    else if (typeof object.userId === "number")
                        message.userId = object.userId;
                    else if (typeof object.userId === "object")
                        message.userId = new $util.LongBits(object.userId.low >>> 0, object.userId.high >>> 0).toNumber(true);
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                if (object.wsEvents) {
                    if (!Array.isArray(object.wsEvents))
                        throw TypeError(".ca2events.Event.wsEvents: array expected");
                    message.wsEvents = [];
                    for (var i = 0; i < object.wsEvents.length; ++i) {
                        if (typeof object.wsEvents[i] !== "object")
                            throw TypeError(".ca2events.Event.wsEvents: object expected");
                        message.wsEvents[i] = $root.ca2.WsEvent.fromObject(object.wsEvents[i]);
                    }
                }
                if (object.newEmailNotifications) {
                    if (!Array.isArray(object.newEmailNotifications))
                        throw TypeError(".ca2events.Event.newEmailNotifications: array expected");
                    message.newEmailNotifications = [];
                    for (var i = 0; i < object.newEmailNotifications.length; ++i) {
                        if (typeof object.newEmailNotifications[i] !== "object")
                            throw TypeError(".ca2events.Event.newEmailNotifications: object expected");
                        message.newEmailNotifications[i] = $root.ca2events.NewEmailNotificationInfo.fromObject(object.newEmailNotifications[i]);
                    }
                }
                if (object.broadcastInstances) {
                    if (!Array.isArray(object.broadcastInstances))
                        throw TypeError(".ca2events.Event.broadcastInstances: array expected");
                    message.broadcastInstances = [];
                    for (var i = 0; i < object.broadcastInstances.length; ++i) {
                        if (typeof object.broadcastInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.broadcastInstances: object expected");
                        message.broadcastInstances[i] = $root.ca2events.BroadcastInstanceInfo.fromObject(object.broadcastInstances[i]);
                    }
                }
                if (object.createInstances) {
                    if (!Array.isArray(object.createInstances))
                        throw TypeError(".ca2events.Event.createInstances: array expected");
                    message.createInstances = [];
                    for (var i = 0; i < object.createInstances.length; ++i) {
                        if (typeof object.createInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.createInstances: object expected");
                        message.createInstances[i] = $root.ca2events.CreateInstanceInfo.fromObject(object.createInstances[i]);
                    }
                }
                if (object.deleteInstances) {
                    if (!Array.isArray(object.deleteInstances))
                        throw TypeError(".ca2events.Event.deleteInstances: array expected");
                    message.deleteInstances = [];
                    for (var i = 0; i < object.deleteInstances.length; ++i) {
                        if (typeof object.deleteInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.deleteInstances: object expected");
                        message.deleteInstances[i] = $root.ca2events.DeleteInstanceInfo.fromObject(object.deleteInstances[i]);
                    }
                }
                if (object.markInstancesAsDeleted) {
                    if (!Array.isArray(object.markInstancesAsDeleted))
                        throw TypeError(".ca2events.Event.markInstancesAsDeleted: array expected");
                    message.markInstancesAsDeleted = [];
                    for (var i = 0; i < object.markInstancesAsDeleted.length; ++i) {
                        if (typeof object.markInstancesAsDeleted[i] !== "object")
                            throw TypeError(".ca2events.Event.markInstancesAsDeleted: object expected");
                        message.markInstancesAsDeleted[i] = $root.ca2events.MarkInstanceAsDeletedInfo.fromObject(object.markInstancesAsDeleted[i]);
                    }
                }
                if (object.reinstallInstances) {
                    if (!Array.isArray(object.reinstallInstances))
                        throw TypeError(".ca2events.Event.reinstallInstances: array expected");
                    message.reinstallInstances = [];
                    for (var i = 0; i < object.reinstallInstances.length; ++i) {
                        if (typeof object.reinstallInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.reinstallInstances: object expected");
                        message.reinstallInstances[i] = $root.ca2events.ReinstallInstanceInfo.fromObject(object.reinstallInstances[i]);
                    }
                }
                if (object.restartInstances) {
                    if (!Array.isArray(object.restartInstances))
                        throw TypeError(".ca2events.Event.restartInstances: array expected");
                    message.restartInstances = [];
                    for (var i = 0; i < object.restartInstances.length; ++i) {
                        if (typeof object.restartInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.restartInstances: object expected");
                        message.restartInstances[i] = $root.ca2events.RestartInstanceInfo.fromObject(object.restartInstances[i]);
                    }
                }
                if (object.startInstances) {
                    if (!Array.isArray(object.startInstances))
                        throw TypeError(".ca2events.Event.startInstances: array expected");
                    message.startInstances = [];
                    for (var i = 0; i < object.startInstances.length; ++i) {
                        if (typeof object.startInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.startInstances: object expected");
                        message.startInstances[i] = $root.ca2events.StartInstanceInfo.fromObject(object.startInstances[i]);
                    }
                }
                if (object.stopInstances) {
                    if (!Array.isArray(object.stopInstances))
                        throw TypeError(".ca2events.Event.stopInstances: array expected");
                    message.stopInstances = [];
                    for (var i = 0; i < object.stopInstances.length; ++i) {
                        if (typeof object.stopInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.stopInstances: object expected");
                        message.stopInstances[i] = $root.ca2events.StopInstanceInfo.fromObject(object.stopInstances[i]);
                    }
                }
                if (object.instancesWasCreated) {
                    if (!Array.isArray(object.instancesWasCreated))
                        throw TypeError(".ca2events.Event.instancesWasCreated: array expected");
                    message.instancesWasCreated = [];
                    for (var i = 0; i < object.instancesWasCreated.length; ++i) {
                        if (typeof object.instancesWasCreated[i] !== "object")
                            throw TypeError(".ca2events.Event.instancesWasCreated: object expected");
                        message.instancesWasCreated[i] = $root.ca2events.InstanceWasCreatedInfo.fromObject(object.instancesWasCreated[i]);
                    }
                }
                if (object.payForInstances) {
                    if (!Array.isArray(object.payForInstances))
                        throw TypeError(".ca2events.Event.payForInstances: array expected");
                    message.payForInstances = [];
                    for (var i = 0; i < object.payForInstances.length; ++i) {
                        if (typeof object.payForInstances[i] !== "object")
                            throw TypeError(".ca2events.Event.payForInstances: object expected");
                        message.payForInstances[i] = $root.ca2events.PayForInstanceInfo.fromObject(object.payForInstances[i]);
                    }
                }
                if (object.newBalanceOperations) {
                    if (!Array.isArray(object.newBalanceOperations))
                        throw TypeError(".ca2events.Event.newBalanceOperations: array expected");
                    message.newBalanceOperations = [];
                    for (var i = 0; i < object.newBalanceOperations.length; ++i) {
                        if (typeof object.newBalanceOperations[i] !== "object")
                            throw TypeError(".ca2events.Event.newBalanceOperations: object expected");
                        message.newBalanceOperations[i] = $root.ca2events.BalanceOperationInfo.fromObject(object.newBalanceOperations[i]);
                    }
                }
                if (object.newPaymentUpdates) {
                    if (!Array.isArray(object.newPaymentUpdates))
                        throw TypeError(".ca2events.Event.newPaymentUpdates: array expected");
                    message.newPaymentUpdates = [];
                    for (var i = 0; i < object.newPaymentUpdates.length; ++i) {
                        if (typeof object.newPaymentUpdates[i] !== "object")
                            throw TypeError(".ca2events.Event.newPaymentUpdates: object expected");
                        message.newPaymentUpdates[i] = $root.ca2events.PaymentUpdateInfo.fromObject(object.newPaymentUpdates[i]);
                    }
                }
                if (object.newUsers) {
                    if (!Array.isArray(object.newUsers))
                        throw TypeError(".ca2events.Event.newUsers: array expected");
                    message.newUsers = [];
                    for (var i = 0; i < object.newUsers.length; ++i) {
                        if (typeof object.newUsers[i] !== "object")
                            throw TypeError(".ca2events.Event.newUsers: object expected");
                        message.newUsers[i] = $root.ca2events.NewUserInfo.fromObject(object.newUsers[i]);
                    }
                }
                if (object.newWsConnections) {
                    if (!Array.isArray(object.newWsConnections))
                        throw TypeError(".ca2events.Event.newWsConnections: array expected");
                    message.newWsConnections = [];
                    for (var i = 0; i < object.newWsConnections.length; ++i) {
                        if (typeof object.newWsConnections[i] !== "object")
                            throw TypeError(".ca2events.Event.newWsConnections: object expected");
                        message.newWsConnections[i] = $root.ca2events.NewWsConnectionInfo.fromObject(object.newWsConnections[i]);
                    }
                }
                if (object.newVerificationCodes) {
                    if (!Array.isArray(object.newVerificationCodes))
                        throw TypeError(".ca2events.Event.newVerificationCodes: array expected");
                    message.newVerificationCodes = [];
                    for (var i = 0; i < object.newVerificationCodes.length; ++i) {
                        if (typeof object.newVerificationCodes[i] !== "object")
                            throw TypeError(".ca2events.Event.newVerificationCodes: object expected");
                        message.newVerificationCodes[i] = $root.ca2events.VerificationCodeInfo.fromObject(object.newVerificationCodes[i]);
                    }
                }
                if (object.newCheckDomains) {
                    if (!Array.isArray(object.newCheckDomains))
                        throw TypeError(".ca2events.Event.newCheckDomains: array expected");
                    message.newCheckDomains = [];
                    for (var i = 0; i < object.newCheckDomains.length; ++i) {
                        if (typeof object.newCheckDomains[i] !== "object")
                            throw TypeError(".ca2events.Event.newCheckDomains: object expected");
                        message.newCheckDomains[i] = $root.ca2events.NewCheckDomainInfo.fromObject(object.newCheckDomains[i]);
                    }
                }
                if (object.newRegisterDomains) {
                    if (!Array.isArray(object.newRegisterDomains))
                        throw TypeError(".ca2events.Event.newRegisterDomains: array expected");
                    message.newRegisterDomains = [];
                    for (var i = 0; i < object.newRegisterDomains.length; ++i) {
                        if (typeof object.newRegisterDomains[i] !== "object")
                            throw TypeError(".ca2events.Event.newRegisterDomains: object expected");
                        message.newRegisterDomains[i] = $root.ca2events.NewRegisterDomainInfo.fromObject(object.newRegisterDomains[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an Event message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.Event
             * @static
             * @param {ca2events.Event} message Event
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Event.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.newUsers = [];
                    object.wsEvents = [];
                    object.createInstances = [];
                    object.newBalanceOperations = [];
                    object.newVerificationCodes = [];
                    object.newWsConnections = [];
                    object.broadcastInstances = [];
                    object.deleteInstances = [];
                    object.stopInstances = [];
                    object.restartInstances = [];
                    object.startInstances = [];
                    object.reinstallInstances = [];
                    object.instancesWasCreated = [];
                    object.payForInstances = [];
                    object.newEmailNotifications = [];
                    object.markInstancesAsDeleted = [];
                    object.newCheckDomains = [];
                    object.newRegisterDomains = [];
                    object.newPaymentUpdates = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.userId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.userId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (typeof message.userId === "number")
                        object.userId = options.longs === String ? String(message.userId) : message.userId;
                    else
                        object.userId = options.longs === String ? $util.Long.prototype.toString.call(message.userId) : options.longs === Number ? new $util.LongBits(message.userId.low >>> 0, message.userId.high >>> 0).toNumber(true) : message.userId;
                if (message.newUsers && message.newUsers.length) {
                    object.newUsers = [];
                    for (var j = 0; j < message.newUsers.length; ++j)
                        object.newUsers[j] = $root.ca2events.NewUserInfo.toObject(message.newUsers[j], options);
                }
                if (message.wsEvents && message.wsEvents.length) {
                    object.wsEvents = [];
                    for (var j = 0; j < message.wsEvents.length; ++j)
                        object.wsEvents[j] = $root.ca2.WsEvent.toObject(message.wsEvents[j], options);
                }
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                if (message.createInstances && message.createInstances.length) {
                    object.createInstances = [];
                    for (var j = 0; j < message.createInstances.length; ++j)
                        object.createInstances[j] = $root.ca2events.CreateInstanceInfo.toObject(message.createInstances[j], options);
                }
                if (message.newBalanceOperations && message.newBalanceOperations.length) {
                    object.newBalanceOperations = [];
                    for (var j = 0; j < message.newBalanceOperations.length; ++j)
                        object.newBalanceOperations[j] = $root.ca2events.BalanceOperationInfo.toObject(message.newBalanceOperations[j], options);
                }
                if (message.newVerificationCodes && message.newVerificationCodes.length) {
                    object.newVerificationCodes = [];
                    for (var j = 0; j < message.newVerificationCodes.length; ++j)
                        object.newVerificationCodes[j] = $root.ca2events.VerificationCodeInfo.toObject(message.newVerificationCodes[j], options);
                }
                if (message.newWsConnections && message.newWsConnections.length) {
                    object.newWsConnections = [];
                    for (var j = 0; j < message.newWsConnections.length; ++j)
                        object.newWsConnections[j] = $root.ca2events.NewWsConnectionInfo.toObject(message.newWsConnections[j], options);
                }
                if (message.broadcastInstances && message.broadcastInstances.length) {
                    object.broadcastInstances = [];
                    for (var j = 0; j < message.broadcastInstances.length; ++j)
                        object.broadcastInstances[j] = $root.ca2events.BroadcastInstanceInfo.toObject(message.broadcastInstances[j], options);
                }
                if (message.deleteInstances && message.deleteInstances.length) {
                    object.deleteInstances = [];
                    for (var j = 0; j < message.deleteInstances.length; ++j)
                        object.deleteInstances[j] = $root.ca2events.DeleteInstanceInfo.toObject(message.deleteInstances[j], options);
                }
                if (message.stopInstances && message.stopInstances.length) {
                    object.stopInstances = [];
                    for (var j = 0; j < message.stopInstances.length; ++j)
                        object.stopInstances[j] = $root.ca2events.StopInstanceInfo.toObject(message.stopInstances[j], options);
                }
                if (message.restartInstances && message.restartInstances.length) {
                    object.restartInstances = [];
                    for (var j = 0; j < message.restartInstances.length; ++j)
                        object.restartInstances[j] = $root.ca2events.RestartInstanceInfo.toObject(message.restartInstances[j], options);
                }
                if (message.startInstances && message.startInstances.length) {
                    object.startInstances = [];
                    for (var j = 0; j < message.startInstances.length; ++j)
                        object.startInstances[j] = $root.ca2events.StartInstanceInfo.toObject(message.startInstances[j], options);
                }
                if (message.reinstallInstances && message.reinstallInstances.length) {
                    object.reinstallInstances = [];
                    for (var j = 0; j < message.reinstallInstances.length; ++j)
                        object.reinstallInstances[j] = $root.ca2events.ReinstallInstanceInfo.toObject(message.reinstallInstances[j], options);
                }
                if (message.instancesWasCreated && message.instancesWasCreated.length) {
                    object.instancesWasCreated = [];
                    for (var j = 0; j < message.instancesWasCreated.length; ++j)
                        object.instancesWasCreated[j] = $root.ca2events.InstanceWasCreatedInfo.toObject(message.instancesWasCreated[j], options);
                }
                if (message.payForInstances && message.payForInstances.length) {
                    object.payForInstances = [];
                    for (var j = 0; j < message.payForInstances.length; ++j)
                        object.payForInstances[j] = $root.ca2events.PayForInstanceInfo.toObject(message.payForInstances[j], options);
                }
                if (message.newEmailNotifications && message.newEmailNotifications.length) {
                    object.newEmailNotifications = [];
                    for (var j = 0; j < message.newEmailNotifications.length; ++j)
                        object.newEmailNotifications[j] = $root.ca2events.NewEmailNotificationInfo.toObject(message.newEmailNotifications[j], options);
                }
                if (message.markInstancesAsDeleted && message.markInstancesAsDeleted.length) {
                    object.markInstancesAsDeleted = [];
                    for (var j = 0; j < message.markInstancesAsDeleted.length; ++j)
                        object.markInstancesAsDeleted[j] = $root.ca2events.MarkInstanceAsDeletedInfo.toObject(message.markInstancesAsDeleted[j], options);
                }
                if (message.newCheckDomains && message.newCheckDomains.length) {
                    object.newCheckDomains = [];
                    for (var j = 0; j < message.newCheckDomains.length; ++j)
                        object.newCheckDomains[j] = $root.ca2events.NewCheckDomainInfo.toObject(message.newCheckDomains[j], options);
                }
                if (message.newRegisterDomains && message.newRegisterDomains.length) {
                    object.newRegisterDomains = [];
                    for (var j = 0; j < message.newRegisterDomains.length; ++j)
                        object.newRegisterDomains[j] = $root.ca2events.NewRegisterDomainInfo.toObject(message.newRegisterDomains[j], options);
                }
                if (message.newPaymentUpdates && message.newPaymentUpdates.length) {
                    object.newPaymentUpdates = [];
                    for (var j = 0; j < message.newPaymentUpdates.length; ++j)
                        object.newPaymentUpdates[j] = $root.ca2events.PaymentUpdateInfo.toObject(message.newPaymentUpdates[j], options);
                }
                return object;
            };
    
            /**
             * Converts this Event to JSON.
             * @function toJSON
             * @memberof ca2events.Event
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Event.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Event
             * @function getTypeUrl
             * @memberof ca2events.Event
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Event.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.Event";
            };
    
            return Event;
        })();
    
        ca2events.NewEmailNotificationInfo = (function() {
    
            /**
             * Properties of a NewEmailNotificationInfo.
             * @memberof ca2events
             * @interface INewEmailNotificationInfo
             * @property {string|null} [email] NewEmailNotificationInfo email
             * @property {string|null} [subject] NewEmailNotificationInfo subject
             * @property {string|null} [body] NewEmailNotificationInfo body
             */
    
            /**
             * Constructs a new NewEmailNotificationInfo.
             * @memberof ca2events
             * @classdesc Represents a NewEmailNotificationInfo.
             * @implements INewEmailNotificationInfo
             * @constructor
             * @param {ca2events.INewEmailNotificationInfo=} [properties] Properties to set
             */
            function NewEmailNotificationInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * NewEmailNotificationInfo email.
             * @member {string} email
             * @memberof ca2events.NewEmailNotificationInfo
             * @instance
             */
            NewEmailNotificationInfo.prototype.email = "";
    
            /**
             * NewEmailNotificationInfo subject.
             * @member {string} subject
             * @memberof ca2events.NewEmailNotificationInfo
             * @instance
             */
            NewEmailNotificationInfo.prototype.subject = "";
    
            /**
             * NewEmailNotificationInfo body.
             * @member {string} body
             * @memberof ca2events.NewEmailNotificationInfo
             * @instance
             */
            NewEmailNotificationInfo.prototype.body = "";
    
            /**
             * Creates a new NewEmailNotificationInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {ca2events.INewEmailNotificationInfo=} [properties] Properties to set
             * @returns {ca2events.NewEmailNotificationInfo} NewEmailNotificationInfo instance
             */
            NewEmailNotificationInfo.create = function create(properties) {
                return new NewEmailNotificationInfo(properties);
            };
    
            /**
             * Encodes the specified NewEmailNotificationInfo message. Does not implicitly {@link ca2events.NewEmailNotificationInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {ca2events.INewEmailNotificationInfo} message NewEmailNotificationInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewEmailNotificationInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.subject != null && Object.hasOwnProperty.call(message, "subject"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.subject);
                if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.body);
                return writer;
            };
    
            /**
             * Encodes the specified NewEmailNotificationInfo message, length delimited. Does not implicitly {@link ca2events.NewEmailNotificationInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {ca2events.INewEmailNotificationInfo} message NewEmailNotificationInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewEmailNotificationInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a NewEmailNotificationInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.NewEmailNotificationInfo} NewEmailNotificationInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewEmailNotificationInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.NewEmailNotificationInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.subject = reader.string();
                            break;
                        }
                    case 3: {
                            message.body = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a NewEmailNotificationInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.NewEmailNotificationInfo} NewEmailNotificationInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewEmailNotificationInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a NewEmailNotificationInfo message.
             * @function verify
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewEmailNotificationInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.subject != null && message.hasOwnProperty("subject"))
                    if (!$util.isString(message.subject))
                        return "subject: string expected";
                if (message.body != null && message.hasOwnProperty("body"))
                    if (!$util.isString(message.body))
                        return "body: string expected";
                return null;
            };
    
            /**
             * Creates a NewEmailNotificationInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.NewEmailNotificationInfo} NewEmailNotificationInfo
             */
            NewEmailNotificationInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.NewEmailNotificationInfo)
                    return object;
                var message = new $root.ca2events.NewEmailNotificationInfo();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.subject != null)
                    message.subject = String(object.subject);
                if (object.body != null)
                    message.body = String(object.body);
                return message;
            };
    
            /**
             * Creates a plain object from a NewEmailNotificationInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {ca2events.NewEmailNotificationInfo} message NewEmailNotificationInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewEmailNotificationInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.subject = "";
                    object.body = "";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.subject != null && message.hasOwnProperty("subject"))
                    object.subject = message.subject;
                if (message.body != null && message.hasOwnProperty("body"))
                    object.body = message.body;
                return object;
            };
    
            /**
             * Converts this NewEmailNotificationInfo to JSON.
             * @function toJSON
             * @memberof ca2events.NewEmailNotificationInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewEmailNotificationInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for NewEmailNotificationInfo
             * @function getTypeUrl
             * @memberof ca2events.NewEmailNotificationInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewEmailNotificationInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.NewEmailNotificationInfo";
            };
    
            return NewEmailNotificationInfo;
        })();
    
        ca2events.NewWsConnectionInfo = (function() {
    
            /**
             * Properties of a NewWsConnectionInfo.
             * @memberof ca2events
             * @interface INewWsConnectionInfo
             * @property {Long|null} [sessionId] NewWsConnectionInfo sessionId
             */
    
            /**
             * Constructs a new NewWsConnectionInfo.
             * @memberof ca2events
             * @classdesc Represents a NewWsConnectionInfo.
             * @implements INewWsConnectionInfo
             * @constructor
             * @param {ca2events.INewWsConnectionInfo=} [properties] Properties to set
             */
            function NewWsConnectionInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * NewWsConnectionInfo sessionId.
             * @member {Long} sessionId
             * @memberof ca2events.NewWsConnectionInfo
             * @instance
             */
            NewWsConnectionInfo.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Creates a new NewWsConnectionInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {ca2events.INewWsConnectionInfo=} [properties] Properties to set
             * @returns {ca2events.NewWsConnectionInfo} NewWsConnectionInfo instance
             */
            NewWsConnectionInfo.create = function create(properties) {
                return new NewWsConnectionInfo(properties);
            };
    
            /**
             * Encodes the specified NewWsConnectionInfo message. Does not implicitly {@link ca2events.NewWsConnectionInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {ca2events.INewWsConnectionInfo} message NewWsConnectionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewWsConnectionInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sessionId);
                return writer;
            };
    
            /**
             * Encodes the specified NewWsConnectionInfo message, length delimited. Does not implicitly {@link ca2events.NewWsConnectionInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {ca2events.INewWsConnectionInfo} message NewWsConnectionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewWsConnectionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a NewWsConnectionInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.NewWsConnectionInfo} NewWsConnectionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewWsConnectionInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.NewWsConnectionInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.sessionId = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a NewWsConnectionInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.NewWsConnectionInfo} NewWsConnectionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewWsConnectionInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a NewWsConnectionInfo message.
             * @function verify
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewWsConnectionInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                        return "sessionId: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a NewWsConnectionInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.NewWsConnectionInfo} NewWsConnectionInfo
             */
            NewWsConnectionInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.NewWsConnectionInfo)
                    return object;
                var message = new $root.ca2events.NewWsConnectionInfo();
                if (object.sessionId != null)
                    if ($util.Long)
                        (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = true;
                    else if (typeof object.sessionId === "string")
                        message.sessionId = parseInt(object.sessionId, 10);
                    else if (typeof object.sessionId === "number")
                        message.sessionId = object.sessionId;
                    else if (typeof object.sessionId === "object")
                        message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber(true);
                return message;
            };
    
            /**
             * Creates a plain object from a NewWsConnectionInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {ca2events.NewWsConnectionInfo} message NewWsConnectionInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewWsConnectionInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.sessionId = options.longs === String ? "0" : 0;
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (typeof message.sessionId === "number")
                        object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                    else
                        object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber(true) : message.sessionId;
                return object;
            };
    
            /**
             * Converts this NewWsConnectionInfo to JSON.
             * @function toJSON
             * @memberof ca2events.NewWsConnectionInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewWsConnectionInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for NewWsConnectionInfo
             * @function getTypeUrl
             * @memberof ca2events.NewWsConnectionInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewWsConnectionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.NewWsConnectionInfo";
            };
    
            return NewWsConnectionInfo;
        })();
    
        ca2events.NewUserInfo = (function() {
    
            /**
             * Properties of a NewUserInfo.
             * @memberof ca2events
             * @interface INewUserInfo
             * @property {Long|null} [id] NewUserInfo id
             * @property {string|null} [email] NewUserInfo email
             */
    
            /**
             * Constructs a new NewUserInfo.
             * @memberof ca2events
             * @classdesc Represents a NewUserInfo.
             * @implements INewUserInfo
             * @constructor
             * @param {ca2events.INewUserInfo=} [properties] Properties to set
             */
            function NewUserInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * NewUserInfo id.
             * @member {Long} id
             * @memberof ca2events.NewUserInfo
             * @instance
             */
            NewUserInfo.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * NewUserInfo email.
             * @member {string} email
             * @memberof ca2events.NewUserInfo
             * @instance
             */
            NewUserInfo.prototype.email = "";
    
            /**
             * Creates a new NewUserInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {ca2events.INewUserInfo=} [properties] Properties to set
             * @returns {ca2events.NewUserInfo} NewUserInfo instance
             */
            NewUserInfo.create = function create(properties) {
                return new NewUserInfo(properties);
            };
    
            /**
             * Encodes the specified NewUserInfo message. Does not implicitly {@link ca2events.NewUserInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {ca2events.INewUserInfo} message NewUserInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewUserInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                return writer;
            };
    
            /**
             * Encodes the specified NewUserInfo message, length delimited. Does not implicitly {@link ca2events.NewUserInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {ca2events.INewUserInfo} message NewUserInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewUserInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a NewUserInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.NewUserInfo} NewUserInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewUserInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.NewUserInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.email = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a NewUserInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.NewUserInfo} NewUserInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewUserInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a NewUserInfo message.
             * @function verify
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewUserInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                return null;
            };
    
            /**
             * Creates a NewUserInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.NewUserInfo} NewUserInfo
             */
            NewUserInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.NewUserInfo)
                    return object;
                var message = new $root.ca2events.NewUserInfo();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.email != null)
                    message.email = String(object.email);
                return message;
            };
    
            /**
             * Creates a plain object from a NewUserInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {ca2events.NewUserInfo} message NewUserInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewUserInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.email = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };
    
            /**
             * Converts this NewUserInfo to JSON.
             * @function toJSON
             * @memberof ca2events.NewUserInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewUserInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for NewUserInfo
             * @function getTypeUrl
             * @memberof ca2events.NewUserInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewUserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.NewUserInfo";
            };
    
            return NewUserInfo;
        })();
    
        ca2events.VerificationCodeInfo = (function() {
    
            /**
             * Properties of a VerificationCodeInfo.
             * @memberof ca2events
             * @interface IVerificationCodeInfo
             * @property {string|null} [email] VerificationCodeInfo email
             * @property {string|null} [code] VerificationCodeInfo code
             */
    
            /**
             * Constructs a new VerificationCodeInfo.
             * @memberof ca2events
             * @classdesc Represents a VerificationCodeInfo.
             * @implements IVerificationCodeInfo
             * @constructor
             * @param {ca2events.IVerificationCodeInfo=} [properties] Properties to set
             */
            function VerificationCodeInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VerificationCodeInfo email.
             * @member {string} email
             * @memberof ca2events.VerificationCodeInfo
             * @instance
             */
            VerificationCodeInfo.prototype.email = "";
    
            /**
             * VerificationCodeInfo code.
             * @member {string} code
             * @memberof ca2events.VerificationCodeInfo
             * @instance
             */
            VerificationCodeInfo.prototype.code = "";
    
            /**
             * Creates a new VerificationCodeInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {ca2events.IVerificationCodeInfo=} [properties] Properties to set
             * @returns {ca2events.VerificationCodeInfo} VerificationCodeInfo instance
             */
            VerificationCodeInfo.create = function create(properties) {
                return new VerificationCodeInfo(properties);
            };
    
            /**
             * Encodes the specified VerificationCodeInfo message. Does not implicitly {@link ca2events.VerificationCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {ca2events.IVerificationCodeInfo} message VerificationCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerificationCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                return writer;
            };
    
            /**
             * Encodes the specified VerificationCodeInfo message, length delimited. Does not implicitly {@link ca2events.VerificationCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {ca2events.IVerificationCodeInfo} message VerificationCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerificationCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VerificationCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.VerificationCodeInfo} VerificationCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerificationCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.VerificationCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.code = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VerificationCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.VerificationCodeInfo} VerificationCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerificationCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VerificationCodeInfo message.
             * @function verify
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerificationCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                return null;
            };
    
            /**
             * Creates a VerificationCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.VerificationCodeInfo} VerificationCodeInfo
             */
            VerificationCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.VerificationCodeInfo)
                    return object;
                var message = new $root.ca2events.VerificationCodeInfo();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.code != null)
                    message.code = String(object.code);
                return message;
            };
    
            /**
             * Creates a plain object from a VerificationCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {ca2events.VerificationCodeInfo} message VerificationCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerificationCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.code = "";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };
    
            /**
             * Converts this VerificationCodeInfo to JSON.
             * @function toJSON
             * @memberof ca2events.VerificationCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerificationCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VerificationCodeInfo
             * @function getTypeUrl
             * @memberof ca2events.VerificationCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VerificationCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.VerificationCodeInfo";
            };
    
            return VerificationCodeInfo;
        })();
    
        ca2events.InstanceWasCreatedInfo = (function() {
    
            /**
             * Properties of an InstanceWasCreatedInfo.
             * @memberof ca2events
             * @interface IInstanceWasCreatedInfo
             * @property {number|null} [instanceId] InstanceWasCreatedInfo instanceId
             */
    
            /**
             * Constructs a new InstanceWasCreatedInfo.
             * @memberof ca2events
             * @classdesc Represents an InstanceWasCreatedInfo.
             * @implements IInstanceWasCreatedInfo
             * @constructor
             * @param {ca2events.IInstanceWasCreatedInfo=} [properties] Properties to set
             */
            function InstanceWasCreatedInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * InstanceWasCreatedInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.InstanceWasCreatedInfo
             * @instance
             */
            InstanceWasCreatedInfo.prototype.instanceId = 0;
    
            /**
             * Creates a new InstanceWasCreatedInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {ca2events.IInstanceWasCreatedInfo=} [properties] Properties to set
             * @returns {ca2events.InstanceWasCreatedInfo} InstanceWasCreatedInfo instance
             */
            InstanceWasCreatedInfo.create = function create(properties) {
                return new InstanceWasCreatedInfo(properties);
            };
    
            /**
             * Encodes the specified InstanceWasCreatedInfo message. Does not implicitly {@link ca2events.InstanceWasCreatedInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {ca2events.IInstanceWasCreatedInfo} message InstanceWasCreatedInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceWasCreatedInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified InstanceWasCreatedInfo message, length delimited. Does not implicitly {@link ca2events.InstanceWasCreatedInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {ca2events.IInstanceWasCreatedInfo} message InstanceWasCreatedInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceWasCreatedInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an InstanceWasCreatedInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.InstanceWasCreatedInfo} InstanceWasCreatedInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceWasCreatedInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.InstanceWasCreatedInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an InstanceWasCreatedInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.InstanceWasCreatedInfo} InstanceWasCreatedInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceWasCreatedInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an InstanceWasCreatedInfo message.
             * @function verify
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InstanceWasCreatedInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates an InstanceWasCreatedInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.InstanceWasCreatedInfo} InstanceWasCreatedInfo
             */
            InstanceWasCreatedInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.InstanceWasCreatedInfo)
                    return object;
                var message = new $root.ca2events.InstanceWasCreatedInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an InstanceWasCreatedInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {ca2events.InstanceWasCreatedInfo} message InstanceWasCreatedInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InstanceWasCreatedInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this InstanceWasCreatedInfo to JSON.
             * @function toJSON
             * @memberof ca2events.InstanceWasCreatedInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InstanceWasCreatedInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for InstanceWasCreatedInfo
             * @function getTypeUrl
             * @memberof ca2events.InstanceWasCreatedInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InstanceWasCreatedInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.InstanceWasCreatedInfo";
            };
    
            return InstanceWasCreatedInfo;
        })();
    
        ca2events.PaymentUpdateInfo = (function() {
    
            /**
             * Properties of a PaymentUpdateInfo.
             * @memberof ca2events
             * @interface IPaymentUpdateInfo
             * @property {Long|null} [paymentId] PaymentUpdateInfo paymentId
             * @property {string|null} [foreignId] PaymentUpdateInfo foreignId
             * @property {ca2types.PaymentStatus|null} [status] PaymentUpdateInfo status
             * @property {string|null} [currencySentCurrency] PaymentUpdateInfo currencySentCurrency
             * @property {string|null} [currencySentAmount] PaymentUpdateInfo currencySentAmount
             * @property {string|null} [currencyReceivedCurrency] PaymentUpdateInfo currencyReceivedCurrency
             * @property {string|null} [currencyReceivedAmount] PaymentUpdateInfo currencyReceivedAmount
             * @property {string|null} [currencyReceivedAmountMinusFee] PaymentUpdateInfo currencyReceivedAmountMinusFee
             * @property {number|null} [rateUsd] PaymentUpdateInfo rateUsd
             */
    
            /**
             * Constructs a new PaymentUpdateInfo.
             * @memberof ca2events
             * @classdesc Represents a PaymentUpdateInfo.
             * @implements IPaymentUpdateInfo
             * @constructor
             * @param {ca2events.IPaymentUpdateInfo=} [properties] Properties to set
             */
            function PaymentUpdateInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PaymentUpdateInfo paymentId.
             * @member {Long} paymentId
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.paymentId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * PaymentUpdateInfo foreignId.
             * @member {string} foreignId
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.foreignId = "";
    
            /**
             * PaymentUpdateInfo status.
             * @member {ca2types.PaymentStatus} status
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.status = 0;
    
            /**
             * PaymentUpdateInfo currencySentCurrency.
             * @member {string} currencySentCurrency
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.currencySentCurrency = "";
    
            /**
             * PaymentUpdateInfo currencySentAmount.
             * @member {string} currencySentAmount
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.currencySentAmount = "";
    
            /**
             * PaymentUpdateInfo currencyReceivedCurrency.
             * @member {string} currencyReceivedCurrency
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.currencyReceivedCurrency = "";
    
            /**
             * PaymentUpdateInfo currencyReceivedAmount.
             * @member {string} currencyReceivedAmount
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.currencyReceivedAmount = "";
    
            /**
             * PaymentUpdateInfo currencyReceivedAmountMinusFee.
             * @member {string} currencyReceivedAmountMinusFee
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.currencyReceivedAmountMinusFee = "";
    
            /**
             * PaymentUpdateInfo rateUsd.
             * @member {number} rateUsd
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             */
            PaymentUpdateInfo.prototype.rateUsd = 0;
    
            /**
             * Creates a new PaymentUpdateInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {ca2events.IPaymentUpdateInfo=} [properties] Properties to set
             * @returns {ca2events.PaymentUpdateInfo} PaymentUpdateInfo instance
             */
            PaymentUpdateInfo.create = function create(properties) {
                return new PaymentUpdateInfo(properties);
            };
    
            /**
             * Encodes the specified PaymentUpdateInfo message. Does not implicitly {@link ca2events.PaymentUpdateInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {ca2events.IPaymentUpdateInfo} message PaymentUpdateInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentUpdateInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.paymentId != null && Object.hasOwnProperty.call(message, "paymentId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.paymentId);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                if (message.currencySentCurrency != null && Object.hasOwnProperty.call(message, "currencySentCurrency"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.currencySentCurrency);
                if (message.currencySentAmount != null && Object.hasOwnProperty.call(message, "currencySentAmount"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.currencySentAmount);
                if (message.currencyReceivedCurrency != null && Object.hasOwnProperty.call(message, "currencyReceivedCurrency"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.currencyReceivedCurrency);
                if (message.currencyReceivedAmount != null && Object.hasOwnProperty.call(message, "currencyReceivedAmount"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.currencyReceivedAmount);
                if (message.currencyReceivedAmountMinusFee != null && Object.hasOwnProperty.call(message, "currencyReceivedAmountMinusFee"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.currencyReceivedAmountMinusFee);
                if (message.rateUsd != null && Object.hasOwnProperty.call(message, "rateUsd"))
                    writer.uint32(/* id 8, wireType 5 =*/69).float(message.rateUsd);
                if (message.foreignId != null && Object.hasOwnProperty.call(message, "foreignId"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.foreignId);
                return writer;
            };
    
            /**
             * Encodes the specified PaymentUpdateInfo message, length delimited. Does not implicitly {@link ca2events.PaymentUpdateInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {ca2events.IPaymentUpdateInfo} message PaymentUpdateInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentUpdateInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PaymentUpdateInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.PaymentUpdateInfo} PaymentUpdateInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentUpdateInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.PaymentUpdateInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.paymentId = reader.uint64();
                            break;
                        }
                    case 9: {
                            message.foreignId = reader.string();
                            break;
                        }
                    case 2: {
                            message.status = reader.int32();
                            break;
                        }
                    case 3: {
                            message.currencySentCurrency = reader.string();
                            break;
                        }
                    case 4: {
                            message.currencySentAmount = reader.string();
                            break;
                        }
                    case 5: {
                            message.currencyReceivedCurrency = reader.string();
                            break;
                        }
                    case 6: {
                            message.currencyReceivedAmount = reader.string();
                            break;
                        }
                    case 7: {
                            message.currencyReceivedAmountMinusFee = reader.string();
                            break;
                        }
                    case 8: {
                            message.rateUsd = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PaymentUpdateInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.PaymentUpdateInfo} PaymentUpdateInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentUpdateInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PaymentUpdateInfo message.
             * @function verify
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaymentUpdateInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.paymentId != null && message.hasOwnProperty("paymentId"))
                    if (!$util.isInteger(message.paymentId) && !(message.paymentId && $util.isInteger(message.paymentId.low) && $util.isInteger(message.paymentId.high)))
                        return "paymentId: integer|Long expected";
                if (message.foreignId != null && message.hasOwnProperty("foreignId"))
                    if (!$util.isString(message.foreignId))
                        return "foreignId: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.currencySentCurrency != null && message.hasOwnProperty("currencySentCurrency"))
                    if (!$util.isString(message.currencySentCurrency))
                        return "currencySentCurrency: string expected";
                if (message.currencySentAmount != null && message.hasOwnProperty("currencySentAmount"))
                    if (!$util.isString(message.currencySentAmount))
                        return "currencySentAmount: string expected";
                if (message.currencyReceivedCurrency != null && message.hasOwnProperty("currencyReceivedCurrency"))
                    if (!$util.isString(message.currencyReceivedCurrency))
                        return "currencyReceivedCurrency: string expected";
                if (message.currencyReceivedAmount != null && message.hasOwnProperty("currencyReceivedAmount"))
                    if (!$util.isString(message.currencyReceivedAmount))
                        return "currencyReceivedAmount: string expected";
                if (message.currencyReceivedAmountMinusFee != null && message.hasOwnProperty("currencyReceivedAmountMinusFee"))
                    if (!$util.isString(message.currencyReceivedAmountMinusFee))
                        return "currencyReceivedAmountMinusFee: string expected";
                if (message.rateUsd != null && message.hasOwnProperty("rateUsd"))
                    if (typeof message.rateUsd !== "number")
                        return "rateUsd: number expected";
                return null;
            };
    
            /**
             * Creates a PaymentUpdateInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.PaymentUpdateInfo} PaymentUpdateInfo
             */
            PaymentUpdateInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.PaymentUpdateInfo)
                    return object;
                var message = new $root.ca2events.PaymentUpdateInfo();
                if (object.paymentId != null)
                    if ($util.Long)
                        (message.paymentId = $util.Long.fromValue(object.paymentId)).unsigned = true;
                    else if (typeof object.paymentId === "string")
                        message.paymentId = parseInt(object.paymentId, 10);
                    else if (typeof object.paymentId === "number")
                        message.paymentId = object.paymentId;
                    else if (typeof object.paymentId === "object")
                        message.paymentId = new $util.LongBits(object.paymentId.low >>> 0, object.paymentId.high >>> 0).toNumber(true);
                if (object.foreignId != null)
                    message.foreignId = String(object.foreignId);
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "PST_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "PST_PENDING":
                case 1:
                    message.status = 1;
                    break;
                case "PST_SUCCESS":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.currencySentCurrency != null)
                    message.currencySentCurrency = String(object.currencySentCurrency);
                if (object.currencySentAmount != null)
                    message.currencySentAmount = String(object.currencySentAmount);
                if (object.currencyReceivedCurrency != null)
                    message.currencyReceivedCurrency = String(object.currencyReceivedCurrency);
                if (object.currencyReceivedAmount != null)
                    message.currencyReceivedAmount = String(object.currencyReceivedAmount);
                if (object.currencyReceivedAmountMinusFee != null)
                    message.currencyReceivedAmountMinusFee = String(object.currencyReceivedAmountMinusFee);
                if (object.rateUsd != null)
                    message.rateUsd = Number(object.rateUsd);
                return message;
            };
    
            /**
             * Creates a plain object from a PaymentUpdateInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {ca2events.PaymentUpdateInfo} message PaymentUpdateInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaymentUpdateInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.paymentId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.paymentId = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "PST_UNKNOWN" : 0;
                    object.currencySentCurrency = "";
                    object.currencySentAmount = "";
                    object.currencyReceivedCurrency = "";
                    object.currencyReceivedAmount = "";
                    object.currencyReceivedAmountMinusFee = "";
                    object.rateUsd = 0;
                    object.foreignId = "";
                }
                if (message.paymentId != null && message.hasOwnProperty("paymentId"))
                    if (typeof message.paymentId === "number")
                        object.paymentId = options.longs === String ? String(message.paymentId) : message.paymentId;
                    else
                        object.paymentId = options.longs === String ? $util.Long.prototype.toString.call(message.paymentId) : options.longs === Number ? new $util.LongBits(message.paymentId.low >>> 0, message.paymentId.high >>> 0).toNumber(true) : message.paymentId;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.ca2types.PaymentStatus[message.status] === undefined ? message.status : $root.ca2types.PaymentStatus[message.status] : message.status;
                if (message.currencySentCurrency != null && message.hasOwnProperty("currencySentCurrency"))
                    object.currencySentCurrency = message.currencySentCurrency;
                if (message.currencySentAmount != null && message.hasOwnProperty("currencySentAmount"))
                    object.currencySentAmount = message.currencySentAmount;
                if (message.currencyReceivedCurrency != null && message.hasOwnProperty("currencyReceivedCurrency"))
                    object.currencyReceivedCurrency = message.currencyReceivedCurrency;
                if (message.currencyReceivedAmount != null && message.hasOwnProperty("currencyReceivedAmount"))
                    object.currencyReceivedAmount = message.currencyReceivedAmount;
                if (message.currencyReceivedAmountMinusFee != null && message.hasOwnProperty("currencyReceivedAmountMinusFee"))
                    object.currencyReceivedAmountMinusFee = message.currencyReceivedAmountMinusFee;
                if (message.rateUsd != null && message.hasOwnProperty("rateUsd"))
                    object.rateUsd = options.json && !isFinite(message.rateUsd) ? String(message.rateUsd) : message.rateUsd;
                if (message.foreignId != null && message.hasOwnProperty("foreignId"))
                    object.foreignId = message.foreignId;
                return object;
            };
    
            /**
             * Converts this PaymentUpdateInfo to JSON.
             * @function toJSON
             * @memberof ca2events.PaymentUpdateInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaymentUpdateInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PaymentUpdateInfo
             * @function getTypeUrl
             * @memberof ca2events.PaymentUpdateInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaymentUpdateInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.PaymentUpdateInfo";
            };
    
            return PaymentUpdateInfo;
        })();
    
        ca2events.BalanceOperationInfo = (function() {
    
            /**
             * Properties of a BalanceOperationInfo.
             * @memberof ca2events
             * @interface IBalanceOperationInfo
             * @property {ca2types.BalanceType|null} [balanceType] BalanceOperationInfo balanceType
             * @property {Long|null} [amountPoints] BalanceOperationInfo amountPoints
             * @property {ca2types.BalanceOperationType|null} [operationType] BalanceOperationInfo operationType
             * @property {number|null} [instanceId] BalanceOperationInfo instanceId
             * @property {Long|null} [actorId] BalanceOperationInfo actorId
             */
    
            /**
             * Constructs a new BalanceOperationInfo.
             * @memberof ca2events
             * @classdesc Represents a BalanceOperationInfo.
             * @implements IBalanceOperationInfo
             * @constructor
             * @param {ca2events.IBalanceOperationInfo=} [properties] Properties to set
             */
            function BalanceOperationInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalanceOperationInfo balanceType.
             * @member {ca2types.BalanceType} balanceType
             * @memberof ca2events.BalanceOperationInfo
             * @instance
             */
            BalanceOperationInfo.prototype.balanceType = 0;
    
            /**
             * BalanceOperationInfo amountPoints.
             * @member {Long} amountPoints
             * @memberof ca2events.BalanceOperationInfo
             * @instance
             */
            BalanceOperationInfo.prototype.amountPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * BalanceOperationInfo operationType.
             * @member {ca2types.BalanceOperationType} operationType
             * @memberof ca2events.BalanceOperationInfo
             * @instance
             */
            BalanceOperationInfo.prototype.operationType = 0;
    
            /**
             * BalanceOperationInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.BalanceOperationInfo
             * @instance
             */
            BalanceOperationInfo.prototype.instanceId = 0;
    
            /**
             * BalanceOperationInfo actorId.
             * @member {Long} actorId
             * @memberof ca2events.BalanceOperationInfo
             * @instance
             */
            BalanceOperationInfo.prototype.actorId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Creates a new BalanceOperationInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {ca2events.IBalanceOperationInfo=} [properties] Properties to set
             * @returns {ca2events.BalanceOperationInfo} BalanceOperationInfo instance
             */
            BalanceOperationInfo.create = function create(properties) {
                return new BalanceOperationInfo(properties);
            };
    
            /**
             * Encodes the specified BalanceOperationInfo message. Does not implicitly {@link ca2events.BalanceOperationInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {ca2events.IBalanceOperationInfo} message BalanceOperationInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperationInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balanceType != null && Object.hasOwnProperty.call(message, "balanceType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.balanceType);
                if (message.amountPoints != null && Object.hasOwnProperty.call(message, "amountPoints"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amountPoints);
                if (message.operationType != null && Object.hasOwnProperty.call(message, "operationType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.operationType);
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.instanceId);
                if (message.actorId != null && Object.hasOwnProperty.call(message, "actorId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.actorId);
                return writer;
            };
    
            /**
             * Encodes the specified BalanceOperationInfo message, length delimited. Does not implicitly {@link ca2events.BalanceOperationInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {ca2events.IBalanceOperationInfo} message BalanceOperationInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceOperationInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalanceOperationInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.BalanceOperationInfo} BalanceOperationInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperationInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.BalanceOperationInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.balanceType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.amountPoints = reader.int64();
                            break;
                        }
                    case 3: {
                            message.operationType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.actorId = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalanceOperationInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.BalanceOperationInfo} BalanceOperationInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceOperationInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalanceOperationInfo message.
             * @function verify
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalanceOperationInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.balanceType != null && message.hasOwnProperty("balanceType"))
                    switch (message.balanceType) {
                    default:
                        return "balanceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.amountPoints != null && message.hasOwnProperty("amountPoints"))
                    if (!$util.isInteger(message.amountPoints) && !(message.amountPoints && $util.isInteger(message.amountPoints.low) && $util.isInteger(message.amountPoints.high)))
                        return "amountPoints: integer|Long expected";
                if (message.operationType != null && message.hasOwnProperty("operationType"))
                    switch (message.operationType) {
                    default:
                        return "operationType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.actorId != null && message.hasOwnProperty("actorId"))
                    if (!$util.isInteger(message.actorId) && !(message.actorId && $util.isInteger(message.actorId.low) && $util.isInteger(message.actorId.high)))
                        return "actorId: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a BalanceOperationInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.BalanceOperationInfo} BalanceOperationInfo
             */
            BalanceOperationInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.BalanceOperationInfo)
                    return object;
                var message = new $root.ca2events.BalanceOperationInfo();
                switch (object.balanceType) {
                default:
                    if (typeof object.balanceType === "number") {
                        message.balanceType = object.balanceType;
                        break;
                    }
                    break;
                case "BT_UNKNOWN":
                case 0:
                    message.balanceType = 0;
                    break;
                case "BT_MAIN":
                case 1:
                    message.balanceType = 1;
                    break;
                case "BT_BONUS":
                case 2:
                    message.balanceType = 2;
                    break;
                }
                if (object.amountPoints != null)
                    if ($util.Long)
                        (message.amountPoints = $util.Long.fromValue(object.amountPoints)).unsigned = false;
                    else if (typeof object.amountPoints === "string")
                        message.amountPoints = parseInt(object.amountPoints, 10);
                    else if (typeof object.amountPoints === "number")
                        message.amountPoints = object.amountPoints;
                    else if (typeof object.amountPoints === "object")
                        message.amountPoints = new $util.LongBits(object.amountPoints.low >>> 0, object.amountPoints.high >>> 0).toNumber();
                switch (object.operationType) {
                default:
                    if (typeof object.operationType === "number") {
                        message.operationType = object.operationType;
                        break;
                    }
                    break;
                case "BOT_UNKNOWN":
                case 0:
                    message.operationType = 0;
                    break;
                case "BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE":
                case 1:
                    message.operationType = 1;
                    break;
                case "BOT_PROMO_CODE":
                case 2:
                    message.operationType = 2;
                    break;
                case "BOT_REFERRAL_OPERATION":
                case 3:
                    message.operationType = 3;
                    break;
                case "BOT_INSTANCE_HOUR":
                case 4:
                    message.operationType = 4;
                    break;
                case "BOT_ADMIN_CORRECTION":
                case 5:
                    message.operationType = 5;
                    break;
                case "BOT_PAYMENT":
                case 6:
                    message.operationType = 6;
                    break;
                }
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.actorId != null)
                    if ($util.Long)
                        (message.actorId = $util.Long.fromValue(object.actorId)).unsigned = true;
                    else if (typeof object.actorId === "string")
                        message.actorId = parseInt(object.actorId, 10);
                    else if (typeof object.actorId === "number")
                        message.actorId = object.actorId;
                    else if (typeof object.actorId === "object")
                        message.actorId = new $util.LongBits(object.actorId.low >>> 0, object.actorId.high >>> 0).toNumber(true);
                return message;
            };
    
            /**
             * Creates a plain object from a BalanceOperationInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {ca2events.BalanceOperationInfo} message BalanceOperationInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalanceOperationInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.balanceType = options.enums === String ? "BT_UNKNOWN" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.amountPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.amountPoints = options.longs === String ? "0" : 0;
                    object.operationType = options.enums === String ? "BOT_UNKNOWN" : 0;
                    object.instanceId = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.actorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.actorId = options.longs === String ? "0" : 0;
                }
                if (message.balanceType != null && message.hasOwnProperty("balanceType"))
                    object.balanceType = options.enums === String ? $root.ca2types.BalanceType[message.balanceType] === undefined ? message.balanceType : $root.ca2types.BalanceType[message.balanceType] : message.balanceType;
                if (message.amountPoints != null && message.hasOwnProperty("amountPoints"))
                    if (typeof message.amountPoints === "number")
                        object.amountPoints = options.longs === String ? String(message.amountPoints) : message.amountPoints;
                    else
                        object.amountPoints = options.longs === String ? $util.Long.prototype.toString.call(message.amountPoints) : options.longs === Number ? new $util.LongBits(message.amountPoints.low >>> 0, message.amountPoints.high >>> 0).toNumber() : message.amountPoints;
                if (message.operationType != null && message.hasOwnProperty("operationType"))
                    object.operationType = options.enums === String ? $root.ca2types.BalanceOperationType[message.operationType] === undefined ? message.operationType : $root.ca2types.BalanceOperationType[message.operationType] : message.operationType;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.actorId != null && message.hasOwnProperty("actorId"))
                    if (typeof message.actorId === "number")
                        object.actorId = options.longs === String ? String(message.actorId) : message.actorId;
                    else
                        object.actorId = options.longs === String ? $util.Long.prototype.toString.call(message.actorId) : options.longs === Number ? new $util.LongBits(message.actorId.low >>> 0, message.actorId.high >>> 0).toNumber(true) : message.actorId;
                return object;
            };
    
            /**
             * Converts this BalanceOperationInfo to JSON.
             * @function toJSON
             * @memberof ca2events.BalanceOperationInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalanceOperationInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BalanceOperationInfo
             * @function getTypeUrl
             * @memberof ca2events.BalanceOperationInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BalanceOperationInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.BalanceOperationInfo";
            };
    
            return BalanceOperationInfo;
        })();
    
        ca2events.CreateInstanceInfo = (function() {
    
            /**
             * Properties of a CreateInstanceInfo.
             * @memberof ca2events
             * @interface ICreateInstanceInfo
             * @property {number|null} [instanceId] CreateInstanceInfo instanceId
             */
    
            /**
             * Constructs a new CreateInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a CreateInstanceInfo.
             * @implements ICreateInstanceInfo
             * @constructor
             * @param {ca2events.ICreateInstanceInfo=} [properties] Properties to set
             */
            function CreateInstanceInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.CreateInstanceInfo
             * @instance
             */
            CreateInstanceInfo.prototype.instanceId = 0;
    
            /**
             * Creates a new CreateInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {ca2events.ICreateInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.CreateInstanceInfo} CreateInstanceInfo instance
             */
            CreateInstanceInfo.create = function create(properties) {
                return new CreateInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified CreateInstanceInfo message. Does not implicitly {@link ca2events.CreateInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {ca2events.ICreateInstanceInfo} message CreateInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified CreateInstanceInfo message, length delimited. Does not implicitly {@link ca2events.CreateInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {ca2events.ICreateInstanceInfo} message CreateInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.CreateInstanceInfo} CreateInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.CreateInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.CreateInstanceInfo} CreateInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateInstanceInfo message.
             * @function verify
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a CreateInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.CreateInstanceInfo} CreateInstanceInfo
             */
            CreateInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.CreateInstanceInfo)
                    return object;
                var message = new $root.ca2events.CreateInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a CreateInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {ca2events.CreateInstanceInfo} message CreateInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this CreateInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.CreateInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.CreateInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.CreateInstanceInfo";
            };
    
            return CreateInstanceInfo;
        })();
    
        ca2events.BroadcastInstanceInfo = (function() {
    
            /**
             * Properties of a BroadcastInstanceInfo.
             * @memberof ca2events
             * @interface IBroadcastInstanceInfo
             * @property {number|null} [instanceId] BroadcastInstanceInfo instanceId
             */
    
            /**
             * Constructs a new BroadcastInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a BroadcastInstanceInfo.
             * @implements IBroadcastInstanceInfo
             * @constructor
             * @param {ca2events.IBroadcastInstanceInfo=} [properties] Properties to set
             */
            function BroadcastInstanceInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BroadcastInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.BroadcastInstanceInfo
             * @instance
             */
            BroadcastInstanceInfo.prototype.instanceId = 0;
    
            /**
             * Creates a new BroadcastInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {ca2events.IBroadcastInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.BroadcastInstanceInfo} BroadcastInstanceInfo instance
             */
            BroadcastInstanceInfo.create = function create(properties) {
                return new BroadcastInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified BroadcastInstanceInfo message. Does not implicitly {@link ca2events.BroadcastInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {ca2events.IBroadcastInstanceInfo} message BroadcastInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BroadcastInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified BroadcastInstanceInfo message, length delimited. Does not implicitly {@link ca2events.BroadcastInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {ca2events.IBroadcastInstanceInfo} message BroadcastInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BroadcastInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BroadcastInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.BroadcastInstanceInfo} BroadcastInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BroadcastInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.BroadcastInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BroadcastInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.BroadcastInstanceInfo} BroadcastInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BroadcastInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BroadcastInstanceInfo message.
             * @function verify
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BroadcastInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a BroadcastInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.BroadcastInstanceInfo} BroadcastInstanceInfo
             */
            BroadcastInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.BroadcastInstanceInfo)
                    return object;
                var message = new $root.ca2events.BroadcastInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a BroadcastInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {ca2events.BroadcastInstanceInfo} message BroadcastInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BroadcastInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this BroadcastInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.BroadcastInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BroadcastInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BroadcastInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.BroadcastInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BroadcastInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.BroadcastInstanceInfo";
            };
    
            return BroadcastInstanceInfo;
        })();
    
        ca2events.DeleteInstanceInfo = (function() {
    
            /**
             * Properties of a DeleteInstanceInfo.
             * @memberof ca2events
             * @interface IDeleteInstanceInfo
             * @property {number|null} [instanceId] DeleteInstanceInfo instanceId
             * @property {string|null} [providerId] DeleteInstanceInfo providerId
             * @property {string|null} [externalId] DeleteInstanceInfo externalId
             * @property {string|null} [startupScriptId] DeleteInstanceInfo startupScriptId
             */
    
            /**
             * Constructs a new DeleteInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a DeleteInstanceInfo.
             * @implements IDeleteInstanceInfo
             * @constructor
             * @param {ca2events.IDeleteInstanceInfo=} [properties] Properties to set
             */
            function DeleteInstanceInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.DeleteInstanceInfo
             * @instance
             */
            DeleteInstanceInfo.prototype.instanceId = 0;
    
            /**
             * DeleteInstanceInfo providerId.
             * @member {string} providerId
             * @memberof ca2events.DeleteInstanceInfo
             * @instance
             */
            DeleteInstanceInfo.prototype.providerId = "";
    
            /**
             * DeleteInstanceInfo externalId.
             * @member {string} externalId
             * @memberof ca2events.DeleteInstanceInfo
             * @instance
             */
            DeleteInstanceInfo.prototype.externalId = "";
    
            /**
             * DeleteInstanceInfo startupScriptId.
             * @member {string} startupScriptId
             * @memberof ca2events.DeleteInstanceInfo
             * @instance
             */
            DeleteInstanceInfo.prototype.startupScriptId = "";
    
            /**
             * Creates a new DeleteInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {ca2events.IDeleteInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.DeleteInstanceInfo} DeleteInstanceInfo instance
             */
            DeleteInstanceInfo.create = function create(properties) {
                return new DeleteInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified DeleteInstanceInfo message. Does not implicitly {@link ca2events.DeleteInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {ca2events.IDeleteInstanceInfo} message DeleteInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
                if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalId);
                if (message.startupScriptId != null && Object.hasOwnProperty.call(message, "startupScriptId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.startupScriptId);
                return writer;
            };
    
            /**
             * Encodes the specified DeleteInstanceInfo message, length delimited. Does not implicitly {@link ca2events.DeleteInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {ca2events.IDeleteInstanceInfo} message DeleteInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.DeleteInstanceInfo} DeleteInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.DeleteInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 3: {
                            message.externalId = reader.string();
                            break;
                        }
                    case 4: {
                            message.startupScriptId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.DeleteInstanceInfo} DeleteInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteInstanceInfo message.
             * @function verify
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    if (!$util.isString(message.externalId))
                        return "externalId: string expected";
                if (message.startupScriptId != null && message.hasOwnProperty("startupScriptId"))
                    if (!$util.isString(message.startupScriptId))
                        return "startupScriptId: string expected";
                return null;
            };
    
            /**
             * Creates a DeleteInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.DeleteInstanceInfo} DeleteInstanceInfo
             */
            DeleteInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.DeleteInstanceInfo)
                    return object;
                var message = new $root.ca2events.DeleteInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.externalId != null)
                    message.externalId = String(object.externalId);
                if (object.startupScriptId != null)
                    message.startupScriptId = String(object.startupScriptId);
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {ca2events.DeleteInstanceInfo} message DeleteInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.instanceId = 0;
                    object.providerId = "";
                    object.externalId = "";
                    object.startupScriptId = "";
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    object.externalId = message.externalId;
                if (message.startupScriptId != null && message.hasOwnProperty("startupScriptId"))
                    object.startupScriptId = message.startupScriptId;
                return object;
            };
    
            /**
             * Converts this DeleteInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.DeleteInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeleteInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.DeleteInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.DeleteInstanceInfo";
            };
    
            return DeleteInstanceInfo;
        })();
    
        ca2events.MarkInstanceAsDeletedInfo = (function() {
    
            /**
             * Properties of a MarkInstanceAsDeletedInfo.
             * @memberof ca2events
             * @interface IMarkInstanceAsDeletedInfo
             * @property {number|null} [instanceId] MarkInstanceAsDeletedInfo instanceId
             */
    
            /**
             * Constructs a new MarkInstanceAsDeletedInfo.
             * @memberof ca2events
             * @classdesc Represents a MarkInstanceAsDeletedInfo.
             * @implements IMarkInstanceAsDeletedInfo
             * @constructor
             * @param {ca2events.IMarkInstanceAsDeletedInfo=} [properties] Properties to set
             */
            function MarkInstanceAsDeletedInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * MarkInstanceAsDeletedInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @instance
             */
            MarkInstanceAsDeletedInfo.prototype.instanceId = 0;
    
            /**
             * Creates a new MarkInstanceAsDeletedInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {ca2events.IMarkInstanceAsDeletedInfo=} [properties] Properties to set
             * @returns {ca2events.MarkInstanceAsDeletedInfo} MarkInstanceAsDeletedInfo instance
             */
            MarkInstanceAsDeletedInfo.create = function create(properties) {
                return new MarkInstanceAsDeletedInfo(properties);
            };
    
            /**
             * Encodes the specified MarkInstanceAsDeletedInfo message. Does not implicitly {@link ca2events.MarkInstanceAsDeletedInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {ca2events.IMarkInstanceAsDeletedInfo} message MarkInstanceAsDeletedInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkInstanceAsDeletedInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified MarkInstanceAsDeletedInfo message, length delimited. Does not implicitly {@link ca2events.MarkInstanceAsDeletedInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {ca2events.IMarkInstanceAsDeletedInfo} message MarkInstanceAsDeletedInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkInstanceAsDeletedInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a MarkInstanceAsDeletedInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.MarkInstanceAsDeletedInfo} MarkInstanceAsDeletedInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkInstanceAsDeletedInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.MarkInstanceAsDeletedInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a MarkInstanceAsDeletedInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.MarkInstanceAsDeletedInfo} MarkInstanceAsDeletedInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkInstanceAsDeletedInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a MarkInstanceAsDeletedInfo message.
             * @function verify
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkInstanceAsDeletedInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a MarkInstanceAsDeletedInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.MarkInstanceAsDeletedInfo} MarkInstanceAsDeletedInfo
             */
            MarkInstanceAsDeletedInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.MarkInstanceAsDeletedInfo)
                    return object;
                var message = new $root.ca2events.MarkInstanceAsDeletedInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a MarkInstanceAsDeletedInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {ca2events.MarkInstanceAsDeletedInfo} message MarkInstanceAsDeletedInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkInstanceAsDeletedInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this MarkInstanceAsDeletedInfo to JSON.
             * @function toJSON
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkInstanceAsDeletedInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for MarkInstanceAsDeletedInfo
             * @function getTypeUrl
             * @memberof ca2events.MarkInstanceAsDeletedInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarkInstanceAsDeletedInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.MarkInstanceAsDeletedInfo";
            };
    
            return MarkInstanceAsDeletedInfo;
        })();
    
        ca2events.StartInstanceInfo = (function() {
    
            /**
             * Properties of a StartInstanceInfo.
             * @memberof ca2events
             * @interface IStartInstanceInfo
             * @property {number|null} [instanceId] StartInstanceInfo instanceId
             * @property {string|null} [providerId] StartInstanceInfo providerId
             * @property {string|null} [externalId] StartInstanceInfo externalId
             */
    
            /**
             * Constructs a new StartInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a StartInstanceInfo.
             * @implements IStartInstanceInfo
             * @constructor
             * @param {ca2events.IStartInstanceInfo=} [properties] Properties to set
             */
            function StartInstanceInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StartInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.StartInstanceInfo
             * @instance
             */
            StartInstanceInfo.prototype.instanceId = 0;
    
            /**
             * StartInstanceInfo providerId.
             * @member {string} providerId
             * @memberof ca2events.StartInstanceInfo
             * @instance
             */
            StartInstanceInfo.prototype.providerId = "";
    
            /**
             * StartInstanceInfo externalId.
             * @member {string} externalId
             * @memberof ca2events.StartInstanceInfo
             * @instance
             */
            StartInstanceInfo.prototype.externalId = "";
    
            /**
             * Creates a new StartInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {ca2events.IStartInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.StartInstanceInfo} StartInstanceInfo instance
             */
            StartInstanceInfo.create = function create(properties) {
                return new StartInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified StartInstanceInfo message. Does not implicitly {@link ca2events.StartInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {ca2events.IStartInstanceInfo} message StartInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
                if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalId);
                return writer;
            };
    
            /**
             * Encodes the specified StartInstanceInfo message, length delimited. Does not implicitly {@link ca2events.StartInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {ca2events.IStartInstanceInfo} message StartInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StartInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.StartInstanceInfo} StartInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.StartInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 3: {
                            message.externalId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StartInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.StartInstanceInfo} StartInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StartInstanceInfo message.
             * @function verify
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    if (!$util.isString(message.externalId))
                        return "externalId: string expected";
                return null;
            };
    
            /**
             * Creates a StartInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.StartInstanceInfo} StartInstanceInfo
             */
            StartInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.StartInstanceInfo)
                    return object;
                var message = new $root.ca2events.StartInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.externalId != null)
                    message.externalId = String(object.externalId);
                return message;
            };
    
            /**
             * Creates a plain object from a StartInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {ca2events.StartInstanceInfo} message StartInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.instanceId = 0;
                    object.providerId = "";
                    object.externalId = "";
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    object.externalId = message.externalId;
                return object;
            };
    
            /**
             * Converts this StartInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.StartInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StartInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.StartInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.StartInstanceInfo";
            };
    
            return StartInstanceInfo;
        })();
    
        ca2events.StopInstanceInfo = (function() {
    
            /**
             * Properties of a StopInstanceInfo.
             * @memberof ca2events
             * @interface IStopInstanceInfo
             * @property {number|null} [instanceId] StopInstanceInfo instanceId
             * @property {string|null} [providerId] StopInstanceInfo providerId
             * @property {string|null} [externalId] StopInstanceInfo externalId
             */
    
            /**
             * Constructs a new StopInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a StopInstanceInfo.
             * @implements IStopInstanceInfo
             * @constructor
             * @param {ca2events.IStopInstanceInfo=} [properties] Properties to set
             */
            function StopInstanceInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StopInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.StopInstanceInfo
             * @instance
             */
            StopInstanceInfo.prototype.instanceId = 0;
    
            /**
             * StopInstanceInfo providerId.
             * @member {string} providerId
             * @memberof ca2events.StopInstanceInfo
             * @instance
             */
            StopInstanceInfo.prototype.providerId = "";
    
            /**
             * StopInstanceInfo externalId.
             * @member {string} externalId
             * @memberof ca2events.StopInstanceInfo
             * @instance
             */
            StopInstanceInfo.prototype.externalId = "";
    
            /**
             * Creates a new StopInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {ca2events.IStopInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.StopInstanceInfo} StopInstanceInfo instance
             */
            StopInstanceInfo.create = function create(properties) {
                return new StopInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified StopInstanceInfo message. Does not implicitly {@link ca2events.StopInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {ca2events.IStopInstanceInfo} message StopInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
                if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalId);
                return writer;
            };
    
            /**
             * Encodes the specified StopInstanceInfo message, length delimited. Does not implicitly {@link ca2events.StopInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {ca2events.IStopInstanceInfo} message StopInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StopInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.StopInstanceInfo} StopInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.StopInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 3: {
                            message.externalId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StopInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.StopInstanceInfo} StopInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StopInstanceInfo message.
             * @function verify
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StopInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    if (!$util.isString(message.externalId))
                        return "externalId: string expected";
                return null;
            };
    
            /**
             * Creates a StopInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.StopInstanceInfo} StopInstanceInfo
             */
            StopInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.StopInstanceInfo)
                    return object;
                var message = new $root.ca2events.StopInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.externalId != null)
                    message.externalId = String(object.externalId);
                return message;
            };
    
            /**
             * Creates a plain object from a StopInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {ca2events.StopInstanceInfo} message StopInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StopInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.instanceId = 0;
                    object.providerId = "";
                    object.externalId = "";
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    object.externalId = message.externalId;
                return object;
            };
    
            /**
             * Converts this StopInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.StopInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StopInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StopInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.StopInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StopInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.StopInstanceInfo";
            };
    
            return StopInstanceInfo;
        })();
    
        ca2events.RestartInstanceInfo = (function() {
    
            /**
             * Properties of a RestartInstanceInfo.
             * @memberof ca2events
             * @interface IRestartInstanceInfo
             * @property {number|null} [instanceId] RestartInstanceInfo instanceId
             * @property {string|null} [providerId] RestartInstanceInfo providerId
             * @property {string|null} [externalId] RestartInstanceInfo externalId
             */
    
            /**
             * Constructs a new RestartInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a RestartInstanceInfo.
             * @implements IRestartInstanceInfo
             * @constructor
             * @param {ca2events.IRestartInstanceInfo=} [properties] Properties to set
             */
            function RestartInstanceInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RestartInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.RestartInstanceInfo
             * @instance
             */
            RestartInstanceInfo.prototype.instanceId = 0;
    
            /**
             * RestartInstanceInfo providerId.
             * @member {string} providerId
             * @memberof ca2events.RestartInstanceInfo
             * @instance
             */
            RestartInstanceInfo.prototype.providerId = "";
    
            /**
             * RestartInstanceInfo externalId.
             * @member {string} externalId
             * @memberof ca2events.RestartInstanceInfo
             * @instance
             */
            RestartInstanceInfo.prototype.externalId = "";
    
            /**
             * Creates a new RestartInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {ca2events.IRestartInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.RestartInstanceInfo} RestartInstanceInfo instance
             */
            RestartInstanceInfo.create = function create(properties) {
                return new RestartInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified RestartInstanceInfo message. Does not implicitly {@link ca2events.RestartInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {ca2events.IRestartInstanceInfo} message RestartInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RestartInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
                if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalId);
                return writer;
            };
    
            /**
             * Encodes the specified RestartInstanceInfo message, length delimited. Does not implicitly {@link ca2events.RestartInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {ca2events.IRestartInstanceInfo} message RestartInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RestartInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RestartInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.RestartInstanceInfo} RestartInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RestartInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.RestartInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 3: {
                            message.externalId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RestartInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.RestartInstanceInfo} RestartInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RestartInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RestartInstanceInfo message.
             * @function verify
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RestartInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    if (!$util.isString(message.externalId))
                        return "externalId: string expected";
                return null;
            };
    
            /**
             * Creates a RestartInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.RestartInstanceInfo} RestartInstanceInfo
             */
            RestartInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.RestartInstanceInfo)
                    return object;
                var message = new $root.ca2events.RestartInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.externalId != null)
                    message.externalId = String(object.externalId);
                return message;
            };
    
            /**
             * Creates a plain object from a RestartInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {ca2events.RestartInstanceInfo} message RestartInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RestartInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.instanceId = 0;
                    object.providerId = "";
                    object.externalId = "";
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    object.externalId = message.externalId;
                return object;
            };
    
            /**
             * Converts this RestartInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.RestartInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RestartInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RestartInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.RestartInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RestartInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.RestartInstanceInfo";
            };
    
            return RestartInstanceInfo;
        })();
    
        ca2events.ReinstallInstanceInfo = (function() {
    
            /**
             * Properties of a ReinstallInstanceInfo.
             * @memberof ca2events
             * @interface IReinstallInstanceInfo
             * @property {number|null} [instanceId] ReinstallInstanceInfo instanceId
             * @property {string|null} [providerId] ReinstallInstanceInfo providerId
             * @property {string|null} [externalId] ReinstallInstanceInfo externalId
             * @property {Array.<number>|null} [sshKeyIds] ReinstallInstanceInfo sshKeyIds
             * @property {string|null} [imageId] ReinstallInstanceInfo imageId
             */
    
            /**
             * Constructs a new ReinstallInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a ReinstallInstanceInfo.
             * @implements IReinstallInstanceInfo
             * @constructor
             * @param {ca2events.IReinstallInstanceInfo=} [properties] Properties to set
             */
            function ReinstallInstanceInfo(properties) {
                this.sshKeyIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ReinstallInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.ReinstallInstanceInfo
             * @instance
             */
            ReinstallInstanceInfo.prototype.instanceId = 0;
    
            /**
             * ReinstallInstanceInfo providerId.
             * @member {string} providerId
             * @memberof ca2events.ReinstallInstanceInfo
             * @instance
             */
            ReinstallInstanceInfo.prototype.providerId = "";
    
            /**
             * ReinstallInstanceInfo externalId.
             * @member {string} externalId
             * @memberof ca2events.ReinstallInstanceInfo
             * @instance
             */
            ReinstallInstanceInfo.prototype.externalId = "";
    
            /**
             * ReinstallInstanceInfo sshKeyIds.
             * @member {Array.<number>} sshKeyIds
             * @memberof ca2events.ReinstallInstanceInfo
             * @instance
             */
            ReinstallInstanceInfo.prototype.sshKeyIds = $util.emptyArray;
    
            /**
             * ReinstallInstanceInfo imageId.
             * @member {string} imageId
             * @memberof ca2events.ReinstallInstanceInfo
             * @instance
             */
            ReinstallInstanceInfo.prototype.imageId = "";
    
            /**
             * Creates a new ReinstallInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {ca2events.IReinstallInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.ReinstallInstanceInfo} ReinstallInstanceInfo instance
             */
            ReinstallInstanceInfo.create = function create(properties) {
                return new ReinstallInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified ReinstallInstanceInfo message. Does not implicitly {@link ca2events.ReinstallInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {ca2events.IReinstallInstanceInfo} message ReinstallInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReinstallInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
                if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalId);
                if (message.sshKeyIds != null && message.sshKeyIds.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        writer.uint32(message.sshKeyIds[i]);
                    writer.ldelim();
                }
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.imageId);
                return writer;
            };
    
            /**
             * Encodes the specified ReinstallInstanceInfo message, length delimited. Does not implicitly {@link ca2events.ReinstallInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {ca2events.IReinstallInstanceInfo} message ReinstallInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReinstallInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ReinstallInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.ReinstallInstanceInfo} ReinstallInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReinstallInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.ReinstallInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.providerId = reader.string();
                            break;
                        }
                    case 3: {
                            message.externalId = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.sshKeyIds && message.sshKeyIds.length))
                                message.sshKeyIds = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.sshKeyIds.push(reader.uint32());
                            } else
                                message.sshKeyIds.push(reader.uint32());
                            break;
                        }
                    case 5: {
                            message.imageId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ReinstallInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.ReinstallInstanceInfo} ReinstallInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReinstallInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ReinstallInstanceInfo message.
             * @function verify
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReinstallInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    if (!$util.isString(message.providerId))
                        return "providerId: string expected";
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    if (!$util.isString(message.externalId))
                        return "externalId: string expected";
                if (message.sshKeyIds != null && message.hasOwnProperty("sshKeyIds")) {
                    if (!Array.isArray(message.sshKeyIds))
                        return "sshKeyIds: array expected";
                    for (var i = 0; i < message.sshKeyIds.length; ++i)
                        if (!$util.isInteger(message.sshKeyIds[i]))
                            return "sshKeyIds: integer[] expected";
                }
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                return null;
            };
    
            /**
             * Creates a ReinstallInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.ReinstallInstanceInfo} ReinstallInstanceInfo
             */
            ReinstallInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.ReinstallInstanceInfo)
                    return object;
                var message = new $root.ca2events.ReinstallInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                if (object.providerId != null)
                    message.providerId = String(object.providerId);
                if (object.externalId != null)
                    message.externalId = String(object.externalId);
                if (object.sshKeyIds) {
                    if (!Array.isArray(object.sshKeyIds))
                        throw TypeError(".ca2events.ReinstallInstanceInfo.sshKeyIds: array expected");
                    message.sshKeyIds = [];
                    for (var i = 0; i < object.sshKeyIds.length; ++i)
                        message.sshKeyIds[i] = object.sshKeyIds[i] >>> 0;
                }
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                return message;
            };
    
            /**
             * Creates a plain object from a ReinstallInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {ca2events.ReinstallInstanceInfo} message ReinstallInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReinstallInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.sshKeyIds = [];
                if (options.defaults) {
                    object.instanceId = 0;
                    object.providerId = "";
                    object.externalId = "";
                    object.imageId = "";
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.providerId != null && message.hasOwnProperty("providerId"))
                    object.providerId = message.providerId;
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    object.externalId = message.externalId;
                if (message.sshKeyIds && message.sshKeyIds.length) {
                    object.sshKeyIds = [];
                    for (var j = 0; j < message.sshKeyIds.length; ++j)
                        object.sshKeyIds[j] = message.sshKeyIds[j];
                }
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                return object;
            };
    
            /**
             * Converts this ReinstallInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.ReinstallInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReinstallInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ReinstallInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.ReinstallInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReinstallInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.ReinstallInstanceInfo";
            };
    
            return ReinstallInstanceInfo;
        })();
    
        ca2events.PayForInstanceInfo = (function() {
    
            /**
             * Properties of a PayForInstanceInfo.
             * @memberof ca2events
             * @interface IPayForInstanceInfo
             * @property {number|null} [instanceId] PayForInstanceInfo instanceId
             */
    
            /**
             * Constructs a new PayForInstanceInfo.
             * @memberof ca2events
             * @classdesc Represents a PayForInstanceInfo.
             * @implements IPayForInstanceInfo
             * @constructor
             * @param {ca2events.IPayForInstanceInfo=} [properties] Properties to set
             */
            function PayForInstanceInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PayForInstanceInfo instanceId.
             * @member {number} instanceId
             * @memberof ca2events.PayForInstanceInfo
             * @instance
             */
            PayForInstanceInfo.prototype.instanceId = 0;
    
            /**
             * Creates a new PayForInstanceInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {ca2events.IPayForInstanceInfo=} [properties] Properties to set
             * @returns {ca2events.PayForInstanceInfo} PayForInstanceInfo instance
             */
            PayForInstanceInfo.create = function create(properties) {
                return new PayForInstanceInfo(properties);
            };
    
            /**
             * Encodes the specified PayForInstanceInfo message. Does not implicitly {@link ca2events.PayForInstanceInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {ca2events.IPayForInstanceInfo} message PayForInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayForInstanceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.instanceId);
                return writer;
            };
    
            /**
             * Encodes the specified PayForInstanceInfo message, length delimited. Does not implicitly {@link ca2events.PayForInstanceInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {ca2events.IPayForInstanceInfo} message PayForInstanceInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayForInstanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PayForInstanceInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.PayForInstanceInfo} PayForInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayForInstanceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.PayForInstanceInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PayForInstanceInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.PayForInstanceInfo} PayForInstanceInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayForInstanceInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PayForInstanceInfo message.
             * @function verify
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PayForInstanceInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isInteger(message.instanceId))
                        return "instanceId: integer expected";
                return null;
            };
    
            /**
             * Creates a PayForInstanceInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.PayForInstanceInfo} PayForInstanceInfo
             */
            PayForInstanceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.PayForInstanceInfo)
                    return object;
                var message = new $root.ca2events.PayForInstanceInfo();
                if (object.instanceId != null)
                    message.instanceId = object.instanceId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a PayForInstanceInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {ca2events.PayForInstanceInfo} message PayForInstanceInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PayForInstanceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.instanceId = 0;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };
    
            /**
             * Converts this PayForInstanceInfo to JSON.
             * @function toJSON
             * @memberof ca2events.PayForInstanceInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PayForInstanceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PayForInstanceInfo
             * @function getTypeUrl
             * @memberof ca2events.PayForInstanceInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PayForInstanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.PayForInstanceInfo";
            };
    
            return PayForInstanceInfo;
        })();
    
        ca2events.NewCheckDomainInfo = (function() {
    
            /**
             * Properties of a NewCheckDomainInfo.
             * @memberof ca2events
             * @interface INewCheckDomainInfo
             * @property {string|null} [checkId] NewCheckDomainInfo checkId
             * @property {Array.<ca2types.IDomainSuggestion>|null} [suggestions] NewCheckDomainInfo suggestions
             */
    
            /**
             * Constructs a new NewCheckDomainInfo.
             * @memberof ca2events
             * @classdesc Represents a NewCheckDomainInfo.
             * @implements INewCheckDomainInfo
             * @constructor
             * @param {ca2events.INewCheckDomainInfo=} [properties] Properties to set
             */
            function NewCheckDomainInfo(properties) {
                this.suggestions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * NewCheckDomainInfo checkId.
             * @member {string} checkId
             * @memberof ca2events.NewCheckDomainInfo
             * @instance
             */
            NewCheckDomainInfo.prototype.checkId = "";
    
            /**
             * NewCheckDomainInfo suggestions.
             * @member {Array.<ca2types.IDomainSuggestion>} suggestions
             * @memberof ca2events.NewCheckDomainInfo
             * @instance
             */
            NewCheckDomainInfo.prototype.suggestions = $util.emptyArray;
    
            /**
             * Creates a new NewCheckDomainInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {ca2events.INewCheckDomainInfo=} [properties] Properties to set
             * @returns {ca2events.NewCheckDomainInfo} NewCheckDomainInfo instance
             */
            NewCheckDomainInfo.create = function create(properties) {
                return new NewCheckDomainInfo(properties);
            };
    
            /**
             * Encodes the specified NewCheckDomainInfo message. Does not implicitly {@link ca2events.NewCheckDomainInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {ca2events.INewCheckDomainInfo} message NewCheckDomainInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewCheckDomainInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.checkId != null && Object.hasOwnProperty.call(message, "checkId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.checkId);
                if (message.suggestions != null && message.suggestions.length)
                    for (var i = 0; i < message.suggestions.length; ++i)
                        $root.ca2types.DomainSuggestion.encode(message.suggestions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified NewCheckDomainInfo message, length delimited. Does not implicitly {@link ca2events.NewCheckDomainInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {ca2events.INewCheckDomainInfo} message NewCheckDomainInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewCheckDomainInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a NewCheckDomainInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.NewCheckDomainInfo} NewCheckDomainInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewCheckDomainInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.NewCheckDomainInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.checkId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.suggestions && message.suggestions.length))
                                message.suggestions = [];
                            message.suggestions.push($root.ca2types.DomainSuggestion.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a NewCheckDomainInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.NewCheckDomainInfo} NewCheckDomainInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewCheckDomainInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a NewCheckDomainInfo message.
             * @function verify
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewCheckDomainInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.checkId != null && message.hasOwnProperty("checkId"))
                    if (!$util.isString(message.checkId))
                        return "checkId: string expected";
                if (message.suggestions != null && message.hasOwnProperty("suggestions")) {
                    if (!Array.isArray(message.suggestions))
                        return "suggestions: array expected";
                    for (var i = 0; i < message.suggestions.length; ++i) {
                        var error = $root.ca2types.DomainSuggestion.verify(message.suggestions[i]);
                        if (error)
                            return "suggestions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a NewCheckDomainInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.NewCheckDomainInfo} NewCheckDomainInfo
             */
            NewCheckDomainInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.NewCheckDomainInfo)
                    return object;
                var message = new $root.ca2events.NewCheckDomainInfo();
                if (object.checkId != null)
                    message.checkId = String(object.checkId);
                if (object.suggestions) {
                    if (!Array.isArray(object.suggestions))
                        throw TypeError(".ca2events.NewCheckDomainInfo.suggestions: array expected");
                    message.suggestions = [];
                    for (var i = 0; i < object.suggestions.length; ++i) {
                        if (typeof object.suggestions[i] !== "object")
                            throw TypeError(".ca2events.NewCheckDomainInfo.suggestions: object expected");
                        message.suggestions[i] = $root.ca2types.DomainSuggestion.fromObject(object.suggestions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a NewCheckDomainInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {ca2events.NewCheckDomainInfo} message NewCheckDomainInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewCheckDomainInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.suggestions = [];
                if (options.defaults)
                    object.checkId = "";
                if (message.checkId != null && message.hasOwnProperty("checkId"))
                    object.checkId = message.checkId;
                if (message.suggestions && message.suggestions.length) {
                    object.suggestions = [];
                    for (var j = 0; j < message.suggestions.length; ++j)
                        object.suggestions[j] = $root.ca2types.DomainSuggestion.toObject(message.suggestions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this NewCheckDomainInfo to JSON.
             * @function toJSON
             * @memberof ca2events.NewCheckDomainInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewCheckDomainInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for NewCheckDomainInfo
             * @function getTypeUrl
             * @memberof ca2events.NewCheckDomainInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewCheckDomainInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.NewCheckDomainInfo";
            };
    
            return NewCheckDomainInfo;
        })();
    
        ca2events.NewRegisterDomainInfo = (function() {
    
            /**
             * Properties of a NewRegisterDomainInfo.
             * @memberof ca2events
             * @interface INewRegisterDomainInfo
             * @property {number|null} [domainId] NewRegisterDomainInfo domainId
             */
    
            /**
             * Constructs a new NewRegisterDomainInfo.
             * @memberof ca2events
             * @classdesc Represents a NewRegisterDomainInfo.
             * @implements INewRegisterDomainInfo
             * @constructor
             * @param {ca2events.INewRegisterDomainInfo=} [properties] Properties to set
             */
            function NewRegisterDomainInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * NewRegisterDomainInfo domainId.
             * @member {number} domainId
             * @memberof ca2events.NewRegisterDomainInfo
             * @instance
             */
            NewRegisterDomainInfo.prototype.domainId = 0;
    
            /**
             * Creates a new NewRegisterDomainInfo instance using the specified properties.
             * @function create
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {ca2events.INewRegisterDomainInfo=} [properties] Properties to set
             * @returns {ca2events.NewRegisterDomainInfo} NewRegisterDomainInfo instance
             */
            NewRegisterDomainInfo.create = function create(properties) {
                return new NewRegisterDomainInfo(properties);
            };
    
            /**
             * Encodes the specified NewRegisterDomainInfo message. Does not implicitly {@link ca2events.NewRegisterDomainInfo.verify|verify} messages.
             * @function encode
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {ca2events.INewRegisterDomainInfo} message NewRegisterDomainInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewRegisterDomainInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domainId != null && Object.hasOwnProperty.call(message, "domainId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.domainId);
                return writer;
            };
    
            /**
             * Encodes the specified NewRegisterDomainInfo message, length delimited. Does not implicitly {@link ca2events.NewRegisterDomainInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {ca2events.INewRegisterDomainInfo} message NewRegisterDomainInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewRegisterDomainInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a NewRegisterDomainInfo message from the specified reader or buffer.
             * @function decode
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ca2events.NewRegisterDomainInfo} NewRegisterDomainInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewRegisterDomainInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ca2events.NewRegisterDomainInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.domainId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a NewRegisterDomainInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ca2events.NewRegisterDomainInfo} NewRegisterDomainInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewRegisterDomainInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a NewRegisterDomainInfo message.
             * @function verify
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewRegisterDomainInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domainId != null && message.hasOwnProperty("domainId"))
                    if (!$util.isInteger(message.domainId))
                        return "domainId: integer expected";
                return null;
            };
    
            /**
             * Creates a NewRegisterDomainInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ca2events.NewRegisterDomainInfo} NewRegisterDomainInfo
             */
            NewRegisterDomainInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.ca2events.NewRegisterDomainInfo)
                    return object;
                var message = new $root.ca2events.NewRegisterDomainInfo();
                if (object.domainId != null)
                    message.domainId = object.domainId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a NewRegisterDomainInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {ca2events.NewRegisterDomainInfo} message NewRegisterDomainInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewRegisterDomainInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.domainId = 0;
                if (message.domainId != null && message.hasOwnProperty("domainId"))
                    object.domainId = message.domainId;
                return object;
            };
    
            /**
             * Converts this NewRegisterDomainInfo to JSON.
             * @function toJSON
             * @memberof ca2events.NewRegisterDomainInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewRegisterDomainInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for NewRegisterDomainInfo
             * @function getTypeUrl
             * @memberof ca2events.NewRegisterDomainInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewRegisterDomainInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ca2events.NewRegisterDomainInfo";
            };
    
            return NewRegisterDomainInfo;
        })();
    
        return ca2events;
    })();

    return $root;
});
