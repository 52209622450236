import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  SelectChangeEvent,
  styled,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {getApiCreateInstanceError} from '../../../api/getApiError';
import {ca2instances} from '../../../api/proto';
import {ReactComponent as BaseQuestionIcon} from '../../../assets/icons/question.svg';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning-orange.svg';
import {ReactComponent as WindowsIcon} from '../../../assets/icons/windows.svg';
import RegionLabel from '../../../components/RegionLabel';
import CheckboxField from '../../../components/UI/CheckboxField';
import MenuItem from '../../../components/UI/MenuItem';
import PasswordInput from '../../../components/UI/PasswordInput';
import Selector from '../../../components/UI/Selector';
import {Switch} from '../../../components/UI/Switch';
import TextField from '../../../components/UI/TextField';
import ToggleButton from '../../../components/UI/ToggleButton';
import Typography from '../../../components/UI/Typography';
import {useStore} from '../../../stores/AppStore';
import {Plan} from '../../../stores/Plan';
import Addon from '../../../stores/Server/Addon';
import PlanCard from '../PlanCard';
import AddonSwitchBox, {SwitchBox} from './AddonSwitchBox';
import AuthMethodField from './AuthMethodField';

const QuestionIcon = styled(BaseQuestionIcon)(({theme}) => ({
  border: `1px solid ${theme.palette.border.tertiary}`,
  borderRadius: '50%',
  'path:last-child': {
    fill: theme.palette.body.tertiary,
  },
}));

interface IProps {
  plan: Plan;
  onSwitchToChoosePlan?(): void;
  onSubmit(setupPlanFormData: ca2instances.ICreateInstanceRequest): void;
}

const DEFAULT_WINDOWS_USER_NAME = 'Administrator';

export const SetupPlanForm: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {instancesStore, notification, serversStore} = useStore();

  const {plan} = props;

  const [selectedRegionId, setSelectedRegionId] = React.useState<string | null>(
    serversStore.regions.selectedId || plan.regions[0].id || null,
  );

  React.useEffect(() => {
    plan.loadPaymentOptions();
  }, [plan]);

  const datacenters = React.useMemo(() => {
    return plan.findDatacentersByRegionId(selectedRegionId);
  }, [plan, selectedRegionId]);

  const formik = useFormik<Omit<ca2instances.ICreateInstanceRequest, 'planId'>>({
    initialValues: {
      name: '',
      datacenterId: datacenters?.[0]?.id || '',
      imageId: plan.imagesList[0]?.id || '',
      generatePassword: plan.passwordAllow,
      sshKeyIds: [],
      enabledAddonIds: [],
      forceUsername: DEFAULT_WINDOWS_USER_NAME,
      forcePassword: '',
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      datacenterId: Yup.string().required(t('create_server_setup_plan_datacenter_required')),
      imageId: Yup.string().required(t('create_server_setup_plan_image_required')),
      generatePassword: Yup.boolean(),
      sshKeyIds: Yup.array().of(Yup.number()),
      enabledAddonIds: Yup.array(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (plan.id) {
        const {res, error} = await instancesStore.validateInstanceRequestData({planId: plan.id, ...values});

        if (error) {
          notification.error(error.message);
        }

        if (res) {
          const {errors} = res;

          if (errors?.length) {
            errors.forEach((error) => {
              const errorMessage = getApiCreateInstanceError(error);

              switch (error) {
                case ca2instances.CreateInstanceError.CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS:
                case ca2instances.CreateInstanceError.CSE_SERVER_NAME_TOO_LONG:
                  formik.setFieldError('name', errorMessage);
                  break;
                case ca2instances.CreateInstanceError.CSE_PASSWORD_NOT_STRONG:
                  formik.setFieldError('forcePassword', errorMessage);
                  break;
                case ca2instances.CreateInstanceError.CSE_INVALID_WINDOWS_USERNAME:
                  formik.setFieldError('forceUsername', errorMessage);
                  break;

                default:
                  notification.error(errorMessage);
                  break;
              }
            });

            return;
          }

          props.onSubmit({planId: plan.id, ...values});
        }
      }
    },
  });

  const handleChangeImageFamily = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement;
    plan.changeImageFamilyName(target.value);

    formik.setFieldValue('imageId', plan.imagesList[0]?.id || '');
  };

  const handleSetSshKeyIds = (ids: number[] | null) => {
    if (!ids?.length) {
      formik.setFieldValue('generatePassword', true);
    }

    formik.setFieldValue('sshKeyIds', ids || []);
  };

  const handleChangeGeneratePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (plan.isWindow && e.target.checked) {
      formik.setFieldValue('forceUsername', DEFAULT_WINDOWS_USER_NAME);
      formik.setFieldValue('forcePassword', '');
    }

    formik.setFieldValue('generatePassword', e.target.checked);
  };

  const handleChangeRegion = (e: SelectChangeEvent<unknown>) => {
    const target = e.target as HTMLInputElement;

    setSelectedRegionId(target.value);

    const datacenters_ = plan.findDatacentersByRegionId(target.value);
    formik.setFieldValue('datacenterId', datacenters_?.[0]?.id || '');
  };

  const toggleAddonSwitchHandler = (addon: Addon) => {
    const {id: addonId} = addon;

    if (!addonId) {
      return;
    }

    const addonIds = formik.values.enabledAddonIds ? [...formik.values.enabledAddonIds] : [];
    const index = addonIds?.indexOf(addonId);

    if (index === -1) {
      addonIds.push(addonId);
      plan.addAddonRate(addon);
    } else {
      addonIds.splice(index, 1);
      plan.subtractAddonRate(addon);
    }

    formik.setFieldValue('enabledAddonIds', addonIds);
  };

  const isAddonChecked = (addonId?: string | null) => {
    return !!formik.values.enabledAddonIds?.find((id) => id === addonId);
  };

  const noSshKeys = !formik.values.sshKeyIds || !formik.values.sshKeyIds.length;

  return (
    <div className="setup-plan-form">
      <PlanCard plan={props.plan} onChangePlan={props.onSwitchToChoosePlan} variant="slim" />

      <div className="setup-plan-form__body">
        <div className="setup-plan-form__form-wrap">
          <div className="setup-plan-form__form">
            <TextField
              fullWidth
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={t('create_server_setup_plan_name_placeholder')}
              label={t('create_server_setup_plan_name_label')}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />

            <Box
              className="setup-plan-form__row"
              sx={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: theme.spacing(4)}}
            >
              <Selector
                value={selectedRegionId}
                label={t('create_server_setup_plan_location_label')}
                onChange={handleChangeRegion}
              >
                {plan.regions.map((region) => (
                  <MenuItem key={region.id} value={region.id || ''}>
                    <RegionLabel regionOrDatacenter={region} />
                  </MenuItem>
                ))}
              </Selector>

              <Selector
                name="datacenterId"
                label={t('create_server_setup_plan_datacenter_label')}
                value={formik.values.datacenterId}
                onChange={formik.handleChange}
                error={!!formik.errors.datacenterId}
                helperText={formik.errors.datacenterId}
              >
                {datacenters.map((datacenter) => (
                  <MenuItem key={datacenter.id} value={datacenter.id || ''}>
                    <RegionLabel regionOrDatacenter={datacenter} />
                  </MenuItem>
                ))}
              </Selector>
            </Box>

            <div className="setup-plan-form__row">
              <InputLabel shrink>{t('create_server_setup_plan_image_family_label')}</InputLabel>
              <ToggleButtonGroup
                sx={{gridTemplateColumns: 'repeat(5, 1fr)'}}
                value={plan.selectedImageFamilyName}
                exclusive
                onChange={handleChangeImageFamily}
              >
                {plan.imageFamiliesList.map((family) => (
                  <ToggleButton key={family} value={family}>
                    {family}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>

            <div className="setup-plan-form__row">
              <Selector
                name="imageId"
                label={t('create_server_setup_plan_image_version_label')}
                value={formik.values.imageId}
                onChange={formik.handleChange}
                error={!!formik.errors.imageId}
                helperText={formik.errors.imageId}
              >
                {plan.imagesList.map((image) => (
                  <MenuItem key={image.id} value={image.id || ''}>
                    {image.name}
                  </MenuItem>
                ))}
              </Selector>
            </div>

            {formik.values.sshKeyIds || plan.isWindow ? (
              <div className="setup-plan-form__row">
                <AuthMethodField
                  isWindows={plan.isWindow}
                  onSetSshKeys={handleSetSshKeyIds}
                  sshKeyIds={formik.values.sshKeyIds || []}
                >
                  <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    {plan.passwordAllow ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          paddingX: theme.spacing(4),
                          border: `1px solid ${theme.palette.border.primary}`,
                          borderRadius: '8px',
                          marginTop: theme.spacing(2),
                        }}
                      >
                        <CheckboxField
                          label={
                            plan.isWindow
                              ? t('create_server_setup_plan_auth_method_generate_administrator_credits_label')
                              : t('create_server_setup_plan_auth_method_generate_root_password_label')
                          }
                          onChange={handleChangeGeneratePassword}
                          checked={!!formik.values.generatePassword}
                          disabled={plan.isWindow ? false : noSshKeys}
                        />

                        <Tooltip
                          disableHoverListener={false}
                          placement="bottom-end"
                          title={t('create_server_setup_plan_auth_method_generate_root_password_tooltip')}
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -10],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <QuestionIcon />
                        </Tooltip>
                      </Box>
                    ) : null}

                    {plan.isWindow && !formik.values.generatePassword ? (
                      <>
                        <TextField
                          fullWidth
                          name="forceUsername"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.forceUsername}
                          placeholder={t('create_server_setup_plan_force_login_placeholder')}
                          error={!!formik.errors.forceUsername}
                          helperText={formik.errors.forceUsername}
                          formControlProps={{
                            sx: {margin: `${theme.spacing(2)} 0`},
                          }}
                        />
                        {plan.passwordAllow ? (
                          <PasswordInput
                            fullWidth
                            name="forcePassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.forcePassword}
                            placeholder={t('create_server_setup_plan_force_password_placeholder')}
                            error={!!formik.errors.forcePassword}
                            helperText={formik.errors.forcePassword}
                            bottomGutter={false}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                </AuthMethodField>
              </div>
            ) : null}

            {plan.isWindow ? (
              <SwitchBox>
                <FormControlLabel
                  control={<Switch sx={(theme) => ({marginLeft: theme.spacing(1)})} />}
                  labelPlacement="start"
                  checked
                  disabled
                  sx={{
                    '& .MuiTypography-root.Mui-disabled': {
                      color: `${theme.palette.body.primary} !important`,
                    },
                  }}
                  label={
                    <>
                      <WindowsIcon style={{fill: 'currentcolor'}} />
                      {t('create_server_setup_plan_windows_license_label')}
                      <div className="price">
                        {t('create_server_setup_plan_addon_per_month_price_label', {
                          price: plan.windowsLicenseMonthlyRateFormatter.formatted,
                        })}
                      </div>
                    </>
                  }
                />
              </SwitchBox>
            ) : null}

            {plan.addons.map((addon) => (
              <AddonSwitchBox
                key={addon.id}
                addon={addon}
                checked={isAddonChecked(addon.id)}
                onChange={() => toggleAddonSwitchHandler(addon)}
              />
            ))}

            {plan.isWindow ? (
              <Box
                sx={{
                  display: 'flex',
                  border: `1px solid ${theme.palette.border.primary}`,
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  borderBottom: 0,
                  padding: theme.spacing(2),
                }}
              >
                <WarningIcon />
                <Box sx={{marginLeft: theme.spacing(2)}}>
                  <Typography variant="subtitle1">
                    {t('create_server_setup_plan_windows_instal_time_message')}
                  </Typography>
                  <Typography variant="body2" color="body.secondary">
                    {t('create_server_setup_plan_windows_instal_time_description')}
                  </Typography>
                </Box>
              </Box>
            ) : null}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.backgroundSecondary,
                border: `1px solid ${theme.palette.border.primary}`,
                borderRadius: '8px',
                borderTopLeftRadius: plan.isWindow ? 0 : '8px',
                borderTopRightRadius: plan.isWindow ? 0 : '8px',
                padding: theme.spacing(4),
              }}
            >
              <Box>
                <Typography variant="h3" component="span">
                  {plan.monthlyTotalRateFormatter.formatted}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{marginLeft: theme.spacing(2), fontWeight: 'normal'}}
                  component="span"
                >
                  {t('create_server_setup_plan_auth_method_price_per_month_label')}
                </Typography>
              </Box>
              <Typography variant="subtitle1" sx={{fontWeight: 'normal'}}>
                ~{' '}
                {t('create_server_setup_plan_auth_method_price_per_hour_label', {
                  value: plan.hourlyTotalRateFormatter.formatted,
                })}
              </Typography>

              <Box sx={{marginTop: theme.spacing(3)}}>
                <Button variant="contained" size="large" onClick={() => formik.handleSubmit()}>
                  {t('create_server_setup_plan_to_payment_button')}
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SetupPlanForm;
