import {DateRange} from '@mui/x-date-pickers-pro';
import {Dayjs} from 'dayjs';
import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2billing, ca2types} from '../../api/proto';
import {PAGINATION_DEFAULT_PAGE, PAGINATION_PAGE_SIZE} from '../../constants';
import {AVAILABLE_OPERATION_TYPES} from '../../pages/BillingPage/OperationTypeFilter';
import isNil from '../../utils/isNil';
import APILayer from '../APILayer';
import BillingStore from './BillingStore';

type BalanceOperationFilter = {
  sortOrder?: ca2billing.BalanceOperationSort;
  currentPage?: number;
  dateRange?: DateRange<Dayjs>;
  operationTypes?: ca2types.BalanceOperationType[];
};

function formatCreatedAt(range: DateRange<Dayjs>): ca2types.IUint64Range | null {
  if (!range[0] || !range[1]) {
    return null;
  }

  const min = Long.fromNumber(range[0].valueOf() / 1000);
  let max = Long.fromNumber(range[1].valueOf() / 1000);

  if (max && min.equals(max)) {
    max = Long.fromNumber(range[1].endOf('day').valueOf() / 1000);
  }

  return {
    min,
    max,
  };
}

export class BalanceOperationsStore extends APILayer {
  constructor(public billingStore: BillingStore) {
    super(billingStore.app);
    makeObservable(this);
  }

  @observable isInit = false;
  @observable list: ca2types.IBalanceOperation[] = [];
  @observable total: number = 0;

  @observable sortOrder: ca2billing.BalanceOperationSort = ca2billing.BalanceOperationSort.BOS_CREATED_AT_DESC;
  @observable currentPage: number = PAGINATION_DEFAULT_PAGE;
  @observable dateRange: DateRange<Dayjs> = [null, null];
  @observable operationTypes: ca2types.BalanceOperationType[] = AVAILABLE_OPERATION_TYPES;

  @computed get isFilterUsed(): boolean {
    return (
      this.sortOrder !== ca2billing.BalanceOperationSort.BOS_CREATED_AT_DESC ||
      this.currentPage !== PAGINATION_DEFAULT_PAGE ||
      this.dateRange.some((date) => date !== null) ||
      this.operationTypes.length !== AVAILABLE_OPERATION_TYPES.length
    );
  }

  @computed get balanceOperationsRequest(): ca2billing.IBalanceOperationsRequest {
    return {
      limit: PAGINATION_PAGE_SIZE,
      offset: (this.currentPage - 1) * PAGINATION_PAGE_SIZE,
      operationTypes: this.operationTypes,
      createdAt: formatCreatedAt(this.dateRange),
      sortOrder: this.sortOrder,
      // balanceType: '',
    };
  }

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadBalanceOperations_();

    this.isInit = true;
  };

  private loadBalanceOperations_ = async () => {
    const {res} = await this.request({
      billing: {
        balanceOperations: this.balanceOperationsRequest,
      },
    });

    if (res?.billing?.balanceOperations) {
      this.loadBalanceOperationsProcess_(res.billing.balanceOperations);
    }
  };

  @action private loadBalanceOperationsProcess_ = (res: ca2billing.IBalanceOperationsResponse) => {
    if (res.items) {
      this.list = res.items;
    }
    if (res.total) {
      this.total = res.total || 0;
    }
  };

  @action setFilter = async ({sortOrder, currentPage, dateRange, operationTypes}: BalanceOperationFilter) => {
    if (!isNil(sortOrder)) {
      this.sortOrder = sortOrder;
    }
    if (!isNil(currentPage)) {
      this.currentPage = currentPage;
    }
    if (!isNil(dateRange)) {
      this.dateRange = dateRange;
    }
    if (!isNil(operationTypes)) {
      this.operationTypes = operationTypes;
    }

    await this.loadBalanceOperations_();
  };

  @action resetFilters = async () => {
    this.sortOrder = ca2billing.BalanceOperationSort.BOS_CREATED_AT_DESC;
    this.currentPage = PAGINATION_DEFAULT_PAGE;
    this.dateRange = [null, null];
    this.operationTypes = AVAILABLE_OPERATION_TYPES;

    await this.loadBalanceOperations_();
  };

  @action reset = () => {
    this.list = [];
    this.total = 0;

    this.sortOrder = ca2billing.BalanceOperationSort.BOS_CREATED_AT_DESC;
    this.currentPage = PAGINATION_DEFAULT_PAGE;
    this.dateRange = [null, null];
    this.operationTypes = AVAILABLE_OPERATION_TYPES;

    this.isInit = false;
  };
}
