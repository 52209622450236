import {Box, Dialog, DialogProps, DialogTitle, IconButton, styled, useTheme} from '@mui/material';
import React from 'react';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import SearchInput, {ISearchInputProps} from '../../components/UI/SearchInput';
import {ModalItem} from '../../stores/ModalsStore/ModalsStore';

const StyledDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.backgroundTertiary,
    borderRadius: '8px',
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
    paddingRight: '55px',
    color: theme.palette.body.primary,
    margin: 0,
    width: '100%',
  },
}));

const DialogHead = styled(Box)(({theme}) => ({
  position: 'relative',
  padding: `${theme.spacing(4)}`,
  borderBottom: `1px solid ${theme.palette.border.primary}`,
  backgroundColor: theme.palette.backgroundSecondary,

  '& .close-button': {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.body.placeholder,
  },
}));

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';

export interface ModalDialogRef {
  close: () => void;
}

interface ModalDialogProps extends Omit<DialogProps, 'open'> {
  modal: ModalItem;
  headerText?: string;
  withSearch?: boolean;
  SearchInputProps?: ISearchInputProps;
  open?: boolean;
  onClose?(): void;
}

const ModalDialog = React.forwardRef<ModalDialogRef, ModalDialogProps>(
  ({modal, headerText, withSearch, open, SearchInputProps, children, ...props}, ref) => {
    const theme = useTheme();
    const [opened, setOpened] = React.useState<boolean>(true);

    const handleAnimationEnd = () => {
      if (!opened) {
        props.onClose?.();
        modal.close();
      }
    };

    const handleClose = () => {
      setOpened(false);
    };

    React.useImperativeHandle(ref, () => ({
      close: handleClose,
    }));

    return (
      <StyledDialog
        open={open || opened}
        className="custom-scroll"
        onClose={handleClose}
        onTransitionExited={handleAnimationEnd}
        disableRestoreFocus
        {...props}
      >
        {headerText || withSearch ? (
          <DialogHead>
            <IconButton className="close-button" onClick={handleClose}>
              <CloseIcon
                style={{
                  fill: theme.palette.body.placeholder,
                }}
              />
            </IconButton>
            {headerText ? (
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <DialogTitle variant="subtitle1">{headerText}</DialogTitle>
              </Box>
            ) : null}

            {withSearch ? (
              <SearchInput
                {...SearchInputProps}
                sx={{marginTop: theme.spacing(4), backgroundColor: theme.palette.backgroundPrimary}}
                fullWidth
              />
            ) : null}
          </DialogHead>
        ) : null}
        {children}
      </StyledDialog>
    );
  },
);

export default ModalDialog;
