export enum Paths {
  Default = '/',
  Root = '/',

  Blocked = '/blocked',
  Logout = '/logout',
  Authorization = '/authorization',
  ForgotPassword = '/forgotPassword',

  Profile = '/profile',
  CreateServer = '/createServer',
  Servers = '/servers',
  ServerInfo = '/servers/:instanceId/:tabType',
  Domains = '/domains',
  SshKeys = '/sshKeys',
  Team = '/team',

  Billing = '/billing',
  Notifications = '/notifications',
}

export default Paths;

export type InstanceRouteParams = {
  instanceId?: string;
  tabType?: string;
};
