import {Box, Button, styled, Tab, Tabs, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import {ProfilePageTab} from './ProfilePage';

const Container = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.backgroundPrimary,
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  paddingBottom: 0,
}));

const Delimiter = styled(Box)(({theme}) => ({
  color: theme.palette.body.placeholder,
  margin: `0 ${theme.spacing(1)}`,
}));

interface IProps {
  currentTab: ProfilePageTab;
  onChange?(tab: ProfilePageTab);
  onClickBackButton?(): void;
}

export const ProfilePageTabs: React.FC<IProps> = ({currentTab, onChange, onClickBackButton}) => {
  const {t} = useTranslation();

  const isChangeEmailOrPassword = [ProfilePageTab.CHANGE_EMAIL, ProfilePageTab.CHANGE_PASSWORD].includes(currentTab);

  const tabTitles = {
    [ProfilePageTab.PROFILE]: t('profile_account_tab'),
    [ProfilePageTab.KYC]: t('profile_kyc_tab'),
    [ProfilePageTab.CHANGE_EMAIL]: t('profile_change_email_tab'),
    [ProfilePageTab.CHANGE_PASSWORD]: t('profile_change_password_tab'),
  };

  const handleChange = (_: React.SyntheticEvent, tab: ProfilePageTab) => {
    onChange?.(tab);
  };

  if (isChangeEmailOrPassword) {
    return (
      <div className="profile-page-top-bar">
        <div className="profile-page-top-bar__back">
          <Button
            className="profile-page-top-bar__back-btn"
            variant="outlined"
            size="large"
            onClick={onClickBackButton}
            sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
          >
            <ArrowLeftIcon width={24} height={24} />
          </Button>
        </div>
        <div className="profile-page-top-bar__title-wrap">
          <Typography variant="body3" color="body.tertiary">
            {t('profile_title')}
            <Delimiter component="span">/</Delimiter>
            {tabTitles[ProfilePageTab.PROFILE]}
          </Typography>
          <div className="profile-page-top-bar__title-bottom">
            <Typography variant="h4" component="span">
              {tabTitles[currentTab]}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('profile_title')}
      </Typography>

      <Tabs className="lineView" sx={{paddingLeft: 0}} value={currentTab} onChange={handleChange}>
        <Tab
          className="lineView"
          label={<Typography variant="subtitle1">{tabTitles[ProfilePageTab.PROFILE]}</Typography>}
          value={ProfilePageTab.PROFILE}
        />
        <Tab
          className="lineView"
          label={<Typography variant="subtitle1">{tabTitles[ProfilePageTab.KYC]}</Typography>}
          value={ProfilePageTab.KYC}
        />
      </Tabs>
    </Container>
  );
};

export default ProfilePageTabs;
