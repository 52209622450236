import {observer} from 'mobx-react';
import React from 'react';
import {animated} from 'react-spring';
import Div100vh from '../../components/Div100vh';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {useStore} from '../../stores/AppStore';
import DomainSuggestion from '../../stores/Domain/DomainSuggestion';
import DomainsPageTabs from './DomainsPageTabs';
import DomainsSearch from './DomainsSearch';
import DomainsTable from './DomainsTable';
import RegisterDomain from './RegisterDomain';

export enum DomainsPageTab {
  SEARCH = 'search',
  TABLE = 'table',
  REGISTER = 'register',
}

const tabsOrder = {
  [DomainsPageTab.SEARCH]: 1,
  [DomainsPageTab.TABLE]: 2,
  [DomainsPageTab.REGISTER]: 3,
};

export const DomainsPage: React.FC = observer(() => {
  const {domainsView, domainsStore} = useStore();

  const [currentTab, setCurrentTab] = React.useState(DomainsPageTab.SEARCH);
  const [prevTab, setPrevTab] = React.useState(DomainsPageTab.REGISTER);
  const [selectedDomainSuggestion, setSelectedDomainSuggestion] = React.useState<DomainSuggestion | null>(null);

  React.useEffect(() => {
    return () => {
      domainsView.reset();
    };
  }, [domainsView]);

  const handleChangeTab = (tab: DomainsPageTab) => {
    setPrevTab(currentTab);
    setCurrentTab(tab);
  };

  const handleRegisterDomain = (suggestion: DomainSuggestion) => {
    setSelectedDomainSuggestion(suggestion);
    handleChangeTab(DomainsPageTab.REGISTER);
  };

  const handleClickRegisterDomainBackButton = () => {
    setCurrentTab(DomainsPageTab.SEARCH);
  };

  const handleCancelRegistration = () => {
    setCurrentTab(DomainsPageTab.SEARCH);
    setSelectedDomainSuggestion(null);
  };

  const switchToSearchDomains = () => {
    setCurrentTab(DomainsPageTab.SEARCH);
  };

  const transitions = useOrderTransition({
    currentStep: tabsOrder[currentTab],
    prevStep: tabsOrder[prevTab],
  });

  if (selectedDomainSuggestion && currentTab === DomainsPageTab.REGISTER) {
    return (
      <RegisterDomain
        domainSuggestion={selectedDomainSuggestion}
        onClickBackButton={handleClickRegisterDomainBackButton}
        onCancelRegistration={handleCancelRegistration}
      />
    );
  }

  return (
    <Div100vh className="page page--domains">
      <DomainsPageTabs currentTab={currentTab} onChange={handleChangeTab} />

      <div className="page__inner">
        {transitions((styles, step) =>
          step === tabsOrder[DomainsPageTab.SEARCH] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <DomainsSearch onRegisterDomain={handleRegisterDomain} />
            </animated.div>
          ) : step === tabsOrder[DomainsPageTab.TABLE] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <DomainsTable domains={domainsStore.domains} onSwitchToSearchDomains={switchToSearchDomains} />
            </animated.div>
          ) : null,
        )}
      </div>
    </Div100vh>
  );
});

export default DomainsPage;
