import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import DomainsStore from './DomainsStore';

export class Domain {
  constructor(public raw: ca2types.IDomain, public domainsStore: DomainsStore) {
    makeObservable(this);

    this.update(raw);
  }

  @observable name: string = '';
  @observable seller: ca2types.DomainSeller | null = null;
  @observable contactId: number | null = null;
  @observable termYears: number | null = null;
  @observable createdAt: Long | null = null;
  @observable expiresAt: Long | null = null;

  @computed get createdAtTimestamp() {
    return this.createdAt ? this.createdAt.toNumber() * 1000 : 0;
  }

  @computed get expiresAtTimestamp() {
    return this.expiresAt ? this.expiresAt.toNumber() * 1000 : 0;
  }

  @action update = (raw: ca2types.IDomain) => {
    Object.assign(this, raw);
  };
}

export default Domain;
