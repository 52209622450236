import {FormControlLabel, TableCell, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Switch} from '../../../components/UI/Switch';
import {Domain} from '../../../stores/Domain';
import {formatTimestamp} from '../../../utils/format/format';
import {DOMAINS_TABLE_COLUMNS, DomainsColumnType} from './DomainsTable';

interface IProps {
  domain: Domain;
}

export const DomainTableRow: React.FC<IProps> = ({domain}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const getTableCellComponent = (columnId: DomainsColumnType) => {
    switch (columnId) {
      case DomainsColumnType.DomainName:
        return domain.name;
      case DomainsColumnType.RegistrationDate:
        return formatTimestamp(domain.createdAtTimestamp, false);
      case DomainsColumnType.ExpirationDate:
        return formatTimestamp(domain.expiresAtTimestamp, false);
      case DomainsColumnType.AutoRenewal:
        return (
          <FormControlLabel
            control={<Switch />}
            labelPlacement="end"
            checked={false}
            label={t('domains_table_disabled_label')}
            sx={{'& .MuiTypography-root': {marginLeft: theme.spacing(2)}}}
          />
        );
    }
  };

  return (
    <React.Fragment>
      {DOMAINS_TABLE_COLUMNS.map((column) => (
        <TableCell
          align="left"
          key={column.id}
          sx={{
            maxWidth: column.width,
            width: column.width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {getTableCellComponent(column.id)}
        </TableCell>
      ))}
    </React.Fragment>
  );
};

export default DomainTableRow;
