import {Switch as MuiSwitch, SwitchProps as MuiSwitchProps} from '@mui/material';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

const StyledSwitch = styled(MuiSwitch)(({theme}) => ({
  width: 34,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '2px 3px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.brand.primary,
      },
      '&:hover + .MuiSwitch-track': {
        backgroundColor: theme.palette.brand.tertiary,
      },
      '&:active + .MuiSwitch-track': {
        backgroundColor: theme.palette.brand.quaternary,
      },
    },
    '&.Mui-disabled': {
      '&+.MuiSwitch-track': {
        backgroundColor: theme.palette.backgroundTertiary,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: theme.palette.body.tint,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.border.primary,
    boxSizing: 'border-box',
  },
  '&:hover .MuiSwitch-track': {
    backgroundColor: theme.palette.border.secondary,
  },
  '&:active .MuiSwitch-track': {
    backgroundColor: theme.palette.body.placeholder,
  },
}));

interface SwitchProps extends MuiSwitchProps {
  startLabel?: React.ReactNode;
  endLabel?: React.ReactNode;
}

export const Switch: React.FC<SwitchProps> = ({startLabel, endLabel, ...props}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" style={{width: '100%'}}>
      {startLabel}
      <StyledSwitch {...props} />
      {endLabel}
    </Stack>
  );
};
